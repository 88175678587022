import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { faCalendar, faMinusSquare, faUserEdit } from '@fortawesome/free-solid-svg-icons';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import {
  ICompany,
  ICompanyDetailDefinition,
  ICompanyDetailUpdate,
  ICompanyType
} from '../../../../services/models/member.model';
import { CompanyService } from '../../../../services/company.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../../environments/environment';
import { AdAuthService } from '../../../../core/ad-auth-service/ad-auth.service';
import { AlertService } from '../../../../services/alert.service';
import { ICountry } from '../../../../services/models/pricing/location.model';
import { takeUntil } from 'rxjs/operators';
import { IAgent } from '../../../../services/models/pricing/partner.model';
import { ApiService } from '../../../../services/api.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-manage-member-modal',
  templateUrl: './create-agent-modal.component.html',
  styleUrls: ['./create-agent-modal.component.scss']
})
export class CreateAgentModalComponent implements OnInit, OnDestroy {
  // Icons
  faRemove = faMinusSquare;
  faInfo = faCircleQuestion;
  faEdit = faUserEdit;
  faCalendar = faCalendar;

  // Component Variables
  newAgent: ICompany;
  newDetails: ICompanyDetailUpdate[] = [];
  companyDetailDefinitions$ = this.companyService.CompanyDetailDefinitions$.asObservable();
  companyTypes$ = this.companyService.CompanyTypes$.asObservable();
  emailDomainDefId: number;
  rateRequestDefId: number;
  agentCodeDefId: number;
  agentParentDefId: number;
  agentCompanyType: ICompanyType;
  invalid: boolean = false;
  checkingAgent: boolean = false;
  agentInUse: IAgent = null;

  // General variables
  env = environment;
  loading: boolean = false;
  private unsubscribe: Subject<any> = new Subject<any>();

  constructor(public activeModal: NgbActiveModal,
              public auth: AdAuthService,
              private api: ApiService,
              private cdr: ChangeDetectorRef,
              private companyService: CompanyService,
              private alertService: AlertService) { }

  ngOnInit(): void {
    // Set Definition ID's for Agent fields
    this.companyDetailDefinitions$.subscribe({
      next: (data: ICompanyDetailDefinition[]) => {
        this.agentCodeDefId = data.find(x => x.PropertyName === 'AgentCode').Id;
        this.agentParentDefId = data.find(x => x.PropertyName === 'AgentParent').Id;
        this.emailDomainDefId = data.find(x => x.PropertyName === 'EmailDomein').Id;
        this.rateRequestDefId = data.find(x => x.PropertyName === 'RateRequest').Id;
      }
    });

    // Set default CompanyType for Agents
    this.companyTypes$.subscribe({
      next: (data: ICompanyType[]) => {
        this.agentCompanyType = data.find(x => x.Description.toLowerCase() === 'agent');
        this.initNewAgent();
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  initNewAgent() {
    this.newAgent = {
      CompanyId: 0,
      CompanyTypeId: this.agentCompanyType.Id,
      Name: null,
      LegalName: null,
      GroupName: null,
      AddressPhysical: null,
      ZipCodePhysical: null,
      CityPhysical: null,
      CountryIdPhysical: null,
      AddressPostal: null,
      ZipCodePostal: null,
      CityPostal: null,
      Biography: null,
      Comment: null,
      Latitude: null,
      Longitude: null,
      IsDeleted: false,
      CreateByUserId: this.auth.CurrentUser.UserId,
      CreatedOnDate: new Date(),
      ModifiedByUserId: null,
      ModifiedOnDate: null,
      CompanyLogo: null,
      CompanyPicture: null,
      ProudMemberLogo: null,
      IsMovingServiceProvider: false,
      IsRelocationServiceProvider: false,
      AgentId: null,
      AgentStatus: null,
      CountryPhysical: null,
      CountryPhysicalIso: null,
      CountryPostal: null,
      CountryPostalIso: null,
      StartDate: null,
      PublishDate: null,
      EndDate: null,
      CoProcessId: null,
      CompanyType: this.agentCompanyType,
      CompanyDetails: []
    } as ICompany;

    this.cdr.detectChanges();
  }

  checkDuplicateAgentId() {
    this.checkingAgent = true;

    this.api.get('Agent/Single/' + this.newAgent.AgentId).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data: IAgent) => {
        if (data) {
          this.agentInUse = Object.assign({}, data);
          this.invalid = true;
        }
        this.checkingAgent = false;
      },
      error: () => {
        this.checkingAgent = false;
        this.agentInUse = null;
        this.invalid = false;
      }
    });
  }

  getDetailValue(propertyName: string) {
    const exists = this.newAgent.CompanyDetails.find((cd) => cd.CompanyDetailDefinition.PropertyName === propertyName);

    if (exists) {
      return exists.PropertyValue;
    } else {
      return null;
    }
  }

  updateDetailValue(definitionId: number, event: any) {
    const propertyValue = event.target.value;
    const indexToUpdate = this.newAgent.CompanyDetails.findIndex((cd) => cd.CompanyDetailDefinitionId === definitionId);

    if (indexToUpdate < 0) {
      const newDetail = {
        Id: null,
        ModifiedByUserId: this.auth.CurrentUser.UserId,
        CompanyId: this.newAgent.CompanyId,
        CompanyDetailDefinitionId: definitionId,
        PropertyValue: propertyValue.toString()
      } as ICompanyDetailUpdate;

      this.newDetails.push(newDetail);
    } else {
      this.newAgent.CompanyDetails[indexToUpdate].PropertyValue = propertyValue;
    }
  }

  setFidiNumber(event: any) {
    if (event.target.checked === true) {
      this.newAgent.FIDINumber = 'Y';
    } else {
      this.newAgent.FIDINumber = 'N';
    }
  }

  setCountryPhysical(event: ICountry) {
    if (event) {
      this.newAgent.CountryPhysicalIso = event.Iso3;
      this.newAgent.CountryIdPhysical = event.Id;
      this.newAgent.CountryPhysical = event.Name;
    } else {
      this.newAgent.CountryPhysicalIso = null;
      this.newAgent.CountryIdPhysical = null;
      this.newAgent.CountryPhysical = null;
    }
  }

  setCountryPostal(event: ICountry) {
    if (event) {
      this.newAgent.CountryPostalIso = event.Iso3;
      this.newAgent.CountryIdPostal = event.Id;
      this.newAgent.CountryPostal = event.Name;
    } else {
      this.newAgent.CountryPostalIso = null;
      this.newAgent.CountryIdPostal = null;
      this.newAgent.CountryPostal = null;
    }
  }

  setMovingProvider(event: any) {
    this.newAgent.IsMovingServiceProvider = event.target.checked;
  }

  setRelocationProvider(event: any) {
    this.newAgent.IsRelocationServiceProvider = event.target.checked;
  }

  canSave() {
    let isValid = false;
    // Check if user info complete
    if (this.newAgent) {
      isValid = (this.newAgent.Name && this.newAgent.Name.length > 0) &&
        (this.newAgent.AgentId && this.newAgent.AgentId > 0) &&
        (this.newAgent.CountryIdPhysical && this.newAgent.CountryIdPhysical > 0);
    }

    // Return result for button
    return isValid;
  }

  save() {
    if (this.canSave()) {
      this.loading = true;
      this.invalid = false;
      // Save member
      this.companyService.createAgent(this.newAgent).subscribe({
        next: (data: ICompany) => {
          if (data) {
            this.loading = false;
            if (this.newDetails && this.newDetails.length > 0 && data.CompanyId > 0) {
              // Set CompanyID from newly saved Company
              this.newDetails.forEach(x => x.CompanyId = data.CompanyId);
              this.companyService.UpsertCompanyDetailList(this.newDetails).subscribe({
                next: () => {
                  this.loading = false;
                  this.newDetails = [];
                  this.close();
                  this.alertService.success('Agent successfully created!');
                },
                error: () => {
                  this.loading = false;
                  this.alertService.warn('An error occurred while attempting to updating agent details.');
                }
              });
            } else {
              this.loading = false;
              this.alertService.success('Agent successfully created!');
            }
          }
        },
        error: () => {
          this.alertService.warn('An error occurred while attempting to update the agent information.');
          this.loading = false;
        }
      });
    } else {
      this.invalid = true;
    }
  }

  close() {
    this.activeModal.close();
  }
}
