<div class="dashboard-background"></div>
<div class="dashboard-wrapper">
  <div class="dashboard-content">
    <!-- Insurance -->
    <div class="three-by-five d-flex align-items-start services-card">
      <div>
        <div class="services-card-header row">
          <div class="col-12">
            <!--Header-->
            <h5 class="harmony-teal-text fw-bold">Insurance</h5>
          </div>
        </div>
        <hr class="ms-3 me-3 mt-0 mb-4">
        <div class="row mt-2">
          <div class="col-3">
            <div class="justify-content-center m-2">
              <img [src]="env.SiteUrls.AzureStorageBaseURL + env.ContainerNames.WebImagesServices + 'Insurance.jpg'"
                   class="service-image-rounded-borders"
                   alt="Insurance">
            </div>
          </div>
          <div class="col-9 service-content">
            <p>
            Pac Global offers Harmony a comprehensive insurance programme with competitive premiums, excellent terms and conditions, and a sophisticated technology platform to register insurance policies and report and monitor claims.
            </p>

            <p>
            Premiums will be reviewed periodically and can go up or down based on performance.
            </p>
            <p>
            Standard each company is placed in the 60% - 70% claim ratio premium schedule, resulting in the Insurance Programme category 4, however if your claim/premium ratio is lower we obviously are able to put your company in another category.
            </p>
            <p>
            In addition, there will be an option to offer various deductibles to the client, lowering the premium, as well as a Lump Sum and Total Loss insurance.
            </p>
            <p>
            Here are some of the main benefits of the programme:
            </p>

            <ul class="service-list">
              <li>100 USD/Euro deductible</li>
              <li>365 days free SIT</li>
              <li>Mould & Mildew</li>
              <li>Pairs & Sets</li>
              <li>Mechanical & Technical Derangements</li>
            </ul>
            <br>
            <p>
            Pac Global will be billing the members directly, and payment must be settled through the Harmony netting, in the first netting following the invoice.
            </p>
            <ol>
              <li>Harmony Relocation Insurance Programme Handout EURO July 2023 (PDF)</li>
              <li>Premium Schedule Overview July 2023 incl Euro 100 deductible (PDF)</li>
              <li>Valuation Inventory Form  Euro 2023 incl Lump Sum option (XLSX)</li>
              <br>
              <li>Harmony Relocation Insurance Programme Handout USD July 2023 (PDF)</li>
              <li>Premium Schedule Overview July 2023 incl USD 100 deductible (PDF)</li>
              <li>Valuation Inventory Form  USD 2023 incl Lump Sum option (XLSX)</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <!-- Insurance Contact -->
    <div class="one-by-one services-card">
      <div class="services-card-header row">
        <div class="col-12">
          <!--Header-->
          <h5 class="harmony-teal-text fw-bold">Insurance Contact</h5>
        </div>
      </div>
      <hr class="ms-3 me-3 mt-0 mb-4">
      <div class="row">
        <div class="col-12 ms-4">
          <div class="row">
            <div class="col-12 service-contact">
              Mr Maurice van Bijleveld
            </div>
          </div>
          <div class="row my-1">
            <div class="col-1">
              <fa-icon [icon]="faEnvelope"></fa-icon>
            </div>
            <div class="col-11 nav-item">
              <a href="mailto:m.bijleveld@harmonyrelo.com?Subject=Request information about Services/Insurance" class="harmony-blue-link">m.bijleveld@harmonyrelo.com</a>
            </div>
          </div>
          <div class="row my-1">
            <div class="col-1">
              <fa-icon [icon]="faPhone"></fa-icon>
            </div>
            <div class="col-11">
              <a href="callto:+31 294 461 020" style="color: rgb(0,0,0)">+31 294 461 020</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Insurance Documents -->
    <div class="one-by-one services-card">
      <div class="services-card-header row">
        <div class="col-12">
          <!--Header-->
          <h5 class="harmony-teal-text fw-bold">Insurance Documents</h5>
        </div>
      </div>
      <hr class="ms-3 me-3 mt-0 mb-4">
      <div class="card-body row mt-2">
        <div class="col-12 service-content document-list">
          <app-document-list [ModuleName]="'Services'" [CategoryCode]="'SERVSHEE'" [Recents]="true"></app-document-list>
        </div>
      </div>
    </div>
  </div>
</div>
