<div class="accordion" [id]="'accordion-documents'">
  <!-- Document Categories -->
  <ng-container *ngFor="let category of (categories$ | async)">
    <div class="row">
      <div class="col-12">
        <div class="card mb-1">
          <div class="card-header p-0" [attr.id]="'heading-' + category.Code">
            <div class="row pointer" (click)="categoryBtn.click()">
              <div class="col-10 my-0 col-compress align-self-center text-start">
                <div class="category-icon">
                  <fa-icon [size]="'2x'" *ngIf="category.Code != openCategory" [icon]="faCategoryIcon"></fa-icon>
                  <fa-icon [size]="'2x'" style="margin-left: 4px" class="harmony-blue-grey-text" *ngIf="category.Code === openCategory" [icon]="faCategoryOpenIcon"></fa-icon>
                </div>
                <div class="category-info">
                  <div class="row">
                    <div class="col-12">
                      <button class="btn chapter-header mb-0 p-0 fw-bold" #categoryBtn
                              type="button"
                              data-bs-toggle="collapse"
                              (click)="categoryBtn.click();setActiveCategory(category.Code)"
                              [attr.data-bs-target]="'#collapse-' + category.Code"
                              [attr.aria-expanded]="false"
                              [attr.aria-controls]="'collapse-' + category.Code">
                        {{ category.Name }}
                      </button>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12" [title]="category.Description">
                      <small class="mb-0">{{ category.Description }}</small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-1 text-center align-middle">
                <div class="mb-0 pt-2 fs-6">

                </div>
              </div>
            </div>
          </div>
          <div [attr.id]="'collapse-' + category.Code" class="collapse"
               [attr.aria-labelledby]="'heading-' + category.Code"
               [attr.data-bs-parent]="'#accordion-documents'">
            <div class="card-body">
              <app-document-list [CategoryCode]="category.Code" [ModuleName]="Module"></app-document-list>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

