import { AfterContentChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../../../../services/alert.service';
import { AdAuthService } from '../../../../core/ad-auth-service/ad-auth.service';
import { ApiService } from '../../../../services/api.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IActiveCompany, IAuthUserCompany } from '../../../../services/models/member.model';
import { environment } from '../../../../../environments/environment';
import { faCheck, faMinusSquare, faPlus } from '@fortawesome/free-solid-svg-icons';
import { NgModel } from '@angular/forms';
import { IAuthUser, IRole} from '../../../../services/models/auth.model';
import { IUser, IUsernameCheckResult, IUserRoleDto } from '../../../../services/models/user.model';

@Component({
  selector: 'app-manage-user-modal',
  templateUrl: './member-user-management-modal.component.html',
  styleUrls: ['./member-user-management-modal.component.scss']
})
export class MemberUserManagementModalComponent implements OnInit, AfterContentChecked {
  // Icons
  faRemove = faMinusSquare;
  faAdd = faPlus;
  faValid = faCheck;

  // Component Variables
  public AuthUser: IAuthUser;
  public IsNewUser: boolean;
  members: IActiveCompany[] = [];
  originalUser: IAuthUser;
  usernameValid: boolean = false;
  usernameValidMessage: string = null;
  invalid: boolean = false;
  rolesCompanyID: number = null;
  roleToAdd: IRole = null;
  userRoles: IUserRoleDto[] = [];
  newUserRoles: IUserRoleDto[] = [];
  roles$ = this.authService.Roles$.asObservable();
  allowedRoles$ = this.authService.AllowedRoles$.asObservable();

  // General variables
  env = environment;
  loading: boolean = false;
  checkingUser: boolean = false;
  private unsubscribe: Subject<any> = new Subject<any>();

  constructor(public activeModal: NgbActiveModal,
              public authService: AdAuthService,
              private api: ApiService,
              private ref: ChangeDetectorRef,
              private alertService: AlertService) {
  }

  ngOnInit(): void {
    if (this.IsNewUser) {
      this.AuthUser = this.getDefaultAuthUser();
    } else {
      // Set OG user object for comparison
      this.originalUser = JSON.parse(JSON.stringify(this.AuthUser));
      this.rolesCompanyID = this.AuthUser.User.CompanyId;
    }

    this.getUserRoles();
    this.checkDefaultRole();

    if (this.AuthUser?.UserId > 0) {
      this.usernameValid = true;
    }
  }

  getDefaultAuthUser() {
    this.rolesCompanyID = this.authService.CurrentUser.User.CompanyId;
    const today = new Date().toJSON();
    return {
      User: {
        Id: null,
        DateCreated: today,
        DateModified: null,
        Active: true,
        Username: '',
        IntranetUserId: null,
        FirstName: '',
        LastName: '',
        Email: '',
        DisplayName: '',
        UserTypeId: 2,
        UserType: null,
        LoginAttempt: 0,
        IsVerified: true,
        CompanyId: this.authService.CurrentUser.User.CompanyId,
        ProfilePicture: null,
        Company: this.authService.CurrentUser.User.Company,
        AzureRegistered: false
      } as IUser,
      UserId: null,
      UserRoles: [],
      UserCompanies: [{
        CompanyId: this.rolesCompanyID,
        CompanyName: this.authService.CurrentUser.User.Company.Name
      } as IAuthUserCompany],
      Permissions: []
    } as IAuthUser;
  }

  ngAfterContentChecked() {
    this.ref.detectChanges();
  }

  checkDefaultRole() {
    // Add default role if no roles have been added
    if (this.AuthUser?.UserRoles?.length <= 0) {
      this.roles$.subscribe((data) => {
        this.roleToAdd = data.find(x => x.Name === 'Member: User');
        this.addRoleToUser();
      });
    }
  }

  checkForValidUsername() {
    if (this.AuthUser.User && this.AuthUser.User.Username) {
      this.checkingUser = true;

      const param = {
        Username: this.AuthUser.User.Username,
        CompanyId: this.AuthUser.User.CompanyId
      };

      this.api.post('User/CheckValidUsername', param).pipe(
        takeUntil(this.unsubscribe)
      ).subscribe({
        next: (data: IUsernameCheckResult) => {
          this.usernameValid = data.IsValid;
          this.usernameValidMessage = data.Message;
          this.checkingUser = false;
        },
        error: () => {
          this.checkingUser = false;
        }
      });
    }
  }

  onUsernameChanged(username: NgModel, event: Event) {
    this.AuthUser.User.Username = (event.target as HTMLInputElement).value;
    this.checkForValidUsername();

    this.usernameValid = !(username.touched && (username.errors?.email || username.errors?.required) || this.AuthUser.User.Username.length <= 0);
  }

  ////////////////
  // User Roles //
  ////////////////
  getUserRoles() {
    if (this.AuthUser && this.AuthUser.UserId) {
      this.loading = true;
      this.authService.GetUserRoles(this.AuthUser.UserId, this.rolesCompanyID).subscribe(
        {
          next: (data) => {
            if (data) {
              this.userRoles = Object.assign([], data);
            }
            this.loading = false;
          },
          error: () => {
            this.loading = false;
          }
        });
    }
  }

  canDeleteRole(userRole: IUserRoleDto) {
    if (this.authService.AllowedRoles$ && this.authService.AllowedRoles$.getValue().length > 0) {
      const hasRoleAccess = this.authService.AllowedRoles$.getValue().filter(x => x.Id === userRole.RoleId);
      return (hasRoleAccess && hasRoleAccess.length > 0);
    }
    return false;
  }

  addRoleToUser() {
    if (this.roleToAdd) {
      const newUserRole = {
        Id: null,
        Active: true,
        UserId: this.AuthUser.UserId,
        RoleId: this.roleToAdd.Id,
        CompanyId: this.AuthUser.User.CompanyId,
        RoleName: this.roleToAdd.Name
      } as IUserRoleDto;
      this.roleToAdd = null;
      this.newUserRoles.push(newUserRole);
    }
  }

  removeNewUserRole(index: number) {
    this.newUserRoles.splice(index, 1);
  }

  updateCurrentUserRole(userRole: IUserRoleDto, active: boolean) {
    const index = this.userRoles.findIndex(x => x.Id === userRole.Id);
    if (index > -1) {
      this.userRoles[index].Active = active;
    }
  }

  userHasRole(role: IRole) {
    if (this.userRoles?.length > 0) {
      const exists = this.userRoles.find((x) => x.RoleId === role.Id);
      return !!exists;
    }
    return false;
  }

  ///////////
  // Save //
  /////////
  canSave() {
    let isValid = false;
    // Check if user info complete
    if (this.AuthUser && this.AuthUser.User) {
      isValid = !!(this.AuthUser.User.DisplayName && this.AuthUser.User.DisplayName.length > 0 &&
        this.AuthUser.User.FirstName && this.AuthUser.User.FirstName.length > 0 &&
        this.AuthUser.User.LastName && this.AuthUser.User.LastName.length > 0 &&
        this.AuthUser.User.CompanyId && this.AuthUser.User.CompanyId > 0 &&
        (this.userRoles.length > 0 || this.newUserRoles.length > 0)
        && this.AuthUser.UserCompanies.length > 0 &&
        this.usernameValid);
    }

    // Return result for button
    return isValid;
  }

  save() {
    if (this.canSave()) {
      this.loading = true;
      this.updateAuthUserRoles();
      this.authService.UpsertUser(this.AuthUser).subscribe({
        next: (data) => {
          if (data) {
            this.AuthUser = Object.assign({}, data);
            this.originalUser = JSON.parse(JSON.stringify(this.AuthUser));
            // get new roles and clear the newly added roles
            this.getUserRoles();
            this.newUserRoles = [];
          }
          this.loading = false;
          this.alertService.success('User data updated');
        },
        error: () => {
          this.loading = false;
          this.alertService.warn('Failed to update user data');
        }
      });
    } else {
      this.invalid = true;
    }
  }

  updateAuthUserRoles() {
    this.AuthUser.UserRoles = this.userRoles;
    this.newUserRoles.forEach(x => this.AuthUser.UserRoles.push(x));
  }

  close() {
    this.activeModal.close(false);
  }
}
