import { Component, NgZone, OnInit } from '@angular/core';
import {
  faBoxOpen,
  faCircleCheck,
  faPlus,
  faSyncAlt,
  faTrash,
  faUserEdit
} from '@fortawesome/free-solid-svg-icons';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { IActiveCompany } from '../../../services/models/member.model';
import { environment } from '../../../../environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CompanyService } from '../../../services/company.service';
import { ManageMemberModalComponent } from './manage-member-modal/manage-member-modal.component';

@Component({
  selector: 'app-member-management',
  templateUrl: './member-management.component.html',
  styleUrls: ['./member-management.component.scss']
})
export class MemberManagementComponent implements OnInit {
  // Icons
  faEdit = faUserEdit;
  faRefresh = faSyncAlt;
  faRemove = faTrash;
  faAdd = faPlus;
  faEmpty = faBoxOpen;
  faYes = faCircleCheck;
  faNo = faCircleXmark;

  // Company variables
  members: IActiveCompany[] = [];
  members$ = this.companyService.AllMembers$.asObservable();
  companyTypes$ = this.companyService.CompanyTypes$.asObservable();
  searchText: string = null;
  bcRef: string = null;
  companyType: string = null;
  now: Date = new Date();

  // General Variables
  loading: boolean = false;
  env = environment;

  constructor(private modalService: NgbModal,
              private zone: NgZone,
              private companyService: CompanyService) { }

  ngOnInit(): void {
    this.loading = true;
    this.companyService.LoadAllMembers();

    this.members$.subscribe((data) => {
      this.members = data;
      this.loading = false;
    });
  }

  getFilteredMembers() {
    return this.members.filter((x) =>
      (this.searchText == null || this.searchText.length === 0 || x.CompanyName.toLowerCase().includes(this.searchText.toLowerCase()))
      &&
      (this.companyType == null || x.CompanyType === this.companyType)
      &&
      (this.bcRef == null || this.bcRef.length === 0 || (x.BusinessCentralID != null && x.BusinessCentralID.toString().includes(this.bcRef.toString())))
    );
  }

  editMember(member: IActiveCompany) {
    this.zone.run(() => {
      // Open modal to create a new user
      const modalRef = this.modalService.open(ManageMemberModalComponent, {
        size: 'xl',
        windowClass: 'modal-3xl',
        backdrop: 'static'
      });
      modalRef.componentInstance.LoadMember(member.CompanyId);
      // On modal close, read the result and apply logic
      modalRef.result.then((result: boolean) => {
        if (result) {
          this.companyService.LoadAllMembers();
        }
      }, () => {
      });
    });
  }
}
