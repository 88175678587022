import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AlertService } from '../../../services/alert.service';
import { ICompany, ICompanyOverview, ICompanyUpdate } from '../../../services/models/member.model';
import { environment } from '../../../../environments/environment';
import { CompanyService } from '../../../services/company.service';
import { AdAuthService } from '../../../core/ad-auth-service/ad-auth.service';
import {
    faCircleQuestion, faUserEdit,
    faTrash,
    faCheckCircle, faEye
} from '@fortawesome/free-solid-svg-icons';
import { MapStyles } from '../../../core/constants/map-styles';
import { IUser, ICompanyUserDto } from '../../../services/models/user.model';
import { IAuthUser } from '../../../services/models/auth.model';

@Component({
  selector: 'app-member-profile',
  templateUrl: './member-profile.component.html',
  styleUrls: ['./member-profile.component.scss'],
  providers: [CompanyService]
})
export class MemberProfileComponent implements OnInit, OnDestroy, AfterViewChecked {
  // Icons
  faRemove = faTrash;
  faInfo = faCircleQuestion;
  faEdit = faUserEdit;
  faCheck = faCheckCircle;
  faTrash = faTrash;
  faView = faEye;

  // Component variables
  companyID: number;
  companyName: string;
  member: ICompany;
  memberUpdate: ICompanyUpdate;
  lastModifiedUser: IUser = null;
  users: ICompanyUserDto[] = [];
  // Map variables
  mapData: ICompanyOverview[] = [];
  mapStyles = MapStyles;
  centerLat: number = 15;
  centerLong: number = 0;
  // Image variables
  currentLogo: string;
  currentPicture: string;
  currentProud: string;
  placeholderImage: string = null;
  timestamp: string = null;

  // General variables
  loading: boolean = false;
  env = environment;

  constructor(public authService: AdAuthService,
              private alertService: AlertService,
              private companyService: CompanyService,
              private cdRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.placeholderImage = `${this.env.SiteUrls.AzureStorageBaseURL}${this.env.SiteUrls.ImagePlaceholderLargeUrl}`;
    this.timestamp = new Date().getTime().toString();

    if (this.authService.CurrentUser) {
      this.companyID = this.authService.CurrentUser.User.CompanyId;
      this.companyName = this.authService.CurrentUser.User.Company.Name;
      this.loadMember();
    } else {
      this.authService.CurrentUser$.subscribe((user: IAuthUser) => {
        this.companyID = user.User.CompanyId;
        this.companyName = user.User.Company.Name;
        this.loadMember();
      });
    }
  }

  ngOnDestroy() { }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  loadMember() {
    this.loading = true;

    this.companyService.getCompany(this.companyID).subscribe({
      next: (data: ICompany) => {
        this.member = Object.assign({}, data);
        this.loadCompanyUsers(this.companyID);
        this.setMemberData();
        this.loadLastModifiedUser();
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  setMemberData() {
    this.memberUpdate = {
      CompanyId: this.member.CompanyId,
      Biography: this.member.Biography,
      MemberEmail: this.getDetailValue('MemberEmail'),
      CompanyLogo: this.member.CompanyLogo,
      CompanyPicture: this.member.CompanyPicture,
      ProudMemberLogo: this.member.ProudMemberLogo,
      RateRequest: this.getDetailValue('RateRequest'),
      MemberTelephone: this.getDetailValue('MemberTelephone'),
      URL: this.getDetailValue('Url'),
      Longitude: this.member.Longitude,
      Latitude: this.member.Latitude,
      UserId: this.authService.CurrentUser.UserId
    } as ICompanyUpdate;

    // Set image data
    this.currentLogo = this.memberUpdate.CompanyLogo ? this.memberUpdate.CompanyLogo.substring(this.memberUpdate.CompanyLogo.lastIndexOf('/') + 1) : null;
    this.currentPicture = this.memberUpdate.CompanyPicture ? this.memberUpdate.CompanyPicture.substring(this.memberUpdate.CompanyPicture.lastIndexOf('/') + 1) : null;
    this.currentProud = this.memberUpdate.ProudMemberLogo ? this.memberUpdate.ProudMemberLogo.substring(this.memberUpdate.ProudMemberLogo.lastIndexOf('/') + 1) : null;

    // Set map data
    this.setMapData();
  }

  loadLastModifiedUser() {
    if (this.member.ModifiedByUserId && this.member.ModifiedByUserId) {
      this.authService.GetUserById(this.member.ModifiedByUserId).subscribe(data => {
        this.lastModifiedUser = Object.assign({}, data);
      });
    }
  }

  loadCompanyUsers(companyId: number) {
    this.loading = true;

    this.companyService.getCompanyUsers(companyId).subscribe({
      next: (data: ICompanyUserDto[]) => {
        this.users = Object.assign([], data);
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  getDetailValue(propertyName: string) {
    const exists = this.member.CompanyDetails.find((cd) => cd.CompanyDetailDefinition.PropertyName === propertyName);

    if (exists) {
      return exists.PropertyValue;
    } else {
      return null;
    }
  }

  // Company Logo
  onCompanyLogoChange(fileUrl: string) {
    this.memberUpdate.CompanyLogo = fileUrl;
    this.currentLogo = fileUrl;
    this.alertService.info('Company Logo successfully updated!');
    this.updateData();
  }

  onCompanyLogoDeleted() {
    this.memberUpdate.CompanyLogo = null;
    this.currentLogo = null;
    this.alertService.info('Company Logo successfully deleted!');
    this.updateData();
  }

  // Company Picture
  onCompanyPictureChange(fileUrl: string) {
    this.memberUpdate.CompanyPicture = fileUrl;
    this.currentPicture = fileUrl;
    this.alertService.info('Company Picture successfully updated!');
    this.updateData();
  }

  onCompanyPictureDeleted() {
    this.memberUpdate.CompanyPicture = null;
    this.currentPicture = null;
    this.alertService.info('Company Picture successfully deleted!');
    this.updateData();
  }

  getCompanyPicture() {
    if (this.memberUpdate && this.memberUpdate.CompanyPicture) {
      return this.memberUpdate.CompanyPicture;
    } else {
      return this.placeholderImage;
    }
  }

  // Proud Member Logo
  onProudMemberLogoChange(fileUrl: string) {
    this.memberUpdate.ProudMemberLogo = fileUrl;
    this.currentProud = fileUrl;
    this.alertService.info('Proud Member Logo successfully updated!');
    this.updateData();
  }

  onProudMemberLogoDeleted() {
    this.memberUpdate.ProudMemberLogo = null;
    this.currentProud = null;
    this.alertService.info('Proud Member Logo successfully deleted!');
    this.updateData();
  }

  updateData() {
    this.loading = true;
    if (this.companyID) {
      this.companyService.updateMemberProfile(this.memberUpdate).subscribe({
        next: (data) => {
          if (data) {
            this.loadMember();
            this.alertService.success('Member profile successfully updated!');
          }
          this.loading = false;
        },
        error: () => {
          this.alertService.warn('An error occurred while updating member info.');
          this.loading = false;
        }
      });
    }
  }

  // Map functions
  setMapData() {
    this.mapData = [];
    this.mapData.push({
      CompanyID: this.member.CompanyId,
      CompanyType: null,
      Name: this.member.Name,
      Country: this.member.CountryPhysical,
      Biography: this.memberUpdate.Biography,
      CompanyLogo: this.member.CompanyLogo,
      Latitude: this.memberUpdate.Latitude,
      Longitude: this.memberUpdate.Longitude,
      WebSite: this.memberUpdate.URL,
      MemberEmail: this.memberUpdate.MemberEmail,
      ContactNumber: this.memberUpdate.MemberTelephone,
      IsMovingServiceProvider: this.member.IsMovingServiceProvider,
      IsRelocationServiceProvider: this.member.IsRelocationServiceProvider
    } as ICompanyOverview);

    this.memberUpdate.Latitude = parseFloat(this.memberUpdate.Latitude.toString());
    this.memberUpdate.Longitude = parseFloat(this.memberUpdate.Longitude.toString());

    this.mapData[0].Latitude = this.memberUpdate.Latitude;
    this.mapData[0].Longitude = this.memberUpdate.Longitude;

    this.setCenter(this.memberUpdate.Latitude, this.memberUpdate.Longitude);
  }

  setCenter(lat: number, long: number) {
    this.centerLat = lat;
    this.centerLong = long;
  }
}
