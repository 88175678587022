<div class="modal-header">
  <h4 class="modal-title">
    Please Note!
  </h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss(null)">

  </button>
</div>
<div class="modal-body">
  <p class="harmony-grey-text">
    Once the rates are exported, no more changes or configuration of rate sources will be allowed.
  </p>
  <p class="harmony-grey-text">
    The rates can be exported again at any moment, but no further changes are permitted.
  </p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary harmony-purple-button" (click)="accept()">Accept</button>
  <button type="button" class="btn btn-primary harmony-teal-button" (click)="close()">Cancel</button>
</div>

