<div class="modal-header" xmlns="http://www.w3.org/1999/html">
  <h4 class="modal-title">
    {{ country && CountryId ? 'Edit State: ' + country.Name : 'Create New State' }}
  </h4>
</div>
<div class="modal-body">
  <ng-container *ngIf="country && !loading">
    <div class="row">
      <div class="col-4">
        <b>Name</b>
      </div>
      <div class="col-8">
        <input type="text"
               class="form-control form-control-sm"
               [ngClass]="{'is-invalid': !country.Name || country.Name.length <= 0}"
               [(ngModel)]="country.Name">
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-4">
        <b>ISO 2</b>
      </div>
      <div class="col-8">
        <input type="text"
               class="form-control form-control-sm"
               [ngClass]="{'is-invalid': !country.Iso2 || country.Iso2.length <= 0}"
               [(ngModel)]="country.Iso2">
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-4">
        <b>ISO 3</b>
      </div>
      <div class="col-8">
        <input type="text"
               class="form-control form-control-sm"
               [ngClass]="{'is-invalid': !country.Iso3 || country.Iso3.length <= 0}"
               [(ngModel)]="country.Iso3">
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-4">
        <b>Continent Code</b>
      </div>
      <div class="col-8">
        <input type="text"
               class="form-control form-control-sm"
               [ngClass]="{'is-invalid': !country.ContinentCode || country.ContinentCode.length <= 0}"
               [(ngModel)]="country.ContinentCode">
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-4">
        <b>EU Member</b>
      </div>
      <div class="col-8">
        <div class="form-check form-switch center-switch">
          <input class="form-check-input"
                 type="checkbox"
                 role="switch"
                 [id]="'switch-eu-member'"
                 [(ngModel)]="country.IsEu">
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!country && loading">
    <app-loader *ngIf="loading" class="ms-2" [Width]="40"></app-loader>
  </ng-container>
</div>
<div class="modal-footer">
  <app-loader *ngIf="loading" class="ms-2" [Width]="20"></app-loader>
  <button type="button" class="btn btn-primary harmony-tertiary-button" (click)="save()">Save</button>
  <button type="button" class="btn btn-primary harmony-primary-button" (click)="cancel()">Close</button>
</div>
