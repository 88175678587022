export const environment = {
  production: false,
  api_url: 'https://api.harmonyrelo.com/uat/api/',
  api_key_header: 'HRN-API-Subscription-Key',
  api_key: 'db091995e62c4e1883dd69ba0a6c31b8',
  website_url: 'https://uat.harmonyrelo.com',
  hangfire_url: 'https://hrn-api-uat.azurewebsites.net/hangfire/jobs/succeeded',
  reCaptchaSiteKey: '6LdJAv0ZAAAAAOIRJSgb-XoQkQvMlQGseEKvTqGO',
  azureSubscriptionKey: '16cb2fa8-0553-4ab3-9310-9ebbc088b1d2',
  ContainerNames: {
    ArticleImages: 'articleimages',
    ArticleAttachment: 'articleattachments',
    WebDocuments: 'webdocs',
    AnnualReviewTemplates: 'templates',
    UserProfilePictures: 'userporfilepictures',
    EmailAttachment: 'emailscheduleattachments',
    EmergencyPlans: 'emergencyplans',
    CompanyChart: 'companychart',
    WebImagesServices: 'webimages/services/'
  },
  Blobs: {
    EmergencyPlan: 'Emergency Plan Template.doc',
    AnnualReviewFAQ: 'ANNUAL REVIEW FAQ 2022.pdf',
    CovidPolicy: 'Covid-19 Compliance Code 2020.pdf',
    CodeOfConduct: 'Compliance, Code of Conduct, Harmony.pdf',
    ClientArticle: 'Relocation Services Harmony Winter 2019 2020.pdf',
  },
  FormattingStandards: {
    ShortDateFormat: 'dd MMM yyyy',
    LongDateFormat: 'dd MMM yyyy H:mm:ss'
  },
  SiteUrls: {
    AzureStorageBaseURL: 'https://sadevelopment1.blob.core.windows.net/',
    UserImagePlaceholderUrl: 'placeholders/user-placeholder.png',
    ImagePlaceholderUrl: 'placeholders/placeholder.png',
    ImagePlaceholderLargeUrl: 'placeholders/large-placeholder-image.jpg',
    NewsImagePlaceHolderUrl: 'placeholders/news-placeholder.jpg'
  },
  domain: 'portal-uat.harmonyrelo.com',
  Pricing: {
    TenderLocationLimit: 150,
    TenderEmail: 'info@harmonyrelo.com',
    TenderLocationFreightCapacity: 60,
  },
  TinyApiKey: '0j6mwb8fnfkkghkt5vqfdypwu73rdlj7qgkv1pc4gg4l4r7l',
  google_api_key: 'AIzaSyBpg3SxqxWbB8tOxTli3ZgjGA_T6v9sSPY',
  b2cPolicies: {
    applicationID: 'ce28832d-2cb7-4661-a55a-bd61ae39f029',
    redirectUrl: 'https://portal-uat.harmonyrelo.com',
    names: {
      signUpSignIn: 'b2c_1_sisu',
      forgotPassword: 'b2c_1_passwordreset',
      editProfile: 'b2c_1_edit_profile',
      signIn: 'b2c_1_signin'
    },
    authorities: {
      signUpSignIn: {
        authority: 'https://harmonyaddev.b2clogin.com/harmonyaddev.onmicrosoft.com/b2c_1_sisu',
      },
      signIn: {
        authority: 'https://harmonyaddev.b2clogin.com/harmonyaddev.onmicrosoft.com/b2c_1_signin',
      },
      forgotPassword: {
        authority: 'https://harmonyaddev.b2clogin.com/harmonyaddev.onmicrosoft.com/b2c_1_passwordreset',
      },
      editProfile: {
        authority: 'https://harmonyaddev.b2clogin.com/harmonyaddev.onmicrosoft.com/b2c_1_editprofile'
      },
      instanceUrl: 'https://harmonyaddev.onmicrosoft.com'
    },
    authorityDomain: 'harmonyaddev.b2clogin.com'
  },
  azureEndpoints: {
    // tslint:disable-next-line:max-line-length
    resubmitMessage: 'https://prod-111.westeurope.logic.azure.com:443/workflows/76fe5cf523b642ba88316d39c8ba8ee1/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=gv84Yflo5Vb713feXXAURspS81XUmfWt6klgrQct_PI'
  }
};
