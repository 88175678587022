
<div class="card-wrapper">
  <div class="card-content">
    <div class="card-header row">
      <div class="col-12">
        <!--Header-->
        <h5 class="harmony-primary-text fw-bold mt-2">Health & Safety</h5>
      </div>
    </div>
    <div class="card-body">
      <ng-container *ngIf="healthAndSafetyAssessment">
        <div class="row">
          <div class="col-12 label">
            You have an {{ healthAndSafetyAssessment.AssessmentStatusName }} assessment
          </div>
        </div>
        <div class="row pointer">
          <div class="col-12 fw-bold">
            <a class="harmony-secondary-link label" (click)="viewAssessment(healthAndSafetyAssessment?.Id)">
              {{ healthAndSafetyAssessment?.Name }}
            </a>
          </div>
        </div>
        <div class="row">
          <div class="col-4 label">Open IRL</div>
          <div class="col-2 fw-bold harmony-primary-text subject">{{ healthAndSafetyAssessment?.OpenIRL }}</div>
          <div class="col-4 label">Open RRL</div>
          <div class="col-2 fw-bold harmony-primary-text subject">{{ healthAndSafetyAssessment?.OpenRRL }}</div>
        </div>
      </ng-container>
      <ng-container *ngIf="!healthAndSafetyAssessment">
        <div class="row label">
          <div class="col-12">
            <small>There is currently no assessment</small>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

