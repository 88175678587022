<!---------------------->
<!------- Header ------->
<!---------------------->
<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom sticky-header">
  <h1 *ngIf="isNew" class="portal-component-header">
    Create a new article
  </h1>
  <h1 *ngIf="!isNew" class="portal-component-header">
    Edit article
  </h1>
  <div class="justify-content-end align-self-center">
    <small *ngIf="article.DateModified" class="harmony-grey-text pt-2 pe-1">
      Last saved: {{ article.DateModified ? (article.DateModified | date: environment.FormattingStandards.LongDateFormat) : '' }}
    </small>
    <button (click)="save()"
            *ngIf="!(loading || isPublished)"
            [title]="'Save article as a draft'"
            class="btn btn-sm btn-primary harmony-blue-outline-button me-1">
      <fa-icon [icon]="faSave" size="lg"></fa-icon>
      Save draft
    </button>
    <button (click)="updateArticle()"
            *ngIf="!loading && isPublished"
            [title]="'Save article as a draft'"
            class="btn btn-sm btn-primary harmony-blue-outline-button me-1">
      <fa-icon [icon]="faSave" size="lg"></fa-icon>
      Save
    </button>
    <button (click)="openSubmitModal()"
            *ngIf="!(loading || isPublished)"
            [title]="'Submit for approval to be published'"
            class="btn btn-sm btn-primary harmony-purple-outline-button me-1">
      <fa-icon [icon]="faStamp" size="lg"></fa-icon>
      Submit for review
    </button>
    <button (click)="returnToOverview()"
            [disabled]="loading"
            [title]="'Back to article management'" class="btn btn-sm btn-primary harmony-teal-outline-button me-1">
      Back
    </button>
  </div>
</div>
<app-loader *ngIf="loading"></app-loader>
<ng-container>
  <!---------------------->
  <!------ Article ------->
  <!---------------------->
  <div class="row">
    <div class="col-4">
      <div class="row">
        <div class="col-8 align-self-center">
          <h5 class="harmony-teal-text fw-bold mb-0">
            Cover Image
          </h5>
        </div>
        <div class="col-4 text-end" *ngIf="!isPublished">
          <button (click)="FileHandler.click()"
                  class="btn btn-sm btn-outline-primary harmony-blue-outline-button"
                  type="button"
                  [title]="'Upload a new cover image'">
            <fa-icon *ngIf="!article.ImageReference && !coverImage" [icon]="faUpload"></fa-icon>
            <fa-icon *ngIf="article.ImageReference || coverImage" [icon]="faChange"></fa-icon>
            {{ article.ImageReference || coverImage ? 'Change' : 'Upload' }}
          </button>
          <button (click)="coverImage = null;coverImageFile = null;"
                  *ngIf="!article.ImageReference && coverImage"
                  class="btn btn-sm btn-outline-danger ms-1"
                  type="button"
                  [title]="'Remove image'">
            <fa-icon [icon]="faDelete"></fa-icon>
          </button>
          <button (click)="removeImage()"
                  *ngIf="article.ImageReference && !coverImage"
                  class="btn btn-sm btn-outline-danger ms-1"
                  type="button"
                  [title]="'Remove image'">
            <fa-icon [icon]="faDelete"></fa-icon>
          </button>
          <input #FileHandler
                 (change)="selectFile($event)"
                 hidden
                 id="file"
                 multiple
                 type="file"/>
        </div>
      </div>
      <hr class="my-1">
      <div class="row mt-1">
        <div class="col-12">
          <div class="cover-image-canvas"
               [ngClass]="{'is-invalid': isPublishInvalid && article.ImageReference?.length < 1 }">
            <img [src]="imageBaseUrl + article.ArticleId + '/'+ article.ImageReference"
                 *ngIf="article.ImageReference && !coverImageFile"
                 alt="article-cover-image"
                 class="cover-image">
            <img [src]="coverImage"
                 *ngIf="!article.ImageReference && coverImage"
                 alt="article-cover-image"
                 class="cover-image">
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12 align-self-center">
          <h5 class="harmony-teal-text fw-bold mb-0">Settings</h5>
        </div>
      </div>
      <hr class="my-1">
      <div class="row mt-1">
        <div class="col-6 align-self-center">
          <b>Featured</b>
          <fa-icon [icon]="faInfo"
                   class="harmony-grey-text detail-info pointer ms-1"
                   [title]="'Featured articles will be shown on certain dashboards throughout the portal'"></fa-icon>
        </div>
        <div class="col-6">
          <div class="form-check form-switch form-switch-md">
            <input class="form-check-input pointer"
                   type="checkbox"
                   role="switch"
                   [disabled]="loading"
                   [id]="'IsArticleFeatured'"
                   [checked]="article.Featured"
                   (change)="setFeaturedStatus($event)">
          </div>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-6 align-self-center">
          <b>Show on website</b>
          <fa-icon [icon]="faInfo"
                   class="harmony-grey-text detail-info pointer ms-1"
                   [title]="'The article will be shown on the member profile page of your company in the Harmony corporate website. \n This will be publicly available to anyone who visits the Harmony website.'"></fa-icon>
        </div>
        <div class="col-6">
          <div class="form-check form-switch form-switch-md">
            <input class="form-check-input pointer"
                   type="checkbox"
                   role="switch"
                   [disabled]="loading"
                   [id]="'IsArticleForWebsite'"
                   [checked]="article.Website"
                   (change)="setWebsiteStatus($event)">
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-6 align-self-center">
          <b>Publish date</b>
          <fa-icon [icon]="faInfo"
                   class="harmony-grey-text detail-info pointer ms-1"
                   [title]="'Preferred date for when the article should be visible to other members'"></fa-icon>
        </div>
        <div class="col-6 text-start">
          <div class="form-check form-switch form-switch-md ps-0">
            <app-date-picker [identifier]="'publishDate'"
                             [disabled]="loading"
                             (onChange)="article.PublishDate = $event"
                             [value]="article.PublishDate"
                             [placeholder]="'Select a publish date'">
            </app-date-picker>
          </div>
        </div>
      </div>
      <div class="row mt-0">
        <div class="col-6 align-self-center">
          <b>Expire date</b>
          <fa-icon [icon]="faInfo"
                   class="harmony-grey-text detail-info pointer ms-1"
                   [title]="'Optional date for when the article should expire and no longer be visible to other members'"></fa-icon>
        </div>
        <div class="col-6 text-start">
          <div class="form-check form-switch form-switch-md ps-0">
            <app-date-picker [identifier]="'expiryDate'"
                             [disabled]="loading"
                             (onChange)="article.ExpireDate = $event"
                             [value]="article.ExpireDate"
                             [placeholder]="'Select an expiry date'">
            </app-date-picker>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-6 align-self-center">
          <h5 class="harmony-teal-text fw-bold mb-0">Attachments</h5>
        </div>
        <div class="col-6 align-self-center text-end">
          <button (click)="openAttachmentModal()"
                  *ngIf="!loading && !isPublished" [title]="'Manage attachments'"
                  class="btn btn-sm btn-primary harmony-blue-outline-button me-1">
            <fa-icon [icon]="faAttachment" size="lg"></fa-icon>
            Attachments ({{ article.TotalAttachments ?? 0 }})
          </button>
        </div>
      </div>
      <hr class="my-1">
      <ng-container *ngIf="attachments?.length <= 0">
        <div class="row">
          <div class="col-12">
            <small class="harmony-grey-text">Article has no attachments...</small>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="attachments?.length > 0">
        <div *ngFor="let a of attachments; let i = index" class="row mb-1">
          <div class="col-9 align-self-center">
            {{ a.Name }}
          </div>
          <div class="col-3">
            <button (click)="downloadAttachment(a.Reference)"
                    class="btn btn-sm btn-primary harmony-purple-outline-button border-0 float-end"
                    type="button" [title]="'Download Attachment'">
              <fa-icon [icon]="faDownload"></fa-icon>
            </button>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="col-8">
      <div class="row">
        <div class="col-12">
          <h5 class="harmony-teal-text fw-bold mb-0">Article</h5>
        </div>
      </div>
      <hr class="my-1">
      <div class="row mt-1">
        <div class="col-12">
          <textarea [(ngModel)]="article.Title" class="form-control form-control-sm" id="article-title"
                    [ngClass]="{'is-invalid': isSaveInvalid && article.Title?.length < 1 }"
                    [disabled]="isPublished" style="font-size: 1.5em;"
                    name="title"
                    [placeholder]="'Provide a title/headline for your article...'"
                    rows="2"></textarea>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-12">
          <ng-select *ngIf="article.Categories && (categories$ | async)"
                     [ngClass]="{'is-invalid': isPublishInvalid && article.Categories?.length < 1}"
                     [(ngModel)]="article.Categories"
                     [closeOnSelect]="false"
                     [items]="(categories$| async)"
                     [placeholder]="'Select categories that apply to your article...'"
                     [multiple]="true"
                     [disabled]="isPublished"
                     [searchable]="false"
                     [bindLabel]="'Name'">
          </ng-select>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <editor (onInit)="initTinymce($event)"
                  [(ngModel)]="article.HtmlContent"
                  [init]="editorConfig"
                  [disabled]="isPublished"
                  [ngClass]="{'is-invalid': isSaveInvalid && article?.HtmlContent?.length  < 1 }"
                  apiKey="{{environment.TinyApiKey}}"></editor>
          <div class="row mt-1" *ngIf="!isPublished">
            <div class="col-6 offset-3 text-center">
              <button (click)="preview()"
                      *ngIf="!isNew"
                      class="btn btn-sm btn-primary harmony-blue-outline-button w-100 me-1"
                      type="button">
                Preview
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

