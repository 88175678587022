<div class="row mt-3">
  <div class="col-12">
    <div class="row">
      <div class="col-3">
        <input type="text"
               class="form-control form-control-sm"
               [(ngModel)]="searchParam.ToAddress"
               [placeholder]="'Search a recipient email'">
      </div>
      <div class="col-3">
        <select class="form-select form-select-sm pointer"
                [disabled]="!(emailStatuses$ | async)"
                [(ngModel)]="searchParam.StatusID">
          <option [ngValue]="null" selected>Select a status...</option>
          <option *ngFor="let status of (emailStatuses$ | async)" [ngValue]="status.Id">{{ status.Status }}</option>
        </select>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-3">
        <select class="form-select form-select-sm pointer"
                [disabled]="!(emailTemplates$ | async)"
                [(ngModel)]="searchParam.TemplateID">
          <option [ngValue]="null" selected>Select a template...</option>
          <option *ngFor="let template of (emailTemplates$ | async)" [ngValue]="template.Id">{{ template.DisplayName }}</option>
        </select>
      </div>
      <div class="col-3">
        <select class="form-select form-select-sm pointer"
                [disabled]="!(emailSchedules$ | async)"
                [(ngModel)]="searchParam.ScheduleID">
          <option [ngValue]="null" selected>Select a schedule...</option>
          <option *ngFor="let schedule of (emailSchedules$ | async)" [ngValue]="schedule.Id">{{ schedule.Name }}</option>
        </select>
      </div>
    </div>
    <hr class="mt-1 mb-1">
    <div class="row mt-1 mb-1">
      <div class="col-3">
        <input type="text"
               class="form-control form-control-sm"
               [(ngModel)]="searchParam.EmailID"
               [placeholder]="'Search an email ID'">
      </div>
      <div class="col-1 offset-6 text-end pe-0">
        <button class="btn btn-sm btn-outline-primary harmony-primary-outline-button"
                (click)="resetSearch()">
          Reset
        </button>
      </div>
      <div class="col-2">
        <button class="btn btn-sm btn-primary harmony-tertiary-button w-100"
                (click)="searchEmails()">
          Search
        </button>
      </div>
    </div>
    <hr class="mt-1 mb-0">
  </div>
</div>
<!------------------->
<!---- Pagination --->
<!------------------->
<div class="row ms-1 me-1 mb-2">
  <div class="col-12">
    <app-pagination [showPageNumber]=true
                    [showRowsPerPage]=true
                    [paginationData]=paginationData
                    (pageEvent)="onPage($event)"
                    [name]="'EmailOverview'"
                    [page]="page">
    </app-pagination>
  </div>
</div>
<div class="row mt-2 header-row">
  <div class="col-3 header-title pointer border-split-left"
       (appOrder)="orderSearch($event)"
       data-column="Subject"
       data-order="asc">
    Subject
    <fa-icon [hidden]="orderParam.OrderBy != 'Subject'" class="ms-2 position-relative"></fa-icon>
  </div>
  <div class="col-1 header-title pointer text-center"
       (appOrder)="orderSearch($event)"
       data-column="EmailStatus.Status"
       data-order="asc">
    Status
    <fa-icon [hidden]="orderParam.OrderBy != 'EmailStatus.Status'" class="ms-2 position-relative"></fa-icon>
  </div>
  <div class="col-2 header-title pointer"
       (appOrder)="orderSearch($event)"
       data-column="ToAddress"
       data-order="asc">
    Recipient
    <fa-icon [hidden]="orderParam.OrderBy != 'ToAddress'" class="ms-2 position-relative"></fa-icon>
  </div>
  <div class="col-2 header-title pointer"
       (appOrder)="orderSearch($event)"
       data-column="EmailTemplate.Name"
       data-order="asc">
    Template
    <fa-icon [hidden]="orderParam.OrderBy != 'EmailTemplate.Name'" class="ms-2 position-relative"></fa-icon>
  </div>
  <div class="col-2 header-title pointer"
       (appOrder)="orderSearch($event)"
       data-column="ProcessDate"
       data-order="asc">
    Processed
    <fa-icon [hidden]="orderParam.OrderBy != 'ProcessDate'" [icon]="faSortDesc" style="bottom: -3px;" class="ms-2 position-relative"></fa-icon>
  </div>
  <div class="col-1 header-title pointer"
       (appOrder)="orderSearch($event)"
       data-column="EmailSchedule.Name"
       data-order="asc">
    Schedule
    <fa-icon [hidden]="orderParam.OrderBy != 'EmailSchedule.Name'" class="ms-2 position-relative"></fa-icon>
  </div>
  <div class="col-1 header-title border-split-right">

  </div>
</div>
<ng-container *ngIf="!loading && paginationData.DataSet.length > 0">
  <div class="row result-row" *ngFor='let email of paginationData.DataSet; let i = index'>
    <div class="col-3 overflow-hidden" [title]="email.Subject">
      <b><small>{{ email.Subject }}</small></b>
    </div>
    <div class="col-1 overflow-hidden text-center">
      <small>{{ email.EmailStatus ? email.EmailStatus.Status : '-' }}</small>
    </div>
    <div class="col-2 overflow-hidden" [title]="email.ToAddress">
      <small>{{ email.ToAddress }}</small>
    </div>
    <div class="col-2 overflow-hidden">
      <small>{{ email.EmailTemplate ? email.EmailTemplate.Name : '-' }}</small>
    </div>
    <div class="col-2 overflow-hidden">
      <small>{{ email.ProcessDate ? (email.ProcessDate | date: environment.FormattingStandards.LongDateFormat) : '-' }}</small>
    </div>
    <div class="col-1 overflow-hidden">
      <small>{{ email.EmailSchedule ? email.EmailSchedule.Name : '-' }}</small>
    </div>
    <div class="col-1 text-end pe-1">
      <button class="btn btn-sm btn-primary harmony-primary-outline-button"
              [title]="'View Email'"
              (click)="viewEmail(email.Id)">
        <fa-icon [icon]="faView" size="sm"></fa-icon>
      </button>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!loading && paginationData.DataSet.length <= 0">
  <!-- No Results -->
  <div class="row mt-4">
    <div class="col-12 text-center harmony-grey-text">
      <fa-icon [icon]="faEmpty" size="2x"></fa-icon>
    </div>
    <div class="col-12 text-center harmony-grey-text mt-1">
      <h4>No results...</h4>
    </div>
  </div>
</ng-container>
<!---------------------->
<!------- Loader ------->
<!---------------------->
<div class="row mt-4" *ngIf="loading">
  <div class="col-12 text-center">
    <app-loader [Width]="50"></app-loader>
  </div>
</div>
