<div id="services-accordion">
  <div class="card portal-menu-collapse-card">
    <a [routerLink]="['services/dashboard']">
      <div class="portal-menu-collapse-header bg-dark" id="headingServices" data-bs-toggle="collapse" data-bs-target="#collapseServices"
           aria-expanded="true" aria-controls="collapseServices">
        <h5 class="portal-menu-heading p-2 mb-0 text-muted">
          <span class="heading-icon icon-color">
            <fa-icon [icon]="faServices" [size]="'xs'"></fa-icon>
          </span>
          <span class="portal-menu-module-name"
                [ngClass]="{'harmony-primary-text fw-bold': router.url.includes('services/')}">
            Services
          </span>
        </h5>
      </div>
    </a>
    <div id="collapseServices" class="collapse" aria-labelledby="headingServices" data-bs-parent="#services-accordion">
      <div class="portal-menu-collapse-body">
        <nav class="col-md-12 d-none d-md-block ps-4">
          <ul class="nav flex-column mb-0">
            <li class="nav-item" *ngIf="serviceOverview">
              <a class="nav-link pointer"
                 [routerLink]="['services/dashboard']"
                 [ngClass]="{'active': router.url.endsWith('services/dashboard')}">
                <fa-icon class="link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Overview of Services
              </a>
            </li>
            <li class="nav-item" *ngIf="businessIntelligence">
              <a class="nav-link pointer"
                 [routerLink]="['services/business-intelligence']"
                 [ngClass]="{'active': router.url.endsWith('services/business-intelligence')}">
                <fa-icon class="link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Business Intelligence
              </a>
            </li>
            <li class="nav-item" *ngIf="clientServices">
              <a class="nav-link pointer"
                 [routerLink]="['services/client-services']"
                 [ngClass]="{'active': router.url.endsWith('services/client-services')}">
                <fa-icon class="link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Client Services
              </a>
            </li>
            <li class="nav-item" *ngIf="compliance">
              <a class="nav-link pointer"
                 [routerLink]="['services/compliance']"
                 [ngClass]="{'active': router.url.endsWith('services/compliance')}">
                <fa-icon class="link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Compliance Programme
              </a>
            </li>
            <li class="nav-item" *ngIf="corporateSales">
              <a class="nav-link pointer"
                 [routerLink]="['services/corporate-sales']"
                 [ngClass]="{'active': router.url.endsWith('services/corporate-sales')}">
                <fa-icon class="link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Corporate Sales Programme
              </a>
            </li>
            <li class="nav-item" *ngIf="customerSatisfaction">
              <a class="nav-link pointer"
                 [routerLink]="['services/customer-satisfaction']"
                 [ngClass]="{'active': router.url.endsWith('services/customer-satisfaction')}">
                <fa-icon class="link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Customer Satisfaction
              </a>
            </li>
            <li class="nav-item" *ngIf="euroTunnel">
              <a class="nav-link pointer"
                 [routerLink]="['services/eurotunnel']"
                 [ngClass]="{'active': router.url.endsWith('services/eurotunnel')}">
                <fa-icon class="link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Eurotunnel
              </a>
            </li>
            <li class="nav-item" *ngIf="insurance">
              <a class="nav-link pointer"
                 [routerLink]="['services/insurance']"
                 [ngClass]="{'active': router.url.endsWith('services/insurance')}">
                <fa-icon class="link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Insurance
              </a>
            </li>
            <li class="nav-item" *ngIf="integration">
              <a class="nav-link pointer"
                 [routerLink]="['services/integration']"
                 [ngClass]="{'active': router.url.endsWith('services/integration')}">
                <fa-icon class="link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Integration
              </a>
            </li>
            <li class="nav-item" *ngIf="marketing">
              <a class="nav-link pointer"
                 [routerLink]="['services/marketing']"
                 [ngClass]="{'active': router.url.endsWith('services/marketing')}">
                <fa-icon class="link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Marketing
              </a>
            </li>
            <li class="nav-item" *ngIf="netting">
              <a class="nav-link pointer"
                 [routerLink]="['services/netting']"
                 [ngClass]="{'active': router.url.endsWith('services/netting')}">
                <fa-icon class="link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Netting
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</div>
