<div class="modal-header">
  <h4 class="modal-title overflow-hidden">
    Email Subject: {{ emailContent ? emailContent.Subject : '-' }}
  </h4>
  <button type="button" class="btn-close" aria-label="Close" [disabled]="loading" (click)="close()"></button>
</div>
<div class="modal-body">
  <div class="row mt-2">
    <div class="col-5">
      <div class="row">
        <div class="col-12 harmony-primary-text">
          <b>Email Details</b>
        </div>
      </div>
      <hr class="mt-1 mb-1">
      <app-loader *ngIf="!emailContent"></app-loader>
      <ng-container *ngIf="emailContent">
        <div class="row mt-1">
          <div class="col-4">
            <b>Recipients</b>
          </div>
          <div class="col-8 overflow-auto">
            {{ emailContent.ToAddress }}
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-4">
            <b>Reply To</b>
          </div>
          <div class="col-8 overflow-auto">
            {{ emailContent.ReplyToAddress ?? '-' }}
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-4">
            <b>CC</b>
          </div>
          <div class="col-8 overflow-auto">
            {{ emailContent.CcAddress ?? '-' }}
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-4">
            <b>BCC</b>
          </div>
          <div class="col-8 overflow-auto">
            {{ emailContent.BccAddress ?? '-' }}
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-4">
            <b>Template</b>
          </div>
          <div class="col-8 overflow-auto">
            {{ emailContent.TemplateName ? emailContent.TemplateName : '-' }}
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-4">
            <b>Status</b>
          </div>
          <div class="col-8 overflow-auto">
            {{ emailContent.Status ? emailContent.Status : '-' }}
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-4">
            <b>Date Processed</b>
          </div>
          <div class="col-8 overflow-auto">
            {{ emailContent.ProcessDate | date:environment.FormattingStandards.LongDateFormat }}
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-4">
            <b>Schedule</b>
          </div>
          <div class="col-8 overflow-auto">
            {{ emailContent.ScheduleName ? emailContent.ScheduleName : '-' }}
          </div>
        </div>
      </ng-container>
      <div class="row mt-3">
        <div class="col-12 harmony-primary-text">
          <b>Attachments</b>
        </div>
      </div>
      <hr class="mt-1 mb-1">
      <ng-container *ngIf="emailContent && emailContent.Attachments && emailContent.Attachments.length > 0">
        <div class="row" *ngFor="let attachment of emailContent.Attachments">
          <div class="col-10">
            {{ attachment }}
          </div>
          <div class="col-2 text-end">
            <button class="btn btn-sm btn-outline-primary harmony-primary-outline-button"
                    (click)="downloadAttachment(emailContent.AttachmentFolder, attachment)">
              <fa-icon [icon]="faDownloadIcon" size="sm"></fa-icon>
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="emailContent && emailContent.TemplateAttachments && emailContent.TemplateAttachments.length > 0">
        <div class="row mt-1" *ngFor="let attachment of emailContent.TemplateAttachments">
          <div class="col-10">
            {{ attachment }}
          </div>
          <div class="col-2 text-end">
            <button class="btn btn-sm btn-outline-primary harmony-primary-outline-button"
                    (click)="downloadAttachment(emailContent.TemplateAttachmentFolder, attachment)">
              <fa-icon [icon]="faDownloadIcon" size="sm"></fa-icon>
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="emailContent && !emailContent.Attachments && !emailContent.TemplateAttachments">
        <div class="row">
          <div class="col-12">
            <small>No attachments.</small>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="col-7 border-start">
      <div class="row">
        <div class="col-12 harmony-primary-text">
          <b>Email Preview</b>
        </div>
      </div>
      <hr class="mt-1">
      <div class="row">
        <div class="col-12">
          <div class="email-wrapper" *ngIf="emailContent">
            <div class="p-2" [innerHTML]="emailBody"></div>
          </div>
          <app-loader *ngIf="!emailContent"></app-loader>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button"
          class="btn btn-primary harmony-primary-button"
          [disabled]="loading"
          (click)="close()">Close
  </button>
</div>
