<div class="member-profile-wrapper">
  <div class="member-profile-background"></div>
  <!-- NAVIGATION -->
  <div class="row">
    <div class="col-3">
      <div class="member-profile-menu-wrapper">
        <ng-container *ngIf="member">
          <div class="member-logo-wrapper">
            <img *ngIf="memberUpdate.CompanyLogo" [src]="memberUpdate.CompanyLogo" alt="member-logo" class="member-logo">
            <img *ngIf="!memberUpdate.CompanyLogo" [src]="placeholderImage" alt="member-logo" class="member-logo">
          </div>
          <div class="row mt-2">
            <div class="col-8 offset-2 text-center">
              <app-manage-image [Container]="'companyimages'"
                                [FilePrefix]="companyID.toString()"
                                [NewFileName]="companyName + '_logo'"
                                [ButtonTitle]="'Change Company Logo'"
                                [CurrentFileName]="currentLogo"
                                (OnChange)="onCompanyLogoChange($event)"
                                (OnDelete)="onCompanyLogoDeleted()"></app-manage-image>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-12">
              <div class="profile-header">
                <div class="row">
                  <div class="col-12 text-center">
                    <h5 class="harmony-blue-text fw-bold mb-0">
                      {{ member.Name }}
                    </h5>
                  </div>
                </div>
                <div class="row mt-1" *ngIf="member.AddressPhysical">
                  <div class="col-12 text-center harmony-grey-text">
                    <small class="mb-0">
                      {{ member.AddressPhysical?.length > 0 ? member.AddressPhysical.trim() : '-' }}{{ member.AddressPhysical2?.length > 0 ? ', ' + member.AddressPhysical2.trim() : '' }}{{ member.CityPhysical?.length > 0 ? ', ' + member.CityPhysical : '' }}{{ member.ZipCodePhysical?.length > 0 ? ', ' + member.ZipCodePhysical : '' }}
                    </small>
                  </div>
                </div>
                <div class="row mt-0" *ngIf="member && member.CountryPhysical">
                  <div class="col-12 text-center harmony-grey-text">
                    <small>
                      {{ member.CountryPhysical }} {{ member.CountryPhysicalIso ? ('(' + member.CountryPhysicalIso + ')') : '' }}
                    </small>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12 text-center">
                    <b>
                      {{ member.IsMovingServiceProvider == true ? 'Moving' : '' }}
                      {{ member.IsMovingServiceProvider == true && member.IsRelocationServiceProvider == true ? ' & ' : ''}}
                      {{ member.IsRelocationServiceProvider == true ? 'Relocation' : '' }}
                      provider
                    </b>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12">
              <div class="align-items-center">
                <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                  <button class="nav-link active" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">Profile</button>
                  <button class="nav-link" id="v-pills-address-tab" data-bs-toggle="pill" data-bs-target="#v-pills-address" type="button" role="tab" aria-controls="v-pills-address" aria-selected="true">Address Information</button>
                  <button class="nav-link" id="v-pills-certificates-tab" data-bs-toggle="pill" data-bs-target="#v-pills-certificates" type="button" role="tab" aria-controls="v-pills-certificates" aria-selected="false">Certifications</button>
                  <button class="nav-link" id="v-pills-branding-tab" data-bs-toggle="pill" data-bs-target="#v-pills-branding" type="button" role="tab" aria-controls="v-pills-branding" aria-selected="false">Branding</button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="!member">
          <app-loader [Width]="50"></app-loader>
        </ng-container>
      </div>
    </div>
    <div class="col-9">
      <!-- CONTENT -->
      <div class="member-profile-content-wrapper">
        <div class="tab-content" id="v-pills-tabContent">
          <!-- PROFILE -->
          <div class="tab-pane fade show active" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
            <ng-container *ngIf="memberUpdate">
              <div class="row mt-3">
                <div class="col-12">
                  <p class="content-header mb-0">Company Profile</p>
                  <hr class="mb-2 mt-0">
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-12">
                  <textarea class="form-control form-control-sm"
                            [rows]="10"
                            [maxlength]="4000"
                            [(ngModel)]="memberUpdate.Biography">
                  </textarea>
                  <small class="harmony-grey-text">Length: {{memberUpdate.Biography.length}}/4000</small>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12">
                  <p class="content-header mb-0">Contact Information</p>
                  <hr class="mb-2 mt-0">
                </div>
              </div>
              <div class="row mt-1">
                <div class="col-2">
                  <b>
                    Telephone
                  </b>
                </div>
                <div class="col-10">
                  <app-mobile-number [Number]="memberUpdate.MemberTelephone"
                                     (OnChange)="memberUpdate.MemberTelephone = $event">
                  </app-mobile-number>
                </div>
              </div>
              <div class="row mt-1">
                <div class="col-2">
                  <b class="fw-bold">
                    Email
                  </b>
                </div>
                <div class="col-10">
                  <input type="text"
                         [(ngModel)]="memberUpdate.MemberEmail"
                         class="form-control form-control-sm">
                </div>
              </div>
              <div class="row mt-1">
                <div class="col-2">
                  <b class="fw-bold">
                    Rate Request
                  </b>
                </div>
                <div class="col-10">
                  <input type="text"
                         [(ngModel)]="memberUpdate.RateRequest"
                         class="form-control form-control-sm">
                </div>
              </div>
              <div class="row mt-1 mb-5">
                <div class="col-2">
                  <b>
                    Website
                  </b>
                </div>
                <div class="col-10">
                  <input type="text"
                         [(ngModel)]="memberUpdate.URL"
                         class="form-control form-control-sm">
                </div>
              </div>
              <div class="row mt-5">
                <div class="col-4 offset-4">
                  <button type="button"
                          (click)="updateData()"
                          class="btn btn-sm btn-primary harmony-purple-button w-100">
                    Save
                  </button>
                </div>
              </div>
              <div class="row" *ngIf="lastModifiedUser">
                <div class="col-6 offset-3 text-center harmony-grey-text">
                  <small>
                    Last updated by {{ lastModifiedUser.DisplayName + ' (' + (member.ModifiedOnDate | date: env.FormattingStandards.ShortDateFormat) + ')' }}
                  </small>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="!memberUpdate">
              <app-loader [Width]="50"></app-loader>
            </ng-container>
          </div>
          <!-- ADDRESS INFORMATION -->
          <div class="tab-pane fade" id="v-pills-address" role="tabpanel" aria-labelledby="v-pills-address-tab">
            <div class="row mt-3">
              <div class="col-12">
                <p class="content-header mb-0">Address Information</p>
                <hr class="mb-2 mt-0">
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-6">
                <div class="row mt-1">
                  <div class="col-12">
                    <b>Physical</b>
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-12">
                    <small>
                      <p class="mb-0">
                        {{ member?.AddressPhysical?.length > 0 ? member?.AddressPhysical.trim() : '-' }}{{ member?.AddressPhysical2?.length > 0 ? ', ' + member?.AddressPhysical2.trim() : '' }}
                      </p>
                      <p class="mb-0">
                        {{ member?.CityPhysical?.length > 0 ? member?.CityPhysical : '' }}{{ member?.ZipCodePhysical?.length > 0 ? ', ' + member?.ZipCodePhysical : '' }}
                      </p>
                      <p class="mb-0">
                        {{ member?.CountryPhysical ?? ''}} {{ member?.CountryPhysicalIso ? '(' + member?.CountryPhysicalIso + ')' : '' }}
                      </p>
                    </small>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="row mt-1">
                  <div class="col-12">
                    <b>Postal</b>
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-12">
                    <small>
                      <p class="mb-0">
                        {{ member?.AddressPostal?.length > 0 ? member?.AddressPostal.trim() : '-' }}{{ member?.AddressPostal2?.length > 0 ? ', ' + member?.AddressPostal2.trim() : '' }}
                      </p>
                      <p class="mb-0">
                        {{ member?.CityPostal?.length > 0 ? member?.CityPostal : '' }}{{ member?.ZipCodePostal?.length > 0 ? ', ' + member?.ZipCodePostal : '' }}
                      </p>
                      <p class="mb-0">
                        {{ member?.CountryPostal ?? '' }} {{ member?.CountryPostalIso ? '(' + member?.CountryPostalIso + ')' : '' }}
                      </p>
                    </small>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3" *ngIf="memberUpdate">
              <div class="col-2 pt-1">
                <b>Longitude</b>
              </div>
              <div class="col-4">
                <input type="text"
                       numbersOnly="3.6"
                       class="form-control form-control-sm"
                       [(ngModel)]="memberUpdate.Longitude"
                       (change)="setMapData()">
              </div>
            </div>
            <div class="row mt-1" *ngIf="memberUpdate">
              <div class="col-2 pt-1">
                <b>Latitude</b>
              </div>
              <div class="col-4">
                <input type="text"
                       numbersOnly="3.6"
                       class="form-control form-control-sm"
                       [(ngModel)]="memberUpdate.Latitude"
                       (change)="setMapData()">
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-4 offset-4">
                <button type="button"
                        (click)="updateData()"
                        class="btn btn-sm btn-primary harmony-purple-button w-100">
                  Save
                </button>
              </div>
            </div>
            <div class="row" *ngIf="lastModifiedUser">
              <div class="col-6 offset-3 text-center harmony-grey-text">
                <small>
                  Last updated by {{ lastModifiedUser.DisplayName + ' (' + (member.ModifiedOnDate | date: env.FormattingStandards.ShortDateFormat) + ')' }}
                </small>
              </div>
            </div>
            <hr>
            <div class="row mt-4">
              <div class="col-12 member-profile-map">
                <app-member-map [CompanyData]="mapData"
                                id="map"
                                [Zoom]="4"
                                [MinZoom]="4"
                                [Center]="{ lat: centerLat, lng: centerLong }"
                                [MapStyle]="mapStyles.MemberMap"></app-member-map>
              </div>
            </div>
          </div>
          <!-- CERTIFICATIONS -->
          <div class="tab-pane fade" id="v-pills-certificates" role="tabpanel" aria-labelledby="v-pills-certificates-tab">
            <div class="row mt-3">
              <div class="col-12">
                <p class="content-header mb-0">Certifications</p>
                <hr class="mb-2 mt-0">
                <ng-container *ngIf="member">
                  <div class="row mt-2 ps-0 pe-0">
                    <div class="col-12">
                      <app-document-list [CategoryCode]="'MMBRCERT'"
                                         [CompanyId]="companyID"
                                         [NoDocumentMessage]="'No certificates found...'"
                      ></app-document-list>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
          <!-- BRANDING -->
          <div class="tab-pane fade" id="v-pills-branding" role="tabpanel" aria-labelledby="v-pills-branding-tab">
            <div class="row mt-3">
              <div class="col-12">
                <p class="content-header mb-0">Branding</p>
                <hr class="mb-2 mt-0">
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-12">
                <div class="member-branding-wrapper align-self-center">
                  <div class="member-picture-clip"
                       [ngStyle]="{'background-image': 'url(\'' + getCompanyPicture() + '\')' }">
                  </div>
                  <div class="proud-member" *ngIf="memberUpdate">
                    <img *ngIf="memberUpdate.ProudMemberLogo !== null && memberUpdate.ProudMemberLogo.length > 1"
                         [src]="memberUpdate.ProudMemberLogo"
                         class="proud-member-logo"
                         [alt]="memberUpdate.CompanyId">
                    <img *ngIf="memberUpdate.ProudMemberLogo == null || memberUpdate.ProudMemberLogo.length <= 0"
                         [src]="placeholderImage"
                         class="proud-member-logo"
                         [alt]="memberUpdate.CompanyId">
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12 text-center position-relative">
                <div class="edit-member-picture">
                  <app-manage-image [Container]="'companyimages'"
                                    [FilePrefix]="companyID?.toString()"
                                    [NewFileName]="companyName + '_picture' + timestamp"
                                    [ButtonTitle]="'Change Company Picture'"
                                    [CurrentFileName]="currentPicture"
                                    (OnChange)="onCompanyPictureChange($event)"
                                    (OnDelete)="onCompanyPictureDeleted()"></app-manage-image>
                </div>
                <div class="edit-member-proud-logo">
                  <app-manage-image [Container]="'companyimages'"
                                    [FilePrefix]="companyID?.toString()"
                                    [NewFileName]="companyName + '_proudMember' + timestamp"
                                    [ButtonTitle]="'Change Proud Member Logo'"
                                    [CurrentFileName]="currentProud"
                                    (OnChange)="onProudMemberLogoChange($event)"
                                    (OnDelete)="onProudMemberLogoDeleted()"></app-manage-image>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
