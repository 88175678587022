import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  faPlus,
  faTrash,
  faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import {
  IRateGroup, IRateGroupCheckResult,
  IRateGroupInclusion,
  IRateGroupServiceDto
} from '../../../../services/models/pricing/rates.model';
import { PricingService } from '../../../../services/pricing.service';
import { AlertService } from '../../../../services/alert.service';
import { ApiService } from '../../../../services/api.service';
import { OpenConfirmationModal } from '../../../../shared/components/confirmation-modal/confirmation-modal-functions';

@Component({
  selector: 'app-rate-group-capture-modal',
  templateUrl: './rate-group-capture-modal.component.html',
  styleUrls: ['./rate-group-capture-modal.component.scss'],
  providers: [DatePipe]
})
export class RateGroupCaptureModalComponent implements OnInit, OnDestroy {
  // Icons
  protected readonly faPlus = faPlus;
  protected readonly faDelete = faTrash;
  protected readonly faInfo = faCircleQuestion;
  protected readonly faRequired = faTriangleExclamation;

  // Component variables
  public RateGroupId: number;
  public CanEdit: boolean = true;
  public CanEditDate: boolean = false;
  rateGroup: IRateGroup = null;
  rateGroupInclusions: IRateGroupInclusion[] = [];
  rgCheckResult: IRateGroupCheckResult[] = [];
  disableOriginPort: boolean = false;
  disableDestinationPort: boolean = false;
  today: any = null;
  minValidTo: any = null;

  // Lookup data
  Currencies$ = this.pricingService.Currencies$.asObservable();

  // General variables
  loading: boolean = false;
  private unsubscribe: Subject<any> = new Subject<any>();
  environment = environment;

  constructor(public activeModal: NgbActiveModal,
              public pricingService: PricingService,
              private alertService: AlertService,
              private api: ApiService,
              private modalService: NgbModal) {
  }

  ngOnInit() {
    const now = new Date();
    this.today = {
      year: now.getFullYear(),
      month: now.getMonth() + 1,
      day: now.getDate()
    } as any;
  }

  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  public LoadRateGroup() {
    this.loading = true;

    this.api.get(`RateGroup/Single/${this.RateGroupId}`).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data) => {
        if (data) {
          this.rateGroup = Object.assign({} as IRateGroup, data);
          this.rateGroupInclusions = this.rateGroup.RateGroupInclusions;
          this.rateGroup.RateGroupInclusions = null;
          // Check the RG setup for any issues/comments
          this.checkRateGroup();
          this.changeValidFromDate(this.rateGroup.ValidFrom);
          // Enable/Disable ports according to request type
          this.disableOriginPort = (
            this.rateGroup.ModalityTypeID === null ||
            this.rateGroup.ModalityTypeID <= 0 ||
            this.rateGroup.ModalityTypeID > 3 ||
            (this.rateGroup.RateRequestTypeName != null && !this.rateGroup.RateRequestTypeName.includes('Origin'))
          );
          this.disableDestinationPort = (
            this.rateGroup.ModalityTypeID === null ||
            this.rateGroup.ModalityTypeID <= 0 ||
            this.rateGroup.ModalityTypeID > 3 ||
            (this.rateGroup.RateRequestTypeName != null && !this.rateGroup.RateRequestTypeName.includes('Destination'))
          );
        }
        this.loading = false;
      },
      error: () => {
        this.alertService.error('Unable to retrieve data for rate group ID: ' + this.rateGroup.ID);
        this.loading = false;
      }
    });
  }

  checkRateGroup() {
    this.loading = true;

    this.pricingService.CheckRateGroup(this.rateGroup.ID).subscribe({
      next: (data: IRateGroupCheckResult[]) => {
        if (data && data.length > 0) {
          this.rgCheckResult = Object.assign([], data);
          this.rgCheckResult.forEach(result => {
            const rgs = this.rateGroup.RateGroupServices.find(x => x.ID === result.RateGroupServiceId);
            rgs.Comment = rgs.Comment ?? '';
            rgs.Comment += result.Comment + '\n';
          });
          this.alertService.warn('Validation concerns spotted, please review rates.');
        }
        this.loading = false;
      },
      error: () => {
        this.alertService.error('Unable to update data for rate group ID: ' + this.rateGroup.ID);
        this.loading = false;
      }
    });
  }

  changeValidFromDate(date: string) {
    this.rateGroup.ValidFrom = date;
    const fromDate = new Date(date);
    this.minValidTo = {
      year: fromDate.getFullYear(),
      month: fromDate.getMonth() + 1,
      day: fromDate.getDate() + 1
    } as any;
  }

  hasRates(rgs: IRateGroupServiceDto) {
    if (rgs.ServiceRates && rgs.ServiceRates.length > 0) {
      const hasRates = rgs.ServiceRates.find(r => r.Rate > 0);
      return !!hasRates;
    }
    return true;
  }

  isMinimumRateProvided() {
    let result: boolean = false;

    if (this.rateGroup.RateGroupServices) {
      const hasRates = this.rateGroup.RateGroupServices.filter((rgs) =>
        rgs.ServiceRates.filter((sr) => sr.Rate > 0).length > 0
      );
      result = (hasRates && hasRates.length > 0);
    }
    return result;
  }

  getRateLimitTypeName(rateLimitTypeId: number) {
    switch (rateLimitTypeId) {
      case 1:
        return 'No minimum or maximum';
      case 2:
        return 'Minimum';
      case 3:
        return 'Maximum';
      default:
        return '-';
    }
  }

  setAllCurrencies(currencyID: number) {
    const message = 'Do you want to set this for currency for all rates?';
    OpenConfirmationModal(this.modalService, message)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((answer: boolean) => {
        if (answer) {
          this.rateGroup.RateGroupServices.forEach(service => {
            service.ServiceRates.forEach(rate => {
              rate.CurrencyID = currencyID;
            });
          });
        }
      });
  }

  disableSave() {
    let result: boolean;

    if (this.rateGroup && this.rateGroup.RateGroupServices) {
      const invalidRgs = this.rateGroup.RateGroupServices.filter((rgs) => rgs.ServiceID == null);
      const invalidSr = this.rateGroup.RateGroupServices.filter((rgs) =>
        rgs.ServiceRates.filter((sr) => sr.RateLimitTypeID == null || sr.Rate == null || sr.Rate.toString().length === 0 || sr.CurrencyID == null).length > 0
      );
      result = (this.loading || (invalidRgs && invalidRgs.length > 0) || (invalidSr && invalidSr.length > 0));
    } else {
      result = true;
    }

    return result;
  }

  close() {
    this.activeModal.close(this.rateGroup);
  }

  accept() {
    if (this.isMinimumRateProvided()) {
      const approved = this.pricingService.RateVerdicts$.getValue().find(x => x.Name === 'Approved');
      if (this.CanEditDate === false && this.rateGroup.RateVerdictID !== approved.Id) {
        const readyForSubmission = this.pricingService.RateVerdicts$.getValue().find(x => x.Name === 'Ready for Submission');
        this.rateGroup.RateVerdictID = readyForSubmission?.Id;
      }
    } else {
      const noRates = this.pricingService.RateVerdicts$.getValue().find(x => x.Name === '0.00 Rate');
      this.rateGroup.RateVerdictID = noRates?.Id;
    }
    this.applyUpdates();
  }

  applyUpdates() {
    this.loading = true;
    this.pricingService.UpsertRateGroup(this.rateGroup).subscribe({
      next: (data) => {
        if (data) {
          this.rateGroup = Object.assign({}, data);
          this.alertService.success('Your rates were successfully saved!');
          // Check the RG setup for any issues/comments
          this.checkRateGroup();
        }
        this.loading = false;
      },
      error: () => {
        this.alertService.error('An error has occurred an we were unable to save your rates.');
        this.loading = false;
      }
    });
  }
}
