<div class="modal-header">
  <h4 class="modal-title" id="rategroup-edit-modal-title">
    {{ (requestTemplate && requestTemplate.Id > 0) ? 'Managing Rate Request template' : 'Create a new Rate Request template' }}
  </h4>
  <button type="button" class="btn-close" aria-label="Close" [disabled]="loading" (click)="activeModal.dismiss('Dismiss')">

  </button>
</div>
<div class="modal-body ps-4 pe-4">
  <app-loader *ngIf="!requestTemplate" [Width]="25"></app-loader>
  <div class="row">
    <div class="col-12">
      <h5 class="harmony-primary-text fw-bold mb-0">
        Details
      </h5>
    </div>
  </div>
  <hr class="my-1">
  <div class="row" *ngIf="requestTemplate">
    <div class="col-6">
      <div class="row mt-1">
        <div class="col-4">
          <b>Modality Type</b>
        </div>
        <div class="col-8 overflow-hidden harmony-grey-text">
          <select class="form-select form-select-sm pointer"
                  [disabled]="!(ModalityTypes$ | async)"
                  [(ngModel)]="requestTemplate.ModalityTypeId">
            <option [ngValue]="null" selected>None...</option>
            <option *ngFor="let modalityType of (ModalityTypes$ | async)" [ngValue]="modalityType.Id">{{ modalityType.Name }}</option>
          </select>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-4">
          <b>Project</b>
        </div>
        <div class="col-8 overflow-hidden harmony-grey-text">
          <select class="form-select form-select-sm pointer"
                  [disabled]="!(Projects$ | async)"
                  [ngClass]="{'is-invalid': projectId == null}"
                  [(ngModel)]="projectId">
            <option [ngValue]="null" selected>Select a project...</option>
            <option *ngFor="let project of (Projects$ | async)" [ngValue]="project.Id">{{ project.Name }}</option>
          </select>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-4">
          <b>Request Type</b>
        </div>
        <div class="col-8 overflow-hidden harmony-grey-text">
          <select class="form-select form-select-sm pointer"
                  [disabled]="!projectId || !(rateRequestTypes$ | async)"
                  [ngClass]="{'is-invalid': requestTemplate.RateRequestTypeId == null}"
                  [(ngModel)]="requestTemplate.RateRequestTypeId">
            <option [ngValue]="null" selected>Select a request type...</option>
            <option *ngFor="let rateRequestType of (rateRequestTypes$ | async)"
                    [hidden]="rateRequestType.ProjectId != projectId"
                    [ngValue]="rateRequestType.Id">
              {{ rateRequestType.Name }}
            </option>
          </select>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-4">
          <b>Rate Source</b>
        </div>
        <div class="col-8 overflow-hidden harmony-grey-text">
          <select class="form-select form-select-sm pointer"
                  [disabled]="!(RateSources$ | async)"
                  [ngClass]="{'is-invalid': requestTemplate.RateSourceId == null}"
                  [(ngModel)]="requestTemplate.RateSourceId">
            <option [ngValue]="null" selected>Select a rate source...</option>
            <option *ngFor="let rateSource of (RateSources$ | async)" [ngValue]="rateSource.Id">{{ rateSource.Name }}</option>
          </select>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-4">
          <b>Description</b>
        </div>
        <div class="col-8 overflow-hidden harmony-grey-text">
          <input type="text"
                 class="form-control form-control-sm"
                 [(ngModel)]="requestTemplate.Description"
                 [ngClass]="{'is-invalid': requestTemplate.Description == null || requestTemplate.Description.length <= 0}">
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="row mt-1">
        <div class="col-4">
          <b>Buy/Sell Type</b>
        </div>
        <div class="col-8 overflow-hidden harmony-grey-text">
          <select class="form-select form-select-sm pointer"
                  [ngClass]="{'is-invalid': requestTemplate.RateBuySellTypeId == null}"
                  [(ngModel)]="requestTemplate.RateBuySellTypeId">
            <option [ngValue]="null" selected>Select a buy/sell type...</option>
            <option [ngValue]="0">Buy</option>
            <option [ngValue]="1">Sell</option>
          </select>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-4">
          <b>Is Origin</b>
        </div>
        <div class="col-8 overflow-hidden harmony-grey-text">
          <div class="form-check form-switch form-switch-md float-start">
            <input class="form-check-input pointer"
                   type="checkbox"
                   role="switch"
                   [id]="'is-origin'"
                   [title]="'Has origin location'"
                   [(ngModel)]="requestTemplate.IsOrigin">
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-4">
          <b>Is Destination</b>
        </div>
        <div class="col-8 overflow-hidden harmony-grey-text">
          <div class="form-check form-switch form-switch-md float-start">
            <input class="form-check-input pointer"
                   type="checkbox"
                   role="switch"
                   [id]="'is-destination'"
                   [title]="'Has destination location'"
                   [(ngModel)]="requestTemplate.IsDestination">
          </div>
        </div>
      </div>
      <hr class="my-2">
      <div class="row mt-1">
        <div class="col-4">
          <b>Default Rate Verdict</b>
        </div>
        <div class="col-8 overflow-hidden">
          <select class="form-select form-select-sm pointer"
                  [disabled]="!(RateVerdicts$ | async)"
                  [(ngModel)]="requestTemplate.RateVerdictId">
            <option [ngValue]="null">None...</option>
            <option *ngFor="let verdict of (RateVerdicts$ | async)" [ngValue]="verdict.Id">{{ verdict.Name }}</option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-4" *ngIf="requestTemplate">
    <div class="col-12">
      <ul class="nav nav-tabs" id="capture-rates" role="tablist">
        <li class="nav-item" role="presentation">
          <a class="nav-link active"
             id="service-rates-tab"
             data-bs-toggle="tab"
             href="#service-rates"
             role="tab"
             aria-controls="service-rates"
             aria-selected="true">
            Services & Rates
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a class="nav-link"
             id="inclusions-tab"
             data-bs-toggle="tab"
             href="#inclusions"
             role="tab"
             aria-controls="inclusions"
             aria-selected="false">
            Inclusions & Exclusions
          </a>
        </li>
      </ul>
      <div class="tab-content harmony-grey-text mt-2" id="capture-rates-content">
        <!----------------------------------->
        <!------- Services & Rates ---------->
        <!----------------------------------->
        <div class="tab-pane fade show active" id="service-rates" role="tabpanel" aria-labelledby="service-rates-tab">
          <div class="row mt-1 header-row">
            <div class="col-4 header-title border-split-left">
              Service Type
            </div>
            <div class="col-3 header-title">
              Calculation Method
            </div>
            <div class="col-3 header-title">

            </div>
            <div class="col-2 header-title-small text-end pt-2 pe-3 border-split-right">
              <fa-icon [icon]="faPlus"
                       class="text-white pointer"
                       [title]="'Add new service'"
                       (click)="newRateGroupService()"
                       size="lg"></fa-icon>
            </div>
          </div>
          <div class="row result-row" *ngFor="let rgs of requestTemplate.RateRequestTemplateServices; let i = index">
            <div class="col-12">
              <div class="row">
                <div class="col-4 overflow-hidden col-compress">
                  <select class="form-select form-select-sm pointer"
                          [disabled]="!(ServiceTypes$ | async) || rgs.Active === false"
                          [ngClass]="{'is-invalid': rgs.ServiceTypeId == null}"
                          [(ngModel)]="rgs.ServiceTypeId">
                    <option [ngValue]="null">None...</option>
                    <option *ngFor="let serviceType of (ServiceTypes$ | async)" [ngValue]="serviceType.Id">{{ serviceType.Name }}</option>
                  </select>
                </div>
                <div class="col-3 overflow-hidden col-compress">
                  <select class="form-select form-select-sm pointer"
                          [disabled]="!(CalculationMethods$ | async) || rgs.Active === false"
                          [ngClass]="{'is-invalid': rgs.CalculationMethodId == null}"
                          [(ngModel)]="rgs.CalculationMethodId">
                    <option [ngValue]="null">None...</option>
                    <option *ngFor="let calcMethod of (CalculationMethods$ | async)" [ngValue]="calcMethod.Id">{{ calcMethod.Name }}</option>
                  </select>
                </div>
                <div class="col-3 overflow-hidden col-compress">

                </div>
                <div class="col-2 text-end col-compress">
                  <button class="btn btn-sm btn-primary harmony-accent-blue-grey-outline-button w-50"
                          type="button"
                          data-bs-toggle="collapse"
                          [disabled]="rgs.Active === false"
                          (click)="toggleActiveRateGroupService(rgs.Id)"
                          [ngClass]="{'harmony-primary-button': activeRateGroupServiceId === rgs.Id, 'harmony-primary-outline-button': activeRateGroupServiceId !== rgs.Id}"
                          [title]="'RateGroupService ID: ' + rgs.Id"
                          [attr.data-bs-target]="'#RGS_' + rgs.Id"
                          [attr.aria-expanded]="false"
                          [attr.aria-controls]="'RGS_' + rgs.Id">
                    {{ rgs.RateRequestTemplateServiceRates ? rgs.RateRequestTemplateServiceRates.length : 0 }} Rates
                  </button>
                  <button type="button"
                          class="btn btn-sm btn-outline-success ms-1"
                          *ngIf="rgs.Active === false"
                          [title]="'Activate service'"
                          (click)="enableTemplateService(rgs)">
                    <fa-icon [icon]="faPlus" size="sm"></fa-icon>
                  </button>
                  <button type="button"
                          class="btn btn-sm btn-outline-danger ms-1"
                          *ngIf="rgs.Active === true"
                          [title]="'De-activate service'"
                          (click)="removeTemplateService(rgs)">
                    <fa-icon [icon]="faDelete" size="sm"></fa-icon>
                  </button>
                </div>
              </div>
              <!------------------->
              <!-- Service Rates -->
              <!------------------->
              <div class="row">
                <div class="col-12 ps-0 pe-0">
                  <div class="collapse multi-collapse ps-0 pe-0 mt-1 mb-2" [id]="'RGS_' + rgs.Id">
                    <div class="card card-body p-1">
                      <div class="row m-0 header-row sub-result-row">
                        <div class="col-2 header-title-small">
                          <small>Limit Type</small>
                        </div>
                        <div class="col-1 header-title-small">
                          <small>Quantity 1</small>
                        </div>
                        <div class="col-1 header-title-small">
                          <small>Unit Type 1</small>
                        </div>
                        <div class="col-2 header-title-small">
                          <small>Measurement Type 1</small>
                        </div>
                        <div class="col-1 header-title-small">
                          <small>Quantity 2</small>
                        </div>
                        <div class="col-1 header-title-small">
                          <small>Unit Type 2</small>
                        </div>
                        <div class="col-2 header-title-small">
                          <small>Measurement Type 2</small>
                        </div>
                        <div class="col-1 header-title-small">
                          <small>Currency</small>
                        </div>
                        <div class="col-1 header-title-small text-end pt-1 pe-3">
                          <fa-icon [icon]="faPlus"
                                   class="text-white pointer"
                                   [title]="'Add new rate'"
                                   (click)="newServiceRate(rgs)"
                                   size="sm"></fa-icon>
                        </div>
                      </div>
                      <div class="row m-0 result-row" *ngFor="let sr of rgs.RateRequestTemplateServiceRates">
                        <div class="col-2 col-compress">
                          <select class="form-select form-select-sm pointer"
                                  [disabled]="!(RateLimitTypes$ | async) || sr.Active === false"
                                  [(ngModel)]="sr.RateLimitTypeId">
                            <option [ngValue]="null">None...</option>
                            <option *ngFor="let limitType of (RateLimitTypes$ | async)" [ngValue]="limitType.Id">{{ limitType.Name }}</option>
                          </select>
                        </div>
                        <div class="col-1 col-compress">
                          <input type="text"
                                 class="form-control form-control-sm"
                                 [disabled]="sr.Active === false"
                                 numbersOnly="5.0"
                                 [(ngModel)]="sr.RateQuantity1">
                        </div>
                        <div class="col-1 col-compress">
                          <select class="form-select form-select-sm pointer"
                                  [disabled]="!(UnitTypes$ | async) || sr.Active === false"
                                  [(ngModel)]="sr.RateUnitTypeId1">
                            <option [ngValue]="null">None...</option>
                            <option *ngFor="let unitType of (UnitTypes$ | async)" [ngValue]="unitType.Id">{{ unitType.Abbreviation }}</option>
                          </select>
                        </div>
                        <div class="col-2 col-compress">
                          <select class="form-select form-select-sm pointer"
                                  [disabled]="!(UnitTypes$ | async) || sr.Active === false"
                                  [(ngModel)]="sr.MeasurementUnitTypeId1">
                            <option [ngValue]="null">None...</option>
                            <option *ngFor="let unitType of (UnitTypes$ | async)" [ngValue]="unitType.Id">{{ unitType.Abbreviation }}</option>
                          </select>
                        </div>
                        <div class="col-1 col-compress">
                          <input type="text"
                                 numbersOnly="5.0"
                                 class="form-control form-control-sm"
                                 [disabled]="sr.Active === false"
                                 [(ngModel)]="sr.RateQuantity2">
                        </div>
                        <div class="col-1 col-compress">
                          <select class="form-select form-select-sm pointer"
                                  [disabled]="!(UnitTypes$ | async) || sr.Active === false"
                                  [(ngModel)]="sr.RateUnitTypeId2">
                            <option [ngValue]="null">None...</option>
                            <option *ngFor="let unitType of (UnitTypes$ | async)" [ngValue]="unitType.Id">{{ unitType.Abbreviation }}</option>
                          </select>
                        </div>
                        <div class="col-2 col-compress">
                          <select class="form-select form-select-sm pointer"
                                  [disabled]="!(UnitTypes$ | async) || sr.Active === false"
                                  [(ngModel)]="sr.MeasurementUnitTypeId2">
                            <option [ngValue]="null">None...</option>
                            <option *ngFor="let unitType of (UnitTypes$ | async)" [ngValue]="unitType.Id">{{ unitType.Abbreviation }}</option>
                          </select>
                        </div>
                        <div class="col-1 col-compress">
                          <select class="form-select form-select-sm pointer"
                                  [disabled]="!(Currencies$ | async) || sr.Active === false"
                                  [(ngModel)]="sr.CurrencyId">
                            <option [ngValue]="null">None...</option>
                            <option *ngFor="let currency of (Currencies$ | async)" [ngValue]="currency.CurrencyId">{{ currency.Currency.Code }}</option>
                          </select>
                        </div>
                        <div class="col-1 col-compress text-end">
                          <button type="button"
                                  class="btn btn-sm btn-outline-success ms-1"
                                  *ngIf="sr.Active === false"
                                  [title]="'Activate rate'"
                                  (click)="activateServiceRate(sr)">
                            <fa-icon [icon]="faPlus" size="sm"></fa-icon>
                          </button>
                          <button type="button"
                                  class="btn btn-sm btn-outline-danger ms-1"
                                  *ngIf="sr.Active === true"
                                  [title]="'De-activate rate'"
                                  (click)="removeServiceRate(sr)">
                            <fa-icon [icon]="faDelete" size="sm"></fa-icon>
                          </button>
                        </div>
                      </div>
                      <app-no-result [showNoResults]="rgs.RateRequestTemplateServiceRates?.length <= 0" [noResultText]="'No rates for \'' + rgs.ServiceTypeName + '\', add rates by clicking on the \'+\''"></app-no-result>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <app-no-result [showNoResults]="requestTemplate.RateRequestTemplateServices?.length <= 0" [noResultText]="'No services found, add services by clicking on the \'+\''"></app-no-result>
        </div>
        <!----------------------------------->
        <!----- Inclusions & Exclusions ----->
        <!----------------------------------->
        <div class="tab-pane fade" id="inclusions" role="tabpanel" aria-labelledby="inclusions-tab">
          <div class="row mb-5">
            <div class="col-12">
              <div class="row mt-1 header-row">
                <div class="col-1 header-title border-split-left">
                  Order
                </div>
                <div class="col-8 header-title">
                  Service
                </div>
                <div class="col-2 header-title">
                  Included / Excluded
                </div>
                <div class="col-1 header-title text-end border-split-right">
                  <fa-icon [icon]="faPlus"
                           class="text-white pointer"
                           [title]="'Add new Inclusion/Exclusion'"
                           (click)="addInclusion()"
                           size="lg"></fa-icon>
                </div>
              </div>
              <app-loader *ngIf="loading" [Width]="15"></app-loader>
              <ng-container *ngIf="requestInclusions">
                <div class="row result-row" *ngFor="let incl of requestInclusions; let i = index">
                  <div class="col-1 overflow-hidden">
                    <input type="text"
                           class="form-control form-control-sm"
                           placeholder="0"
                           [numbersOnly]="'3.0'"
                           (change)="sortInclusions()"
                           [disabled]="incl.Active === false"
                           [(ngModel)]="incl.Order">
                  </div>
                  <div class="col-8 overflow-hidden" [title]="incl.Description">
                    <input type="text"
                           class="form-control form-control-sm"
                           placeholder="Enter a description"
                           [disabled]="incl.Active === false"
                           [ngClass]="{'is-invalid': (incl.Description == null || incl.Description.length <= 0) }"
                           [(ngModel)]="incl.Description">
                  </div>
                  <div class="col-2 overflow-hidden text-start">
                    <div class="form-check form-switch float-start form-switch-md">
                      <input class="form-check-input me-1"
                             type="checkbox"
                             role="switch"
                             (change)="sortInclusions()"
                             [disabled]="incl.Active === false"
                             [id]="'switch-' + incl.Id"
                             [title]="incl.IsIncluded ? 'Exclude' : 'Include'"
                             [(ngModel)]="incl.IsIncluded">
                      <span class="position-relative" style="top: 5px">{{ incl.IsIncluded ? 'Included' : 'Excluded' }}</span>
                    </div>
                  </div>
                  <div class="col-1 overflow-hidden text-end">
                    <button type="button"
                            class="btn btn-sm btn-danger ms-1"
                            *ngIf="incl.Active === true"
                            (click)="removeInclusion(incl, i)">
                      <fa-icon [icon]="faDelete" size="sm"></fa-icon>
                    </button>
                    <button type="button"
                            class="btn btn-sm btn-outline-success ms-1"
                            *ngIf="incl.Active === false"
                            (click)="incl.Active = true">
                      <fa-icon [icon]="faPlus" size="sm"></fa-icon>
                    </button>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="!requestInclusions || requestInclusions.length <= 0">
                <div class="row">
                  <div class="col-12 text-center">
                    There are no inclusions/exclusions for this rate group.
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <app-loader *ngIf="loading" [Width]="25"></app-loader>
  <button type="button" class="btn btn-primary harmony-tertiary-button" [disabled]="disableSave()" (click)="save()">Save</button>
  <button type="button" class="btn btn-primary harmony-primary-button" (click)="cancel()">Close</button>
</div>
