<div class="card-wrapper">
  <div class="card-content">
    <div class="card-header row">
      <div class="col-12">
        <h5 class="harmony-primary-text fw-bold mt-2">Pulse Chart {{ currentYear }}</h5>
      </div>
    </div>
    <div class="card-body pointer" (click)="viewPulseChart()">
      <div class="row">
        <div class="col-12">
          <ng-container *ngIf="companyData">
            <div class="row">
              <div class="col-3 offset-6 text-end pulse-icon">
                <span class="heading-icon pulse-icon-color" title="Your company">
                  <fa-icon [icon]="faBuilding"></fa-icon>
                </span>
              </div>
              <div class="col-3 text-end pulse-icon">
                <span class="heading-icon pulse-icon-color" title="Network average">
                  <fa-icon [icon]="faNetwork"></fa-icon>
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col-6 label harmony-secondary-text" [title]="'FTE'">FTE</div>
              <div class="col-3 subject text-end harmony-secondary-text fw-bold">{{ companyData?.FTE }}</div>
              <div class="col-3 subject text-end harmony-secondary-text fw-bold">{{ netWorkData?.AvgFTE }}</div>
            </div>
            <div class="row mt-1">
              <div class="col-6 label harmony-secondary-text" [title]="'Worked Days'">Worked Days</div>
              <div class="col-3 subject text-end harmony-secondary-text fw-bold">{{companyData?.WorkDays}}</div>
              <div class="col-3 subject text-end harmony-secondary-text fw-bold">{{netWorkData?.AvgWorkDays}}</div>
            </div>
            <div class="row mt-1">
              <div class="col-6 label harmony-secondary-text" [title]="'Worked Hours'">Worked Hours</div>
              <div class="col-3 subject text-end harmony-secondary-text fw-bold">{{companyData?.WorkHours}}</div>
              <div class="col-3 subject text-end harmony-secondary-text fw-bold">{{netWorkData?.AvgWorkHours}}</div>
            </div>
            <div class="row mt-1">
              <div class="col-6 label harmony-secondary-text" [title]="'Accidents with sick leave'">Accidents with sick leave</div>
              <div class="col-3 subject text-end harmony-secondary-text fw-bold">{{companyData?.AccidentsWithSickLeave}}</div>
              <div class="col-3 subject text-end harmony-secondary-text fw-bold">{{netWorkData?.AvgAccidentsWithSickLeave}}</div>
            </div>
            <div class="row mt-1">
              <div class="col-6 label harmony-secondary-text" [title]="'Incidents (no sick leave)'">Incidents (no sick leave)</div>
              <div class="col-3 subject text-end harmony-secondary-text fw-bold">{{companyData?.IncidentsWithNoSickLeave}}</div>
              <div class="col-3 subject text-end harmony-secondary-text fw-bold">{{netWorkData?.AvgIncidentsWithNoSickLeave}}</div>
            </div>
            <div class="row mt-1">
              <div class="col-6 label harmony-secondary-text" [title]="'Environmental Incidents'">Environmental Incidents</div>
              <div class="col-3 subject text-end harmony-secondary-text fw-bold">{{companyData?.EnvironmentalIncident}}</div>
              <div class="col-3 subject text-end harmony-secondary-text fw-bold">{{netWorkData?.AvgEnvironmentalIncident}}</div>
            </div>
            <div class="row mt-1">
              <div class="col-6 label harmony-secondary-text" [title]="'Sick Days'">Sick Days</div>
              <div class="col-3 subject text-end harmony-secondary-text fw-bold">{{companyData?.SickDays}}</div>
              <div class="col-3 subject text-end harmony-secondary-text fw-bold">{{netWorkData?.AvgSickDays}}</div>
            </div>
            <div class="row mt-1">
              <div class="col-6 label harmony-secondary-text" [title]="'Work related sick days'">Work related sick days</div>
              <div class="col-3 subject text-end harmony-secondary-text fw-bold">{{companyData?.WorkRelatedSickDays}}</div>
              <div class="col-3 subject text-end harmony-secondary-text fw-bold">{{netWorkData?.AvgWorkRelatedSickDays}}</div>
            </div>
          </ng-container>
          <ng-container *ngIf="!companyData">
            <div class="row">
              <div class="col-12 label">
                You have no pulse chart data
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
