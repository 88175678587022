import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventsDashboardContainerComponent } from './events-dashboard/dashboard-container/events-dashboard-container.component';
import { EventsDashboardCardComponent } from './events-dashboard/dashboard-card/events-dashboard-card.component';
import { EventsRoutingModule } from './events-routing.module';
import { EventsMenuComponent } from './events-menu/events-menu.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [
    EventsDashboardContainerComponent,
    EventsDashboardCardComponent,
    EventsMenuComponent
  ],
  imports: [
    CommonModule,
    EventsRoutingModule,
    FontAwesomeModule
  ],
  exports: [
    EventsMenuComponent
  ]
})
export class EventsModule { }
