<div class="member-wrapper">
  <div class="member-background"></div>
  <!-- NAVIGATION -->
  <div class="row">
    <div class="col-3">
      <div class="member-menu-wrapper">
        <ng-container *ngIf="company">
          <div class="member-logo-wrapper">
            <img *ngIf="company.CompanyLogo" [src]="company.CompanyLogo" alt="member-logo" class="member-logo">
            <img *ngIf="!company.CompanyLogo" [src]="placeholderImage" alt="member-logo" class="member-logo">
          </div>
          <div class="row mt-3">
            <div class="col-12">
              <div class="profile-header">
                <div class="row">
                  <div class="col-12 text-center">
                    <h5 class="harmony-blue-text fw-bold mb-0">
                      {{ company.Name }}
                    </h5>
                  </div>
                </div>
                <div class="row mt-1" *ngIf="company.AddressPhysical">
                  <div class="col-12 text-center">
                    <small class="mb-0" style="font-size: 0.75rem">
                      {{ company.AddressPhysical?.length > 0 ? company.AddressPhysical.trim() : '-' }}{{ company.AddressPhysical2?.length > 0 ? ', ' + company.AddressPhysical2.trim() : '' }}{{ company.CityPhysical?.length > 0 ? ', ' + company.CityPhysical : '' }}{{ company.ZipCodePhysical?.length > 0 ? ', ' + company.ZipCodePhysical : '' }}
                    </small>
                  </div>
                </div>
                <div class="row mt-0" *ngIf="company && company.CountryPhysical">
                  <div class="col-12 text-center">
                    <small class="fw-bold">
                      {{ company.CountryPhysical }} {{ company.CountryPhysicalIso ? ('(' + company.CountryPhysicalIso + ')') : '' }}
                    </small>
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-12 text-center">
                    <small class="harmony-grey-text">
                      {{ company.IsMovingServiceProvider == true ? 'Moving' : '' }}
                      {{ company.IsMovingServiceProvider == true && company.IsRelocationServiceProvider == true ? ' & ' : ''}}
                      {{ company.IsRelocationServiceProvider == true ? 'Relocation' : '' }}
                      provider
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12">
              <div class="align-items-center">
                <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                  <button class="nav-link active" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">Profile</button>
                  <button class="nav-link" id="v-pills-address-tab" data-bs-toggle="pill" data-bs-target="#v-pills-address" type="button" role="tab" aria-controls="v-pills-address" aria-selected="true">Address Information</button>
                  <button class="nav-link" id="v-pills-contact-tab" data-bs-toggle="pill" data-bs-target="#v-pills-contact" type="button" role="tab" aria-controls="v-pills-contact" aria-selected="false">Contact Information</button>
                  <button class="nav-link" id="v-pills-people-tab" data-bs-toggle="pill" data-bs-target="#v-pills-people" type="button" role="tab" aria-controls="v-pills-people" aria-selected="false">Users</button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="!company">
          <app-loader [Width]="50"></app-loader>
        </ng-container>
      </div>
    </div>
    <div class="col-9">
      <!-- CONTENT -->
      <div class="member-content-wrapper">
        <div class="tab-content" id="v-pills-tabContent">
          <!-- PROFILE -->
          <div class="tab-pane fade show active" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
            <ng-container *ngIf="company">
              <div class="row mt-4">
                <div class="col-12">
                  <div class="member-branding-wrapper align-self-center">
                    <div class="member-picture-clip"
                         [ngStyle]="{'background-image': 'url(\'' + getCompanyPicture() + '\')' }">
                    </div>
                    <div class="proud-member">
                      <img *ngIf="company.ProudMemberLogo !== null && company.ProudMemberLogo.length > 1"
                           [src]="company.ProudMemberLogo"
                           class="proud-member-logo"
                           [alt]="company.CompanyId">
                      <img *ngIf="company.ProudMemberLogo == null || company.ProudMemberLogo.length <= 0"
                           [src]="placeholderImage"
                           class="proud-member-logo"
                           [alt]="company.CompanyId">
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-12">
                  <!-- About -->
                  <div class="company-profile-wrapper" *ngIf="company.Biography && company.Biography.trim().length > 0">
                    <h3>
                      About {{ company.Name }}
                    </h3>
                    <p [innerText]="company.Biography"></p>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="!company">
              <app-loader [Width]="50"></app-loader>
            </ng-container>
          </div>
          <!-- ADDRESS INFORMATION -->
          <div class="tab-pane fade" id="v-pills-address" role="tabpanel" aria-labelledby="v-pills-address-tab">
            <ng-container *ngIf="company">
              <div class="row mt-3">
                <div class="col-12">
                  <p class="content-header mb-0">Address Information</p>
                  <hr class="mb-2 mt-0">
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-6">
                  <div class="row mt-1">
                    <div class="col-12">
                      <b>Physical</b>
                    </div>
                  </div>
                  <div class="row mt-1">
                    <div class="col-12">
                      <small>
                        <p class="mb-0">
                          {{ company?.AddressPhysical?.length > 0 ? company?.AddressPhysical.trim() : '-' }}{{ company?.AddressPhysical2?.length > 0 ? ', ' + company?.AddressPhysical2.trim() : '' }}
                        </p>
                        <p class="mb-0">
                          {{ company?.CityPhysical?.length > 0 ? company?.CityPhysical : '' }}{{ company?.ZipCodePhysical?.length > 0 ? ', ' + company?.ZipCodePhysical : '' }}
                        </p>
                        <p class="mb-0">
                          {{ company?.CountryPhysical ?? ''}} {{ company?.CountryPhysicalIso ? '(' + company?.CountryPhysicalIso + ')' : '' }}
                        </p>
                      </small>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="row mt-1">
                    <div class="col-12">
                      <b>Postal</b>
                    </div>
                  </div>
                  <div class="row mt-1">
                    <div class="col-12">
                      <small>
                          <p class="mb-0">
                            {{ company?.AddressPostal?.length > 0 ? company?.AddressPostal.trim() : '-' }}{{ company?.AddressPostal2?.length > 0 ? ', ' + company?.AddressPostal2.trim() : '' }}
                          </p>
                          <p class="mb-0">
                            {{ company?.CityPostal?.length > 0 ? company?.CityPostal : '' }}{{ company?.ZipCodePostal?.length > 0 ? ', ' + company?.ZipCodePostal : '' }}
                          </p>
                          <p class="mb-0">
                            {{ company?.CountryPostal ?? '' }} {{ company?.CountryPostalIso ? '(' + company?.CountryPostalIso + ')' : '' }}
                          </p>
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-5">
                <div class="col-12 member-profile-map">
                  <app-member-map [CompanyData]="mapData"
                                  id="map"
                                  [Zoom]="4"
                                  [MinZoom]="4"
                                  [Center]="{ lat: centerLat, lng: centerLong }"
                                  [MapStyle]="mapStyles.MemberMap"></app-member-map>
                </div>
              </div>
            </ng-container>
          </div>
          <!-- CONTACT INFORMATION -->
          <div class="tab-pane fade" id="v-pills-contact" role="tabpanel" aria-labelledby="v-pills-contact-tab">
            <div class="row mt-3">
              <div class="col-12">
                <p class="content-header mb-0">Contact Information</p>
                <hr class="mb-2 mt-0">
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-2">
                <b>
                  Telephone
                </b>
              </div>
              <div class="col-10">
                {{ getDetailValue('MemberTelephone') }}
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-2">
                <b class="fw-bold">
                  General Enquiries
                </b>
              </div>
              <div class="col-10">
                {{ getDetailValue('MemberEmail') }}
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-2">
                <b class="fw-bold">
                  Pricing Contact
                </b>
              </div>
              <div class="col-10">
                {{ getDetailValue('RateRequest') }}
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-4 col-compress">
                <button class="btn btn-sm btn-primary harmony-blue-button w-100 text-white"
                        [disabled]="companyEmail == null"
                        (click)="contactMember()">Contact</button>
              </div>
              <div class="col-4 col-compress">
                <button class="btn btn-sm btn-primary harmony-coral-button w-100 text-white text-nowrap"
                        (click)="getDirectQuote()">Direct quote</button>
              </div>
              <div class="col-4 col-compress">
                <button class="btn btn-sm btn-primary harmony-blue-button w-100 text-white"
                        [disabled]="companyUrl == null"
                        (click)="openNewWindow(companyUrl)">website</button>
              </div>
            </div>
          </div>
          <!-- USERS -->
          <div class="tab-pane fade" id="v-pills-people" role="tabpanel" aria-labelledby="v-pills-people-tab">
            <div class="row mt-3">
              <div class="col-8">
                <p class="content-header mb-0">Users</p>
              </div>
              <div class="col-4">
                <input
                  class="form-control form-control-sm"
                  type="search"
                  placeholder="Search..."
                  [(ngModel)]="searchText">
              </div>
            </div>
            <hr class="mb-2 mt-0">
            <div class="row result-row py-1"
                 *ngFor="let user of getFilteredUsers()">
              <div class="col-4 col-compress">
                <div class="row">
                  <div class="col-2 overflow-hidden">
                    <div class="profile-icon" *ngIf="token && user.ProfilePicture"
                         [ngStyle]="{'background-image': 'url(\'' + getFullPictureName(user.Id, user.ProfilePicture) + token + '\')' }">
                    </div>
                    <div class="profile-icon" *ngIf="!user.ProfilePicture"
                         [ngStyle]="{'background-image': 'url(\'' + defaultImage + '\')' }">
                    </div>
                  </div>
                  <div class="col-10 col-compress">
                    <strong>{{ user.DisplayName ? user.DisplayName : '-' }}</strong>
                    <br>
                    <small class="harmony-grey-text">{{ user.JobTitle ?? '-' }}</small>
                  </div>
                </div>
              </div>
              <div class="col-3 col-compress overflow-hidden">
                <ng-container *ngIf="user.ContactNumber">
                  <a class="harmony-blue-link" href="tel:{{ user.ContactNumber }}">{{ user.ContactNumber }}</a>
                </ng-container>
                <ng-container *ngIf="!user.ContactNumber">
                  -
                </ng-container>
              </div>
              <div class="col-3 col-compress overflow-hidden">
                <a class="harmony-blue-link" href="mailto:{{ user.Username }}">{{ user.Username }}</a>
              </div>
              <div class="col-2 col-compress text-end pe-0">
                <button class="btn btn-sm btn-outline-success harmony-teal-outline-button"
                        (click)="viewContactCard(user.Id)">
                  <fa-icon [icon]="faView" size="xs"></fa-icon>
                </button>
              </div>
            </div>
          </div>
          <!-- ARTICLES -->
          <div class="tab-pane fade" id="v-pills-articles" role="tabpanel" aria-labelledby="v-pills-articles-tab">
            <div class="row mt-3">
              <div class="col-12">
                <p class="content-header mb-0">Articles</p>
                <hr class="mb-2 mt-0">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
