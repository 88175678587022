<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom sticky-header">
  <h1 class="portal-component-header">
    Emailing
  </h1>
  <div>
    <button
      class="btn btn-sm btn-outline-primary harmony-tertiary-outline-button"
      (click)="newSchedule()">
      <fa-icon [icon]="faAdd"></fa-icon> New Schedule
    </button>
  </div>
</div>
<div class="row mt-3">
  <div class="col-12">
    <ul class="nav nav-tabs" id="emailsTab" role="tablist">
      <li class="nav-item" role="presentation">
        <a class="nav-link active" id="email-tab" data-bs-toggle="tab" href="#email" role="tab" aria-controls="email" aria-selected="true">
          Emails
        </a>
      </li>
      <li class="nav-item" role="presentation">
        <a class="nav-link" id="email-schedule-tab" data-bs-toggle="tab" href="#email-schedule" role="tab" aria-controls="email-schedule" aria-selected="true">
          Email Schedules
        </a>
      </li>
      <li class="nav-item" role="presentation">
        <a class="nav-link" id="email-template-tab" data-bs-toggle="tab" href="#email-templates" role="tab" aria-controls="email-templates" aria-selected="true">
          Email Templates
        </a>
      </li>
    </ul>
    <!-------------------------------->
    <!------ Tab Content Start ------->
    <!-------------------------------->
    <div class="tab-content" id="permissionsTabContent">
      <!------------------------------>
      <!---------- Emails ------------>
      <!------------------------------>
      <div class="tab-pane fade show active mb-5" id="email" role="tabpanel" aria-labelledby="email-tab">
        <app-email-overview></app-email-overview>
      </div>
      <!------------------------------>
      <!----- Email Schedules ------>
      <!------------------------------>
      <div class="tab-pane fade mb-5" id="email-schedule" role="tabpanel" aria-labelledby="email-schedule-tab">
        <app-email-schedule-overview></app-email-schedule-overview>
      </div>
      <!------------------------------>
      <!----- Email Templates ------>
      <!------------------------------>
      <div class="tab-pane fade mb-5" id="email-templates" role="tabpanel" aria-labelledby="email-template-tab">
        <app-email-template-overview></app-email-template-overview>
      </div>
    </div>
  </div>
</div>
