import { Component, Input } from '@angular/core';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-services-client-services',
  templateUrl: './services-client-services.component.html',
  styleUrls: ['./services-client-services.component.scss']
})
export class ServicesClientServicesComponent {
  @Input() column = 1;
  @Input() row = 1;

  // Icons
  faPhone = faPhone;
  faEnvelope = faEnvelope;

  // General variables
  env = environment;
}
