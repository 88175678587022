import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdAuthService } from '../../../../core/ad-auth-service/ad-auth.service';
import { IAuthUser } from '../../../../services/models/auth.model';
import { PermissionCodes } from '../../../../core/constants/permission-codes';

@Component({
  selector: 'app-dashboard-container-pricing',
  templateUrl: './dashboard-container-pricing.component.html',
  styleUrls: ['./dashboard-container-pricing.component.scss']
})
export class DashboardContainerPricingComponent implements OnInit {

  // Permissions
  rateRequests: boolean = true;
  checkRates: boolean = true;
  documents: boolean = true;
  tenders: boolean = true;

  constructor(public router: Router,
              public authService: AdAuthService) { }

  ngOnInit(): void {
    if (this.authService.CurrentUser) {
      this.setPermissions();
    }

    this.authService.CurrentUser$.subscribe((user: IAuthUser) => {
      this.setPermissions();
    });
  }

  setPermissions() {
    this.rateRequests = this.authService.CheckPermissionByCode(PermissionCodes.Pricing_Dashboard);
    this.checkRates = this.authService.CheckPermissionByCode(PermissionCodes.Pricing_Dashboard);
    this.documents = this.authService.CheckPermissionByCode(PermissionCodes.Pricing_Dashboard);
    this.tenders = this.authService.CheckPermissionByCode(PermissionCodes.Pricing_Tender_Tool);
  }
}
