import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { AdAuthService } from '../../../../core/ad-auth-service/ad-auth.service';
import { ITenderReport } from '../models/pricing-dashboard.model';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from '../../../../services/api.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-dashboard-card-tenders',
  templateUrl: './dashboard-card-tenders.component.html',
  styleUrls: ['./dashboard-card-tenders.component.scss'],
  host: {'[class.one-by-one]': '(column <= 1 || column > 3) && (row <= 1 || row > 4)',
    '[class.two-by-one]': 'column == 2 && row == 1',
    '[class.three-by-one]': 'column == 3 && row == 1',
    '[class.two-by-two]': 'column == 2 && row == 2',
    '[class.one-by-two]': 'column == 1 && row == 2',
    '[class.one-by-three]': 'column == 1 && row == 3',
    '[class.one-by-four]': 'column == 1 && row == 4',
    '[class.one-by-five]': 'column == 1 && row == 5',
    '[class.one-by-six]': 'column == 1 && row == 6'}
})
export class DashboardCardTendersComponent implements OnInit {
  // Inputs
  @Input() column = 1;
  @Input() row = 1;

  // Icons
  faLink = faLink;

  // Component variables
  report: ITenderReport;
  companyId: number = null;

  // General variables
  private unsubscribe: Subject<any> = new Subject<any>();
  loading: boolean = false;

  constructor(public authService: AdAuthService,
              private router: Router,
              private api: ApiService) { }

  ngOnInit(): void {
    if (this.authService.CurrentUser) {
      this.companyId = this.authService.CurrentUser.User.CompanyId;
      this.getReport();
    }

    this.authService.CurrentUser$.subscribe((data) => {
      this.companyId = data.User.CompanyId;
      this.getReport();
    });
  }

  getReport() {
    this.loading = true;

    this.api.get(`Pricing/Report/Tenders`).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data: ITenderReport) => {
        if (data) {
          this.report = Object.assign({}, data);
        }
        this.loading = false;
      },
      error: () => {
        this.report = {
          ConfigureCount: 0,
          LocationCount: 0,
          VolumeWeightCount: 0,
          FreightCount: 0,
          ExportCount: 0
        } as ITenderReport;
        this.loading = false;
      }
    });
  }

  viewTenders(param: string) {
    if (param == null) {
      this.router.navigate([`pricing/rate-tender`]).then(() => {
      });
    } else {
      this.router.navigate([`pricing/rate-tender`, { filter: param }])
        .then(() => {
        });
    }
  }
}
