<!---------------------->
<!------- Header ------->
<!---------------------->
<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom sticky-header">
  <h1 class="portal-component-header">
    Incident Improvements
  </h1>
  <button class="btn btn-sm btn-primary harmony-teal-outline-button"
          [disabled]="loading || (!hseService)"
          (click)="registerNewIncident()">
    + Register a new incident
  </button>
</div>
<!------------------------------->
<!--- Searchbar with filters ---->
<!------------------------------->
<ng-container *ngIf="incidentListParam">
  <div class="row mt-1">
    <div class="col-2">
      <select class="form-select form-select-sm pointer"
              [(ngModel)]="incidentListParam.AnalysisStatusId"
              [disabled]="(loading || (!hseService))">
        <option [ngValue]="null" selected>All statuses</option>
        <option *ngFor="let status of hseService.AnalysisStatus" [ngValue]="status.AnalysisStatusId">{{ status.Description }}</option>
      </select>
    </div>
    <div class="col-2">
      <select class="form-select form-select-sm pointer"
              [(ngModel)]="incidentListParam.DisciplineId"
              [disabled]="loading || (!hseService)">
        <option [ngValue]="null" selected>All disciplines</option>
        <option *ngFor="let discipline of hseService.RegistrationDiscipline" [ngValue]="discipline.DisciplineId">{{ discipline.Description }}</option>
      </select>
    </div>
    <div class="col-1 text-start align-self-center">
      <div class="form-check form-switch form-switch-sm pointer">
        <input class="form-check-input pointer"
               type="checkbox"
               role="switch"
               [id]="'switch-ii-overdue'"
               [checked]="incidentListParam.Overdue"
               [title]="'Show overdue items only'"
               (change)="setOverdue($event)">
        Overdue
      </div>
    </div>
  </div>
  <hr class="mt-2 mb-2">
  <div class="row">
    <div class="col-4">
      <input id="searchText"
             type="text"
             class="form-control form-control-sm w-100"
             placeholder="Enter search criteria"
             [(ngModel)]="incidentListParam.Criteria"
             (keyup.enter)="loadIncidentList()"/>
    </div>
    <div class="col-3 align-self-center">
      <ng-container *ngIf="companyFilterAll; else nonAdminCompany">
        <div class="col-8 text-start ps-0">
          <select class="form-select form-select-sm pointer"
                  [(ngModel)]="incidentListParam.CompanyId"
                  [disabled]="loading || !(companies$ | async)">
            <option [ngValue]="null" selected>Select a company...</option>
            <option *ngFor="let company of (companies$ | async)" [ngValue]="company.CompanyId">{{ company.CompanyName }}</option>
          </select>
        </div>
      </ng-container>
      <ng-template #nonAdminCompany>
        <span class="fw-semibold">
          {{ authService.CurrentUser ? authService.CurrentUser.User.Company.Name : '-' }}
        </span>
      </ng-template>
    </div>
    <div class="col-4 offset-1">
      <div class="row justify-content-end me-1">
        <div class="col-4 offset-2 text-end">
          <button class="btn btn-sm btn-primary excel-export-btn"
                  [title]="'Export to Excel'"
                  *ngIf="canExportExcel"
                  (click)="excelExport()">
            <fa-icon [icon]="faExcel"></fa-icon> Export
          </button>
        </div>
        <div class="col-2 pe-1 text-end">
          <button class="btn btn-sm btn-primary harmony-teal-outline-button ms-1"
                  [title]="'Reset Search'"
                  (click)="resetSearch()">
            Reset
          </button>
        </div>
        <div class="col-4 p-0">
          <button class="btn btn-sm btn-primary harmony-purple-button w-100"
                  style="bottom: 0; right: 1.5em;"
                  (click)="loadIncidentList()">
            Search
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<hr class="mt-2 mb-0">
<!--------------------->
<!---- Result table --->
<!--------------------->
<ng-container *ngIf="paginationData">
  <div class="row ms-1 me-1 mb-2">
    <div class="col-12">
      <app-pagination [showPageNumber]=true
                      [showRowsPerPage]=true
                      [paginationData]=paginationData
                      (pageEvent)="onPage($event)"
                      [name]="'IncidentOverview'"
                      [page]="page">
      </app-pagination>
    </div>
  </div>
  <div class="row ms-1 me-1 mt-1 header-row">
    <div class="col-1 col-compress pointer header-title border-split-left"
         (appOrder)="orderSearch($event)"
         data-column="IncidentNumber"
         data-order="asc">
      No.
      <fa-icon [hidden]="orderParam.OrderBy != 'IncidentNumber'" [icon]="faSortDesc" style="bottom: -3px;" class="ms-2 position-relative"></fa-icon>
    </div>
    <!-- Origin -->
    <div class="col-2 col-compress pointer header-title"
         (appOrder)="orderSearch($event)"
         data-column="Company.Name"
         data-order="asc">
      Company
      <fa-icon [hidden]="orderParam.OrderBy != 'Company.Name'" class="ms-2 position-relative"></fa-icon>
    </div>
    <!-- Destination -->
    <div class="col-1 col-compress pointer header-title"
         (appOrder)="orderSearch($event)"
         data-column="CreatedOnDate"
         data-order="asc">
      Date
      <fa-icon [hidden]="orderParam.OrderBy != 'CreatedOnDate'" class="ms-2 position-relative"></fa-icon>
    </div>
    <!-- Other -->
    <div class="col-1 col-compress pointer header-title"
         (appOrder)="orderSearch($event)"
         data-column="AnalysisStatus.Description"
         data-order="asc">
      Status
      <fa-icon [hidden]="orderParam.OrderBy != 'AnalysisStatus.Description'" class="ms-2 position-relative"></fa-icon>
    </div>
    <div class="col-1 col-compress pointer header-title"
         (appOrder)="orderSearch($event)"
         data-column="ActionDeadline"
         data-order="asc">
      Deadline
      <fa-icon [hidden]="orderParam.OrderBy != 'ActionDeadline'" class="ms-2 position-relative"></fa-icon>
    </div>
    <div class="col-1 col-compress pointer header-title"
         (appOrder)="orderSearch($event)"
         data-column="RegistrationDiscipline.Description"
         data-order="asc">
      Discipline
      <fa-icon [hidden]="orderParam.OrderBy != 'RegistrationDiscipline.Description'" class="ms-2 position-relative"></fa-icon>
    </div>
    <div class="col-3 col-compress pointer header-title"
         (appOrder)="orderSearch($event)"
         data-column="IncidentDescription"
         data-order="asc">
      Description
      <fa-icon [hidden]="orderParam.OrderBy != 'IncidentDescription'" class="ms-2 position-relative"></fa-icon>
    </div>
    <div class="col-1 col-compress pointer header-title"
         (appOrder)="orderSearch($event)"
         data-column="RegistrationType.Description"
         data-order="asc">
      Reg. Type
      <fa-icon [hidden]="orderParam.OrderBy != 'RegistrationType.Description'" class="ms-2 position-relative"></fa-icon>
    </div>
    <div class="col-1 col-compress header-title border-split-right">
    </div>
  </div>
  <!--------------------->
  <!---- Grid table --->
  <!--------------------->
  <div class="row result-row ms-1 me-1" *ngFor="let inc of paginationData.DataSet; let i = index">
    <!-- IncidentNumber -->
    <div class="col-1 col-compress overflow-hidden">
      {{ inc.IncidentNumber }}
    </div>
    <!-- Company -->
    <div class="col-2 col-compress overflow-hidden" [title]="inc.Company">
      {{ inc.Company }}
    </div>
    <!-- Create date -->
    <div class="col-1 col-compress overflow-hidden">
      <small>{{ inc.Created | date: environment.FormattingStandards.ShortDateFormat }}</small>
    </div>
    <!-- Status -->
    <div class="col-1 col-compress overflow-hidden">
      {{ inc.AnalysisStatus }}
    </div>
    <!-- Deadline -->
    <div class="col-1 col-compress overflow-hidden" [ngClass]="{ 'text-danger' : inc.AnalysisStatus == 'Open' && getDayDifference(inc.ActionDeadline) <= 0 }">
      <small>{{ inc.ActionDeadline | date: environment.FormattingStandards.ShortDateFormat }}</small>
    </div>
    <!-- Discipline -->
    <div class="col-1 col-compress overflow-hidden">
      {{ inc.Discipline }}
    </div>
    <!-- Description -->
    <div class="col-3 col-compress overflow-hidden" [title]="inc.IncidentDescription">
      {{ inc.IncidentDescription }}
    </div>
    <!-- Registration Type -->
    <div class="col-1 col-compress overflow-hidden" [title]="inc.RegistrationType">
      {{ inc.RegistrationType }}
    </div>
    <div class="col-1 pe-0 me-0 text-end">
      <button class="btn btn-sm btn-primary harmony-teal-outline-button" data-icon="fa-pen"
              [disabled]="loading || (!hseService)"
              (click)="editIncident(inc.IncidentNumber)">
        <fa-icon [icon]="faEditIcon"></fa-icon>
      </button>
<!--      <button class="btn btn-sm btn-outline-danger ms-2"-->
<!--              [disabled]="loading || (!hseService)"-->
<!--              (click)="deleteIncident(inc.IncidentNumber)">-->
<!--        <fa-icon [icon]="faDeleteIcon"></fa-icon>-->
<!--      </button>-->
    </div>
  </div>
  <div class="row mt-4" *ngIf="paginationData.DataSet && paginationData.DataSet.length === 0 && !loading">
    <div class="col-12 text-center harmony-grey-text">
      <fa-icon [icon]="faEmpty" size="2x"></fa-icon>
    </div>
    <div class="col-12 text-center harmony-grey-text mt-1">
      <h4>No incidents found, change filters and try again...</h4>
    </div>
  </div>
</ng-container>
<br>
<br>
<!---------------------->
<!------- Loader ------->
<!---------------------->
<div class="row mt-4" *ngIf="loading">
  <div class="col-12 text-center">
    <app-loader [Width]="50"></app-loader>
  </div>
</div>
