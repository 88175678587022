import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../core/guards/auth-guard.guard';
import { PermissionCodes } from '../../core/constants/permission-codes';
import { EventsDashboardContainerComponent } from './events-dashboard/dashboard-container/events-dashboard-container.component';

const routes: Routes = [
  {
    path: 'dashboard',
    component: EventsDashboardContainerComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.Events_Dashboard
    },
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EventsRoutingModule { }
