<div class="modal-header">
  <h4 class="modal-title">
    Create missing value mapping
  </h4>
</div>
<div class="modal-body overflow-hidden">
  <div class="row">
    <div class="col-2">

    </div>
    <div class="col-4">
      <h5 class="harmony-primary-text fw-bold">
        Source
      </h5>
      <hr class="my-1">
    </div>
    <div class="col-5 offset-1">
      <h5 class="harmony-primary-text fw-bold">
        Target
      </h5>
      <hr class="my-1">
    </div>
  </div>
  <div class="row">
    <div class="col-2">
      <div class="row mt-2">
        <div class="col-4 align-self-center text-end">
          <b>System</b>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-3 align-self-center text-end">
          <b>Client</b>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-3 align-self-center text-end">
          <b>Element</b>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-3 align-self-center text-end">
          <b>Value</b>
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="row mt-2">
        <div class="col-12 align-self-center">
          {{ MissingValue.SourceSystemName ?? '-' }}
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-12 align-self-center">
          {{ MissingValue.SourceClientName ?? '-' }}
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-12 align-self-center overflow-hidden text-nowrap">
          <small>{{ MissingValue.SourceElementPath ?? '-' }}</small>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12 align-self-center">
          {{ MissingValue.SourceValue ?? '-' }}
        </div>
      </div>
    </div>
    <div class="col-1 text-center align-self-center harmony-secondary-text">
      <fa-icon [icon]="faDirection" [size]="'2x'"></fa-icon>
    </div>
    <div class="col-5">
      <div class="row mt-1">
        <div class="col-12 align-self-center">
          {{ MissingValue.TargetSystemName ?? '-' }}
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-12 align-self-center">
          <ng-container *ngIf="MissingValue.TargetClientId && MissingValue.TargetClientId > 0">
            {{ MissingValue.TargetClientName ?? '-' }}
          </ng-container>
          <ng-container *ngIf="(!MissingValue.TargetClientId || MissingValue.TargetClientId <= 0) && targetClients != null">
            <select class="form-select form-select-sm pointer"
                    [disabled]="!targetClients || targetClients.length <= 0"
                    [(ngModel)]="newMapping.TargetClientId"
                    [ngClass]="{'is-invalid': invalid && (newMapping.TargetValue == null || newMapping.TargetValue.length <= 0)}">
              <option [ngValue]="null" [disabled]="true">Select target client...</option>
              <option *ngFor="let client of targetClients"
                      [ngValue]="client.ClientId">
                {{ client.ClientName }} ({{client.SystemName}})
              </option>
            </select>
          </ng-container>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-12 align-self-center overflow-hidden text-nowrap">
          <small>{{ targetElement?.ElementPath ?? '-' }}</small>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-12 align-self-center">
          <input class="form-control form-control-sm"
                 id="targetValue"
                 name="targetValue"
                 [placeholder]="'Enter a target value...'"
                 [ngClass]="{'is-invalid': invalid && (newMapping.TargetValue == null || newMapping.TargetValue.length <= 0)}"
                 [(ngModel)]="newMapping.TargetValue"
                 [disabled]="!MissingValue">
        </div>
      </div>
    </div>
  </div>
  <hr>
  <div class="row m-0 header-row">
    <div class="col-1 header-title-small">
      <small>ID</small>
    </div>
    <div class="col-2 header-title-small">
      <small>Date</small>
    </div>
    <div class="col-2 header-title-small">
      <small>Source Client</small>
    </div>
    <div class="col-2 header-title-small">
      <small>Target Client</small>
    </div>
    <div class="col-2 header-title-small">
      <small>Source Reference</small>
    </div>
    <div class="col-2 header-title-small">
      <small>Target Reference</small>
    </div>
    <div class="col-1 header-title-small text-end">
    </div>
  </div>
  <div class="row result-row my-0" *ngFor="let message of messages">
    <div class="col-1 col-compress">
      {{ message.Id }}
    </div>
    <div class="col-2 col-compress">
      <small>{{ message.CreatedDate ? ((message.CreatedDate + '+00:00') | date: env.FormattingStandards.LongDateFormat:timezone) : '-' }}</small>
    </div>
    <div class="col-2 col-compress">
      {{ message.SourceClientName }}
    </div>
    <div class="col-2 col-compress">
      {{ message.TargetClientName }}
    </div>
    <div class="col-2 col-compress overflow-hidden" [title]="message.SourceReference">
      {{ message.SourceReference ?? '-' }}
    </div>
    <div class="col-2 col-compress overflow-hidden" [title]="message.TargetReference">
      {{ message.TargetReference ?? '-' }}
    </div>
    <div class="col-1 col-compress pe-0 text-end">
      <button class="btn btn-sm btn-outline-primary harmony-accent-blue-grey-outline-button"
              [title]="'View message'"
              (click)="viewMessage(message.Id)">
        <fa-icon [icon]="faView"></fa-icon>
      </button>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary harmony-tertiary-button" (click)="save()">Create Mapping</button>
  <button type="button" class="btn btn-primary harmony-primary-button" (click)="close()">Cancel</button>
</div>
