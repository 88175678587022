import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ICompany } from '../../../services/models/member.model';
import { VerifyService } from '../../../services/verify.service';
import { ICountry } from '../../../services/models/pricing/location.model';
import { IVerifyCompanyDetailsRequest } from '../../../services/models/verify/verify.model';
import { AlertService } from '../../../services/alert.service';
import { Subject } from 'rxjs';
import { OpenConfirmationModal } from '../../../shared/components/confirmation-modal/confirmation-modal-functions';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-verify-company-details-modal',
  templateUrl: './verify-company-details-modal.component.html',
  styleUrls: ['./verify-company-details-modal.component.scss']
})
export class VerifyCompanyDetailsModalComponent implements OnInit, OnDestroy {
  // External Variables
  public company: ICompany;
  public userId: number;

  // Variables
  invalid = false;
  loading = false;
  verifyRequest: IVerifyCompanyDetailsRequest;
  private unsubscribe: Subject<any> = new Subject<any>();

  constructor(private activeModal: NgbActiveModal,
              private verifyService: VerifyService,
              private alertService: AlertService,
              private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.verifyRequest = {
      Company: this.company,
      UserId: this.userId,
      CompanyDetails: []
    } as IVerifyCompanyDetailsRequest;
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  // Modal functions
  close() {
    const message = 'You will be asked on the next login to verify company details.';
    OpenConfirmationModal(this.modalService, message)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((answer: boolean) => {
      if (answer) {
        this.activeModal.close();
      }
    });
  }

  save() {
    if (this.company.AddressPhysical &&
      this.company.CityPhysical &&
      this.company.CountryIdPhysical &&
      this.company.ZipCodePhysical &&
      this.company.Latitude &&
      this.company.Longitude
    ) {
      this.invalid = false;

      const message = 'You agree all information filled in is true and accurate?';
      OpenConfirmationModal(this.modalService, message)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((answer: boolean) => {
          // Only when answered yes/ok then save
          if (answer) {
            this.verifyRequest.Company = this.company;
            this.verifyRequest.UserId = this.userId;
            this.loading = true;
            this.verifyService.companyDetails(this.verifyRequest)
              .subscribe({
                next: () => {
                  this.loading = false;
                  this.activeModal.close();
                }, error: () => {
                  this.loading = false;
                  this.alertService.error('Any error occurred close and try again another time');
                }
              });
          }
        });
    } else {
      this.invalid = true;
      this.alertService.warn('Ensure all required fields are filled in');
    }
  }

  // Address Functions
  setCountry(event: ICountry) {
    if (event) {
      this.company.CountryPhysicalIso = event.Iso3;
      this.company.CountryIdPhysical = event.Id;
      this.company.CountryPhysical = event.Name;
    } else {
      this.company.CountryPhysicalIso = null;
      this.company.CountryIdPhysical = null;
      this.company.CountryPhysical = null;
    }
  }

  // Service Functions
  setMovingService(event: any) {
    this.company.IsMovingServiceProvider = event.target.checked;
  }

  setRelocationService(event: any) {
    this.company.IsRelocationServiceProvider = event.target.checked;
  }
}
