<div class="modal-header">
  <h3 class="modal-title">
    Terms & Conditions
  </h3>
  <button type="button" class="btn-close" aria-label="Close" [disabled]="loading" (click)="activeModal.dismiss('Dismiss')">

  </button>
</div>
<div class="modal-body p-4">
  <app-loader *ngIf="loading"></app-loader>
  <div class="row">
    <div class="col-12">
      <h5 class="harmony-secondary-text fw-bold">
        Rate Sources
      </h5>
      <p>
        Harmony has collected these rates from a variety of sources, in a variety of currencies. The most common sources are:
      </p>
      <ul>
        <li>
          <h6 class="d-inline"><b class="harmony-accent-blue-grey-text">For origin, destination and supplemental rates:</b></h6> Annual rate submission from Harmony members and agents in countries where no Harmony member is present, specific rate requests for past RFP’s from Harmony members and agents, and external rate platforms such as PricePoint
        </li>
        <li>
          <h6 class="d-inline"><b class="harmony-accent-blue-grey-text">For air freight:</b></h6> Spot rates as listed by IATA on their TACT platform, and recent submissions by Harmony members and agents
        </li>
        <li>
          <h6 class="d-inline"><b class="harmony-accent-blue-grey-text">For ocean freight:</b></h6> Spot rates as listed by Drewry, an external sea freight benchmark tool, and recent submission by Harmony members and agents
        </li>
      </ul>
      <h5 class="mt-2 harmony-secondary-text fw-bold">
        Rate Validity
      </h5>
      <p>
        The rates Harmony provides are to be considered ‘current’ market rates, with limited validity. The member company using these rates must make their own judgement as to how these rates relate to the validity required by the ultimate customer, and apply appropriate risk premiums to cover for potential rate fluctuations.
      </p>
      <h5 class="mt-2 harmony-secondary-text fw-bold">
        Responsibility
      </h5>
      <ul>
        <li>
          Harmony will, to the best of their ability, verify that all rates are in line with the parameters commonly used (lump sum, per CBM, per 100lbs etc..), perform sanity checks, and apply automation where appropriate and possible to process the rates in the spreadsheets as required by the requesting Harmony member
        </li>
        <li>
          Harmony is not responsible for mistakes made and members cannot hold Harmony responsible for any consequence of having used incorrect rates or clerical errors
        </li>
        <li>
          The Harmony member that uses the rates as published by this rate tool, is solely responsible for the submission of the rates to their ultimate customer, and it is advised that the Harmony members performs additional benchmarks and sanity checks to ensure the rates used are correct
        </li>
      </ul>
    </div>
  </div>
</div>
<div class="modal-footer d-block">
  <div class="row">
    <div class="col-6 text-start">
      <div class="row mt-1 ms-2">
        <div class="col-12">
          <label class="pointer">
            <input type="checkbox" class="form-check-input" [(ngModel)]="quoteAccepted" /> I accept the Quote
          </label>
        </div>
      </div>
      <div class="row mt-1 ms-2 mb-1">
        <div class="col-12">
          <label class="pointer">
            <input type="checkbox" class="form-check-input" value="accept" [(ngModel)]="termsAccepted" /> I accept the Terms & Conditions stated above
          </label>
        </div>
      </div>
    </div>
    <div class="col-6 text-end pt-3">
      <button type="button" class="btn btn-outline-danger me-1" [disabled]="loading" (click)="decline()">Decline</button>
      <button type="button" class="btn btn-primary harmony-tertiary-button" [disabled]="disableSubmit()" (click)="accept()">Accept</button>
    </div>
  </div>
</div>

