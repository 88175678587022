<!---------------------->
<!------- Header ------->
<!---------------------->
<div
  class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-2 border-bottom sticky-header">
  <h1 class="portal-component-header">
    Manage Preferred Agents
  </h1>
  <div class="text-end">
    <button class="btn btn-sm btn-outline-primary harmony-tertiary-outline-button me-1"
            [title]="'Create a new preferred agent'" (click)="openPreferredAgentModal(true)">
      <fa-icon [icon]="faAdd"></fa-icon>
      Add Preferred Agent
    </button>
  </div>
</div>
<!---------------------->
<!------- Search ------->
<!---------------------->
<div class="row">
  <div class="col-1 display-field">
    <b>Loc. Type</b>
  </div>
  <div class="col-3">
    <select class="form-select form-select-sm pointer"
            [disabled]="!(locationTypes$ | async)"
            [(ngModel)]="searchRequest.LocationTypeId">
      <option [ngValue]="null" selected>All location types...</option>
      <option *ngFor="let lt of (locationTypes$ | async)" [ngValue]="lt.Id">{{ lt.Name }}</option>
    </select>
  </div>
  <div class="col-1 display-field">
    <b>Agent</b>
  </div>
  <div class="col-3">
    <app-agent-search class="d-inline"
                      [Identifier]="'AgentID'"
                      [SelectedID]="searchRequest.AgentId"
                      (OnSelect)="setAgent($event)">
    </app-agent-search>
  </div>
</div>
<div class="row mt-1">
  <div class="col-1 display-field">
    <div *ngIf="!locationType || (locationType && locationType?.Name == 'City')">
      <b>City</b>
    </div>
    <div *ngIf="locationType && locationType?.Name == 'Country'">
      <b>Country</b>
    </div>
    <div *ngIf="locationType && locationType?.Name == 'State'">
      <b>State</b>
    </div>
  </div>
  <div class="col-3">
    <div *ngIf="!locationType || (locationType && locationType?.Name == 'City')">
      <app-city-search class="d-inline"
                       [Identifier]="'CityID'"
                       [SelectedID]="searchRequest.CityId"
                       (OnSelect)="clearLocationIds(); searchRequest.CityId = $event">
      </app-city-search>
    </div>
    <div *ngIf="locationType && locationType?.Name == 'Country'">
      <app-country-search class="d-inline" [Identifier]="'CountryID'"
                          [SelectedID]="searchRequest.CountryId"
                          (OnSelect)="clearLocationIds(); searchRequest.CountryId = $event">
      </app-country-search>
    </div>
    <div *ngIf="locationType && locationType?.Name == 'State'">
      <app-state-search class="d-inline" [Identifier]="'StateID'"
                        [SelectedID]="searchRequest.StateId"
                        (OnSelect)="clearLocationIds(); searchRequest.StateId = $event;">
      </app-state-search>
    </div>
  </div>
  <div class="col-1 display-field">
    <b>Active</b>
  </div>
  <div class="col-3">
    <div class="float-start">
      <app-toggle-button [id]="'active-toggle'"
                         [checked]="searchRequest.Active"
                         [Placeholder]="'Toggle Active'">
      </app-toggle-button>
    </div>
  </div>
</div>
<hr class="my-1">
<div class="row">
  <div class="col-1 text-end offset-9 pe-0">
    <button class="btn btn-sm btn-primary harmony-primary-outline-button ms-2" [title]="'Reset Search'"
            (click)="resetSearch()">
      Reset
    </button>
  </div>
  <div class="col-2">
    <button class="btn btn-sm btn-primary harmony-tertiary-button w-100"
            (click)="searchPreferredAgents()">
      Search
    </button>
  </div>
</div>
<hr class="my-1">
<!------------------------->
<!----- Pagination Top----->
<!------------------------->
<div class="row ms-1 me-1">
  <div class="col-12">
    <app-pagination [showPageNumber]=true
                    [showRowsPerPage]=true
                    [paginationData]="paginationData"
                    (pageEvent)="onPage($event)"
                    [name]="'PrefAgentManagement'"
                    [page]="page">
    </app-pagination>
  </div>
</div>
<!---------------------->
<!------- Results ------>
<!---------------------->
<div class="row header-row mt-1">
  <div class="col-2 header-title border-split-left">
    Agent
  </div>
  <div class="col-1  header-title">
    Loc. Type
  </div>
  <div class="col-3  header-title">
    Location
  </div>
  <div class="col-1  header-title">
    Rate Type
  </div>
  <div class="col-2  header-title">
    Requesting
  </div>
  <div class="col-1  header-title">
    Start Date
  </div>
  <div class="col-1  header-title">
    End Date
  </div>
  <div class="col-1 text-end header-title border-split-right">
  </div>
</div>
<div class="row result-row p-1" *ngFor='let agent of paginationData.DataSet; let i = index'>
  <div class="col-2 overflow-hidden">
    {{ agent.AgentCompanyName }}
  </div>
  <div class="col-1 overflow-hidden">
    {{ agent.LocationTypeName }}
  </div>
  <div class="col-3 overflow-hidden">
    <b>{{ agent.StateName ? agent.StateName : agent.CityName ? agent.CityName : agent.CountryName }}</b>
  </div>
  <div class="col-1 overflow-hidden">
    {{ agent.RateTypeName ?? '-' }}
  </div>
  <div class="col-2 overflow-hidden">
    {{ agent.RequestingCompanyName ?? '-' }}
  </div>
  <div class="col-1 overflow-hidden">
    <small>{{ agent.StartDate ? (agent.StartDate | date: environment.FormattingStandards.ShortDateFormat) : '-' }}</small>
  </div>
  <div class="col-1 overflow-hidden">
    <small>{{ agent.EndDate ? (agent.EndDate | date: environment.FormattingStandards.ShortDateFormat) : '-' }}</small>
  </div>
  <div class="col-1 text-end overflow-hidden pe-0">
    <button class="btn btn-sm btn-primary harmony-secondary-outline-button me-0"
            [title]="'Edit Agent'"
            (click)="openPreferredAgentModal(false, agent)">
      <fa-icon [icon]="faEdit" size="sm"></fa-icon>
    </button>
  </div>
</div>
<!----- No Results View ----->
<div class="row mt-4" *ngIf="showNoResults">
  <div class="col-12 text-center harmony-grey-text">
    <fa-icon [icon]="faBoxOpen" size="2x"></fa-icon>
  </div>
  <div class="col-12 text-center harmony-grey-text mt-1">
    <h4>No results...</h4>
  </div>
</div>
<app-loader *ngIf="loading"></app-loader>
