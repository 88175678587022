import { Component } from '@angular/core';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-services-marketing',
  templateUrl: './services-marketing.component.html',
  styleUrls: ['./services-marketing.component.scss']
})
export class ServicesMarketingComponent {

  // Icons
  faPhone = faPhone;
  faEnvelope = faEnvelope;

  // General variables
  env = environment;
}
