import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {faAngleRight, faCircleDot, faMedal} from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { AdAuthService } from '../../../core/ad-auth-service/ad-auth.service';
import { PermissionCodes } from '../../../core/constants/permission-codes';

@Component({
  selector: 'app-qms-menu',
  templateUrl: './qms-menu.component.html',
  styleUrls: ['./qms-menu.component.scss']
})
export class QmsMenuComponent implements OnInit, AfterViewChecked {
  // Icons
  faQms = faMedal;
  faMenuItem = faCircleDot;
  faExpandable = faAngleRight;

  // Menu permissions
  qualityManual: boolean = false;

  // Component variables
  public logger = (this as any).constructor.name as string;

  constructor(public router: Router,
              public authService: AdAuthService,
              private cdRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    if (this.authService.CurrentUser) {
      this.setPermissions();
    }

    this.authService.CurrentUser$.subscribe(() => {
      this.setPermissions();
    });
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  setPermissions() {
    this.qualityManual = this.authService.CheckPermissionByCode(PermissionCodes.Qms_QualityManual);
  }
}
