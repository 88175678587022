<div class="dashboard-background"></div>
<div class="dashboard-wrapper">
    <div class="dashboard-content">
      <!-- For each card create below div with card component inside-->
      <app-dashboard-services-card class="dashboard-card my-4" [column]="3" [row]="5"></app-dashboard-services-card>
      <div class="one-by-two services-card my-4">
        <div class="services-card-header row">
          <div class="col-12">
            <!--Header-->
            <h5 class="harmony-primary-text fw-bold">Service Sheets</h5>
            <hr class="mt-0 mb-1">
          </div>
        </div>
        <div class="card-body row mt-2">
          <div class="col-12 document-list">
            <app-document-list [CategoryCode]="'SERVSHEE'" [Recents]="true"></app-document-list>
          </div>
        </div>
      </div>
    </div>
</div>

