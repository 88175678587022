<!---- Header ---->
<div class="modal-header" xmlns="http://www.w3.org/1999/html">
  <h4 class="modal-title" *ngIf="!category.Id || category.Id <= 0">
    Add category
  </h4>
  <h4 class="modal-title" *ngIf="category.Id && category.Id > 0">
    Update category
  </h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="close(true)"> </button>
</div>
<!-- Body -->
<div class="modal-body">
  <div class="row ms-1 me-1 my-1">
    <div class="col-3">
      <b>Category name</b>
    </div>
    <div class="col-9">
      <input class="form-control form-control-sm"
             type="text"
             [maxlength]="255"
             [autofocus]="true"
             [ngClass]="{'is-invalid': category?.Description?.length <= 0 && invalid}"
             [(ngModel)]="category.Description">
    </div>
  </div>
  <div class="row ms-1 me-1 my-1">
    <div class="col-3">
      <b>Order</b>
    </div>
    <div class="col-9">
      <input class="form-control form-control-sm"
             type="text"
             numbersOnly="3.0"
             [(ngModel)]="category.OrderBy">
    </div>
  </div>
</div>
<!---- Footer ---->
<div class="modal-footer" >
  <button *ngIf="!category.Id || category.Id <= 0" type="button" class="btn btn-primary harmony-tertiary-button" (click)="saveCategory()">Add</button>
  <button *ngIf="category.Id && category.Id > 0" type="button" class="btn btn-primary harmony-tertiary-button" (click)="saveCategory()">Update</button>
  <button type="button" class="btn btn-primary harmony-primary-button" (click)="close(false)">Close</button>
</div>
