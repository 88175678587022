<div class="modal-header">
  <h4 class="modal-title">
    {{ (EmailScheduleID == null ? 'Create new email schedule' : 'Editing email schedule: ' + schedule?.Name) }}
  </h4>
  <button type="button" class="btn-close" aria-label="Close" [disabled]="loading" (click)="close()">

  </button>
</div>
<div class="modal-body" *ngIf="schedule">
  <div class="row">
    <div class="col-6">
      <div class="row">
        <div class="col-12">
          <h5 class="harmony-primary-text mb-0">Configure Schedule</h5>
        </div>
      </div>
      <hr class="mt-1">
      <div class="row mt-1">
        <div class="col-4">
          <b>Schedule Name</b>
        </div>
        <div class="col-8">
          <input type="text"
                 class="form-control form-control-sm"
                 [placeholder]="'Enter a name'"
                 #scheduleName="ngModel"
                 [ngClass]="{ 'required': (validated || scheduleName.touched) && schedule.Name == null }"
                 [(ngModel)]="schedule.Name">
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-4">
          <b>Data Source</b>
        </div>
        <div class="col-8">
          <select class="form-select form-select-sm pointer"
                  [disabled]="!(dataSources$ | async)"
                  #dataSource="ngModel"
                  [ngClass]="{ 'required': (validated || dataSource.touched) && schedule.DataSourceID == null }"
                  [(ngModel)]="selectedDataSource"
                  (ngModelChange)="setDataSource()">
            <option [ngValue]="null" selected>Select a data source...</option>
            <option *ngFor="let dataSource of (dataSources$ | async)"
                    [ngValue]="dataSource">{{ dataSource.Name }}</option>
          </select>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-4">
          <b>Template</b>
        </div>
        <div class="col-8">
          <select class="form-select form-select-sm pointer"
                  [disabled]="!(emailTemplates$ | async)"
                  #emailTemplate="ngModel"
                  [ngClass]="{ 'required': (validated || emailTemplate.touched) && schedule.TemplateID == null }"
                  [(ngModel)]="selectedEmailTemplate"
                  (ngModelChange)="setTemplate()">
            <option [ngValue]="null" selected>Select a template...</option>
            <option *ngFor="let template of (emailTemplates$ | async)"
                    [ngValue]="template">{{ template.DisplayName }}</option>
          </select>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-4">
          <b>Start Date</b>
        </div>
        <div class="col-8">
          <app-date-picker [identifier]="'startDate'"
                           (onChange)="schedule.StartDate = $event" [value]="schedule.StartDate"
                           [showError]="validated && !schedule.StartDate"
                           [placeholder]="'Select a start date'">
          </app-date-picker>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-4">
          <b>End Date</b>
        </div>
        <div class="col-8">
          <app-date-picker [identifier]="'endDate'"
                           [showError]="validated && !schedule.EndDate"
                           (onChange)="schedule.EndDate = $event" [value]="schedule.EndDate"
                           [placeholder]="'Select an end date'">
          </app-date-picker>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="row">
        <div class="col-12">
          <h5 class="harmony-primary-text mb-0">Recurrence</h5>
        </div>
      </div>
      <hr class="mt-1">
      <!-- Schedule Time -->
      <div class="row">
        <div class="col-4">
          <b>Schedule Time</b>
        </div>
        <div class="col-8">
          <input type="time"
                 class="form-control form-control-sm time-control"
                 [(ngModel)]="scheduleTime"
                 placeholder="HH:mm"
                 min="00:00"
                 max="23:59"
                 (ngModelChange)="setScheduleTime()"
                 #scheduleTimePicker="ngModel"
                 [ngClass]="{ 'required': (validated || scheduleTimePicker.touched) && schedule.ScheduleTime == null }"
                 [required]="true">
        </div>
      </div>
      <!-- Recurrence Type -->
      <div class="row mt-1">
        <div class="col-4">
          <b>Recurrence Type</b>
        </div>
        <div class="col-8">
          <select class="form-select form-select-sm pointer"
                  [(ngModel)]="selectedRecurrence"
                  [ngClass]="{ 'required': validated && this.schedule.RecurrenceTypeID == null }"
                  [disabled]="!(recurrenceTypes$ | async)"
                  (ngModelChange)="setRecurrenceType()">
            <option [ngValue]="null" selected>Select a recurrence type...</option>
            <option *ngFor="let type of (recurrenceTypes$ | async)" [ngValue]="type">{{ type.Name }}</option>
          </select>
        </div>
      </div>
      <!-- Recurrence weekly options -->
      <ng-container *ngIf="selectedRecurrence?.Name == 'Weekly'">
        <div class="row mt-1">
          <div class="col-4">
            <b>Recurrence Options</b>
          </div>
          <div class="col-8">
            <div class="row">
              <div class="col-3" *ngFor="let option of selectedRecurrence.RecurrenceTypeOptions">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    [checked]="schedule.RecurrenceOptions.includes(option.Id)"
                    (change)="setWeeklyOption(option)"
                    [id]="option.Id"/>
                  <label class="form-check-label" [for]="option.Id">{{ option.Name }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <!-- Recurrence monthly options -->
      <ng-container *ngIf="selectedRecurrence?.Name == 'Monthly'">
        <div class="row mt-1">
          <div class="col-4">
            <b>Recurrence Options</b>
            <br>
            Runs per year: {{ this.schedule.RecurrenceOptions.length }}
          </div>
          <div class="col-4">
            <select class="form-select form-select-sm pointer mt-1"
                    [multiple]="true"
                    [size]="10"
                    [(ngModel)]="selectedMonths"
                    (change)="setMonthlyOptions()">
              <option *ngFor="let month of months" [ngValue]="month.Value">
                {{ month.Label }}
              </option>
            </select>
          </div>
          <div class="col-4">
            <select class="form-select form-select-sm pointer mt-1"
                    [multiple]="true"
                    [size]="10"
                    [(ngModel)]="selectedDaysOfMonth"
                    (change)="setMonthlyOptions()">
              <option *ngFor="let day of daysOfMonth" [ngValue]="day.Value">
                {{ day.Label }}
              </option>
            </select>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-6">
      <div class="row">
        <div class="col-4">
          <h5 class="harmony-primary-text mb-0 mt-2">Query</h5>
        </div>
      </div>
      <hr class="mt-1 mb-1">
      <div class="row">
        <div class="col-12 harmony-grey-text small">
          <small class="mb-0">
            <fa-icon [icon]="faImportant" size="xs"></fa-icon>
            The query <strong>MUST</strong> contain <code>[Subject]</code> and <code>[ToAddress]</code> in the result.
            Please ensure correct naming.
          </small><br>
          <small class="mb-0">
            <fa-icon [icon]="faImportant" size="xs"></fa-icon>
            The query can contain an <strong>OPTIONAL</strong> field <code>[ReferenceID]</code>. To create a reference
            between the data and the emails.
          </small><br>
          <small class="mb-1">
            <fa-icon [icon]="faImportant" size="xs"></fa-icon>
            The query <strong>CANNOT</strong> contain <code>ALTER</code>,<code>UPDATE</code>,<code>DROP</code>,<code>DELETE</code>
            or <code>INSERT</code> commands. Only <code>SELECT</code> is allowed.
          </small>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <textarea class="form-control form-control-sm w-100"
                    [rows]="15"
                    [placeholder]="queryPlaceholder"
                    [ngClass]="{ 'required': validated && !isValidQuery() }"
                    [(ngModel)]="schedule.Query">
          </textarea>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="row">
        <div class="col-8">
          <h5 class="harmony-primary-text mb-0 pt-2">Attachments</h5>
        </div>
        <div class="col-4 text-end">
          <button class="btn btn-sm btn-primary harmony-tertiary-outline-button"
                  [title]="'Upload attachment'"
                  (click)="attachment.click()">
            <fa-icon class="edit-picture" [icon]="faAdd"></fa-icon>
            Add
          </button>
          <input [hidden]="true" #attachment (change)="addAttachment($event)" type="file" accept="image/*">
        </div>
      </div>
      <hr class="mt-1 mb-1">
      <ng-container
        *ngIf="!newAttachments || newAttachments.length <= 0 || !scheduleAttachments || scheduleAttachments.length <= 0">
        <div class="row">
          <div class="col-12 harmony-grey-text small">
            <fa-icon [icon]="faInfo" size="sm"></fa-icon>
            Click on the "+ Add" button to add an attachment. Attachments will be included in each email sent by this
            schedule.
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="scheduleAttachments && scheduleAttachments.length > 0">
        <div class="row mt-1" *ngFor="let attachment of scheduleAttachments">
          <div class="col-10">
            {{ attachment.Reference }}/{{ attachment.Name }}
          </div>
          <div class="col-2 text-end">
            <button class="btn btn-sm btn-outline-primary harmony-primary-outline-button me-1"
                    (click)="downloadAttachment(attachment)">
              <fa-icon [icon]="faDownloadIcon" size="sm"></fa-icon>
            </button>
            <button class="btn btn-sm btn-outline-danger"
                    (click)="deleteAttachment(attachment.Id)">
              <fa-icon [icon]="faDelete" size="sm"></fa-icon>
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="newAttachments && newAttachments.length > 0">
        <div class="row mt-1" *ngFor="let attachment of newAttachments; let i = index">
          <div class="col-10">
            {{ attachment.Name }}
          </div>
          <div class="col-2 text-end">
            <button class="btn btn-sm btn-outline-danger"
                    (click)="removeAttachment(i)">
              <fa-icon [icon]="faDelete" size="sm"></fa-icon>
            </button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-outline-danger"
          style="position: absolute; left: 0.75em;"
          [disabled]="loading"
          *ngIf="schedule.Id > 0"
          (click)="deleteSchedule()">
    Delete Schedule
  </button>
  <button class="btn btn-primary harmony-tertiary-button"
          [disabled]="loading"
          (click)="save()">
    Save
  </button>
  <button type="button"
          class="btn btn-primary harmony-primary-button"
          [disabled]="loading"
          (click)="close()">
    Close
  </button>
</div>
