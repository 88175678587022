<div class="modal-header">
  <h4 class="modal-title" id="rate-group-capture-modal-title">
    Capture Rates | Reference: {{ rateGroup?.ID }}
  </h4>
  <button type="button" class="btn-close" aria-label="Close" [disabled]="loading" (click)="activeModal.dismiss('Dismiss')">

  </button>
</div>
<div class="modal-body ps-4 pe-4">
  <app-loader *ngIf="loading" [Width]="15"></app-loader>
  <div class="row" *ngIf="rateGroup">
    <div class="col-6">
      <div class="row">
        <div class="col-12">
          <h5 class="harmony-primary-text fw-bold mb-0">
            Details
          </h5>
        </div>
      </div>
      <hr class="my-1">
      <div class="row mt-1">
        <div class="col-4">
          <b>Request Type</b>
        </div>
        <div class="col-8 overflow-hidden">
          {{ rateGroup.RateRequestTypeName ? rateGroup.RateRequestTypeName : '-' }}
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-4">
          <b>Mode Type</b>
        </div>
        <div class="col-8 overflow-hidden">
          {{ rateGroup.ModalityTypeName }}
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-4">
          <b>Project</b>
        </div>
        <div class="col-8 overflow-hidden">
          {{ rateGroup.ProjectName }}
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-4">
          <b>Valid From</b>
        </div>
        <div class="col-8 overflow-hidden">
          <ng-container *ngIf="CanEdit">
            <app-date-picker [identifier]="'fromDate'"
                             [value]="rateGroup.ValidFrom"
                             (onChange)="changeValidFromDate($event)"
                             [minDate]="today"
                             [placeholder]="'Select date valid from'">
            </app-date-picker>
          </ng-container>
          <ng-container *ngIf="!CanEdit">
            {{ rateGroup.ValidFrom ? (rateGroup.ValidFrom | date: environment.FormattingStandards.ShortDateFormat) : '-' }}
          </ng-container>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-4">
          <b>Valid To</b>
        </div>
        <div class="col-8 overflow-hidden">
          <ng-container *ngIf="CanEdit || CanEditDate">
            <app-date-picker [identifier]="'toDate'"
                             (onChange)="rateGroup.ValidTo = $event"
                             [value]="rateGroup.ValidTo"
                             [minDate]="minValidTo"
                             [placeholder]="'Select date valid until'">
            </app-date-picker>
          </ng-container>
          <ng-container *ngIf="!CanEdit && !CanEditDate">
            {{ rateGroup.ValidTo ? (rateGroup.ValidTo | date: environment.FormattingStandards.ShortDateFormat) : '-' }}
          </ng-container>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="row">
        <div class="col-12 overflow-hidden">
          <h5 class="harmony-primary-text fw-bold mb-0">
            Origin
          </h5>
        </div>
        <hr class="my-1">
        <div class="col-4 my-1">
          <b>City</b>
        </div>
        <div class="col-8 overflow-hidden my-1">
          {{ rateGroup.OriginCityName ? rateGroup.OriginCityName : '-' }}
        </div>
        <div class="col-4 my-1">
          <b>Port</b>
        </div>
        <div class="col-8 my-1">
          <ng-container *ngIf="CanEdit && !disableOriginPort">
            <app-port-search
              class="d-inline"
              [Identifier]="'DeparturePort'"
              [SelectedID]="rateGroup.DeparturePortID"
              [IsAirport]="rateGroup.ModalityTypeID <= 1"
              [ShowError]="false"
              (OnSelect)="rateGroup.DeparturePortID = $event">
            </app-port-search>
          </ng-container>
          <ng-container *ngIf="!CanEdit || disableOriginPort">
            {{ rateGroup.DeparturePortID ? rateGroup.DeparturePortCode : '-' }}
          </ng-container>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <h5 class="harmony-primary-text fw-bold mb-0">
            Destination
          </h5>
        </div>
        <hr class="my-1">
        <div class="col-4 my-1">
          <b>City</b>
        </div>
        <div class="col-8 overflow-hidden my-1">
          {{ rateGroup.DestinationCityName ? rateGroup.DestinationCityName : '-' }}
        </div>
        <div class="col-4 my-1">
          <b>Port</b>
        </div>
        <div class="col-8 my-1">
          <ng-container *ngIf="CanEdit && !disableDestinationPort">
            <app-port-search
              class="d-inline"
              [Identifier]="'ArrivalPort'"
              [SelectedID]="rateGroup.ArrivalPortID"
              [IsAirport]="rateGroup.ModalityTypeID <= 1"
              [ShowError]="false"
              (OnSelect)="rateGroup.ArrivalPortID = $event">
            </app-port-search>
          </ng-container>
          <ng-container *ngIf="!CanEdit || disableDestinationPort">
            {{ rateGroup.ArrivalPortID ? rateGroup.ArrivalPortCode : '-' }}
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <hr class="my-1">
  <small class="harmony-grey-text" *ngIf="rateGroup">
    <p class="mb-1">
      <fa-icon [icon]="faInfo"></fa-icon> Below are the services that form this rate group.
      Expand each service by clicking the <b>Rates</b> button.
    </p>
    <p class="mb-1">
      <fa-icon [icon]="faInfo"></fa-icon> All rate values are <b>0</b> by default,
      please populate these with the correct rates from <i><b>{{ rateGroup.AgentCompanyName }}</b></i>.
    </p>
  </small>
  <div class="row mt-3" *ngIf="rateGroup">
    <div class="col-12">
      <ul class="nav nav-tabs" id="capture-rates" role="tablist">
        <li class="nav-item" role="presentation">
          <a class="nav-link active"
             id="service-rates-tab"
             data-bs-toggle="tab"
             href="#service-rates"
             role="tab"
             aria-controls="service-rates"
             aria-selected="true">
            Services & Rates
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a class="nav-link"
             id="inclusions-tab"
             data-bs-toggle="tab"
             href="#inclusions"
             role="tab"
             aria-controls="inclusions"
             aria-selected="false">
            Inclusions & Exclusions
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a class="nav-link"
             id="comments-tab"
             data-bs-toggle="tab"
             href="#comments"
             role="tab"
             aria-controls="comments"
             aria-selected="false">
            Comments
            <fa-icon [icon]="faInfo"
                      class="harmony-accent-yellow-text ms-1 pointer"
                      [title]="'Comments added'"
                      *ngIf="rateGroup.Comment && rateGroup.Comment.length > 0">
            </fa-icon>
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a class="nav-link"
             id="test-rates-tab"
             data-bs-toggle="tab"
             href="#test-rates"
             role="tab"
             aria-controls="test-rates"
             aria-selected="false">
            Test Rates
          </a>
        </li>
      </ul>
      <div class="tab-content mt-2" id="capture-rates-content">
        <!--------------------------------->
        <!------- Services & Rates -------->
        <!--------------------------------->
        <div class="tab-pane fade show active" id="service-rates" role="tabpanel" aria-labelledby="service-rates-tab">
          <div class="row mb-5">
            <div class="col-12">
              <!------------------------->
              <!-- Rate Group Services -->
              <!------------------------->
              <div class="row mt-1 header-row">
                <div class="col-4 col-compress header-title border-split-left">
                  Service Type
                </div>
                <div class="col-4 col-compress header-title">
                  Calculation Method
                </div>
                <div class="col-2 col-compress header-title">
                  Location Type
                </div>
                <div class="col-2 col-compress header-title text-end border-split-right">
                </div>
              </div>
              <div class="row result-row border-bottom" *ngFor="let rgs of rateGroup.RateGroupServices; let i = index">
                <div class="col-12" *ngIf="rgs.Active === true">
                  <div class="row">
                    <div class="col-4 overflow-hidden col-compress" style="align-self: center !important;">
                      {{ rgs.ServiceName }}
                    </div>
                    <div class="col-4 overflow-hidden col-compress" style="align-self: center !important;">
                      {{ rgs.CalculationMethodName }}
                    </div>
                    <div class="col-2 overflow-hidden col-compress" style="align-self: center !important;">
                      {{ rgs.ServiceLocationID == null ? '-' : (rgs.ServiceLocationID == 1 ? 'Origin' : 'Destination') }}
                    </div>
                    <div class="col-2 text-end col-compress" style="align-self: center !important;">
                      <fa-icon [icon]="faInfo"
                               size="xl"
                               class="harmony-accent-yellow-text me-2 position-relative pointer"
                               style="top: 2px;"
                               *ngIf="rgs.Comment?.length > 0"
                               [title]="rgs.Comment"></fa-icon>
                      <fa-icon [icon]="faRequired"
                               class="text-danger me-2 pointer"
                               [title]="'No rates provided'"
                               *ngIf="!hasRates(rgs)"></fa-icon>
                      <button class="btn btn-sm btn-primary harmony-accent-blue-grey-outline-button w-50"
                              type="button"
                              data-bs-toggle="collapse"
                              [disabled]="rgs.ServiceRates.length <= 0"
                              [title]="'Manage Rates'"
                              [attr.data-bs-target]="'#RGS_' + rgs.ID"
                              [attr.aria-expanded]="false"
                              [attr.aria-controls]="'RGS_' + rgs.ID">
                        {{ rgs.ServiceRates ? rgs.ServiceRates.length : 0 }} Rates
                      </button>
                    </div>
                  </div>
                  <!------------------->
                  <!-- Service Rates -->
                  <!------------------->
                  <div class="row">
                    <div class="col-12 ps-0 pe-0">
                      <div class="collapse multi-collapse ps-0 pe-0 mt-1 mb-2" [id]="'RGS_' + rgs.ID">
                        <div class="card card-body p-1">
                          <div class="row m-0 header-row sub-result-row">
                            <div class="col-1 header-title-small border-split-left">
                              <small>Limit Type</small>
                            </div>
                            <div class="col-1 header-title-small">
                              <small>Quantity 1</small>
                            </div>
                            <div class="col-1 header-title-small">
                              <small>Unit Type 1</small>
                            </div>
                            <div class="col-2 header-title-small">
                              <small>Measurement Type 1</small>
                            </div>
                            <div class="col-1 header-title-small">
                              <small>Quantity 2</small>
                            </div>
                            <div class="col-1 header-title-small">
                              <small>Unit Type 2</small>
                            </div>
                            <div class="col-2 header-title-small">
                              <small>Measurement Type 2</small>
                            </div>
                            <div class="col-1 header-title-small">
                              <small>Currency</small>
                            </div>
                            <div class="col-1 header-title-small">
                              <small>Rate</small>
                            </div>
                            <div class="col-1 header-title-small text-end border-split-right">
                            </div>
                          </div>
                          <div class="row m-0 result-row" *ngFor="let sr of rgs.ServiceRates">
                            <div class="col-1 overflow-hidden col-compress" style="align-self: center !important;">
                              {{ getRateLimitTypeName(sr.RateLimitTypeID) }}
                            </div>
                            <div class="col-1 col-compress" style="align-self: center !important;">
                              {{ sr.RateQuantity_1 ?? '-' }}
                            </div>
                            <div class="col-1 overflow-hidden col-compress" style="align-self: center !important;">
                              {{ pricingService.GetUnitTypeName(sr.RateUnitTypeID_1) }}
                            </div>
                            <div class="col-2 overflow-hidden col-compress" style="align-self: center !important;">
                              {{ pricingService.GetUnitTypeName(sr.MeasurementUnitTypeID_1) }}
                            </div>
                            <div class="col-1 overflow-hidden col-compress" style="align-self: center !important;">
                              {{ sr.RateQuantity_2 ?? '-' }}
                            </div>
                            <div class="col-1 overflow-hidden col-compress" style="align-self: center !important;">
                              {{ pricingService.GetUnitTypeName(sr.RateUnitTypeID_2) }}
                            </div>
                            <div class="col-2 overflow-hidden col-compress" style="align-self: center !important;">
                              {{ pricingService.GetUnitTypeName(sr.MeasurementUnitTypeID_2) }}
                            </div>
                            <div class="col-1 col-compress">
                              <select class="form-select form-select-sm pointer"
                                      [disabled]="!(Currencies$ | async) || !sr.Active || !CanEdit"
                                      [(ngModel)]="sr.CurrencyID"
                                      (ngModelChange)="setAllCurrencies(sr.CurrencyID)">
                                <option [ngValue]="null">None...</option>
                                <option *ngFor="let tc of (Currencies$ | async)" [ngValue]="tc.CurrencyId">{{ tc.Currency.Code }}</option>
                              </select>
                            </div>
                            <div class="col-2 col-compress">
                              <div class="row">
                                <div class="col-12">
                                  <input type="text"
                                         class="form-control form-control-sm"
                                         numbersOnly="6.2"
                                         [disabled]="!sr.Active || !CanEdit"
                                         [(ngModel)]="sr.Rate">
                                </div>
                              </div>
                            </div>
                          </div>
                          <app-no-result [showNoResults]="rgs.ServiceRates?.length <= 0" [noResultText]="'No rates for \'' + rgs.ServiceName + '\''"></app-no-result>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <app-no-result [showNoResults]="rateGroup.RateGroupServices?.length <= 0" [noResultText]="'No services found...'"></app-no-result>
            </div>
          </div>
        </div>
        <!----------------------------------->
        <!----- Inclusions & Exclusions ----->
        <!----------------------------------->
        <div class="tab-pane fade" id="inclusions" role="tabpanel" aria-labelledby="inclusions-tab">
          <div class="row mb-5">
            <div class="col-12">
              <div class="row mt-1 header-row">
                <div class="col-10 header-title border-split-left">
                  Service Name
                </div>
                <div class="col-2 header-title text-end border-split-right">
                  Included
                </div>
              </div>
              <app-loader *ngIf="loading" [Width]="15"></app-loader>
              <ng-container *ngIf="rateGroupInclusions">
                <div class="row result-row" *ngFor='let incl of rateGroupInclusions'>
                  <div class="col-10 align-self-center overflow-hidden" [title]="incl.Description">
                    {{ incl.Description }}
                  </div>
                  <div class="col-2 overflow-hidden text-end">
                    <div class="form-check form-switch float-end form-switch-md pointer">
                      <input class="form-check-input me-1"
                             *ngIf="CanEdit"
                             type="checkbox"
                             role="switch"
                             [id]="'switch-' + incl.Id"
                             [title]="incl.IsIncluded ? 'Exclude' : 'Include'"
                             [(ngModel)]="incl.IsIncluded">
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="!rateGroupInclusions || rateGroupInclusions.length <= 0">
                <div class="row">
                  <div class="col-12 text-center">
                    There are no inclusions/exclusions for this rate group.
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <!----------------------------------->
        <!------------ Comments ------------->
        <!----------------------------------->
        <div class="tab-pane fade" id="comments" role="tabpanel" aria-labelledby="comments-tab">
          <div class="row mb-5">
            <div class="col-12">
              <textarea rows="8"
                        class="form-control form-control-sm w-100"
                        [disabled]="!CanEdit"
                        [(ngModel)]="rateGroup.Comment"
                        [placeholder]="'Add comments here...'">
              </textarea>
            </div>
          </div>
        </div>
        <!----------------------------------->
        <!----------- Test Rates ------------>
        <!----------------------------------->
        <div class="tab-pane fade" id="test-rates" role="tabpanel" aria-labelledby="test-rates-tab">
          <app-test-rate-group [RateGroup]="rateGroup"></app-test-rate-group>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <app-loader *ngIf="loading" [Width]="15"></app-loader>
  <button type="button"
          class="btn btn-primary harmony-tertiary-button"
          *ngIf="CanEdit || CanEditDate"
          [disabled]="disableSave()"
          (click)="accept()">Save</button>
  <button type="button" class="btn btn-primary harmony-primary-button" (click)="close()">Close</button>
</div>

