<!---- Header ---->
<div class="modal-header">
  <h4 class="modal-title">
    Verify Company Information
  </h4>
</div>
<div class="modal-body">
  <div class="d-flex">
    <p class="mb-2">
      To keep your company information accurate, we require you to periodically verify that the information is
      correct. Update your information where applicable and click 'Confirm & Save'.
      <br>
      Company name and address changes will be submitted for review and not reflect immediately.
    </p>
  </div>
  <hr class="mt-0 mb-0">
  <div class="row">
    <div class="col-6 border-end pe-3">
      <!------------Company Name------------->
      <div class="row mt-3">
        <div class="col-12">
          <div class="row">
            <div class="col-12">
              <span class="fw-bold harmony-primary-text">Company Name</span>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <small class="harmony-grey-text lh-sm d-block">
                This name will be visible to other Harmony members on the Harmony Portal, the Harmony Corporate Website and
                the Harmony Netting.
                It is the name as you will be known to other Harmony members.
              </small>
            </div>
          </div>
          <hr class="mt-1 mb-1">
          <div class="row">
            <div class="col-12">
              <input type="text"
                     class="form-control form-control-sm"
                     [placeholder]="'Company Name'"
                     [ngClass]="{'is-invalid': !company.Name && invalid}"
                     [(ngModel)]="company.Name">
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-12">
              <small class="harmony-grey-text lh-sm d-block">
                <span class="fw-bold harmony-tertiary-text">Please note:</span> This is <b>NOT</b> the Legal Company Name which will be applied in your contractual agreements
                with Harmony or the Billing Name
                which is used on the invoices you receive from Harmony
              </small>
            </div>
          </div>
        </div>
      </div>
      <!------------Physical Address------------->
      <div class="row mt-3">
        <div class="col-12">
          <span class="fw-bold harmony-primary-text">Address Information</span>
          <br>
          <small class="harmony-grey-text lh-sm d-block">
            The physical address will be visible on the Harmony Portal and the Harmony Corporate Website
          </small>
          <hr class="mt-1 mb-1">
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-12">
          <input type="text"
                 class="form-control form-control-sm"
                 [placeholder]="'Physical Address 1'"
                 [ngClass]="{'is-invalid': !company.AddressPhysical && invalid}"
                 [(ngModel)]="company.AddressPhysical">
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-12">
          <input type="text"
                 class="form-control form-control-sm"
                 [placeholder]="'Physical Address 2'"
                 [(ngModel)]="company.AddressPhysical2">
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-8">
          <input type="text"
                 class="form-control form-control-sm"
                 [placeholder]="'City'"
                 [ngClass]="{'is-invalid': !company.CityPhysical && invalid}"
                 [(ngModel)]="company.CityPhysical">
        </div>
        <div class="col-4">
          <input type="text"
                 class="form-control form-control-sm"
                 [placeholder]="'ZIP Code'"
                 [ngClass]="{'is-invalid': !company.ZipCodePhysical && invalid}"
                 [(ngModel)]="company.ZipCodePhysical">
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-12">
          <app-country-search
            class="d-inline"
            [Identifier]="'physical-country-Id'"
            [ShowError]="(!company.CountryIdPhysical || company.CountryIdPhysical <= 0) && invalid"
            [SelectedID]="company.CountryIdPhysical"
            (OnSelectObject)="setCountry($event)">
          </app-country-search>
        </div>
      </div>
    </div>
    <div class="col-6 ps-3">
      <!------------Service Information------------->
      <div class="row mt-3">
        <div class="col-12">
          <span class="fw-bold harmony-primary-text">Service Information</span>
          <br>
          <small class="harmony-grey-text lh-sm d-block">
            The Service Information will be used on the Harmony Corporate Website to show the services you provide for
            the direct quote generator
          </small>
          <hr class="mt-1 mb-1">
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-5 fw-bold">
          Moving Provider
        </div>
        <div class="col-7 text-start">
          <div class="form-check form-switch form-switch-md float-start">
            <input class="form-check-input me-3 float-end"
                   type="checkbox"
                   role="switch"
                   [id]="'moving-company'"
                   [checked]="company.IsMovingServiceProvider"
                   (change)="setMovingService($event)">
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-5 fw-bold">
          Relocation Provider
        </div>
        <div class="col-7 text-start">
          <div class="form-check form-switch form-switch-md float-start">
            <input class="form-check-input me-3 float-end"
                   type="checkbox"
                   role="switch"
                   [id]="'relocation-company'"
                   [checked]="company.IsRelocationServiceProvider"
                   (change)="setRelocationService($event)">
          </div>
        </div>
      </div>
      <!------------Location------------->
      <div class="row mt-3">
        <div class="col-12">
          <span class="fw-bold harmony-primary-text">Location</span>
          <br>
          <small class="harmony-grey-text lh-sm d-block">
            The location information will be visible on the Harmony Portal and the Harmony Corporate Website
          </small>
          <hr class="mt-1 mb-1">
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-5">
          <b>Longitude</b>
        </div>
        <div class="col-7">
          <input type="text"
                 [placeholder]="'GPS coordinates in numbers'"
                 numbersOnly="3.6" class="form-control form-control-sm"
                 [ngClass]="{'is-invalid': !company.Longitude && invalid}"
                 [(ngModel)]="company.Longitude">
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-5">
          <b>Latitude</b>
        </div>
        <div class="col-7">
          <input type="text"
                 [placeholder]="'GPS coordinates in numbers'"
                 numbersOnly="3.6" class="form-control form-control-sm"
                 [ngClass]="{'is-invalid': !company.Latitude && invalid}"
                 [(ngModel)]="company.Latitude">
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <span class="fw-bold harmony-primary-text">General Information</span>
          <br>
          <small class="harmony-grey-text lh-sm d-block">
            The General information will be visible on the Harmony Portal and the Harmony Corporate Website
          </small>
          <hr class="mt-1 mb-1">
        </div>
      </div>
      <app-company-details [companyId]="company.CompanyId" [branchId]="null" [propertyCategory]="'Member'"
                           [autoSave]="false" [showInfo]="true"
                           (OnChange)="verifyRequest.CompanyDetails = $event"></app-company-details>
      <app-company-details [companyId]="company.CompanyId" [branchId]="null" [propertyCategory]="'Contact'"
                           [autoSave]="false" [showInfo]="true"
                           (OnChange)="verifyRequest.CompanyDetails = $event"></app-company-details>
    </div>
  </div>
  <app-loader *ngIf="loading"></app-loader>
</div>
<!---- Footer ---->
<div class="modal-footer mt-2">
  <button type="button" class="btn btn-primary harmony-primary-button" (click)="close()">I'll do it later</button>
  <button type="button" class="btn btn-primary harmony-tertiary-button" (click)="save()">Confirm & Save</button>
</div>
