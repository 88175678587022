<!---------------------->
<!------- Header ------->
<!---------------------->
<div
  class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom sticky-header">
  <h1 class="portal-component-header">
    Risk Assessment Items
  </h1>
</div>
<!--------------------------->
<!-- Active Risk Assessment-->
<!--------------------------->
<app-loader *ngIf="loading"></app-loader>
<ng-container>
  <div class="row">
    <div class="col-3">
      <input class="form-control form-control-sm"
             type="text"
             placeholder="Search risk assessment items..."
             [(ngModel)]="searchText">
    </div>
    <div class="col-2 offset-5 text-end pe-0">
      <button class="btn btn-sm btn-primary harmony-primary-outline-button ms-2"
              [title]="'Reset search'"
              (click)="resetSearch()">
        Reset
      </button>
    </div>
    <div class="col-2 mb-1">
      <button class="btn btn-sm btn-primary harmony-tertiary-button w-100 float-end"
              (click)="searchItems()">
        Search
      </button>
    </div>
  </div>
  <hr class="my-1">
</ng-container>
<ng-container *ngIf="isAdmin && !loading">
  <app-no-result [showNoResults]="noResult"></app-no-result>
  <div class="accordion" id="accordion-question" *ngIf="riskAssessmentGroups && riskAssessmentItems && !loading">
    <div class="row my-1">
      <div class="col-2 offset-10 text-end d-flex justify-content-end">
        <button class="btn btn-sm btn-primary harmony-tertiary-outline-button"
                [title]="'Add new group'"
                (click)="addGroup()">
          <fa-icon class="edit-picture" [icon]="faAdd"></fa-icon>
          Add new group
        </button>
      </div>
    </div>
    <ng-container *ngFor='let group of riskAssessmentGroups; let c = index;'>
      <div class="row" *ngIf="!(getGroupItems(group.Id).length <= 0 && searchText?.length > 0)">
        <div class="col-12">
          <div class="card my-1">
            <div class="card-header p-0" [attr.id]="'heading-' + c">
              <div class="row pointer category-edit-wrapper" (click)="chapterBtn.click()">
                <div class="col-10 text-start">
                  <h3 class="mb-0">
                    <button class="btn chapter-header mb-0 pt-2 fw-bold collapsed" #chapterBtn
                            type="button"
                            data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse-' + c"
                            [attr.aria-expanded]="c == 0" [attr.aria-controls]="'collapse-' + c">
                      {{ group.Description }}
                    </button>
                  </h3>
                </div>
                <div class="col-2 text-end d-flex justify-content-end">
                  <div class="py-1">
                    <button class="btn btn-sm btn-primary harmony-primary-outline-button me-1 category-edit-button"
                            (click)="editGroup(group);$event.stopPropagation();"
                            [title]="'Edit group'">
                      <fa-icon [icon]="faEdit"></fa-icon>
                    </button>
                    <button class="btn btn-sm btn-outline-danger me-1 category-edit-button"
                            (click)="deleteGroup(group.Id);$event.stopPropagation();"
                            [disabled]="getGroupItems(group.Id).length > 0"
                            [title]="'Delete group'">
                      <fa-icon [icon]="faDelete"></fa-icon>
                    </button>
                    <button class="btn btn-sm btn-primary harmony-tertiary-outline-button me-1"
                            (click)="addItem(group);$event.stopPropagation();"
                            [title]="'Add risk assessment item'">
                      <fa-icon [icon]="faPlus"></fa-icon> Add item
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div [attr.id]="'collapse-' + c" class="collapse" [attr.aria-labelledby]="'heading-' + c"
                 data-bs-parent="#accordion-question">
              <!-- For each Assessment item group a card body  -->
              <div class="card-body">
                <div class="row m-0 header-row">
                  <div class="col-1 header-title-small">
                    <small>Item number</small>
                  </div>
                  <div class="col-5 header-title-small">
                    <small>Topic</small>
                  </div>
                  <div class="col-5 header-title-small">
                    <small>Subject</small>
                  </div>
                  <div class="col-1 header-title-small text-end">
                  </div>
                </div>
                <div class="row result-row my-0" *ngFor='let item of getGroupItems(group.Id);'
                     [ngClass]="{'even': item % 2 === 0}">
                  <div class="col-1">
                    {{ item.ItemNumber }}
                  </div>
                  <div class="col-5 overflow-hidden">
                    {{ item.Topic }}
                  </div>
                  <div class="col-5 overflow-hidden">
                    {{ item.Subject }}
                  </div>
                  <div class="col-1 text-end">
                    <button *ngIf="item" class="btn btn-sm btn-primary harmony-primary-outline-button me-1"
                            (click)="openRiskAssessmentItemModal(item)"
                            [title]="'Edit item'">
                      <fa-icon [icon]="faEdit"></fa-icon>
                    </button>
                    <button *ngIf="authService.CheckPermissionByCode(PermissionCodes.HSE_Admin)"
                            class="btn btn-sm btn-outline-danger"
                            [title]="'Delete item'" (click)="deleteRiskAssessmentItem(item)">
                      <fa-icon [icon]="faDelete"></fa-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>


