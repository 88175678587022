import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../core/guards/auth-guard.guard';
import { PermissionCodes } from '../../core/constants/permission-codes';
import { DashboardContainerServicesComponent } from './services-dashboard/dashboard-container-services/dashboard-container-services.component';
import { ServicesBusinessIntelligenceComponent } from './services-business-intelligence/services-business-intelligence.component';
import { ServicesClientServicesComponent } from './services-client-services/services-client-services.component';
import { ServicesComplianceComponent } from './services-compliance/services-compliance.component';
import { ServicesCorporateSalesComponent } from './services-corporate-sales/services-corporate-sales.component';
import { ServicesCustomerSatisfactionComponent } from './services-customer-satisfaction/services-customer-satisfaction.component';
import { ServicesEurotunnelComponent } from './services-eurotunnel/services-eurotunnel.component';
import { ServicesInsuranceComponent } from './services-insurance/services-insurance.component';
import { ServicesMarketingComponent } from './services-marketing/services-marketing.component';
import { ServicesNettingComponent } from './services-netting/services-netting.component';
import { ServicesIntegrationComponent } from './services-integration/services-integration.component';

const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardContainerServicesComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.Services_Menu
    },
  },
  {
    path: 'business-intelligence',
    component: ServicesBusinessIntelligenceComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.Services_Menu
    },
  },
  {
    path: 'client-services',
    component: ServicesClientServicesComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.Services_Menu
    },
  },
  {
    path: 'compliance',
    component: ServicesComplianceComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.Services_Menu
    },
  },
  {
    path: 'corporate-sales',
    component: ServicesCorporateSalesComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.Services_Menu
    },
  },
  {
    path: 'customer-satisfaction',
    component: ServicesCustomerSatisfactionComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.Services_Menu
    },
  },
  {
    path: 'eurotunnel',
    component: ServicesEurotunnelComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.Services_Menu
    },
  },
  {
    path: 'insurance',
    component: ServicesInsuranceComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.Services_Menu
    },
  },
  {
    path: 'marketing',
    component: ServicesMarketingComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.Services_Menu
    },
  },
  {
    path: 'netting',
    component: ServicesNettingComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.Services_Menu
    },
  },
  {
    path: 'integration',
    component: ServicesIntegrationComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.Services_Menu
    },
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ServicesRoutingModule { }
