<div class="row mt-2">
  <div class="col-4">
    <input type="text"
           class="form-control form-control-sm"
           [(ngModel)]="searchParam.SearchText"
           (keyup.enter)="search()"
           [placeholder]="'Search permissions...'">
  </div>
  <div class="col-2">
    <select name="component-module"
            class="form-select form-select-sm"
            id="component-module"
            [(ngModel)]="searchParam.ModuleId"
            (ngModelChange)="search()">
      <option [ngValue]="null">All modules</option>
      <option *ngFor="let module of (modules$ | async)" [ngValue]="module.Id">
        {{module.Name}}
      </option>
    </select>
  </div>
  <div class="col-1 offset-3 text-end pe-0">
    <button class="btn btn-sm btn-outline-primary harmony-primary-outline-button"
            (click)="resetSearch()">
      Reset
    </button>
  </div>
  <div class="col-2">
    <button class="btn btn-sm btn-primary harmony-tertiary-button w-100"
            (click)="search()">
      Search
    </button>
  </div>
</div>
<hr class="my-1">
<!--------------------->
<!---- Pagination ----->
<!--------------------->
<div class="row ms-1 me-1">
  <div class="col-12" *ngIf="page">
    <app-pagination (pageEvent)="onPageChange($event)"
                    [page]="page"
                    [paginationData]=paginationData
                    [name]="'PermissionManagement'"
                    [showPageNumber]=true
                    [showRowsPerPage]=true></app-pagination>
  </div>
</div>
<!--------------------->
<!------ Results ------>
<!--------------------->
<div class="row mt-1 header-row">
  <div class="col-3 header-title border-split-left pointer"
       (appOrder)="onOrderChange($event)"
       data-column="Name"
       data-order="asc">
    Name
    <fa-icon [hidden]="order?.OrderBy != 'Name'" [icon]="faSortDown" style="bottom: 3px;" class="ms-1 position-relative"></fa-icon>
  </div>
  <div class="col-1 header-title pointer"
       (appOrder)="onOrderChange($event)"
       data-column="Module.Name"
       data-order="asc">
    Module
    <fa-icon [hidden]="order?.OrderBy != 'Module.Name'" class="ms-1 position-relative"></fa-icon>
  </div>
  <div class="col-1 header-title pointer"
       (appOrder)="onOrderChange($event)"
       data-column="Code"
       data-order="asc">
    Code
    <fa-icon [hidden]="order?.OrderBy != 'Code'" class="ms-1 position-relative"></fa-icon>
  </div>
  <div class="col-5 header-title pointer"
       (appOrder)="onOrderChange($event)"
       data-column="Description"
       data-order="asc">
    Description
    <fa-icon [hidden]="order?.OrderBy != 'Description'" class="ms-1 position-relative"></fa-icon>
  </div>
  <div class="col-2 header-title pointer border-split-right"
       (appOrder)="onOrderChange($event)"
       data-column="DateCreated"
       data-order="asc">
    Date Created
    <fa-icon [hidden]="order?.OrderBy != 'DateCreated'" class="ms-1 position-relative"></fa-icon>
  </div>
</div>
<!---------------------->
<!------- Loader ------->
<!---------------------->
<ng-container *ngIf="loading === true">
  <app-loader class="mt-3"></app-loader>
</ng-container>
<!----------------------->
<!------- Results ------->
<!----------------------->
<div class="row result-row" *ngFor="let component of paginationData?.DataSet; let i = index">
  <div class="col-3 overflow-hidden">
    <b>{{ component.Name }}</b>
  </div>
  <div class="col-1 overflow-hidden">
    {{ component.ModuleName ?? '-' }}
  </div>
  <div class="col-1 overflow-hidden">
    <code>{{ component.Code }}</code>
  </div>
  <div class="col-5 overflow-hidden" [title]="component.Description">
    {{ component.Description }}
  </div>
  <div class="col-1 overflow-hidden">
    <small>{{ component.DateCreated | date: environment.FormattingStandards.ShortDateFormat }}</small>
  </div>
  <div class="col-1 text-end pe-0">
    <button class="btn btn-sm btn-primary harmony-primary-outline-button me-1"
            [title]="'Edit permission: ' + component.Name"
            (click)="managePermission(component.Id, false)">
      <fa-icon [icon]="faEditIcon" size="sm"></fa-icon>
    </button>
    <button class="btn btn-sm btn-outline-danger"
            [title]="'Delete permission'"
            (click)="deletePermission(component.Id)">
      <fa-icon [icon]="faDelete" size="sm"></fa-icon>
    </button>
  </div>
</div>
