<!---------------------->
<!------- Header ------->
<!---------------------->
<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom sticky-header">
  <h1 class="portal-component-header">
    Search Members
  </h1>
</div>
<div class="row">
  <div class="member-search-wrapper">
    <div class="row">
      <div class="col-12">
        <div class="row" *ngIf="companyData && companyData.length > 0">
          <div class="col-12 member-map-wrapper">
            <div class="member-map-search">
              <div class="row">
                <div class="col-12">
                  <input
                    class="form-control"
                    type="search"
                    placeholder="Find members..."
                    aria-label="Search"
                    [(ngModel)]="searchText"
                    (input)="searchMembers()">
                </div>
              </div>
              <hr class="harmony-grey-text">
              <div class="member-map-results">
                <div class="row" *ngIf="mapData">
                  <div class="col-12">
                    <div class="member-result-row" *ngFor="let member of mapData" (click)="goToMemberLocation(member)">
                      <div class="row result-row pointer" [title]="'View member: ' + member.Name">
                        <div class="col-1 align-self-center">
                          <img width="25" height="25" class="float-end" src="../../../../assets/icons/map/map-marker-default.svg" alt="default" *ngIf="!member.IsMovingServiceProvider && !member.IsRelocationServiceProvider">
                          <img width="25" height="25" class="float-end" src="../../../../assets/icons/map/map-marker-default.svg" alt="moving-services" *ngIf="member.IsMovingServiceProvider && !member.IsRelocationServiceProvider">
                          <img width="25" height="25" class="float-end" src="../../../../assets/icons/map/map-marker-relocation.svg" alt="relocation-services" *ngIf="!member.IsMovingServiceProvider && member.IsRelocationServiceProvider">
                          <img width="25" height="25" class="float-end" src="../../../../assets/icons/map/map-marker-all.svg" alt="all-services" *ngIf="member.IsMovingServiceProvider && member.IsRelocationServiceProvider">
                        </div>
                        <div class="col-10 ps-0 overflow-hidden">
                          <p class="result-name">
                            {{ member.Name }}
                          </p>
                          <small class="small">
                            {{ member.Country }}, {{ member.City ?? '-' }}
                          </small>
                        </div>
                        <div class="col-1 text-center align-self-center">
                          <a class="nav-link pointer member-profile-link"
                             [routerLink]="['/network/member/' + member.CompanyID]">
                            <fa-icon [icon]="faView"
                                     size="1x"
                                     class="me-0 harmony-grey-text"></fa-icon>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" *ngIf="!mapData && mapData.length <= 0">
                  <div class="col-12">
                    No results...
                  </div>
                </div>
              </div>
            </div>
            <div class="member-map">
              <app-member-map [CompanyData]="mapData"
                              [Zoom]="zoom"
                              [Center]="{ lat: centerLat, lng: centerLong }">
              </app-member-map>
            </div>
          </div>
        </div>
        <div class="row mt-3 mx-2">
          <div class="col-12 fw-bold text-end">
            <div class="d-inline mx-2 harmony-teal-text">
              <img width="25" height="25"
                   ngSrc="../../../../assets/icons/map/map-marker-default.svg"
                   alt="moving-services">
              Moving Services
            </div>
            <div class="d-inline mx-2 harmony-purple-text">
              <img width="25" height="25"
                   ngSrc="../../../../assets/icons/map/map-marker-relocation.svg"
                   alt="relocation-services">
              Relocation Services
            </div>
            <div class="d-inline mx-2 harmony-yellow-text">
              <img width="25" height="25"
                   ngSrc="../../../../assets/icons/map/map-marker-all.svg"
                   alt="all-services">
              Moving and Relocation Services
            </div>
          </div>
        </div>
        <div class="row text-center mt-1">
          <div class="col-sm-12 col-md-6 position-relative">
            <img class="member-count-icon" src="../../../../assets/images/vector/combo-truck.svg" alt=""/>
            <p class="map-count-header">
              {{ companyData ? companyData.length : '-'}}
            </p>
            <p class="map-count-sub-header harmony-teal-text">
              Members in network
            </p>
          </div>
          <div class="col-sm-12 col-md-6 position-relative">
            <img class="member-count-icon" src="../../../../assets/images/vector/combo-world.svg" alt=""/>
            <p class="map-count-header">
              {{ getCountryCount() }}
            </p>
            <p class="map-count-sub-header harmony-teal-text">
              Countries located in
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
