<!---------------------->
<!------- Header ------->
<!---------------------->
<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom sticky-header">
  <h1 class="portal-component-header">
    Review requested changes
  </h1>
  <div class="justify-content-end" *ngIf="articleReview">
    <button (click)="respondToRequest()"
            [disabled]="loading" [title]="'Request changes for review by the author'"
            class="btn btn-sm btn-outline-primary harmony-secondary-outline-button me-1">
      Re-submit for approval
    </button>
    <button (click)="approveArticleChanges()"
            [disabled]="loading" [title]="'Approve the article without changes'"
            class="btn btn-sm btn-outline-primary harmony-accent-green-outline-button ms-2 me-1">
      <fa-icon [icon]="faThumbsUp" size="lg"></fa-icon>
      Approve
    </button>
    <button (click)="rejectChanges()"
            [disabled]="loading" [title]="'Reject the article'"
            class="btn btn-sm btn-outline-primary harmony-accent-coral-outline-button me-1">
      <fa-icon [icon]="faThumbsDown" size="lg"></fa-icon>
      Reject
    </button>
    <button (click)="returnToOverview()"
            [disabled]="loading"
            [title]="'Back to article management'"
            class="btn btn-sm btn-primary harmony-primary-outline-button ms-2 me-1">
      Back
    </button>
  </div>
</div>
<ng-container *ngIf="articleLogs?.length > 0">
  <div class="row mb-2">
    <div class="col-6 lh-sm">
      <small class="harmony-grey-text lh-sm">
        Your article has been reviewed by an administrator from Harmony and they have made some suggested updates to your article.
        Information about the reviewer and a comment is provided on the right hand side. Below you will see a comparison between your original article and the updated one. Please review the suggested changes, you can then approve or reject these changes accordingly.
        If you would like to request for the article to be reviewed again, you can do so by clicking the 'Request- Re-Approval' button.
      </small>
    </div>
    <div class="col-6">
      <div class="row">
        <div class="col-12 align-self-center">
          <h5 class="harmony-secondary-text fw-bold mb-0">Review feedback</h5>
        </div>
      </div>
      <hr class="my-1">
      <div class="row mt-2">
        <div class="col-8 align-self-center">
          <p class="mb-0 fw-bold" style="margin-bottom: -5px !important;">
            {{ articleLogs[0].UserDisplayName }}
          </p>
        </div>
        <div class="col-4 harmony-grey-text align-self-center">
          <small class="float-end" style="font-size: 9pt;">{{ articleLogs[0].DateCreated | date:env.FormattingStandards.LongDateFormat }}</small>
        </div>
        <div class="col-12 align-self-center">
          <p class="mb-0">{{ articleLogs[0].Comment }}</p>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="articleReview">
  <app-article-review-comparison [ArticleReview]="articleReview"></app-article-review-comparison>
</ng-container>
