<!---- Header ---->
<div class="modal-header">
  <h4 class="modal-title" *ngIf="isNew">
    Add Question
  </h4>
  <h4 class="modal-title" *ngIf="!isNew">
    Edit Question
  </h4>
</div>
<!---- Add/Edit ---->
<form [formGroup]="auditForm">
  <div class="modal-body">
    <div class="row mb-2">
      <div class="col-2">
        Audit Question <span class="text-danger">*</span>
      </div>
      <div class="col-10">
        <div class="required-text" *ngIf="auditForm.controls.value.hasError('minlength') && showError">
          A question must be at least 4 characters long.
        </div>
        <textarea [formControlName]="'value'"
                  name="audit-question"
                  appAutocompleteOff
                  rows="2"
                  class="form-control"
                  [placeholder]="'A question must be at least 4 characters long.'"
                  [ngClass]="{'is-invalid': auditForm.controls.value.touched && auditForm.controls.value.invalid}"></textarea>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-2">
        Review Question <span class="text-danger">*</span>
      </div>
      <div class="col-10">
        <select class="form-select"
                [formControlName]="'question'" [title]="'The question relates to one asked in the annual review'"
                [ngClass]="{'is-invalid': auditForm.controls.question.touched && auditForm.controls.question.invalid}"
                (selectionchange)="setAuditQuestion(question)">
          <option [ngValue]="null" disabled selected>Select the related question out of the annual review</option>
          <option *ngFor="let q of questionSearchFields" [value]="q.QuestionId">
            {{ q.Question }}
          </option>
        </select>
      </div>
    </div>
    <div class="row mb-2" *ngIf="audit && audit.QuestionId > 0">
      <div class="col-2">
        Requirement
      </div>
      <div class="col-10">
         <textarea disabled
                   name="requirement"
                   appAutocompleteOff
                   rows="2"
                   class="form-control" [value]="audit.Requirement"></textarea>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-2">
        Order
      </div>
      <div class="col-4">
        <input [formControlName]="'order'"
               type="text"
               class="form-control"
               placeholder="Enter an optional order appearance number" numbersOnly="4.0">
      </div>
      <div class="col-2">
        Label <span class="text-danger">*</span>
      </div>
      <div class="col-4">
        <input [formControlName]="'label'" type="text" class="form-control" placeholder="Enter an label/question number"
               [ngClass]="{'is-invalid': auditForm.controls.label.touched && auditForm.controls.label.invalid}">
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-2">
        Type <span class="text-danger">*</span>
      </div>
      <div class="col-4">
        <div class="required-text" *ngIf="auditForm.controls.type.hasError('required') && showError">
          Selecting a type is required.
        </div>
        <select [formControlName]="'type'" required name="type" id="type" class="form-control"
                [ngClass]="{'is-invalid': auditForm.controls.type.touched && auditForm.controls.type.invalid}">
          <option disabled selected>Select a answer type</option>
          <option [ngValue]="1">Text</option>
          <option [ngValue]="2">Date</option>
          <option [ngValue]="3">Document</option>
        </select>
      </div>
      <div class="col-2" *ngIf="auditForm.controls.type.value === 3">
        Documents Required <span class="text-danger">*</span>
      </div>
      <div class="col-4" *ngIf="auditForm.controls.type.value === 3">
        <input [formControlName]="'requiredDocumentAmount'"
               type="text"
               class="form-control"
               placeholder="Enter total number of documents required" numbersOnly="2.0"
               [ngClass]="{'is-invalid': auditForm.controls.requiredDocumentAmount.touched &&
                auditForm.controls.requiredDocumentAmount.invalid &&
                 auditForm.controls.type.value === 3}">
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-2">
        Reference
      </div>
      <div class="col-4">
        <input [formControlName]="'reference'"
               type="text"
               class="form-control"
               placeholder="Enter an optional reference">
      </div>
    </div>
  </div>
</form>
<!---- Footer ---->
<div class="modal-footer" *ngIf="!isNew">
  <button type="button" class="btn btn-primary harmony-tertiary-button" (click)="update()">Save</button>
  <button type="button" class="btn btn-primary harmony-primary-button" (click)="close(false)">Close</button>
</div>
<div class="modal-footer" *ngIf="isNew">
  <button type="button" class="btn btn-primary harmony-tertiary-button" (click)="add()">Save</button>
  <button type="button" class="btn btn-primary harmony-primary-button" (click)="close(false)">Close</button>
</div>
