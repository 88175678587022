<div class="card-wrapper">
  <div class="card-content">
    <div class="card-header row">
      <div class="col-12 align-self-center pt-2">
        <h3 class="harmony-teal-text fw-bold mb-0">{{ company?.LegalName }}</h3>
      </div>
    </div>
    <div class="card-body row">
      <div class="row mt-2" *ngIf="company.IsMovingServiceProvider || company.IsRelocationServiceProvider">
        <div class="col-12">
          <b class="harmony-teal-text">
            {{ company.IsMovingServiceProvider == true ? 'Moving' : '' }}
            {{ company.IsMovingServiceProvider == true && company.IsRelocationServiceProvider == true ? ' & ' : ''}}
            {{ company.IsRelocationServiceProvider == true ? 'Relocation' : '' }}
            services provider
          </b>
        </div>
      </div>
      <div class="row mt-0 pb-3">
        <div class="col-5 align-self-center">
          <div class="row">
            <div class="col-12 harmony-blue-text">
              <fa-icon [icon]="faLocation" [size]="'lg'" class="me-1"></fa-icon>
              {{ company.CountryPhysical }} {{ company.CountryPhysicalIso ? ('(' + company.CountryPhysicalIso + ')') : '' }}
            </div>
          </div>
          <div class="row mt-2" *ngIf="companyTelephone?.PropertyValue">
            <div class="col-12 harmony-blue-text">
              <fa-icon [icon]="faPhoneIcon" [size]="'lg'" class="me-1"></fa-icon>
              {{ companyTelephone?.PropertyValue }}
            </div>
          </div>
          <div class="row mt-2" *ngIf="companyUrl?.PropertyValue">
            <div class="col-12 harmony-blue-text">
              <fa-icon [icon]="faWebsiteIcon" [size]="'lg'" class="me-1"></fa-icon>
              <span (click)="openNewWindow(companyUrl.PropertyValue)"
                    class="harmony-blue-link">
                {{ companyUrl?.PropertyValue.replace(' ', '') }}
              </span>
            </div>
          </div>
          <div class="row mt-2" *ngIf="companyEmail?.PropertyValue">
            <div class="col-12 harmony-blue-text">
              <fa-icon [icon]="faEmailIcon" [size]="'lg'" class="me-1"></fa-icon>
              <a href="mailto:{{companyEmail?.PropertyValue}}"
                 class="harmony-blue-link">
                {{ companyEmail?.PropertyValue }}
              </a>
            </div>
          </div>
        </div>
        <div class="col-3 align-self-center">
          <div class="row">
            <div class="col-12">
              <div class="info-box member-data">
                <b>Member Since</b>
                <br>
                <small>{{ company?.StartDate | date: env.FormattingStandards.ShortDateFormat  }}</small>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="info-box netting-id">
                <b>Netting ID</b>
                <br>
                <small>{{ company?.CoProcessId ?? '-' }}</small>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4 align-self-center text-center">
          <img [src]="getCompanyLogo()"
               class="member-logo"
               [alt]="company?.Name">
        </div>
      </div>
    </div>
  </div>
</div>
