<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom sticky-header">
  <h1 class="portal-component-header">
    Document Management
  </h1>
  <div>
    <button
      class="btn btn-sm btn-outline-primary harmony-tertiary-outline-button"
      (click)="addDocument()">
      <fa-icon [icon]="faAdd"></fa-icon> New Document
    </button>
  </div>
</div>
<div class="row mt-1">
  <div class="col-12">
    <div class="row">
      <div class="col-3">
        <div class="row">
          <div class="col-12">
            <app-company-search class="d-inline"
                                [Identifier]="'DocumentCompanyID'"
                                [MembersOnly]="true"
                                [SelectedID]="searchParam.CompanyId"
                                (OnSelect)="setCompanyId($event)">
            </app-company-search>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="row">
          <div class="col-12">
            <app-user-search class="d-inline"
                             [Identifier]="'DocumentUserID'"
                             [SelectedID]="searchParam.DocumentUserId"
                             (OnSelect)="setUserId($event)">
            </app-user-search>
          </div>
        </div>
      </div>
    </div>
    <hr class="mt-1 mb-1">
    <div class="row mt-1 mb-1">
      <div class="col-4">
        <input type="text"
               class="form-control form-control-sm"
               [(ngModel)]="searchParam.SearchText"
               [placeholder]="'Search...'">
      </div>
      <div class="col-2">
        <div class="row">
          <div class="col-12">
            <select class="form-select form-select-sm pointer"
                    [disabled]="!(documentCategories$ | async)"
                    [(ngModel)]="searchParam.DocumentCategoryId">
              <option [ngValue]="null" selected>All categories...</option>
              <option *ngFor="let category of (documentCategories$ | async)" [ngValue]="category.Id">{{ category.Name }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-1 offset-3 text-end pe-0">
        <button class="btn btn-sm btn-outline-primary harmony-primary-outline-button"
                (click)="resetSearch()">
          Reset
        </button>
      </div>
      <div class="col-2">
        <button class="btn btn-sm btn-primary harmony-tertiary-button w-100"
                (click)="searchDocuments()">
          Search
        </button>
      </div>
    </div>
    <hr class="mt-1 mb-0">
  </div>
</div>
<!------------------->
<!---- Pagination --->
<!------------------->
<div class="row ms-1 me-1 mb-2">
  <div class="col-12">
    <app-pagination [showPageNumber]=true
                    [showRowsPerPage]=true
                    [paginationData]=paginationData
                    (pageEvent)="onPage($event)"
                    [name]="'DocumentsOverview'"
                    [page]="page">
    </app-pagination>
  </div>
</div>
<div class="row mt-2 header-row">
  <div class="col-3 header-title pointer border-split-left"
       (appOrder)="orderSearch($event)"
       data-column="DisplayName"
       data-order="asc">
    Display Name
    <fa-icon [hidden]="orderParam.OrderBy != 'DisplayName'" class="ms-2 position-relative"></fa-icon>
  </div>
  <div class="col-1 header-title pointer"
       (appOrder)="orderSearch($event)"
       data-column="DocumentCategory.Name"
       data-order="asc">
    Category
    <fa-icon [hidden]="orderParam.OrderBy != 'DocumentCategory.Name'" class="ms-2 position-relative"></fa-icon>
  </div>
  <div class="col-1 header-title pointer"
       (appOrder)="orderSearch($event)"
       data-column="FileName"
       data-order="asc">
    File Name
    <fa-icon [hidden]="orderParam.OrderBy != 'FileName'" class="ms-2 position-relative"></fa-icon>
  </div>
  <div class="col-2 header-title pointer"
       (appOrder)="orderSearch($event)"
       data-column="Description"
       data-order="asc">
    Description
    <fa-icon [hidden]="orderParam.OrderBy != 'Description'" class="ms-2 position-relative"></fa-icon>
  </div>
  <div class="col-1 header-title pointer"
       (appOrder)="orderSearch($event)"
       data-column="Company.Name"
       data-order="asc">
    Company
    <fa-icon [hidden]="orderParam.OrderBy != 'Company.Name'" class="ms-2 position-relative"></fa-icon>
  </div>
  <div class="col-1 header-title pointer"
       (appOrder)="orderSearch($event)"
       data-column="DocumentUser.Name"
       data-order="asc">
    User
    <fa-icon [hidden]="orderParam.OrderBy != 'DocumentUser.Name'" class="ms-2 position-relative"></fa-icon>
  </div>
  <div class="col-1 header-title pointer"
       (appOrder)="orderSearch($event)"
       data-column="DateCreated"
       data-order="asc">
    Created
    <fa-icon [hidden]="orderParam.OrderBy != 'DateCreated'" [icon]="faSortDesc" style="bottom: -3px;" class="ms-2 position-relative"></fa-icon>
  </div>
  <div class="col-1 header-title pointer"
       (appOrder)="orderSearch($event)"
       data-column="CreatedByUser.Name"
       data-order="asc">
    By
    <fa-icon [hidden]="orderParam.OrderBy != 'CreatedByUser.Name'" class="ms-2 position-relative"></fa-icon>
  </div>
  <div class="col-1 header-title border-split-right">

  </div>
</div>
<ng-container *ngIf="!loading && paginationData.DataSet.length > 0">
  <div class="row result-row" *ngFor='let document of paginationData.DataSet; let i = index'>
    <div class="col-3 overflow-hidden" [title]="document.DisplayName">
      <app-document-type-icon [Extension]="document.Extension"></app-document-type-icon>
      <small class="ms-1">{{ document.DisplayName }}</small>
    </div>
    <div class="col-1 overflow-hidden" [title]="document.DocumentCategoryName">
      <b><small>{{ document.DocumentCategoryName }}</small></b>
    </div>
    <div class="col-1 overflow-hidden" [title]="document.FileName">
      <small>{{ document.FileName }}</small>
    </div>
    <div class="col-2 overflow-hidden" [title]="document.Description">
      <small>{{ document.Description }}</small>
    </div>
    <div class="col-1 overflow-hidden">
      <small>{{ document.CompanyName ? document.CompanyName : '-' }}</small>
    </div>
    <div class="col-1 overflow-hidden">
      <small>{{ document.DocumentUserName ? document.DocumentUserName : '-' }}</small>
    </div>
    <div class="col-1 overflow-hidden">
      <small>{{ document.DateCreated ? (document.DateCreated | date: env.FormattingStandards.LongDateFormat) : '-' }}</small>
    </div>
    <div class="col-1 overflow-hidden">
      <small>{{ document.CreatedByUserName }}</small>
    </div>
    <div class="col-1 text-end pe-1">
      <button class="btn btn-sm btn-primary harmony-primary-outline-button"
              [title]="'View/Edit document'"
              (click)="editDocument(document)">
        <fa-icon [icon]="faEdit" size="sm"></fa-icon>
      </button>
      <button class="btn btn-sm ms-1 btn-primary harmony-secondary-outline-button"
              [title]="'Download document'"
              (click)="downloadDocument(document)">
        <fa-icon [icon]="faDownloadIcon" size="sm"></fa-icon>
      </button>
      <button class="btn btn-sm ms-1 btn-outline-danger"
              [title]="'Delete document'"
              (click)="deleteDocument(document.Id)">
        <fa-icon [icon]="faDelete" size="sm"></fa-icon>
      </button>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!loading && paginationData.DataSet.length <= 0">
  <!-- No Results -->
  <div class="row mt-4">
    <div class="col-12 text-center harmony-grey-text">
      <fa-icon [icon]="faEmpty" size="2x"></fa-icon>
    </div>
    <div class="col-12 text-center harmony-grey-text mt-1">
      <h4>No results...</h4>
    </div>
  </div>
</ng-container>
<!---------------------->
<!------- Loader ------->
<!---------------------->
<div class="row mt-4" *ngIf="loading">
  <div class="col-12 text-center">
    <app-loader [Width]="50"></app-loader>
  </div>
</div>
