<div class="dashboard-background"></div>
<div class="dashboard-wrapper">
  <div class="dashboard-content">
    <!-- Customer Satisfaction Measurement -->
    <div class="three-by-two d-flex align-items-start services-card">
      <div>
        <div class="services-card-header row">
          <div class="col-12">
            <!--Header-->
            <h5 class="harmony-primary-text fw-bold">Customer Satisfaction Measurement</h5>
            <hr class="mt-0 mb-1">
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-3">
            <div class="justify-content-center m-2">
              <img [src]="env.SiteUrls.AzureStorageBaseURL + env.ContainerNames.WebImagesServices + 'CS02B.jpg'"
                   class="service-image-rounded-borders"
                   alt="Customer Satisfaction Measurement">
            </div>
          </div>
          <div class="col-9 service-content">
            <p>
            Customer feedback is one of the most valuable tools we have within the Harmony Network to improve our service level. Obtaining useful customer feedback, however, is not always that obvious.
            </p>
            <p>
            The amount of feedback received, and the usefulness of that feedback, partly depends on the way we approach our customers.
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- Customer Satisfaction Contact -->
    <div class="one-by-one services-card">
      <div class="services-card-header row">
        <div class="col-12">
          <!--Header-->
          <h5 class="harmony-primary-text fw-bold">Customer Satisfaction Contact</h5>
          <hr class="mt-0 mb-1">
        </div>
      </div>
      <div class="row">
        <div class="col-12 ms-4">
          <div class="row">
            <div class="col-12 service-contact">
              Mr Willem-Frederik van der Heijden
            </div>
          </div>
          <div class="row my-1">
            <div class="col-1">
              <fa-icon [icon]="faEnvelope"></fa-icon>
            </div>
            <div class="col-11 nav-item">
              <a href="mailto:w.heijden@harmonyrelo.com?Subject=Request information about Services/Customer Satisfaction" class="harmony-secondary-link">w.heijden@harmonyrelo.com</a>
            </div>
          </div>
          <div class="row my-1">
            <div class="col-1">
              <fa-icon [icon]="faPhone"></fa-icon>
            </div>
            <div class="col-11">
              <a href="callto:+31 294 299 066" class="harmony-secondary-link">+31 294 299 066</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Questionnaire Examples -->
    <div class="one-by-one services-card">
      <div class="services-card-header row">
        <div class="col-12">
          <!--Header-->
          <h5 class="harmony-primary-text fw-bold">Questionnaire Examples</h5>
          <hr class="mt-0 mb-1">
        </div>
      </div>
      <div class="card-body row mt-2">
        <div class="col-12 service-content document-list">
          <app-document-list [ModuleName]="'Services'" [CategoryCode]="'SERVQUEX'" [Recents]="true"></app-document-list>
        </div>
      </div>
    </div>
    <!-- Quality Questionnaire -->
    <div class="three-by-two d-flex align-items-start services-card">
      <div>
        <div class="services-card-header row">
          <div class="col-12">
            <!--Header-->
            <h5 class="harmony-primary-text fw-bold">Quality Questionnaire</h5>
            <hr class="mt-0 mb-1">
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-3">
            <div class="justify-content-center m-2">
              <img [src]="env.SiteUrls.AzureStorageBaseURL + env.ContainerNames.WebImagesServices + 'QQ_02.png'"
                   class="service-image-rounded-borders"
                   alt="Quality Questionnaire">
            </div>
          </div>
          <div class="col-9 service-content">
            <p>
              Harmony Relocation has developed a Quality Questionnaire (Transferee Satisfaction Survey) which is linked to the standard Key Performance Indicators (KPI) but at the same time allows to add additional information depending on your (clients) wishes. Setup can be done dynamically in principle allowing one survey to be used for multiple clients even though their individual wishes differ.
            </p>
            <p>
              Proactive alerting via email on issues like negative feedback or indication of claim. Based on rules defined by you, emails can be sent to the appropriate person for prompt follow-up. Our response rate is between 45% to 55%, which well exceeds the 30%, that is generally accepted as being a satisfactory response rate.
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- Reporting & Integration -->
    <div class="three-by-four d-flex align-items-start services-card">
      <div>
        <div class="services-card-header row">
          <div class="col-12">
            <!--Header-->
            <h5 class="harmony-primary-text fw-bold">Reporting & Integration</h5>
            <hr class="mt-0 mb-1">
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-3">
            <div class="justify-content-center m-2">
              <img [src]="env.SiteUrls.AzureStorageBaseURL + env.ContainerNames.WebImagesServices + 'Customer_Satisfaction_Survey_Irvine_activity_cs.jpg'"
                   class="service-image-rounded-borders"
                   alt="Reporting & Integration">
            </div>
          </div>
          <div class="col-9 service-content">

            <p>
              <span><h5 class="harmony-secondary-text fw-bold">Reporting</h5></span>
            Feedback received can be analysed via the available report functionality. Here you can choose from 7 different graph types, and drill down to the individual surveys for details. Different reports can be created on the same survey.
            You can also share reports with your corporate clients via a (password protected) link.
            Results can be downloaded to excel for a detailed data analysis.
            </p>

            <br>


            <p>
              <span><h5 class="harmony-secondary-text fw-bold">Integrating Questionaire in Move Management System</h5></span>
            RedSky has the option to send quality surveys to your customers and collect data from the online survey and directly show this in the Service Order.
            Per client, a specific email lay-out can be created using the following set up:
            </p>
            <ul class="service-list">
              <li><span>Sender</span></li>
              <li><span>Recipient</span></li>
              <li><span>Email body</span></li>
              <li><span>Subject</span></li>
              <li><span>Number of follow-ups</span></li>
              <li><span>Delay between follow-ups</span></li>
            </ul>
            <br>
            <p>
            Once set up, all is handled automatically from sending the initial mail, the reminders and importing the feedback into RedSky. Your coordinators can spend their time talking to customers instead of manually processing feedback.
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- Customised Questionnaires -->
    <div class="three-by-two d-flex align-items-start services-card">
      <div>
        <div class="services-card-header row">
          <div class="col-12">
            <!--Header-->
            <h5 class="harmony-primary-text fw-bold">Customised Questionnaires</h5>
            <hr class="mt-0 mb-1">
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-3">
            <div class="justify-content-center m-2">
              <img [src]="env.SiteUrls.AzureStorageBaseURL + env.ContainerNames.WebImagesServices + 'Survey_Branding_cs.jpg'"
                   class="service-image-rounded-borders"
                   alt="Customised Questionnaires">
            </div>
          </div>
          <div class="col-9 service-content">
            <p>
            When communicating with your clients it is important that from start to finish they encounter the same brand. For your customer satisfaction survey this is no different. The survey form can be branded according to your Corporate Identity giving the customer a trusted feeling from start to finish.
            </p>
            <p>
            If your organisation operates under different local brands it is possible to still use the same survey as the layout can dynamically alter depending on settings.
            </p>
            <p>
            Some corporate clients would like to brand the satisfaction survey in their own Corporate Identity. With the available flexibility this request can be met.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
