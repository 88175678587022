import { Component,  OnInit } from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../../../../../services/alert.service';
import { AdAuthService } from '../../../../../core/ad-auth-service/ad-auth.service';
import { ApiService } from '../../../../../services/api.service';
import { BehaviorSubject, Subject } from 'rxjs';
import {IAuthUser, IPermission, IRole, IRoleFeature} from '../../../../../services/models/auth.model';
import { takeUntil } from 'rxjs/operators';
import {faMinusSquare, faPlusSquare, faUserEdit} from '@fortawesome/free-solid-svg-icons';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { IContactCard } from '../../../../../services/models/user.model';
import * as jwt_decode from 'jwt-decode';
import {UserManagementModalComponent} from '../../../user-management/user-management-modal/user-management-modal.component';
import {IApiResponse} from '../../../../../shared/models/api-reponse.model';

@Component({
  selector: 'app-role-modal',
  templateUrl: './role-modal.component.html',
  styleUrls: ['./role-modal.component.scss']
})
export class RoleModalComponent implements OnInit {
  // Icons
  protected readonly faAdd = faPlusSquare;
  protected readonly faRemove = faMinusSquare;
  protected readonly faInfo = faCircleQuestion;
  protected readonly faEdit = faUserEdit;

  // Component Variables
  public RoleId: number;
  public IsNew: boolean;
  Role: IRole;
  components: IPermission[] = [];
  invalid: boolean = false;
  moduleId: number = null;
  activeModuleId: number = null;
  userSearchText: string = null;

  // Lookup data
  modules$ = this.authService.Modules$.asObservable();
  components$ = this.authService.Components$.asObservable();
  roles$ = this.authService.Roles$.asObservable();
  users$: BehaviorSubject<IContactCard[]> = new BehaviorSubject<IContactCard[]>([]);
  filteredUsers$: BehaviorSubject<IContactCard[]> = new BehaviorSubject<IContactCard[]>([]);

  // General variables
  loading: boolean = false;
  private unsubscribe: Subject<any> = new Subject<any>();

  constructor(public activeModal: NgbActiveModal,
              private api: ApiService,
              private authService: AdAuthService,
              private alertService: AlertService,
              private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.components$.subscribe(data => {
      this.components = data;
    });
    if (this.IsNew) {
      this.Role = {
        Id: null,
        DateCreated: new Date(),
        DateModified: null,
        Active: true,
        Name: null,
        Description: null,
        ParentRoleId: null,
        Features: []
      } as IRole;
    } else {
      this.loadRole();
    }
  }

  loadRole() {
    this.loading = true;
    this.api.get(`Role/Single/${this.RoleId}`).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data: IRole) => {
        if (data) {
          this.Role = Object.assign({}, data);
          this.getRoleUsers();
        }
        this.loading = false;
      }, error: (err: IApiResponse) => {
        this.alertService.error(err?.Meta?.Message);
        this.loading = false;
      }
    });
  }

  getRoleUsers() {
    if (this.Role?.Id > 0) {
      this.authService.GetUsersByRoleId(this.Role.Id).pipe(
        takeUntil(this.unsubscribe)
      ).subscribe({
        next: (data: IContactCard[]) => {
          if (data) {
            this.users$.next(data);
            this.filterUsers();
          }
        }
      });
    }
  }

  filterUsers() {
    if (this.userSearchText && this.userSearchText.length > 0) {
      const filteredUsers = this.users$.getValue()
        .filter((m) => m.DisplayName.toLocaleLowerCase().includes(this.userSearchText.toLocaleLowerCase()) ||
                       m.Username.toLocaleLowerCase().includes(this.userSearchText.toLocaleLowerCase()));
      this.filteredUsers$.next(filteredUsers);
    } else {
      const filteredUsers = this.users$.getValue().slice();
      this.filteredUsers$.next(filteredUsers);
    }
  }

  editUser(username: string) {
    this.loading = true;
    this.authService.GetUserPermissions(username).subscribe({
      next: (data) => {
        if (data) {
          this.loading = false;
          const token = data.Token;
          // Decode JWT token data section and set the current user
          const decoded: any = jwt_decode.default(token);
          const userParam = {
            UserId: +decoded.UserID,
            User: JSON.parse(decoded.User),
            UserRoles: JSON.parse(decoded.UserRoles),
            Permissions: JSON.parse(decoded.Permissions),
            UserCompanies: decoded.UserCompanies.length > 0 ? JSON.parse(decoded.UserCompanies) : []
          } as IAuthUser;
          // Open modal to Add/Edit a module
          const modalRef = this.modalService.open(UserManagementModalComponent, {size: 'xl', backdrop: 'static'});
          modalRef.componentInstance.authUser = userParam;
          // On modal close, read the result and apply logic
          modalRef.result.then((result: string) => {
            if (result) {
              this.alertService.success(result);
            }
          }, () => {
          });
        } else {
          this.alertService.error('Permission data for ' + username + ' could not be loaded.');
        }
        this.loading = false;
      },
      error: () => {
        this.alertService.error('Permission data for ' + username + ' could not be loaded.');
        this.loading = false;
      }
    });
  }

  activeRoleFeatures(): IRoleFeature[] {
    if (this.Role.Features) {
      return this.Role.Features.filter((r) => (this.activeModuleId == null || r.ModuleId === this.activeModuleId) && r.Active === true);
    }
    return [];
  }

  availableRoleFeatures(): IPermission[] {
    if (this.components) {
      return this.components.filter((c) => (this.moduleId == null || c.ModuleId === this.moduleId) && !this.hasComponent(c.Id));
    }
    return [];
  }

  removeRoleFeature(feature: IRoleFeature) {
    if (feature) {
      feature.Active = false;
      const index = this.Role.Features.indexOf(feature);
      // Check if record has been previously saved in the DB, otherwise remove from list completely to avoid inserting an inactive record
      if (feature.Id != null) {
        feature.Active = false;
      } else {
        this.Role.Features.splice(index, 1);
      }
    }
  }

  addRoleFeature(permission: IPermission) {
    const newRoleFeature = {
      Id: null,
      DateCreated: new Date(),
      DateModified: null,
      Active: true,
      RoleId: this.Role.Id,
      PermissionId: permission.Id,
      PermissionCode: permission.Code,
      PermissionName: permission.Name,
      ModuleId: permission.ModuleId
    } as IRoleFeature;

    this.Role.Features.push(newRoleFeature);
  }

  identify(index, item) {
    return item;
  }

  hasComponent(permissionId: number): boolean {
    return this.Role.Features?.some((r) => r.PermissionId === permissionId && r.Active === true);
  }

  saveRole() {
    if (this.canSave()) {
      this.loading = true;

      this.api.post('Role/Update', this.Role).pipe(
        takeUntil(this.unsubscribe)
      ).subscribe({
        next: (data: IRole) => {
          if (data) {
            this.alertService.success('Role update successful!');
            this.activeModal.close(this.Role);
          }
          this.loading = false;
        }, error: (err: IApiResponse) => {
          this.alertService.error(err?.Meta?.Message);
          this.loading = false;
        }
      });
    } else {
      this.invalid = true;
    }
  }

  canSave() {
    return !!(this.Role && this.Role.Name && this.Role.Name.length > 3 && this.Role.Description && this.Role.Description.length > 10);
  }

  close() {
    this.activeModal.close(null);
  }
}
