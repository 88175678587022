<!---------------------->
<!------- Header ------->
<!---------------------->
<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom sticky-header">
  <div class="row">
    <h1 class="portal-component-header">
      {{ companyChart == null ? 'Company Chart' : companyChart.Name ? companyChart.Name : companyChart.BranchName ? companyChart.CompanyName + ' - ' + companyChart.BranchName : companyChart.CompanyName + ' - ' + companyChart.City }}
    </h1>
  </div>
  <div class="justify-content-end">
    <ng-container *ngIf="canCreateNew && !companyChart">
      <button class="btn btn-sm btn-primary harmony-tertiary-outline-button"
              [disabled]="loading"
              (click)="openCompanyChartCreate()">
        <fa-icon class="edit-picture" [icon]="faAdd"></fa-icon>
        New Company Chart
      </button>
    </ng-container>
    <button class="btn btn-sm btn-primary harmony-primary-outline-button me-1"
            *ngIf="companyChart"
            [disabled]="loading"
            (click)="openInfoModal()">
      Company Information
    </button>
    <button class="btn btn-sm btn-outline-primary harmony-tertiary-outline-button me-2"
            *ngIf="companyChart"
            [title]="'Back to overview'"
            (click)="back(true)">
      Back
    </button>
  </div>
</div>
<!--------------------------------->
<!-- Company chart Overview ----->
<!--------------------------------->
<ng-container *ngIf="!companyChart">
  <div class="risk-assessment">
    <!----------------------->
    <!------- Filters ------->
    <!----------------------->
    <ng-container *ngIf="canViewAllCompanies">
      <div class="row">
        <div class="col-3">
          <input class="form-control form-control-sm"
                 type="text"
                 placeholder="Search company charts..."
                 [(ngModel)]="companyChartSearchParam.Criteria">
        </div>
        <div class="col-1 align-self-center">
          <b>Company</b>
        </div>
        <div class="col-2">
          <select class="form-select form-select-sm pointer"
                  [(ngModel)]="companyChartSearchParam.CompanyId"
                  (ngModelChange)="setCompanyId()"
                  [disabled]="loading || !(companies$ | async)">
            <option [ngValue]="null" selected>All companies...</option>
            <option *ngFor="let company of (companies$ | async)"
                    [ngValue]="company.CompanyId">{{ company.CompanyName }}</option>
          </select>
        </div>
        <div class="col-2 offset-4">
          <button class="btn btn-sm btn-primary harmony-tertiary-button w-100"
                  (click)="getCompanyCharts()">
            Search
          </button>
        </div>
      </div>
      <hr class="mt-2 mb-0">
    </ng-container>
    <!----------------------->
    <!----- Pagination ------>
    <!----------------------->
    <div class="row ms-1 me-1">
      <div class="col-12">
        <app-pagination [showPageNumber]=true
                        [showRowsPerPage]=true
                        [paginationData]=paginationData
                        (pageEvent)="onPage($event)"
                        [name]="'CCOverview'"
                        [page]="page">
        </app-pagination>
      </div>
    </div>
    <!----------------------->
    <!------- Results ------->
    <!----------------------->
    <div class="row mt-2 header-row">
      <div class="col-3 header-title pointer border-split-left"
           (appOrder)="orderSearch($event)"
           data-column="Branch.Name"
           data-order="asc">
        Name
        <fa-icon [hidden]="orderParam.OrderBy != 'Branch.Name'" class="ms-2 position-relative"></fa-icon>
      </div>
      <div class="col-2 header-title pointer"
           (appOrder)="orderSearch($event)"
           data-column="Company.Name"
           data-order="desc">
        Company
        <fa-icon [hidden]="orderParam.OrderBy != 'Company.Name'" [icon]="faSortAsc" style="bottom: 3px;" class="ms-2 position-relative"></fa-icon>
      </div>
      <div class="col-2 header-title pointer overflow-hidden">
        Contact person/s
      </div>
      <div class="col-2 header-title pointer"
           (appOrder)="orderSearch($event)"
           data-column="Company.CityPhysical"
           data-order="asc">
        City
        <fa-icon [hidden]="orderParam.OrderBy != 'Company.CityPhysical'" class="ms-2 position-relative"></fa-icon>
      </div>
      <div class="col-2 header-title pointer overflow-hidden">
        Items
      </div>
      <div class="col-1 header-title border-split-right text-center">
      </div>
    </div>
    <app-loader *ngIf="loading"></app-loader>
    <div class="row result-row my-0" *ngFor='let r of paginationData.DataSet; let i = index'>
      <div class="col-3 overflow-hidden" [title]="r.Name ? r.Name : r.BranchName ? r.BranchName : r.CompanyName">
        <b>{{ r.Name ? r.Name : r.BranchName ? r.BranchName : r.CompanyName }}</b>
      </div>
      <div class="col-2 overflow-hidden" [title]="r.CompanyName">
        {{ r.CompanyName }}
      </div>
      <div class="col-2 overflow-hidden" [title]="r.ContactEmail">
        <small>{{ r.ContactEmail }}</small>
      </div>
      <div class="col-2 overflow-hidden" [title]="r.City">
        {{ r.City }}
      </div>
      <div class="col-2 overflow-hidden">
        <span class="cc-total cc-legend d-inline-block">
          {{ r.CompanyChartItemCount }}
        </span> |
        <span class="cc-status-orange cc-legend d-inline-block me-1" *ngIf="r.CompanyChartAttentionCount > 0">{{ r.CompanyChartAttentionCount }}</span>
        <span class="cc-status-red cc-legend d-inline-block me-1" *ngIf="r.CompanyChartOverdueCount > 0">{{ r.CompanyChartOverdueCount }}</span>
      </div>
      <div class="col-1 text-end pe-0">
        <button class="btn btn-sm btn-primary harmony-primary-outline-button"
                [title]="'View or edit company chart'" (click)="navigateToCompanyChart(r.Id)">
          <fa-icon [icon]="faEdit"></fa-icon>
        </button>
        <button *ngIf="canDelete"
                class="btn btn-sm btn-outline-danger ms-1"
                [title]="'Delete company chart'" (click)="deleteCompanyChart(r)">
          <fa-icon [icon]="faDelete"></fa-icon>
        </button>
      </div>
    </div>
  </div>
</ng-container>
<!--------------------------->
<!-- Active Company Chart --->
<!--------------------------->
<ng-container *ngIf="companyChart">
  <app-company-chart-view [CompanyChart]="companyChart" (OnClose)="back($event)"></app-company-chart-view>
</ng-container>
<app-loader *ngIf="loadingCompanyChart"></app-loader>
<app-no-result [showNoResults]="noResult"></app-no-result>
