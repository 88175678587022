<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom sticky-header">
  <h1 class="portal-component-header">
    Security Management
  </h1>
  <div>
    <button class="btn btn-sm btn-outline-primary harmony-purple-outline-button me-1"
            (click)="manageModule(null, true)">
      + New Module
    </button>
    <button class="btn btn-sm btn-outline-primary harmony-purple-outline-button me-1"
            (click)="managePermission(null, true)">
      + New Permission
    </button>
    <button class="btn btn-sm btn-outline-primary harmony-purple-outline-button"
            (click)="manageRole(null, true)">
      + New Role
    </button>
  </div>
</div>
<div class="row mt-3">
  <div class="col-12">
    <ul class="nav nav-tabs" id="permissionsTab" role="tablist">
      <li class="nav-item" role="presentation">
        <a class="nav-link active" id="module-tab" data-bs-toggle="tab" href="#module" role="tab" aria-controls="module" aria-selected="true">
          Modules
        </a>
      </li>
      <li class="nav-item" role="presentation">
        <a class="nav-link" id="component-tab" data-bs-toggle="tab" href="#component" role="tab" aria-controls="component" aria-selected="true">
          Permissions
        </a>
      </li>
      <li class="nav-item" role="presentation">
        <a class="nav-link" id="roles-tab" data-bs-toggle="tab" href="#roles" role="tab" aria-controls="roles" aria-selected="false">
          Roles
        </a>
      </li>
    </ul>
    <!-------------------------------->
    <!------ Tab Content Start ------->
    <!-------------------------------->
    <div class="tab-content" id="permissionsTabContent">
      <!------------------------------>
      <!------ Manage Modules -------->
      <!------------------------------>
      <div class="tab-pane fade show active mb-5" id="module" role="tabpanel" aria-labelledby="module-tab">
        <app-module-management></app-module-management>
      </div>
      <!------------------------------>
      <!----- Manage Permissions ----->
      <!------------------------------>
      <div class="tab-pane fade mb-5" id="component" role="tabpanel" aria-labelledby="component-tab">
        <app-permission-management></app-permission-management>
      </div>
      <!------------------------------>
      <!------- Manage Roles --------->
      <!------------------------------>
      <div class="tab-pane fade mb-5" id="roles" role="tabpanel" aria-labelledby="roles-tab">
        <app-role-management></app-role-management>
      </div>
    </div>
  </div>
</div>
