<div id="hqms-accordion">
  <div class="card portal-menu-collapse-card">
    <div class="portal-menu-collapse-header bg-dark" id="headingHqms" data-bs-toggle="collapse" data-bs-target="#collapseHqms"
         aria-expanded="true" aria-controls="collapseHqms">
      <h5 class="portal-menu-heading p-2 mb-0 text-muted">
        <span class="heading-icon icon-color">
          <fa-icon [icon]="faHqms" [size]="'xs'"></fa-icon>
        </span>
        <span class="portal-menu-module-name"
              [ngClass]="{'harmony-primary-text fw-bold': router.url.includes('hqms/')}">
          HQMS
        </span>
      </h5>
    </div>
    <div id="collapseHqms" class="collapse" aria-labelledby="headingHqms" data-bs-parent="#hqms-accordion">
      <div class="portal-menu-collapse-body">
        <nav class="col-md-12 d-none d-md-block ps-4">
          <ul class="nav flex-column mb-0">
            <li class="nav-item" *ngIf="qualityManual">
              <a class="nav-link pointer"
                 [routerLink]="['hqms/manual']"
                 [ngClass]="{'active': router.url.endsWith('hqms/manual')}">
                <fa-icon class="link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Quality Manual
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</div>
