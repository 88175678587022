import {AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {
  faArrowRight,
  faBoxOpen,
  faEdit,
  faEye,
  faListCheck, faMapPin, faPlaneArrival, faPlaneDeparture,
  faPlus, faShip, faSortDown, faSortUp,
  faSyncAlt,
  faTrash, faTruck
} from '@fortawesome/free-solid-svg-icons';
import { ViewActionLogModalComponent } from './view-action-log-modal/view-action-log-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateRateRequestModalComponent } from './create-rate-request-modal/create-rate-request-modal.component';
import { DatePipe } from '@angular/common';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { RateGroupEditModalComponent } from '../rategroup-manager/rategroup-edit-modal/rate-group-edit-modal.component';
import {
  RequestTemplateEditModalComponent
} from './request-template-management/request-template-edit-modal/request-template-edit-modal.component';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { IRateRequestDto, IRateRequestSearchParam } from '../../../services/models/pricing/rate-request.model';
import { IPage, IPaginationData } from '../../../shared/models/pagination-data.model';
import { IRateGroupPagination, IRateGroupParam } from '../../../services/models/pricing/rates.model';
import { PricingService } from '../../../services/pricing.service';
import { AdAuthService } from '../../../core/ad-auth-service/ad-auth.service';
import { AlertService } from '../../../services/alert.service';
import { ApiService } from '../../../services/api.service';
import { ICityFunctionDto } from '../../../services/models/pricing/location.model';
import { IAgent } from '../../../services/models/pricing/partner.model';
import { IOrderParam } from '../../../shared/directives/sort/order.directive';
import { OpenConfirmationModal } from '../../../shared/components/confirmation-modal/confirmation-modal-functions';
import { PermissionCodes } from '../../../core/constants/permission-codes';

@Component({
  selector: 'app-rate-request',
  templateUrl: './rate-request.component.html',
  styleUrls: ['./rate-request.component.scss'],
  providers: [DatePipe]
})
export class RateRequestComponent implements OnInit, AfterViewChecked, OnDestroy {
  // Icons
  protected readonly faEditIcon = faEdit;
  protected readonly faEmpty = faBoxOpen;
  protected readonly faRefresh = faSyncAlt;
  protected readonly faEye = faEye;
  protected readonly faActions = faListCheck;
  protected readonly faAdd = faPlus;
  protected readonly faRemove = faTrash;
  protected readonly faLocation = faMapPin;
  protected readonly faDepartureAir = faPlaneDeparture;
  protected readonly faArrivalAir = faPlaneArrival;
  protected readonly faSea = faShip;
  protected readonly faRoad = faTruck;
  protected readonly faSortAsc = faSortDown;
  protected readonly faDateArrow = faArrowRight;
  protected readonly faSortDesc = faSortUp;

  // Overview variables
  paginationData: IPaginationData<IRateRequestDto>;
  orderParam: IOrderParam;
  searchParam: IRateRequestSearchParam;
  projectID: number = null;
  modalityTypeID: number = null;
  portType: boolean = true;
  page: IPage;
  activeTab: string = 'manage';

  // Lookup data
  projects$ = this.pricingService.Projects$.asObservable();
  rateRequestTypes$ = this.pricingService.RateRequestTypes$.asObservable();
  modalityTypes$ = this.pricingService.ModalityTypes$.asObservable();
  rateRequestStatuses$ = this.pricingService.RateRequestStatuses$.asObservable();

  // Active request variables
  activeRequest: IRateRequestDto = null;
  rateGroupsPaginationData: IPaginationData<IRateGroupPagination>;

  // Permissions
  isRateAdmin: boolean = false;

  // General variables
  loading: boolean = false;
  private unsubscribe: Subject<any> = new Subject<any>();
  environment = environment;

  constructor(public pricingService: PricingService,
              private authService: AdAuthService,
              private alertService: AlertService,
              private modalService: NgbModal,
              private route: ActivatedRoute,
              private api: ApiService,
              private cdRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.route.paramMap.subscribe( paramMap => {
      const routeParam = paramMap.get('activeTab');

      if (routeParam) {
        this.activeTab = routeParam;
      } else {
        this.activeTab = 'manage';
      }
    });
    this.initPage();
    if (this.authService.CurrentUser) {
      this.isRateAdmin = this.authService.CheckPermissionByCode(PermissionCodes.Pricing_RateGroup_Admin);
    }
    this.authService.CurrentUser$.subscribe(() => {
      this.isRateAdmin = this.authService.CheckPermissionByCode(PermissionCodes.Pricing_RateGroup_Admin);
    });
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  initPage() {
    // Init pagination data
    this.paginationData = {
      DataSet: [],
      Data: null,
      CurrentPage: 1,
      PageSize: 30,
      TotalPages: 1
    };

    // Init Search Param
    this.searchParam = {
      Id: null,
      RateRequestTypeId: null,
      RateRequestStatusId: null,
      DeparturePortId: null,
      OriginLocationTypeId: null,
      OriginCityId: null,
      OriginStateId: null,
      OriginCountryId: null,
      ArrivalPortId: null,
      DestinationLocationTypeId: null,
      DestinationCityId: null,
      DestinationStateId: null,
      DestinationCountryId: null,
      AgentId: null,
      ActiveOnly: true
    };

    // Set default ordering
    this.orderParam = {
      OrderBy: 'DateCreated',
      OrderDirection: 'desc'
    } as IOrderParam;

    // Init page
    this.page = {
      pageNumber: this.paginationData.CurrentPage,
      pageSize: this.paginationData.PageSize,
      batched: false
    };
  }

  //////////////////////////////////////
  /////// Overview tab functions ///////
  //////////////////////////////////////
  updateSearch() {
    // Reset data
    this.page.pageNumber = 1;
    this.paginationData.DataSet = [];
    this.paginationData.TotalPages = 1;
    this.paginationData.CurrentPage = 1;

    this.searchRateRequests();
  }

  resetSearch() {
    // Reset search param object
    this.searchParam = {
      Id: null,
      RateRequestTypeId: null,
      RateRequestStatusId: null,
      DeparturePortId: null,
      OriginLocationTypeId: null,
      OriginCityId: null,
      OriginStateId: null,
      OriginCountryId: null,
      ArrivalPortId: null,
      DestinationLocationTypeId: null,
      DestinationCityId: null,
      DestinationStateId: null,
      DestinationCountryId: null,
      AgentId: null,
      ActiveOnly: true
    } as IRateRequestSearchParam;

    // Reset data
    this.modalityTypeID = null;

    // Reset pagination data
    this.resetPaginationData();
  }

  setPortType() {
    this.portType = this.modalityTypeID && this.modalityTypeID === 1;
    // reset port id
    this.searchParam.DeparturePortId = null;
    this.searchParam.ArrivalPortId = null;
  }

  searchRateRequests() {
    this.loading = true;
    const o = this.orderParam;
    const p = this.page;
    this.pricingService.SearchRateRequests(this.searchParam, p, o).subscribe({
      next: (data: IPaginationData<IRateRequestDto>) => {
        if (data) {
          this.paginationData = Object.assign([], data);
        }
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  resetPaginationData() {
    this.paginationData.DataSet = [];
    this.paginationData.CurrentPage = 1;
    this.paginationData.TotalPages = 1;
    this.page.pageNumber = this.paginationData.CurrentPage;
    this.page.pageNumber = this.paginationData.PageSize;
  }

  orderSearch(param: IOrderParam) {
    this.orderParam = param;
    this.searchRateRequests();
  }

  onPage(page: IPage) {
    this.paginationData.CurrentPage = page.pageNumber;
    this.page = page;
    this.searchRateRequests();
  }

  selectOriginPort(port: ICityFunctionDto) {
    this.activeRequest.DeparturePortId = port?.Id;
    this.activeRequest.DeparturePortName = port?.CityName;
  }

  selectDestinationPort(port: ICityFunctionDto) {
    this.activeRequest.ArrivalPortId = port?.Id;
    this.activeRequest.ArrivalPortName = port?.CityName;
  }

  selectAgent(agent: IAgent) {
    this.activeRequest.AgentId = agent.AgentId;
    this.activeRequest.AgentName = agent.AgentName;
  }

  // The user can manage valid requests at anytime
  editRequest(request: IRateRequestDto) {
    this.loading = true;
    this.activeRequest = request;

    const param = {
      RateRequestID: request.Id
    } as IRateGroupParam;

    const o = this.orderParam;
    const p = {
      pageNumber: 1,
      pageSize: 100,
      batched: false
    } as IPage;

    this.pricingService.SearchRateGroups(param, p, o).subscribe({
      next: (data: IPaginationData<IRateGroupPagination>) => {
        if (data) {
          this.rateGroupsPaginationData = Object.assign([], data);
        }
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });
  }


  removeRateRequest(request: IRateRequestDto) {
    if (request) {
      const message = 'Are you sure you want to de-activate this rate request?';
      OpenConfirmationModal(this.modalService, message)
        .pipe(
          takeUntil(this.unsubscribe),
          switchMap(answer => {
            if (answer) {
              const successMessage = `Rate request successfully de-activated!`;
              return this.pricingService.ToggleActiveRateRequest(request.Id)
                .pipe(
                  tap(() => {
                    request.Active = false;
                    this.alertService.success(successMessage);
                  }));
            }
          })
        ).subscribe();
    }
  }

  activateRateRequest(request: IRateRequestDto) {
    if (request) {
      const message = 'Are you sure you want to re-activate this rate request?';
      OpenConfirmationModal(this.modalService, message)
        .pipe(
          takeUntil(this.unsubscribe),
          switchMap(answer => {
            if (answer) {
              const successMessage = `Rate request successfully activated!`;
              return this.pricingService.ToggleActiveRateRequest(request.Id)
                .pipe(
                  tap(() => {
                    request.Active = true;
                    this.alertService.success(successMessage);
                  }));
            }
          })
        ).subscribe();
    }
  }

  updateRequest() {
    this.loading = true;

    this.api.post(`RateCapture/UpdateRequest/${this.authService.CurrentUser.UserId}`, this.activeRequest).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data: IRateRequestDto) => {
        if (data) {
          this.activeRequest = Object.assign({}, data);
          this.alertService.success('Rate request updated successfully.');
        }
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  // Data handling functions
  manageRateGroup(rateGroupId: number) {
    // Open modal to manage a RateGroup
    const modalRef = this.modalService.open(RateGroupEditModalComponent, {size: 'xl', windowClass: 'modal-2xl', backdrop: 'static'});
    modalRef.componentInstance.RateGroupID = rateGroupId;
    modalRef.componentInstance.LoadRateGroup();
    // On modal close, read the result and apply logic
    modalRef.result.then((result: string) => {
      if (result) {
        this.editRequest(this.activeRequest);
      }
    }, () => { });
  }

  generateRequests() {
    // Open modal to Add/Edit a module
    const modalRef = this.modalService.open(CreateRateRequestModalComponent, { size: 'xl', windowClass: 'modal-2xl', backdrop: 'static' });
    // On modal close, read the result and apply logic
    modalRef.result.then((result: string) => {
      if (result) {
        this.updateSearch();
      }
    }, () => { });
  }

  createNewTemplate() {
    // Open modal to Add/Edit a Rate Request Template
    const modalRef = this.modalService.open(RequestTemplateEditModalComponent, {size: 'xl', backdrop: 'static'});
    modalRef.componentInstance.TemplateId = null;
    modalRef.componentInstance.LoadNewRequestTemplate();
    // On modal close, read the result and apply logic
    modalRef.result.then((result: string) => {
      if (result) {
        this.updateSearch();
      }
    }, () => {
    });
  }

  viewActionLog(rateRequest: IRateRequestDto) {
    // Open modal to Add/Edit a module
    const modalRef = this.modalService.open(ViewActionLogModalComponent, { size: 'xl', backdrop: 'static' });
    modalRef.componentInstance.RateRequest = rateRequest;
    modalRef.componentInstance.LoadActionLogs();
    // On modal close, read the result and apply logic
    modalRef.result.then((result: string) => {
      if (result) {
        this.updateSearch();
      }
    }, () => { });
  }

  backToOverview() {
    this.activeRequest = null;
    this.rateGroupsPaginationData = null;
  }
}
