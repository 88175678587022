import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faEdit, faSyncAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { IPermission, IModule, IRole } from '../../../services/models/auth.model';
import { Subject } from 'rxjs';
import { ModuleModalComponent } from './module-management/module-modal/module-modal.component';
import { PermissionModalComponent } from './permission-management/permission-modal/permission-modal.component';
import { RoleModalComponent } from './role-management/role-modal/role-modal.component';
import { AdAuthService } from '../../../core/ad-auth-service/ad-auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-security-management',
  templateUrl: './security-management.component.html',
  styleUrls: ['./security-management.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SecurityManagementComponent implements OnInit, OnDestroy {
  // Icons
  faEditIcon = faEdit;
  faDelete = faTrash;
  faRefresh = faSyncAlt;

  private unsubscribe: Subject<any> = new Subject<any>();
  environment = environment;

  constructor(public authService: AdAuthService,
              private modalService: NgbModal,
              private ref: ChangeDetectorRef) {
  }

  ngOnInit(): void { }

  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  ////////////////////////
  /// Module Functions ///
  ////////////////////////
  manageModule(moduleId: number = null, isNew: boolean) {
    // Open modal to Add/Edit a module
    const modalRef = this.modalService.open(ModuleModalComponent, {size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.IsNew = isNew;
    modalRef.componentInstance.ModuleId = moduleId;
    // On modal close, read the result and apply logic
    modalRef.result.then((result: IModule) => {
      if (result) {
        this.authService.LoadModules();
      }
      this.ref.markForCheck();
    }, () => {
      this.ref.markForCheck();
    });
  }

  /////////////////////////////
  /// Permissions Functions ///
  /////////////////////////////
  managePermission(componentId: number, isNew: boolean) {
    // Open modal to Add/Edit a module
    const modalRef = this.modalService.open(PermissionModalComponent, {size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.IsNew = isNew;
    modalRef.componentInstance.ComponentId = componentId;
    // On modal close, read the result and apply logic
    modalRef.result.then((result: IPermission) => {
      if (result) {
        this.authService.LoadPermissions();
      }
      this.ref.markForCheck();
    }, () => {
      this.ref.markForCheck();
    });
  }

  ///////////////////////////////////////
  /// Role and Role feature Functions ///
  ///////////////////////////////////////
  manageRole(roleId: number, isNew: boolean) {
    // Open modal to Add/Edit a module
    const modalRef = this.modalService.open(RoleModalComponent, {size: 'xl', backdrop: 'static'});
    modalRef.componentInstance.IsNew = isNew;
    modalRef.componentInstance.RoleId = roleId;
    // On modal close, read the result and apply logic
    modalRef.result.then((result: IRole) => {
      if (result) {
        this.authService.LoadRoles();
      }
      this.ref.markForCheck();
    }, () => {
      this.ref.markForCheck();
    });
  }
}
