<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom sticky-header">
  <h1 class="portal-component-header">
    Quality Manual
  </h1>
</div>
<!----------------------->
<!------- Results ------->
<!----------------------->
<app-loader *ngIf="loading"></app-loader>
<app-document-category-list [Module]="'HQMS'"></app-document-category-list>
