<div class="row">
  <div class="col-md-12">
    <div class="input-group input-group-sm">
      <input id="{{Identifier}}"
             type="text"
             class="form-control"
             [(ngModel)]="selectedModel"
             [ngbTypeahead]="search"
             [inputFormatter]="formatter"
             [resultFormatter]="formatter"
             [placeholder]="SearchLabel"
             (ngModelChange)="onSelect()"
             [editable]='false' />
        <span class="input-group-text" *ngIf="searching" >
          <img src="../../../../assets/loaders/tail-spin.svg" width="20" alt="" />
        </span>
        <button class="btn btn-outline-primary harmony-tertiary-outline-button" type="button" [title]="'Clear'" (click)="clear()">X</button>
    </div>
  </div>
</div>
