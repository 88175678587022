import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TenderWizardComponent } from './tender-wizard/tender-wizard.component';
import { AuthGuard } from '../../core/guards/auth-guard.guard';
import { SingleRateWizardComponent } from './single-rate-wizard/single-rate-wizard.component';
import { RateGroupManagerComponent } from './rategroup-manager/rate-group-manager.component';
import { RateCaptureComponent } from './rate-capture/rate-capture.component';
import { RateRequestComponent } from './rate-request/rate-request.component';
import { FailedRatesComponent } from './failed-rates/failed-rates.component';
import { PreferredAgentManagementComponent } from './preferred-agent-management/preferred-agent-management.component';
import { ExternalDataManagerComponent } from './external-data-manager/external-data-manager.component';
import { PermissionCodes } from '../../core/constants/permission-codes';
import { DashboardContainerPricingComponent } from './pricing-dashboard/dashboard-container-pricing/dashboard-container-pricing.component';

const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardContainerPricingComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.Pricing_Dashboard
    },
  },
  {
    path: 'rate-tender',
    component: TenderWizardComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.Pricing_Tender_Tool
    },
  },
  {
    path: 'rate-calc',
    component: SingleRateWizardComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.Pricing_SingleRate
    },
  },
  {
    path: 'rate-group-manager',
    component: RateGroupManagerComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.Pricing_RateGroupManager
    },
  },
  {
    path: 'rate-capture',
    component: RateCaptureComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.Pricing_CaptureRates
    },
  },
  {
    path: 'rate-request/:activeTab',
    component: RateRequestComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.Pricing_RequestRates
    }
  },
  {
    path: 'failed-rates',
    component: FailedRatesComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.Pricing_FailedRates
    },
  },
  {
    path: 'preferred-agents',
    component: PreferredAgentManagementComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.Pricing_PreferredAgents
    },
  },
  {
    path: 'external-data-manager/:activeTab',
    component: ExternalDataManagerComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.Pricing_ExternalDataManager
    },
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PricingRoutingModule { }
