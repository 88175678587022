import { Component, OnInit } from '@angular/core';
import { IPage, IPaginationData } from '../../../shared/models/pagination-data.model';
import { IPulseChartDto, IPulseChartOverviewResponseDto } from '../../../services/models/hse/pulse-chart.model';
import { faEdit, faEye, faTable, faTrash } from '@fortawesome/free-solid-svg-icons';
import { CompanyService } from '../../../services/company.service';
import { ApiService } from '../../../services/api.service';
import { PermissionCodes } from '../../../core/constants/permission-codes';
import { IAuthUser } from '../../../services/models/auth.model';
import { AdAuthService } from '../../../core/ad-auth-service/ad-auth.service';
import { PulseChartInformationComponent } from './pulse-chart-information/pulse-chart-information.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PulseChartModalComponent } from './pulse-chart-modal/pulse-chart-modal.component';
import { AlertService } from '../../../services/alert.service';
import { IActiveCompany } from '../../../services/models/member.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-pulse-chart',
  templateUrl: './pulse-chart.component.html',
  styleUrls: ['./pulse-chart.component.scss']
})
export class PulseChartComponent implements OnInit {
  // Icons
  protected readonly faView = faEye;
  protected readonly faDelete = faTrash;
  protected readonly faEdit = faEdit;
  protected readonly faTable = faTable;

  // Component Variables
  public canFilterCompany: boolean = false;
  public canEdit: boolean = false;
  public canAdd: boolean = false;
  public canDelete: boolean = false;
  public pulseChartsOverview: IPulseChartOverviewResponseDto[];
  public pulseCharts: IPulseChartDto[];
  companyId: number = null;
  selectedCompany: IActiveCompany = null;
  companyName: string;
  showTableView: boolean;
  selectedCompanyIdOnTableView: number = null;
  companies$ = this.companyService.ActiveMembers$.asObservable();

  // Shared Components
  paginationData: IPaginationData<IPulseChartOverviewResponseDto> = null;
  page: IPage;
  loading: boolean;
  noResult: boolean = false;

  constructor(private companyService: CompanyService,
              private apiService: ApiService,
              private authService: AdAuthService,
              private modalService: NgbModal,
              private route: ActivatedRoute,
              private alertService: AlertService) { }

  ngOnInit() {
    this.paginationData = {
      DataSet: [],
      CurrentPage: 1,
      PageSize: 30,
      TotalPages: 1
    } as IPaginationData<IPulseChartOverviewResponseDto>;
    this.page = {
      pageNumber: this.paginationData.CurrentPage,
      pageSize: this.paginationData.PageSize
    } as IPage;

    if (this.authService.CurrentUser) {
      this.setPermissions();
    }
    // Subscribe to user to always check if something changes
    this.authService.CurrentUser$
      .subscribe((user: IAuthUser) => {
        this.setPermissions();
      });
  }

  setPermissions() {
    this.canFilterCompany = this.authService.CheckPermissionByCode(PermissionCodes.HSE_PulseChart_CompanyFilter);
    this.canAdd = this.authService.CheckPermissionByCode(PermissionCodes.HSE_PulseChart_Add);
    this.canEdit = this.authService.CheckPermissionByCode(PermissionCodes.HSE_PulseChart_Edit);
    this.canDelete = this.authService.CheckPermissionByCode(PermissionCodes.HSE_PulseChart_Delete);
    this.getInitialDisplayData();
    this.checkRoutes();
  }

  getInitialDisplayData() {
    if (!this.canFilterCompany) {
      this.companyId = this.authService.CurrentUser.User.CompanyId;
    } else {
      this.getPulseChartOverview();
    }
  }

  checkRoutes() {
    this.route.paramMap.subscribe(paramMap => {
      const showOverview = paramMap.get('overview');

      if (showOverview) {
        this.viewTabledPulseChart(this.authService.CurrentUser.User.CompanyId);
      } else {
        this.getPulseChartOverview();
      }
    });
  }

  getPulseChartOverview() {
    let route = `PulseChart?pageNumber=${this.page.pageNumber}&pageSize=${this.page.pageSize}`;
    if (this.companyId) {
      route += `&companyId=${this.companyId}`;
    }

    this.loading = true;
    this.apiService.get(route)
      .subscribe({
        next: (data) => {
          this.loading = false;
          this.paginationData = Object.assign({}, data);
          this.page.pageNumber = this.paginationData.CurrentPage;
          this.pulseChartsOverview = this.paginationData.DataSet;
          this.noResult = this.paginationData?.DataSet?.length === 0;
        }, error: () => {
          this.noResult = true;
          this.loading = false;
        }
      });
  }

  getPulseChartPerCompany(companyId: number) {
    this.loading = true;
    this.apiService.get(`PulseChart/Company/${companyId}`)
      .subscribe({
        next: (data) => {
          this.loading = false;
          this.pulseCharts = Object.assign([], data);
          this.noResult = this.pulseCharts.length === 0;
        }, error: () => {
          this.noResult = true;
          this.loading = false;
        }
      });
  }

  search(){
    this.page.pageNumber = 1;
    this.getPulseChartOverview();
  }

  // ACTION BUTTONS
  deleteChart(id: number, index: number) {
    if (confirm('Are you sure you want to delete the pulse chart?')) {
      this.apiService.delete(`PulseChart/${id}`)
        .subscribe({
          next: () => {
            // remove from display
            this.paginationData.DataSet.splice(index, 1);
            this.pulseChartsOverview.splice(index, 1);
          }
        });
    }
  }

  viewTabledPulseChart(companyId: number) {
    this.showTableView = true;
    this.selectedCompanyIdOnTableView = companyId;
    this.getPulseChartPerCompany(companyId);
  }

  // MODALS
  openInfoModal() {
    if (!this.companyId) {
      this.alertService.error('Please select company');
      return;
    }
    const modalRef = this.modalService.open(PulseChartInformationComponent, {size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.companyId = this.companyId;
    modalRef.componentInstance.companyName = this.companyName;
    modalRef.componentInstance.hasAccess = this.canEdit;
  }

  openPulseChartModal(isNew: boolean, pulseChartId: number = null, companyId: number = null) {
    const modalRef = this.modalService.open(PulseChartModalComponent, {size: 'xl', backdrop: 'static'});
    modalRef.componentInstance.isNew = isNew;
    modalRef.componentInstance.canSelectCompany = this.canFilterCompany;
    modalRef.componentInstance.canEdit = this.canEdit;
    modalRef.componentInstance.pulseChartId = pulseChartId;
    modalRef.componentInstance.companyId = companyId;
    modalRef.result.then((result: string) => {
      if (result) {
        if (this.showTableView) {
          // Refresh the list if there were updates
          this.getPulseChartPerCompany(this.selectedCompanyIdOnTableView);
        }
        this.getPulseChartOverview();
      }
    }, () => {
    });
  }

  selectCompany($event: IActiveCompany) {
    this.selectedCompany = $event;
    this.companyId = $event?.CompanyId;
    this.companyName = $event?.CompanyName;
    this.search();
  }

  onPageChange($event: IPage) {
    this.page = $event;
    this.getPulseChartOverview();
  }
}
