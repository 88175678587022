import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {faCircleCheck, faCircleXmark, faUpload} from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/services/alert.service';
import { AnnualReviewService } from 'src/app/services/annual-review.service';
import { IAnnualReviewCreateRequest, IAnnualReviewResponse, IAnnualReviewUpdateRequest } from 'src/app/services/models/annual-review/annual-review.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { FileService } from '../../../../services/file.service';

@Component({
  selector: 'app-view-review-modal',
  templateUrl: './view-review-modal.component.html',
  styleUrls: ['./view-review-modal.component.scss'],
  providers: [DatePipe]
})
export class ViewReviewModalComponent implements OnInit, OnDestroy {
  // Icons
  protected readonly faCheck = faCircleCheck;
  protected readonly faNotFeatured = faCircleXmark;
  protected readonly faUpload = faUpload;

  // Component variables
  public isNew: boolean = false;
  public isClone: boolean = false;
  public reviewIdClone: number;
  public userId: number;
  public review = {} as IAnnualReviewResponse;
  reviewForm: UntypedFormGroup;

  // General variables
  public loading: boolean = false;
  private unsubscribe: Subject<any> = new Subject<any>();
  container = 'templates' as string;

  constructor(private annualReviewService: AnnualReviewService,
              public activeModal: NgbActiveModal,
              private alertService: AlertService,
              private formBuilder: UntypedFormBuilder,
              private fileService: FileService) {
  }

  ngOnInit(): void {
    this.createForm();
    if (!this.isNew || !this.isClone) {
      this.fillFrom(this.review);
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  isFormValid() {
    this.reviewForm.markAllAsTouched();
    this.reviewForm.markAsTouched();
    if (!this.reviewForm.controls.year.valid) {
      this.alertService.warn('Form is not valid');
    }
    return this.reviewForm.controls.year.valid;
  }

  createForm() {
    this.reviewForm = this.formBuilder.group({
      year: new UntypedFormControl(null, Validators.required),
      startDate: new UntypedFormControl(null),
      endDate: new UntypedFormControl(null),
      harmonyDocDate: new UntypedFormControl(null),
      networkDocDate: new UntypedFormControl(null),
      companyDocDate: new UntypedFormControl(null),
      auditStartDate: new UntypedFormControl(null),
      auditEndDate: new UntypedFormControl(null)
    });
  }

  fillFrom(review: IAnnualReviewResponse) {
    this.reviewForm.controls.year.setValue(review.YearCreated);
    this.reviewForm.controls.startDate.setValue(review.StartDate);
    this.reviewForm.controls.endDate.setValue(review.EndDate);
    this.reviewForm.controls.harmonyDocDate.setValue(review.HarmonyDocumentEnableDate);
    this.reviewForm.controls.networkDocDate.setValue(review.NetworkCompanyDocumentEnableDate);
    this.reviewForm.controls.companyDocDate.setValue(review.CompanyDocumentEnableDate);
    this.reviewForm.controls.auditStartDate.setValue(review.AuditStartDate);
    this.reviewForm.controls.auditEndDate.setValue(review.AuditEndDate);
  }

  // set date values
  setStartDate(date: string) {
    this.review.StartDate = date;
    this.reviewForm.controls.startDate.setValue(date);
  }

  setEndDate(date: string) {
    this.review.EndDate = date;
    this.reviewForm.controls.endDate.setValue(date);
  }

  setHarmonyDate(date: string) {
    this.review.HarmonyDocumentEnableDate = date;
    this.reviewForm.controls.harmonyDocDate.setValue(date);
  }

  setNetworkDate(date: string) {
    this.review.NetworkCompanyDocumentEnableDate = date;
    this.reviewForm.controls.networkDocDate.setValue(date);
  }

  setCompanyDate(date: string) {
    this.review.CompanyDocumentEnableDate = date;
    this.reviewForm.controls.companyDocDate.setValue(date);
  }

  setAuditStartDate(date: string) {
    this.review.AuditStartDate = date;
    this.reviewForm.controls.auditStartDate.setValue(date);
  }

  setAuditEndDate(date: string) {
    this.review.AuditEndDate = date;
    this.reviewForm.controls.auditEndDate.setValue(date);
  }

  add() {
    if (this.isFormValid()) {
      const review = {
        Year: this.reviewForm.value.year,
        StartDate: this.reviewForm.value.startDate,
        EndDate: this.reviewForm.value.endDate,
        UserId: this.userId,
        HarmonyDocumentEnableDate: this.reviewForm.value.harmonyDocDate,
        NetworkCompanyDocumentEnableDate: this.reviewForm.value.networkDocDate,
        CompanyDocumentEnableDate: this.reviewForm.value.companyDocDate,
        AuditDocument: this.review.AuditDocument
      } as IAnnualReviewCreateRequest;
      this.annualReviewService.addAnnualReview(review).pipe(
        takeUntil(this.unsubscribe))
        .subscribe({
          next: () => {
            this.close(true);
          },
          error: () => {
            this.alertService.error('Failed to add review');
          }
      });
    }
  }

  uploadDocument(file: any, annualReviewType: number) {
    this.loading = true;
    if ((file.size / 1024 / 1024) > 10) {
      this.alertService.warn('The selected file is bigger than 10MB, please select another file');
      this.loading = false;
      return false;
    }
    // Upload file to Azure storage
    this.fileService.Upload(file, this.container, file.name)
      .subscribe({
        next: () => {
          this.loading = false;
          switch (annualReviewType) {
            case 1:
              this.review.HarmonyDocument = file.name;
              break;
            case 2:
              this.review.NetworkCompanyDocument = file.name;
              break;
            case 3:
              this.review.CompanyDocument = file.name;
              break;
            case 4:
              this.review.AuditDocument = file.name;
              break;
          }
          this.alertService.success('Successfully uploaded document, please save');
          return true;
        }, error: (err) => {
          this.loading = false;
          this.alertService.error(err.Meta.Message);
          return false;
        }
      });
  }

  uploadHarmonyDoc(event) {
    this.loading = true;
    if (this.review.HarmonyDocument) {
      this.fileService.DeleteFile(this.container, this.review.HarmonyDocument)
        .subscribe();
    }
    const file = event.target.files[0];
    this.uploadDocument(file, 1);
  }

  uploadNetworkDoc(event) {
    this.loading = true;
    if (this.review.NetworkCompanyDocument) {
      this.fileService.DeleteFile(this.container, this.review.NetworkCompanyDocument)
        .subscribe();
    }
    const file = event.target.files[0];
    this.uploadDocument(file, 2);
  }

  uploadCompanyDoc(event) {
    this.loading = true;
    if (this.review.CompanyDocument) {
      this.fileService.DeleteFile(this.container, this.review.CompanyDocument)
        .subscribe();
    }
    const file = event.target.files[0];
    this.uploadDocument(file, 3);
  }

  uploadAuditDoc(event) {
    this.loading = true;
    if (this.review.AuditDocument) {
      this.fileService.DeleteFile(this.container, this.review.AuditDocument)
        .subscribe();
    }
    const file = event.target.files[0];
    this.uploadDocument(file, 4);
  }

  update() {
    if (this.isFormValid()) {
      const review = {
        ReviewId: this.review.Id,
        Year: this.reviewForm.value.year,
        StartDate: this.review.StartDate,
        EndDate: this.review.EndDate,
        HarmonyDocumentEnableDate: this.reviewForm.value.harmonyDocDate,
        HarmonyDocument: this.review.HarmonyDocument,
        NetworkCompanyDocumentEnableDate: this.reviewForm.value.networkDocDate,
        NetworkCompanyDocument: this.review.NetworkCompanyDocument,
        CompanyDocumentEnableDate: this.reviewForm.value.companyDocDate,
        CompanyDocument: this.review.CompanyDocument,
        AuditEndDate: this.review.AuditEndDate,
        AuditStartDate: this.review.AuditStartDate,
        AuditDocument: this.review.AuditDocument
      } as IAnnualReviewUpdateRequest;
      this.annualReviewService.updateAnnualReview(review)
        .subscribe({
          next: () => {
            this.close(true);
          },
          error: () => {
            this.loading = false;
            this.alertService.error('Failed to update review');
          }
        });
    }
  }

  clone() {
    this.loading = true;
    if (this.isFormValid()) {
      const review = {
        Year: this.reviewForm.value.year,
        StartDate: this.review.StartDate,
        EndDate: this.review.EndDate,
        UserId: this.userId,
        HarmonyDocumentEnableDate: this.reviewForm.value.harmonyDocDate,
        NetworkCompanyDocumentEnableDate: this.reviewForm.value.networkDocDate,
        CompanyDocumentEnableDate: this.reviewForm.value.companyDocDate,
        AuditEndDate: this.review.AuditEndDate,
        AuditStartDate: this.review.AuditStartDate
      } as IAnnualReviewCreateRequest;
      this.annualReviewService.addAnnualReview(review).pipe(
        takeUntil(this.unsubscribe)).subscribe({
        next: (data) => {
          if (data) {
            this.annualReviewService.cloneAnnualReview(data, this.reviewIdClone)
              .subscribe(() => {
                this.loading = false;
                this.close(true);
              });
          }
        },
        error: () => {
          this.loading = false;
          this.alertService.error('Failed to clone review');
        }
      });
    }
  }

  close(changed: boolean) {
    this.activeModal.close(changed);
  }
}
