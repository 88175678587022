export class PermissionCodes {
  //////////////////
  /////// HSE //////
  //////////////////
  // Incident Improvement
  static readonly HSE_IncidentImprovement = 'HSEINCDT';
  static readonly HSE_IncidentImprovement_CauseAndInvestigation = 'INCDCAIN';
  static readonly HSE_IncidentImprovement_Effectiveness = 'INCDEFCT';
  static readonly HSE_IncidentImprovement_Analysis = 'INCDANLS';
  static readonly HSE_IncidentImprovement_Level = 'INCDLEVL';
  static readonly HSE_IncidentImprovement_CompanyFilter = 'INCDCMPY';
  static readonly HSE_IncidentImprovement_ExcelExport = 'INCDEXPT';
  // Health and Safety
  static readonly HSE_HS_RiskAssessment = 'HSERISK';
  static readonly HSE_HS_RiskAssessment_DeleteAssessment = 'HSRSKDLT';
  static readonly HSE_HS_RiskAssessment_CompanyFilter = 'HSRSKCMP';
  static readonly HSE_HS_RiskAssessment_CreateNew = 'HSRSKNWB';
  static readonly HSE_HS_RiskAssessment_EditInformation = 'HSRSKINF';
  static readonly HSE_HS_RiskAssessment_EditName = 'HSRSKNME';
  // Environment
  static readonly HSE_EnvironmentalAssessment = 'HSEENVA';
  static readonly HSE_EnvironmentalAssessment_DeleteAssessment = 'ENVADLT';
  static readonly HSE_EnvironmentalAssessment_CompanyFilter = 'ENVACMPF';
  static readonly HSE_EnvironmentalAssessment_CreateNew = 'ENVACNWB';
  static readonly HSE_EnvironmentalAssessment_EditInformation = 'ENVAEINF';
  static readonly HSE_EnvironmentalAssessment_EditName = 'ENVANAME';
  // Company chart
  static readonly HSE_HS_CompanyChart = 'HSEHSCC';
  static readonly HSE_HS_CompanyChart_EditInformation = 'HSECCEIF';
  static readonly HSE_HS_CompanyChart_UploadDocument = 'HSECCUPD';
  static readonly HSE_HS_CompanyChart_CompanyFilter = 'HSECCCMP';
  static readonly HSE_HS_CompanyChart_CreateNew = 'HSECCNEW';
  static readonly HSE_HS_CompanyChart_Delete = 'HSECCDLT';
  static readonly HSE_HS_CompanyChart_DeleteCategories = 'HSECCDLC';
  // Dangerous Substance Register
  static readonly HSE_DSR = 'HSEDSR';
  static readonly HSE_DSR_Edit = 'HSEDSRED';
  static readonly HSE_DSR_Delete = 'HSEDSRDL';
  static readonly HSE_DSR_CompanyFilter = 'HSEDSRCF';
  // Pulse Chart
  static readonly HSE_PulseChart = 'HSEPC';
  static readonly HSE_PulseChart_CompanyFilter = 'HSEPCCF';
  static readonly HSE_PulseChart_Add = 'HSEPCADD';
  static readonly HSE_PulseChart_Edit = 'HSEPCED';
  static readonly HSE_PulseChart_Delete = 'HSEPCDLT';
  // Emergency
  static readonly HSE_Emergency = 'HSEEDE';
  static readonly HSE_Emergency_CompanyFilter = 'HSEEDECF';
  static readonly HSE_Emergency_Edit = 'HSEEDED';
  static readonly HSE_Emergency_Add = 'HSEEADD';
  static readonly HSE_Emergency_Delete = 'HSEEDDL';
  // Impact Data
  static readonly HSE_EnvironmentalImpactData = 'HSEEID';
  static readonly HSE_EnvironmentalImpactData_CompanyFilter = 'HSEEIDCF';
  static readonly HSE_EnvironmentalImpactData_Edit = 'HSEEIDED';
  static readonly HSE_EnvironmentalImpactData_Add = 'HSEEIDAD';
  static readonly HSE_EnvironmentalImpactData_Delete = 'HSEEIDDL';
  // HSE Documents
  static readonly HSE_Documents = 'HSEDOCS';
  // HSE Admin
  static readonly HSE_Admin = 'HSEADMIN';
  static readonly HSE_DashBoard = 'HSEDSHBD';

  ///////////////////
  ////// ADMIN /////
  //////////////////
  static readonly Admin_User_Edit = 'EDITUSER';
  static readonly Admin_User_Create = 'CREATUSR';
  static readonly Admin_User_RoleAdmin = 'ROLEADMN';
  static readonly Admin_User_Delete = 'DLTUSERS';
  static readonly Admin_User_Company = 'USERCOMP';
  static readonly Admin_User_Management = 'USERMAN';
  static readonly Admin_Emailing = 'EMAILING';
  static readonly Admin_MemberManagement = 'MANGMBRS';
  static readonly Admin_AgentManagement = 'MANGAGNT';
  static readonly Admin_DocumentManagement = 'DOCMANGR';
  static readonly Admin_DocumentCategories = 'DOCCTGRY';
  static readonly Admin_PermissionManagement = 'PERMMAN';
  static readonly Admin_LocationManagement = 'LOCTNMAN';
  static readonly Admin_LocationDelete = 'LOCTNDLT';
  static readonly Admin_JobScheduler = 'JOBSCHED';
  static readonly Admin_ApplicationLog = 'APPLOG';

  /////////////////
  ////// MENU /////
  /////////////////
  static readonly Menu_Dash = 'PRTLDASH';
  static readonly Menu_Pricing = 'TOOLMENU';
  static readonly Menu_Network = 'NETWMENU';
  static readonly Menu_News = 'NEWSMENU';
  static readonly Menu_AnnualReview = 'ARMENU';
  static readonly Menu_Hub = 'HUBMENU';
  static readonly Menu_HSE = 'HSEMENU';
  static readonly Menu_Admin = 'ADMINNAV';

  ///////////////////
  // Annual Review //
  ///////////////////
  static readonly AnnualReview = 'ANNREV';
  static readonly AnnualReview_Audit = 'ARAUDIT';
  static readonly AnnualReview_Admin = 'ARADMIN';

  /////////////
  // Pricing //
  /////////////
  static readonly Pricing_Dashboard = 'PRICDASH';
  static readonly Pricing_RateGroup_Admin = 'RATEADMN';
  static readonly Pricing_Tender_PreProcess = 'RUNTENDR';
  static readonly Pricing_Tender_Tool = 'TNDRTOOL';
  static readonly Pricing_SingleRate = 'SNGLRATE';
  static readonly Pricing_RateGroupManager = 'RATEMNGR';
  static readonly Pricing_CaptureRates = 'RATECAPT';
  static readonly Pricing_RequestRates = 'RATERQST';
  static readonly Pricing_FailedRates = 'FAILDRTS';
  static readonly Pricing_PreferredAgents = 'AGNTMNG';
  static readonly Pricing_ExternalDataManager = 'EXTDMNG';
  static readonly Pricing_Admin = 'PRCADMIN';

  /////////
  // HUB //
  /////////
  static readonly Hub_Admin = 'HUBADMIN';
  static readonly Hub_ViewAllData = 'HUBVWALL';
  static readonly Hub_EditValues = 'HUBEDITV';
  static readonly Hub_MessageLogs = 'MSGLOG';
  static readonly Hub_DefaultValues = 'DEFVALUE';
  static readonly Hub_MissingValues = 'MSSNGVAL';
  static readonly Hub_ManageMappings = 'MNGEMAPS';
  static readonly Hub_SchemaManager = 'SCHMAMNG';
  static readonly Hub_SchemaMappings = 'SCHMAMAP';

  ///////////////////////
  // Member Management //
  ///////////////////////
  static readonly MemberManagement_VerifyPops = 'VRFYPOP';

  /////////////
  // Network //
  /////////////
  static readonly Network_MemberSearch = 'SRHMEMBR';
  static readonly Network_EditMember = 'EDTMEMBR';
  static readonly Network_MemberUserManagement = 'MNGMBRUS';
  static readonly Network_Training = 'TRAINING';

  //////////
  // News //
  //////////
  static readonly News_NetworkArticles = 'MBRARTCL';
  static readonly News_ManageArticles = 'MNGARTCL';
  static readonly News_ApproveArticles = 'APPARTCL';
  static readonly News_DeleteArticles = 'DLTARTCL';

  /////////
  // QMS //
  /////////
  static readonly Qms_Menu = 'QMSMENU';
  static readonly Qms_QualityManual = 'QLTYMANL';

  ////////////
  // EVENTS //
  ////////////
  static readonly Events_Menu = 'EVNTMENU';
  static readonly Events_Dashboard = 'EVNTDASH';

  //////////////
  // SERVICES //
  //////////////
  static readonly Services_Menu = 'SERVMENU';
}
