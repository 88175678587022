<div class="modal-header">
  <h4 class="modal-title">
    Manage Included Services
  </h4>
  <button type="button" class="btn-close" aria-label="Close" [disabled]="loading" (click)="activeModal.dismiss(null)">

  </button>
</div>
<div class="modal-body">
  <app-loader *ngIf="loading"></app-loader>
  <div class="row mb-3 text-center px-2">
    <div class="col-4 ps-1 pe-1" (click)="selectedType = 'Origin'">
      <div class="service-option p-1" [ngClass]="{ 'active': selectedType === 'Origin', 'inactive-mode': selectedType !== 'Origin' }">
        Origin
      </div>
    </div>
    <div class="col-4 ps-1 pe-1" (click)="selectedType = 'Freight'">
      <div class="service-option p-1" [ngClass]="{ 'active': selectedType === 'Freight', 'inactive-mode': selectedType !== 'Freight' }">
        Freight
      </div>
    </div>
    <div class="col-4 ps-1 pe-1" (click)="selectedType = 'Destination'">
      <div class="service-option p-1" [ngClass]="{ 'active': selectedType === 'Destination', 'inactive-mode': selectedType !== 'Destination' }">
        Destination
      </div>
    </div>
  </div>
  <div class="row mt-2 header-row">
    <div class="col-9 header-title border-split-left">Service</div>
    <div class="col-3 text-center header-title border-split-right">Included</div>
  </div>
  <div class="service-options">
    <div class="row result-row pointer pt-0 pb-0" *ngFor="let serviceType of filterServiceTypes()">
      <div class="col-9 overflow-hidden">
        {{ serviceType.Name }}
      </div>
      <div class="col-3 text-center">
        <div class="form-check form-switch form-switch-md">
          <input class="form-check-input pointer float-none"
                 type="checkbox"
                 role="switch"
                 [id]="'switch-flammable'"
                 [title]="'Is the substance flammable'"
                 [checked]="checkServiceStatus(serviceType)"
                 (change)="toggleServiceType(serviceType)">
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary harmony-tertiary-button" [disabled]="loading" (click)="close()">
    Apply
  </button>
</div>

