<div class="modal-header">
  <h4 class="modal-title">
    Upload Required Documents
  </h4>
</div>
<div class="modal-body">
  <div class="row mx-1">
    <div class="col-12">
      <h5 class="harmony-secondary-text mb-1">{{ questionText ?? 'Files' }}</h5>
      <p class="harmony-grey-text mb-0">{{ uploadRequirement ?? '-' }}</p>
      <hr class="my-2">
    </div>
  </div>
  <app-loader *ngIf="loading"></app-loader>
  <ng-container *ngIf="!loading && documents?.length > 0">
    <div class="row result-row" *ngFor="let doc of documents">
      <div class="col-8 align-self-center">
        <p>{{ doc.Name }}</p>
      </div>
      <div class="col-4 text-end">
      <span [title]="'Remove document'"
            class="btn btn-sm btn-outline-danger border-0"
            (click)="delete(doc)">
        <fa-icon [icon]="faDelete"></fa-icon>
      </span>
        <button class="btn btn-sm btn-outline-success harmony-secondary-outline-button border-0 pointer ms-2"
                [title]="'Download Document'"
                (click)="downloadDocument(doc)">
          <fa-icon [icon]="faDownload"></fa-icon>
        </button>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!loading && documents?.length <= 0">
    <div class="row mt-3">
      <div class="col-12 text-center harmony-grey-text">
        <fa-icon [icon]="faBoxOpen" size="2x"></fa-icon>
      </div>
      <div class="col-12 text-center harmony-grey-text mt-1">
        <h4>No documents uploaded yet...</h4>
      </div>
    </div>
  </ng-container>
</div>
<div class="modal-footer">
  <div class="me-2">
    <h5 class="fw-bold harmony-grey-text mb-0">
      <span [ngClass]="{'text-danger': documents.length < requiredDocumentAmount, 'harmony-accent-green-text': documents.length >= requiredDocumentAmount}">
        {{ documents.length }}
      </span> / {{ requiredDocumentAmount }}
    </h5>
  </div>
  <button class="btn btn-primary harmony-tertiary-button"
          type="button"
          (click)="FileHandler.click()">
    <fa-icon [icon]="faUpload"></fa-icon> Upload
  </button>
  <input hidden
         #FileHandler
         type="file"
         id="file"
         multiple
         (change)="setFiles($event)"/>
  <button type="button" class="btn btn-primary harmony-primary-button" [disabled]="loading" (click)="close()">
    Close
  </button>
</div>
