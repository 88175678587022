<div class="row">
  <div class="col-12 text-start">
    <!-- Network -->
    <span class="network-icon-color m-1" [title]="'Network'" *ngIf="hasModule('Network')">
      <fa-icon [icon]="faNetwork" size="1x"></fa-icon>
    </span>
    <!-- Services -->
    <span class="services-icon-color m-1" [title]="'Services'" *ngIf="hasModule('Services')">
      <fa-icon [icon]="faServices" size="1x"></fa-icon>
    </span>
    <!-- News -->
    <span class="news-icon-color m-1" [title]="'News'" *ngIf="hasModule('News')">
      <fa-icon [icon]="faNews" size="1x"></fa-icon>
    </span>
    <!-- Pricing -->
    <span class="pricing-icon-color m-1" [title]="'Pricing'" *ngIf="hasModule('Pricing')">
      <fa-icon [icon]="faPricing" size="1x"></fa-icon>
    </span>
    <!-- Events -->
    <span class="events-icon-color m-1" [title]="'Events'" *ngIf="hasModule('Events')">
      <fa-icon [icon]="faEvents" size="1x"></fa-icon>
    </span>
    <!-- HQMS -->
    <span class="hqms-icon-color m-1" [title]="'HQMS'" *ngIf="hasModule('HQMS')">
      <fa-icon [icon]="faHqms" size="1x"></fa-icon>
    </span>
    <!-- Annual Review -->
    <span class="ar-icon-color m-1" [title]="'Annual Review'" *ngIf="hasModule('Annual Review')">
      <fa-icon [icon]="faAnnualReview" size="1x"></fa-icon>
    </span>
    <!-- HSE -->
    <span class="hse-icon-color m-1" [title]="'HSE'" *ngIf="hasModule('HSE')">
      <fa-icon [icon]="faHSE" size="1x"></fa-icon>
    </span>
    <!-- Hub Admin -->
    <span class="hub-admin-icon-color m-1" [title]="'Integration Hub'" *ngIf="hasModule('Integration Hub')">
      <fa-icon [icon]="faHub" size="1x"></fa-icon>
    </span>
    <!-- Admin -->
    <span class="admin-icon-color m-1" [title]="'Admin'" *ngIf="hasModule('Admin')">
      <fa-icon [icon]="faAdmin" size="1x"></fa-icon>
    </span>
    <!-- No Access -->
    <span class="harmony-grey-text m-1" [title]="'User has no permissions'" *ngIf="Modules.length <= 0">
      <fa-icon [icon]="faNoAccess" size="1x"></fa-icon>
    </span>
  </div>
</div>
