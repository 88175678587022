import { Component, OnInit } from '@angular/core';
import { IRate } from '../../../../services/models/pricing/iata.model';
import { JsonViewRatesModalComponent } from '../json-view-rates-modal/json-view-rates-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IPage, IPaginationData } from '../../../../shared/models/pagination-data.model';
import { ExternalDataService } from '../../../../services/external-data.service';
import { AlertService } from '../../../../services/alert.service';
import {IApiResponse} from '../../../../shared/models/api-reponse.model';

@Component({
  selector: 'app-iata-rates-tab',
  templateUrl: './iata-rates-tab.component.html',
  styleUrls: ['./iata-rates-tab.component.scss']
})
export class IataRatesTabComponent implements OnInit {

  public loading: boolean;
  fromDate: string;
  toDate: string;
  rateSource: string = null;
  destination: string;
  originId: number;
  destinationId: number;
  origin: string;
  rates: IRate[] = [];
  ratesToImport: IRate[] = [];
  applicableProduct: string = 'GENERAL CARGO';
  pagination: IPaginationData<IRate>;
  page: IPage;
  noResults: boolean = false;

  constructor(private externalDataService: ExternalDataService,
              private modalService: NgbModal,
              private alertService: AlertService) {
  }

  ngOnInit() {
    this.pagination = {
      DataSet: [],
      Data: null,
      CurrentPage: 1,
      PageSize: 30,
      TotalPages: 1
    } as IPaginationData<IRate>;
    this.page = {
      pageNumber: 1,
      pageSize: 0,
      batched: false
    } as IPage;
  }

  canSearch() {
    return (
      this.fromDate ||
      this.toDate ||
      this.destination ||
      this.origin ||
      this.rateSource);
  }

  changePage(event: IPage) {
    this.page = event;
    this.getFreightRates();
  }

  // Data Functions
  getFreightRates() {
    this.loading = true;
    this.externalDataService.getIataRates(this.toDate, this.fromDate, this.rateSource, this.origin, this.destination, this.page, this.applicableProduct)
      .subscribe({
        next: (data) => {
          if (data) {
            this.pagination = Object.assign([], data);
            this.rates = this.pagination.DataSet;
            this.noResults = this.pagination.DataSet && this.pagination.DataSet.length === 0;
          }
          this.loading = false;
        }, error: () => {
          this.loading = false;
        }
      });
  }

  selectRate(rate: IRate) {
    const isRateAdded = this.ratesToImport.indexOf(rate);

    if (isRateAdded !== -1) {
      this.ratesToImport.splice(isRateAdded, 1);
    } else {
      this.ratesToImport.push(rate);
    }
  }

  // The rates should be set and valid object and only done once
  canImportRates() {
    return this.ratesToImport?.length > 0;
  }

  importRates() {
    this.loading = true;

    this.externalDataService.addIataRates(this.ratesToImport)
      .subscribe({
        next: () => {
          this.loading = false;
          this.alertService.info('Successfully imported');
        }, error: (err: IApiResponse) => {
          this.alertService.error(err?.Meta?.Message);
          this.loading = false;
        }
      });
  }

  jsonViewRate(rate) {
    const modalRef = this.modalService.open(JsonViewRatesModalComponent, {size: 'xl', backdrop: 'static'});
    modalRef.componentInstance.Rate = rate;
  }

  clear() {
    this.applicableProduct = null;
  }

  resetSearch() {
    this.fromDate = null;
    this.toDate = null;
    this.rateSource = null;
    this.destination = null;
    this.originId = null;
    this.destinationId = null;
    this.origin = null;
    this.applicableProduct = 'GENERAL CARGO';
  }
}
