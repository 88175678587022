<div class="dashboard-background"></div>
<div class="dashboard-wrapper">
    <div class="dashboard-content">
      <!-- For each card create below div with card component inside -->
      <app-dashboard-card-rate-requests *ngIf="rateRequests" class="dashboard-card" [column]="2" [row]="1"></app-dashboard-card-rate-requests>
      <dashboard-card-pricing-document-list *ngIf="documents" class="dashboard-card" [column]="1" [row]="1"></dashboard-card-pricing-document-list>
      <app-dashboard-pricing-articles class="dashboard-card" [column]="1" [row]="5" [categories]="['Pricing']"></app-dashboard-pricing-articles>
      <app-dashboard-card-tenders *ngIf="tenders" class="dashboard-card" [column]="1" [row]="2"></app-dashboard-card-tenders>
      <app-dashboard-card-check-rates *ngIf="checkRates" class="dashboard-card" [column]="2" [row]="2"></app-dashboard-card-check-rates>
    </div>
</div>

