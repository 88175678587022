<!---------------------------------------------------->
<!-- Freight Is NOT Allowed, Location limit reached -->
<!---------------------------------------------------->
<div class="row mt-4" *ngIf="locationCount > locationLimit">
  <div class="col-8 offset-2 text-center">
    <h4 class="harmony-grey-text fw-bold">
      Location capacity for freight rates have been reached, please <a class="fw-bold harmony-secondary-link" [href]="mailToString">contact us</a> for freight rates.
    </h4>
    <p class="harmony-grey-text">
      Freight rates can only be calculated through the Rate Wizard when working with less than <b>{{locationLimit}}</b> locations in one tender.
      If you require freight rates for this tender, stop here and <a class="fw-bold harmony-secondary-link" [href]="mailToString">contact</a>
      Harmony Relocation Network, we will implement the freight rates for you manually as part of this tender and they will be available as part of the export.
    </p>
    <p class="harmony-grey-text">
      <small>
        <fa-icon [icon]="faInfo"></fa-icon> If you do not require freight rates, you can continue with the tender. Only origin and destination rates will be provided.
      </small>
      <br>
      <small>
        <fa-icon [icon]="faInfo"></fa-icon> LCL Freight rates are currently unavailable.
      </small>
    </p>
  </div>
</div>
<!------------------------>
<!-- Freight Is Allowed -->
<!------------------------>
<div class="mt-3 row ps-2 pe-2" *ngIf="locationCount <= locationLimit">
  <div class="col-12 text-start harmony-grey-text">
    <p class="mb-0">
      <small>
        <fa-icon [icon]="faInfo"></fa-icon> Please select all origin/destination combinations that require freight rates.
      </small>
    </p>
    <p class="mb-0">
      <small>
        <fa-icon [icon]="faInfo"></fa-icon> It is possible to select all fields in the matrix, by clicking on the <b>Select all</b> button, or toggle all fields for a specific row/column only, by clicking on the respective header.
      </small>
    </p>
  </div>
</div>
<div class="row mt-2" *ngIf="locationCount <= locationLimit">
  <div class="col-12">
    <ng-container *ngIf="freightModalityTypes && freightModalityTypes.length > 0">
      <ul class="nav nav-tabs" id="freightTab" role="tablist">
        <li class="nav-item fw-bold"
            *ngFor="let modalityType of freightModalityTypes; let i = index"
            [hidden]="getTenderModalityByModalityTypeID(modalityType.Id).length <= 0">
          <a class="nav-link harmony-tertiary-text"
            role="tab"
            data-bs-toggle="tab"
            [ngClass]="{'active': isActiveTab(modalityType.Id)}"
            [id]="modalityType.Name + '-tab'"
            [href]="'#' + modalityType.Name"
            [attr.aria-controls]="modalityType.Name"
            [attr.aria-selected]="true">
            {{ modalityType.Name }}
          </a>
        </li>
      </ul>
      <div class="tab-content" id="freightTabContent">
        <div class="tab-pane mb-5 pb-5 fade"
             role="tabpanel"
             *ngFor="let modalityType of freightModalityTypes; let i = index"
             [ngClass]="{'show active': isActiveTab(modalityType.Id)}"
             [id]="modalityType.Name"
             [hidden]="getTenderModalityByModalityTypeID(modalityType.Id).length <= 0"
             [attr.aria-labelledby]="modalityType.Name + '-tab'">
          <div class="row">
            <div class="col-12 mt-2 mb-0 text-start">
              <p class="harmony-grey-text">
                Select which combinations will require <b>{{ modalityType.Name }}</b> Freight rates. <b>Origin</b> and <b>Destination</b> configuration is set in the <b>Locations</b> step, and can be updated in that step if the below is not correct.
              </p>
            </div>
          </div>
          <ng-container *ngIf="!loading">
            <div class="row">
              <div class="col-10 text-center mt-1">
                <h5 class="fw-bold harmony-primary-text mb-0">
                  DESTINATIONS
                </h5>
              </div>
              <div class="col-2 text-end">
                <button class="btn btn-sm btn-primary harmony-primary-button me-1"
                        [disabled]="tenderService.IsQuoteAccepted() || tenderService.loading"
                        (click)="selectAll(modalityType)">
                  Select all
                </button>
                <button class="btn btn-sm btn-primary harmony-primary-outline-button"
                        [disabled]="tenderService.IsQuoteAccepted() || tenderService.loading"
                        (click)="unselectAll(modalityType)">
                  Unselect all
                </button>
              </div>
              <div class="col-12">
                <div class="table-wrapper">
                  <table class="table mt-2">
                    <thead>
                    <tr>
                      <th scope="col" class="text-start pointer origin-label">
                        <h5 class="fw-bold harmony-primary-text">
                          ORIGINS
                        </h5>
                      </th>
                      <th scope="col"
                          style=""
                          class="freight-column destinations text-center pointer"
                          [ngClass]="{'vertical-text': destinationLocations.length > 1}"
                          *ngFor="let destination of destinationLocations"
                          (click)="toggleColumn(destination, modalityType)">
                        {{ destination.PartnerLocationName }}
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let origin of originLocations">
                      <th scope="row"
                          class="freight-column-label origins pointer ps-2"
                          (click)="toggleRow(origin, modalityType)">
                        {{ origin.PartnerLocationName }}
                      </th>
                      <td class="freight-column text-center pointer"
                          *ngFor="let combination of getOptionsByOrigin(freightCombinations, origin.Id, modalityType.Id)"
                          [ngClass]="{ 'muted': combination.Origin.Id == combination.Destination.Id}"
                          (click)="toggleCombination(combination)"
                          [title]="getTooltip(combination.Origin, combination.Destination)">
                        <div class="form-check form-switch center-switch mb-0">
                          <input class="form-check-input d-inline mb-0"
                                 type="checkbox"
                                 role="switch"
                                 [disabled]="tenderService.IsQuoteAccepted() || tenderService.loading"
                                 [checked]="combination.IsSelected"
                                 *ngIf="combination.Origin.Id != combination.Destination.Id">
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="loading">
            <div class="row mt-3">
              <div class="col-12 text-center">
                <app-loader [Width]="50"></app-loader>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</div>
