<!---------------------->
<!------- Header ------->
<!---------------------->
<div
  class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom sticky-header">
  <h1 class="portal-component-header">
    Company branches
  </h1>
  <div class="justify-content-end">
    <button class="btn btn-sm btn-primary harmony-purple-outline-button"
            *ngIf="isAdmin"
            [disabled]="loading" (click)="createBranch()">
      Add Company branch
    </button>
  </div>
</div>
<!--------------------------------->
<!-- Company branch Overview ----->
<!--------------------------------->
<ng-container *ngIf="isAdmin">
  <div class="risk-assessment">
    <!----------------------->
    <!------- Filters ------->
    <!----------------------->
    <ng-container>
      <div class="row">
        <div class="col-3">
          <input class="form-control form-control-sm"
                 type="text"
                 placeholder="Search company branch..."
                 [(ngModel)]="branchSearchParams.SearchText">
        </div>
        <div class="col-1">
          <b>Company</b>
        </div>
        <div class="col-2">
          <select class="form-select form-select-sm pointer"
                  [(ngModel)]="branchSearchParams.CompanyId"
                  [disabled]="loading || !(companies$ | async)">
            <option [ngValue]="null" selected>All companies...</option>
            <option *ngFor="let company of (companies$ | async)"
                    [ngValue]="company.CompanyId">{{ company.CompanyName }}</option>
          </select>
        </div>
        <div class="col-2 offset-2 text-end pe-0">
          <button class="btn btn-sm btn-primary harmony-teal-outline-button ms-2"
                  [title]="'Reset search'"
                  (click)="resetSearch()">
            Reset
          </button>
        </div>
        <div class="col-2 mb-1">
          <button class="btn btn-sm btn-primary harmony-purple-button w-100 float-end"
                  (click)="searchBranches()">
            Search
          </button>
        </div>
      </div>
      <hr class="my-1">
    </ng-container>
    <!------------------->
    <!---- Pagination --->
    <!------------------->
    <div class="row ms-1 me-1">
      <div class="col-12">
        <app-pagination [showPageNumber]=true
                        [showRowsPerPage]=true
                        [paginationData]=paginationData
                        (pageEvent)="onPage($event)"
                        [name]="'BranchAdmin'"
                        [page]="page">
        </app-pagination>
      </div>
    </div>
    <!----------------------->
    <!------- Results ------->
    <!----------------------->
    <div class="row mt-2 header-row">
      <div class="col-2 header-title border-split-left"
           (appOrder)="orderSearch($event)"
           data-column="Company.Name"
           data-order="desc">
        Company
        <fa-icon [hidden]="orderParam.OrderBy != 'Company.Name'" [icon]="faSortAsc" style="bottom: 3px;" class="ms-2 position-relative"></fa-icon>
      </div>
      <div class="col-2 header-title"
           (appOrder)="orderSearch($event)"
           data-column="Name"
           data-order="asc">
        Branch name
        <fa-icon [hidden]="orderParam.OrderBy != 'Name'" [icon]="faSortAsc" style="bottom: 3px;" class="ms-2 position-relative"></fa-icon>
      </div>
      <div class="col-2 header-title"
           (appOrder)="orderSearch($event)"
           data-column="Branch.Address1"
           data-order="asc">
        Address1
      </div>
      <div class="col-2 header-title "
           (appOrder)="orderSearch($event)"
           data-column="Branch.Address2"
           data-order="asc">
        Address2
      </div>
      <div class="col-1 header-title"
           (appOrder)="orderSearch($event)"
           data-column="Branch.ZipCode"
           data-order="asc">
        Zip code
      </div>
      <div class="col-1 header-title"
           (appOrder)="orderSearch($event)"
           data-column="City.Name"
           data-order="asc">
        City
        <fa-icon [hidden]="orderParam.OrderBy != 'City.Name'" [icon]="faSortAsc" style="bottom: 3px;" class="ms-2 position-relative"></fa-icon>
      </div>
      <div class="col-1 header-title"
           (appOrder)="orderSearch($event)"
           data-column="Country.Name"
           data-order="asc">
        Country
        <fa-icon [hidden]="orderParam.OrderBy != 'Country.Name'" [icon]="faSortAsc" style="bottom: 3px;" class="ms-2 position-relative"></fa-icon>
      </div>
      <div class="col-1 header-title border-split-right text-center">
      </div>
    </div>
    <app-loader *ngIf="loading"></app-loader>
    <app-no-result [showNoResults]="noResult"></app-no-result>
    <div class="row result-row my-0" *ngFor='let branch of paginationData.DataSet; let i = index'>
      <div class="col-2 overflow-hidden">
        {{ branch.Company }}
      </div>
      <div class="col-2 fw-bold overflow-hidden">
        {{ branch.Name }}
      </div>
      <div class="col-2 overflow-hidden">
        {{ branch.Address1?.length > 0 ? branch.Address1 : '-' }}
      </div>
      <div class="col-2 overflow-hidden">
        {{ branch.Address2?.length > 0 ? branch.Address2 : '-' }}
      </div>
      <div class="col-1 overflow-hidden">
        {{ branch.ZipCode?.length > 0 ? branch.ZipCode : '-' }}
      </div>
      <div class="col-1 overflow-hidden">
        {{ branch.City?.length > 0 ? branch.City : '-' }}
      </div>
      <div class="col-1 overflow-hidden">
        {{ branch.Country?.length > 0 ? branch.Country : '-' }}
      </div>
      <div class="col-1 text-end">
        <button class="btn btn-sm btn-primary harmony-teal-outline-button me-1"
                [title]="'View or edit company branch'" (click)="openBranch(branch)">
          <fa-icon [icon]="faEdit"></fa-icon>
        </button>
        <button *ngIf="authService.CheckPermissionByCode(PermissionCodes.HSE_Admin)"
                class="btn btn-sm btn-outline-danger"
                [title]="'Delete company branch'" (click)="deleteBranch(branch)">
          <fa-icon [icon]="faDelete"></fa-icon>
        </button>
      </div>
    </div>
  </div>
</ng-container>


