<!---------------------->
<!------- Header ------->
<!---------------------->
<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom sticky-header">
  <ng-container *ngIf="!isARSelected">
    <h1 class="portal-component-header">
      Annual Review
    </h1>
    <h6 class="ms-auto mb-0">
      Questions? Download our <b class="harmony-tertiary-link pointer" (click)="getFAQ()"> FAQ</b>
    </h6>
    <button class="btn btn-sm btn-primary harmony-tertiary-outline-button float-end ms-3" *ngIf="isAdmin"
            (click)="close()">
      Back to Admin
    </button>
  </ng-container>
  <ng-container *ngIf="isARSelected">
    <h1 class="portal-component-header">
      Annual Review Questions & Answers : {{ selectedReview.ReviewYear }}
    </h1>
    <div class="justify-content-end">
      <button class="btn btn-sm btn-outline-primary harmony-tertiary-outline-button me-2"
              [title]="'Back to annual review'" (click)="back()">
        Back
      </button>
    </div>
  </ng-container>
</div>
<!-------------------------------->
<!-------- Overview List --------->
<!-------------------------------->
<div *ngIf="!isARSelected">
  <div class="row header-row align-content-start">
    <div class="col-3 header-title border-split-left">
      Company
    </div>
    <div class="col-1 header-title">
      Year
    </div>
    <div class="col-2 header-title">
      Answer Progress
    </div>
    <div class="col-2 header-title">
      Date Completed
    </div>
    <div class="col-3 header-title">
      Documents
    </div>
    <div class="col-1 header-title border-split-right">

    </div>
  </div>
  <!--Table Data-->
  <div class="row result-row" *ngFor='let review of reviews;'>
    <div class="col-3 overflow-hidden">
      <b>{{ review.CompanyName }}</b>
    </div>
    <div class="col-1 overflow-hidden">
      {{ review.ReviewYear }}
    </div>
    <div class="col-2 overflow-hidden">
      {{ review.TotalAnswered }} / {{ review.TotalQuestions }}
    </div>
    <div class="col-2 overflow-hidden">
      {{ review.DateCompleted ? (review.DateCompleted | date: environment.FormattingStandards.ShortDateFormat) : '-'}}
    </div>
    <div class="col-3 overflow-hidden">
      <button class="btn btn-sm  harmony-primary-outline-button mx-1"
              [disabled]="!review.HasHarmonyDocument"
              (click)="getDocumentByName(review.HarmonyDocument)">
        Harmony
      </button>
      <button class="btn btn-sm harmony-primary-outline-button mx-1"
              [disabled]="!review.HasNetworkCompanyDocument"
              (click)="getAnnualReviewNetworkDoc(review.ReviewId, review.Id, review.CompanyId)">
        Combined
      </button>
      <button class="btn btn-sm harmony-primary-outline-button mx-1"
              [disabled]="!review.HasCompanyDocument"
              (click)="getAnnualReviewCompanyDoc(review.ReviewId, review.Id, review.CompanyId)">
        Company
      </button>
    </div>
    <div class="col-1 overflow-hidden text-end pe-0">
      <button *ngIf="review.IsReviewOpen" class="btn btn-sm btn-outline-primary harmony-tertiary-outline-button m-0"
              (click)="viewAnswers(review)" [title]="'Answer annual review'">
        <fa-icon [icon]="faEdit"></fa-icon>
      </button>
      <button *ngIf="review.HasReviewClosed" class="btn btn-sm btn-outline-primary harmony-primary-outline-button m-0"
              (click)="viewAnswers(review)" [title]="'View annual review'">
        <fa-icon [icon]="faView"></fa-icon>
      </button>
    </div>
  </div>
</div>
<app-loader *ngIf="loading"></app-loader>
<app-no-result [showNoResults]="questionNoResults"></app-no-result>
<!-------------------------------------->
<!-------- Questions & Answers --------->
<!-------------------------------------->
<div *ngIf="isARSelected" class="pt-2 pb-2">
  <div class="row mb-2">
    <div class="col-3">
      <div class="form-check">
        <input class="form-check-input"
               type="checkbox"
               name="mandatory-check"
               id="mandatory"
               [(ngModel)]="isMandatory"
               (ngModelChange)="filter()">
        <label class="form-check-label" for="mandatory">
          Mandatory
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input"
               type="checkbox"
               name="unansweredCheck"
               id="unanswered"
               [(ngModel)]="isUnanswered"
               (ngModelChange)="filter()">
        <label class="form-check-label" for="unanswered">
          Unanswered
        </label>
      </div>
    </div>
    <div class="col-3 align-self-center offset-6">
      <button *ngIf="!isReviewCompleted" class="btn btn-sm btn-primary harmony-tertiary-button float-end"
              [ngbTooltip]="'Save and submit your annual review'" (click)="submit()">
        Submit
      </button>
      <button *ngIf="!isReviewCompleted" class="btn btn-sm btn-primary harmony-primary-outline-button float-end me-2"
              [ngbTooltip]="'Save your progress'" (click)="save()">
        Save
      </button>
      <h6 class="mt-2 float-end me-2">
        Total progress: {{ chapterQuestionAnswers.TotalAnswered ?? 0 }}/{{ chapterQuestionAnswers.TotalQuestions }}
      </h6>
    </div>
  </div>
  <div class="accordion" id="accordion-question">
    <div class="row" *ngFor='let chapter of filteredChapters; let c = index;'>
      <div class="col-12">
        <div class="card my-1">
          <div class="card-header p-0" [attr.id]="'heading-' + c">
            <div class="row pointer" (click)="chapterBtn.click()">
              <div class="col-9 text-start">
                <h3 class="mb-0">
                  <button class="btn chapter-header mb-0 pt-2" #chapterBtn [ngClass]="{ 'collapsed': c > 0 }"
                          type="button"
                          data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse-' + c"
                          [attr.aria-expanded]="c == 0" [attr.aria-controls]="'collapse-' + c">
                    {{ chapter.ChapterName }}
                  </button>
                  <fa-icon class="float-end exclamation-icon mt-1" *ngIf="this.validateAnswers && chapter.Invalid"
                           [icon]="faAlert"></fa-icon>
                </h3>
              </div>
              <div class="col-3 align-self-center text-end harmony-grey-text pe-4">
                <small>Progress: <strong>{{ chapter.TotalAnswered }} / {{ chapter.TotalQuestions}}</strong></small>
              </div>
            </div>
          </div>
          <div [attr.id]="'collapse-' + c" class="collapse" [attr.aria-labelledby]="'heading-' + c"
               [ngClass]="{'show' : c == 0 }" data-bs-parent="#accordion-question">
            <div class="card-body">
              <div class="row result-row py-2" *ngFor='let question of chapter.AnswerQuestions; let q = index'
                   [ngClass]="{'even': question % 2 === 0}">
                <div class="col-9">
                  <div class="row">
                    <div class="col-1">
                      <strong>{{ question.Label ? question.Label : '-' }}</strong>
                    </div>
                    <div class="col-11 question-field"
                         [ngClass]="{'is-invalid':this.validateAnswers && question.Invalid }">
                      {{ question.Question }} <span *ngIf="question.Mandatory" class="text-danger">*</span>
                      <br>
                      <small class="harmony-grey-text" *ngIf="question.Requirement">
                        {{ question.Requirement }}
                      </small>
                    </div>
                  </div>
                </div>
                <div class="col-3">
                  <!-- QUESTION TYPE :: 1 Text Answers ------------------------------------------------------------------>
                  <div class="row" *ngIf="question.Type == 1">
                    <div class="col-12 align-self-center">
                      <textarea rows="2"
                                [disabled]="isReviewCompleted || isAdmin"
                                class="form-control input-picker-value"
                                appAutocompleteOff
                                placeholder="Enter answer. Stretch box or select button"
                                [(ngModel)]="question.Answer.Value"
                                (change)="changeAnswer(chapter.Id, question.Id, question.Answer.Value, c)"></textarea>
                      <button *ngIf="!isReviewCompleted"
                              class="btn btn-sm btn-primary harmony-primary-outline-button float-end input-picker-button"
                              [title]="'Edit answer'"
                              (click)="openAnswerModal(isReviewCompleted, question.Answer, chapter.Id, question.Id, c)">
                        <fa-icon [icon]="faEdit"></fa-icon>
                      </button>
                      <button *ngIf="isReviewCompleted"
                              class="btn btn-sm btn-primary harmony-primary-outline-button float-end input-picker-button"
                              [title]="'View answer'"
                              (click)="openAnswerModal(isReviewCompleted, question.Answer, chapter.Id, question.Id, c)">
                        <fa-icon [icon]="faView"></fa-icon>
                      </button>
                    </div>
                  </div>
                  <!-- QUESTION TYPE 2 :: Radio [YES/NO] ------------------------------------------------------------------>
                  <div class="row" *ngIf="question.Type == 2">
                    <div class="col-4">
                      <input type="radio" class="me-1"
                             [attr.id]="'radio-type-2-yes-' + question.Id"
                             [attr.name]="'radio-type-2-' + question.Id"
                             [value]="'Yes'"
                             [disabled]="isReviewCompleted || isAdmin"
                             [checked]="question.Answer.Value == 'Yes'"
                             (change)="changeAnswer(chapter.Id, question.Id, 'Yes', c)">
                      <label [attr.for]="'radio-type-2-yes-' + question.Id" class="me-4">Yes</label>
                    </div>
                    <div class="col-4">
                      <input type="radio" class="me-1"
                             [attr.id]="'radio-type-2-no-' + question.Id"
                             [attr.name]="'radio-type-2-' + question.Id"
                             [value]="'No'"
                             [disabled]="isReviewCompleted || isAdmin"
                             [checked]="question.Answer.Value == 'No'"
                             (change)="changeAnswer(chapter.Id, question.Id, 'No', c)">
                      <label [attr.for]="'radio-type-2-no-' + question.Id">No</label>
                    </div>
                  </div>
                  <!-- QUESTION TYPE 3 :: Radio [YES/NO/NA] ------------------------------------------------------------------>
                  <div class="row" *ngIf="question.Type == 3">
                    <div class="col-4">
                      <input type="radio"
                             class="me-1"
                             [attr.id]="'radio-type-3-yes-' + question.Id"
                             [attr.name]="'radio-type-3-' + question.Id"
                             [value]="'Yes'"
                             [disabled]="isReviewCompleted || isAdmin"
                             [checked]="question.Answer.Value === 'Yes'"
                             (change)="changeAnswer(chapter.Id, question.Id, 'Yes', c)"
                             name="radio-type-3">
                      <label [attr.for]="'radio-type-3-yes-' + question.Id">Yes</label>
                    </div>
                    <div class="col-4">
                      <input type="radio"
                             class="me-1"
                             [attr.id]="'radio-type-3-no-' + question.Id"
                             [attr.name]="'radio-type-3-' + question.Id"
                             [value]="'No'"
                             [disabled]="isReviewCompleted || isAdmin"
                             [checked]="question.Answer.Value === 'No'"
                             (change)="changeAnswer(chapter.Id, question.Id, 'No', c)"
                             name="radio-type-3">
                      <label [attr.for]="'radio-type-3-no-' + question.Id">No</label>
                    </div>
                    <div class="col-4">
                      <input type="radio"
                             class="me-1"
                             [attr.id]="'radio-type-3-na-' + question.Id"
                             [attr.name]="'radio-type-3-' + question.Id"
                             [value]="'N/A'"
                             [disabled]="isReviewCompleted || isAdmin"
                             [checked]="question.Answer.Value === 'N/A'"
                             (change)="changeAnswer(chapter.Id, question.Id, 'N/A', c)"
                             name="radio-type-3">
                      <label [attr.for]="'radio-type-3-na-' + question.Id">N/A</label>
                    </div>
                  </div>
                  <!-- QUESTION TYPE 4 :: Number ------------------------------------------------------------------>
                  <input class="form-control"
                         type="text"
                         placeholder="Enter answer with a number"
                         [disabled]="isReviewCompleted || isAdmin"
                         [(ngModel)]="question.Answer.Value"
                         (change)="changeAnswer(chapter.Id, question.Id, question.Answer.Value, c, false)"
                         *ngIf="question.Type == 4" numbersOnly="10.2">
                  <!-- QUESTION TYPE 5 :: Date ------------------------------------------------------------------>
                  <div *ngIf="question.Type == 5">
                    <input [attr.id]="'date-input-' + q"
                           type="text"
                           ngbDatepicker
                           #AnswerDate="ngbDatepicker"
                           class="form-control form-control-sm input-picker-value"
                           placeholder="Enter date yyyy-mm-dd"
                           (dateSelect)="setDateAnswer($event, chapter.Id, question.Id, c)"
                           [disabled]="isReviewCompleted || isAdmin"
                           [value]="question.Answer.Value"
                           [ngbTooltip]="'Select a date'"/>
                    <button class="btn btn-sm btn-primary harmony-tertiary-button input-picker-button"
                            [ngbTooltip]="'Select a start date'"
                            [disabled]="isReviewCompleted || isAdmin"
                            (click)="AnswerDate.toggle()">
                      <fa-icon [icon]="faCalendar"></fa-icon>
                    </button>
                  </div>
                  <!-- QUESTION TYPE 6 :: Radio [YES/NO/FIRST] ------------------------------------------------------------>
                  <div class="row" *ngIf="question.Type == 6">
                    <div class="col-4">
                      <input type="radio" class="me-1" [disabled]="isReviewCompleted || isAdmin"
                             [attr.id]="'radio-type-6-yes-' + question.Id"
                             [attr.name]="'radio-type-6-' + question.Id"
                             [checked]="question.Answer.Value === 'Yes'"
                             (change)="changeAnswer(chapter.Id, question.Id, 'Yes', c)"
                             name="radio-type-6">
                      <label [attr.for]="'radio-type-6-yes-' + question.Id">Yes</label>
                    </div>
                    <div class="col-4">
                      <input type="radio" class="me-1" [disabled]="isReviewCompleted || isAdmin"
                             [attr.id]="'radio-type-6-no-' + question.Id"
                             [attr.name]="'radio-type-6-' + question.Id"
                             [checked]="question.Answer.Value === 'No'"
                             (change)="changeAnswer(chapter.Id, question.Id, 'No', c)"
                             name="radio-type-6">
                      <label [attr.for]="'radio-type-6-no-' + question.Id">No</label>
                    </div>
                    <div class="col-4">
                      <input type="radio" class="me-1" [disabled]="isReviewCompleted || isAdmin"
                             [attr.id]="'radio-type-6-first-' + question.Id"
                             [attr.name]="'radio-type-6-' + question.Id"
                             [checked]="question.Answer.Value === 'First'"
                             (change)="changeAnswer(chapter.Id, question.Id, 'First', c)"
                             name="radio-type-6">
                      <label [attr.for]="'radio-type-6-first-' + question.Id">First</label>
                    </div>
                  </div>
                  <!-- QUESTION TYPE 7 :: MULTIPLE CHOICE CHECKBOX ---------------------------------------------------------->
                  <div *ngIf="question.Type == 7">
                    <ng-container *ngFor="let option of getOrderedOptions(question.Options); let o = index">
                      <div class="row">
                        <div class="col-12">
                          <input type="checkbox"
                                 class="me-1"
                                 [disabled]="isReviewCompleted || isAdmin"
                                 [attr.id]="'multiple-choice-' + option.Id"
                                 (change)="changeMultipleChoiceAnswer(question, option.Id, $event, c, q)"
                                 [checked]="getMultipleChoiceAnswer(question.MultipleChoiceAnswers, option)">
                          <label class="label-display-inline" [attr.for]="'multiple-choice-' + option.Id">
                            {{ option.Label }} {{ option.Option }}
                          </label>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
