<ng-container *ngIf="!companyReviewId">
  <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom sticky-header">
    <h1 class="portal-component-header">
      The audit no longer exists.
    </h1>
  </div>
</ng-container>
<ng-container *ngIf="companyReviewId">
  <!-------------------------------------------------------------------->
  <!---------------------------- Header -------------------------------->
  <!-------------------------------------------------------------------->
  <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom sticky-header">
    <h1 class="portal-component-header">
      Annual Review Audit | {{companyReview?.CompanyName}} {{companyReview?.ReviewYear}}
    </h1>
    <div class="justify-content-end">
      <button class="btn btn-sm btn-primary harmony-primary-button float-end ms-2"
              *ngIf="!hasSubmitAudit && isAdmin"
              [disabled]="!isAdmin"
              [title]="'Back to Admin'" (click)="close()">
        Back to Admin
      </button>
      <button class="btn btn-sm btn-primary harmony-tertiary-button float-end"
              *ngIf="!hasSubmitAudit"
              [disabled]="isAdmin"
              [title]="'Save and submit your audit'" (click)="submit()">
        Submit
      </button>
      <button class="btn btn-sm btn-primary harmony-primary-outline-button float-end me-2"
              *ngIf="!hasSubmitAudit"
              [disabled]="isAdmin"
              [title]="'Save your progress'" (click)="save(true)">
        Save
      </button>
    </div>
  </div>
  <app-loader *ngIf="loading"></app-loader>
  <div *ngIf="!hasSubmitAudit">
    <div class="row result-row mt-3" *ngFor='let question of auditQuestions; let i = index'>
      <div class="col-1 fw-bold">
        {{ question.Label ? question.Label : '-' }}
      </div>
      <div class="col-7 question-field"
           [ngClass]="{'is-invalid':this.validateAnswers &&
           ((question.Type === 1 || question.Type === 2) && (!question.Answer || question.Answer === '' || question.Answer === null || question.Answer === undefined)) ||
           (question.Type === 3 && question.Invalid) }">
        <b class="harmony-secondary-text">{{ question.ReviewQuestion }}</b>
        <br>
        {{ question.Value }}
        <br>
        <small class="harmony-grey-text" *ngIf="question.Requirement">
          {{ question.Requirement }}
        </small>
      </div>
      <div class="col-4 text-end">
        <!------------------AUDIT QUESTION TYPE :: TEXT ------------------------------------>
        <textarea class="form-control" *ngIf="question.Type === 1"
                  rows="3"
                  [placeholder]="'Enter your answer in text'"
                  [(ngModel)]="question.Answer"
                  [disabled]="isAdmin"
                  style="width:100%;" (change)="changeAnswer(question.Answer, question.AnswerId, question.Id, i)"></textarea>
        <!------------------AUDIT QUESTION TYPE :: DATE ------------------------------------>
        <div *ngIf="question.Type === 2">
          <input type="text"
                 ngbDatepicker
                 #AnswerDate="ngbDatepicker"
                 class="form-control input-picker-value"
                 (dateSelect)="setDateAnswer($event, question.AnswerId, question.Id, i)"
                 [value]="question.Answer"
                 [disabled]="isAdmin"
                 placeholder="Select a date">
          <button class="btn btn btn-primary harmony-tertiary-button input-picker-button"
                  [title]="'Select a start date'"
                  (click)="AnswerDate.toggle()"
                  [disabled]="isAdmin">
            <fa-icon [icon]="faCalendar"></fa-icon>
          </button>
        </div>
        <!------------------AUDIT QUESTION TYPE :: DOCUMENT ------------------------------------>
        <div *ngIf="question.Type === 3">
          <button class="btn btn-sm btn-outline-primary d-inline"
                  [ngClass]="question.Invalid ? 'harmony-primary-outline-button' : 'harmony-accent-green-outline-button' "
                  type="button"
                  style="width: 100%"
                  (click)="openDocumentModal(question, i)">
            Documents
            <span class="fw-bold">
              {{ question.Documents.length }}/{{ question.RequiredDocumentAmount }}
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="hasSubmitAudit" class="text-center mt-5">
    <h4 class="fw-bold harmony-secondary-text">Thank you for completing the Audit!</h4>
  </div>
</ng-container>
