<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-2 border-bottom sticky-header">
  <h1 class="portal-component-header">
    Agent Management
  </h1>
  <div>
    <button
      class="btn btn-sm btn-outline-primary harmony-primary-outline-button me-2"
      (click)="mergeAgents()">
      <fa-icon [icon]="faMerge"></fa-icon> Merge Agents
    </button>
    <button
      class="btn btn-sm btn-outline-primary harmony-tertiary-outline-button"
      (click)="newAgent()">
      <fa-icon [icon]="faAdd"></fa-icon> New Agent
    </button>
  </div>
</div>
<div class="row mb-5">
  <div class="col-12">
    <!-- Filtering -->
    <div class="row mt-2">
      <div class="col-3">
        <input type="text"
               numbersOnly="10.0"
               class="filter-item form-control form-control-sm"
               [(ngModel)]="searchParam.AgentId"
               (keyup.enter)="search()"
               placeholder="Agent ID...">
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-3">
        <input type="text"
               class="filter-item form-control form-control-sm"
               [(ngModel)]="searchParam.CityName"
               (keyup.enter)="search()"
               placeholder="Search by city name...">
      </div>
      <div class="col-2">
        <app-country-search
          class="d-inline"
          [Identifier]="'OriginCountryID'"
          [SelectedID]="searchParam.CountryId"
          (OnSelect)="searchParam.CountryId = $event">
        </app-country-search>
      </div>
    </div>
    <hr class="mt-1 mb-0">
    <div class="row mt-1">
      <div class="col-3">
        <input type="text"
               class="filter-item form-control form-control-sm"
               [(ngModel)]="searchParam.SearchText"
               (keyup.enter)="search()"
               placeholder="Search agents by name...">
      </div>
      <div class="col-3 offset-6 text-end">
        <button class="btn btn-sm btn-outline-primary harmony-primary-outline-button me-1"
                (click)="resetSearch()">
          Reset
        </button>
        <button class="btn btn-sm btn-primary harmony-tertiary-button w-50"
                (click)="searchAgents()">
          Search
        </button>
      </div>
    </div>
    <hr class="mt-1 mb-0">
    <!------------------->
    <!---- Pagination --->
    <!------------------->
    <div class="row ms-1 me-1">
      <div class="col-12">
        <app-pagination [showPageNumber]=true
                        [showRowsPerPage]=true
                        [paginationData]=paginationData
                        (pageEvent)="onPage($event)"
                        [name]="'AgentManagement'"
                        [page]="page">
        </app-pagination>
      </div>
    </div>
    <!--------------------->
    <!---- Result table --->
    <!--------------------->
    <div class="row mt-1 header-row">
      <div class="col-4 header-title col-compress pointer border-split-left"
           (appOrder)="orderSearch($event)"
           data-column="Name"
           data-order="asc">
        Name
        <fa-icon [hidden]="orderParam.OrderBy != 'Name'" [icon]="faSortDesc" style="bottom: -3px;" class="ms-2 position-relative"></fa-icon>
      </div>
      <div class="col-2 col-compress header-title pointer"
           (appOrder)="orderSearch($event)"
           data-column="CityPhysical"
           data-order="asc">
        City
        <fa-icon [hidden]="orderParam.OrderBy != 'CityPhysical'" class="ms-2 position-relative"></fa-icon>
      </div>
      <div class="col-2 col-compress header-title pointer"
           (appOrder)="orderSearch($event)"
           data-column="CountryPhysicalIso"
           data-order="asc">
        Country
        <fa-icon [hidden]="orderParam.OrderBy != 'CountryPhysicalIso'" class="ms-2 position-relative"></fa-icon>
      </div>
      <div class="col-2 col-compress header-title pointer"
           (appOrder)="orderSearch($event)"
           data-column="CompanyType.Description"
           data-order="asc">
        Type
        <fa-icon [hidden]="orderParam.OrderBy != 'CompanyType.Description'" class="ms-2 position-relative"></fa-icon>
      </div>
      <div class="col-1 col-compress header-title pointer"
           (appOrder)="orderSearch($event)"
           data-column="AgentId"
           data-order="asc">
        Agent ID
        <fa-icon [hidden]="orderParam.OrderBy != 'AgentId'" class="ms-2 position-relative"></fa-icon>
      </div>
      <div class="col-1 header-title col-compress text-end border-split-right">

      </div>
    </div>
    <!--------------------->
    <!------ Results ------>
    <!--------------------->
    <ng-container *ngIf="paginationData && paginationData.DataSet.length > 0">
      <div class="row result-row" *ngFor="let agent of paginationData.DataSet; let i = index">
        <div class="col-4 col-compress overflow-hidden">
          <b>{{ agent.Name }}</b>
        </div>
        <div class="col-2 col-compress overflow-hidden">
          {{ agent.City ?? '-' }}
        </div>
        <div class="col-2 col-compress overflow-hidden">
          {{ agent.Country ?? '-' }} {{ agent.CountryIso ? '(' + agent.CountryIso + ')': '' }}
        </div>
        <div class="col-2 col-compress overflow-hidden">
          {{ agent.CompanyType }}
        </div>
        <div class="col-1 col-compress overflow-hidden">
          {{ agent.AgentId ?? '-' }}
        </div>
        <div class="col-1 col-compress text-end pe-0">
          <button class="btn btn-sm btn-primary harmony-primary-outline-button me-1"
                  (click)="editAgent(agent)">
            <fa-icon [icon]="faEdit" size="xs"></fa-icon>
          </button>
          <button class="btn btn-sm btn-outline-danger"
                  *ngIf="agent.CompanyType.toLowerCase() == 'agent'"
                  (click)="deleteAgent(agent.CompanyId)">
            <fa-icon [icon]="faRemove" size="xs"></fa-icon>
          </button>
        </div>
      </div>
    </ng-container>
    <!--------------------->
    <!------ Loader ------->
    <!--------------------->
    <div class="row mt-4" *ngIf="loading">
      <div class="col-12 text-center">
        <app-loader [Width]="50"></app-loader>
      </div>
    </div>
    <!--------------------->
    <!---- No Results ----->
    <!--------------------->
    <div class="row mt-4" *ngIf="!loading && (paginationData && paginationData.DataSet.length === 0)">
      <div class="col-12 text-center harmony-grey-text">
        <fa-icon [icon]="faEmpty" size="2x"></fa-icon>
      </div>
      <div class="col-12 text-center harmony-grey-text mt-1">
        <h4>No results...</h4>
      </div>
    </div>
  </div>
</div>
