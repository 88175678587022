import { Component } from '@angular/core';
import { faCircleDot, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-services-insurance',
  templateUrl: './services-insurance.component.html',
  styleUrls: ['./services-insurance.component.scss']
})
export class ServicesInsuranceComponent {

  // Icons
  faMenuItem = faCircleDot;
  faEnvelope = faEnvelope;
  faPhone = faPhone;

  // General variables
  env = environment;

}
