import { Component, OnDestroy, OnInit } from '@angular/core';
import { IEnvironmentalImpactData } from '../../../services/models/hse/environmental-dashboard-model';
import { faEdit, faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { CompanyService } from '../../../services/company.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../../../services/alert.service';
import { ApiService } from '../../../services/api.service';
import { AdAuthService } from '../../../core/ad-auth-service/ad-auth.service';
import { ImpactDataInformationModalComponent } from './impact-data-information-modal/impact-data-information-modal.component';
import { PermissionCodes } from '../../../core/constants/permission-codes';
import { OpenConfirmationModal } from '../../../shared/components/confirmation-modal/confirmation-modal-functions';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { IApiResponse } from '../../../shared/models/api-reponse.model';
import { IActiveCompany, ICompanyImagesDto } from '../../../services/models/member.model';

@Component({
  selector: 'app-environmental-impact-data',
  templateUrl: './environmental-impact-data.component.html',
  styleUrls: ['./environmental-impact-data.component.scss']
})
export class EnvironmentalImpactDataComponent implements OnInit, OnDestroy {
  // Icons
  protected readonly faEdit = faEdit;
  protected readonly faView = faEye;
  protected readonly faDelete = faTrash;
  protected readonly faInfo = faCircleQuestion;

  // Component variables
  selectedCompany: IActiveCompany = null;
  companyImages: ICompanyImagesDto;
  impactData: IEnvironmentalImpactData[] = [];
  activeTab: string = 'CB';
  invalid: boolean;
  newEntry: boolean;
  showWatermark: boolean = false;
  companies$ = this.companyService.ActiveMembers$.asObservable();
  currentYear: number = 0;

  // Permissions
  canAdd: boolean = false;
  canFilterCompany: boolean;
  canDelete: boolean;
  canEdit: boolean;

  // General Variables
  loading: boolean;
  noResult: boolean = true;
  private unsubscribe: Subject<any> = new Subject<any>();

  constructor(private companyService: CompanyService,
              private modalService: NgbModal,
              private alertService: AlertService,
              private apiService: ApiService,
              private authService: AdAuthService) {
  }

  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  ngOnInit() {
    this.getCurrentYear();

    if (this.authService.CurrentUser) {
      this.setDefaultCompany(this.authService.CurrentUser.User.CompanyId);
      this.assignPermissions();
    }

    this.authService.CurrentUser$
      .subscribe(() => {
        this.setDefaultCompany(this.authService.CurrentUser.User.CompanyId);
        this.assignPermissions();
      });
  }

  setDefaultCompany(companyId: number) {
    this.companies$.subscribe((x) => {
      if (companyId) {
        this.selectedCompany = x.find(l => l.CompanyId === companyId);
        this.selectCompany(this.selectedCompany);
      }
    });
  }

  getCurrentYear() {
    const today = new Date();
    this.currentYear = today.getFullYear();
  }

  assignPermissions() {
    this.canFilterCompany = this.authService.CheckPermissionByCode(PermissionCodes.HSE_EnvironmentalImpactData_CompanyFilter);
    this.canAdd = this.authService.CheckPermissionByCode(PermissionCodes.HSE_EnvironmentalImpactData_Add);
    this.canDelete = this.authService.CheckPermissionByCode(PermissionCodes.HSE_EnvironmentalImpactData_Delete);
    this.canEdit = this.authService.CheckPermissionByCode(PermissionCodes.HSE_EnvironmentalImpactData_Edit);
  }

  selectCompany($event: IActiveCompany) {
    this.selectedCompany = $event;
    this.setWatermark();
    if (!!$event) {
      this.getImpactData();
    } else {
      this.impactData = [];
      this.noResult = true;
    }
  }

  toggleTab(code: string) {
    if (this.activeTab === code) {
      this.activeTab = null;
    } else {
      this.activeTab = code;
    }
  }

  setWatermark() {
    if (this.selectedCompany) {
      this.companyService.getCompanyImages(this.selectedCompany?.CompanyId)
        .subscribe({
          next: (data) => {
            this.companyImages = Object.assign({}, data);
          },
          error: () => {
            this.alertService.warn('Could not load company image data.');
          }
        });
    }
  }

  addImpactChart() {
    if (!this.selectedCompany.CompanyId || !(this.selectedCompany.CompanyId > 0)) {
      this.alertService.error('Select a company before adding a new entry');
      return;
    }

    if (!this.newEntry) {
      const newYear = this.impactData[0]?.Year;

      const item = {
        IsNew: true,
        CompanyId: this.selectedCompany.CompanyId,
        Year: newYear ? (newYear + 1) : this.currentYear
      } as IEnvironmentalImpactData;
      this.impactData.unshift(item);
      this.noResult = false;
      if (this.activeTab == null) {
        this.activeTab = 'CB';
      }
      return;
    } else {
      this.alertService.warn('Please save the existing new entry before adding another.');
    }
  }

  getImpactData() {
    this.loading = true;
    this.apiService.get(`EnvironmentalImpactData/ByCompanyId/${this.selectedCompany.CompanyId}`)
      .subscribe({
        next: (data) => {
          this.loading = false;
          this.impactData = data;
          this.impactData.forEach((item) => {
            this.calcValues(item);
            this.calcCo2(item);
          });
          this.noResult = this.impactData.length === 0;
        }, error: () => {
          this.noResult = true;
          this.loading = false;
        }
      });
  }

  calcValues(item: IEnvironmentalImpactData) {
    item.OfficeElectricUsePerFte = (+(item.GreyElectricUse ?? 0) + +(item.GreenElectricUse ?? 0)) / +(item.CompanyAverageFte ?? 1);
    item.NaturalGasUsePerDegreeDay = +(item.NaturalGasUse ?? 0) / +(item.DegreeDays ?? 1);
    item.NaturalGasUsePerSquareMeter = +(item.NaturalGasUse ?? 0) / +(item.OfficeFloorSurfaceArea ?? 1);
    item.FuelOilUsePerDegreeDay = +(item.FuelOilUse ?? 0) / +(item.DegreeDays ?? 1);
    item.FuelOilUsePerSquareMeter = +(item.FuelOilUse ?? 0) / +(item.OfficeFloorSurfaceArea ?? 1);
  }

  calcCo2(item: IEnvironmentalImpactData) {
    item.GreyElectricUseCo2 = this.calculateCO2(item.GreyElectricUse ?? 0, 0.7);
    item.GreenElectricUseCo2 = this.calculateCO2(item.GreenElectricUse ?? 0, 0.015);
    item.NaturalGasUseCo2 = this.calculateCO2(item.NaturalGasUse ?? 0, 2.2);
    item.NaturalGasUsePerDegreeDayCo2 = this.calculateCO2(item.NaturalGasUsePerDegreeDay ?? 0, 2.2);
    item.NaturalGasUsePerSquareMeterCo2 = this.calculateCO2(item.NaturalGasUsePerSquareMeter ?? 0, 2.2);
    item.FuelOilUseCo2 = this.calculateCO2(item.FuelOilUse ?? 0, 3.185);
    item.FuelOilUsePerDegreeDayCo2 = this.calculateCO2(item.FuelOilUsePerDegreeDay ?? 0, 3.185);
    item.FuelOilUsePerSquareMeterCo2 = this.calculateCO2(item.FuelOilUsePerSquareMeter ?? 0, 3.185);
    item.LargeDieselConsRemovalTrucksCo2 = this.calculateCO2(item.LargeDieselConsRemovalTrucks ?? 0, 3.135);
    item.SmallDieselConsRemovalTrucksCo2 = this.calculateCO2(item.SmallDieselConsRemovalTrucks ?? 0, 3.135);
    item.DieselConsCarsCo2 = this.calculateCO2(item.DieselConsCars ?? 0, 3.135);
    item.PetrolConsCarsCo2 = this.calculateCO2(item.PetrolConsCars ?? 0, 2.780);
    item.LpgConsCarsCo2 = this.calculateCO2(item.LpgConsCars ?? 0, 1.860);
    item.ElectricConsCarsGreyCo2 = this.calculateCO2(item.ElectricConsCarsGrey ?? 0, 0.7);
    item.ElectricConsCarsGreenCo2 = this.calculateCO2(item.ElectricConsCarsGreen ?? 0, 0.015);
    item.DieselConsCarsSubcontractCo2 = this.calculateCO2(item.DieselConsCarsSubcontract ?? 0, 3.135);
    item.AirTravelKmCo2 = this.calculateCO2(item.AirTravelKm ?? 0, 0.2);
    item.CompanyWasteCo2 = this.calculateCO2(item.CompanyWaste ?? 0, 434);
    item.CompanyPaperWasteCo2 = this.calculateCO2(item.CompanyPaperWaste ?? 0, 1000);

    item.CarbonDioxideCompensationEuro = +((29 + (129 / 2)) * (((item.CarbonDioxideCompensation ?? 0) / 1000))).toFixed(2);
    item.TotalCarbonDioxideEmission =
      (
        item.GreyElectricUseCo2 + item.GreenElectricUseCo2 + item.NaturalGasUseCo2 + item.FuelOilUseCo2 +
        item.LargeDieselConsRemovalTrucksCo2 + item.SmallDieselConsRemovalTrucksCo2 + item.DieselConsCarsCo2 +
        item.PetrolConsCarsCo2 + item.LpgConsCarsCo2 + item.ElectricConsCarsGreyCo2 + item.ElectricConsCarsGreenCo2 +
        item.DieselConsCarsSubcontractCo2 + item.AirTravelKmCo2 + item.CompanyWasteCo2 + item.CompanyPaperWasteCo2
      );

    item.TotalCarbonDioxideCompensation = item.CarbonDioxideCompensation ?? 0;
    item.NetCarbonDioxideEmission = +((item.TotalCarbonDioxideEmission ?? 0) - ((item.CarbonDioxideCompensation ?? 0) / 1000)).toFixed(2);
  }

  calculateCO2(value: number, calculation: number): number {
    if (!!value) {
      return +((value * calculation) / 1000).toFixed(2);
    }
    return 0;
  }

  openInfoModal() {
    if (!this.selectedCompany.CompanyId) {
      this.alertService.error('Please select company');
      return;
    }
    const modalRef = this.modalService.open(ImpactDataInformationModalComponent, {size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.companyId = this.selectedCompany.CompanyId;
    modalRef.componentInstance.hasAccess = this.canEdit;
  }

  upsert(item: IEnvironmentalImpactData) {
    if (!item.Year || item.Year < 2000) { // general measurement
      this.invalid = true;
      this.alertService.error('Please enter a valid year from 2000');
      return;
    }
    this.invalid = false;
    if (item.IsNew) {
      this.add(item);
    } else {
      this.update(item);
    }
  }

  update(item: IEnvironmentalImpactData) {
    item.loading = true;
    this.apiService.put(`EnvironmentalImpactData`, item)
      .subscribe({
        next: () => {
          item.loading = false;
          this.alertService.info('Successfully saved data for ' + item.Year);
        }, error: (err: IApiResponse) => {
          this.getImpactData();
          this.alertService.error(err?.Meta?.Message);
          item.loading = false;
        }
      });
  }

  add(item: IEnvironmentalImpactData) {
    item.loading = true;
    this.apiService.post(`EnvironmentalImpactData`, item)
      .subscribe({
        next: () => {
          item.loading = false;
          this.newEntry = false;
          this.alertService.info('Successfully added data for ' + item.Year);
        }, error: (err: IApiResponse) => {
          this.newEntry = false;
          this.getImpactData();
          this.alertService.error(err?.Meta?.Message);
          item.loading = false;
        }
      });
  }

  delete(itemId: number, isNew: boolean) {
    if (isNew) {
      const index = this.impactData.findIndex(x => x.IsNew);
      this.impactData.splice(index, 1);
      this.newEntry = false;
      return;
    } else {
      const message = 'This cannot be undone and will permanently delete the data. Would you like to continue?';
      OpenConfirmationModal(this.modalService, message)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(answer => {
          if (answer) {
            this.apiService.delete(`EnvironmentalImpactData/${itemId}`)
              .subscribe({
                next: () => {
                  this.getImpactData();
                }, error: () => {
                  this.getImpactData();
                }
              });
          }
        });
    }
  }

  getColorIndicator(value: number, previousValue: number) {
    if (value > previousValue) {
      return 'level-high';
    }
    if (value < previousValue) {
      return 'level-low';
    }
    return 'level-neutral';
  }
}
