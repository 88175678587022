import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ApiService } from '../../../services/api.service';
import { ICountryShortDto, IState } from '../../../services/models/pricing/location.model';

@Component({
  selector: 'app-state-search',
  templateUrl: './state-search.component.html',
  styleUrls: ['./state-search.component.scss']
})
export class StateSearchComponent implements OnInit, OnDestroy {
  // Input Variables
  @Input() Identifier: string = '';
  @Input() Disabled: boolean = false;
  @Input() ShowError: boolean = false;
  @Input()
  set SelectedID(id: number) {
    if (id && id > 0) {
      this.selectedId = id;
      this.loadStateByID(this.selectedId);
    } else {
      this.clear();
    }
  }

  get SelectedID(): number {
    return this.selectedId;
  }

  // Output Emitters
  @Output() OnSelect: EventEmitter<number> = new EventEmitter<number>();
  @Output() OnSelectObject: EventEmitter<IState> = new EventEmitter<IState>();

  // Component Variables
  public SelectedState: IState = null;
  selectedId: number;
  countries$: BehaviorSubject<ICountryShortDto[]> = new BehaviorSubject<ICountryShortDto[]>([]);

  // General variables
  errorColor: string = '#dc3545';
  searching: boolean = false;
  searchFailed: boolean = false;
  selectedCountry: string = 'All';

  // General variables
  private unsubscribe: Subject<any> = new Subject<any>();

  // Formatter for search, decides what to show in typeahead list
  formatter = (result: IState) => result.Name;

  constructor(private api: ApiService) { }

  ngOnInit() {
    this.loadCountries();
    if (this.SelectedID && this.SelectedID > 0) {
      this.loadStateByID(this.SelectedID);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  public reset() {
    this.SelectedState = null;
  }

  loadCountries() {
    this.api.get('Country/GetAll').pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data: ICountryShortDto[]) => {
        if (data) {
          this.countries$.next(data);
        }
      }
    });
  }

  searchState = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(() => {
        this.searching = true;
        this.searchFailed = false;
      }),
      switchMap(term =>
        this.getState(term).pipe(
          tap(() => {
            this.searching = false;
          }),
          catchError(() => {
            this.searching = false;
            this.searchFailed = true;
            return of([]);
          }))
      )
    );
  };

  getState(searchText: string): Observable<IState[]> {
    if (searchText && searchText.length > 2) {
      // Build URL for request
      const urlPath = `State/Search/${searchText}/${this.selectedCountry}`;
      // Call City controller "List" endpoint
      return this.api.get(urlPath).pipe(
        map((data) => data)
      );
    } else if (!searchText) {
      this.clear();
    }
    return of([]);
  }

  loadStateByID(Id: number) {
    this.searching = true;
    // Call state controller "Single" endpoint
    this.api.get('State/Single/' + Id).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe((data: IState) => {
      if (data) {
        this.SelectedState = Object.assign({}, data);
      }
      this.searching = false;
    });
  }

  onSelect(selectedState: IState) {
    if (selectedState && selectedState.Id) {
      this.OnSelect.emit(selectedState.Id);
      this.OnSelectObject.emit(selectedState);
    }
  }

  clear() {
    this.searching = false;
    this.SelectedState = null;
    this.OnSelect.emit(null);
    this.OnSelectObject.emit(null);
  }
}
