<!---- Header ---->
<div class="modal-header">
  <h4 class="modal-title">
    Risk Assessment Information
  </h4>
</div>
<!---- Add/Edit ---->
<div class="modal-body">
  <div class="row mb-1">
    <div class="col-12">
      <app-company-details [companyId]="companyId" [propertyCategory]="'RiskAssessment'"
                           [autoSave]="false" (OnChange)="updateCompanyDetails = $event" [viewOnly]="!hasAccess"></app-company-details>
    </div>
  </div>
</div>
<!---- Footer ---->
<div class="modal-footer">
  <button type="button" class="btn btn-primary harmony-tertiary-button" (click)="update()">Save</button>
  <button type="button" class="btn btn-primary harmony-primary-button" (click)="close()">Close</button>
</div>
