import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  IEmail,
  IEmailSchedule,
  IEmailScheduleDto,
  IEmailScheduleSearch
} from '../../../../services/models/email.model';
import { EmailService } from '../../../../services/email.service';
import { AlertService } from '../../../../services/alert.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditScheduleModalComponent } from './edit-schedule-modal/edit-schedule-modal.component';
import { faBoxOpen, faEdit, faPlay, faPlus, faSortUp, faStop } from '@fortawesome/free-solid-svg-icons';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { IOrderParam } from '../../../../shared/directives/sort/order.directive';
import { getDayDifference } from '../../../../shared/functions/datetime-functions';
import {IApiResponse} from '../../../../shared/models/api-reponse.model';
import {OpenConfirmationModal} from '../../../../shared/components/confirmation-modal/confirmation-modal-functions';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-email-schedule-overview',
  templateUrl: './email-schedule-overview.component.html',
  styleUrls: ['./email-schedule-overview.component.scss'],
  providers: [DatePipe]
})
export class EmailScheduleOverviewComponent implements OnInit, OnDestroy {
  // Icons
  protected readonly faAdd = faPlus;
  protected readonly faEditIcon = faEdit;
  protected readonly faEmpty = faBoxOpen;
  protected readonly faTrigger = faPlay;
  protected readonly faDisable = faStop;
  protected readonly faSortDesc = faSortUp;
  protected readonly faRunning = faSortUp;

  // Component variables
  emailSchedules: IEmailSchedule[] = [];
  searchParam: IEmailScheduleSearch;
  emailTemplates$ = this.emailService.emailTemplates$.asObservable();
  recurrenceTypes$ = this.emailService.recurrenceTypes$.asObservable();
  dataSources$ = this.emailService.dataSources$.asObservable();
  orderParam: IOrderParam;
  activeScheduleId: number = null;

  // General variables
  protected readonly getDayDifference = getDayDifference;
  private unsubscribe: Subject<any> = new Subject<any>();
  loading: boolean = false;
  environment = environment;

  constructor(public emailService: EmailService,
              private alertService: AlertService,
              private modalService: NgbModal) {
  }

  ngOnInit(): void {
    // Set default ordering
    this.orderParam = {
      OrderBy: 'Name',
      OrderDirection: 'desc'
    } as IOrderParam;
    // Init searchParam
    this.resetSearch();
    this.searchEmailSchedules();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  searchEmailSchedules() {
    this.loading = true;

    this.emailService.searchEmailSchedule(this.searchParam, this.orderParam).subscribe({
      next: (data) => {
        if (data) {
          this.emailSchedules = Object.assign([], data);
        }
        this.loading = false;
      }, error: () => {
        this.loading = false;
        this.alertService.error('Failed to search emails.');
      }
    });
  }

  orderSearch(param: IOrderParam) {
    this.orderParam = param;
    this.searchEmailSchedules();
  }

  resetSearch() {
    this.searchParam = {
      Id: null,
      Active: true,
      FromStartDate: null,
      ToEndDate: null,
      TemplateID: null,
      DataSourceID: null,
      RecurrenceTypeID: null
    } as IEmailScheduleSearch;
  }

  editSchedule(emailScheduleID: number) {
    // Open modal to Add/Edit a module
    const modalRef = this.modalService.open(EditScheduleModalComponent, {size: 'xl', windowClass: 'modal-2xl', backdrop: 'static'});
    modalRef.componentInstance.EmailScheduleID = emailScheduleID;
    modalRef.componentInstance.InitEmailSchedule();
    // On modal close, read the result and apply logic
    modalRef.result.then((result: string) => {
      if (result) {
        this.searchEmailSchedules();
      }
    }, () => {
    });
  }

  runSchedule(emailSchedule: IEmailSchedule) {
    const message = `Are you sure you want to run this email schedule? \n\n ${emailSchedule.Name}`;
    OpenConfirmationModal(this.modalService, message)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((answer: boolean) => {
        if (answer) {
          this.activeScheduleId = emailSchedule.Id;
          this.emailService.runEmailSchedule(emailSchedule.Id).subscribe({
            next: (data: IEmail[]) => {
              if (data) {
                this.alertService.success('Schedule successfully triggered: ' + emailSchedule.Name);
                this.alertService.info('Emails sent: ' + data.length);
                this.activeScheduleId = null;
              }
              this.loading = false;
            }, error: (err: IApiResponse) => {
              this.alertService.error('Failed to run email schedule. Error: ' + err?.Meta?.Message);
              this.loading = false;
              this.activeScheduleId = null;
            }
          });
        }
      });
  }

  disableSchedule(emailSchedule: IEmailSchedule) {
    emailSchedule.Active = false;
    this.updateEmailSchedule(emailSchedule);
  }

  enableSchedule(emailSchedule: IEmailSchedule) {
    emailSchedule.Active = true;
    this.updateEmailSchedule(emailSchedule);
  }

  updateEmailSchedule(emailSchedule: IEmailSchedule) {
    const updateDto = {
      Id: emailSchedule.Id,
      DateCreated: emailSchedule.DateCreated,
      Active: emailSchedule.Active,
      Name: emailSchedule.Name,
      TemplateID: emailSchedule.TemplateID,
      TemplateName: emailSchedule.Template?.DisplayName,
      DataSourceID: emailSchedule.DataSourceID,
      DataSourceName: emailSchedule.DataSource?.Name,
      StartDate: emailSchedule.StartDate,
      EndDate: emailSchedule.EndDate,
      Query: emailSchedule.Query,
      RecurrenceTypeID: emailSchedule.RecurrenceTypeID,
      RecurrenceTypeName: emailSchedule.RecurrenceType?.Name,
      RecurrenceOptions: (emailSchedule.RecurrenceOptions && emailSchedule.RecurrenceOptions.length > 0) ? emailSchedule.RecurrenceOptions.split(',').map(Number) : [],
      ScheduleTime: emailSchedule.ScheduleTime,
      Attachments: []
    } as IEmailScheduleDto;

    this.emailService.updateEmailSchedule(updateDto).subscribe({
      next: () => {
        this.alertService.success('Email Schedule successfully updated!');
        this.loading = false;
      }, error: () => {
        this.alertService.success('An error occurred while attempting to update the schedule.');
        this.loading = false;
      }
    });
  }
}
