<!------------------------------->
<!--- Searchbar with filters ---->
<!------------------------------->
<div class="row mt-1">
  <div class="col-4">
    <div class="row mt-1">
      <div class="col-4 overflow-hidden align-self-center">
        <b>Country</b>
      </div>
      <div class="col-8">
        <app-country-search [Disabled]="loading"
                            [SelectedID]="searchParam.CountryId"
                            (OnSelect)="searchParam.CountryId = $event">
        </app-country-search>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-4 overflow-hidden align-self-center">
        <b>State</b>
      </div>
      <div class="col-8">
        <app-state-search [Identifier]="'StateID'"
                          [SelectedID]="searchParam.StateId"
                          (OnSelect)="searchParam.StateId = $event">
        </app-state-search>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-4 overflow-hidden align-self-center">
        <b>City</b>
      </div>
      <div class="col-8">
        <app-city-search [Identifier]="'CityID'"
                         [SelectedID]="searchParam.CityId"
                         (OnSelect)="searchParam.CityId = $event">
        </app-city-search>
      </div>
    </div>
  </div>
  <div class="col-4">
    <div class="row mt-1">
      <div class="col-4 overflow-hidden align-self-center">
        <b>Function Type</b>
      </div>
      <div class="col-8">
        <select class="form-select form-select-sm pointer"
                [(ngModel)]="searchParam.FunctionTypeId">
          <option [ngValue]="null">All...</option>
          <option *ngFor="let functionType of (FunctionTypes$ | async)" [ngValue]="functionType.Id">{{ functionType.Description }}</option>
        </select>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-4 overflow-hidden align-self-center">
        <b>Active</b>
      </div>
      <div class="col-8">
        <select class="form-select form-select-sm pointer"
                [(ngModel)]="searchParam.Active">
          <option [ngValue]="null">All</option>
          <option [ngValue]="true" selected>Active only</option>
          <option [ngValue]="false">Disabled only</option>
        </select>
      </div>
    </div>
  </div>
  <div class="col-2 offset-2">
    <button class="btn btn-sm btn-outline-primary harmony-tertiary-outline-button w-100"
            (click)="newCityFunction()">
      + Add City Function
    </button>
  </div>
</div>
<hr class="mt-2 mb-1">
<div class="row">
  <div class="col-4">
    <input type="text"
           class="form-control form-control-sm"
           [placeholder]="'Search city functions...'"
           (keyup.enter)="searchFilter()"
           [(ngModel)]="searchParam.SearchText">
  </div>
  <div class="col-4 offset-2 text-end pe-0">
    <button class="btn btn-sm btn-primary harmony-primary-outline-button ms-2"
            [title]="'Reset search'"
            (click)="resetSearch()">
      Reset
    </button>
  </div>
  <div class="col-2 text-end ps-1">
    <button class="btn btn-sm btn-primary harmony-tertiary-button w-100"
            (click)="searchFilter()">
      Search
    </button>
  </div>
</div>
<hr class="mt-1 mb-0">
<!------------------->
<!---- Pagination --->
<!------------------->
<div class="row ms-1 me-1 mb-2">
  <div class="col-12">
    <app-pagination [showPageNumber]=true
                    [showRowsPerPage]=true
                    [paginationData]=paginationData
                    (pageEvent)="onPage($event)"
                    [name]="'CityFunction'"
                    [page]="page">
    </app-pagination>
  </div>
</div>
<!--------------------->
<!---- Result table --->
<!--------------------->
<div class="row mt-1 header-row">
  <div class="col-3 col-compress header-title border-split-left pointer"
       (appOrder)="orderSearch($event)"
       data-column="Description"
       data-order="desc">
    Description
    <fa-icon [hidden]="orderParam.OrderBy != 'Description'" [icon]="faSortAsc" style="bottom: 3px;" class="ms-2 position-relative"></fa-icon>
  </div>
  <div class="col-3 col-compress header-title pointer"
       (appOrder)="orderSearch($event)"
       data-column="City.Name"
       data-order="asc">
    City
    <fa-icon [hidden]="orderParam.OrderBy != 'City.Name'" class="ms-2 position-relative"></fa-icon>
  </div>
  <div class="col-1 col-compress header-title pointer"
       (appOrder)="orderSearch($event)"
       data-column="Code"
       data-order="asc">
    Code
    <fa-icon [hidden]="orderParam.OrderBy != 'Code'" class="ms-2 position-relative"></fa-icon>
  </div>
  <div class="col-3 col-compress header-title pointer"
       (appOrder)="orderSearch($event)"
       data-column="FunctionType.Description"
       data-order="asc">
    Function
    <fa-icon [hidden]="orderParam.OrderBy != 'FunctionType.Description'" class="ms-2 position-relative"></fa-icon>
  </div>
  <div class="col-1 col-compress header-title pointer"
       (appOrder)="orderSearch($event)"
       data-column="DateModified"
       data-order="asc">
    Modified
    <fa-icon [hidden]="orderParam.OrderBy != 'DateModified'" class="ms-2 position-relative"></fa-icon>
  </div>
  <div class="col-1 text-end col-compress header-title border-split-right">

  </div>
</div>
<!---------------------->
<!------- Results ------->
<!---------------------->
<ng-container *ngIf="!loading">
  <div class="row result-row" style="margin-bottom: -0.25rem;" *ngFor="let cityFunction of paginationData.DataSet; let i = index">
    <div class="col-3 col-compress overflow-hidden">
      {{ cityFunction.Description ?? '-' }}
    </div>
    <div class="col-3 col-compress overflow-hidden">
     {{ cityFunction.CityName ?? '-' }}
    </div>
    <div class="col-1 col-compress overflow-hidden">
      <b>{{ cityFunction.Code ?? '-' }}</b>
    </div>
    <div class="col-3 col-compress overflow-hidden">
      {{ cityFunction.FunctionDescription ?? '-' }}
    </div>
    <div class="col-1 col-compress overflow-hidden">
      <small>{{ (cityFunction.DateModified | date: env.FormattingStandards.ShortDateFormat) ?? '-' }}</small>
    </div>
    <div class="col-1 col-compress text-end pe-1">
      <button class="btn btn-sm btn-outline-light harmony-primary-outline-button"
              [title]="'Edit City: ' + cityFunction.Description"
              (click)="editCityFunction(cityFunction)">
        <fa-icon [icon]="faEditIcon" size="sm"></fa-icon>
      </button>
      <ng-container *ngIf="hasDeletePermission">
        <button class="btn btn-sm btn-outline-danger ms-1"
                *ngIf="cityFunction.Active === true"
                [title]="'Deactivate User'"
                (click)="toggleCityFunction(cityFunction)">
          <fa-icon [icon]="faRemove" size="xs"></fa-icon>
        </button>
        <button class="btn btn-sm btn-outline-success ms-1"
                *ngIf="cityFunction.Active === false"
                (click)="toggleCityFunction(cityFunction)">
          <fa-icon [icon]="faAdd" size="xs"></fa-icon>
        </button>
      </ng-container>
    </div>
  </div>
</ng-container>
<div class="row mt-4" *ngIf="paginationData && paginationData.DataSet.length === 0 && !loading">
  <div class="col-12 text-center harmony-grey-text">
    <fa-icon [icon]="faEmpty" size="2x"></fa-icon>
  </div>
  <div class="col-12 text-center harmony-grey-text mt-1">
    <h4>No city functions found, change filters and try again...</h4>
  </div>
</div>
<!---------------------->
<!------- Loader ------->
<!---------------------->
<div class="row mt-4" *ngIf="loading">
  <div class="col-12 text-center">
    <app-loader [Width]="50"></app-loader>
  </div>
</div>
<br>
