import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FileService } from '../../../services/file.service';
import { AlertService } from '../../../services/alert.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-manage-image',
  templateUrl: './manage-image.component.html',
  styleUrls: ['./manage-image.component.scss']
})
export class ManageImageComponent implements AfterViewChecked {
  // Inputs
  @Input() Container: string;
  @Input() FilePrefix: string;
  // Optional inputs for specific file naming
  @Input() NewFileName: string;
  @Input() CurrentFileName: string;
  @Input() ButtonTitle: string;

  // Output Emitters
  @Output() OnChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() OnDelete: EventEmitter<boolean> = new EventEmitter<boolean>();

  // Icons
  faEditPencil = faPencilAlt;
  faTrash = faTrash;

  // Component variables
  fileName: string;
  loading: boolean = false;

  constructor(private fileService: FileService,
              private alertService: AlertService,
              private cdRef: ChangeDetectorRef) { }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  changeImage(event) {
    const file = event.target.files[0];

    if ((file.size / 1024 / 1024) > 10) {
      this.alertService.warn('The selected file is bigger than 10MB, please select another file');
    } else {
      this.loading = true;
      this.removeCurrentImageFromStorage();
      const baseUrl = environment.SiteUrls.AzureStorageBaseURL;
      const extensionStart = file.name.lastIndexOf('.');
      const extension = file.name.substring(extensionStart + 1);
      const timeOfEntry = Date.now().toString();
      if (this.NewFileName) {
        this.fileName = (this.FilePrefix ? `${this.FilePrefix}/${this.NewFileName}-${timeOfEntry}.${extension}` : `${this.NewFileName}-${timeOfEntry}.${extension}`);
      } else {
        const newFileName = [file.name.slice(0, extensionStart), timeOfEntry, file.name.slice(extensionStart)].join('');
        this.fileName = (this.FilePrefix ? `${this.FilePrefix}/${newFileName}` : newFileName);
      }

      const result = `${baseUrl}${this.Container}/${this.fileName}`;

      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        this.fileService.Upload(file, this.Container, this.fileName)
          .subscribe({
            next: () => {
              this.alertService.success('Image successfully updated');
              this.CurrentFileName = result;
              this.OnChange.emit(result);
              this.loading = false;
            },
            error: (error) => {
              this.loading = false;
              if (error.Meta.Code === 409) {
                this.alertService.warn('Upload failed, retrying...');
                // Delete existing file and retry upload
                this.fileService
                  .DeleteFile(this.Container, this.fileName)
                  .subscribe({
                    next: () => {
                      this.retryFileUpload(file);
                    }
                  });
              } else {
                this.alertService.error('An error occurred, please try again');
              }
            }
          });
      };
    }
  }

  retryFileUpload(file: any) {
    const baseUrl = environment.SiteUrls.AzureStorageBaseURL;
    const result = `${baseUrl}${this.Container}/${this.fileName}`;

    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      this.fileService.Upload(file, this.Container, this.fileName)
        .subscribe({
          next: () => {
            this.alertService.success('Image successfully updated');
            this.OnChange.emit(result);
            this.loading = false;
          },
          error: () => {
            this.loading = false;
            this.alertService.error('An error occurred, please try again');
          }
        });
    };
  }

  removeCurrentImageFromStorage() {
    if (this.CurrentFileName) {
      const fileName = this.CurrentFileName.substring(this.CurrentFileName.lastIndexOf('/') + 1);
      const fileFullPath = this.FilePrefix ? `${this.FilePrefix}/${fileName}` : fileName;
      // Delete file
      this.fileService
        .DeleteFile(this.Container, fileFullPath)
        .subscribe({
          next: () => {
            // this.alertService.success('Existing image successfully removed.');
          }
        });
    }
  }

  deleteFile() {
    const fileName = this.CurrentFileName.substring(this.CurrentFileName.lastIndexOf('/') + 1);
    const fileFullPath = this.FilePrefix ? `${this.FilePrefix}/${fileName}` : fileName;
    // Delete file
    this.fileService
      .DeleteFile(this.Container, fileFullPath)
      .subscribe({
        next: () => {
          this.alertService.success('Image successfully deleted.');
          this.OnDelete.emit(true);
        }
      });
  }
}
