<div class="modal-header">
  <h4 class="modal-title" id="modal-title-edit">
    Create New Agent
  </h4>
  <button type="button" class="btn-close" aria-label="Close" [disabled]="loading" (click)="activeModal.dismiss(false)">

  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-7">
      <div class="row">
        <div class="col-12 fw-bold harmony-primary-text">
          Agent Details
        </div>
      </div>
      <hr class="mt-1 mb-1">
      <ng-container *ngIf="!newAgent">
        <app-loader class="mt-2" [Width]="30"></app-loader>
      </ng-container>
      <ng-container  *ngIf="newAgent">
        <div class="row my-2">
          <div class="col-4">
            <b>Type</b>
          </div>
          <div class="col-8">
            {{ agentCompanyType.Description }}
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-4">
            <b>Name</b>
          </div>
          <div class="col-8">
            <input type="text"
                   class="form-control form-control-sm"
                   [ngClass]="{'is-invalid': (!newAgent.Name || newAgent.Name.length <= 0) && invalid}"
                   [(ngModel)]="newAgent.Name">
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-4">
            <b>Legal Name</b>
          </div>
          <div class="col-8">
            <input type="text"
                   class="form-control form-control-sm"
                   [(ngModel)]="newAgent.LegalName">
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-4">
            <b>Agent ID </b> <small> (RedSky)</small>
          </div>
          <div class="col-8" style="line-height: 16px;">
            <input type="text"
                   class="form-control form-control-sm"
                   [ngClass]="{'is-invalid': (!newAgent.AgentId || newAgent.AgentId <= 0) && invalid || agentInUse != null}"
                   (change)="checkDuplicateAgentId()"
                   [(ngModel)]="newAgent.AgentId">
            <small class="mb-0 mt-1 harmony-grey-text" *ngIf="agentInUse">This ID is already assigned to <strong>{{ agentInUse.AgentName }}</strong>. To merge these agents, leave the Agent ID empty and merge the existing agent into this one after it has been created.</small>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-4">
            <b>Agent Status</b>
          </div>
          <div class="col-8">
            <input type="text"
                   class="form-control form-control-sm"
                   [(ngModel)]="newAgent.AgentStatus">
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12 fw-bold harmony-primary-text">
            Additional Information
          </div>
        </div>
        <hr class="mt-1 mb-1">
        <div class="row mt-1">
          <div class="col-4">
            <b>Allowed Domains</b>
            <fa-icon [icon]="faInfo"
                     size="xs"
                     [ngbTooltip]="'Define the domains for this agent for the registration of new users. Format: domain1.com; domain2.com; etc...'"
                     class="harmony-grey-text detail-info ms-1">
            </fa-icon>
          </div>
          <div class="col-8">
            <input type="text"
                   class="form-control form-control-sm"
                   (change)="updateDetailValue(emailDomainDefId, $event)"
                   [value]="getDetailValue('EmailDomein')">
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-4">
            <b>Rate Contact</b>
            <fa-icon [icon]="faInfo"
                     size="xs"
                     [ngbTooltip]="'Define the Rate Request contact/s (email) for this agent. Format: email1@domain.com; email2@domain.com; etc...'"
                     class="harmony-grey-text detail-info ms-1">
            </fa-icon>
          </div>
          <div class="col-8">
            <input type="text"
                   class="form-control form-control-sm"
                   (change)="updateDetailValue(rateRequestDefId, $event)"
                   [value]="getDetailValue('RateRequest')">
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-4">
            <b>Agent Code</b>
          </div>
          <div class="col-8">
            <input type="text"
                   class="form-control form-control-sm"
                   (change)="updateDetailValue(agentCodeDefId, $event)"
                   [value]="getDetailValue('AgentCode')">
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-4">
            <b>Agent Parent Code</b>
          </div>
          <div class="col-8">
            <input type="text"
                   class="form-control form-control-sm"
                   (change)="updateDetailValue(agentParentDefId, $event)"
                   [value]="getDetailValue('AgentParent')">
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-4">
            <b>FIDI</b>
            <fa-icon [icon]="faInfo"
                     [ngbTooltip]="'Is this agent a FIDI member?'"
                     size="xs"
                     class="harmony-grey-text detail-info ms-1">
            </fa-icon>
          </div>
          <div class="col-8 text-start">
            <div class="form-check form-switch form-switch-md">
              <input class="form-check-input"
                     type="checkbox"
                     role="switch"
                     [id]="'switch-fidi'"
                     [checked]="false"
                     [title]="'Is FIDI Member?'"
                     (change)="setFidiNumber($event)">
            </div>
          </div>
        </div>
      </ng-container>
      <div class="row mt-3">
        <div class="col-12 fw-bold harmony-primary-text">
          Service Information
        </div>
      </div>
      <hr class="mt-1 mb-1">
      <ng-container *ngIf="!newAgent">
        <app-loader class="mt-2" [Width]="30"></app-loader>
      </ng-container>
      <ng-container *ngIf="newAgent">
        <div class="row mt-2">
          <div class="col-4 fw-bold">
            Moving Provider
          </div>
          <div class="col-8 text-start">
            <div class="form-check form-switch form-switch-md">
              <input class="form-check-input"
                     type="checkbox"
                     role="switch"
                     [id]="newAgent.CompanyId.toString() + '_moving'"
                     [checked]="newAgent.IsMovingServiceProvider"
                     (change)="setMovingProvider($event)">
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-4 fw-bold">
            Relocation Provider
          </div>
          <div class="col-8 text-start">
            <div class="form-check form-switch form-switch-md">
              <input class="form-check-input"
                     type="checkbox"
                     role="switch"
                     [id]="newAgent.CompanyId.toString() + '_relocation'"
                     [checked]="newAgent.IsRelocationServiceProvider"
                     (change)="setRelocationProvider($event)">
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <!-- Address Details -->
    <div class="col-5">
      <div class="row">
        <div class="col-12 fw-bold harmony-primary-text">
          Address Information
        </div>
      </div>
      <hr class="mt-1 mb-1">
      <ng-container *ngIf="!newAgent">
        <app-loader class="mt-2" [Width]="30"></app-loader>
      </ng-container>
      <ng-container *ngIf="newAgent">
        <div class="row mt-2">
          <div class="col-12">
            <b>Physical</b>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-12">
            <input type="text"
                   class="form-control form-control-sm"
                   [placeholder]="'Physical Address'"
                   [(ngModel)]="newAgent.AddressPhysical">
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-8">
            <input type="text"
                   class="form-control form-control-sm"
                   [placeholder]="'City'"
                   [(ngModel)]="newAgent.CityPhysical">
          </div>
          <div class="col-4">
            <input type="text"
                   class="form-control form-control-sm"
                   [placeholder]="'ZIP Code'"
                   [(ngModel)]="newAgent.ZipCodePhysical">
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-12">
            <app-country-search
              class="d-inline"
              [Identifier]="'PhysicalCountryID'"
              [ShowError]="(!newAgent.CountryIdPhysical || newAgent.CountryIdPhysical <= 0) && invalid"
              [SelectedID]="newAgent.CountryIdPhysical"
              (OnSelectObject)="setCountryPhysical($event)">
            </app-country-search>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12">
            <b>Postal</b>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-12">
            <input type="text"
                   class="form-control form-control-sm"
                   [placeholder]="'Postal Address'"
                   [(ngModel)]="newAgent.AddressPostal">
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-8">
            <input type="text"
                   class="form-control form-control-sm"
                   [placeholder]="'City'"
                   [(ngModel)]="newAgent.CityPostal">
          </div>
          <div class="col-4">
            <input type="text"
                   class="form-control form-control-sm"
                   [placeholder]="'ZIP Code'"
                   [(ngModel)]="newAgent.ZipCodePostal">
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-12">
            <app-country-search
              class="d-inline"
              [Identifier]="'PostalCountryID'"
              [SelectedID]="newAgent.CountryIdPostal"
              (OnSelectObject)="setCountryPostal($event)">
            </app-country-search>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-4">
            <b>Longitude</b>
          </div>
          <div class="col-8">
            <input type="text"
                   numbersOnly="3.6"
                   class="form-control form-control-sm"
                   [(ngModel)]="newAgent.Longitude">
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-4">
            <b>Latitude</b>
          </div>
          <div class="col-8">
            <input type="text"
                   numbersOnly="3.6"
                   class="form-control form-control-sm"
                   [(ngModel)]="newAgent.Latitude">
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
<div class="modal-footer">
  <app-loader *ngIf="loading" class="ms-2" [Width]="20"></app-loader>
  <button type="button" class="btn btn-primary harmony-tertiary-button" [disabled]="invalid" (click)="save()">Save</button>
  <button type="button" class="btn btn-primary harmony-primary-button" (click)="close()">Close</button>
</div>
