<div class="row mt-2">
  <div class="col-12">
    <div class="row">
      <div class="col-4">
        <input type="text"
               class="form-control form-control-sm"
               [(ngModel)]="searchText"
               (keyup.enter)="search()"
               [placeholder]="'Search modules...'">
      </div>
      <div class="col-1 offset-5 text-end pe-0">
        <button class="btn btn-sm btn-outline-primary harmony-teal-outline-button"
                (click)="resetSearch()">
          Reset
        </button>
      </div>
      <div class="col-2">
        <button class="btn btn-sm btn-primary harmony-purple-button w-100"
                (click)="search()">
          Search
        </button>
      </div>
    </div>
  </div>
</div>
<hr class="my-1">
<!--------------------->
<!---- Pagination ----->
<!--------------------->
<div class="row ms-1 me-1">
  <div class="col-12" *ngIf="page">
    <app-pagination (pageEvent)="onPageChange($event)"
                    [page]="page"
                    [paginationData]=paginationData
                    [name]="'ModuleManager'"
                    [showPageNumber]=true
                    [showRowsPerPage]=true></app-pagination>
  </div>
</div>
<!--------------------->
<!------ Results ------>
<!--------------------->
<div class="row mt-1 header-row">
  <div class="col-3 header-title border-split-left pointer"
       (appOrder)="onOrderChange($event)"
       data-column="Name"
       data-order="asc">
    Name
    <fa-icon [hidden]="order?.OrderBy != 'Name'" [icon]="faSortDown" style="bottom: 3px;" class="ms-1 position-relative"></fa-icon>
  </div>
  <div class="col-7 header-title pointer"
       (appOrder)="onOrderChange($event)"
       data-column="Description"
       data-order="asc">
    Description
    <fa-icon [hidden]="order?.OrderBy != 'Description'" class="ms-1 position-relative"></fa-icon>
  </div>
  <div class="col-2 header-title border-split-right pointer"
       (appOrder)="onOrderChange($event)"
       data-column="DateCreated"
       data-order="asc">
    Date Created
    <fa-icon [hidden]="order?.OrderBy != 'DateCreated'" class="ms-1 position-relative"></fa-icon>
  </div>
</div>
<!---------------------->
<!------- Loader ------->
<!---------------------->
<ng-container *ngIf="loading === true">
  <app-loader class="mt-3"></app-loader>
</ng-container>
<!----------------------->
<!------- Results ------->
<!----------------------->
<div class="row result-row" *ngFor="let module of paginationData?.DataSet; let i = index">
  <div class="col-3 overflow-hidden">
    <b>{{ module.Name }}</b>
  </div>
  <div class="col-7 overflow-hidden">
    {{ module.Description ?? '-' }}
  </div>
  <div class="col-1 overflow-hidden">
    <small>{{module.DateCreated | date: environment.FormattingStandards.ShortDateFormat}}</small>
  </div>
  <div class="col-1 text-end pe-0">
    <button class="btn btn-sm btn-primary harmony-teal-outline-button me-1"
            [title]="'Edit Module: ' + module.Name"
            (click)="manageModule(module.Id, false)">
      <fa-icon [icon]="faEditIcon" size="sm"></fa-icon>
    </button>
    <button class="btn btn-sm btn-outline-danger"
            [title]="'Delete Module'"
            (click)="deleteModule(module.Id)">
      <fa-icon [icon]="faDelete" size="sm"></fa-icon>
    </button>
  </div>
</div>
