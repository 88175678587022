import { Component, OnInit } from '@angular/core';
import { TenderService } from '../../../services/tender.service';
import { faChevronCircleLeft, faChevronCircleRight, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { ITenderSingleLaneOverview } from '../../../services/models/pricing/tender.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../../../services/alert.service';
import { HttpResponse } from '@angular/common/http';
import { FileService } from '../../../services/file.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {
  ExportSingleLaneRatesModalComponent
} from './single-rate-overview/export-single-lane-rates-modal/export-single-lane-rates-modal.component';
import { getDayDifference } from 'src/app/shared/functions/datetime-functions';

@Component({
  selector: 'app-single-rate-wizard',
  templateUrl: './single-rate-wizard.component.html',
  styleUrls: ['./single-rate-wizard.component.scss'],
  providers: [TenderService]
})
export class SingleRateWizardComponent implements OnInit {
  // Icons
  faLeftArrow = faChevronCircleLeft;
  faRightArrow = faChevronCircleRight;
  faExcel = faFileExcel;

  // Component Variables
  activeTenderId: number = null;
  exportingRates: boolean = false;
  private unsubscribe: Subject<any> = new Subject<any>();
  getDayDifference = getDayDifference;

  constructor(public tenderService: TenderService,
              private modalService: NgbModal,
              private alertService: AlertService,
              private fileService: FileService) { }

  ngOnInit(): void { }

  createNew() {
    this.activeTenderId = 0;
    this.tenderService.NewSingleLaneTender();
  }

  cancelCreateEdit() {
    this.activeTenderId = null;
    this.tenderService.ResetTenderService();
  }

  SetSingleRateTender(srTender: ITenderSingleLaneOverview) {
    this.activeTenderId = srTender.Id;
  }

  exportRates() {
    if (this.tenderService.CheckPopupBlocker()) {
      alert( 'Your Pop-up blocker is enabled! Please disable the Pop-up blocker for this site and try again.');
    } else {
      if (this.tenderService.IsSingleRateExported()) {
        this.exportTender();
      } else {
        // Open modal to accept export terms
        const modalRef = this.modalService.open(ExportSingleLaneRatesModalComponent);
        modalRef.componentInstance.Tender = this.tenderService.TenderView;
        // On modal close, read the result and apply logic
        modalRef.result.then((result: boolean) => {
          if (result === true) {
            this.exportingRates = true;
            this.tenderService.SetAsExported(this.tenderService.SingleLaneTenderResult.Id).pipe(
              takeUntil(this.unsubscribe)
            ).subscribe({
              next: (data) => {
                this.tenderService.SingleLaneTenderParam.DateFirstExport = data;
                this.exportTender();
              },
              error: () => {
                this.exportingRates = false;
              }
            });
          } else {
            this.alertService.info('Excel export was interrupted.');
          }
        }, () => {
          this.alertService.info('Excel export was cancelled.');
        });
      }
    }
  }

  exportTender() {
    this.exportingRates = true;
    this.tenderService.ExportSingleLaneTender().subscribe({
      next: (response: HttpResponse<Blob>) => {
        const fileName = this.fileService.GetFileName(response);
        this.fileService.DownloadFile(response, fileName);
        this.exportingRates = false;
      }, error: () => {
        this.alertService.error('An error occurred while trying to export your rates. Please try again or contact support for assistance.');
        this.exportingRates = false;
      }
    });
  }
}
