import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdAuthService } from '../../../../core/ad-auth-service/ad-auth.service';

@Component({
  selector: 'app-dashboard-events-container',
  templateUrl: './events-dashboard-container.component.html',
  styleUrls: ['./events-dashboard-container.component.scss']
})
export class EventsDashboardContainerComponent implements OnInit {

  // Component variables


  // General variables


  constructor(public router: Router,
              public authService: AdAuthService) { }

  ngOnInit(): void { }
}
