import { Component, OnDestroy, OnInit } from '@angular/core';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { IEmergencyPlanRequestDto, IEmergencyPlanResponseDto } from '../../../../services/models/hse/emergency.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../../../../services/alert.service';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from '../../../../services/api.service';
import { Subject } from 'rxjs';
import { IApiResponse } from '../../../../shared/models/api-reponse.model';

@Component({
  selector: 'app-emergency-plan-document-modal',
  templateUrl: './emergency-plan-document-modal.component.html',
  styleUrls: ['./emergency-plan-document-modal.component.scss']
})
export class EmergencyPlanDocumentModalComponent implements OnInit, OnDestroy {

  public companyId: number;
  public isNew: boolean;
  public currentPlan: IEmergencyPlanResponseDto;
  public hasAccess: boolean;
  private unsubscribe: Subject<any> = new Subject<any>();
  loading: boolean;
  invalid: boolean;
  // Icons
  faDownload = faDownload;
  // Variables
  plan = {} as IEmergencyPlanRequestDto;

  constructor(private activeModal: NgbActiveModal,
              private alertService: AlertService,
              private apiService: ApiService) {
  }

  ngOnInit() {
    if (this.isNew) {
      this.plan = {
        Name: '',
        CompanyId: this.companyId,
        HasFile: false
      } as IEmergencyPlanRequestDto;
    } else {
      this.plan = {
        Name: this.currentPlan.Name,
        HasFile: false,
        FileName: this.currentPlan.Reference.substring(
          this.currentPlan.Reference.lastIndexOf('/') + 1)
      } as IEmergencyPlanRequestDto;
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  selectFile(event: any) {
    if (event.target.files.length > 1) {
      this.alertService.error('Only a single file can be upload at a time');
      return;
    }
    const file = event.target.files[0];
    this.loading = true;
    if ((file.size / 1024 / 1024) > 10) {
      event.target.value = null;
      this.alertService.warn('The selected file is bigger than 10MB, please select another file');
      this.loading = false;
      return;
    } else {
      this.loading = false;
      this.plan.File = file;
      this.plan.FileName = file.name;
      this.plan.HasFile = true;
      event.target.value = null;
    }
  }

  close(changed: boolean) {
    this.activeModal.close(changed);
  }

  update() {
    if (this.isValid()) {
      this.loading = true;
      const formData = new FormData();
      if (this.plan.HasFile) {
        formData.append('File', this.plan.File, this.plan.File.name);
      }
      formData.append('Name', this.plan.Name);
      formData.append('HasFile', `${this.plan.HasFile}`);

      this.apiService.put(`Emergency/Plan/${this.currentPlan.Id}`, formData).pipe(
        takeUntil(this.unsubscribe)
      ).subscribe({
          next: () => {
            this.loading = false;
            this.close(true);
          }, error: (err: IApiResponse) => {
            this.loading = false;
            this.alertService.error(err?.Meta?.Message);
          }
        }
      );
    }
  }

  isValid() {
    if (this.plan.Name.length > 1 &&
      (this.plan.HasFile || !this.isNew)) {
      return true;
    } else {
      this.alertService.error('Invalid, please check the document upload and name');
      this.invalid = true;
    }
    return false;
  }

  add() {
    if (this.isValid()) {
      this.loading = true;
      const formData = new FormData();
      formData.append('File', this.plan.File, this.plan.File.name);
      formData.append('Name', this.plan.Name);
      formData.append('CompanyId', this.companyId.toString());

      this.apiService.post('Emergency/Plan', formData).pipe(
        takeUntil(this.unsubscribe)
      ).subscribe({
          next: () => {
            this.loading = false;
            this.close(true);
          }, error: (err: IApiResponse) => {
            this.alertService.error(err?.Meta?.Message);
            this.loading = false;
          }
        }
      );
    }
  }
}
