<div id="qms-accordion">
  <div class="card portal-menu-collapse-card">
    <div class="portal-menu-collapse-header bg-dark" id="headingQms" data-bs-toggle="collapse" data-bs-target="#collapseQms"
         aria-expanded="true" aria-controls="collapseQms">
      <h5 class="portal-menu-heading p-2 mb-0 text-muted">
        <span class="heading-icon icon-color">
          <fa-icon [icon]="faQms"></fa-icon>
        </span>
        <span class="portal-menu-module-name"
              [ngClass]="{'harmony-teal-text': router.url.includes('qms/')}">
          HQMS
        </span>
      </h5>
    </div>
    <div id="collapseQms" class="collapse" aria-labelledby="headingQms" data-bs-parent="#qms-accordion">
      <div class="portal-menu-collapse-body">
        <nav class="col-md-12 d-none d-md-block ps-4">
          <ul class="nav flex-column mb-0">
            <li class="nav-item" *ngIf="qualityManual">
              <a class="nav-link pointer"
                 [routerLink]="['qms/manual']"
                 [ngClass]="{'active': router.url.endsWith('qms/manual')}">
                <fa-icon class="link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Quality Manual
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</div>
