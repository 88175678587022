import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  faAdd,
  faCalendar,
  faSyncAlt,
  faDownload,
  faEdit,
  faClone,
  faTrash, faEye, faFileExcel, faCircleXmark, faCircleCheck, faBoxOpen
} from '@fortawesome/free-solid-svg-icons';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { AlertService } from 'src/app/services/alert.service';
import { AnnualReviewService } from 'src/app/services/annual-review.service';
import {
  IAnnualReviewResponse,
  IAnnualReviewSearchFilterResponse
} from 'src/app/services/models/annual-review/annual-review.model';
import { IPage, IPaginationData } from 'src/app/shared/models/pagination-data.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewReviewModalComponent } from './view-review-modal/view-review-modal.component';
import {
  IChapterQuestionResponse,
  IQuestion,
  IQuestionOption
} from '../../../services/models/annual-review/annual-review-question.model';
import { ViewQuestionModalComponent } from './view-question-modal/view-question-modal.component';
import { IChapterResponse } from '../../../services/models/annual-review/annual-review-chapter.model';
import { ViewChapterModalComponent } from './view-chapter-modal/view-chapter-modal.component';
import { AdAuthService } from '../../../core/ad-auth-service/ad-auth.service';
import {
  ICompanyReviewAuditRequest,
  ICompanyReviewFilterRequest,
  ICompanyReviewFilterResponse,
  ICompanyReviewRequest
} from '../../../services/models/annual-review/annual-review-company-review.model';
import { ICompanySearchResponse } from '../../../services/models/company/company.model';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FileService } from '../../../services/file.service';
import { IAuditQuestionResponse } from '../../../services/models/annual-review/annual-review-audit-question';
import { ViewAuditQuestionModalComponent } from './view-audit-question-modal/view-audit-question-modal.component';
import { HttpResponse } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { OpenConfirmationModal } from '../../../shared/components/confirmation-modal/confirmation-modal-functions';
import {IApiResponse} from '../../../shared/models/api-reponse.model';

@Component({
  selector: 'app-admin',
  templateUrl: './annual-review-admin.component.html',
  styleUrls: ['./annual-review-admin.component.scss']
})
export class AnnualReviewAdminComponent implements OnInit, OnDestroy {
  @ViewChild('QuestionTab') QuestionTab: ElementRef;

  // Icons
  protected readonly faAdd = faAdd;
  protected readonly faDownload = faDownload;
  protected readonly faEdit = faEdit;
  protected readonly faClone = faClone;
  protected readonly faQuestion = faCircleQuestion;
  protected readonly faCalendar = faCalendar;
  protected readonly faRefresh = faSyncAlt;
  protected readonly faDelete = faTrash;
  protected readonly faView = faEye;
  protected readonly faExcel = faFileExcel;
  protected readonly faCheck = faCircleCheck;
  protected readonly faNotFeatured = faCircleXmark;
  protected readonly faBoxOpen = faBoxOpen;

  // Component Variables
  public loading: boolean = false;
  public isQuestionTab: boolean;
  public isReviewTab: boolean;
  public isMemberTab: boolean;
  public isAuditTab: boolean;

  // Review variables
  paginationData: IPaginationData<IAnnualReviewResponse> = null;
  page: IPage;
  public reviews: IAnnualReviewResponse[];
  private unsubscribe: Subject<any> = new Subject<any>();
  public userId: number;
  // Member variables
  public companyReviewFilter = {} as ICompanyReviewFilterRequest;
  public companies: ICompanySearchResponse[];
  public companyReviews: ICompanyReviewFilterResponse[];
  public memberImport = [] as number[];
  isAnnualReviewViewOpen = false;
  isAuditOpen = false;
  showContent = true;
  selectedCompanyReviewId: number;
  companySelected: number;
  // Question variables
  public chapterQuestions: IChapterQuestionResponse[];
  public question: IQuestion;
  public reviewSearchFilter: IAnnualReviewSearchFilterResponse[];
  public questionFilterReviewId: number = null;
  public chapter: IChapterResponse;
  public questionNoResults: boolean;
  // Audit variables
  public auditQuestionNoResults: boolean;
  public auditQuestions: IAuditQuestionResponse[];
  public auditFilterReviewId: number;

  constructor(private annualReviewService: AnnualReviewService,
              private alertService: AlertService,
              private modalService: NgbModal,
              private authService: AdAuthService,
              private fileService: FileService) {
    this.setToReviewTab();
  }

  ngOnInit(): void {
    this.page = {
      pageNumber: 1,
      pageSize: 50,
    } as IPage;
    this.companyReviewFilter = {
      ReviewId: null,
      CompanyType: null,
      CompanyName: null
    } as ICompanyReviewFilterRequest;
    this.getUserId();
    this.getAllAnnualReviews();
    this.getAnnualReviewDropDownItems();
  }

  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  // FUNCTIONS USED IN MORE THAN ONE TAB
  getAnnualReviewDropDownItems() {
    this.loading = true;
    this.annualReviewService.getAnnualReviewSearchFilter().pipe(
      takeUntil(this.unsubscribe))
      .subscribe(data => {
        if (data) {
          this.reviewSearchFilter = Object.assign([], data);
        }
      });
    this.loading = false;
  }

  // ANNUAL REVIEW TAB FUNCTIONS
  getAllAnnualReviews() {
    this.loading = true;
    this.annualReviewService.getAllAnnualReviews(this.page).pipe(
      takeUntil(this.unsubscribe))
      .subscribe({
        next: (data) => {
          if (data) {
            this.paginationData = Object.assign([], data);
            this.reviews = this.paginationData.DataSet;
            this.getAnnualReviewDropDownItems();
          }
          this.loading = false;
        }, error: (err: IApiResponse) => {
          this.alertService.success(err?.Meta?.Message);
          this.loading = false;
        }
      });
  }

  getUserId() {
    // Check if user can view tender in storage
    if (this.authService.CurrentUser) {
      this.userId = this.authService.CurrentUser.UserId;
    }
    this.authService.CurrentUser$.subscribe((user) => {
      this.userId = user.UserId;
    });
  }

  deleteReview(reviewId: number) {
    const message = 'Are you sure you want to delete this Annual Review?';

    OpenConfirmationModal(this.modalService, message)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((answer: boolean) => {
        if (answer) {
          this.annualReviewService.deleteAnnualReview(reviewId)
            .subscribe({
              next: () => {
                this.getAllAnnualReviews();
                this.annualReviewService.triggerAnnualReviewMenu();
              },
              error: () => {
                this.alertService.error('Could not delete the review');
              }
            });
        }
      });
  }

  // QUESTION TAB FUNCTIONS
  getQuestions(reviewId: number) {
    this.loading = true;
    this.annualReviewService.getQuestions(reviewId)
      .subscribe(data => {
        if (data) {
          this.chapterQuestions = Object.assign([], data);
          this.questionNoResults = this.chapterQuestions && this.chapterQuestions.length === 0;
        }
        this.loading = false;
      });
  }

  deleteQuestion(questionId: number, chapterId: number) {
    const message = 'Are you sure you want to delete this Question?';
    OpenConfirmationModal(this.modalService, message)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((answer: boolean) => {
        if (answer) {
          this.annualReviewService.deleteQuestion(questionId)
            .subscribe(() => {
              this.alertService.success('Question successfully removed.');
              const chapter = this.chapterQuestions.find(c => c.Id === chapterId);
              const index = chapter.Questions.findIndex(q => q.Id === questionId);
              chapter.Questions.splice(index, 1);
            });
        }
      });
  }

  deleteChapter(chapterId: number) {
    const message = 'Are you sure you want to delete this Chapter?';
    OpenConfirmationModal(this.modalService, message)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((answer: boolean) => {
        if (answer) {
          this.annualReviewService.deleteChapter(chapterId).subscribe({
            next: () => {
              const chapter = this.chapterQuestions.find(c => c.Id === chapterId);
              this.getQuestions(chapter.ReviewId);
            },
            error: () => {
              this.alertService.warn('Unable to delete chapter.');
            }
          });
        }
      });
  }

  getDocument(documentName: string) {
    this.fileService.GetFile(environment.ContainerNames.AnnualReviewTemplates, documentName);
  }

  getOrderedOptions(options: IQuestionOption[]): IQuestionOption[] {
    return options?.sort((a, b) => a.Label < b.Label ? -1 : 1);
  }

  // COMPANY REVIEW TAB FUNCTIONS
  getCompanyReviews() {
    this.loading = true;
    this.annualReviewService.filterCompanyReviews(this.companyReviewFilter).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe(data => {
      if (data) {
        this.companyReviews = Object.assign(data, {});
        this.memberImport = [];
      }
    });
    this.loading = false;
  }

  // MODALS FUNCTIONS
  openReviewModal(annualReview: IAnnualReviewResponse, isNew: boolean) {
    const modalRef = this.modalService.open(ViewReviewModalComponent, {size: 'xl', backdrop: 'static'});
    modalRef.componentInstance.isNew = isNew;
    modalRef.componentInstance.userId = this.userId;
    if (!isNew) {
      modalRef.componentInstance.review = annualReview;
    }
    // When closed
    modalRef.result.then(() => {
      this.getAllAnnualReviews();
    });
  }

  openAuditQuestionModal(auditQuestion: IAuditQuestionResponse, isNew: boolean) {
    const modalRef = this.modalService.open(ViewAuditQuestionModalComponent, {size: 'xl', backdrop: 'static'});
    modalRef.componentInstance.isNew = isNew;
    modalRef.componentInstance.reviewId = this.auditFilterReviewId;
    if (!isNew) {
      modalRef.componentInstance.audit = auditQuestion;
    }
    // When close refresh the result list
    modalRef.result.then((added: boolean) => {
      if (added) {
        this.getAuditQuestions(this.auditFilterReviewId);
      }
    }, () => {
    });
  }

  openQuestionModal(question: IQuestion, isNew: boolean, chapter: IChapterQuestionResponse = null) {
    const modalRef = this.modalService.open(ViewQuestionModalComponent, {size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.isNew = isNew;
    if (!isNew) {
      modalRef.componentInstance.question = question;
    }
    if (chapter) {
      modalRef.componentInstance.reviewId = chapter.ReviewId;
      modalRef.componentInstance.chapterId = chapter.Id;
    }
    modalRef.componentInstance.reviewSearchFilter = this.reviewSearchFilter;
    modalRef.componentInstance.init();
    // When closed
    modalRef.result.then((added: boolean) => {
      if (question != null) {
        this.annualReviewService.getQuestion(question.Id).subscribe((data: IQuestion) => {
          const filteredChapter = this.chapterQuestions.find(c => c.Id === data.ChapterId);
          const index = filteredChapter.Questions.findIndex(q => q.Id === data.Id);
          filteredChapter.Questions[index] = Object.assign({}, data);
          filteredChapter.Questions = filteredChapter.Questions.sort((a, b) => a.Order - b.Order);
        });
      } else if (added) {
        this.getQuestions(this.questionFilterReviewId);
      }
    }, () => { });
  }

  openChapterModal(chapter: IChapterResponse, isNew: boolean) {
    const modalRef = this.modalService.open(ViewChapterModalComponent, {size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.isNew = isNew;
    if (!isNew) {
      modalRef.componentInstance.chapter = chapter;
    }
    modalRef.componentInstance.reviewSearchFilter = this.reviewSearchFilter;
    // When closed
    modalRef.result.then((result: IChapterResponse) => {
      if (result) {
        chapter = result;
      }
    }, () => {
    });
  }

  openCloneReviewModal(reviewId: number) {
    const modalRef = this.modalService.open(ViewReviewModalComponent, {size: 'xl', backdrop: 'static'});
    modalRef.componentInstance.isNew = false;
    modalRef.componentInstance.isClone = true;
    modalRef.componentInstance.userId = this.userId;
    modalRef.componentInstance.reviewIdClone = reviewId;
    // When closed
    modalRef.result.then(() => {
      this.getAllAnnualReviews();
    });
  }

  // AUDIT TAB FUNCTIONS
  getAuditQuestions(reviewId: number) {
    this.loading = true;
    this.annualReviewService.getAuditQuestions(reviewId)
      .subscribe(data => {
        if (data) {
          this.auditQuestions = Object.assign([], data);
          this.auditQuestionNoResults = this.auditQuestions && this.auditQuestions.length === 0;
        }
        this.loading = false;
      });
  }

  deleteAuditQuestion(ID: number) {
    const message = 'Are you sure you want to delete this Audit Question?';
    OpenConfirmationModal(this.modalService, message)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((answer: boolean) => {
        if (answer) {
          this.annualReviewService.deleteAuditQuestion(ID).subscribe();
        }
      });
  }

  // OPEN TABS FUNCTIONS
  openQuestionTab(reviewId: number) {
    this.setToQuestionTab();
    this.QuestionTab.nativeElement.click();
    this.getQuestions(reviewId);
    this.questionFilterReviewId = reviewId;
  }

  setToQuestionTab() {
    this.setAllTabsToFalse();
    this.isQuestionTab = true;
    if (!this.chapterQuestions || this.chapterQuestions.length <= 0) {
      this.questionFilterReviewId = this.reviewSearchFilter[0].Id;
      this.getQuestions(this.questionFilterReviewId);
    }
  }

  setToReviewTab() {
    this.setAllTabsToFalse();
    this.isReviewTab = true;
  }

  setToMemberTab() {
    this.setAllTabsToFalse();
    this.isMemberTab = true;
    if (!this.companyReviews || this.companyReviews.length <= 0) {
      this.companyReviewFilter.ReviewId = this.reviewSearchFilter[0].Id;
      this.getCompanyReviews();
    }
  }

  setAllTabsToFalse() {
    this.isMemberTab = false;
    this.isReviewTab = false;
    this.isQuestionTab = false;
    this.isAuditTab = false;
  }

  setToAuditTab() {
    this.setAllTabsToFalse();
    this.isAuditTab = true;
    if (!this.auditQuestions || this.auditQuestions.length <= 0) {
      this.auditFilterReviewId = this.reviewSearchFilter[0].Id;
      this.getAuditQuestions(this.auditFilterReviewId);
    }
  }

  toggleReview(index: number, reviewId: number, toggleValue: boolean, companyId: number) {
    this.companyReviews[index].IsReviewEnabled = toggleValue;
    // when the review is disabled, automatically disable audit
    if (!toggleValue) {
      this.companyReviews[index].IsAuditEnabled = toggleValue;
    }
    this.updateCompanyReviewToggle(reviewId, companyId, toggleValue);
  }

  toggleAudit(index: number, toggleValue: boolean, companyReviewId: number) {
    this.companyReviews[index].IsAuditEnabled = toggleValue;
    this.updateAuditToggle(companyReviewId, this.companyReviews[index].IsAuditEnabled);
  }

  updateCompanyReviewToggle(reviewId: number, companyId: number, toggleValue: boolean) {
    const request = {
      ReviewId: reviewId,
      CompanyId: companyId,
      Enable: toggleValue
    } as ICompanyReviewRequest;

    this.annualReviewService.addCompanyReviews(request)
      .subscribe({
        next: () => {
          if (toggleValue === true) {
            this.alertService.success('Member review successfully activated.');
            this.annualReviewService.triggerAnnualReviewMenu();
          } else {
            this.alertService.success('Member review successfully de-activated.');
            this.annualReviewService.triggerAnnualReviewMenu();
          }
          this.getCompanyReviews();
        }, error: () => {
          this.alertService.error('Could not update the member review');
        }
      });
  }

  updateAuditToggle(companyReviewId: number, toggleValue: boolean) {
    const request = {
      CompanyReviewId: companyReviewId,
      Toggle: toggleValue
    } as ICompanyReviewAuditRequest;

    this.annualReviewService.toggleCompanyReviewAudit(request)
      .subscribe({
        next: () => {
          if (toggleValue === true) {
            this.alertService.success('Member Audit successfully activated.');
            this.annualReviewService.triggerAnnualReviewMenu();
          } else {
            this.alertService.success('Member Audit successfully de-activated.');
            this.annualReviewService.triggerAnnualReviewMenu();
          }
          this.getCompanyReviews();
        }, error: () => {
          this.alertService.error('Could not update the member review');
        }
      });
  }

  viewAnnualReview(companyId: number) {
    this.isAnnualReviewViewOpen = true;
    this.companySelected = companyId;
    this.showContent = false;
  }

  viewAudit(companyReviewId: number) {
    this.selectedCompanyReviewId = companyReviewId;
    this.isAuditOpen = true;
    this.showContent = false;
  }

  downloadAuditDocument(reviewId: number, companyReviewId: number, companyId: number) {
    this.annualReviewService.getAuditDocument(reviewId, companyReviewId, companyId)
      .subscribe({
        next: (response: HttpResponse<Blob>) => {
          const filename = this.fileService.GetFileName(response);
          this.fileService.DownloadFile(response, filename);
        }, error: () => {
          this.alertService.error('Could not download file, ensure ARA template is added');
        }
      });
  }

  downloadExcelExportData(reviewId: number) {
    this.loading = true;
    this.annualReviewService.downloadExcelExportData(reviewId)
      .subscribe({
        next: (response: HttpResponse<Blob>) => {
          const filename = this.fileService.GetFileName(response);
          this.fileService.DownloadFile(response, filename);
          this.loading = false;
        }, error: () => {
          // cant get json error response from type blob
          // message is logged in API
          this.loading = false;
          this.alertService.error('Could not download file, please check AR answers data');
        }
      });
  }
}
