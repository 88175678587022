import { Component, OnDestroy, OnInit } from '@angular/core';
import { IPage, IPaginationData } from '../../../shared/models/pagination-data.model';
import {
  IEmergencyDrillEvaluationResponseDto,
  IEmergencyPlanResponseDto
} from '../../../services/models/hse/emergency.model';
import {
  faDownload,
  faEdit,
  faEye,
  faHelmetSafety,
  faSeedling,
  faTrash,
  faUpload
} from '@fortawesome/free-solid-svg-icons';
import { IBranchDto } from '../../../services/models/hse/branch.model';
import { HseService } from '../../../services/hse.service';
import { CompanyService } from '../../../services/company.service';
import { AdAuthService } from '../../../core/ad-auth-service/ad-auth.service';
import { ApiService } from '../../../services/api.service';
import { AlertService } from '../../../services/alert.service';
import { IApiResponse } from '../../../shared/models/api-reponse.model';
import { OpenConfirmationModal } from '../../../shared/components/confirmation-modal/confirmation-modal-functions';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FileService } from '../../../services/file.service';
import { environment } from '../../../../environments/environment';
import {
  EmergencyPlanDocumentModalComponent
} from './emergency-plan-document-modal/emergency-plan-document-modal.component';
import {
  EmergencyDrillEvaluationModalComponent
} from './emergency-drill-evaluation-modal/emergency-drill-evaluation-modal.component';
import { PermissionCodes } from '../../../core/constants/permission-codes';
import { IAuthUser } from '../../../services/models/auth.model';

@Component({
  selector: 'app-emergency',
  templateUrl: './emergency.component.html',
  styleUrls: ['./emergency.component.scss']
})
export class EmergencyComponent implements OnInit, OnDestroy {
  // Icons
  protected readonly faEditIcon = faEdit;
  protected readonly faView = faEye;
  protected readonly faTrash = faTrash;
  protected readonly faDownload = faDownload;
  protected readonly faUpload = faUpload;
  protected readonly faEarth = faSeedling;
  protected readonly faHealthAndSafety = faHelmetSafety;

  // Pagination
  drillPagination: IPaginationData<IEmergencyDrillEvaluationResponseDto>;
  planPagination: IPaginationData<IEmergencyPlanResponseDto>;
  drillPage: IPage;
  planPage: IPage;

  // Permissions
  canFilterCompany: boolean = false;
  canEdit: boolean = false;
  canAdd: boolean = false;
  canDelete: boolean = false;
  // Variables
  companyId: number = null;
  branches = [] as IBranchDto[];
  branch: IBranchDto = null;
  // Tabs
  isDrillTab: boolean = true;
  isPlanTab: boolean = false;

  environment = environment;
  companies$ = this.companyService.ActiveMembers$.asObservable();
  private unsubscribe: Subject<any> = new Subject<any>();
  loading: boolean;

  constructor(private hseService: HseService,
              private companyService: CompanyService,
              private authService: AdAuthService,
              private apiService: ApiService,
              private alertService: AlertService,
              private modalService: NgbModal,
              private fileService: FileService) {
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  ngOnInit(): void {
    this.isDrillTab = true;
    this.initPage();

    this.assignPermissions();
    if (!this.canFilterCompany) {
      if (this.authService.CurrentUser) {
        // Get results by CompanyId
        this.companyId = this.authService.CurrentUser.User.CompanyId;
        this.getBranches();
        this.getDrills();
        this.getPlans();
      } else {
        this.authService.CurrentUser$
          .subscribe((user: IAuthUser) => {
            // Get results by CompanyId
            this.companyId = user.User.CompanyId;
            this.getBranches();
            this.getDrills();
            this.getPlans();
          });
      }
    } else {
      // Gets all results and does not assign companyId
      this.getDrills();
      this.getPlans();
    }
  }

  initPage() {
    this.resetDrillPagePage();
    this.planPage = {
      pageNumber: 1,
      pageSize: 30,
      batched: false
    } as IPage;
  }

  resetDrillPagePage() {
    this.drillPage = {
      pageNumber: 1,
      pageSize: 30,
      batched: false
    } as IPage;
  }

  ///////////////// Emergency Drill Start //////////////////

  assignPermissions() {
    this.canFilterCompany = this.authService.CheckPermissionByCode(PermissionCodes.HSE_Emergency_CompanyFilter);
    this.canEdit = this.authService.CheckPermissionByCode(PermissionCodes.HSE_Emergency_Edit);
    this.canAdd = this.authService.CheckPermissionByCode(PermissionCodes.HSE_Emergency_Add);
    this.canDelete = this.authService.CheckPermissionByCode(PermissionCodes.HSE_Emergency_Delete);
  }

  getDrills() {
    const route = `Emergency/DrillEvaluation?pageNumber=${this.drillPage?.pageNumber}&pageSize=${this.drillPage?.pageSize}&branchId=${this.branch?.Id ?? ''}&companyId=${this.companyId ?? ''}`;

    this.loading = true;
    this.apiService.get(route)
      .subscribe({
        next: (data) => {
          this.loading = false;
          this.drillPagination = Object.assign({}, data);
          this.drillPage.pageNumber = this.drillPagination.CurrentPage;
        }, error: (err: IApiResponse) => {
          this.loading = false;
          this.alertService.error(err?.Meta?.Message);
        }
      });
  }

  searchDrills() {
    this.resetDrillPagePage();
    this.getDrills();
  }

  getDrillPage(event: IPage) {
    this.drillPage = event;
    this.getDrills();
  }

  deleteDrill(index: number, id: number) {
    const message = 'Are you sure you want to delete this. It can not be undone?';
    OpenConfirmationModal(this.modalService, message)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((answer: any) => {
        if (answer) {
          this.apiService.delete(`Emergency/DrillEvaluation/${id}`)
            .subscribe({
              next: () => {
                // remove from display
                this.drillPagination.DataSet.splice(index, 1);
              }
            });
        }
      });
  }

  openDrillEvaluationModal(isNew: boolean, drill: IEmergencyDrillEvaluationResponseDto = null) {
    const modalRef = this.modalService.open(EmergencyDrillEvaluationModalComponent, {size: 'xl', backdrop: 'static'});
    modalRef.componentInstance.isNew = isNew;
    modalRef.componentInstance.drillEvaluation = drill;
    if (isNew) {
      // take the selected company in filtered drop down
      modalRef.componentInstance.companyId = this.companyId;
      modalRef.componentInstance.branchId = this.branch?.Id ?? null;
    } else {
      modalRef.componentInstance.companyId = drill.CompanyId;
      modalRef.componentInstance.branchId = drill.BranchId;
    }
    modalRef.componentInstance.userId = this.authService.CurrentUser.UserId;
    modalRef.componentInstance.hasCompanyFilterPermission = this.canFilterCompany;
    modalRef.componentInstance.canEdit = this.canEdit;

    modalRef.result.then((changed) => {
      if (changed) {
        this.getDrills();
      }
    });
  }

  setDrillTab() {
    this.isDrillTab = true;
    this.isPlanTab = false;
  }


  ///////////////// Emergency Drill Start //////////////////

  ///////////////// Emergency Plans Start //////////////////

  getPlans() {
    const route = `Emergency/Plan?pageNumber=${this.planPage?.pageNumber}&pageSize=${this.planPage?.pageSize}&companyId=${this.companyId ?? ''}`;

    this.loading = true;
    this.apiService.get(route)
      .subscribe({
        next: (data) => {
          this.loading = false;
          this.planPagination = Object.assign({}, data);
          this.planPage.pageNumber = this.planPagination.CurrentPage;
        }, error: (err: IApiResponse) => {
          this.loading = false;
          this.alertService.error(err?.Meta?.Message);
        }
      });
  }

  getPlanPage(event: IPage) {
    this.planPage = event;
    this.getPlans();
  }

  deletePlan(index: number, id: number) {
    const message = 'Are you sure you want to delete this. It can not be undone?';
    OpenConfirmationModal(this.modalService, message)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((answer: any) => {
        if (answer) {
          this.apiService.delete(`Emergency/plan/${id}`)
            .subscribe({
              next: () => {
                // remove from display
                this.planPagination.DataSet.splice(index, 1);
              }
            });
        }
      });
  }

  downloadPlan(index: number, reference: string) {
    this.fileService.GetFile(environment.ContainerNames.EmergencyPlans, reference);
  }

  setPlanTab() {
    this.isDrillTab = false;
    this.isPlanTab = true;
  }

  openDocumentModal(isNew: boolean, plan: IEmergencyPlanResponseDto = null) {
    const modalRef = this.modalService.open(EmergencyPlanDocumentModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });
    modalRef.componentInstance.companyId = this.authService.CurrentUser.User.CompanyId;
    modalRef.componentInstance.currentPlan = plan;
    modalRef.componentInstance.isNew = isNew;
    modalRef.componentInstance.hasAccess = this.canEdit;
    modalRef.result.then((uploaded) => {
      if (uploaded) {
        this.getPlans();
      }
    });
  }

  ///////////////// Emergency Plans End //////////////////

  ///////////////// General Start //////////////////

  updateBranches() {
    // Reset branch
    this.branch = null;
    this.branches = [];
    this.getBranches();
  }

  getBranches() {
    if (this.companyId) {
      this.hseService.getBranchesByCompanyId(this.companyId)
        .subscribe({
          next: value => {
            if (value) {
              this.branches = Object.assign([], value);
              // this.hasBranches = true;
            } else {
              this.branches = [];
              // this.hasBranches = false;
            }
          },
        });
    }
  }

  downloadTemplate() {
    this.fileService.GetFile(environment.ContainerNames.WebDocuments, environment.Blobs.EmergencyPlan);
  }

  ///////////////// General End //////////////////
}
