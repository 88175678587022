<div class="card-wrapper">
  <div class="card-content">
    <div class="card-header row">
      <div class="col-10">
        <h5 class="harmony-teal-text fw-bold mt-2">Recent documents</h5>
      </div>
      <div class="col-2 text-end">
        <!--<a class="nav-link pe-0 pointer" (click)="viewDocuments()"><fa-icon [icon]="faLink"></fa-icon></a>-->
      </div>
    </div>
    <div class="card-body">
      <app-document-list [ModuleName]="'Pricing'" [Recents]="true"></app-document-list>
    </div>
  </div>
</div>
