import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { IDocument, IDocumentListParam } from '../../../services/models/document/document.model';
import { DocumentService } from '../../../services/document.service';
import { AlertService } from '../../../services/alert.service';
import { FileService } from '../../../services/file.service';
import { faDownload, faFileCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgForOf, NgIf } from '@angular/common';
import { SharedModule } from '../../shared.module';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { DocumentTypeIconComponent } from '../document-type-icon/document-type-icon.component';
import { AdAuthService } from '../../../core/ad-auth-service/ad-auth.service';

@Component({
  selector: 'app-document-list',
  standalone: true,
  templateUrl: './document-list.component.html',
  imports: [
    FontAwesomeModule,
    NgForOf,
    SharedModule,
    NgIf,
    DocumentTypeIconComponent
  ],
  styleUrls: ['./document-list.component.scss']
})
export class DocumentListComponent implements OnInit {
  // Inputs
  @Input() ModuleName: string = null;
  @Input() CategoryCode: string = null;
  @Input()
  set CompanyId(companyId: number) {
    if (companyId && companyId > 0) {
      this.searchParam.CompanyId = companyId;
      this.loadDocuments();
    }
  }

  @Input() DocumentUserId: number = null;
  @Input() Recents: boolean = null;
  @Input() NoDocumentMessage: string = 'No documents found';

  // Icons
  protected readonly faDownloadIcon = faDownload;
  protected readonly faInfo = faCircleQuestion;
  protected readonly faNoFile = faFileCircleXmark;

  // Component variables
  searchParam = {
    CategoryCode: null,
    ModuleName: null,
    CompanyId: null,
    DocumentUserId: null,
    Recents: null
  } as IDocumentListParam;
  documents: IDocument[] = [];

  // General variables
  loading: boolean = false;
  protected readonly env = environment;

  constructor(private alertService: AlertService,
              private fileService: FileService,
              private auth: AdAuthService,
              private documentService: DocumentService) { }

  ngOnInit(): void {
    this.loadDocuments();
  }

  loadDocuments() {
    this.searchParam.ModuleName = this.ModuleName;
    this.searchParam.CategoryCode = this.CategoryCode;
    this.searchParam.DocumentUserId = this.DocumentUserId;
    this.searchParam.Recents = this.Recents;

    this.documentService.loadDocuments(this.searchParam).subscribe({
      next: (data: IDocument[]) => {
        if (data) {
          // Populate extensions
          data.forEach(x => x.Extension = this.getFileType(x.FileName).toLowerCase());
          // Check permissions
          const authorizedData = data.filter(c => this.checkRole(c) === true);
          this.documents = Object.assign([], authorizedData);
        }
        this.loading = false;
      }, error: () => {
        this.loading = false;
        this.alertService.error('Failed to search documents.');
      }
    });
  }

  checkRole(doc: IDocument) {
    let result = true;

    const category = this.documentService.documentCategories$.getValue().find(x => x.Name === doc.DocumentCategoryName);
    if (category.RoleId != null) {
      result = this.auth.CheckRoleById(category.RoleId);
    }

    return result;
  }

  downloadDocument(doc: IDocument) {
    this.fileService.GetFile('documents', doc.FileName, `${doc.DisplayName}.${doc.Extension}`);
  }

  getFileType(fileName: string) {
    return fileName.substring(fileName.lastIndexOf('.') + 1);
  }
}
