<!-- Content Area -->
<app-cookie-policy></app-cookie-policy>
<div class="content-wrapper">
  <div class="content" role="main">
    <!-- Alerts appear here -->
    <app-alert></app-alert>
    <!---------------------->
    <!--- Top Search Bar --->
    <!---------------------->
    <div class="container-fluid">
      <div class="row navigation-bar shadow">
        <div class="col-md-2 branding">
          <span class="portal-header-primary">Harmony</span>
          <span class="portal-header-secondary"> PORTAL</span>
          <fa-icon class="fa-pull-right pointer" [icon]="faBars"
                   (click)="authService.HideMenu = !authService.HideMenu"></fa-icon>
        </div>
        <div class="col-md-10 text-end navigation-bar-right" *ngIf="currentUser">
          <div class="btn-group pt-1" [title]="'Switch companies'" *ngIf="currentUser.UserCompanies.length > 1">
            <button type="button"
                    class="btn user-menu-button ps-1"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false">
              <fa-icon [icon]="faCompany" class="user-company-icon" [size]="'lg'"></fa-icon>
            </button>
            <div class="dropdown-menu dropdown-menu-right">
              <button class="dropdown-item harmony-grey-text"
                      *ngFor="let userCompany of currentUser.UserCompanies"
                      (click)="changeCompany(userCompany)"
                      [disabled]="userCompany.CompanyId == currentUser.User.CompanyId"
                      type="button">
                {{ userCompany.CompanyName }}
              </button>
            </div>
          </div>
          <div class="btn-group pt-1">
            <button type="button"
                    class="btn user-menu-button ps-1"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false">
              <fa-icon [icon]="faProfile" class="user-profile-icon" [size]="'lg'"></fa-icon>
            </button>
            <div class="dropdown-menu dropdown-menu-right">
              <button class="dropdown-item harmony-grey-text"
                      type="button"
                      [routerLink]="'user-profile'">
                My profile
              </button>
              <div class="dropdown-divider"></div>
              <button class="dropdown-item harmony-grey-text" type="button" (click)="authService.Logout()">
                <fa-icon [icon]="faSignOut" class="harmony-grey-text sign-out-btn pe-1"></fa-icon>
                Sign out
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!----------------------------->
    <!--- Collapsible Menu Bar ---->
    <!----------------------------->
    <div>
      <!---------------------->
      <!----- Side Menu ------>
      <!---------------------->
      <div class="container-fluid">
        <div class="row">
          <nav class="col-md-2 d-none d-md-block bg-dark sidebar" *ngIf="!authService.HideMenu"
               [@sidebarAnimation]="true">
            <div class="sidebar-sticky" *ngIf="currentUser">
              <div class="sidebar-header">
                <div class="user-picture-wrapper">
                  <ng-container *ngIf="hasPicture">
                    <div class="user-img"
                         (error)="hasPicture = false"
                         [ngStyle]="{'background-image': backgroundUrl }">
                    </div>
                    <img [src]="picture" alt="Primary Background" style="display: none" (error)="hasPicture = false">
                  </ng-container>
                  <ng-container *ngIf="!hasPicture">
                    <div class="user-img"
                         [ngStyle]="{'background-image': 'url(\'' + defaultImage + '\')' }">
                    </div>
                  </ng-container>
                </div>
                <div class="user-info">
              <span class="user-name">
                {{ currentUser.User.DisplayName }}
              </span>
                  <span class="user-company">
                {{ currentUser.User.Company ? currentUser.User.Company.Name : '' }}
              </span>
                </div>
              </div>
              <div class="harmony-divider-grey m-2 me-3"></div>
              <div class="card portal-menu-collapse-card">
                <div class="portal-menu-collapse-header bg-dark"
                     id="dashboard">
                  <h5 class="portal-menu-heading" [routerLink]="['']">
                    <span class="heading-icon dashboard-icon-color">
                      <fa-icon [icon]="faDashboard"></fa-icon>
                    </span>
                    <span class="portal-menu-module-name"
                          [ngClass]="{'harmony-teal-text': router.url == '/'}">
                      Dashboard
                    </span>
                  </h5>
                </div>
              </div>
              <!----------------------------->
              <!----- Menu for Network ------>
              <!----------------------------->
              <app-network-menu *ngIf="networkMenu"></app-network-menu>
              <!----------------------------->
              <!--------- Services ---------->
              <!----------------------------->
              <app-services-menu *ngIf="serviceMenu"></app-services-menu>
              <!----------------------------->
              <!------- Menu for News ------->
              <!----------------------------->
              <app-news-menu *ngIf="newsMenu"></app-news-menu>
              <!----------------------------->
              <!---- Menu for all Tools ----->
              <!----------------------------->
              <app-pricing-menu *ngIf="pricingMenu"></app-pricing-menu>
              <!----------------------------->
              <!----- Menu for Events ------->
              <!----------------------------->
              <app-events-menu *ngIf="eventsMenu"></app-events-menu>
              <!----------------------------->
              <!------- Menu for QMS -------->
              <!----------------------------->
              <app-qms-menu *ngIf="qmsMenu"></app-qms-menu>
              <!---------------------------------->
              <!------- Annual review menu ------->
              <!---------------------------------->
              <app-annual-review-menu *ngIf="annualReviewMenu"></app-annual-review-menu>
              <!----------------------------->
              <!------- Menu for HSE ------->
              <!----------------------------->
              <app-hse-menu *ngIf="hseMenu"></app-hse-menu>
              <!----------------------------->
              <!------- Menu for Hub ------->
              <!----------------------------->
              <app-hub-menu *ngIf="hubMenu"></app-hub-menu>
              <!------------------------------>
              <!---- Menu for Admin Tools ---->
              <!----- Harmony employees ------>
              <!------------------------------>
              <app-admin-menu *ngIf="adminMenu && currentUser.User.CompanyId === 1"></app-admin-menu>
            </div>
          </nav>
          <!---------------------->
          <!---- Content Area ---->
          <!---------------------->
          <main role="main" class="ms-sm-auto px-4 mt-5"
                [@contentAnimation]="!authService.HideMenu ? 'open' : 'closed'">
            <app-splash-screen></app-splash-screen>
            <router-outlet></router-outlet>
          </main>
        </div>
      </div>
    </div>
  </div>
</div>
