
<div class="card-wrapper">
  <div class="card-content">
    <div class="card-header row">
      <div class="col-12">
        <h5 class="harmony-primary-text fw-bold mt-2">Environment</h5>
      </div>
    </div>
    <div class="card-body">
      <ng-container *ngIf="environmentalAssessment">
        <div class="row">
          <div class="col-12 label">
            You have an {{ environmentalAssessment.AssessmentStatusName }} assessment
          </div>
        </div>
        <div class="row pointer">
          <div class="col-12 fw-bold">
            <a class="harmony-secondary-link label" (click)="viewAssessment(environmentalAssessment?.Id)">
              {{ environmentalAssessment?.Name }}
            </a>
          </div>
        </div>
        <div class="row">
          <div class="col-4 label">Open IRL</div>
          <div class="col-2 fw-bold harmony-primary-text subject">{{ environmentalAssessment?.OpenIRL }}</div>
          <div class="col-4 label">Open RRL</div>
          <div class="col-2 fw-bold harmony-primary-text subject">{{ environmentalAssessment?.OpenRRL }}</div>
        </div>
      </ng-container>
      <ng-container *ngIf="!environmentalAssessment">
        <div class="row label">
          <div class="col-12">
            <small>There is currently no assessment</small>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

