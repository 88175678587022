import { Component } from '@angular/core';
import { faCircleDot, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-services-integration',
  templateUrl: './services-integration.component.html',
  styleUrls: ['./services-integration.component.scss']
})
export class ServicesIntegrationComponent {
  // Icons
  faMenuItem = faCircleDot;
  faEnvelope = faEnvelope;
  faPhone = faPhone;

  // General variables
  env = environment;
}
