import { Component } from '@angular/core';

@Component({
  selector: 'app-quality-manual',
  templateUrl: './quality-manual.component.html',
  styleUrls: ['./quality-manual.component.scss']
})
export class QualityManualComponent {

  // Component variables
  module: string = 'HQMS';

  // General Variables
  loading: boolean;
  noResult: boolean = true;

  constructor() { }
}
