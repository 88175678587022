<!---- Header ---->
<div class="modal-header" xmlns="http://www.w3.org/1999/html">
  <h4 class="modal-title">
<!--    {{ CompanyChartItem.Id == 0 ? 'New company chart item' : 'Edit company chart item' }}-->
    {{ title }}
  </h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="close(null)"></button>
</div>
<!-- Body -->
<div class="modal-body">
  <div class="row">
    <div class="col-7">
      <div class="row mt-1">
        <div class="col-4 fw-bold d-flex justify-content-between">
          Change Category
          <fa-icon [icon]="faInfo" class="harmony-grey-text detail-info"
                   [title]="'Move this item to another category in this company chart'"></fa-icon>
        </div>
        <div class="col-8 fw-bold">
          <select class="form-select form-select-sm pointer w-100"
                  [disabled]="!canEdit"
                  [(ngModel)]="CompanyChartItem.CategoryId">
            <option [ngValue]="null" selected disabled>None...</option>
            <option *ngFor="let item of companyChartCategories"
                    [ngValue]="item.Id">{{ item.Description }}</option>
          </select>
        </div>
      </div>
      <hr class="my-1">
      <div class="row mt-1">
        <div class="col-4 fw-bold d-flex justify-content-between">
          Subject
          <fa-icon [icon]="faInfo" class="harmony-grey-text detail-info"
                   [title]="'Item to be inspected or tested'"></fa-icon>
        </div>
        <div class="col-8 fw-bold">
          <input class="form-control form-control-sm w-100"
                 [disabled]="!canEdit"
                 type="text"
                 [ngClass]="{ 'is-invalid': CompanyChartItem.Subject == null || CompanyChartItem.Subject?.length <= 0}"
                 [(ngModel)]="CompanyChartItem.Subject">
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-4 fw-bold d-flex justify-content-between">
          Make Type
          <fa-icon [icon]="faInfo" class="harmony-grey-text detail-info"
                   [title]="'Make of the item'"></fa-icon>
        </div>
        <div class="col-8 fw-bold">
          <input class="form-control form-control-sm w-100"
                 [disabled]="!canEdit"
                 type="text"
                 [(ngModel)]="CompanyChartItem.MakeType">
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-4 fw-bold d-flex justify-content-between">
          Year
          <fa-icon [icon]="faInfo" class="harmony-grey-text detail-info"
                   [title]="'Year the item was installed or acquired'"></fa-icon>
        </div>
        <div class="col-8 fw-bold">
          <input class="form-control form-control-sm"
                 [disabled]="!canEdit"
                 type="text"
                 numbersOnly="4.0"
                 [(ngModel)]="CompanyChartItem.Year">
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-4 fw-bold d-flex justify-content-between">
          # items
          <fa-icon [icon]="faInfo" class="harmony-grey-text detail-info"
                   [title]="'# of these items in place'"></fa-icon>
        </div>
        <div class="col-8 fw-bold">
          <input class="form-control form-control-sm"
                 [disabled]="!canEdit"
                 type="text"
                 numbersOnly="6.0"
                 [(ngModel)]="CompanyChartItem.NrOfItems">
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-4 fw-bold d-flex justify-content-between">
          Action
          <fa-icon [icon]="faInfo" class="harmony-grey-text detail-info"
                   [title]="'Inspection, maintenance or calibration'"></fa-icon>
        </div>
        <div class="col-8 fw-bold">
          <textarea class="form-control form-control-sm w-100"
                    [disabled]="!canEdit"
                    [rows]="2"
                    [(ngModel)]="CompanyChartItem.Action">
          </textarea>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-4 fw-bold d-flex justify-content-between">
          Standard
          <fa-icon [icon]="faInfo" class="harmony-grey-text detail-info"
                   [title]="'Legal or industry standard that applies'"></fa-icon>
        </div>
        <div class="col-8 fw-bold">
          <input class="form-control form-control-sm w-100"
                 [disabled]="!canEdit"
                 type="text"
                 [(ngModel)]="CompanyChartItem.Standard">
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-4 fw-bold d-flex justify-content-between">
          Frequency
          <fa-icon [icon]="faInfo" class="harmony-grey-text detail-info"
                   [title]="'Frequency at which Action must take place'"></fa-icon>
        </div>
        <div class="col-8 fw-bold">
          <input class="form-control form-control-sm w-100"
                 [disabled]="!canEdit"
                 type="text"
                 [(ngModel)]="CompanyChartItem.Frequency">
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-4 fw-bold d-flex justify-content-between">
          Manager
          <fa-icon [icon]="faInfo" class="harmony-grey-text detail-info"
                   [title]="'Manager responsible for maintenance and inspection'"></fa-icon>
        </div>
        <div class="col-8 fw-bold">
          <input class="form-control form-control-sm w-100"
                 [disabled]="!canEdit"
                 type="text"
                 [(ngModel)]="CompanyChartItem.ResponsibleManager">
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-4 fw-bold d-flex justify-content-between">
          Assigned To
          <fa-icon [icon]="faInfo" class="harmony-grey-text detail-info"
                   [title]="'Assigned users will receive direct notifications regarding this item.'"></fa-icon>
        </div>
        <div class="col-8 fw-bold">
          <select class="form-select form-select-sm pointer"
                  appAutocompleteOff
                  (change)="assignedUserChanged()"
                  [(ngModel)]="CompanyChartItem.AssignedUserId">
            <option [ngValue]="null">Select a user...</option>
            <option *ngFor="let user of (users$ | async)"
                    [ngValue]="user.Id">{{ user.DisplayName }}</option>
          </select>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-4 fw-bold d-flex justify-content-between">
          By
          <fa-icon [icon]="faInfo" class="harmony-grey-text detail-info"
                   [title]="'Person of company who carries out the Action'"></fa-icon>
        </div>
        <div class="col-8 fw-bold">
          <input class="form-control form-control-sm w-100"
                 [disabled]="!canEdit"
                 type="text"
                 [(ngModel)]="CompanyChartItem.CcBy">
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-4 fw-bold d-flex justify-content-between">
          Last
          <fa-icon [icon]="faInfo" class="harmony-grey-text detail-info"
                   [title]="'Item to be inspected or tested'"></fa-icon>
        </div>
        <div class="col-8 fw-bold">
          <app-date-picker [identifier]="'lastDatepicker'"
                           [disabled]="!canEdit"
                           [showError]="!CompanyChartItem.Last"
                           (onChange)="setLastDate($event)" [value]="CompanyChartItem.Last"
                           [placeholder]="'Latest date the Action took place'"
                           [ngClass]="{ 'is-invalid': CompanyChartItem.Last == null}">
          </app-date-picker>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-4 fw-bold d-flex justify-content-between">
          Next
          <fa-icon [icon]="faInfo" class="harmony-grey-text detail-info"
                   [title]="'Latest date at which Action must take place'"></fa-icon>
        </div>
        <div class="col-8 fw-bold">
          <app-date-picker [identifier]="'nextDatepicker'"
                           [disabled]="!canEdit"
                           [showError]="!CompanyChartItem.Next"
                           (onChange)="setNextDate($event)" [value]="CompanyChartItem.Next"
                           [placeholder]="'Select a date'"
                           [ngClass]="{ 'is-invalid': CompanyChartItem.Next == null}">
          </app-date-picker>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-4 fw-bold d-flex justify-content-between">
          Status
          <fa-icon [icon]="faInfo" class="harmony-grey-text detail-info"
                   [title]="'OK / requires attention / overdue / out of service'"></fa-icon>
        </div>
        <div class="col-8 fw-bold">
          <select class="form-select form-select-sm pointer w-100"
                  [disabled]="!canEdit"
                  [(ngModel)]="activeStatus"
                  (change)="setStatus()"
                  [ngClass]="{
                    'is-invalid': CompanyChartItem.CcStatus == null,
                    'cc-status-green': CompanyChartItem.CcStatus == 1,
                    'cc-status-orange': CompanyChartItem.CcStatus == 2,
                    'cc-status-red': CompanyChartItem.CcStatus == 3,
                    'cc-status-grey': CompanyChartItem.CcStatus == 4
                  }">
            <option [ngValue]="null" selected>None...</option>
            <option *ngFor="let item of (companyChartStatus$ | async)"
                    [ngValue]="item">{{ item.Status }}</option>
          </select>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-4 fw-bold d-flex justify-content-between">
          Remarks
          <fa-icon [icon]="faInfo" class="harmony-grey-text detail-info"
                   [title]="'Remarks'"></fa-icon>
        </div>
        <div class="col-8">
          <textarea class="form-control form-control-sm w-100"
                    [disabled]="!canEdit"
                    [rows]="4"
                    [maxLength]="255"
                    [(ngModel)]="CompanyChartItem.Remarks">
          </textarea>
          <small class="harmony-grey-text">Length: {{CompanyChartItem?.Remarks?.length ?? 0}}/255</small>
        </div>
      </div>
    </div>
    <div class="col-5 border-start">
      <!--------------------->
      <!-- Documents/Files -->
      <!--------------------->
      <div class="row mt-1">
        <div class="col-9">
          <h4>Files</h4>
        </div>
        <div class="col-3">
          <button class="btn btn-sm btn-primary harmony-purple-button w-100"
                  type="button"
                  [disabled]="IsNew"
                  *ngIf="canUpload"
                  (click)="openFileSelectionModal()">
            <fa-icon [icon]="faUpload"></fa-icon>
            Upload
          </button>
        </div>
      </div>
      <hr class="my-1">
      <small *ngIf="IsNew">Save item first to upload files</small>
      <!-- Documents & Files -->
      <ng-container *ngIf="documents.length > 0">
        <div class="row result-row my-0" style="margin-left: 0; margin-right: 0;" *ngFor="let document of documents">
          <div class="col-9 ps-0">
            <div class="d-flex">
              <input class="form-control form-control-sm"
                     type="text"
                     [(ngModel)]="document.Name">
              <button class="btn btn-sm harmony-blue-outline-button ms-1"
                      [title]="'Save file description'" (click)="updateName(document)">
                <fa-icon [icon]="faSave"></fa-icon>
              </button>
            </div>
            <div>
              <small>{{ document.Reference }}</small>
            </div>
          </div>
          <div class="col-3 text-end pe-0" *ngIf="canUpload">
            <button class="btn btn-sm btn-primary harmony-teal-outline-button me-1"
                    [title]="'Download File'" (click)="downloadDocument(document.Reference)">
              <fa-icon [icon]="faDownload"></fa-icon>
            </button>
            <button class="btn btn-sm btn-outline-danger"
                    [title]="'Delete file'" (click)="deleteDocument(document.Id)">
              <fa-icon [icon]="faDelete"></fa-icon>
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="documents.length <= 0">
        <div class="row mt-4">
          <div class="col-12 text-center harmony-grey-text">
            <fa-icon [icon]="faBoxOpen" size="2x"></fa-icon>
          </div>
          <div class="col-12 text-center harmony-grey-text mt-1">
            <h4>No files...</h4>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
<!---- Footer ---->
<div class="modal-footer" >
  <button *ngIf="canEdit" type="button" class="btn btn-primary harmony-purple-button" (click)="updateCompanyChartItem()">Save</button>
  <button type="button" class="btn btn-primary harmony-teal-button" (click)="close(CompanyChartItem)">Close</button>
</div>
