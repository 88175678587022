<!--------------------------->
<!---- Filter & Refresh ----->
<!--------------------------->
<div class="row mt-1">
  <div class="col-3">
    <input type="text"
           id="tenderName"
           class="filter-item form-control form-control-sm"
           [(ngModel)]="searchParam.TenderName"
           (keyup.enter)="searchSingleRateTenders()"
           placeholder="Filter requests...">
  </div>
  <div class="col-3 offset-6 text-end">
    <button class="btn btn-sm btn-outline-primary harmony-teal-outline-button me-1"
            (click)="resetSearch()">
      Reset
    </button>
    <button class="btn btn-sm btn-primary harmony-purple-button w-50"
            (click)="searchSingleRateTenders()">
      Search
    </button>
  </div>
</div>
<hr class="my-1">
<!------------------->
<!---- Pagination --->
<!------------------->
<div class="row ms-1 me-1">
  <div class="col-12">
    <app-pagination [showPageNumber]=true
                    [showRowsPerPage]=true
                    [paginationData]=paginationData
                    (pageEvent)="onPage($event)"
                    [name]="'SROverview'"
                    [page]="page">
    </app-pagination>
  </div>
</div>
<!--------------------->
<!---- Result table --->
<!--------------------->
<div class="flex-wrap flex-md-nowrap align-items-center pt-1 pb-2">
  <div class="row header-row align-content-start">
    <div class="col-2 header-title border-split-left">
      Name
    </div>
    <div class="col-1 header-title">
      Currency
    </div>
    <div class="col-2 header-title">
      Origin
    </div>
    <div class="col-2 header-title">
      Destination
    </div>
    <div class="col-1 header-title">
      Created
    </div>
    <div class="col-1 header-title">
      Created By
    </div>
    <div class="col-1 header-title">
      Expires
    </div>
    <div class="col-1 header-title">
      Quote
    </div>
    <div class="col-1 header-title text-end border-split-right">

    </div>
  </div>
  <ng-container *ngIf="paginationData && !loading">
    <div class="row result-row" *ngFor="let tender of paginationData.DataSet; let i = index">
      <div class="col-2 overflow-hidden text-capitalize fw-bold">
        {{ tender.Name }}
      </div>
      <div class="col-1">
        {{tender.DefaultCurrencyCode }}
      </div>
      <div class="col-2 overflow-hidden">
        {{ tender.OriginLocationName ?? '-' }}
      </div>
      <div class="col-2 overflow-hidden">
        {{ tender.DestinationLocationName ?? '-' }}
      </div>
      <div class="col-1">
        <small>{{tender.DateCreated | date: environment.FormattingStandards.ShortDateFormat }}</small>
      </div>
      <div class="col-1 overflow-hidden">
        {{ tender.CreateByUserName }}
      </div>
      <div class="col-1">
        <small [ngClass]="{
            'harmony-green-text': isDayGreaterThan(tender.ExpiryDate, 30),
            'text-warning': isDaySmallerThan(tender.ExpiryDate, 30),
            'text-danger': getDayDifference(tender.ExpiryDate) <= 0}">
          {{ tender.ExpiryDate | date: environment.FormattingStandards.ShortDateFormat }}
        </small>
      </div>
      <div class="col-1 overflow-hidden">
        <b>&euro;</b> {{ tender.QuoteAmount ? tender.QuoteAmount : '-'}}
      </div>
      <div class="col-1 text-end pe-0">
        <button class="btn btn-sm btn-outline-primary harmony-teal-outline-button"
                data-bs-toggle="tooltip"
                title="Open"
                (click)="editTender(tender)">
          <fa-icon [icon]="faView" size="xs"></fa-icon>
        </button>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="paginationData && paginationData.DataSet.length <= 0 && !loading">
    <div class="row text-center m-3">
      <div class="col-md-12 tender-item harmony-grey-text">
        No active single-lane tenders found. Create a <b (click)="createNew()" class="pointer harmony-purple-link">new one</b>?
      </div>
    </div>
  </ng-container>
  <app-loader *ngIf="loading" class="m-3"></app-loader>
</div>
