<div class="modal-header">
  <h4 class="modal-title">
    Application Log: {{ Log.Id }}
  </h4>
  <button type="button"
          class="btn-close"
          aria-label="Close"
          [disabled]="loading"
          (click)="activeModal.dismiss('Dismiss')">

  </button>
</div>
<div class="modal-body">
  <div class="row text-center">
    <div class="col-4 fw-bold">
      <h5>Level</h5>
<!--      <hr class="my-1">-->
    </div>
    <div class="col-4 fw-bold">
      <h5>Log Date</h5>
<!--      <hr class="my-1">-->
    </div>
    <div class="col-4 fw-bold">
      <h5>Source</h5>
<!--      <hr class="my-1">-->
    </div>
  </div>
  <div class="row text-center">
    <div class="col-4 fs-5 fw-bold" [ngClass]="getClass(Log.Level)">
      {{ Log.Level }}
    </div>
    <div class="col-4 fs-5 fw-bold">
      {{ Log.Date | date: env.FormattingStandards.LongDateFormat }}
    </div>
    <div class="col-4 fs-5 fw-bold">
      {{ Log.Source ?? '-' }}
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-12 text-left fw-bold">
      <h5>Logger</h5>
      <hr class="my-1">
    </div>
    <div class="col-12">
      <pre>{{ Log.Logger }}</pre>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-12 text-left fw-bold">
      <h5>Message</h5>
      <hr class="my-1">
    </div>
    <div class="col-12">
      <pre>{{ Log.Message }}</pre>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-12 text-left fw-bold">
      <h5>Exception</h5>
      <hr class="my-1">
    </div>
    <div class="col-12">
      <pre>{{ Log.Exception }}</pre>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-12 text-left fw-bold">
      <h5>Properties</h5>
      <hr class="my-1">
    </div>
    <div class="col-12">
      <pre>
        {{ Log.Properties }}
      </pre>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button"
          class="btn btn-primary harmony-primary-button"
          [disabled]="loading"
          (click)="close()">
    Close
  </button>
</div>
