import { Injectable, OnDestroy } from '@angular/core';
import { IPage } from '../shared/models/pagination-data.model';
import { ApiService } from './api.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  IArticle,
  IArticleAttachment,
  IArticleReview,
  IArticleReviewResponse,
  IArticleStatus, IArticleCategory, IArticleSearchParam, IArticleReviewRequest, IArticleStatusLog
} from './models/news/article.model';
import { IOrderParam } from '../shared/directives/sort/order.directive';

@Injectable({
  providedIn: 'root'
})
export class ArticleService implements OnDestroy {

  // Public variables
  public Statuses$: BehaviorSubject<IArticleStatus[]> = new BehaviorSubject<IArticleStatus[]>([]);
  public Categories$: BehaviorSubject<IArticleCategory[]> = new BehaviorSubject<IArticleCategory[]>([]);

  private unsubscribe: Subject<any> = new Subject<any>();

  constructor(private apiService: ApiService) {
    // Load lookups
    this.loadStatuses();
    this.loadCategories();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  GetArticles(page: IPage, order: IOrderParam, param: IArticleSearchParam) {
    return this.apiService.post(`Article/Search?pageNumber=${page.pageNumber}&pageSize=${page.pageSize}&orderBy=${order.OrderBy}&order=${order.OrderDirection}`, param)
      .pipe(takeUntil(this.unsubscribe));
  }

  get(id: number) {
    return this.apiService.get(`Article/${id}`)
      .pipe(takeUntil(this.unsubscribe));
  }

  add(article: IArticle) {
    return this.apiService.post('Article', article)
      .pipe(takeUntil(this.unsubscribe));
  }

  update(article: IArticle) {
    return this.apiService.put(`Article/${article.ArticleId}`, article)
      .pipe(takeUntil(this.unsubscribe));
  }

  delete(id: number) {
    return this.apiService.delete(`Article/${id}`)
      .pipe(takeUntil(this.unsubscribe));
  }

  getAttachments(articleId: number) {
    return this.apiService.get(`ArticleAttachment/${articleId}`)
      .pipe(takeUntil(this.unsubscribe));
  }

  addAttachment(attachment: IArticleAttachment) {
    const formData = new FormData();
    formData.append('File', attachment.File, attachment.File.name);
    formData.append('Name', attachment.Name);
    formData.append('ArticleId', attachment.ArticleId.toString());
    return this.apiService.post('ArticleAttachment', formData)
      .pipe(takeUntil(this.unsubscribe));
  }

  deleteAttachment(id: number) {
    return this.apiService.delete(`ArticleAttachment/${id}`)
      .pipe(takeUntil(this.unsubscribe));
  }

  getReview(articleId: number): Observable<IArticleReviewResponse> {
    return this.apiService.get(`Article/Review/${articleId}`)
      .pipe(takeUntil(this.unsubscribe));
  }

  addReview(review: IArticleReview): Observable<IArticleReview> {
    return this.apiService.post('Article/Review', review)
      .pipe(takeUntil(this.unsubscribe));
  }

  updateReview(review: IArticleReview): Observable<IArticleReview> {
    return this.apiService.put(`Article/Review`, review)
      .pipe(takeUntil(this.unsubscribe));
  }

  submitForApproval(article: IArticle) {
    return this.apiService.put(`Article/Review/AwaitApproval/${article.ArticleId}`, article)
      .pipe(takeUntil(this.unsubscribe));
  }

  approve(id: number) {
    return this.apiService.put(`Article/Review/Approve/${id}`)
      .pipe(takeUntil(this.unsubscribe));
  }

  approveChanges(id: number) {
    return this.apiService.put(`Article/Review/ApproveChanges/${id}`)
      .pipe(takeUntil(this.unsubscribe));
  }

  reject(param: IArticleReviewRequest) {
    return this.apiService.post(`Article/Review/Reject`, param)
      .pipe(takeUntil(this.unsubscribe));
  }

  requestChanges(param: IArticleReviewRequest) {
    return this.apiService.post(`Article/Review/RequestChanges`, param)
      .pipe(takeUntil(this.unsubscribe));
  }

  requestReApproval(param: IArticleReviewRequest) {
    return this.apiService.post(`Article/Review/ReApproval`, param)
      .pipe(takeUntil(this.unsubscribe));
  }

  getArticleStatusLogs(articleId: number): Observable<IArticleStatusLog> {
    return this.apiService.get(`Article/Status/Logs/${articleId}`)
      .pipe(takeUntil(this.unsubscribe));
  }

  // Lookup endpoints
  loadStatuses() {
    this.apiService.get('Article/Status/All')
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data: IArticleStatus[]) => {
        this.Statuses$.next(Object.assign([], data));
      });
  }

  loadCategories() {
    this.apiService.get('Article/Categories')
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data: IArticleStatus[]) => {
        this.Categories$.next(Object.assign([], data));
      });
  }
}
