import { Component } from '@angular/core';
import { CompanyService } from '../../../../services/company.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AdAuthService } from '../../../../core/ad-auth-service/ad-auth.service';
import { HseService } from '../../../../services/hse.service';
import { IBranchDto } from '../../../../services/models/hse/branch.model';
import { AlertService } from '../../../../services/alert.service';
import { ICompanyChartDto } from '../../../../services/models/hse/hse.model';
import { PermissionCodes } from '../../../../core/constants/permission-codes';

@Component({
  selector: 'app-company-chart',
  templateUrl: './company-chart-create-modal.component.html',
  styleUrls: ['./company-chart-create-modal.component.scss']
})
export class CompanyChartCreateModalComponent {
  // Component Variables
  companyChart: ICompanyChartDto;
  useDefaultCategories: boolean = true;
  branches: IBranchDto[] = [];
  companies$ = this.companyService.ActiveMembers$.asObservable();
  contactValid: boolean = true;
  invalid: boolean = false;

  // Permissions
  canViewAllCompanies: boolean;
  companyName: string;

  // General variables
  loading: boolean = false;

  constructor(private companyService: CompanyService,
              private hseService: HseService,
              private alertService: AlertService,
              private activeModal: NgbActiveModal,
              private authService: AdAuthService) {
    this.setPermissions();
  }

  initModal() {
    this.companyChart = {
      Name: null,
      ContactEmail: null,
      BranchName: '',
      City: '',
      CompanyName: '',
      Id: 0,
      CompanyId: 0,
      BranchId: null,
      Active: true,
      DateCreated: new Date(),
      CreatedByUserId: this.authService.CurrentUser.UserId,
      DateModified: null,
      ModifiedByUserId: null,
      CompanyChartItemCount: 0,
      CompanyChartAttentionCount: 0
    } as ICompanyChartDto;
  }

  setPermissions() {
    this.canViewAllCompanies = this.authService.CheckPermissionByCode(PermissionCodes.HSE_HS_CompanyChart_CompanyFilter);
    this.initModal();

    if (this.canViewAllCompanies === false) {
      this.companyChart.CompanyId = this.authService.CurrentUser.User.CompanyId;
      this.companyName = this.authService.CurrentUser.User.Company.Name;
      this.getBranches();
    }
  }

  getBranches() {
    this.hseService.getBranchesByCompanyId(this.companyChart.CompanyId)
      .subscribe({
        next: value => {
          if (value) {
            this.branches = Object.assign([], value);
          }
        }
      });
  }

  setUseDefaultCategories(event: any) {
    this.useDefaultCategories = event.target.checked === true;
  }

  canCreate() {
    return this.companyChart.Name?.length > 0 &&
      (this.contactValid === true && this.companyChart.ContactEmail?.length > 0) &&
      (this.branches.length > 0 && this.companyChart.BranchId > 0) &&
      this.companyChart.CompanyId > 0;
  }

  createCompanyChart() {
    if (this.canCreate()) {
      this.invalid = false;
      this.hseService.addCompanyChart(this.companyChart, this.useDefaultCategories)
        .subscribe({
          next: () => {
            this.alertService.info('Company chart successfully created.');
            this.close(true);
          },
          error: (error) => {
            this.alertService.error(error.Meta.Message);
          }
        });
    } else {
      this.invalid = true;
      this.alertService.info('Please fill in all required fields.');
    }
  }

  close(shouldReload: boolean) {
    this.activeModal.close(shouldReload);
  }
}
