import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdAuthService } from '../../../../core/ad-auth-service/ad-auth.service';
import { faCircleDot } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-dashboard-services-card',
  templateUrl: './dashboard-card-services-overview.component.html',
  styleUrls: ['./dashboard-card-services-overview.component.scss'],
  host: {'[class.one-by-one]': '(column <= 1 || column > 4) && (row <= 1 || row > 6)',
    '[class.two-by-one]': 'column == 2 && row == 1',
    '[class.three-by-one]': 'column == 3 && row == 1',
    '[class.two-by-two]': 'column == 2 && row == 2',
    '[class.one-by-two]': 'column == 1 && row == 2',
    '[class.one-by-three]': 'column == 1 && row == 3',
    '[class.three-by-three]': 'column == 3 && row == 3',
    '[class.three-by-four]': 'column == 3 && row == 4',
    '[class.three-by-five]': 'column == 3 && row == 5',
    '[class.three-by-six]': 'column == 3 && row == 6',
    '[class.one-by-four]': 'column == 1 && row == 4',
    '[class.one-by-five]': 'column == 1 && row == 5',
    '[class.one-by-six]': 'column == 1 && row == 6'}
})
export class DashboardCardServicesOverviewComponent implements OnInit {
  @Input() column = 1;
  @Input() row = 1;

  // Icons
  protected readonly faMenuItem = faCircleDot;

  // Component variables

  // General variables

  constructor(public router: Router,
              public authService: AdAuthService) { }

  ngOnInit() { }
}
