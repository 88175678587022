import { Component, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../../../../services/alert.service';
import { Observable, of, Subject } from 'rxjs';
import {
  IJobInspectionCategory,
  IJobInspectionQuestion
} from '../../../../services/models/hse/job-inspection.model';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from '../../../../services/api.service';
import { CompanyService } from '../../../../services/company.service';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import {IApiResponse} from '../../../../shared/models/api-reponse.model';

@Component({
  selector: 'app-job-inspection-configure',
  templateUrl: './job-inspection-configure-modal.component.html',
  styleUrls: ['./job-inspection-configure-modal.component.scss']
})
export class JobInspectionConfigureModalComponent implements OnDestroy {
  // Icons
  protected readonly faAdd = faPlus;
  protected readonly faDelete = faTrash;

  // Component Variables
  public CompanyId: number = null;
  jobInspectionCategories$: Observable<IJobInspectionCategory[]>;
  companies$ = this.companyService.ActiveMembers$.asObservable();
  invalid: boolean = false;
  additionalQuestions: IJobInspectionQuestion[] = [];

  // Permissions
  companyName: string;

  // General variables
  loading: boolean = false;
  private unsubscribe: Subject<any> = new Subject<any>();

  constructor(private alertService: AlertService,
              private companyService: CompanyService,
              private activeModal: NgbActiveModal,
              private api: ApiService) { }

  public InitModal() {
    this.loadCategories(this.CompanyId);
    this.companyName = this.companyService.ActiveMembers$.getValue().find(x => x.CompanyId === this.CompanyId)?.CompanyName;
  }

  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  loadCategories(companyId: number) {
    this.loading = true;

    this.api.get(`JobInspection/Categories/0/${companyId}`).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: data => {
        if (data) {
          this.jobInspectionCategories$ = of(data);
        }
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  getAdditionalQuestions(categoryId: number) {
    return this.additionalQuestions.filter(x => x.QuestionCategoryId === categoryId);
  }

  addAdditionalQuestion(categoryId: number) {
    const newQuestion = {
      Id: null,
      QuestionCategoryId: categoryId,
      JobInspectionId: null,
      CompanyId: this.CompanyId,
      QuestionText: null,
      Order: 100
    } as IJobInspectionQuestion;

    this.additionalQuestions.push(newQuestion);
  }

  deleteAdditionalQuestion(question: IJobInspectionQuestion) {
    const index = this.additionalQuestions.indexOf(question);

    if (index >= 0) {
      this.additionalQuestions.splice(index, 1);
    }
  }

  canSave() {
    const additionalValid =
      this.additionalQuestions.find(x => x.QuestionText?.length <= 0);

    return additionalValid == null;
  }

  save() {
    if (this.canSave()) {
      this.invalid = false;
      this.saveAdditionalQuestions();
    } else {
      this.invalid = true;
    }
  }

  saveAdditionalQuestions() {
    this.api.post('JobInspection/Additional', this.additionalQuestions).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data) => {
        this.loadCategories(this.CompanyId);
        this.additionalQuestions = [];
      },
      error: (err: IApiResponse) => {
        this.alertService.error(err?.Meta?.Message);
      }
    });
  }

  close(shouldReload: boolean) {
    this.activeModal.close(shouldReload);
  }
}
