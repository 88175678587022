<div class="row">
    <div class="col-md-12">
      <div class="input-group input-group-sm">
        <input id="{{Identifier}}"
               type="text"
               class="form-control form-control-sm"
               appAutocompleteOff
               #searchBox
               [disabled]="Disabled"
               [(ngModel)]="selectedTender"
               [ngStyle]="{'border' : ShowError ? '1px solid ' + errorColor : '1px solid #ccc' }"
               [ngbTypeahead]="searchTender"
               [inputFormatter]="formatter"
               [resultFormatter]="formatter"
               (ngModelChange)="onSelect()"
               [placeholder]="Placeholder"
               [editable]='false' />
          <span class="input-group-text" *ngIf="searching" >
            <img src="../../../../assets/loaders/tail-spin.svg" width="20" alt="" />
          </span>
          <button
            class="btn btn-outline-primary harmony-tertiary-outline-button"
            type="button"
            [title]="'Clear'"
            [disabled]="Disabled"
            (click)="clear()">X</button>
      </div>
    </div>
  </div>
