import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ICompanyChartItem, IDocumentRequestDto } from '../../../../services/models/hse/hse.model';
import { HseService } from '../../../../services/hse.service';
import { AlertService } from '../../../../services/alert.service';
import { faDownload, faEdit, faTrash, faUpload } from '@fortawesome/free-solid-svg-icons';
import { AdAuthService } from '../../../../core/ad-auth-service/ad-auth.service';
import { IApiResponse } from '../../../../shared/models/api-reponse.model';

@Component({
  selector: 'app-company-chart-item-document-management-modal',
  templateUrl: './company-chart-item-document-modal.component.html',
  styleUrls: ['./company-chart-item-document-modal.component.scss']
})
export class CompanyChartItemDocumentModalComponent{
  // Icons
  faDownload = faDownload;
  faEdit = faEdit;
  faDelete = faTrash;
  faUpload = faUpload;

  // Component Variables
  public companyId: number;
  public companyChartItem: ICompanyChartItem;
  document = {} as IDocumentRequestDto;

  // General variables
  loading: boolean;

  constructor(private hseService: HseService,
              private alertService: AlertService,
              public authService: AdAuthService,
              private activeModal: NgbActiveModal) { }

  isValid() {
    return this.document?.Name?.length > 1 && (this.document?.HasFile);
  }

  selectFile(event: any) {
    if (event.target.files.length > 1) {
      this.alertService.error('Only a single file can be upload at a time');
      return;
    }
    const file = event.target.files[0];
    this.loading = true;

    if ((file.size / 1024 / 1024) > 10) {
      this.alertService.warn('The selected file is bigger than 10MB, please select another file');
      this.loading = false;
      return;
    } else {
      this.loading = false;
      file.FileName = this.companyChartItem.Id + '_' + file.name;
      this.document.CompanyId = this.companyId;
      this.document.CompanyChartItemId = this.companyChartItem.Id;
      this.document.HasFile = true;
      this.document.File = file;
      this.document.FileName = file.name;
      this.document.DateModified = new Date();
      this.document.CreatedByUserId = this.authService.CurrentUser.UserId;
    }
  }

  addDocument() {
    if (this.isValid()) {
      this.loading = true;
      const formData = new FormData();
      formData.append('File', this.document.File, this.document.File.name);
      formData.append('Name', this.document.Name);
      formData.append('CompanyChartItemId', this.document.CompanyChartItemId.toString());
      formData.append('CompanyId', this.companyId.toString());
      formData.append('HasFile', this.document.HasFile.toString());
      formData.append('UserId', this.document.CreatedByUserId.toString());

      this.hseService.addCompanyChartItemDocument(formData).subscribe({
          next: () => {
            this.loading = false;
            this.alertService.info('File successfully uploaded');
            this.activeModal.close(true);
          },
          error: (err: IApiResponse) => {
            this.alertService.error(err.Meta.Message);
            this.loading = false;
          }
        }
      );
    } else {
      this.alertService.warn('Invalid file or description');
    }
  }

  close() {
    this.activeModal.close(false);
  }
}
