<div class="modal-header" xmlns="http://www.w3.org/1999/html">
  <h4 class="modal-title">
    {{ state && StateId ? 'Edit State: ' + state.Name : 'Create New State' }}
  </h4>
</div>
<div class="modal-body">
  <ng-container *ngIf="state && !loading">
    <div class="row">
      <div class="col-4">
        <b>Name</b>
      </div>
      <div class="col-8">
        <input type="text"
               class="form-control form-control-sm"
               [ngClass]="{'is-invalid': !state.Name || state.Name.length <= 0}"
               [(ngModel)]="state.Name">
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-4">
        <b>Code</b>
      </div>
      <div class="col-8">
        <input type="text"
               class="form-control form-control-sm"
               [ngClass]="{'is-invalid': !state.Code || state.Code.length <= 0}"
               [(ngModel)]="state.Code">
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-4">
        <b>Country</b>
      </div>
      <div class="col-8">
        <app-country-search [Disabled]="loading"
                            [SelectedID]="state.CountryId"
                            [ShowError]="state.CountryId == null"
                            (OnSelect)="state.CountryId = $event">
        </app-country-search>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!state && loading">
    <app-loader *ngIf="loading" class="ms-2" [Width]="40"></app-loader>
  </ng-container>
</div>
<div class="modal-footer">
  <app-loader *ngIf="loading" class="ms-2" [Width]="20"></app-loader>
  <button type="button" class="btn btn-primary harmony-tertiary-button" (click)="save()">Save</button>
  <button type="button" class="btn btn-primary harmony-primary-button" (click)="cancel()">Close</button>
</div>
