  <input type="checkbox"
         class="form-control form-control-sm"
         [checked]="checked"
         id="{{id}}"
         (change)="sendOutput()"
         [placeholder]="Placeholder"
         [disabled]="isDisabled">
  <label class="toggle-button-switch" for="{{id}}"></label>
  <div class="toggle-button-text">
    <div class="toggle-button-text-on">on</div>
    <div class="toggle-button-text-off">off</div>
  </div>
