<div class="modal-header">
  <h4 class="modal-title" id="modal-title-edit" *ngIf="AuthUser.UserId != null">
    Manage user
  </h4>
  <h4 class="modal-title" id="modal-title-create" *ngIf="AuthUser.UserId == null">
    Create new user
  </h4>
  <button type="button"
          class="btn-close"
          aria-label="Close"
          [disabled]="loading"
          (click)="activeModal.dismiss(false)">
  </button>
</div>
<div class="modal-body" *ngIf="AuthUser">
  <div class="row">
    <div class="col-8">
      <div class="row">
        <div class="col-12 harmony-teal-text">
          <h6 class="mb-0 fw-bold">User Details</h6>
        </div>
      </div>
      <hr class="mt-1 mb-1">
      <div class="row mt-2">
        <div class="col-4">
          <b>Company</b>
        </div>
        <div class="col-8 ps-3 my-1">
          {{ this.AuthUser?.User?.Company?.Name }}
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-4">
          <b>Username</b>
          <app-loader *ngIf="checkingUser" class="float-end" [Width]="20"></app-loader>
          <fa-icon [icon]="faValid" size="1x" *ngIf="usernameValid" class="mt-1 float-end harmony-green-text"></fa-icon>
        </div>
        <div class="col-8">
          <ng-container *ngIf="AuthUser.UserId != null && AuthUser.UserId > 0">
            <div class="row">
              <div class="col-12 ps-3 py-1">
                {{ AuthUser?.User?.Username }}
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="AuthUser.UserId == null || AuthUser.UserId == 0">
            <input type="text"
                   class="form-control form-control-sm"
                   [ngModel]="AuthUser.User.Username"
                   [disabled]="checkingUser"
                   [email]="true"
                   [required]="true"
                   [autofocus]="true"
                   #username="ngModel"
                   (change)="onUsernameChanged(username, $event);"
                   [ngClass]="{'is-invalid': !usernameValid && invalid}">
            <small *ngIf="username.errors?.email">
              Primary email address is not valid.
            </small>
            <small *ngIf="!usernameValid">
              {{ usernameValidMessage }}
            </small>
          </ng-container>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-4">
          <b>First Name</b>
        </div>
        <div class="col-8">
          <input type="text"
                 [value]="AuthUser.User.FirstName"
                 (change)="AuthUser.User.FirstName = $event.target.value"
                 [ngClass]="{'is-invalid': (!AuthUser.User.FirstName || AuthUser.User.FirstName.length <= 0) && invalid}"
                 [required]="true"
                 class="form-control form-control-sm">
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-4">
          <b>Last Name</b>
        </div>
        <div class="col-8">
          <input type="text"
                 [value]="AuthUser.User.LastName"
                 (change)="AuthUser.User.LastName = $event.target.value"
                 [ngClass]="{'is-invalid': (!AuthUser.User.LastName || AuthUser.User.LastName.length <= 0) && invalid}"
                 [required]="true"
                 class="form-control form-control-sm">
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-4">
          <b>Display Name</b>
        </div>
        <div class="col-8">
          <input type="text"
                 [ngClass]="{'is-invalid': AuthUser.User.DisplayName.length < 1 && invalid}"
                 [value]="AuthUser.User.DisplayName"
                 (change)="AuthUser.User.DisplayName = $event.target.value"
                 class="form-control form-control-sm">
        </div>
      </div>
    </div>
    <div class="col-4 border-start">
      <div class="row">
        <div class="col-12 harmony-teal-text">
          <h6 class="mb-0 fw-bold">Roles</h6>
        </div>
      </div>
      <hr class="mt-1 mb-1">
      <div class="row mt-1 mb-2">
        <div class="col-12">
          <select class="form-select form-select-sm pointer"
                  [(ngModel)]="roleToAdd"
                  [disabled]="loading || (allowedRoles$ | async)?.length <= 0 || AuthUser.UserCompanies.length <= 0"
                  (ngModelChange)="addRoleToUser()">
            <option [ngValue]="null">Select role to add...</option>
            <ng-container *ngFor="let role of (allowedRoles$ | async)" >
              <option *ngIf="!userHasRole(role)"
                      [ngValue]="role">
                {{ role.Name }}
              </option>
            </ng-container>
          </select>
        </div>
      </div>
      <div class="row result-row" *ngFor="let userRole of userRoles; let i = index">
        <div class="col-10"
             [ngClass]="{'strikethrough': userRole.Active == false}">
          {{ userRole?.RoleName}}
        </div>
        <div class="col-2 text-end">
          <fa-icon [icon]="faRemove"
                   class="text-danger pointer"
                   size="1x"
                   *ngIf="userRole.Active && canDeleteRole(userRole)"
                   (click)="updateCurrentUserRole(userRole, false)"></fa-icon>
          <fa-icon [icon]="faAdd"
                   class="harmony-green-text pointer"
                   size="1x"
                   *ngIf="!userRole.Active && canDeleteRole(userRole)"
                   (click)="updateCurrentUserRole(userRole, false)"></fa-icon>
        </div>
      </div>
      <div class="row result-row" *ngFor="let userRole of newUserRoles; let i = index">
        <div class="col-10">
          {{ userRole?.RoleName}}
        </div>
        <div class="col-2 text-end">
          <ng-container>
            <fa-icon [icon]="faRemove" class="text-danger pointer"
                     size="1x" (click)="removeNewUserRole(i)">
            </fa-icon>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <app-loader *ngIf="loading" class="ms-2" [Width]="20"></app-loader>
  <button type="button" class="btn btn-primary harmony-purple-button" (click)="save()">Save</button>
  <button type="button" class="btn btn-primary harmony-teal-button" (click)="close()">Close</button>
</div>

