import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AdAuthService } from '../../../../core/ad-auth-service/ad-auth.service';

@Component({
  selector: 'app-dashboard-services-container',
  templateUrl: './dashboard-container-services.component.html',
  styleUrls: ['./dashboard-container-services.component.scss']
})
export class DashboardContainerServicesComponent {

  // Component variables

  // General variables

  constructor(public router: Router,
              public authService: AdAuthService) { }
}
