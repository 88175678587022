<div class="article-card pointer" (click)="viewArticle()" *ngIf="Article">
  <div class="article-headline"
       [ngStyle]="{'background-image': 'url(\'' + imageUrl + '\')' }">
    <p class="article-headline-text" [ngClass]="Class">
      {{ Article.Title }}
    </p>
  </div>
  <div class="article-body">
    <div class="row">
      <div class="col-12 text-center">
        <p class="article-publish-date">
          {{ Article.PublishDate | date: env.FormattingStandards.ShortDateFormat }} -
          <b class="harmony-accent-coral-text fw-light">{{ Article.Author ?? '' }}</b>
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <p class="article-content mt-2 mb-2" [innerHTML]="Article.Summary">
          <a class="read-more-link" (click)="viewArticle()">Read article</a>
        </p>
      </div>
    </div>
  </div>
</div>
