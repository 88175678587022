import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdAuthService } from '../../../../core/ad-auth-service/ad-auth.service';
import { ICheckRateDashboard, ICheckRateReportParam, IDashboardLookup } from '../models/pricing-dashboard.model';
import { Subject } from 'rxjs';
import { ApiService} from '../../../../services/api.service';
import { takeUntil} from 'rxjs/operators';
import { PricingService } from '../../../../services/pricing.service';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {RateGroupEditModalComponent} from '../../rategroup-manager/rategroup-edit-modal/rate-group-edit-modal.component';
import {RateGroupCaptureModalComponent} from '../../rate-capture/rategroup-capture-modal/rate-group-capture-modal.component';
import {IRateGroup} from '../../../../services/models/pricing/rates.model';
import {faArrowRight, faScaleBalanced} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-dashboard-card-check-rates',
  templateUrl: './dashboard-card-check-rates.component.html',
  styleUrls: ['./dashboard-card-check-rates.component.scss'],
  host: {'[class.one-by-one]': '(column <= 1 || column > 3) && (row <= 1 || row > 4)',
    '[class.two-by-one]': 'column == 2 && row == 1',
    '[class.three-by-one]': 'column == 3 && row == 1',
    '[class.two-by-two]': 'column == 2 && row == 2',
    '[class.one-by-two]': 'column == 1 && row == 2',
    '[class.one-by-three]': 'column == 1 && row == 3',
    '[class.one-by-four]': 'column == 1 && row == 4',
    '[class.one-by-five]': 'column == 1 && row == 5',
    '[class.one-by-six]': 'column == 1 && row == 6'}
})
export class DashboardCardCheckRatesComponent implements OnInit {
  @Input() column = 1;
  @Input() row = 1;

  // Icons
  protected readonly faInfo = faCircleQuestion;
  protected readonly faDirection = faArrowRight;
  protected readonly faNoCheckRate = faScaleBalanced;

  // Component variables
  report: ICheckRateDashboard[] = [];
  projects$ = this.pricingService.Projects$.asObservable();
  companyProjects$: Subject<IDashboardLookup[]> = new Subject<IDashboardLookup[]>();
  companyId: number = null;
  projectId: number = null;

  // Permissions
  pricingAdmin: boolean = false;

  // General variables
  private unsubscribe: Subject<any> = new Subject<any>();
  loading: boolean = false;

  constructor(public router: Router,
              public authService: AdAuthService,
              private modalService: NgbModal,
              private pricingService: PricingService,
              private api: ApiService) { }

  ngOnInit() {
    if (this.authService.CurrentUser) {
      this.setPermissions();
    }

    this.authService.CurrentUser$.subscribe(() => {
      this.setPermissions();
    });
  }

  setPermissions() {
    this.pricingAdmin = this.authService.CheckRole('Pricing: Admin User');

    if (!this.pricingAdmin) {
      this.companyId = this.authService.CurrentUser.User.CompanyId;
    }

    this.getProjects();
  }

  getProjects() {
    const param = this.companyId ?? 0;

    this.api.get(`Pricing/Report/Projects/${param}`).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data: IDashboardLookup[]) => {
        if (data) {
          this.companyProjects$.next([...data]);
          this.getRateGroupCheckReport();
        }
      },
      error: () => { }
    });
  }

  onProjectChanged() {
    this.getRateGroupCheckReport();
  }

  getRateGroupCheckReport() {
    this.loading = true;
    const param = {
      CompanyId: this.companyId,
      ProjectId: this.projectId
    } as ICheckRateReportParam;

    this.api.post(`Pricing/Report/CheckRates`, param).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data: ICheckRateDashboard[]) => {
        if (data) {
          this.report = Object.assign([], data);
        }
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  viewRateGroup(id: number) {
    if (this.pricingAdmin === true) {
      // Open rate group edit modal
      // Open modal to edit the rate group
      const modalRef = this.modalService.open(RateGroupEditModalComponent, {size: 'xl', windowClass: 'modal-2xl', backdrop: 'static'});
      modalRef.componentInstance.RateGroupID = id;
      modalRef.componentInstance.LoadRateGroup();
      // On modal close, read the result and apply logic
      modalRef.result.then((result: boolean) => {
        if (result) {
          this.getRateGroupCheckReport();
        }
      });
    } else {
      // Open rate capture modal
      const modalRef = this.modalService.open(RateGroupCaptureModalComponent, {size: 'xl', backdrop: 'static'});
      modalRef.componentInstance.RateGroupId = id;
      modalRef.componentInstance.CanEdit = true;
      modalRef.componentInstance.CanEditDate = true;
      modalRef.componentInstance.LoadRateGroup();
      // On modal close, read the result and apply logic
      modalRef.result.then((data: IRateGroup) => {
        if (data) {
          this.getRateGroupCheckReport();
        }
      }, () => {
      });
    }
  }
}
