<!---- Header ---->
<div class="modal-header" xmlns="http://www.w3.org/1999/html">
  <h4 class="modal-title" >
    {{ environmentalAssessmentItem.Id > 0 ? environmentalAssessmentItem.ItemNumber + '. ' + environmentalAssessmentItem.Topic : 'New environmental assessment item for: ' + environmentalAssessmentGroup.Description}}
  </h4>
</div>

<!-- Body -->
<div class="modal-body">
  <div class="row mb-1">
    <div class="col-2 fw-bold">
      Item number
    </div>
    <div class="col-6">
      <input class="form-control form-control-sm" type="text" maxlength="10"
             [(ngModel)]="environmentalAssessmentItem.ItemNumber"
             [ngClass]="{ 'is-invalid': environmentalAssessmentItem.ItemNumber == null && isInvalid }">
    </div>
    <div class="col-4 harmony-grey-text">
      <small></small>
    </div>
  </div>
  <div class="row mb-1">
    <div class="col-2 fw-bold">
      Topic
    </div>
    <div class="col-6">
      <textarea class="form-control form-control-sm w-100"
                [rows]="4"
                [(ngModel)]="environmentalAssessmentItem.Topic"
                [ngClass]="{ 'is-invalid': (environmentalAssessmentItem.Topic == null || environmentalAssessmentItem.Topic.length < 1) && isInvalid }">
      </textarea>
    </div>
    <div class="col-4 harmony-grey-text">
      <small></small>
    </div>
  </div>
  <div class="row mb-1">
    <div class="col-2 fw-bold">
      Subject
    </div>
    <div class="col-6">
      <textarea class="form-control form-control-sm w-100"
                [rows]="4"
                [(ngModel)]="environmentalAssessmentItem.Subject"
                [ngClass]="{ 'is-invalid': (environmentalAssessmentItem.Subject == null || environmentalAssessmentItem.Subject.length < 1) && isInvalid}">
      </textarea>
    </div>
    <div class="col-4 harmony-grey-text">
      <small>situation/occurrence with possible negative impact</small>
    </div>
  </div>
  <div class="row mb-1">
    <div class="col-2 fw-bold">
      environmental
    </div>
    <div class="col-6">
      <input class="form-control form-control-sm" type="text"
             [(ngModel)]="environmentalAssessmentItem.Risk"
             [ngClass]="{ 'is-invalid': (environmentalAssessmentItem.Risk == null || environmentalAssessmentItem.Risk.length < 1) && isInvalid }">
    </div>
    <div class="col-4 harmony-grey-text">
      <small>what is the consequence if the unwanted situation occurs</small>
    </div>
  </div>
  <div class="row mb-1">
    <div class="col-2 fw-bold">
      Area
    </div>
    <div class="col-6">
      <input class="form-control form-control-sm" type="text"
             [(ngModel)]="environmentalAssessmentItem.Area"
             [ngClass]="{ 'is-invalid': (environmentalAssessmentItem.Area == null || environmentalAssessmentItem.Area.length < 1) && isInvalid }">
    </div>
    <div class="col-4 harmony-grey-text">
      <small>all</small>
    </div>
  </div>
  <div class="row mb-1">
    <div class="col-2 fw-bold">
      Possible measures
    </div>
    <div class="col-6">
      <textarea class="form-control form-control-sm w-100"
                [rows]="4"
                [(ngModel)]="environmentalAssessmentItem.PossibleMeasures"
                [ngClass]="{ 'is-invalid': (environmentalAssessmentItem.PossibleMeasures == null || environmentalAssessmentItem.PossibleMeasures.length < 1) && isInvalid}">
      </textarea>
    </div>
    <div class="col-4 harmony-grey-text">
      <small>all</small>
    </div>
  </div>
</div>

<!---- Footer ---->
<div class="modal-footer" >
   <button type="button" class="btn btn-primary harmony-tertiary-button" (click)="updateItem()">Save</button>
   <button type="button" class="btn btn-primary harmony-primary-button" (click)="close(environmentalAssessmentItem)">Close</button>
</div>

