<!---- Header ---->
<div class="modal-header">
  <h4 class="modal-title">
    Verify Your Users
  </h4>
</div>
<div class="modal-body">
  <p class="small">
    To keep your data accurate and secure, verify your associated user(s) accessibility.
    User(s) accessibility will automatically be set to inactive whom have not logged in within 6 months.
    <br>
    A blue status indicates the user is active, gray inactive.
  </p>
  <div class="row header-row">
    <div class="col-4 header-title-small border-split-left">
      Name
    </div>
    <div class="col-4 header-title-small">
      Email
    </div>
    <div class="col-2 header-title-small">
      Last Login
    </div>
    <div class="col-2 text-center header-title-small border-split-right">
      Status
    </div>
  </div>
  <app-loader *ngIf="loading"></app-loader>
  <div *ngIf="this.users.length > 0" class="scrollable">
    <div class="row result-row" *ngFor="let u of users; let i = index;">
      <div class="col-4">
        {{ u.DisplayName }}
      </div>
      <div class="col-4">
        {{ u.Username }}
      </div>
      <div class="col-2">
        {{ u.LastLoggedInDate | date: 'dd-MM-yyyy' }}
      </div>
      <div class="col-2 text-center align-self-center">
        <div class="form-check form-switch form-switch-sm" style="margin-left: 45%; margin-top: 5px;">
          <input class="form-check-input pointer" type="checkbox" role="switch"
                 [id]="'switch-'+ i"
                 [title]="'Activate/deactivate user'" [checked]="!u.IsInactive"
                 (change)="setUser(u, $event)">
        </div>
      </div>
    </div>
  </div>
</div>
<!---- Footer ---->
<div class="modal-footer">
  <div class="container-fluid">
    <button type="button" class="btn btn-sm btn-primary harmony-tertiary-button float-end ms-2" (click)="save()">
      Confirm & Save
    </button>
    <button type="button" class="btn btn-sm btn-primary harmony-primary-button float-end" (click)="close()">
      I'll do it later
    </button>
  </div>
</div>
