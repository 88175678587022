<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom sticky-header">
  <h1 class="portal-component-header">
    Scheduled Jobs
  </h1>
</div>
<div class="row mt-3">
  <div class="col-12">
    <ul class="nav nav-tabs" id="hangfireTabs" role="tablist">
      <li class="nav-item" role="presentation">
        <a class="nav-link active" id="hangfire-dashboard-tab" data-bs-toggle="tab" href="#hangfire-dashboard" role="tab" aria-controls="hangfire-dashboard" aria-selected="true">
          Dashboard
        </a>
      </li>
      <li class="nav-item" role="presentation">
        <a class="nav-link" id="sp-jobs-tab" data-bs-toggle="tab" href="#sp-jobs" role="tab" aria-controls="sp-jobs">
          SP Jobs
        </a>
      </li>
    </ul>
    <!-------------------------------->
    <!------ Tab Content Start ------->
    <!-------------------------------->
    <div class="tab-content" id="hangfireTabsContent">
      <!----------------------------->
      <!-------- Dashboard ---------->
      <!----------------------------->
      <div class="tab-pane fade show active mb-5" id="hangfire-dashboard" role="tabpanel" aria-labelledby="hangfire-dashboard-tab">
        <div class="mt-2">
          <app-hangfire-dashboard></app-hangfire-dashboard>
        </div>
      </div>
      <!--------------------------------------------->
      <!-------- Stored Proc Recurring Jobs --------->
      <!--------------------------------------------->
      <div class="tab-pane fade mb-5" id="sp-jobs" role="tabpanel" aria-labelledby="sp-jobs-tab">
        <div class="row mt-3">
          <div class="col-12 text-center">
            <h3 class="fw-bold">
              Create a recurring job
            </h3>
          </div>
        </div>
        <ng-container *ngIf="recurringJobParam">
          <div class="row mt-1">
            <div class="col-4 offset-4">
              <input type="text"
                     [(ngModel)]="recurringJobParam.Name"
                     placeholder="e.g. HSE: Daily Status Check"
                     class="form-control form-control-sm">
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-4 offset-4">
              <select class="form-select form-select-sm"
                      name="database-name"
                      id="database-name"
                      [(ngModel)]="recurringJobParam.DatabaseName">
                <option [ngValue]="null">Select a database...</option>
                <option [ngValue]="'Portal'">Portal</option>
                <option [ngValue]="'Pricing'">Pricing</option>
                <option [ngValue]="'Hub'">Hub</option>
              </select>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-4 offset-4">
              <input type="text"
                     [(ngModel)]="recurringJobParam.Query"
                     placeholder="e.g. EXEC SP_Example"
                     class="form-control form-control-sm">
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-4 offset-4">
              <input type="text"
                     [(ngModel)]="recurringJobParam.CronExpression"
                     placeholder="e.g. 0 0 6 * * ?"
                     class="form-control form-control-sm">
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-4 offset-4">
              <button class="btn btn-primary harmony-tertiary-button w-100"
                      (click)="createJob()"
                      [disabled]="loading">
                Create
              </button>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
