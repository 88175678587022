
<div class="card-wrapper">
  <div class="card-content">
    <div class="card-header row">
      <div class="col-12">
        <!--Header-->
        <h5 class="harmony-primary-text fw-bold mt-2">Header</h5>
      </div>
    </div>
    <div class="card-body row">
      <div class="col-12">
        <!-- This is where you have to develop the component-->
        <!-- Code or text goes here-->
      </div>
    </div>
  </div>
</div>

