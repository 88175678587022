import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { EmailService } from '../../../../../services/email.service';
import { IEmailContent } from '../../../../../services/models/email.model';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FileService } from '../../../../../services/file.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AlertService } from '../../../../../services/alert.service';
import { environment } from 'src/environments/environment';
import {IApiResponse} from '../../../../../shared/models/api-reponse.model';

@Component({
  selector: 'app-view-email-modal',
  templateUrl: './view-email-modal.component.html',
  styleUrls: ['./view-email-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ViewEmailModalComponent implements OnInit {
  // Icons
  faDownloadIcon = faDownload;

  // Variables
  public EmailID: number = null;
  emailContent: IEmailContent;
  emailBody: SafeHtml;

  // General variables
  loading: boolean = false;
  environment = environment;

  constructor(private emailService: EmailService,
              private activeModal: NgbActiveModal,
              private fileService: FileService,
              private sanitizer: DomSanitizer,
              private alertService: AlertService) {
  }

  ngOnInit(): void { }

  public LoadEmailContent() {
    this.loading = true;

    this.emailService.getEmailContent(this.EmailID).subscribe({
      next:
        (data: IEmailContent) => {
          this.emailContent = Object.assign({}, data);
          this.emailBody = this.sanitizer.bypassSecurityTrustHtml(this.emailContent.Content);
          this.loading = false;
        }, error: (err: IApiResponse) => {
        this.alertService.error(err?.Meta?.Message);
        this.loading = false;
        this.close();
      }
    });
  }

  downloadAttachment(folder: string, attachmentName: string) {
    this.fileService.GetFile(folder, attachmentName);
  }

  close() {
    this.activeModal.close();
  }
}
