<div class="dashboard-background"></div>
<div class="dashboard-wrapper">
  <div class="dashboard-content">
    <!-- Integration -->
    <div class="three-by-two d-flex align-items-start services-card">
      <div>
        <div class="services-card-header row">
          <div class="col-12">
            <!--Header-->
            <h5 class="harmony-teal-text fw-bold">Tech Support & Interfaces</h5>
          </div>
        </div>
        <hr class="ms-3 me-3 mt-0 mb-4">
        <div class="row mt-2">
          <div class="col-3">
            <div class="justify-content-center m-2">
              <img [src]="env.SiteUrls.AzureStorageBaseURL + env.ContainerNames.WebImagesServices + 'images.png'"
                   class="service-image-rounded-borders"
                   alt="Tech Support & Interfaces">
            </div>
          </div>
          <div class="col-9 service-content">
            <p>
              Harmony has a proven track record of providing high level Technology Support to member companies, mainly where it concerns the exchange of data between different systems, for a variety of purposes.
            </p>
            <p>
              To that effect Harmony uses software called the Harmony HUB. This software makes it relatively easy to set up an interface, by connecting relevant data fields, with firm agreements on the direction of data, and the proper business rules that determine when a data field can and cannot be updated.
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- Integration Contact -->
    <div class="one-by-one services-card">
      <div class="services-card-header row">
        <div class="col-12">
          <!--Header-->
          <h5 class="harmony-teal-text fw-bold">Integration Contact</h5>
        </div>
      </div>
      <hr class="ms-3 me-3 mt-0 mb-4">
      <div class="row">
        <div class="col-12 ms-4">
          <div class="row">
            <div class="col-12 service-contact">
              Mr Robert van der Kooij
            </div>
          </div>
          <div class="row my-1">
            <div class="col-1">
              <fa-icon [icon]="faEnvelope"></fa-icon>
            </div>
            <div class="col-11 nav-item">
              <a href="mailto:r.kooij@harmonyrelo.com?Subject=Request information about Services/Integration" class="harmony-blue-link">r.kooij@harmonyrelo.com</a>
            </div>
          </div>
          <div class="row my-1">
            <div class="col-1">
              <fa-icon [icon]="faPhone"></fa-icon>
            </div>
            <div class="col-11">
              <a href="callto:+31 294 461 026" style="color: rgb(0,0,0)">+31 294 461 026</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Integration Documents -->
    <div class="one-by-one services-card">
      <div class="services-card-header row">
        <div class="col-12">
          <!--Header-->
          <h5 class="harmony-teal-text fw-bold">Insurance Documents</h5>
        </div>
      </div>
      <hr class="ms-3 me-3 mt-0 mb-4">
      <div class="card-body row mt-2">
        <div class="col-12 service-content document-list">
          <app-document-list [ModuleName]="'Services'" [CategoryCode]="'SERVSHEE'" [Recents]="true"></app-document-list>
        </div>
      </div>
    </div>
    <!-- Examples of Interfaces -->
    <div class="three-by-two d-flex align-items-start services-card">
      <div>
        <div class="services-card-header row">
          <div class="col-12">
            <!--Header-->
            <h5 class="harmony-teal-text fw-bold">Examples of Interfaces</h5>
          </div>
        </div>
        <hr class="ms-3 me-3 mt-0 mb-4">
        <div class="row mt-2">
          <div class="col-3">
            <div class="justify-content-center m-2">
              <img [src]="env.SiteUrls.AzureStorageBaseURL + env.ContainerNames.WebImagesServices + 'hrn_hub.jpg'"
                   class="service-image-rounded-borders"
                   alt="Examples of Interfaces">
            </div>
          </div>
          <div class="col-9 service-content">
            <p>
              There is a variety of ways that a data interface can work for you. Here is a snapshot of some of the projects we have already implemented, all to avoid double entry of data.
            </p>
            <ul class="service-list">
            <li>Harmony receives order data from our direct (legacy) customers, and the interface makes sure that the order data is directly uploaded into our move management software (RedSky).</li>
            <li>For a member company that is not working on RedSky, that has booked a large contract, for which Harmony does the billing, we make sure that the order data is sent from RedSky to their own move management software, so that the member company has the relevant data for their own reporting purposes.</li>
            <li>For an RMC client, we send regular data feeds to their own IT system, to make sure that their relocation coordinators are being kept up to date regarding the status of the order.</li>
            <li>A customer of a member company uses an auditing firm. The auditing firm requires that all order data is uploaded into their audit tool. We have built an interface with the auditing firm, so that all order data is sent over automatically.</li>
            </ul>
            <p>
              Going forward, we expect that our contribution and added value of Tech Support to the network will increase. Efficiency is key in cost reduction, and technology is crucial in solving that problem. We foresee that Harmony will collect relevant order data for better visibility of intra-European shipments, consolidation containers etc.
            </p>
            <p>
              We’d be happy to chat about potential interfacing projects with any member company, and see where we can add value.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
