<div class="google_map_container">
  <google-map
    [height]="'100%'"
    [width]="'100%'"
    style="border-radius: 27.5px;"
    [zoom]="Zoom"
    [center]="center"
    [options]="mapOptions">
    <map-marker-clusterer *ngIf="map?.googleMap"
                          [gridSize]="20"
                          [imagePath]="'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'">
      <map-marker
        #markerElem="mapMarker"
        *ngFor="let marker of markers"
        [position]="marker.position"
        [options]="marker.options"
        [label]="marker.label"
        [title]="marker.title"
        [clickable]="true"
        (mapClick)="openInfo(markerElem, marker)">
      </map-marker>
    </map-marker-clusterer>
    <map-info-window>
      <div id="member-info-content">
        <div id="body-content">
          <div id="header" class="row m-1">
            <div class="col-md-12 text-center" *ngIf="CompanyLogo">
              <img [src]="CompanyLogo" width="150" alt="logo" class="map-company-logo"/>
            </div>
            <div class="col-md-12 text-center">
              <a [href]="'/member/' + CompanyID"
                 target="_blank"
                 class="harmony-primary-link">
                <h5 id="company-name"
                    class="harmony-primary-text map-company-name">
                  {{ CompanyName }}
                </h5>
              </a>
            </div>
          </div>
          <div class="row m-1 mt-3">
            <div class="col-md-8 offset-md-2 text-center">
              <a [href]="env.website_url + '/contact/' + CompanyID" title="Contact Member" target="_blank" class="pointer text-decoration-none d-inline me-3">
                <img src="../../../../assets/icons/color/purple/icon-sm-mail.svg" alt="contact-member" class="map-icon">
              </a>
              <a [href]="'/member/' + CompanyID" title="View Member Page" target="_blank" class="pointer text-decoration-none d-inline me-3">
                <img src="../../../../assets/images/vector/house-1.svg" alt="view-member" class="map-icon" style="width: 40px">
              </a>
              <a [href]="CompanyWebsiteUrl" title="Visit Website" target="_blank" class="pointer text-decoration-none">
                <img src="../../../../assets/icons/color/purple/icon-sm-world.svg" alt="member-website" class="map-icon">
              </a>
            </div>
          </div>
        </div>
      </div>
    </map-info-window>
  </google-map>
</div>
