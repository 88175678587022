<div class="row ps-1 pe-1 mt-2">
  <div class="col-2 ps-0">
    <button class="btn btn-sm btn-primary harmony-teal-outline-button w-100"
            (click)="previous()"
            [disabled]="disablePreviousPage() || !hasPaginationData()">
      Previous Page
    </button>
  </div>
  <div class="col-8 align-self-center">
    <div class="row">
      <div class="col-6 align-self-center text-start">
        <div *ngIf="showRowsPerPage">
          <small>Page size:</small>
          <select class="form-select d-inline row-count-select"
                  name="rowCount"
                  id="rowCount"
                  [(ngModel)]="page.pageSize"
                  (change)="setRowCount()">
            <option [ngValue]="15">15</option>
            <option [ngValue]="30">30</option>
            <option [ngValue]="50">50</option>
            <option [ngValue]="100">100</option>
          </select>
        </div>
      </div>
      <div class="col-6 align-self-center text-end">
        <div *ngIf="showPageNumber">
          <small>
            Page <strong>{{ page?.pageNumber}}</strong> of <strong>{{ paginationData?.TotalPages }}</strong>
          </small>
        </div>
      </div>
    </div>
  </div>
  <div class="col-2 text-end pe-0">
    <button class="btn btn-sm btn-primary harmony-teal-outline-button w-100"
            (click)="next()"
            [disabled]="disableNextPage() || !hasPaginationData()">
      Next Page
    </button>
  </div>
</div>
