import { IRateRequestType } from './rate-request.model';

export interface IRateGroupParam {
  RateGroupID: number;
  ProjectID: number; /* FK to Project */
  ModalityTypeID: number; /* FK: ModalityType */
  RateRequestTypeID: number; /* FK: RateRequestType */
  OriginCityID: number;
  OriginCountryID: number;
  DeparturePortID: number; /* FK to CityFunction with FunctionType: Port */
  DestinationCityID: number;
  DestinationCountryID: number;
  ArrivalPortID: number; /* FK to CityFunction with FunctionType: Port */
  RateRequestID: number; /* FK to RateRequest */
  ValidFrom: string;
  ValidTo: string;
  AgentID: number;
  RateVerdictID: number;
  ActiveOnly: boolean;
}

export interface IRateGroupPagination {
  RateGroupId: number;
  Active: boolean;
  Description: string;
  RateRequestTypeName: string;
  CompanyName: string;
  OriginLocationName: string;
  DeparturePortCode: string;
  DeparturePortDescription: string;
  DestinationLocationName: string;
  ArrivalPortCode: string;
  ArrivalPortDescription: string;
  ValidFrom: string;
  ValidTo: string;
  RateVerdictName: string;
  CanEdit: boolean;
  CanEditDate: boolean;
}

export interface IRateGroup {
  ID: number;
  ModalityTypeID: number; /* FK: ModalityType */
  ModalityTypeName: string;
  Description: string;
  ProjectID: number; /* FK to Project */
  ProjectName: string;
  AgentCompanyID: number;
  AgentCompanyName: string;
  RateRequestID: number; /* FK to RateRequest */
  RateRequestTypeName: string;
  RateBuySellTypeID: number; /* FK to RateTypeBuySell */
  RateSourceID: number; /* FK to RateSource */
  RateVerdictID: number; /* FK to RateVerdict */
  OriginCityID: number;
  OriginCityName: string;
  OriginStateID: number;
  OriginStateName: string;
  OriginCountryID: number;
  OriginCountryName: string;
  DeparturePortID: number; /* FK to CityFunction with FunctionType: Port */
  DeparturePortCode: string;
  DeparturePortDescription: string;
  ArrivalPortID: number; /* FK to CityFunction with FunctionType: Port */
  ArrivalPortCode: string;
  ArrivalPortDescription: string;
  DestinationCityID: number;
  DestinationCityName: string;
  DestinationStateID: number;
  DestinationStateName: string;
  DestinationCountryID: number;
  DestinationCountryName: string;
  SubmissionDate: string;
  ValidFrom: string;
  ValidTo: string;
  Comment: string;
  ServiceCount: number;
  RateGroupServices: IRateGroupServiceDto[];
  RateGroupInclusions: IRateGroupInclusion[];
}

export interface IRateGroupServiceDto {
  ID: number;
  DateCreated: Date;
  DateModified: Date;
  Active: boolean;
  RateGroupID: number; /* FK to RateGroup */
  ServiceID: number; /* FK to ServiceType */
  ServiceName: string;
  CalculationMethodID: number; /* FK to CalculationMethod */
  CalculationMethodName: string;
  ServiceLocationID: number; /* FK to ??? */
  ServiceRates: IServiceRateDto[];
  // Extra fields
  Comment: string;
}

export interface IServiceRateDto {
  ID: number;
  DateCreated: Date;
  DateModified: Date;
  Active: boolean;
  RateGroupServiceID: number; /* FK to RateGroupService */
  RateLimitTypeID: number; /* FK to RateLimitTypeID */
  CurrencyID: number; /* FK to Currency */
  Rate: number;
  RateUnitTypeID_1: number; /* FK to UnitType (UnitType of Rate value) */
  RateQuantity_1: number; /* Quantity by which Rate should be multiplied */
  MeasurementUnitTypeID_1: number; /* FK to UnitType (UnitType of Quantity value) */
  RateUnitTypeID_2: number; /* FK to UnitType (UnitType of Rate value) */
  RateQuantity_2: number; /* Quantity by which Rate should be multiplied after RateQuantity_1 */
  MeasurementUnitTypeID_2: number; /* FK to UnitType (UnitType of Quantity value) */
}

export interface IRateGroupInclusion {
  Id: number;
  DateCreated: Date;
  DateModified: Date;
  Active: boolean;
  RateGroupId: number;
  Description: number;
  IsIncluded: number;
  StartDate: number;
  EndDate: number;
  Order: number;
}

export interface ISelectRateGroupParam {
  CityID: number;
  PortID: number;
  RateTypeID: number;
  TenderID: number;
  RequestingCompanyID: number;
  Date: Date;
  Radius_1: number;
  Radius_2: number;
  MaximumDaysInvalid: number;
  MaximumRateGroup: number;
}

export interface ISelectRateGroupOption {
  ID: number;
  CityID: number;
  CityName: string;
  StateID: number;
  StateName: string;
  CountryID: number;
  CountryName: string;
  PortID: number;
  PortName: number;
  AgentCompanyID: string;
  AgentCompanyName: string;
  IsHarmonyMember: boolean;
  ProjectID: number;
  ProjectName: string;
  ValidTo: Date;
  SubmissionDate: Date;
  Distance: number;
}

export interface ICloneRateGroupParam {
  RateGroupId: number;
  ValidFrom: string;
  ValidTo: string;
  RateDeviation: number | null;
}

export interface IRateGroupCheckResult {
  RateGroupId: number;
  RateGroupServiceId: number;
  ServiceTypeName: string;
  Comment: number;
}

/* LOOKUP DATA MODELS */
export interface IProject {
  Id: number;
  DateCreated: Date;
  DateModified: Date;
  Active: boolean;
  Name: string;
  Description: string;
  PartnerId: number;

  RateRequestTypes: IRateRequestType[];
}

export interface IModalityType {
  Id: number;
  DateCreated: Date;
  DateModified: Date;
  Active: boolean;
  Name: string;
}

export interface IUnitType {
  Id: number;
  DateCreated: Date;
  DateModified: Date;
  Active: boolean;
  Name: string;
  Abbreviation: string;
  Gross: boolean;
  Volume: boolean;
  Metric: boolean;
  Weight: boolean;
}

export interface IFunctionType {
  Id: number;
  Active: boolean;
  DateCreated: Date;
  DateModified: Date;
  Description: string;
}

export interface IServiceType {
  Id: number;
  DateCreated: Date;
  DateModified: Date;
  Active: boolean;
  Name: string;
  IsOrigin: boolean;
  IsOriginDefault: boolean;
  IsFreight: boolean;
  IsFreightDefault: boolean;
  IsDestination: boolean;
  IsDestinationDefault: boolean;
  InputTypeId: number; // FK to InputType
  InputType: IInputType;
}

export interface IInputType {
  Id: number;
  DateCreated: Date;
  DateModified: Date;
  Active: boolean;
  Name: string;
  Description: string;
}

export interface IRateSource {
  Id: number;
  Name: string;
  Description: string;
}

export interface IRateVerdict {
  Id: number;
  Name: string;
  Description: string;
  IncludeInOverview: boolean;
  AgentEdit: boolean;
}

export interface ICalculationMethod {
  Id: number;
  DateCreated: Date;
  DateModified: Date;
  Active: boolean;
  Name: string;
}

export interface IRateLimitType {
  Id: number;
  DateCreated: Date;
  DateModified: Date;
  Active: boolean;
  Name: string;
}

export interface IServiceTypeUnitType {
  Id: number;
  DateCreated: Date;
  DateModified: Date;
  Active: boolean;
  ServiceTypeId: number;
  UnitTypeId1: number;
  UnitTypeId2: number;

  UnitTypeId1Navigation: IUnitType;
  UnitTypeId2Navigation: IUnitType;
  ServiceType: IServiceType;
}

export interface IModalityTypeUnitType {
  ID: number;
  DateCreated: Date;
  DateModified: Date;
  Active: boolean;
  ModalityTypeID: number;
  UnitTypeID_1: number;
  UnitTypeID_2: number;
  ModalityTypeName: string;
  UnitTypeName: string;
  SecondaryUnitTypeName: string;
}

export interface IRateGroupSampleParam {
  RateGroupID: number;
  RateUnitTypeID_1: number;
  RateUnitTypeID_2: number;
  Quantity_1: number;
  Quantity_2: number;
  CurrencyID: number;
  OriginLocationID: number;
  OriginLocationTypeID: number;
  DestinationLocationID: number;
  DestinationLocationTypeID: number;
}

export interface IRateGroupSampleResult {
  Rate: number;
  Comment: string;
}

/* ENUMS */
export enum RateTypes {
  AIROrigin = 1,
  AIRFreight = 5,
  AIRDestination = 9,
  FCLOrigin = 2,
  FCLFreight = 6,
  FCLDestination = 10,
  LCLOrigin = 3,
  LCLFreight = 7,
  LCLDestination = 11,
  ROADOrigin = 4,
  ROADFreight = 8,
  ROADDestination = 12
}

export enum LocationTypes {
  City = 5,
  State = 4,
  Country = 3
}
