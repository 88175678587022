<div class="card-wrapper">
  <div class="card-content">
    <div class="card-header row">
      <div class="col-10">
        <h5 class="harmony-primary-text fw-bold mt-2">Active Tenders</h5>
      </div>
      <div class="col-2 text-end">
<!--        <a class="nav-link pe-0 pointer" (click)="viewDocuments()"><fa-icon [icon]="faLink"></fa-icon></a>-->
      </div>
    </div>
    <div class="card-body">
      <ng-container *ngIf="loading">
        <br>
        <app-loader [Width]="30"></app-loader>
      </ng-container>
      <ng-container *ngIf="!loading && report">
        <div class="row">
          <div class="col-8">
            <small>Configuring tender</small>
          </div>
          <div class="col-4">
            <div class="tender-count float-end pointer" (click)="viewTenders('configure')">
              {{ report?.ConfigureCount }}
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-8">
            <small>Adding locations</small>
          </div>
          <div class="col-4">
            <div class="tender-count float-end" (click)="viewTenders('locations')">
              {{ report?.LocationCount }}
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-8">
            <small>Setting volume/weight</small>
          </div>
          <div class="col-4">
            <div class="tender-count float-end" (click)="viewTenders('volume')">
              {{ report?.VolumeWeightCount }}
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-8">
            <small>Configuring freight lanes</small>
          </div>
          <div class="col-4">
            <div class="tender-count float-end" (click)="viewTenders('freight')">
              {{ report?.FreightCount }}
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-8">
            <small>Quoted</small>
          </div>
          <div class="col-4">
            <div class="tender-count float-end" (click)="viewTenders('quote')">
              {{ report?.QuoteCount }}
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-8">
            <small>Quote Accepted</small>
          </div>
          <div class="col-4">
            <div class="tender-count float-end" (click)="viewTenders('quote-accepted')">
              {{ report?.QuoteAccepted }}
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-8">
            <small>Ready for export</small>
          </div>
          <div class="col-4">
            <div class="tender-count tender-export float-end" (click)="viewTenders('export')">
              {{ report?.ExportCount }}
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
