<div class="modal-header modal-title">
  <h4>Article Attachments</h4>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-2">
      <b>Name</b>
    </div>
    <div class="col-8">
      <input [(ngModel)]="attachment.Name" [ngClass]="{'is-invalid': attachment?.Name?.length < 1 && this.invalid}"
             [placeholder]="'Enter a name for the document'"
             class="form-control form-control-sm w-100" type="text">
    </div>
    <div class="col-2">
      <button (click)="FileHandler.click()" *ngIf="!hasFile"
              class="btn btn-sm btn-primary harmony-tertiary-button w-100"
              type="button">
        <fa-icon [icon]="faUpload"></fa-icon>
        Upload
      </button>
      <button (click)="clearSelectedFile()" *ngIf="hasFile"
              class="btn btn-sm btn-primary harmony-secondary-button w-100"
              type="button" [title]="'Cancel upload'">
        <fa-icon [icon]="faCancel"></fa-icon>
      </button>
      <input #FileHandler
             (change)="selectFile($event)"
             hidden
             id="file"
             multiple
             type="file"/>
    </div>
  </div>
  <div class="row mt-1">
    <div class="col-2">
      <b>File</b>
    </div>
    <div class="col-9 harmony-grey-text">
      {{ attachment?.File?.name ?? 'upload a file...'}}
    </div>
  </div>
  <app-loader *ngIf="loading"></app-loader>
  <hr class="my-2">
  <div *ngFor="let a of attachments" class="row mb-1">
    <div class="col-9 align-self-center">
      {{ a.Name }}
    </div>
    <div class="col-3 text-end">
      <button (click)="downloadAttachment(a.Reference)"
              class="btn btn-sm btn-primary harmony-tertiary-outline-button border-0"
              type="button" [title]="'Download Attachment'">
        <fa-icon [icon]="faDownload"></fa-icon>
      </button>
      <button (click)="delete(a.Id)"
              class="btn btn-sm btn-outline-danger border-0 ms-1"
              type="button">
        <fa-icon [icon]="faDelete"></fa-icon>
      </button>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button (click)="save()" [disabled]="loading" class="btn btn-primary harmony-tertiary-button" type="button">
    Save
  </button>
  <button (click)="close()" [disabled]="loading" class="btn btn-outline-primary harmony-primary-outline-button" type="button">
    Close
  </button>
</div>
