import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from './api.service';
import {
  IDocumentCategory,
  IDocumentCategorySearchParam,
  IDocumentCategoryUpdateParam
} from './models/document/document-category.model';
import { IPage } from '../shared/models/pagination-data.model';
import { IOrderParam } from '../shared/directives/sort/order.directive';
import {ICategoryDocumentParam, IDocumentListParam, IDocumentSearchParam, IDocumentUpdateParam} from './models/document/document.model';

@Injectable({
  providedIn: 'root'
})
export class DocumentService implements OnDestroy {

  documentCategories$: BehaviorSubject<IDocumentCategory[]> = new BehaviorSubject<IDocumentCategory[]>([]);

  private unsubscribe: Subject<any> = new Subject<any>();

  constructor(private apiService: ApiService) {
    this.LoadLookupData();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  // Documents //
  getDocument(Id: number) {
    return this.apiService.get(`Document/${Id}`).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  searchDocuments(param: IDocumentSearchParam, p: IPage, o: IOrderParam) {
    return this.apiService.post(`Document/Search?pageNumber=${p.pageNumber}&pageSize=${p.pageSize}&orderBy=${o.OrderBy}&order=${o.OrderDirection}`, param).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  loadDocuments(param: IDocumentListParam) {
    return this.apiService.post(`Document/Load`, param).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  loadCategoryDocuments(param: ICategoryDocumentParam) {
    return this.apiService.post(`Document/Load/Categories`, param).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  createDocument(param: IDocumentUpdateParam, file: any) {
    const formData = new FormData();
    formData.append('File', file, file.name);
    formData.append('DocumentCategoryId', param.DocumentCategoryId.toString());
    formData.append('DisplayName', param.DisplayName.toString());
    formData.append('FileName', param.FileName.toString());
    formData.append('Description', param.Description.toString());
    formData.append('CompanyId', param.CompanyId ? param.CompanyId.toString() : '0');
    formData.append('DocumentUserId', param.DocumentUserId ? param.DocumentUserId.toString() : '0');

    return this.apiService.post(`Document`, formData).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  updateDocument(param: IDocumentUpdateParam, file: any | null) {
    const formData = new FormData();
    if (file) {
      formData.append('File', file, file.name);
    }
    formData.append('Id', param.Id.toString());
    formData.append('DocumentCategoryId', param.DocumentCategoryId.toString());
    formData.append('DisplayName', param.DisplayName.toString());
    formData.append('FileName', param.FileName.toString());
    formData.append('Description', param.Description.toString());
    formData.append('CompanyId', param.CompanyId ? param.CompanyId.toString() : '0');
    formData.append('DocumentUserId', param.DocumentUserId ? param.DocumentUserId.toString() : '0');

    return this.apiService.put(`Document`, formData).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  deleteDocument(documentId: number) {
    return this.apiService.delete(`Document/${documentId}`).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  // Document Categories //
  public LoadLookupData() {
    this.apiService.get('DocumentCategory').pipe(
      takeUntil(this.unsubscribe)
    ).subscribe((data: IDocumentCategory[]) => {
      if (data) {
        this.documentCategories$.next(data);
      }
    });
  }

  getDocumentCategory(id: number) {
    return this.apiService.get(`DocumentCategory/${id}`).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  searchDocumentCategories(param: IDocumentCategorySearchParam, p: IPage, o: IOrderParam) {
    return this.apiService.post(`DocumentCategory/Search?pageNumber=${p.pageNumber}&pageSize=${p.pageSize}&orderBy=${o.OrderBy}&order=${o.OrderDirection}`, param).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  upsertDocumentCategory(param: IDocumentCategoryUpdateParam) {
    return this.apiService.post(`DocumentCategory`, param).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  deleteDocumentCategory(documentCategoryId: number) {
    return this.apiService.delete(`DocumentCategory/${documentCategoryId}`).pipe(
      takeUntil(this.unsubscribe)
    );
  }
}
