<div class="dashboard-background"></div>
<div class="dashboard-wrapper">
  <div class="dashboard-content">
    <!-- Netting -->
    <div class="three-by-five d-flex align-items-start services-card">
      <div>
        <div class="services-card-header row">
          <div class="col-12">
            <!--Header-->
            <h5 class="harmony-primary-text fw-bold">Netting</h5>
            <hr class="mt-0 mb-1">
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-3">
            <div class="justify-content-center m-2">
              <img [src]="env.SiteUrls.AzureStorageBaseURL + env.ContainerNames.WebImagesServices + 'Netting.jpg'"
                   class="service-image-rounded-borders"
                   alt="Netting">
            </div>
          </div>
          <div class="col-9 service-content">
            <p>
            The Netting is a payment service available to all Harmony members. Its goal is to reduce bank costs and make payments between members more foreseeable.
            </p>
            <br>
            <p>
            <span><h5 class="harmony-secondary-text fw-bold">How does it work?</h5></span>
            Harmony members choose a currency in which to pay invoices to other members and/or receive payments from members. Every month all members enter the invoice amounts of invoices received from other members in an online system, called CoProcess. On the second Wednesday of the month, the Harmony head office sends all members a preliminary Netting Statement, which shows how much you will pay to other members, as well as how much other members will pay to you, plus the balance of these two amounts.
            </p>
            <p>
            On the third Wednesday of the month, the Harmony head office calculates the final balance payable or receivable per member. That same day every Harmony member receives a final Netting Statement, which tells you the balance to pay or to receive.
            </p>
            <p>
            Members that need to pay have one week to transfer their money to the head office. With this money, the head office buys and sells the currencies that are needed to pay the receiving members. All transfers to receiving members are made on the fourth Wednesday of the month. Depending on your bank, the money will be in your account 1-2 days later.
            </p>
            <br>
            <p>
            <span><h5 class="harmony-secondary-text fw-bold">Netting Rules</h5></span>
            The most important rule of the Netting is to timely enter a payable invoice into the netting system before the third Wednesday of the current month, these invoices should be dated older than the third Wednesday of the previous month.
            </p>
            <p>
            The Harmony Netting is mandatory for all members. All members signed the Netting Agreement when they signed their Membership Agreement. Only members that can prove that local legislation prohibits the netting of invoices are exempt. Not using the Harmony netting requires approval from the Harmony Supervisory Board. If you think your company is eligible for exemption, please contact the Harmony head office.
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- Netting Contact -->
    <div class="one-by-one services-card">
      <div class="services-card-header row">
        <div class="col-12">
          <!--Header-->
          <h5 class="harmony-primary-text fw-bold">Netting Contact</h5>
          <hr class="mt-0 mb-1">
        </div>
      </div>
      <div class="row">
        <div class="col-12 ms-4">
          <div class="row">
            <div class="col-12 service-contact">
              Ms Anne-Mieke de Boer
            </div>
          </div>
          <div class="row my-1">
            <div class="col-1">
              <fa-icon [icon]="faEnvelope"></fa-icon>
            </div>
            <div class="col-11 nav-item">
              <a href="mailto:a.boer@harmonyrelo.com?Subject=Request information about Services/Netting" class="harmony-secondary-link">a.boer@harmonyrelo.com</a>
            </div>
          </div>
          <div class="row my-1">
            <div class="col-1">
              <fa-icon [icon]="faPhone"></fa-icon>
            </div>
            <div class="col-11">
              <a href="callto:+31 294 461 015" style="color: rgb(0,0,0)">+31 294 461 015</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Netting Documents -->
    <div class="one-by-one services-card">
      <div class="services-card-header row">
        <div class="col-12">
          <!--Header-->
          <h5 class="harmony-primary-text fw-bold">Netting Documents</h5>
          <hr class="mt-0 mb-1">
        </div>
      </div>
      <div class="card-body row mt-2">
        <div class="col-12 service-content document-list">
          <app-document-list [ModuleName]="'Services'" [CategoryCode]="'SERVNETD'" [Recents]="true"></app-document-list>
        </div>
      </div>
    </div>
    <!-- Netting Links -->
    <div class="one-by-one services-card">
      <div class="services-card-header row">
        <div class="col-12">
          <!--Header-->
          <h5 class="harmony-primary-text fw-bold">Netting Links</h5>
          <hr class="mt-0 mb-1">
        </div>
      </div>
      <div class="card-body row mt-2">
        <div class="col-12 service-content document-list">
          <div class="row ms-2 my-1">
            <div class="col-1">
              <fa-icon [icon]="faLink"></fa-icon>
            </div>
            <div class="col-11 nav-item">
              <a href="https://coprocess1.netting.net/netWEB/HTML/Coprocess/PreLoginAuth.html" target="_blank" class="harmony-secondary-link">Netting System - CoProcess</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Netting Fee & Options -->
    <div class="three-by-three d-flex align-items-start services-card">
      <div>
        <div class="services-card-header row">
          <div class="col-12">
            <!--Header-->
            <h5 class="harmony-primary-text fw-bold">Netting Fee & Options</h5>
            <hr class="mt-0 mb-1">
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-3">
            <div class="justify-content-center m-2">
              <img [src]="env.SiteUrls.AzureStorageBaseURL + env.ContainerNames.WebImagesServices + 'Netting2.jpg'"
                   class="service-image-rounded-borders"
                   alt="Netting Fee & Options">
            </div>
          </div>
          <div class="col-9 service-content">
            <p>
            The montly fee for a netting ID is related to your Membership Level. This amount is charged through your monthly invoice from Harmony head office.
            </p>
            <p>
            Some members have bank accounts for several currencies, for example GBP and USD. They may prefer to pay or receive in two netting currencies instead of just one. Harmony offers the option to work with a maximum of three netting currencies. For every additional currency, a montly fee is charged.
            </p>
            <p>
            Members with multiple offices may hold multiple memberships and, thus, have multiple netting IDs. Some members prefer to do all their payments from one central office, hence they would need a Netting Statement that is consolidated for all netting IDs, and shows only a single balance to pay or receive. This set up is called a netting “pool” and is available upon request. No additional charges apply.
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- Netting Manual -->
    <div class="three-by-two d-flex align-items-start services-card">
      <div>
        <div class="services-card-header row">
          <div class="col-12">
            <!--Header-->
            <h5 class="harmony-primary-text fw-bold">Netting Manual</h5>
            <hr class="mt-0 mb-1">
          </div>
        </div>
        <div class="row mt-2 mx-0">
          <div class="col-12 service-content">
            <div class="justify-content-center m-2">
              <p>
                The Netting Manual will answer most of your questions regarding the Harmony netting system. It can be downloaded under the documents section of this page. Should you require more information or if you have any other question please do not hesitate to contact us via netting@harmonyrelo.com
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
