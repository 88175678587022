<div class="card-wrapper">
  <div class="card-content">
    <div class="card-header row">
      <div class="col-12">
        <h5 class="harmony-primary-text fw-bold mt-2">Rate Requests</h5>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-6">
              <ng-container *ngIf="pricingAdmin">
                <select class="form-select form-select-sm form-control-xs pointer"
                        [(ngModel)]="projectId"
                        [disabled]="loading || !(projects$ | async)"
                        (ngModelChange)="onProjectChanged()">
                  <option [ngValue]="null" selected>All projects...</option>
                  <option *ngFor="let project of (projects$ | async)"
                          [ngValue]="project.Id">{{ project.Name }}</option>
                </select>
              </ng-container>
              <ng-container *ngIf="!pricingAdmin">
                <select class="form-select form-select-sm form-control-xs pointer"
                        [(ngModel)]="projectId"
                        [disabled]="loading || !(companyProjects$ | async)"
                        (ngModelChange)="onProjectChanged()">
                  <option [ngValue]="null" selected>All projects...</option>
                  <option *ngFor="let project of (companyProjects$ | async)"
                          [ngValue]="project.Id">{{ project.Name }}</option>
                </select>
              </ng-container>
            </div>
            <div class="col-6">
              <select class="form-select form-select-sm form-control-xs pointer"
                      [(ngModel)]="cityId"
                      [disabled]="loading || !(locations$ | async)"
                      (change)="getReport()">
                <option [ngValue]="null" selected>All locations...</option>
                <option *ngFor="let city of (locations$ | async)"
                        [ngValue]="city.Id">{{ city.Name }}</option>
              </select>
            </div>
          </div>
          <ng-container *ngIf="loading">
            <br>
            <app-loader [Width]="30"></app-loader>
          </ng-container>
          <div *ngIf="!loading && report" class="row mt-3">
            <div class="col-3 pointer" (click)="viewRequests('Pending')">
              <div class="rr-count level-pending">
                <div class="label pt-1 text-center">Pending</div>
                <div class="fw-bold text-center subject">{{ report.PendingRequests }}</div>
              </div>
            </div>
            <div class="col-3 pointer" (click)="viewRequests('Awaiting')">
              <div class="rr-count pt-1 level-awaiting">
                <div class="label text-center">Awaiting submission</div>
                <div class="fw-bold text-center subject">{{ report.AwaitingSubmission }}</div>
              </div>
            </div>
            <div class="col-3 pointer" (click)="viewRequests('ExpiringSoon')">
              <div class="rr-count pt-1 level-expiring">
                <div class="label text-center">Expiring soon</div>
                <div class="fw-bold text-center subject">{{ report.ExpireIn1Month }}</div>
              </div>
            </div>
            <div class="col-3 pointer" (click)="viewRequests('Expired')">
              <div class="rr-count pt-1 level-expired">
                <div class="label text-center">Expired</div>
                <div class="fw-bold text-center subject">{{ report.Expired }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
