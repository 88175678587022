import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { IAuditAnswer, IAuditAnswerRequest, IAuditAnswerResponse, IAuditAnswerSubmitRequest } from '../../../services/models/annual-review/annual-review-audit-answer';
import { AnnualReviewDocumentModalComponent } from './annual-review-audit-document-modal/annual-review-document-modal.component';
import { NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../../../services/alert.service';
import { StorageService } from '../../../services/storage.service';
import { AnnualReviewService } from '../../../services/annual-review.service';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { ICompanyReviewResponse } from '../../../services/models/annual-review/annual-review-company-review.model';
import { IApiResponse } from '../../../shared/models/api-reponse.model';
import {AdAuthService} from '../../../core/ad-auth-service/ad-auth.service';
import {IAuthUser} from '../../../services/models/auth.model';

@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.scss']
})
export class AuditComponent implements OnInit {
  // External Variables
  @Input() isAdmin: boolean = false;
  @Input() reviewId: number;
  @Input() companyReviewId: number;
  @Output() OnClose: EventEmitter<boolean> = new EventEmitter();

  // Variables
  public loading: boolean;
  auditQuestions: IAuditAnswerResponse[];
  answers = [] as IAuditAnswer[];
  validateAnswers: boolean;
  hasSavedData = false;
  hasSubmitAudit = false;
  environment = environment;
  // Icons
  faCalendar = faCalendar;
  companyReview: ICompanyReviewResponse;

  constructor(private annualReviewService: AnnualReviewService,
              private modalService: NgbModal,
              private alertService: AlertService,
              private datePipe: DatePipe,
              private sessionStorage: StorageService,
              private authService: AdAuthService) {
  }

  ngOnInit() {
    if (this.isAdmin) {
      this.getAuditQuestions();
    } else {
      if (this.authService.CurrentUser) {
        this.getCompanyAudit(this.authService.CurrentUser.User.CompanyId);
      }

      this.authService.CurrentUser$.subscribe((user: IAuthUser) => {
        this.getCompanyAudit(user.User.CompanyId);
      });
    }
  }

  getCompanyAudit(companyId: number) {
    this.annualReviewService.isAuditMenuItemVisible(companyId)
      .subscribe(data => {
        const auditData = Object.assign(data, {});
        if (auditData) {
          this.reviewId = auditData.ReviewId;
          this.companyReviewId = auditData.CompanyReviewId;
          this.getAuditQuestions();
        } else {
          this.hasSubmitAudit = true;
        }
      });
  }

  getAuditQuestions() {
    this.loading = true;
    this.annualReviewService.getAudit(this.reviewId, this.companyReviewId)
      .subscribe({
        next: (data) => {
          if (data) {
            this.auditQuestions = data.Item2;
            this.companyReview = Object.assign(data.Item1, []);
            this.checkQuestionStatus();
          }
          this.loading = false;
        },
        error: (err: IApiResponse) => {
          this.alertService.error('Error occurred : ' + err?.Meta?.Message);
          this.loading = false;
        }
      });
  }

  openDocumentModal(auditQuestion: IAuditAnswerResponse, index: number) {
    // Open modal to add locations
    const modalRef = this.modalService.open(AnnualReviewDocumentModalComponent, {size: 'lg', centered: true, backdrop: 'static'});
    modalRef.componentInstance.documents = auditQuestion.Documents;
    modalRef.componentInstance.requiredDocumentAmount = auditQuestion.RequiredDocumentAmount;
    modalRef.componentInstance.companyReviewId = this.companyReviewId;
    modalRef.componentInstance.reviewId = this.reviewId;
    modalRef.componentInstance.auditQuestionId = auditQuestion.Id;
    modalRef.componentInstance.questionText = auditQuestion.ReviewQuestion;
    modalRef.componentInstance.uploadRequirement = auditQuestion.Value;
    // On modal close, read the result and apply logic
    modalRef.result.then((result) => {
      if (result.MadeChanges) {
        this.auditQuestions[index].Documents = result.Documents;
        this.checkQuestionStatus();
      }
    });
  }

  changeAnswer(answer: string, answerId: number, auditQuestionId: number, index: number) {
    this.hasSavedData = false;
    answer = answer.trim();
    const indexOfAnswer = this.answers.findIndex(x => x.AuditQuestionId === auditQuestionId);
    if (indexOfAnswer >= 0) {
      // update the answer if it's already added to the list
      this.answers[indexOfAnswer].Value = answer;
    } else {
      this.answers.push({
        Id: answerId,
        Value: answer,
        AuditQuestionId: auditQuestionId
      } as IAuditAnswer);
    }
    // update the value for the validation
    this.auditQuestions[index].Answer = answer;
  }

  setDateAnswer(event: NgbDate, answerId: number, auditQuestionId: number, index: number) {
    const date = new Date(event.year, event.month - 1, event.day);
    const formattedDate = this.datePipe.transform(date, environment.FormattingStandards.ShortDateFormat);
    this.changeAnswer(formattedDate, answerId, auditQuestionId, index);
  }

  submit() {
    if (this.isAnyAnswersInvalid()) {
      this.alertService.warn('Not all questions are completed');
      return;
    }
    // check to see if save is required
    if (!this.hasSavedData) {
      this.save(false);
    }
    const request = {
      CompanyReviewId: this.companyReviewId,
      ReviewId: this.reviewId
    } as IAuditAnswerSubmitRequest;

    this.loading = true;
    this.annualReviewService.submitAudit(request)
      .subscribe(() => {
        this.loading = false;
        this.hasSubmitAudit = true;
        this.sessionStorage.removeSessionItem('auditData');
      });
  }

  checkQuestionStatus(): void {
    this.auditQuestions.forEach(question => {
      if (question.Type === 3) {
        question.Invalid = !(question.Documents && question.Documents.length >= question.RequiredDocumentAmount);
      }
    });
  }

  isAnyAnswersInvalid() {
    this.validateAnswers = true;
    let isAnyInvalid = false;
    this.auditQuestions.forEach(question => {
      if (question.Type === 1 || question.Type === 2) {
        if (!question.Answer || question.Answer === '' || question.Answer === null || question.Answer === undefined) {
          question.Invalid = true;
          isAnyInvalid = true;
        } else {
          question.Invalid = false;
        }
      } else if (question.Type === 3) {
        if (question.Documents && question.Documents.length >= question.RequiredDocumentAmount) {
          question.Invalid = false;
        } else {
          question.Invalid = true;
          isAnyInvalid = true;
        }
      }
    });
    return isAnyInvalid;
  }

  save(showMessage: boolean) {
    if (this.answers && this.answers.length > 0) {
      this.loading = true;
      const request = {
        CompanyReviewId: this.companyReviewId,
        ReviewId: this.reviewId,
        Answers: this.answers
      } as IAuditAnswerRequest;

      this.annualReviewService.upsertAnswers(request)
        .subscribe(() => {
          this.loading = false;
          this.hasSavedData = true;
          this.answers = []; // Reset the answers
          if (showMessage) {
            this.alertService.success('Answers has been saved!');
          }
        });
    } else {
      this.alertService.warn('Answers already up to date');
    }
  }

  close(){
    this.OnClose.emit(false);
  }
}
