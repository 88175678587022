<div class="modal-header">
  <h4 class="modal-title" id="modal-title-edit" *ngIf="member != null">
    {{ this.member.Name }}
  </h4>
  <button type="button" class="btn-close" aria-label="Close" [disabled]="loading" (click)="activeModal.dismiss(false)">

  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-4">
      <div class="row" style="margin-top: 13px;">
        <div class="col-12 fw-bold harmony-primary-text">
          Member Details
        </div>
      </div>
      <hr class="mt-1 mb-1">
      <ng-container *ngIf="!member">
        <app-loader class="mt-2" [Width]="30"></app-loader>
      </ng-container>
      <ng-container *ngIf="member">
        <div class="row mt-2">
          <div class="col-4">
            <b>Member Type</b>
          </div>
          <div class="col-8">
            {{ member.CompanyType ? member.CompanyType.Description : '-' }}
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-4">
            <b>Legal Name</b>
          </div>
          <div class="col-8">
            {{ member.LegalName }}
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-4">
            <b>Group Name</b>
          </div>
          <div class="col-8">
            {{ member.GroupName ? member.GroupName : '-' }}
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-4">
            <b>Country</b>
          </div>
          <div class="col-8">
            {{ member.CountryPhysical ? member.CountryPhysical : '-' }}
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-4">
            <b>Business Central ID</b>
          </div>
          <div class="col-8">
            {{ member.CoProcessId ? member.CoProcessId : '-' }}
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-4">
            <b>Last Modified By</b>
          </div>
          <div class="col-8">
            {{ lastModifiedUser ? lastModifiedUser.DisplayName + ' (' + (member.ModifiedOnDate | date: env.FormattingStandards.ShortDateFormat) + ')' : '-' }}
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-4">
            <b>Start Date</b>
          </div>
          <div class="col-8">
            {{ member.StartDate | date:env.FormattingStandards.ShortDateFormat}}
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-4">
            <b>End Date</b>
          </div>
          <div class="col-8">
            {{ member.EndDate ? (member.EndDate | date:env.FormattingStandards.ShortDateFormat) : '-' }}
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-4">
            <b>Publish Date</b>
          </div>
          <div class="col-8">
            <app-date-picker [identifier]="'startDate'"
                             [showError]="!member.PublishDate"
                             (onChange)="member.PublishDate = $event"
                             [value]="member.PublishDate"
                             [placeholder]="'Select a start date'"></app-date-picker>
          </div>
        </div>
      </ng-container>
      <div class="row mt-3">
        <div class="col-12 fw-bold harmony-primary-text">
          Service Information
        </div>
      </div>
      <hr class="mt-1 mb-1">
      <ng-container *ngIf="!member">
        <app-loader class="mt-2" [Width]="30"></app-loader>
      </ng-container>
      <ng-container *ngIf="member">
        <div class="row mt-2">
          <div class="col-4 fw-bold">
            Moving Provider
          </div>
          <div class="col-8 text-start">
            <app-toggle-button [id]="member.CompanyId.toString() + '_moving'"
                               [checked]="member.IsMovingServiceProvider"
                               (change)="setMovingProvider($event)">
            </app-toggle-button>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-4 fw-bold">
            Relocation Provider
          </div>
          <div class="col-8 text-start">
            <app-toggle-button [id]="member.CompanyId.toString() + '_relocation'"
                               [checked]="member.IsRelocationServiceProvider"
                               (change)="setRelocationProvider($event)">
            </app-toggle-button>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="col-6">
      <ul class="nav nav-tabs" id="companyEditTab" role="tablist">
        <li class="nav-item" role="presentation">
          <a class="nav-link active" id="profile-tab" data-bs-toggle="tab" href="#profile" role="tab"
             aria-controls="profile" aria-selected="true">
            Profile
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a class="nav-link" id="additional-info-tab" data-bs-toggle="tab" href="#additional-info" role="tab"
             aria-controls="additional-info" aria-selected="false">
            Additional Information
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a class="nav-link" id="address-details-tab" data-bs-toggle="tab" href="#address-details" role="tab"
             aria-controls="address-details" aria-selected="false">
            Address Details
          </a>
        </li>
      </ul>
      <!-------------------------------->
      <!------ Tab Content Start ------->
      <!-------------------------------->
      <div class="tab-content" id="companyEditTabContent">
        <!------------------------->
        <!------- Profile --------->
        <!------------------------->
        <div class="tab-pane fade active show mb-2" id="profile" role="tabpanel" aria-labelledby="profile-tab">
          <ng-container *ngIf="!member">
            <app-loader class="mt-2" [Width]="30"></app-loader>
          </ng-container>
          <ng-container *ngIf="member">
            <div class="row mt-2">
              <div class="col-4 text-center">
                <b>Member Logo</b>
                <app-manage-image [Container]="'companyimages'"
                                  [FilePrefix]="member.CompanyId.toString()"
                                  [NewFileName]="member.Name + '_logo'"
                                  [ButtonTitle]="'Change Company Logo'"
                                  [CurrentFileName]="currentLogo"
                                  (OnChange)="onCompanyLogoChange($event)"
                                  (OnDelete)="onCompanyLogoDeleted()"></app-manage-image>
              </div>
              <div class="col-4 text-center">
                <b>Member Picture</b>
                <app-manage-image [Container]="'companyimages'"
                                  [FilePrefix]="member.CompanyId.toString()"
                                  [NewFileName]="member.Name + '_picture'"
                                  [ButtonTitle]="'Change Company Picture'"
                                  [CurrentFileName]="currentPicture"
                                  (OnChange)="onCompanyPictureChange($event)"
                                  (OnDelete)="onCompanyPictureDeleted()"></app-manage-image>
              </div>
              <div class="col-4 text-center">
                <b>Proud Member Logo</b>
                <app-manage-image [Container]="'companyimages'"
                                  [FilePrefix]="member.CompanyId.toString()"
                                  [NewFileName]="member.Name + '_proudMember'"
                                  [ButtonTitle]="'Change Proud Member Logo'"
                                  [CurrentFileName]="currentProud"
                                  (OnChange)="onProudMemberLogoChange($event)"
                                  (OnDelete)="onProudMemberLogoDeleted()"></app-manage-image>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-4 justify-content-center">
                <img *ngIf="member.CompanyLogo" [src]="member.CompanyLogo" alt="company-picture" class="member-image">
                <img *ngIf="!member.CompanyLogo"
                     [src]="env.SiteUrls.AzureStorageBaseURL + env.SiteUrls.ImagePlaceholderLargeUrl"
                     alt="company-logo" class="member-image">
              </div>
              <div class="col-4 justify-content-center">
                <img *ngIf="member.CompanyPicture" [src]="member.CompanyPicture" alt="company-picture"
                     class="member-image">
                <img *ngIf="!member.CompanyPicture"
                     [src]="env.SiteUrls.AzureStorageBaseURL + env.SiteUrls.ImagePlaceholderLargeUrl"
                     alt="company-picture" class="member-image">
              </div>
              <div class="col-4 justify-content-center">
                <img *ngIf="member.ProudMemberLogo" [src]="member.ProudMemberLogo" alt="company-picture"
                     class="member-image">
                <img *ngIf="!member.ProudMemberLogo"
                     [src]="env.SiteUrls.AzureStorageBaseURL + env.SiteUrls.ImagePlaceholderLargeUrl"
                     alt="proud-member-logo" class="member-image">
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12 fw-bold text-center">
                Company profile
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12">
              <textarea class="form-control form-control-sm"
                        [rows]="8"
                        [value]="member.Biography"
                        (change)="member.Biography = $event.target['value']">
              </textarea>
              </div>
            </div>
          </ng-container>
        </div>
        <!------------------------------>
        <!--- Additional Information --->
        <!------------------------------>
        <div class="tab-pane fade mb-2" id="additional-info" role="tabpanel" aria-labelledby="additional-info-tab">
          <div class="details-container pt-2" *ngIf="member">
            <app-company-details [companyId]="member.CompanyId"
                                 [branchId]="null"
                                 [propertyCategory]="'Member'"
                                 [autoSave]="false"
                                 [showInfo]="true"
                                 (OnChange)="updateCompanyDetails = $event"
                                 [viewOnly]="false">
            </app-company-details>
          </div>
        </div>
        <!------------------------------>
        <!---- Address Information ----->
        <!------------------------------>
        <div class="tab-pane fade mb-2" id="address-details" role="tabpanel" aria-labelledby="address-details-tab">
          <ng-container *ngIf="!member">
            <app-loader class="mt-2" [Width]="30"></app-loader>
          </ng-container>
          <ng-container *ngIf="member">
            <div class="row mt-2">
              <div class="col-6">
                <div class="row mt-1">
                  <div class="col-12">
                    <b>Physical</b>
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-12">
                    <small>
                      <p class="mb-0">
                        {{ member?.AddressPhysical?.length > 0 ? member?.AddressPhysical.trim() : '-' }}{{ member?.AddressPhysical2?.length > 0 ? ', ' + member?.AddressPhysical2.trim() : '' }}
                      </p>
                      <p class="mb-0">
                        {{ member?.CityPhysical?.length > 0 ? member?.CityPhysical : '' }}{{ member?.ZipCodePhysical?.length > 0 ? ', ' + member?.ZipCodePhysical : '' }}
                      </p>
                      <p class="mb-0">
                        {{ member?.CountryPhysical ?? ''}} {{ member?.CountryPhysicalIso ? '(' + member?.CountryPhysicalIso + ')' : '' }}
                      </p>
                    </small>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="row mt-1">
                  <div class="col-12">
                    <b>Postal</b>
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-12">
                    <small>
                      <p class="mb-0">
                        {{ member?.AddressPostal?.length > 0 ? member?.AddressPostal.trim() : '-' }}{{ member?.AddressPostal2?.length > 0 ? ', ' + member?.AddressPostal2.trim() : '' }}
                      </p>
                      <p class="mb-0">
                        {{ member?.CityPostal?.length > 0 ? member?.CityPostal : '' }}{{ member?.ZipCodePostal?.length > 0 ? ', ' + member?.ZipCodePostal : '' }}
                      </p>
                      <p class="mb-0">
                        {{ member?.CountryPostal ?? '' }} {{ member?.CountryPostalIso ? '(' + member?.CountryPostalIso + ')' : '' }}
                      </p>
                    </small>
                  </div>
                </div>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-6">
                <b>Longitude</b>
              </div>
              <div class="col-6">
                <input type="text"
                       numbersOnly="3.6"
                       class="form-control form-control-sm"
                       [(ngModel)]="member.Longitude">
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-6">
                <b>Latitude</b>
              </div>
              <div class="col-6">
                <input type="text"
                       numbersOnly="3.6"
                       class="form-control form-control-sm"
                       [(ngModel)]="member.Latitude">
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="col-2 border-start">
      <div class="row" style="margin-top: 13px;">
        <div class="col-8 fw-bold harmony-primary-text">
          Users
        </div>
        <div class="col-4 text-end">
          <button class="btn btn-sm btn-outline-primary harmony-tertiary-outline-button"
                  *ngIf="hasCreatePermission"
                  (click)="openNewUserModal()">
            +
          </button>
        </div>
      </div>
      <hr class="mt-1 mb-1">
      <div class="user-list">
        <ng-container *ngIf="auth.CheckPermissionByCode(PermissionCodes.Admin_User_Management)">
          <div class="row mt-1" *ngFor="let user of users">
            <div class="col-10 overflow-hidden">
              {{ user.Username }}
            </div>
            <div class="col-2 text-start col-compress">
              <fa-icon [icon]="faEdit" class="harmony-tertiary-text pointer" [title]="'Edit user'" (click)="editUser(user.Username)"></fa-icon>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="!auth.CheckPermissionByCode(PermissionCodes.Admin_User_Management)">
          <div class="row mt-1" *ngFor="let user of users">
            <div class="col-12 overflow-hidden">
              {{ user.Username }}
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="!users || users.length <= 0">
          <div class="row">
            <div class="col-12 harmony-grey-text">
              This member has no users.
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <app-loader *ngIf="loading" class="ms-2" [Width]="20"></app-loader>
  <button type="button" class="btn btn-primary harmony-tertiary-button" (click)="save()">Save</button>
  <button type="button" class="btn btn-primary harmony-primary-button" (click)="close()">Close</button>
</div>
