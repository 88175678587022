import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QualityManualComponent } from './quality-manual/quality-manual.component';
import { HqmsMenuComponent } from './hqms-menu/hqms-menu.component';
import { DocumentListComponent } from '../../shared/components/document-list/document-list.component';
import { SharedModule } from '../../shared/shared.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterLink } from '@angular/router';
import { HqmsRoutingModule } from './hqms-routing.module';
import { DocumentCategoryListComponent } from '../../shared/components/document-category-list/document-category-list.component';

@NgModule({
  declarations: [
    QualityManualComponent,
    HqmsMenuComponent
  ],
  exports: [
    HqmsMenuComponent
  ],
  imports: [
      CommonModule,
      DocumentListComponent,
      SharedModule,
      FontAwesomeModule,
      RouterLink,
      HqmsRoutingModule,
      DocumentCategoryListComponent
  ]
})
export class HqmsModule { }
