import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { faEdit, faSortDesc, faSyncAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Subject } from 'rxjs';
import { IModule, IModuleOverview } from '../../../../services/models/auth.model';
import { AdAuthService } from 'src/app/core/ad-auth-service/ad-auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModuleModalComponent } from './module-modal/module-modal.component';
import { OpenConfirmationModal } from '../../../../shared/components/confirmation-modal/confirmation-modal-functions';
import { takeUntil } from 'rxjs/operators';
import { IPage, IPaginationData } from '../../../../shared/models/pagination-data.model';
import { IOrderParam } from '../../../../shared/directives/sort/order.directive';

@Component({
  selector: 'app-module-management',
  templateUrl: './module-management.component.html',
  styleUrls: ['./module-management.component.scss']
})
export class ModuleManagementComponent implements OnInit, OnDestroy {
  // Icons
  protected readonly faEditIcon = faEdit;
  protected readonly faDelete = faTrash;
  protected readonly faRefresh = faSyncAlt;
  protected readonly faSortDown = faSortDesc;

  // Component variables
  paginationData: IPaginationData<IModuleOverview> = null;
  order: IOrderParam;
  page: IPage;
  searchText: string = null;

  // General variables
  private unsubscribe: Subject<any> = new Subject<any>();
  loading: boolean = false;
  environment = environment;

  constructor(public authService: AdAuthService,
              private modalService: NgbModal,
              private ref: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.initPage();
    this.ref.markForCheck();
  }

  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  initPage() {
    this.page = {
      pageNumber: 1,
      pageSize: 30,
      batched: false
    } as IPage;

    this.order = {
      OrderBy: 'Name',
      OrderDirection: 'asc'
    } as IOrderParam;

    this.searchText = null;

    this.searchModules();
  }

  onOrderChange(param: IOrderParam) {
    this.order = param;
    this.search();
  }

  onPageChange($event: IPage) {
    this.page = $event;
    this.searchModules();
  }

  resetSearch() {
    this.initPage();
  }

  search() {
    this.page.pageNumber = 1;
    this.searchModules();
  }

  private searchModules() {
    this.loading = true;

    this.authService.SearchModules(this.page, this.order, this.searchText)
      .subscribe({
        next: (data: IPaginationData<IModuleOverview>) => {
          this.paginationData = Object.assign({}, data);
          this.page.pageNumber = this.paginationData.CurrentPage;
          this.ref.markForCheck();
          this.loading = false;
        }, error: () => {
          this.paginationData = null;
          this.loading = false;
        }
      });
  }

  manageModule(moduleId: number = null, isNew: boolean) {
    // Open modal to Add/Edit a module
    const modalRef = this.modalService.open(ModuleModalComponent, {size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.IsNew = isNew;
    modalRef.componentInstance.ModuleId = moduleId;
    // On modal close, read the result and apply logic
    modalRef.result.then((result: IModule) => {
      if (result) {
        this.authService.LoadModules();
      }
      this.ref.markForCheck();
    }, () => {
      this.ref.markForCheck();
    });
  }

  deleteModule(id: number) {
    const message = 'This action will mark a module and all components linked to that module as inactive.\nWould you like to continue?';
    OpenConfirmationModal(this.modalService, message)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((answer: boolean) => {
        if (answer) {
          this.authService.DeleteModule(id);
        }
      });
  }
}
