import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { faDownload, faNewspaper } from '@fortawesome/free-solid-svg-icons';
import { ArticleService } from '../../../../services/article.service';
import { IArticle, IArticleAttachment } from '../../../../services/models/news/article.model';
import { FileService } from '../../../../services/file.service';
import {
  UserContactModalComponent
} from '../../../network/member-search/member/user-contact-modal/user-contact-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss']
})
export class ArticleComponent implements OnInit {
  @Input() articleId: number = null;
  @Input() article: IArticle = null;

  // Icons
  protected readonly faNews = faNewspaper;
  protected readonly faDownload = faDownload;

  // Component variables
  imageUrl: string = '';
  placeholderImage: string = '';
  attachments: IArticleAttachment[] = [];

  // General variables
  loading: boolean = false;
  env = environment;

  constructor(private sanitizer: DomSanitizer,
              private fileService: FileService,
              private modalService: NgbModal,
              private articleService: ArticleService) {
  }

  ngOnInit() {
    if (this.articleId > 0) {
      this.getArticle();
    } else {
      this.setImageUrl();
    }

    this.placeholderImage = `${this.env.SiteUrls.AzureStorageBaseURL}${environment.SiteUrls.NewsImagePlaceHolderUrl}`;
  }

  initArticle() {
    this.articleId = null;
    this.article = null;
  }

  getArticle() {
    this.loading = true;
    this.articleService.get(this.articleId)
      .subscribe({
        next: (data) => {
          this.article = {...data};
          this.setImageUrl();
          this.getAttachments();
          this.loading = false;
        },
        error: () => {
          this.initArticle();
          this.loading = false;
        }
      });
  }

  getAttachments() {
    this.articleService.getAttachments(this.articleId)
      .subscribe({
        next: (data) => {
          this.loading = false;
          this.attachments = [...data];
          this.article.TotalAttachments = this.attachments?.length ?? 0;
        }, error: () => {
          this.article.TotalAttachments = 0;
          this.loading = false;
        }
      });
  }

  downloadAttachment(reference: string) {
    this.fileService.GetFile(environment.ContainerNames.ArticleAttachment, reference);
  }

  sanitizeArticleContent(article: string) {
    return this.sanitizer.bypassSecurityTrustHtml(article);
  }

  setImageUrl() {
    this.imageUrl = `${environment.SiteUrls.AzureStorageBaseURL}${environment.ContainerNames.ArticleImages}/${this.article.ArticleId}/${this.article.ImageReference}`;
  }

  viewContactCard(userId: number) {
    // Open modal to edit user
    const modalRef = this.modalService.open(UserContactModalComponent, { size: 'md', centered: true, backdrop: 'static' });
    modalRef.componentInstance.UserID = userId;
  }
}
