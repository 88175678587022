<div class="dashboard-background"></div>
<div class="dashboard-wrapper">
  <div class="dashboard-content">
    <!-- Marketing -->
    <div class="three-by-two d-flex align-items-start services-card">
      <div>
        <div class="services-card-header row">
          <div class="col-12">
            <!--Header-->
            <h5 class="harmony-primary-text fw-bold">Marketing</h5>
            <hr class="mt-0 mb-1">
          </div>
        </div>
        <div class="d-flex justify-content-center row mt-2">
          <div class="col-3">
            <div class="justify-content-center m-2">

              <img [src]="env.SiteUrls.AzureStorageBaseURL + env.ContainerNames.WebImagesServices + 'general%20Harmony%20small.png'"
                   class="service-image-rounded-borders"
                   alt="Marketing">

            </div>
          </div>
          <div class="col-9 service-content">
            <p>
              Global marketing of the Harmony brand is important to all Proud Members of our Network. The Harmony brand is developed as a global brand to support our local Member brands; showing our worldwide unity as well as our individual strengths.
            </p>
            <p>
              The Harmony Marketing & Communications Department assists members with branding, PowerPoint presentations, press releases, tradeshows, marketing materials, and intranet support.
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- Marketing Contact -->
    <div class="one-by-one services-card">
      <div class="services-card-header row">
        <div class="col-12">
          <!--Header-->
          <h5 class="harmony-primary-text fw-bold">Marketing Contact</h5>
          <hr class="mt-0 mb-1">
        </div>
      </div>
      <div class="row">
        <div class="col-12 ms-4">
          <div class="row">
            <div class="col-12 service-contact">
              Ms. Vanessa Cremers
            </div>
          </div>
          <div class="row my-1">
            <div class="col-1">
              <fa-icon [icon]="faEnvelope"></fa-icon>
            </div>
            <div class="col-11 nav-item">
              <a href="mailto:v.cremers@harmonyrelo.com?Subject=Request information about Services/Marketing" class="harmony-secondary-link">v.cremers@harmonyrelo.com</a>
            </div>
          </div>
          <div class="row my-1">
            <div class="col-1">
              <fa-icon [icon]="faPhone"></fa-icon>
            </div>
            <div class="col-11">
              <a href="callto:+31 294 461 019" class="harmony-secondary-link">+31 294 461 019</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Marketing Documents -->
    <div class="one-by-one services-card">
      <div class="services-card-header row">
        <div class="col-12">
          <!--Header-->
          <h5 class="harmony-primary-text fw-bold">Marketing Documents</h5>
          <hr class="mt-0 mb-1">
        </div>
      </div>
      <div class="card-body mt-2">
        <div class="col-12 service-content document-list">
          <app-document-list [ModuleName]="'Services'" [CategoryCode]="'SERVMRKT'"  [Recents]="true"></app-document-list>
        </div>
      </div>
    </div>
    <!-- Harmony Branding -->
    <div class="three-by-two d-flex align-items-start services-card">
      <div>
        <div class="services-card-header row">
          <div class="col-12">
            <!--Header-->
            <h5 class="harmony-primary-text fw-bold">Harmony Branding</h5>
            <hr class="mt-0 mb-1">
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-3">
            <div class="justify-content-center m-2">

              <img [src]="env.SiteUrls.AzureStorageBaseURL + env.ContainerNames.WebImagesServices + 'Branding%20picture.png'"
                   class="service-image-rounded-borders"
                   alt="Harmony Branding">

            </div>
          </div>
          <div class="col-9 service-content">
            <p>
              The Harmony branding process can be difficult and time consuming. The Head Office can assist you with the rebranding, e.g. custom made logo toolkit, web branding, PowerPoint template.
            </p>
            <p>
              If you need branding assistance, want to receive Harmony rebranding updates/materials, or if you just want to check your own Harmony rebranding before making the new materials, please contact Vanessa Cremers. At Harmony we know how to incorporate the Harmony brand in line with your own branding.
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- Press -->
    <div class="one-by-one services-card">
      <div class="services-card-header row">
        <div class="col-12">
          <!--Header-->
          <h5 class="harmony-primary-text fw-bold">Press</h5>
          <hr class="mt-0 mb-1">
        </div>
      </div>
      <div class="card-body  mt-2">
        <div class="col-12 service-content document-list">
          <app-document-list [ModuleName]="'Services'" [CategoryCode]="'SERVPRSS'" [Recents]="true"></app-document-list>
        </div>
      </div>
    </div>
    <!-- RES Forum Search -->
    <div class="one-by-one services-card">
      <div class="services-card-header row">
        <div class="col-12">
          <!--Header-->
          <h5 class="harmony-primary-text fw-bold">RES Forum Search</h5>
          <hr class="mt-0 mb-1">
        </div>
      </div>
      <div class="card-body  mt-2">
        <div class="col-12 service-content document-list">
          <app-document-list [ModuleName]="'Marketing'" [CategoryCode]="'SERVRESF'" [Recents]="true"></app-document-list>
        </div>
      </div>
    </div>
    <!-- Corporate Identity Manual Harmony -->
    <div class="three-by-three d-flex align-items-start services-card">
      <div>
        <div class="services-card-header row">
          <div class="col-12">
            <!--Header-->
            <h5 class="harmony-primary-text fw-bold">Corporate Identity Manual Harmony</h5>
            <hr class="mt-0 mb-1">
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-3">
            <div class="justify-content-center m-2">

              <img [src]="env.SiteUrls.AzureStorageBaseURL + env.ContainerNames.WebImagesServices + 'HRN_local-love-PPT.JPG'"
                   class="service-image-rounded-borders"
                   alt="Corporate Identity Manual Harmony">

            </div>
          </div>
          <div class="col-9 service-content">
            <p>
              The Harmony Corporate Identity Manual (CI Manual) can only be found online. It contains all relevant logo information for members and the (mandatory) rules & guidelines concerning Harmony branding throughout the network.
            </p>
            <br>
            <p>
              <span><h5 class="harmony-secondary-text fw-bold">Login Details</h5></span>
              The Manual is available on: Harmony CI Manual<br>
              Login Name: <span class="harmony-secondary-link">Harmonyrelo</span><br>
              Password: <span class="harmony-secondary-link">1@peoplenetwork</span><br>
            </p>
            <p>
              In the CI Manual you can find the following (mandatory) rules & guidelines for incorporating Harmony in your company branding:
            </p>
            <ul class="service-list">
              <li><span class="harmony-secondary-text fw-semibold">Creating your customised Proud Member Logo</span></li>
              <li><span class="harmony-secondary-text fw-semibold">Member Materials (e.g. Business Cards, Email Signatures, Letterheads, Website, Uniforms & Packing Materials)</span></li>
              <li><span class="harmony-secondary-text fw-semibold">Fleet Graphics</span></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- Proud Member Logo Toolkit -->
    <div class="three-by-four d-flex align-items-start services-card">
      <div>
        <div class="services-card-header row">
          <div class="col-12">
            <!--Header-->
            <h5 class="harmony-primary-text fw-bold">Proud Member Logo Toolkit</h5>
            <hr class="mt-0 mb-1">
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-3">
            <div class="justify-content-center m-2">
              <div>
                <img [src]="env.SiteUrls.AzureStorageBaseURL + env.ContainerNames.WebImagesServices + 'HRN_members%20intranet.jpg'"
                     class="service-image-rounded-borders"
                     alt="Proud Member Logo Toolkit">
              </div>
            </div>
          </div>
          <div class="col-9 service-content">
            <p>
              All Harmony Members are required to create their own customised Proud Member Logo Toolkit, with their main corporate identity colour.
              Please note: only one of the member's corporate colours can be used in the Harmony Proud Member logo.
            </p>
            <p>
              The Harmony Marketing & Communications Department assists members with branding, PowerPoint presentations, press releases, tradeshows, marketing materials, and intranet support.
            </p>
            <br>
            <p>
              <span><h5 class="harmony-secondary-text fw-bold">Creating the Proud Member Logo Toolkit</h5></span>
              Please send the login details of the online Harmony CI Manual to your own design company, so they can make the endorsement logo toolkit with your dominant corporate colour.
            </p>
            <p>
              Our designer can make the logo toolkit for you as well, costs are 100 EUR ex VAT. If you want to order the toolkit via our designer, please approve the costs and send the colour codes of your main corporate identity colour (PMS, RGB etc.) to Vanessa Cremers. We will then send you the logo toolkit then asap. The costs will be settled through the netting.
            </p>
            <br>
            <p>
              If you want to order your Harmony Proud Member Logo Toolkit, please send an email with the following information:
            </p>
            <ul class="service-list">
              <li>Ordering of the logo toolkit and approving the 100 EUR ex VAT</li>
              <li>Mention the dominant corporate colour which you want to have incorporated in your Proud member logo</li>
              <li>Include the RGB & PMS colour code</li>
              <li>Include your company logo (.eps or .jpg - format)</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
