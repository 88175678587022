<div class="modal-header">
  <h4 class="modal-title">
    Create a new value mapping
  </h4>
</div>
<div class="modal-body">
  <div class="row harmony-primary-text">
    <div class="col-12 text-center">
      <h5>
        Message direction
      </h5>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-10 offset-1">
      <select class="form-select form-select-sm pointer"
              [disabled]="!(userClients$ | async)"
              [(ngModel)]="selectedClientFlow"
              (change)="setClientData()">
        <option [ngValue]="null" [disabled]="true" *ngIf="canViewAll">Select a message client direction...</option>
        <option *ngFor="let client of (userClients$ | async)"
                [ngValue]="client">
          {{ client.SourceClientName }} ({{client.SourceSystemName}})&nbsp; &DDotrahd; &nbsp;{{ client.TargetClientName }} ({{client.TargetSystemName}})
        </option>
      </select>
    </div>
  </div>
  <div class="row harmony-primary-text mt-2">
    <div class="col-5 text-center">
      <h5>
        Source
      </h5>
    </div>
    <div class="col-5 offset-2 text-center">
      <h5>
        Target
      </h5>
    </div>
  </div>
  <div class="row">
    <div class="col-5">
      <div class="row mt-1">
        <div class="col-12">
          <select class="form-select form-select-sm pointer"
                  [ngClass]="{'is-invalid': invalid && !newMapping.SourceElementId}"
                  [disabled]="!(sourceElements$ | async) || (sourceElements$ | async).length <= 0 || loading"
                  [(ngModel)]="newMapping.SourceElementId"
                  (change)="setSourceElement()">
            <option [ngValue]="null" [disabled]="true">Select...</option>
            <option *ngFor="let element of (sourceElements$ | async)"
                    [ngValue]="element.ElementId">
              {{ element.ElementPath }}
            </option>
          </select>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-10">
          <ng-container *ngIf="newSourceValue">
            <input class="form-control form-control-sm"
                   id="sourceValue"
                   name="sourceValue"
                   [placeholder]="'Enter a source value...'"
                   [ngClass]="{'is-invalid': invalid && (newMapping.SourceValue == null || newMapping.SourceValue.length <= 0)}"
                   [(ngModel)]="newMapping.SourceValue">
          </ng-container>
          <ng-container *ngIf="!newSourceValue">
            <select class="form-select form-select-sm"
                    [(ngModel)]="selectedSourceValue"
                    [ngClass]="{'is-invalid': invalid && !newMapping.SourceValue}"
                    (change)="setSourceValue()"
                    [disabled]="!(sourceValues$ | async) || (sourceValues$ | async).length <= 0 || loading">
              <option [ngValue]="null"
                      *ngIf="newMapping.SourceElementId && (sourceValues$ | async) && (sourceValues$ | async).length > 0"
                      [disabled]="true">
                Select source value...
              </option>
              <option [ngValue]="null"
                      *ngIf="newMapping.SourceElementId && (sourceValues$ | async) && (sourceValues$ | async).length <= 0"
                      [disabled]="true">
                No values found for element...
              </option>
              <option *ngFor="let value of (sourceValues$ | async)"
                      [ngValue]="value">
                {{ value.Value }}
              </option>
            </select>
          </ng-container>
        </div>
        <div class="col-2 align-self-center">
          <div class="form-check form-switch form-switch-sm mb-0">
            <input class="form-check-input"
                   type="checkbox"
                   role="switch"
                   [id]="'switch-new-source'"
                   [checked]="newSourceValue"
                   [title]="'New source value?'"
                   (change)="toggleNewSourceValue($event)">
          </div>
        </div>
      </div>
    </div>
    <div class="col-2 text-center align-self-center harmony-secondary-text">
      <fa-icon [icon]="faDirection" [size]="'2x'"></fa-icon>
    </div>
    <div class="col-5">
      <div class="row mt-1">
        <div class="col-12">
          <select class="form-select form-select-sm pointer"
                  [ngClass]="{'is-invalid': invalid && !newMapping.TargetElementId}"
                  [disabled]="!(targetElements$ | async) || (targetElements$ | async).length <= 0 || loading"
                  (change)="loadTargetValues()"
                  [(ngModel)]="newMapping.TargetElementId">
            <option [ngValue]="null" [disabled]="true">Select...</option>
            <option *ngFor="let element of (targetElements$ | async)"
                    [ngValue]="element.TargetElementId">
              {{ element.TargetElementPath }}
            </option>
          </select>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-12">
          <input class="form-control form-control-sm"
                 id="targetValue"
                 name="targetValue"
                 [placeholder]="'Enter a target value...'"
                 [ngClass]="{'is-invalid': invalid && (newMapping.TargetValue == null || newMapping.TargetValue.length <= 0)}"
                 [(ngModel)]="newMapping.TargetValue">
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <app-loader *ngIf="loading" class="ms-2" [Width]="20"></app-loader>
  <button type="button" class="btn btn-primary harmony-tertiary-button" (click)="save()">Save</button>
  <button type="button" class="btn btn-primary harmony-primary-button" (click)="close()">Close</button>
</div>
