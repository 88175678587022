import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { AdAuthService } from '../../../../core/ad-auth-service/ad-auth.service';

@Component({
  selector: 'dashboard-card-hse-document-list',
  templateUrl: './dashboard-card-hse-document-list.component.html',
  styleUrls: ['./dashboard-card-hse-document-list.component.scss'],
  host: {'[class.one-by-one]': '(column <= 1 || column > 3) && (row <= 1 || row > 4)',
    '[class.two-by-one]': 'column == 2 && row == 1',
    '[class.three-by-one]': 'column == 3 && row == 1',
    '[class.two-by-two]': 'column == 2 && row == 2',
    '[class.one-by-two]': 'column == 1 && row == 2',
    '[class.one-by-three]': 'column == 1 && row == 3',
    '[class.one-by-four]': 'column == 1 && row == 4',
    '[class.one-by-five]': 'column == 1 && row == 5',
    '[class.one-by-six]': 'column == 1 && row == 6'}
})
export class DashboardCardHseDocumentListComponent implements OnInit {
  // Inputs
  @Input() column = 1;
  @Input() row = 1;

  // Icons
  faLink = faLink;

  // Component variables
  companyId: number = null;

  // General variables
  loading: boolean = false;

  constructor(public authService: AdAuthService,
              private router: Router) { }

  ngOnInit(): void {
    if (this.authService.CurrentUser) {
      this.companyId = this.authService.CurrentUser.User.CompanyId;
    }

    this.authService.CurrentUser$.subscribe((data) => {
      this.companyId = data.User.CompanyId;
    });
  }

  viewDocuments(){
    this.router.navigate(['hse/documents']).then(() => {});
  }
}
