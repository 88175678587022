import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject } from 'rxjs';
import { ApiService } from '../../../../services/api.service';
import { takeUntil } from 'rxjs/operators';
import { AlertService } from '../../../../services/alert.service';
import { PricingService } from '../../../../services/pricing.service';
import {
  faExclamationCircle,
  faPlus,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import {
  IProject,
  IRateGroupCheckResult,
  IRateGroup,
  IRateGroupServiceDto,
  IServiceRateDto
} from '../../../../services/models/pricing/rates.model';
import { AdAuthService } from '../../../../core/ad-auth-service/ad-auth.service';
import { PermissionCodes } from '../../../../core/constants/permission-codes';
import { environment } from 'src/environments/environment';
import { OpenConfirmationModal } from '../../../../shared/components/confirmation-modal/confirmation-modal-functions';
import {ICompanySearchResult} from '../../../../services/models/member.model';
import {ICityDto} from '../../../../services/models/pricing/location.model';

@Component({
  selector: 'app-rate-group-edit',
  templateUrl: './rate-group-edit-modal.component.html',
  styleUrls: ['./rate-group-edit-modal.component.scss']
})
export class RateGroupEditModalComponent implements OnInit {
  // Icons
  protected readonly faPlus = faPlus;
  protected readonly faDelete = faTrash;
  protected readonly faInfo = faCircleQuestion;
  protected readonly faRequired = faExclamationCircle;

  // Component variables
  public RateGroupID: number = null;
  rateGroup: IRateGroup;
  rgCheckResult: IRateGroupCheckResult[] = [];
  disableOriginPort: boolean = false;
  disableDestinationPort: boolean = false;
  closeResult: boolean = false;
  invalid: boolean = false;
  today: any = null;
  minValidTo: any = null;

  // Lookup data
  currencies$ = this.pricingService.Currencies$.asObservable();
  unitTypes$ = this.pricingService.UnitTypes$.asObservable();
  rateLimitTypes$ = this.pricingService.RateLimitTypes$.asObservable();
  calculationMethods$ = this.pricingService.CalculationMethods$.asObservable();
  serviceTypes$ = this.pricingService.ServiceTypes$.asObservable();
  rateVerdicts$ = this.pricingService.RateVerdicts$.asObservable();
  rateSources$ = this.pricingService.RateSources$.asObservable();
  modalityTypes$ = this.pricingService.ModalityTypes$.asObservable();
  projects$ = this.pricingService.Projects$.asObservable();
  companyProjects$: Observable<IProject[]>;

  // Permissions
  isRateAdmin: boolean = false;

  // General variables
  public readonly PermissionCodes = PermissionCodes;
  private unsubscribe: Subject<any> = new Subject<any>();
  loading: boolean = false;
  environment = environment;

  constructor(public activeModal: NgbActiveModal,
              public pricingService: PricingService,
              public authService: AdAuthService,
              private alertService: AlertService,
              private modalService: NgbModal,
              private api: ApiService) { }

  ngOnInit() {
    const now = new Date();
    this.today = {
      year: now.getFullYear(),
      month: now.getMonth() + 1,
      day: now.getDate()
    } as any;
    if (this.authService.CurrentUser) {
      this.isRateAdmin = this.authService.CheckPermissionByCode(PermissionCodes.Pricing_RateGroup_Admin);
      this.initComponent();
    }
    this.authService.CurrentUser$.subscribe(() => {
      this.isRateAdmin = this.authService.CheckPermissionByCode(PermissionCodes.Pricing_RateGroup_Admin);
      this.initComponent();
    });
  }

  initComponent() {
    const companyId = this.authService.CurrentUser.User.CompanyId;
    if (companyId != null && companyId > 0) {
      if (!this.isRateAdmin) {
        this.getProjects(companyId);
        this.rateGroup.AgentCompanyID = this.authService.CurrentUser.User.CompanyId;
        this.rateGroup.AgentCompanyName = this.authService.CurrentUser.User.Company.Name;
      }
    }
  }

  // FOR EDIT: Set the RateGroupID then call this function when creating the modal instance.
  // e.g.
  // modalRef.componentInstance.RateGroupID = rateGroupId;
  // modalRef.componentInstance.LoadRateGroup();
  public LoadRateGroup() {
    this.loading = true;

    this.api.get('RateGroup/Single/' + this.RateGroupID).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data) => {
        if (data) {
          this.rateGroup = Object.assign({} as IRateGroup, data);
          // Check the RG setup for any issues/comments
          this.checkRateGroup();
          this.changeValidFromDate(this.rateGroup.ValidFrom);
          // Enable/Disable ports according to request type
          this.disableOriginPort = (
            this.rateGroup.ModalityTypeID === null ||
            this.rateGroup.ModalityTypeID <= 0 ||
            this.rateGroup.ModalityTypeID > 3 ||
            (this.rateGroup.RateRequestTypeName != null && !this.rateGroup.RateRequestTypeName.includes('Origin'))
          );
          this.disableDestinationPort = (
            this.rateGroup.ModalityTypeID === null ||
            this.rateGroup.ModalityTypeID <= 0 ||
            this.rateGroup.ModalityTypeID > 3 ||
            (this.rateGroup.RateRequestTypeName != null && !this.rateGroup.RateRequestTypeName.includes('Destination'))
          );
        }
        this.loading = false;
      },
      error: () => {
        this.alertService.error('Unable to retrieve data for rate group ID: ' + this.RateGroupID);
        this.loading = false;
      }
    });
  }

  // TO CREATE: Set the RateGroupID to NULL then call this function when creating the modal instance.
  // e.g.
  // modalRef.componentInstance.RateGroupID = null;
  // modalRef.componentInstance.NewRateGroup();
  public NewRateGroup() {
    this.rateGroup = this.getNewRateGroup();
    this.newRateGroupService();
  }

  getProjects(companyId: number) {
    this.companyProjects$ = this.api.get(`RateGroup/CompanyRateGroupProjects/${companyId}`).pipe(
      takeUntil(this.unsubscribe)
    );

    this.companyProjects$.subscribe({
      next: (data) => {
        if (data && data.length > 0) {
          this.rateGroup.ProjectID = data[0].Id;
        }
      },
      error: () => { }
    });
  }

  identify(index, item) {
    return item;
  }

  changeAgent(company: ICompanySearchResult) {
    if (company) {
      this.rateGroup.AgentCompanyID = company.CompanyId;
      this.rateGroup.AgentCompanyName = company.Name;
    }
  }

  changeValidFromDate(date: string) {
    this.rateGroup.ValidFrom = date;
    const fromDate = new Date(date);
    this.minValidTo = {
      year: fromDate.getFullYear(),
      month: fromDate.getMonth() + 1,
      day: fromDate.getDate() + 1
    } as any;
  }

  setOriginCity(city: ICityDto) {
    if (city && city.Id != null) {
      this.rateGroup.OriginCityID = city.Id;
      this.rateGroup.OriginCityName = city.Name;
      this.rateGroup.OriginStateID = city.StateId;
      this.rateGroup.OriginStateName = null;
      this.rateGroup.OriginCountryID = city.CountryId;
      this.rateGroup.OriginCountryName = null;
    }
  }

  setDestinationCity(city: ICityDto) {
    if (city && city.Id != null) {
      this.rateGroup.DestinationCityID = city.Id;
      this.rateGroup.DestinationCityName = city.Name;
      this.rateGroup.DestinationStateID = city.StateId;
      this.rateGroup.DestinationStateName = null;
      this.rateGroup.DestinationCountryID = city.CountryId;
      this.rateGroup.DestinationCountryName = null;
    }
  }

  removeRateGroupService(rateGroupService: IRateGroupServiceDto) {
    if (rateGroupService) {
      const message = 'Are you sure you want to de-activate this service and it\'s accompanying rates?';
      OpenConfirmationModal(this.modalService, message)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((answer: boolean) => {
          if (answer === true) {
            rateGroupService.Active = false;
            rateGroupService.ServiceRates.forEach((s) => s.Active = false);
          }
        });
    }
  }

  enableRateGroupService(rateGroupService: IRateGroupServiceDto) {
    if (rateGroupService) {
      rateGroupService.Active = true;
      rateGroupService.ServiceRates.forEach((s) => s.Active = true);
    }
  }

  removeServiceRate(serviceRate: IServiceRateDto) {
    if (serviceRate) {
      const message = 'Are you sure you want to de-activate this rate?';
      OpenConfirmationModal(this.modalService, message)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((answer: boolean) => {
          if (answer === true) {
            serviceRate.Active = false;
          }
        });
    }
  }

  activateServiceRate(serviceRate: IServiceRateDto) {
    if (serviceRate) {
      const message = 'Are you sure you want to re-activate this rate?';
      OpenConfirmationModal(this.modalService, message)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((answer: boolean) => {
          if (answer === true) {
            serviceRate.Active = true;
          }
        });
    }
  }

  canSave() {
    let servicesInvalid: boolean = false;
    let rateGroupInvalid: boolean = false;
    const rg = this.rateGroup;

    if (rg && rg.RateGroupServices?.length > 0) {
      const invalidRgs = rg.RateGroupServices.filter((rgs) => rgs.ServiceID == null);
      const invalidSr = rg.RateGroupServices.filter((rgs) =>
        rgs.ServiceRates.filter((sr) => sr.Active === true && (sr.RateLimitTypeID == null || sr.Rate == null || sr.CurrencyID == null)).length > 0
      );
      servicesInvalid = ((invalidRgs && invalidRgs.length > 0) || (invalidSr && invalidSr.length > 0));
    }

    if (rg && (
          rg.ProjectID == null ||
          rg.RateSourceID == null ||
          rg.RateBuySellTypeID == null ||
          rg.RateVerdictID == null ||
          rg.ValidFrom == null ||
          (
            rg.ModalityTypeID != null &&
            rg.OriginCityID == null &&
            rg.DestinationCityID == null &&
            rg.DeparturePortID == null &&
            rg.ArrivalPortID == null
          ))
    ) {
      rateGroupInvalid = true;
    }

    this.invalid = servicesInvalid || rateGroupInvalid;
    return !this.invalid;
  }

  save() {
    if (this.canSave()) {
      this.loading = true;
      this.pricingService.UpsertRateGroup(this.rateGroup).subscribe({
        next: (data) => {
          if (data) {
            this.rateGroup = Object.assign({}, data);
            this.alertService.success(' Rate group successfully updated!');
            // Check the RG setup for any issues/comments
            this.checkRateGroup();
            this.closeResult = true;
          }
          this.loading = false;
        },
        error: () => {
          this.alertService.error('Unable to update data for rate group ID: ' + this.rateGroup.ID);
          this.loading = false;
        }
      });
    }
  }

  checkRateGroup() {
    this.loading = true;

    this.pricingService.CheckRateGroup(this.rateGroup.ID).subscribe({
      next: (data: IRateGroupCheckResult[]) => {
        if (data && data.length > 0) {
          this.rgCheckResult = Object.assign([], data);
          this.rgCheckResult.forEach(result => {
            const rgs = this.rateGroup.RateGroupServices.find(x => x.ID === result.RateGroupServiceId);
            rgs.Comment = rgs.Comment ?? '';
            rgs.Comment += result.Comment + '\n';
          });
          this.alertService.warn('Validation concerns spotted, please review rates.');
        }
        this.loading = false;
      },
      error: () => {
        this.alertService.error('Unable to update data for rate group ID: ' + this.rateGroup.ID);
        this.loading = false;
      }
    });
  }

  close() {
    this.activeModal.dismiss();
  }

  cancel() {
    this.activeModal.close(this.closeResult);
  }

  getNewRateGroup() {
    return {
      ID: null,
      ModalityTypeID: null,
      ModalityTypeName: null,
      Description:  null,
      ProjectID: null,
      ProjectName: null,
      AgentCompanyID: null,
      AgentCompanyName: null,
      RateRequestID: null, /* FK to RateRequest */
      RateRequestTypeName: null,
      RateBuySellTypeID: null, /* FK to RateTypeBuySell */
      RateSourceID: null, /* FK to RateSource */
      RateVerdictID: null, /* FK to RateVerdict */
      OriginCityID: null,
      OriginCityName: null,
      OriginStateID: null,
      OriginStateName: null,
      OriginCountryID: null,
      OriginCountryName: null,
      OriginLocationTypeID: null, /* FK to LocationType */
      DeparturePortID: null, /* FK to CityFunction with FunctionType: Port */
      DeparturePortCode: null,
      DeparturePortDescription: null,
      ArrivalPortID: null, /* FK to CityFunction with FunctionType: Port */
      ArrivalPortCode: null,
      ArrivalPortDescription: null,
      DestinationCityID: null,
      DestinationCityName: null,
      DestinationStateID: null,
      DestinationStateName: null,
      DestinationCountryID: null,
      DestinationCountryName: null,
      SubmissionDate: null,
      ValidFrom: null,
      ValidTo: null,
      Comment: null,
      ServiceCount:  0,
      RateGroupServices: [],
      RateGroupInclusions: []
    } as IRateGroup;
  }

  newRateGroupService() {
    const newRateGroupService = {
      ID: null,
      DateCreated: new Date(),
      DateModified: new Date(),
      Active: true,
      RateGroupID: this.rateGroup.ID,
      ServiceID: null,
      ServiceName: null,
      CalculationMethodID: null,
      CalculationMethodName: null,
      ServiceLocationID: null,
      ServiceRates: []
    } as IRateGroupServiceDto;

    this.rateGroup.RateGroupServices.push(newRateGroupService);
    this.newServiceRate(newRateGroupService);
  }

  newServiceRate(rateGroupService: IRateGroupServiceDto) {
    const newServiceRate = {
      ID: null,
      DateCreated: new Date(),
      DateModified: new Date(),
      Active: true,
      RateGroupServiceID: rateGroupService.ID,
      RateLimitTypeID: null,
      CurrencyID: null,
      Rate: null,
      RateUnitTypeID_1: null,
      RateQuantity_1: null,
      MeasurementUnitTypeID_1: null,
      RateUnitTypeID_2: null,
      RateQuantity_2: null,
      MeasurementUnitTypeID_2: null
    } as IServiceRateDto;

    rateGroupService.ServiceRates.push(newServiceRate);
  }
}
