<div id="events-accordion">
  <div class="card portal-menu-collapse-card">
    <a [routerLink]="['events/dashboard']">
      <div class="portal-menu-collapse-header bg-dark" id="headingEvents" data-bs-toggle="collapse" data-bs-target="#collapseEvents"
           aria-expanded="true" aria-controls="collapseEvents">
        <h5 class="portal-menu-heading p-2 mb-0 text-muted">
          <span class="heading-icon icon-color">
            <fa-icon [icon]="faEvents"></fa-icon>
          </span>
          <span class="portal-menu-module-name"
                [ngClass]="{'harmony-teal-text': router.url.includes('events/')}">
            Events
          </span>
        </h5>
      </div>
    </a>
    <div id="collapseEvents" class="collapse" aria-labelledby="headingEvents" data-bs-parent="#events-accordion">
      <div class="portal-menu-collapse-body">
        <nav class="col-md-12 d-none d-md-block ps-4">
          <ul class="nav flex-column mb-0">
            <li class="nav-item" *ngIf="dashboard">
              <a class="nav-link pointer"
                 [routerLink]="['events/dashboard']"
                 [ngClass]="{'active': router.url.endsWith('events/dashboard')}">
                <fa-icon class="link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Dashboard
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</div>
