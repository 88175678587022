<!---------------------->
<!------- Header ------->
<!---------------------->
<div class="dangerous-substance">
  <div
    class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom sticky-header">
    <h1 class="portal-component-header">
      Dangerous Substance Register
    </h1>
    <div class="justify-content-end">
      <button class="btn btn-sm btn-primary harmony-teal-outline-button me-1"
              [disabled]="loading" (click)="openInfoModal()">
        Substance Information
      </button>
      <button class="btn btn-sm btn-primary harmony-purple-outline-button" *ngIf="canEditOrAdd"
              [disabled]="loading" (click)="openSubstanceModal(true, null, this.companyId)">
        New Substance
      </button>
    </div>
  </div>
  <!----------------------->
  <!------- Filters ------->
  <!----------------------->
  <div class="row">
    <div class="col-3" *ngIf="canFilterCompany">
      <select class="form-select form-select-sm pointer"
              [(ngModel)]="companyId" (ngModelChange)="getBranches()"
              [disabled]="loading || !(companies$ | async)">
        <option [ngValue]="null" selected>All companies...</option>
        <option *ngFor="let company of (companies$ | async)"
                [ngValue]="company.CompanyId">{{ company.CompanyName }}</option>
      </select>
    </div>
    <div class="col-3" *ngIf="(!canFilterCompany && branches.length > 0) || canFilterCompany">
      <select class="form-select form-select-sm pointer"
              [(ngModel)]="branch"
              [disabled]="loading || !companyId">
        <option [ngValue]="null" selected>All branches...</option>
        <option *ngFor="let branch of branches"
                [ngValue]="branch">{{ branch.Name }}</option>
      </select>
    </div>
    <div class="col-2" [ngClass]="canFilterCompany ? 'offset-4' : 'offset-7'" *ngIf="(!canFilterCompany && branches.length > 0) || canFilterCompany">
      <button class="btn btn-sm btn-primary harmony-purple-button w-100 float-end"
              [disabled]="!companyId && !canFilterCompany" (click)="searchSubstances()">
        Search
      </button>
    </div>
  </div>
  <hr class="mt-2 mb-0">
  <!----------------------->
  <!------- Results ------->
  <!----------------------->
  <!-- Pagination -->
  <div class="row ms-1 me-1">
    <div class="col-12">
      <app-pagination [showPageNumber]=true
                      [showRowsPerPage]=true
                      [paginationData]=paginationData
                      (pageEvent)="onPageChange($event)"
                      [name]="'DGOverview'"
                      [page]="page">
      </app-pagination>
    </div>
  </div>
  <div class="row mt-1 header-row">
    <div class="col-2 header-title border-split-left">
      Company
    </div>
    <div class="col-1 header-title">
      Quantity
    </div>
    <div class="col-2 header-title">
      Product
    </div>
    <div class="col-2 header-title">
      Substance
    </div>
    <div class="col-2 header-title">
      Supplier
    </div>
    <div class="col-2 header-title">
      Emergency Contact
    </div>
    <div class="col-1 header-title border-split-right text-center">
    </div>
  </div>
  <app-loader *ngIf="loading"></app-loader>
  <app-no-result [showNoResults]="noResult"></app-no-result>
  <!-- Table Data -->
  <div class="row result-row" *ngFor='let s of substances; let i = index'>
    <div class="col-2 overflow-hidden">
      <b>{{ s.CompanyName ?? '-' }}</b>
    </div>
    <div class="col-1 overflow-hidden">
      {{ s.Quantity ?? '-'}}
    </div>
    <div class="col-2 overflow-hidden">
      {{ s.Product?.length > 0 ? s.Product : '-'}}
    </div>
    <div class="col-2 overflow-hidden">
      {{ s.Substance?.length > 0 ? s.Substance : '-'}}
    </div>
    <div class="col-2 overflow-hidden">
      {{ s.Supplier?.length > 0 ? s.Supplier : '-'}}
    </div>
    <div class="col-2 overflow-hidden">
      <small>{{ s.EmergencyNumber?.length > 0 ? s.EmergencyNumber : '-'}}</small>
    </div>
    <div class="col-1 text-end pe-0">
      <button class="btn btn-sm btn-primary harmony-teal-outline-button me-1"
              [title]="'View or edit substances'" (click)="openSubstanceModal(false, s.Id, s.CompanyId)">
        <fa-icon *ngIf="canEditOrAdd" [icon]="faEditIcon"></fa-icon>
        <fa-icon *ngIf="!canEditOrAdd" [icon]="faView"></fa-icon>
      </button>
      <button class="btn btn-sm btn-primary harmony-teal-outline-button me-1"
              [title]="'Print the substance chart'" (click)="printSafetyChart(s.Id)">
        <fa-icon [icon]="faPrint"></fa-icon>
      </button>
      <button *ngIf="canDelete" class="btn btn-sm btn-outline-danger me-0"
              [title]="'Delete substances'" (click)="deleteDangerousSubstance(s.Id, i)">
        <fa-icon [icon]="faDelete"></fa-icon>
      </button>
    </div>
  </div>
</div>
<div class="safety-chart">
  <app-safety-chart [substance]="selectedSubstance"></app-safety-chart>
</div>
