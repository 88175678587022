<!---------------------->
<!------- Header ------->
<!---------------------->
<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom sticky-header">
  <h1 class="portal-component-header">
    Job Inspections
  </h1>
  <div>
    <button class="btn btn-sm btn-primary harmony-primary-outline-button me-1"
            [disabled]="loading || searchParam?.CompanyId == null"
            *ngIf="canConfigure"
            (click)="openConfigModal()">
      Configure
    </button>
    <button class="btn btn-sm btn-primary harmony-tertiary-outline-button"
            [disabled]="loading"
            (click)="newJobInspection()">
      + New Inspection
    </button>
  </div>
</div>
<!------------------------------->
<!--- Searchbar with filters ---->
<!------------------------------->
<ng-container *ngIf="searchParam">
  <div class="row mt-1">
    <div class="col-2">
      <select class="form-select form-select-sm pointer"
              [(ngModel)]="searchParam.InspectionTypeId"
              [disabled]="loading || !(jobInspectionTypes$ | async)">
        <option [ngValue]="null" selected>All types</option>
        <option *ngFor="let type of (jobInspectionTypes$ | async)" [ngValue]="type.Id">{{ type.Name }}</option>
      </select>
    </div>
    <div class="col-2">
      <select class="form-select form-select-sm pointer"
              [(ngModel)]="searchParam.StatusId"
              [disabled]="loading || !(jobInspectionStatuses$ | async)">
        <option [ngValue]="null" selected>All statuses</option>
        <option *ngFor="let status of (jobInspectionStatuses$ | async)" [ngValue]="status.Id">{{ status.Name }}</option>
      </select>
    </div>
  </div>
  <hr class="mt-2 mb-2">
  <div class="row">
    <div class="col-3">
      <input id="searchText"
             type="text"
             class="form-control form-control-sm w-100"
             placeholder="Search job inspections..."
             [(ngModel)]="searchParam.SearchText"
             (keyup.enter)="searchJobInspections()"/>
    </div>
    <div class="col-2 align-self-center">
      <ng-container *ngIf="companyFilterAll; else nonAdminCompany">
        <select class="form-select form-select-sm pointer"
                [(ngModel)]="searchParam.CompanyId"
                [disabled]="loading || !(companies$ | async)">
          <option [ngValue]="null" selected>All companies</option>
          <option *ngFor="let company of (companies$ | async)" [ngValue]="company.CompanyId">{{ company.CompanyName }}</option>
        </select>
      </ng-container>
      <ng-template #nonAdminCompany>
        <span class="fw-semibold">
          {{ authService.CurrentUser ? authService.CurrentUser.User.Company.Name : '-' }}
        </span>
      </ng-template>
    </div>
    <div class="col-4 offset-3">
      <div class="row justify-content-end me-1">
        <div class="col-2 pe-1 text-end">
          <button class="btn btn-sm btn-primary harmony-primary-outline-button ms-1"
                  [title]="'Reset Search'"
                  (click)="initPage()">
            Reset
          </button>
        </div>
        <div class="col-4 p-0">
          <button class="btn btn-sm btn-primary harmony-tertiary-button w-100"
                  style="bottom: 0; right: 1.5em;"
                  (click)="searchJobInspections()">
            Search
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<hr class="mt-2 mb-0">
<!--------------------->
<!---- Result table --->
<!--------------------->
<ng-container *ngIf="paginationData">
  <div class="row ms-1 me-1 mb-2">
    <div class="col-12">
      <app-pagination [showPageNumber]=true
                      [showRowsPerPage]=true
                      [paginationData]=paginationData
                      (pageEvent)="onPage($event)"
                      [name]="'IncidentOverview'"
                      [page]="page">
      </app-pagination>
    </div>
  </div>
  <div class="row ms-1 me-1 mt-1 header-row">
    <div class="col-1 col-compress pointer header-title border-split-left"
         (appOrder)="orderSearch($event)"
         data-column="Id"
         data-order="asc">
      Id
      <fa-icon [hidden]="orderParam.OrderBy != 'Id'" [icon]="faSortDesc" style="bottom: -3px;" class="ms-2 position-relative"></fa-icon>
    </div>
    <div class="col-2 col-compress pointer header-title"
         (appOrder)="orderSearch($event)"
         data-column="Company.Name"
         data-order="asc">
      Company
      <fa-icon [hidden]="orderParam.OrderBy != 'Company.Name'" class="ms-2 position-relative"></fa-icon>
    </div>
    <div class="col-1 col-compress pointer header-title"
         (appOrder)="orderSearch($event)"
         data-column="FileNo"
         data-order="asc">
      File #
      <fa-icon [hidden]="orderParam.OrderBy != 'FileNo'" class="ms-2 position-relative"></fa-icon>
    </div>
    <div class="col-2 col-compress pointer header-title"
         (appOrder)="orderSearch($event)"
         data-column="Location"
         data-order="asc">
      Location
      <fa-icon [hidden]="orderParam.OrderBy != 'Location'" class="ms-2 position-relative"></fa-icon>
    </div>
    <div class="col-1 col-compress pointer header-title"
         (appOrder)="orderSearch($event)"
         data-column="InspectionDate"
         data-order="asc">
      Date
      <fa-icon [hidden]="orderParam.OrderBy != 'InspectionDate'" class="ms-2 position-relative"></fa-icon>
    </div>
    <div class="col-1 col-compress pointer header-title"
         (appOrder)="orderSearch($event)"
         data-column="JobInspectionType.Name"
         data-order="asc">
      Type
      <fa-icon [hidden]="orderParam.OrderBy != 'JobInspectionType.Name'" class="ms-2 position-relative"></fa-icon>
    </div>
    <div class="col-1 col-compress pointer header-title"
         (appOrder)="orderSearch($event)"
         data-column="JobInspectionStatus.Name"
         data-order="asc">
      Status
      <fa-icon [hidden]="orderParam.OrderBy != 'JobInspectionStatus.Name'" class="ms-2 position-relative"></fa-icon>
    </div>
    <div class="col-1 col-compress pointer header-title"
         (appOrder)="orderSearch($event)"
         data-column="DateCreated"
         data-order="asc">
      Created On
      <fa-icon [hidden]="orderParam.OrderBy != 'DateCreated'" class="ms-2 position-relative"></fa-icon>
    </div>
    <div class="col-1 col-compress pointer header-title"
         (appOrder)="orderSearch($event)"
         data-column="CreatedByUser.DisplayName"
         data-order="asc">
      Created By
      <fa-icon [hidden]="orderParam.OrderBy != 'CreatedByUser.DisplayName'" class="ms-2 position-relative"></fa-icon>
    </div>
    <div class="col-1 col-compress header-title border-split-right">
    </div>
  </div>
  <!--------------------->
  <!---- Grid table --->
  <!--------------------->
  <div class="row result-row ms-1 me-1" *ngFor="let job of paginationData.DataSet; let i = index">
    <div class="col-1 col-compress overflow-hidden">
      {{ job.Id }}
    </div>
    <div class="col-2 col-compress overflow-hidden" [title]="job.CompanyName">
      {{ job.CompanyName ?? '-' }}
    </div>
    <div class="col-1 col-compress overflow-hidden">
      {{ job.FileNo ?? '-' }}
    </div>
    <div class="col-2 col-compress overflow-hidden">
      {{ job.Location ?? '-' }}
    </div>
    <div class="col-1 col-compress overflow-hidden">
      <small>{{ job.InspectionDate | date: environment.FormattingStandards.ShortDateFormat }}</small>
    </div>
    <div class="col-1 col-compress overflow-hidden" [title]="job.InspectionTypeName">
      {{ job.InspectionTypeName ?? '-' }}
    </div>
    <div class="col-1 col-compress overflow-hidden" [title]="job.Status">
      {{ job.Status ?? '-' }}
    </div>
    <div class="col-1 col-compress overflow-hidden" [title]="job.DateCreated">
      <small>{{ job.DateCreated | date: environment.FormattingStandards.ShortDateFormat }}</small>
    </div>
    <div class="col-1 col-compress overflow-hidden" [title]="job.CreatedByUserName">
      <small>{{ job.CreatedByUserName ?? '-' }}</small>
    </div>
    <div class="col-1 pe-0 me-0 text-end">
      <button class="btn btn-sm btn-primary harmony-primary-outline-button" data-icon="fa-pen"
              [disabled]="loading"
              (click)="openJobInspection(job.Id)">
        <fa-icon [icon]="faEditIcon"></fa-icon>
      </button>
        <button class="btn btn-sm btn-outline-danger ms-2"
                [disabled]="loading"
                *ngIf="canDelete"
                (click)="deleteInspection(job.Id)">
          <fa-icon [icon]="faTrash"></fa-icon>
        </button>
    </div>
  </div>
  <div class="row mt-4" *ngIf="paginationData.DataSet && paginationData.DataSet.length === 0 && !loading">
    <div class="col-12 text-center harmony-grey-text">
      <fa-icon [icon]="faEmpty" size="2x"></fa-icon>
    </div>
    <div class="col-12 text-center harmony-grey-text mt-1">
      <h4>No inspections found, change filters or create a new one...</h4>
    </div>
  </div>
</ng-container>
<br>
<br>
<!---------------------->
<!------- Loader ------->
<!---------------------->
<div class="row mt-4" *ngIf="loading">
  <div class="col-12 text-center">
    <app-loader [Width]="50"></app-loader>
  </div>
</div>
