import { Component, OnDestroy, OnInit } from '@angular/core';
import { faBan, faDownload, faTrash, faUpload } from '@fortawesome/free-solid-svg-icons';
import { IArticleAttachment } from '../../../../../services/models/news/article.model';
import { AlertService } from '../../../../../services/alert.service';
import { IApiResponse } from '../../../../../shared/models/api-reponse.model';
import { ArticleService } from '../../../../../services/article.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OpenConfirmationModal } from '../../../../../shared/components/confirmation-modal/confirmation-modal-functions';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FileService } from '../../../../../services/file.service';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-attachment-modal',
  templateUrl: './article-attachment-modal.component.html',
  styleUrls: ['./article-attachment-modal.component.scss']
})
export class ArticleAttachmentModalComponent implements OnInit, OnDestroy {
  // Input
  public articleId: number;

  // Icons
  protected readonly faUpload = faUpload;
  protected readonly faDownload = faDownload;
  protected readonly faDelete = faTrash;
  protected readonly faCancel = faBan;

  invalid: boolean = false;
  attachment = {} as IArticleAttachment;
  attachments: IArticleAttachment[] = [];
  hasFile: boolean = false;
  hasChanges: boolean = false;

  private unsubscribe: Subject<any> = new Subject<any>();
  loading: boolean = false;

  constructor(private alertService: AlertService,
              private articleService: ArticleService,
              private activeModal: NgbActiveModal,
              private modalService: NgbModal,
              private fileService: FileService) {
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  ngOnInit(): void {
    this.getAttachments();
    this.initAttachment();
  }

  initAttachment() {
    this.attachment.Name = null;
    this.attachment.File = null;
    this.attachment.ArticleId = this.articleId;
    this.hasFile = false;
  }

  getAttachments() {
    this.articleService.getAttachments(this.articleId)
      .subscribe({
        next: (data) => {
          this.loading = false;
          this.attachments = [...data];
        }, error: (err: IApiResponse) => {
          this.alertService.error(err?.Meta?.Message);
          this.loading = false;
        }
      });
  }

  selectFile(event: any) {
    if (event.target.files.length > 1) {
      this.alertService.error('Only a single file can be upload at a time');
      event.target.value = null;
      return;
    }
    const file = event.target.files[0];
    this.loading = true;
    if ((file.size / 1024 / 1024) > 10) {
      event.target.value = null;
      this.alertService.warn('The selected file is bigger than 10MB, please select another file');
      this.loading = false;
      return;
    } else {
      this.loading = false;
      this.hasFile = true;
      this.attachment.File = file;
      event.target.value = null;
    }
  }

  delete(id: number) {
    const message = `You are about to delete the attachment. This cannot be undone, are you sure?`;
    OpenConfirmationModal(this.modalService, message)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((answer: boolean) => {
        if (answer) {
          this.hasChanges = true;
          this.articleService.deleteAttachment(id)
            .subscribe(() =>
              this.getAttachments()
            );
        }
      });
  }

  clearSelectedFile() {
    this.attachment.File = null;
    this.attachment.Name = null;
    this.hasFile = false;
  }

  downloadAttachment(reference: string) {
    this.fileService.GetFile(environment.ContainerNames.ArticleAttachment, reference);
  }

  save() {
    if (this.attachment?.Name?.length > 1 &&
      this.attachment.File) {
      this.loading = true;
      this.invalid = false;

      this.articleService.addAttachment(this.attachment)
        .subscribe({
          next: () => {
            this.loading = false;
            this.hasChanges = true;
            this.getAttachments();
            this.initAttachment();
          }, error: (err: IApiResponse) => {
            this.alertService.error(err?.Meta?.Message);
            this.loading = false;
          }
        });
    } else {
      this.invalid = true;
      this.alertService.error('Please add a file and name');
    }
  }

  close() {
    this.activeModal.close(this.hasChanges);
  }
}
