<div class="modal-header">
  <h4 class="modal-title">
    Generate new rate requests
  </h4>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-6">
      <div class="row mt-1">
        <div class="col-3">
          <b>Project</b>
        </div>
        <div class="col-9">
          <select class="form-select form-select-sm pointer"
                  [disabled]="!(projects$ | async)"
                  [(ngModel)]="ProjectID">
            <option [ngValue]="null" selected>Select a project...</option>
            <option *ngFor="let project of (projects$ | async)" [ngValue]="project.Id">{{ project.Name }}</option>
          </select>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-3">
          <b>City</b>
        </div>
        <div class="col-9">
          <!-- City Search -->
          <app-city-search
            class="d-inline"
            [Identifier]="'LocationCityID'"
            [SelectedID]="rateRequestParam.CityId"
            (OnSelect)="setCity($event)">
          </app-city-search>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-3">
          <b>Agent</b>
        </div>
        <div class="col-9">
          <!-- Agent Search -->
          <app-agent-search
            class="d-inline"
            [Identifier]="'AgentID'"
            [SelectedID]="rateRequestParam.AgentId"
            (OnSelect)="setAgent($event)">
          </app-agent-search>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-3 overflow-hidden">
          <b>Ignore Ports</b>
        </div>
        <div class="col-9">
          <div class="form-check mb-0 form-switch form-check-inline">
            <input class="form-check-input me-3"
                   type="checkbox"
                   role="switch"
                   [id]="'ignore-ports'"
                   [checked]="rateRequestParam.IgnorePorts"
                   (change)="setIgnorePort($event)">
          </div>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="row mt-1">
        <div class="col-3">
          <b>Request Types</b>
        </div>
        <div class="col-9">
          <select class="form-select form-select-sm pointer"
                  [disabled]="!ProjectID || !(rateRequestTypes$ | async)"
                  [multiple]="true"
                  [size]="5"
                  [(ngModel)]="rateRequestParam.RateRequestTypes">
            <option *ngFor="let rateRequestType of (rateRequestTypes$ | async)"
                    [hidden]="rateRequestType.ProjectId != ProjectID"
                    [ngValue]="rateRequestType.Id">
              {{ rateRequestType.Name }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-4 offset-4">
      <button class="btn btn-outline-primary harmony-tertiary-outline-button w-100"
              [disabled]="!rateRequestParam.CityId || !rateRequestParam.AgentId || rateRequestParam.RateRequestTypes.length < 1"
              (click)="generateNewRequests()">
        Generate Requests
      </button>
    </div>
  </div>
  <div class="row mb-0 mt-3 header-row">
    <div class="col-3 header-title border-split-left">
      Agent
    </div>
    <div class="col-2 header-title">
      Request Type
    </div>
    <div class="col-3 header-title">
      Origin
    </div>
    <div class="col-3 header-title">
      Destination
    </div>
    <div class="col-1 header-title border-split-right">
      Status
    </div>
  </div>
  <div class="row" *ngIf="generatedRateRequests && generatedRateRequests.length === 0 && !loading">
    <div class="col-12 text-center">
      No requests. Set parameters to generate new requests.
    </div>
  </div>
  <div class="row mt-4" *ngIf="loading">
    <div class="col-12 text-center">
      <app-loader [Width]="50"></app-loader>
    </div>
  </div>
  <div class="row result-row border-top" *ngFor='let request of generatedRateRequests; let i = index'>
    <div class="col-3 overflow-hidden">
      {{ request.AgentName }}
    </div>
    <div class="col-2 overflow-hidden">
      {{ request.RateRequestTypeName }}
    </div>
    <div class="col-3 overflow-hidden">
      <small>
        <!-- Location types City, Country, State -->
        <b>{{ request.OriginLocationTypeName }}</b>
        {{ pricingService.GetRequestOriginLocationName(request) }}
        <br>
        <!-- Location types Seaport or Airport -->
        <b>Port:</b> {{ request.DeparturePortName ?? '-' }}
      </small>
    </div>
    <div class="col-3 overflow-hidden">
      <small>
        <!-- Location types City, Country, State -->
        <b>{{ request.DestinationLocationTypeName }}</b>
        {{ pricingService.GetRequestDestinationLocationName(request) }}
        <br>
        <!-- Location types Seaport or Airport -->
        <b>Port:</b> {{ request.ArrivalPortName ?? '-' }}
      </small>
    </div>
    <div class="col-1 overflow-hidden">
      <fa-icon [icon]="faExists" *ngIf="request.Id && request.Active == true" class="harmony-accent-green-text"></fa-icon>
      <fa-icon [icon]="faInactive" *ngIf="request.Id && request.Active == false" class="harmony-accent-coral-text"></fa-icon>
      <fa-icon [icon]="faNew" *ngIf="!request.Id" class="harmony-accent-yellow-text"></fa-icon>
      <small class="ms-2">{{ request.Id }}</small>
    </div>
  </div>
</div>
<div class="modal-footer">
  <span class="harmony-accent-green-text">{{ saveResultMessage }}</span>
  <button type="button"
          class="btn btn-primary harmony-tertiary-button"
          [disabled]="canSave()"
          (click)="applyNewRequests()">
    Save
  </button>
  <button type="button"
          class="btn btn-primary harmony-primary-button"
          (click)="close()">
    Close
  </button>
</div>
