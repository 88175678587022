import { AfterContentChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../../../../services/alert.service';
import { AdAuthService } from '../../../../core/ad-auth-service/ad-auth.service';
import { ApiService } from '../../../../services/api.service';
import { Subject } from 'rxjs';
import { IUser, IUserRoleDto } from '../../../../services/models/user.model';
import { takeUntil } from 'rxjs/operators';
import { IAdUser, IAuthUser, IRole } from '../../../../services/models/auth.model';
import { IActiveCompany, ICompanySearchResult, IAuthUserCompany, ICompany } from '../../../../services/models/member.model';
import { environment } from '../../../../../environments/environment';
import { faCheck, faMinusSquare, faPlus, faX } from '@fortawesome/free-solid-svg-icons';
import { CompanyService } from '../../../../services/company.service';
import { NgModel } from '@angular/forms';
import { FileService } from '../../../../services/file.service';
import { PermissionCodes } from '../../../../core/constants/permission-codes';
import { LogService } from '../../../../services/log.service';

@Component({
  selector: 'app-manage-user-modal',
  templateUrl: './user-management-modal.component.html',
  styleUrls: ['./user-management-modal.component.scss']
})
export class UserManagementModalComponent implements OnInit, AfterContentChecked {
  // Icons
  protected readonly faRemove = faMinusSquare;
  protected readonly faAdd = faPlus;
  protected readonly faValid = faCheck;
  protected readonly faInvalid = faX;

  // Component Variables
  public authUser: IAuthUser;
  public isNewUser: boolean;
  public newUserCompanyID: number = null;
  picture: any = null;
  members$ = this.companyService.ActiveMembers$.asObservable();
  roles$ = this.authService.Roles$.asObservable();
  allowedRoles$ = this.authService.AllowedRoles$.asObservable();
  members: IActiveCompany[] = [];
  originalUser: IAuthUser;
  adUser: IAdUser;
  usernameValid: boolean = false;
  domainValid: boolean = false;
  usernameValidMessage: string = null;
  showValidation: boolean = false;
  rolesCompanyId: number = null;
  rolesCompanyName: string = '-';
  roleToAdd: IRole = null;
  defaultMemberRole: IRole = null;
  defaultAgentRole: IRole = null;
  sendNotification: boolean = true;
  selectedCompanyId: number = 0;
  token: string;

  // Permissions
  canCreate: boolean = false;
  canEdit: boolean = false;
  canEditCompanies: boolean = false;
  roleAdmin: boolean = false;

  // General variables
  env = environment;
  loading: boolean = false;
  checkingUser: boolean = false;
  loadingAd: boolean = false;
  private unsubscribe: Subject<any> = new Subject<any>();
  public readonly PermissionCodes = PermissionCodes;
  public logger = (this as any).constructor.name as string;

  constructor(public activeModal: NgbActiveModal,
              public authService: AdAuthService,
              private api: ApiService,
              private log: LogService,
              private ref: ChangeDetectorRef,
              private fileService: FileService,
              private companyService: CompanyService,
              private alertService: AlertService) {
  }

  ngOnInit(): void {
    // Get SAS token for image
    this.getToken();
    if (this.isNewUser) {
      this.initAuthUser();
    }
    if (this.authUser.User) {
      this.setPermissions();
      this.rolesCompanyId = this.authUser.User.CompanyId;
      this.rolesCompanyName = this.authUser.User.Company.Name;
    } else {
      this.authService.CurrentUser$.subscribe(() => {
        this.setPermissions();
        this.rolesCompanyId = this.authUser.User.CompanyId;
        this.rolesCompanyName = this.authUser.User.Company.Name;
      });
    }
    this.getCurrentUserRoles();
    this.subscribeToObservableLookups();

    // Set OG user object for comparison
    this.originalUser = JSON.parse(JSON.stringify(this.authUser));

    if (this.authUser.UserId && this.authUser.UserId > 0) {
      this.usernameValid = true;
      this.domainValid = true;
    }
    // Check Active Directory settings
    this.isUserInAD(this.authUser.User);
    this.getProfilePicture();
  }

  ngAfterContentChecked() {
    this.ref.detectChanges();
  }

  //////////////////
  // Startup Func //
  //////////////////
  getToken() {
    this.fileService.GetSasToken(environment.ContainerNames.UserProfilePictures)
      .subscribe({
        next: (data) => {
          this.token = `?${data.Token}`;
        }
      });
  }

  initAuthUser() {
    const today = new Date().toJSON();
    this.authUser = {
      User: {
        Id: null,
        DateCreated: today,
        DateModified: null,
        Active: true,
        Username: '',
        IntranetUserId: null,
        FirstName: '',
        LastName: '',
        Email: '',
        DisplayName: '',
        UserTypeId: 2,
        UserType: null,
        LoginAttempt: 0,
        IsVerified: true,
        CompanyId: this.newUserCompanyID,
        ProfilePicture: null,
        Company: null,
        AzureRegistered: false
      } as IUser,
      UserId: null,
      UserRoles: [],
      UserCompanies: [],
      Permissions: []
    } as IAuthUser;

    if (this.newUserCompanyID) {
      this.companyService.getCompany(this.newUserCompanyID).subscribe((data: ICompany) => {
        const param = {
          CompanyId: data.CompanyId,
          CompanyType: data.CompanyType.Description,
          Name: data.Name,
          LegalName: data.LegalName,
          GroupName: data.GroupName,
          Country: data.CountryPhysical,
          CountryIso: data.CountryPhysicalIso,
          City: data.CityPhysical,
          CompanyLogo: data.CompanyLogo,
          AgentId: data.AgentId
        } as ICompanySearchResult;
        this.addCompanyToUser(param);
      });
    }
  }

  subscribeToObservableLookups() {
    this.roles$.subscribe((data) => {
      this.defaultMemberRole = data.find(x => x.Name === 'Member: User');
      this.defaultAgentRole = data.find(x => x.Name === 'Agent: User');
    });

    this.members$.subscribe((data) => {
      this.members = Object.assign([], data);
    });
  }

  setPermissions() {
    this.canCreate = this.authService.CheckPermissionByCode(PermissionCodes.Admin_User_Create);
    this.canEdit = this.authService.CheckPermissionByCode(PermissionCodes.Admin_User_Edit);
    this.roleAdmin = this.authService.CheckPermissionByCode(PermissionCodes.Admin_User_RoleAdmin);
    this.canEditCompanies = this.authService.CheckPermissionByCode(PermissionCodes.Admin_User_Company);
  }

  getProfilePicture() {
    if (this.authUser.User.ProfilePicture && !this.picture) {
      this.fileService.GetFileBlobUrl(this.env.ContainerNames.UserProfilePictures, this.getFullPictureName())
        .subscribe({
          next: (data) => {
            if (data) {
              this.picture = data;
            }
          }, error: (err) => {
            this.log.error('Failed to get profile picture ', this.logger, 'getProfilePicture', err);
            this.picture = null;
          }
        });
    }
  }

  getFullPictureName() {
    const pictureName = this.authUser.User.ProfilePicture.substring(
      this.authUser.User.ProfilePicture.lastIndexOf('/') + 1);
    // Includes sub-folder of the userId
    return `${this.authUser.User.Id}/${pictureName}`;
  }

  ////////////////
  // User Name //
  ///////////////
  isUserInAD(user: IUser) {
    if (user && user.Username) {
      this.loadingAd = true;

      this.authService.GetADUserByUsername(user.Username).subscribe({
        next: (data: any) => {
          if (data) {
            this.adUser = Object.assign({}, data);
            user.AzureRegistered = true;
          } else {
            user.AzureRegistered = false;
          }
          this.loadingAd = false;
        },
        error: () => {
          user.AzureRegistered = false;
          this.loadingAd = false;
        }
      });
    }
  }

  registerUserInAD(user: IUser) {
    this.loadingAd = true;

    if (user) {
      this.authService.CreateADUserOnly(user, this.sendNotification).subscribe({
        next: () => {
          this.isUserInAD(user);
          this.alertService.success('User has been successfully registered in the B2C Active Directory.');
        },
        error: () => {
          user.AzureRegistered = false;
          this.alertService.error('Failed to register user in the B2C Active Directory.');
        }
      });
    }
  }

  onUsernameChanged(username: NgModel, event: Event) {
    this.authUser.User.Username = (event.target as HTMLInputElement).value;
    this.usernameValid = !(username.touched && (username.errors?.email || username.errors?.required) || this.authUser.User.Username.length <= 0);
    this.checkForValidUsername();
  }

  checkForValidUsername() {
    if (this.authUser.User && this.authUser.User.Username && !this.authUser.UserId) {
      this.checkingUser = true;

      const param = {
        Username: this.authUser.User.Username,
        CompanyId: this.authUser.User.CompanyId
      };

      this.api.post('User/CheckValidUsername', param).pipe(
        takeUntil(this.unsubscribe)
      ).subscribe({
        next: (data: any) => {
          this.domainValid = data.IsValid;
          this.usernameValidMessage = data.Message;
          this.checkingUser = false;
        },
        error: () => {
          this.checkingUser = false;
        }
      });
    }
  }

  changeDefaultCompany() {
    const company = this.authUser.UserCompanies.find(x => x.CompanyId === this.authUser.User.CompanyId);
    this.rolesCompanyId = company.CompanyId;
    this.rolesCompanyName = company.CompanyName;
    this.getCurrentUserRoles();
    this.checkForValidUsername();
  }

  ////////////////////
  // User Companies //
  ////////////////////
  userHasCompany(companyId: number) {
    if (this.authUser?.UserCompanies?.length > 0) {
      const exists = this.authUser?.UserCompanies.find((x) => x.CompanyId === companyId);
      return !!exists;
    }
    return false;
  }

  addCompanyToUser(company: ICompanySearchResult) {
    if (company && company.CompanyId > 0) {
      this.selectedCompanyId = company.CompanyId;
      // Update the default company
      if (!(this.authUser.User.CompanyId > 0)) {
        this.authUser.User.CompanyId = company.CompanyId;
        this.checkForValidUsername();
      }
      // Check existing user companies to avoid duplicates
      if (!this.userHasCompany(company.CompanyId)) {
        this.rolesCompanyId = company.CompanyId;
        this.rolesCompanyName = company.Name;

        // Create new UserCompany
        this.addNewUserCompany(company);

        // If this is the first company added, set it as the users default company
        if (this.authUser.User.CompanyId == null) {
          this.authUser.User.CompanyId = company.CompanyId;
          this.checkForValidUsername();
        }

        this.getCurrentUserRoles();
        this.selectedCompanyId = null;
      }

      // reset value on a delay to allow change detection on app-company-search to triggered correctly every time.
      setTimeout(() => {
        this.selectedCompanyId = null;
      }, 200);
    }
  }

  checkDefaultRole(companyId: number) {
    const activeMember = this.companyService.ActiveMembers$.getValue().find(x => x.CompanyId === companyId);
    // Add default roles
    if (activeMember && activeMember.CompanyType && activeMember.CompanyType.toLowerCase() !== 'agent') {
      // Default MEMBER role
      const hasRole = this.authUser.UserRoles.find((x) => x.CompanyId === companyId && x.RoleId === this.defaultMemberRole.Id && x.CompanyId === this.rolesCompanyId);
      if (hasRole == null) {
        const newUserRole = {
          Id: null,
          RoleId: this.defaultMemberRole.Id,
          CompanyId: companyId,
          Active: true,
          RoleName: this.defaultMemberRole.Name
        } as IUserRoleDto;
        this.authUser.UserRoles.push(newUserRole);
      }
    } else {
      const hasAgentRole = this.authUser.UserRoles.find((x) => x.CompanyId === companyId && x.RoleId === this.defaultAgentRole.Id && x.CompanyId === this.rolesCompanyId);
      if (hasAgentRole == null) {
        // Default AGENT role
        const newUserRole = {
          Id: null,
          RoleId: this.defaultAgentRole.Id,
          CompanyId: companyId,
          Active: true,
          RoleName: this.defaultAgentRole.Name
        } as IUserRoleDto;
        this.authUser.UserRoles.push(newUserRole);
      }
    }
  }

  addNewUserCompany(company: ICompanySearchResult) {
    const hasCompany = this.authUser.UserCompanies.find((x) => x.CompanyId === company.CompanyId);
    if (hasCompany == null) {
      const newUserCompany = {
        CompanyId: company.CompanyId,
        CompanyName: company.Name,
      } as IAuthUserCompany;
      // for display
      this.authUser.UserCompanies.push(newUserCompany);
      this.roleToAdd = null;
    } else {
      this.alertService.warn('This company is already assigned to the user');
    }
  }

  removeUserCompany(userCompany: IAuthUserCompany) {
    if (userCompany != null) {
      // Unsaved company, remove from list
      if (userCompany.Id === null) {
        this.authUser.UserRoles = this.authUser.UserRoles.filter(x => x.CompanyId !== userCompany.CompanyId);
        const indexOfCompany = this.authUser.UserCompanies.findIndex(x => x.CompanyId === userCompany.CompanyId);
        this.authUser.UserCompanies.splice(indexOfCompany, 1);
      } else {
        userCompany.Active = false;
        this.authUser.UserRoles.forEach(x => {
          if (x.CompanyId === userCompany.CompanyId) {
            x.Active = false;
          }
        });
      }
    }
  }

  ////////////////////
  // User Roles //////
  ////////////////////
  getCurrentUserRoles() {
    if (this.authUser?.UserId) {
      this.loading = true;
      this.authService.GetUserRoles(this.authUser.UserId, this.rolesCompanyId).subscribe(
        {
          next: (data) => {
            if (data) {
              this.authUser.UserRoles = Object.assign([], data);
              this.checkDefaultRole(this.rolesCompanyId);
            }
            this.loading = false;
          },
          error: () => {
            this.loading = false;
          }
        });
    }
  }

  addRoleToUser() {
    if (this.roleToAdd) {
      this.addNewUserRole(this.rolesCompanyId, this.roleToAdd);
      this.roleToAdd = null;
    }
  }

  userHasRole(role: IRole) {
    if (this.authUser.UserRoles && this.authUser.UserRoles.length > 0) {
      const exists = this.authUser.UserRoles.find((x) => x.RoleId === role.Id && x.CompanyId === this.rolesCompanyId);
      return !!exists;
    }
    return false;
  }

  canDeleteRole(userRole: IUserRoleDto) {
    if (this.authService.AllowedRoles$ && this.authService.AllowedRoles$.getValue().length > 0) {
      const hasRoleAccess = this.authService.AllowedRoles$.getValue().filter(x => x.Id === userRole.RoleId);
      return (hasRoleAccess && hasRoleAccess.length > 0);
    }
    return false;
  }

  addNewUserRole(companyId: number, role: IRole) {
    if (role && role.Id > 0) {
      const hasRole = this.authUser.UserRoles.find((x) => x.CompanyId === companyId && x.RoleId === role.Id && x.CompanyId === this.rolesCompanyId);
      if (hasRole == null) {
        const newUserRole = {
          Id: null,
          RoleId: role.Id,
          CompanyId: companyId,
          Active: true,
          RoleName: role.Name
        } as IUserRoleDto;
        this.authUser.UserRoles.push(newUserRole);
      } else {
        this.alertService.warn('This role is already assigned to the user');
      }
    }
  }

  removeUserRole(userRole: IUserRoleDto) {
    if (userRole != null) {
      // Unsaved role, remove from list
      if (userRole.Id === null) {
        const indexOfRole = this.authUser.UserRoles
          .findIndex(x => x.CompanyId === userRole.CompanyId && x.RoleId === userRole.RoleId);
        this.authUser.UserRoles.splice(indexOfRole, 1);
      } else {
        userRole.Active = false;
      }
    }
  }

  ////////////////////
  // Company Role ////
  ////////////////////
  changeCompanyRoles() {
    const company = this.authUser.UserCompanies.find(x => x.CompanyId === this.rolesCompanyId);
    this.rolesCompanyName = company.CompanyName;
    this.getCurrentUserRoles();
  }

  ///////////
  // Save ///
  ///////////
  canSave() {
    let isValid = false;
    // Check if user info complete
    if (this.authUser && this.authUser.User &&
      (this.authUser.User.DisplayName && this.authUser.User.DisplayName.length > 0) &&
      (this.authUser.User.FirstName && this.authUser.User.FirstName.length > 0) &&
      (this.authUser.User.LastName && this.authUser.User.LastName.length > 0) &&
      (this.authUser.User.CompanyId && this.authUser.User.CompanyId > 0) &&
      this.userHasCompanyAndRole() && this.usernameValid && this.domainValid) {
      isValid = true;
    }
    // Return result for button
    return isValid;
  }

  userHasCompanyAndRole() {
    return (this.authUser?.UserCompanies?.length > 0) &&
      (this.authUser?.UserRoles?.length > 0);
  }

  save() {
    if (this.canSave()) {
      this.loading = true;
      this.showValidation = false;
      this.authService.UpsertUser(this.authUser)
        .subscribe({
          next: (data) => {
            if (data) {
              this.authUser = Object.assign({}, data);
              this.originalUser = JSON.parse(JSON.stringify(this.authUser));
              this.isUserInAD(this.authUser.User);
              this.getCurrentUserRoles();
            }
            this.loading = false;
            this.alertService.success('User data updated');
          },
          error: () => {
            this.loading = false;
            this.alertService.warn('Failed to update user data');
          }
        });
    } else {
      this.showValidation = true;
    }
  }

  close() {
    this.activeModal.close(false);
  }
}
