<div class="dashboard-background"></div>
<div class="dashboard-wrapper">
  <div class="dashboard-content">
    <!-- Eurotunnel -->
    <div class="three-by-three d-flex align-items-start services-card">
      <div>
        <div class="services-card-header row">
          <div class="col-12">
            <!--Header-->
            <h5 class="harmony-teal-text fw-bold">Eurotunnel</h5>
          </div>
        </div>
        <hr class="ms-3 me-3 mt-0 mb-4">
        <div class="row mt-2">
          <div class="col-3">
            <div class="justify-content-center m-2">
              <img [src]="env.SiteUrls.AzureStorageBaseURL + env.ContainerNames.WebImagesServices + 'Eurostar-eurotunnel%20Small.jpg'"
                   class="service-image-rounded-borders"
                   alt="Eurotunnel">
            </div>
          </div>
          <div class="col-9 service-content">
            <span><h5 class="harmony-blue-text fw-bold">Eurotunnel Crossings France – UK  (and v.v.)</h5></span>
            <p>
            In line with our Environmental Policy, Harmony has a Eurotunnel Programme in place, to motivate members to reduce CO2 emissions by choosing to cross the channel with Eurotunnel.
            </p>
            <p>
            We have negotiated special rates for crossing the channel with Eurotunnel, and Harmony Head Office is the contract holder for these rates.
            </p>
            <br>
            <span><h5 class="harmony-blue-text fw-bold">Advantages of using the Eurotunnel</h5></span>
            <ul class="service-list">
              <li>Online and automatic registration of vehicles via license plate scanning</li>
              <li>Reduced waiting time at the toll gates</li>
              <li>First on the train via the Harmony Corporate Account contract</li>
              <li>Invoicing via the Harmony Netting system</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- Eurotunnel Contact -->
    <div class="one-by-one services-card">
      <div class="services-card-header row">
        <div class="col-12">
          <!--Header-->
          <h5 class="harmony-teal-text fw-bold">Eurotunnel Contact</h5>
        </div>
      </div>
      <hr class="ms-3 me-3 mt-0 mb-4">
      <div class="row">
        <div class="col-12 ms-4">
          <div class="row">
            <div class="col-12 service-contact">
              Mr Maurice van Bijleveld
            </div>
          </div>
          <div class="row my-1">
            <div class="col-1">
              <fa-icon [icon]="faEnvelope"></fa-icon>
            </div>
            <div class="col-11 nav-item">
              <a href="mailto:m.bijleveld@harmonyrelo.com?Subject=Request information about Services/Eurotunnel" class="harmony-blue-link">m.bijleveld@harmonyrelo.com</a>
            </div>
          </div>
          <div class="row my-1">
            <div class="col-1">
              <fa-icon [icon]="faPhone"></fa-icon>
            </div>
            <div class="col-11">
              <a href="callto:+31 294 461 020" style="color: rgb(0,0,0)">+31 294 461 020</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Eurotunnel Documents -->
    <div class="one-by-one services-card">
      <div class="services-card-header row">
        <div class="col-12">
          <!--Header-->
          <h5 class="harmony-teal-text fw-bold">Eurotunnel Documents</h5>
        </div>
      </div>
      <hr class="ms-3 me-3 mt-0 mb-4">
      <div class="card-body row mt-2">
        <div class="col-12 service-content document-list">
          <app-document-list [ModuleName]="'Services'" [CategoryCode]="'SERVETLD'" [Recents]="true"></app-document-list>
        </div>
      </div>
    </div>
    <!-- Prices & Peak Day Freight -->
    <div class="three-by-two d-flex align-items-start services-card">
      <div>
        <div class="services-card-header row">
          <div class="col-12">
            <!--Header-->
            <h5 class="harmony-teal-text fw-bold">Prices & Peak Day Freight</h5>
          </div>
        </div>
        <hr class="ms-3 me-3 mt-0 mb-4">
        <div class="row mt-2">
          <div class="col-3">
            <div class="justify-content-center m-2">
              <img [src]="env.SiteUrls.AzureStorageBaseURL + env.ContainerNames.WebImagesServices + 'eurotunnel_logo.jpg'"
                   class="service-image-rounded-borders"
                   alt="Prices & Peak Day Freight">
            </div>
          </div>
          <div class="col-9 service-content">

            <span><h5 class="harmony-blue-text fw-bold">2024: EUROTUNNEL PRICING for single crossings including electricity charge</h5></span>

            <br>
            <table class="w-75">
              <tr>
                <td></td>
                <td class="fw-bold">normal days</td>
                <td class="fw-bold">peak days</td>
              </tr>
              <tr>
                <td>above 13 mtr</td>
                <td>€ 325</td>
                <td>€ 337</td>
              </tr>
              <tr>
                <td>7.5 - 13 mtr	</td>
                <td>€ 273</td>
                <td>€ 285</td>
              </tr>
              <tr>
                <td>under 7.5 mtr	</td>
                <td>€ 205	</td>
                <td>€ 217</td>
              </tr>
            </table>
            <br>
            <p>
            To regulate demands on very busy days Eurotunnel applies a Peak Day Freight Rate as set out in the Peak Days Schedule.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
