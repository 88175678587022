import { Component } from '@angular/core';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-services-corporate-sales',
  templateUrl: './services-corporate-sales.component.html',
  styleUrls: ['./services-corporate-sales.component.scss']
})
export class ServicesCorporateSalesComponent {
  // Icons
  faPhone = faPhone;
  faEnvelope = faEnvelope;

  // General variables
  env = environment;
}
