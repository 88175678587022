<ng-container *ngIf="articleReview">
  <div class="row mt-5">
    <div class="col-6">
      <h5 class="harmony-secondary-text fw-bold">
        Your original version
      </h5>
      <hr class="my-1">
    </div>
    <div class="col-6">
      <div class="d-flex justify-content-between">
        <h5 class="harmony-secondary-text fw-bold">
          Suggested changes
        </h5>
        <div class="justify-content-end">
          <button (click)="preview()"
                  [disabled]="articleReview.Review?.HtmlContent?.length <= 0 && articleReview.Review?.Title?.length <= 0"
                  class="btn btn-sm btn-primary harmony-secondary-outline-button float-end me-1 px-5"
                  type="button">
            Preview
          </button>
        </div>
      </div>
      <hr class="my-1">
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-6 px-3 border-end">
      <div class="row">
        <div class="col-7">
          <div class="article-image-wrapper">
            <div class="row" *ngIf="articleReview.Article.ImageReference; else noimage">
              <div class="col-12 text-center">
                <img class="article-image"
                     [src]="imageBaseUrl + articleReview.Article.ArticleId + '/'+ articleReview.Article.ImageReference"
                     [alt]="articleReview.Article.Title">
              </div>
            </div>
          </div>
        </div>
        <div class="col-5">
          <div class="row">
            <div class="col-12">
              <label class="category-label" *ngFor="let category of articleReview.Article.Categories">{{category.Name}}</label>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12">
              <p *ngIf="articleReview.Article.Featured" class="mb-0">
                <fa-icon class="harmony-accent-green-text" [icon]="faCheck"></fa-icon> Featured
              </p>
              <p *ngIf="!articleReview.Article.Featured" class="mb-0">
                <fa-icon class="harmony-grey-text" [icon]="faCancel"></fa-icon> Not featured
              </p>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-12">
              <p *ngIf="articleReview.Article.Website" class="mb-0">
                <fa-icon class="harmony-accent-green-text" [icon]="faCheck"></fa-icon> Show on website
              </p>
              <p *ngIf="!articleReview.Article.Website" class="mb-0">
                <fa-icon class="harmony-grey-text" [icon]="faCancel"></fa-icon> Not shown on website
              </p>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-12">
              <p class="mb-0">
                <fa-icon class="harmony-secondary-text" style="margin-right: 5px; margin-left: 1px;" [icon]="faDate"></fa-icon>To publish on {{(articleReview.Article.PublishDate | date:env.FormattingStandards.ShortDateFormat)}}
              </p>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-12">
              <p *ngIf="articleReview.Article.ExpireDate" class="mb-0">
                <fa-icon class="harmony-secondary-text" style="margin-right: 5px; margin-left: 0;" [icon]="faExpire"></fa-icon>
                To expire on {{(articleReview.Article.ExpireDate | date:env.FormattingStandards.ShortDateFormat)}}
              </p>
              <p *ngIf="!articleReview.Article.ExpireDate" class="mb-0">
                <fa-icon class="harmony-secondary-text" style="margin-right: 5px; margin-left: 0;" [icon]="faExpire"></fa-icon>
                No expiry date
              </p>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-12">
              <p class="mb-0">
                <fa-icon [icon]="faAttachment" class="harmony-secondary-text" style="margin-right: 5px; margin-left: 2px;" size="sm"></fa-icon>
                {{articleReview.Article.TotalAttachments}} Attachments
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6 px-3">
      <div class="row">
        <div class="col-7">
          <div class="article-image-wrapper">
            <div class="row" *ngIf="articleReview.Article.ImageReference; else noimage">
              <div class="col-12 text-center">
                <img class="article-image"
                     [src]="imageBaseUrl + articleReview.Article.ArticleId + '/'+ articleReview.Article.ImageReference"
                     [alt]="articleReview.Article.Title">
              </div>
            </div>
          </div>
        </div>
        <div class="col-5">
          <div class="row">
            <div class="col-12" id="diffCategoryContainer" [innerHTML]="diffCategories"></div>
          </div>
          <div class="row mt-3">
            <div class="col-12">
              <p *ngIf="articleReview.Review.Featured" class="mb-0">
                <fa-icon class="harmony-accent-green-text" style="margin-right: 5px; margin-left: 0;" [icon]="faCheck"></fa-icon>
                <span [ngClass]="{'diff-change': articleReview.Article.Featured != articleReview.Review.Featured}">
                  Featured
                </span>
              </p>
              <p *ngIf="!articleReview.Review.Featured" class="mb-0">
                <fa-icon class="harmony-grey-text"  style="margin-right: 5px; margin-left: 0;" [icon]="faCancel"></fa-icon>
                <span [ngClass]="{'diff-change': articleReview.Article.Featured != articleReview.Review.Featured}">
                  Not featured
                </span>
              </p>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-12">
              <p *ngIf="articleReview.Review.Website" class="mb-0">
                <fa-icon class="harmony-accent-green-text" style="margin-right: 5px; margin-left: 0;" [icon]="faCheck"></fa-icon>
                <span [ngClass]="{'diff-change': articleReview.Article.Website != articleReview.Review.Website}">
                  Show on website
                </span>
              </p>
              <p *ngIf="!articleReview.Review.Website" class="mb-0">
                <fa-icon class="harmony-grey-text" style="margin-right: 5px; margin-left: 0;" [icon]="faCancel"></fa-icon>
                <span [ngClass]="{'diff-change': articleReview.Article.Website != articleReview.Review.Website}">
                  Not shown on website
                </span>
              </p>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-12">
              <p class="mb-0">
                <fa-icon class="harmony-secondary-text" style="margin-right: 5px; margin-left: 1px;" [icon]="faDate"></fa-icon>
                <span [ngClass]="{'diff-change': articleReview.Article.PublishDate != articleReview.Review.PublishDate}">
                  To publish on {{(articleReview.Review.PublishDate | date:env.FormattingStandards.ShortDateFormat)}}
                </span>
              </p>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-12">
              <p *ngIf="articleReview.Review.ExpireDate" class="mb-0">
                <fa-icon class="harmony-secondary-text" style="margin-right: 5px; margin-left: 0;" [icon]="faExpire"></fa-icon>
                <span [ngClass]="{'diff-change': articleReview.Article.ExpireDate != articleReview.Review.ExpireDate}">
                  To expire on {{(articleReview.Review.ExpireDate | date:env.FormattingStandards.ShortDateFormat)}}
                </span>
              </p>
              <p *ngIf="!articleReview.Review.ExpireDate" class="mb-0">
                <fa-icon class="harmony-secondary-text" style="margin-right: 5px; margin-left: 0;" [icon]="faExpire"></fa-icon>
                <span [ngClass]="{'diff-change': articleReview.Article.ExpireDate != articleReview.Review.ExpireDate}">
                  No expiry date
                </span>
              </p>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-12">
              <p class="mb-0">
                <fa-icon [icon]="faAttachment" class="harmony-secondary-text" style="margin-right: 5px; margin-left: 2px;" size="sm"></fa-icon>
                {{articleReview.Article.TotalAttachments}} Attachments
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!---------------------->
  <!------ Preview ------->
  <!---------------------->
  <div class="row">
    <div class="col-6 px-2 border-end">
      <div class="article-content-wrapper mt-5">
        <div class="row text-center">
          <div class="col-12">
            <h4 class="harmony-primary-text fw-bold mb-0">
              {{ articleReview.Article.Title }}
            </h4>
          </div>
          <div class="col-12">
            <p class="article-publish-date">
              {{ articleReview.Article.PublishDate | date: env.FormattingStandards.ShortDateFormat }}
              - <small class="harmony-secondary-text">{{ articleReview.Article.Author ?? '' }}</small>
            </p>
            <hr class="harmony-grey-text">
          </div>
        </div>
        <div class="article-body-wrapper">
          <div class="row pt-3">
            <div [innerHTML]="articleReview.Article.HtmlContent | noSanitize"
                 class="col-12">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6 px-2">
      <div class="article-content-wrapper mt-5">
        <div class="row text-center">
          <div class="col-12 harmony-primary-text"
               style="font-weight: 700 !important; font-size: 1.5rem;"
               id="diffTitleContainer"
               [innerHTML]="diffTitle">
          </div>
          <div class="col-12">
            <p class="article-publish-date">
              <span [ngClass]="{'diff-change': articleReview.Article.PublishDate != articleReview.Review.PublishDate}">
                {{ articleReview.Review.PublishDate | date: env.FormattingStandards.ShortDateFormat }}
              </span> - <small class="harmony-secondary-text">{{ articleReview.Article.Author ?? '' }}</small>
            </p>
            <hr class="harmony-grey-text">
          </div>
        </div>
        <div class="article-body-wrapper">
          <div class="row pt-3">
            <div id="diffBodyContainer" [innerHTML]="diffBody | noSanitize"
                 class="col-12">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #noimage>
  <div class="row">
    <div class="col-12 text-center">
      <img class="article-image"
           [src]="placeholderImage"
           [alt]="articleReview.Article.Title">
    </div>
  </div>
</ng-template>
