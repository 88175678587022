<!-- Search Bar -->
<div class="row mt-3">
  <div class="col-2 pt-1">
    <b>Request Type</b>
  </div>
  <div class="col-2">
    <select class="form-select form-select-sm" id="requestType" [(ngModel)]="requestType">
      <option *ngFor="let t of requestTypes | keyvalue" [ngValue]="t.key">{{t.key}}</option>
    </select>
  </div>
  <div class="col-1 pt-1">
    <b>From Date</b>
  </div>
  <div class="col-2">
    <app-date-picker [identifier]="'inputFromDate'" (onChange)="prismFromDate = $event" [title]="'Select a from date'"></app-date-picker>
  </div>
  <div class="col-2 offset-3 text-end">
    <button class="btn btn-sm btn-primary harmony-tertiary-button w-100" [disabled]="!canSearchPrism()"
            [title]="'Enter from date and search'" (click)="searchPrism()">
      Search
    </button>
  </div>
</div>
<div class="row mt-2" *ngIf="requestType === 'Ports'">
  <div class="col-2">
    <b>Port Type</b>
  </div>
  <div class="col-2 text-start">
    <select class="form-select form-select-sm" id="portType" [(ngModel)]="rateSource">
      <option [ngValue]="null">Select a port type...</option>
      <option [ngValue]="'NotSet'">Not Set</option>
      <option [ngValue]="'Air'">Air</option>
      <option [ngValue]="'Sea'">Sea</option>
    </select>
  </div>
  <div class="col-1 display-field">
    <b>ISO2 Code</b>
  </div>
  <div class="col-2">
    <input id="countryCode"
           class="form-control form-control-sm"
           [ngModel]="countryCode" maxlength="2"
           (ngModelChange)="countryCode = $event.toUpperCase()"
           placeholder="Enter an ISO2 Country Code"
           type="text">
  </div>
</div>
<hr class="mb-0 mt-2">
<!----- Ports Pagination top----->
<div class="row ms-1 me-1">
  <div class="col-12">
    <app-pagination [showPageNumber]=true
                    [showRowsPerPage]=true
                    [paginationData]=paginationPorts
                    [name]="'PrismRates'"
                    (pageEvent)="searchPage($event)"
                    *ngIf="requestType === 'Ports'"
                    [page]="page">
    </app-pagination>
  </div>
</div>
<!----- Ports Results View ----->
<!----- Table Header ----->
<div *ngIf="requestType === 'Ports'">
  <div class="row mt-1 header-row">
    <div class="col-1 header-title border-split-left">
      ID
    </div>
    <div class="col-3 header-title">
      Port Name
    </div>
    <div class="col-2 header-title">
      Port Type
    </div>
    <div class="col-2 header-title">
      Country CD
    </div>
    <div class="col-2 header-title">
      Port CD
    </div>
    <div class="col-2 header-title border-split-right">
      Lo Code
    </div>
  </div>
  <app-loader *ngIf="primsLoading"></app-loader>
  <!----- Table Data ----->
  <div class="row result-row p-1" *ngFor='let port of ports; let i = index'>
    <div class="col-1 overflow-hidden">
      {{ port.countryPortID }}
    </div>
    <div class="col-3 overflow-hidden">
      {{ port.portName }}
    </div>
    <div class="col-2 overflow-hidden">
      {{ port.portTypeDesc }}
    </div>
    <div class="col-2 overflow-hidden">
      {{ port.countryCD }}
    </div>
    <div class="col-2 overflow-hidden">
      {{ port.portCD }}
    </div>
    <div class="col-2 overflow-hidden">
      {{ port.loCode }}
    </div>
  </div>
</div>
<div class="row ms-1 me-1 mb-2">
  <div class="col-12">
    <!----- Agents Pagination Top----->
    <app-pagination [showPageNumber]=false
                    [showRowsPerPage]=false
                    [name]="'PrismRates'"
                    [paginationData]=paginationAgents
                    (pageEvent)="searchPage($event)"
                    *ngIf="requestType === 'Agents'"
                    [page]="page">
    </app-pagination>
  </div>
</div>
<!----- Agents Results View ----->
<!----- Table Header ----->
<div *ngIf="requestType === 'Agents'">
  <div class="row mt-1 header-row">
    <div class="col-1 header-title border-split-left">
      ID
    </div>
    <div class="col-2 header-title">
      Company Name
    </div>
    <div class="col-2 header-title">
      Contact Number
    </div>
    <div class="col-1 header-title">
      Company Code
    </div>
    <div class="col-2 header-title">
      City
    </div>
    <div class="col-1 header-title">
      Country Code
    </div>
    <div class="col-3 header-title border-split-right">
      Address
    </div>
  </div>
  <app-loader *ngIf="primsLoading"></app-loader>
  <!----- Table Data ----->
  <div class="row result-row p-1" *ngFor='let agent of agents; let i = index'>
    <div class="col-1 overflow-hidden">
      {{ agent.agentID }}
    </div>
    <div class="col-2 overflow-hidden">
      {{ agent.agentCompanyName }}
    </div>
    <div class="col-2 overflow-hidden">
      {{ agent.contactPhoneNbr }}
    </div>
    <div class="col-1 overflow-hidden">
      {{ agent.agentCompanyCD }}
    </div>
    <div class="col-2 overflow-hidden">
      {{ agent.city }}
    </div>
    <div class="col-1 overflow-hidden">
      {{ agent.countryCD }}
    </div>
    <div class="col-3 overflow-hidden">
      {{ agent.address }}
    </div>
  </div>
</div>
<!----- No Results View ----->
<div class="row mt-4" *ngIf="!hasResults()">
  <div class="col-12 text-center harmony-grey-text">
    <fa-icon [icon]="faEmpty" size="2x"></fa-icon>
  </div>
  <div class="col-12 text-center harmony-grey-text mt-1">
    <h4>No results...</h4>
  </div>
</div>
<div class="row ms-1 me-1">
  <div class="col-12">
    <!----- Ports Pagination Bottom----->
    <app-pagination [showPageNumber]=false
                    [showRowsPerPage]=false
                    [paginationData]=paginationPorts
                    [name]="'PrismRates'"
                    (pageEvent)="searchPage($event)"
                    *ngIf="requestType === 'Ports'"
                    [page]="page">
    </app-pagination>
    <!----- Agents Pagination Bottom----->
    <app-pagination [showPageNumber]=false
                    [showRowsPerPage]=false
                    [paginationData]=paginationAgents
                    [name]="'PrismRates'"
                    (pageEvent)="searchPage($event)"
                    *ngIf="requestType === 'Agents'"
                    [page]="page">
    </app-pagination>
  </div>
</div>
