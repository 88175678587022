<!------------------------------->
<!--- Searchbar with filters ---->
<!------------------------------->
<div class="row mt-1">
  <div class="col-4">
    <div class="row mt-1">
      <div class="col-4 overflow-hidden align-self-center">
        <b>Country</b>
      </div>
      <div class="col-8">
        <app-country-search [Disabled]="loading"
                            [SelectedID]="searchParam.CountryId"
                            (OnSelect)="searchParam.CountryId = $event">
        </app-country-search>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-4 overflow-hidden align-self-center">
        <b>State</b>
      </div>
      <div class="col-8">
        <app-state-search [Identifier]="'StateID'"
                          [SelectedID]="searchParam.StateId"
                          (OnSelect)="searchParam.StateId = $event">
        </app-state-search>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-4 overflow-hidden align-self-center">
        <b>Active</b>
      </div>
      <div class="col-8">
        <select class="form-select form-select-sm pointer"
                [(ngModel)]="searchParam.Active">
          <option [ngValue]="null">All</option>
          <option [ngValue]="true" selected>Active only</option>
          <option [ngValue]="false">Disabled only</option>
        </select>
      </div>
    </div>
  </div>
  <div class="col-2 offset-6">
    <button class="btn btn-sm btn-outline-primary harmony-tertiary-outline-button w-100"
            (click)="newCity()">
      + Add City
    </button>
  </div>
</div>
<hr class="mt-2 mb-1">
<div class="row">
  <div class="col-4 overflow-hidden">
    <input type="text"
           class="form-control form-control-sm"
           [placeholder]="'Search cities...'"
           (keyup.enter)="searchFilter()"
           [(ngModel)]="searchParam.SearchText">
  </div>
  <div class="col-4 offset-2 text-end pe-0">
    <button class="btn btn-sm btn-primary harmony-primary-outline-button ms-2"
            [title]="'Reset search'"
            (click)="resetSearch()">
      Reset
    </button>
  </div>
  <div class="col-2 text-end ps-1">
    <button class="btn btn-sm btn-primary harmony-tertiary-button w-100"
            (click)="searchFilter()">
      Search
    </button>
  </div>
</div>
<hr class="mt-1 mb-0">
<!------------------->
<!---- Pagination --->
<!------------------->
<div class="row ms-1 me-1 mb-2">
  <div class="col-12">
    <app-pagination [showPageNumber]=true
                    [showRowsPerPage]=true
                    [paginationData]=paginationData
                    (pageEvent)="onPage($event)"
                    [name]="'CityManagement'"
                    [page]="page">
    </app-pagination>
  </div>
</div>
<!--------------------->
<!---- Result table --->
<!--------------------->
<div class="row mt-1 header-row">
  <div class="col-3 col-compress header-title border-split-left pointer"
       (appOrder)="orderSearch($event)"
       data-column="Name"
       data-order="desc">
    Name
    <fa-icon [hidden]="orderParam.OrderBy != 'Name'" [icon]="faSortAsc" style="bottom: 3px;" class="ms-2 position-relative"></fa-icon>
  </div>
  <div class="col-2 col-compress header-title pointer"
       (appOrder)="orderSearch($event)"
       data-column="State.Name"
       data-order="asc">
    State
    <fa-icon [hidden]="orderParam.OrderBy != 'State.Name'" class="ms-2 position-relative"></fa-icon>
  </div>
  <div class="col-3 col-compress header-title pointer"
       (appOrder)="orderSearch($event)"
       data-column="Country.Name"
       data-order="asc">
    Country
    <fa-icon [hidden]="orderParam.OrderBy != 'Country.Name'" class="ms-2 position-relative"></fa-icon>
  </div>
  <div class="col-2 col-compress header-title pointer"
       (appOrder)="orderSearch($event)"
       data-column="LOCode"
       data-order="asc">
    LO Code
    <fa-icon [hidden]="orderParam.OrderBy != 'LOCode'" class="ms-2 position-relative"></fa-icon>
  </div>
  <div class="col-1 col-compress header-title pointer"
       (appOrder)="orderSearch($event)"
       data-column="DateModified"
       data-order="asc">
    Modified
    <fa-icon [hidden]="orderParam.OrderBy != 'DateModified'" class="ms-2 position-relative"></fa-icon>
  </div>
  <div class="col-1 text-end col-compress header-title border-split-right">

  </div>
</div>
<!---------------------->
<!------- Results ------->
<!---------------------->
<ng-container *ngIf="!loading">
  <div class="row result-row" style="margin-bottom: -0.25rem;" *ngFor="let city of paginationData.DataSet; let i = index">
    <div class="col-3 col-compress overflow-hidden">
      <b>{{ city.Name }}</b>
      <br>
      <small>{{ city.NameWoDiacritics ?? '-' }}</small>
    </div>
    <div class="col-2 col-compress overflow-hidden">
      {{ city.StateName ?? '-' }}
    </div>
    <div class="col-3 col-compress overflow-hidden">
      {{ city.CountryName }} <small>({{ city.CountryIso }})</small>
    </div>
    <div class="col-2 col-compress overflow-hidden">
      {{ city.LOCode ?? '-' }}
    </div>
    <div class="col-1 col-compress overflow-hidden">
      <small>{{ (city.DateModified | date: env.FormattingStandards.ShortDateFormat) ?? '-' }}</small>
    </div>
    <div class="col-1 col-compress text-end pe-1">
      <button class="btn btn-sm btn-outline-light harmony-primary-outline-button"
              [title]="'Edit City: ' + city.Name"
              (click)="editCity(city)">
        <fa-icon [icon]="faEditIcon" size="sm"></fa-icon>
      </button>
      <ng-container *ngIf="hasDeletePermission">
        <button class="btn btn-sm btn-outline-danger ms-1"
                *ngIf="city.Active === true"
                [title]="'Deactivate User'"
                (click)="toggleCity(city)">
          <fa-icon [icon]="faRemove" size="xs"></fa-icon>
        </button>
        <button class="btn btn-sm btn-outline-success ms-1"
                *ngIf="city.Active === false"
                (click)="toggleCity(city)">
          <fa-icon [icon]="faAdd" size="xs"></fa-icon>
        </button>
      </ng-container>
    </div>
  </div>
</ng-container>
<div class="row mt-4" *ngIf="paginationData && paginationData.DataSet.length === 0 && !loading">
  <div class="col-12 text-center harmony-grey-text">
    <fa-icon [icon]="faEmpty" size="2x"></fa-icon>
  </div>
  <div class="col-12 text-center harmony-grey-text mt-1">
    <h4>No cities found, change filters and try again...</h4>
  </div>
</div>
<!---------------------->
<!------- Loader ------->
<!---------------------->
<div class="row mt-4" *ngIf="loading">
  <div class="col-12 text-center">
    <app-loader [Width]="50"></app-loader>
  </div>
</div>
<br>
