<div class="modal-header">
  <h4 class="modal-title" id="modal-title-edit">
    Merge Agents
  </h4>
  <button type="button" class="btn-close" aria-label="Close" [disabled]="loading" (click)="activeModal.dismiss(false)">

  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-12 harmony-grey-text small">
      <fa-icon [icon]="faInfo" size="sm"></fa-icon> Please note that merging companies is a permanent change and will
      result in the deactivation of the "old" company record and only the one which was merge to will be kept active.
    </div>
    <div class="col-12 harmony-grey-text small">
      <fa-icon [icon]="faInfo" size="sm"></fa-icon> The AgentID will be applied to the remaining company and all
      PreferredAgent, RateRequests and RateGroup data will be transferred. CompanyDetail data will be lost.
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-5 text-center">
      <b>Merge From</b>
    </div>
    <div class="col-5 offset-2 text-center">
      <b>Merge To</b>
    </div>
  </div>
  <div class="row mt-1">
    <div class="col-5">
      <app-company-search class="d-inline"
                          [Identifier]="'MergeFromCompany'"
                          [ShowError]="invalid && (agentMergeFrom == null || agentMergeFrom.AgentId == null)"
                          [SelectedID]="mergeFromCompanyId"
                          (OnSelectObject)="setMergeFromCompany($event)">
      </app-company-search>
    </div>
    <div class="col-2 text-center harmony-grey-text">
      <fa-icon [icon]="faArrowRight" size="2x"></fa-icon>
    </div>
    <div class="col-5">
      <app-company-search class="d-inline"
                          [Identifier]="'MergeToCompany'"
                          [ShowError]="invalid && (agentMergeTo == null || agentMergeTo.AgentId == null)"
                          [SelectedID]="mergeToCompanyId"
                          (OnSelectObject)="setMergeToCompany($event)">
      </app-company-search>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-4 offset-4 text-center border-bottom">
      <b>Merge Preview</b>
    </div>
  </div>
  <div class="row mt-1">
    <div class="col-5 text-end">
      <span style="text-decoration: line-through;">{{ agentMergeFrom ? agentMergeFrom.CompanyId : '' }}</span>
    </div>
    <div class="col-2 text-center">
      Company ID
    </div>
    <div class="col-5 text-start">
      {{ agentMergeTo ? agentMergeTo.CompanyId : '' }}
    </div>
  </div>
  <div class="row">
    <div class="col-5 text-end">
      <span style="text-decoration: line-through;">{{ agentMergeFrom ? (agentMergeFrom.AgentId ? agentMergeFrom.AgentId : '-') : '' }}</span>
    </div>
    <div class="col-2 text-center">
      Agent ID
    </div>
    <div class="col-5 text-start">
      <span *ngIf="agentMergeTo && agentMergeTo.AgentId" style="text-decoration: line-through;">{{ agentMergeTo.AgentId }}
      </span> {{ agentMergeFrom ? (agentMergeFrom.AgentId ? agentMergeFrom.AgentId : '-') : '' }}
    </div>
  </div>
</div>
<div class="modal-footer">
  <app-loader *ngIf="loading" class="ms-2" [Width]="20"></app-loader>
  <button type="button" class="btn btn-primary harmony-tertiary-button" (click)="save()">Merge</button>
  <button type="button" class="btn btn-primary harmony-primary-button" (click)="close()">Close</button>
</div>
