<div class="dashboard-background"></div>
<div class="dashboard-wrapper">
  <div class="dashboard-content">
    <!-- Client Services -->
    <div class="three-by-two d-flex align-items-start services-card">
      <div>
        <div class="services-card-header row">
          <div class="col-12">
            <!--Header-->
            <h5 class="harmony-primary-text fw-bold">Client Services</h5>
            <hr class="mt-0 mb-1">
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-3">
            <div class="justify-content-center m-2">

              <img [src]="env.SiteUrls.AzureStorageBaseURL + env.ContainerNames.WebImagesServices + 'customer-service-principles_1.jpg'"
                   class="service-image-rounded-borders"
                   alt="Client Services">

            </div>
          </div>
          <div class="col-9 service-content">
            <p>
              Strategically, the Harmony Relocation Head Office is not actively selling to the corporate market anymore. Prior to 2015 we were, therefore there are still a couple of Key Global Accounts that are held by Head Office. For these accounts, we perform the following activities:
            </p>
            <ul class="service-list">
              <li><span>Move Management, including billing</span></li>
              <li><span>Claims Settlement</span></li>
              <li><span>Quality Measurement</span></li>
              <li><span>Reporting</span></li>
              <li><span>Account Management</span></li>
            </ul>
            <p>
              We endeavour to involve as much Member Companies as possible in the execution of these accounts.
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- Client Services Contact -->
    <div class="one-by-one services-card">
      <div class="services-card-header row">
        <div class="col-12">
          <!--Header-->
          <h5 class="harmony-primary-text fw-bold">Client Services Contact</h5>
          <hr class="mt-0 mb-1">
        </div>
      </div>
      <div class="row">
        <div class="col-12 ms-4">
          <div class="row">
            <div class="col-12 service-contact">
              Mr Willem-Frederik van der Heijden
            </div>
          </div>
          <div class="row my-1">
            <div class="col-1">
              <fa-icon [icon]="faEnvelope"></fa-icon>
            </div>
            <div class="col-11 nav-item">
              <a href="mailto:w.heijden@harmonyrelo.com?Subject=Request information about Services/Client-Services" class="harmony-secondary-link">w.heijden@harmonyrelo.com</a>
            </div>
          </div>
          <div class="row my-1">
            <div class="col-1">
              <fa-icon [icon]="faPhone"></fa-icon>
            </div>
            <div class="col-11">
              <a href="callto:+31 294 299 066" class="harmony-secondary-link">+31 294 299 066</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Move Management -->
    <div class="three-by-three d-flex align-items-start services-card">
      <div>
        <div class="services-card-header row">
          <div class="col-12">
            <!--Header-->
            <h5 class="harmony-primary-text fw-bold">Move Management</h5>
            <hr class="mt-0 mb-1">
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-3">
            <div class="justify-content-center m-2">
              <img [src]="env.SiteUrls.AzureStorageBaseURL + env.ContainerNames.WebImagesServices + 'puzzelstukje-sparren%20HRN%20small.jpg'"
                   class="service-image-rounded-borders"
                   alt="Move Management">
            </div>
          </div>
          <div class="col-9 service-content">
            <p>
              <span><h5 class="harmony-secondary-text fw-bold">Move Management Services</h5></span>
              Members can be requested to provide quotations for origin, destination or door-to-door services. When awarded a move, it is mandatory to comply with the procedures as described in our Quality manual, or specific account related instructions. Furthermore, data must be entered into RedSky.
              Approved invoices will be paid on time.
            </p>
            <p>
              Some head office accounts are move managed by a selected member company. This is done for a specific reason, and can be related to time zone, language or other account preferences.
            </p>
            <br>
            <p>
              <span><h5 class="harmony-secondary-text fw-bold">Claims</h5></span>
              In case of an insurance claim, the member is requested to follow the instructions of the Move Coordinator.
            </p>
            <br>
            <p>
              <span><h5 class="harmony-secondary-text fw-bold">Complaints</h5></span>
              If an assignee of an account files a complaint, you should notify the central Move Manager immediately. We urge you to be be pro-active, client oriented, communicative and supportive in finding a solution for the complaint as quickly as possible. A complaint is solved once the assignee/account has confirmed this.
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- Client Services Admin -->
  </div>
</div>
