import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { faAngleRight, faCircleDot } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { AdAuthService } from '../../../core/ad-auth-service/ad-auth.service';
import { PermissionCodes } from '../../../core/constants/permission-codes';
import { faCalendarDays } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-events-menu',
  templateUrl: './events-menu.component.html',
  styleUrls: ['./events-menu.component.scss']
})
export class EventsMenuComponent implements OnInit, AfterViewChecked {
  // Icons
  faEvents = faCalendarDays;
  faMenuItem = faCircleDot;
  faExpandable = faAngleRight;

  // Menu permissions
  dashboard: boolean = false;

  // Component variables
  public logger = (this as any).constructor.name as string;

  constructor(public router: Router,
              public authService: AdAuthService,
              private cdRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    if (this.authService.CurrentUser) {
      this.setPermissions();
    }

    this.authService.CurrentUser$.subscribe(() => {
      this.setPermissions();
    });
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  setPermissions() {
    this.dashboard = this.authService.CheckPermissionByCode(PermissionCodes.Events_Dashboard);
  }
}
