<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom sticky-header">
  <h1 class="portal-component-header">
    {{ activeTraining == null ? 'Training Courses' : activeTraining.Name }}
  </h1>
  <button class="btn btn-sm btn-primary harmony-primary-button float-end"
          (click)="backToOverview()"
          *ngIf="activeTraining != null"
          [title]="'Back to course overview'">
    Back to course list
  </button>
</div>
<div class="course-container">
  <ng-container *ngIf="!activeTraining">
    <div class="row harmony-grey-text">
      <div class="col-12">
        <small>
          We hope you enjoy the course and would love to hear your feed back. If your require further information please don't hesitate to
          <a class="harmony-tertiary-link" href="mailto:v.cremers@harmonyrelo.com">contact us</a>.
        </small>
      </div>
    </div>
    <div class="course-resources">
      <div class="row my-3 border-bottom" *ngFor="let training of trainingResources">
        <div class="col-10">
          <div class="row">
            <div class="col-12">
              <h5 class="harmony-accent-blue-grey-text fw-bold">{{ training.Name }}</h5>
            </div>
          </div>
          <div class="row">
            <div class="col-12 course-description">
              <small>{{ training.Description }}</small>
            </div>
          </div>
        </div>
        <div class="col-2 align-content-center pt-4">
          <button class="btn btn-sm btn-outline-light harmony-primary-outline-button float-end"
                  (click)="openTrainingManual(training)"
                  *ngIf="userHasStartedCourse(training.Id)"
                  [title]="'Continue'">
            Continue
          </button>
          <button class="btn btn-sm btn-primary harmony-tertiary-button float-end"
                  (click)="openTrainingManual(training)"
                  *ngIf="!userHasStartedCourse(training.Id)"
                  [title]="'Open training course'">
            Start course
          </button>
        </div>
      </div>
      <!--------------------->
      <!------ Loader ------->
      <!--------------------->
      <div class="row mt-4" *ngIf="loading">
        <div class="col-12 text-center">
          <app-loader [Width]="50"></app-loader>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="activeTraining">
    <div *ngIf="activeTraining" class="course-wrapper" [innerHTML]="courseHtml"></div>
  </ng-container>
</div>
