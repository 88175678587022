import { Inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { InteractionStatus, InteractionType } from '@azure/msal-browser';
import { ApiService } from '../../services/api.service';
import { filter, takeUntil } from 'rxjs/operators';
import {IAuthTokenResponse, IAuthUser, IPermission, IModule, IRole, IModuleOverview, IPermissionParam} from '../../services/models/auth.model';
import { StorageService } from '../../services/storage.service';
import * as jwt_decode from 'jwt-decode';
import { AlertService } from '../../services/alert.service';
import { ICreateADUser, IUser, IUserPermission, IUserRoleDto } from '../../services/models/user.model';
import { Router, RouterStateSnapshot } from '@angular/router';
import { SessionStorageVariables } from '../constants/session-storage-variables';
import { IAuthUserCompany } from '../../services/models/member.model';
import {IPage} from '../../shared/models/pagination-data.model';
import {IOrderParam} from '../../shared/directives/sort/order.directive';
import {IApiResponse} from '../../shared/models/api-reponse.model';

@Injectable({
  providedIn: 'root'
})
export class AdAuthService implements OnDestroy {
  public HideMenu: boolean = false;
  public IsLoggedIn = false;
  public IsAuthorized = false;
  public CurrentUser: IAuthUser = null;
  public CurrentUser$ = new Subject<IAuthUser>();
  public Roles$: BehaviorSubject<IRole[]> = new BehaviorSubject<IRole[]>([]);
  public AllowedRoles$: BehaviorSubject<IRole[]> = new BehaviorSubject<IRole[]>([]);
  public Modules$: BehaviorSubject<IModuleOverview[]> = new BehaviorSubject<IModuleOverview[]>([]);
  public Components$: BehaviorSubject<IPermission[]> = new BehaviorSubject<IPermission[]>([]);
  private unsubscribe: Subject<any> = new Subject<any>();

  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
              private msalService: MsalService,
              private api: ApiService,
              private router: Router,
              private storage: StorageService,
              private alertService: AlertService,
              private msalBroadcastService: MsalBroadcastService) {
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  public LoadLookupData() {
    this.LoadModules();
    this.LoadRoles();
    this.LoadPermissions();
  }

  SetLoggedInStatus() {
    const accounts = this.msalService.instance.getAllAccounts();
    this.IsLoggedIn = accounts.length > 0;
    if (this.IsLoggedIn) {
      const activeAccount = accounts[0];
      this.msalService.instance.setActiveAccount(activeAccount);
      const userName = activeAccount.username;
      this.LoadLookupData();
      this.GetUserPermissions(userName).subscribe({
        next: (data) => {
          this.SetAuthToken(data, userName);
          // Record a "Last Login" date, which indicates a user accessing the system
          this.RecordLogin();
          //  After successful login, check if a specific route was hit first, then redirect
          this.checkInitialRoute();
        }, error: () => {
          this.Logout();
        }
      });
    } else {
      this.IsLoggedIn = false;
      this.CurrentUser = null;
      this.removeSessionTokens();
      this.Login(null);
    }
  }

  removeSessionTokens() {
    this.storage.removeSessionItem(SessionStorageVariables.UserToken);
    this.storage.removeSessionItem(SessionStorageVariables.UserID);
    this.storage.removeSessionItem(SessionStorageVariables.CompanyID);
  }

  checkInitialRoute() {
    const initialRoute = this.storage.getSessionItem(SessionStorageVariables.InitialStateUrl);
    if (initialRoute) {
      // User is directed to the original url that was entered
      this.router.navigate([initialRoute]).then(() => {
        this.storage.removeSessionItem(SessionStorageVariables.InitialStateUrl);
      });
    }
  }

  // When a given Portal URL is entered into browser
  // 1. Trigger the B2C login
  // 2. Store the state of the initial URL
  Login(state: RouterStateSnapshot = null) {
    if (state) {
      this.storage.storeSessionItem(SessionStorageVariables.InitialStateUrl, state?.url ?? '');
    }

    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.msalService.loginPopup().subscribe(() => {
        this.SetLoggedInStatus();
      });
    } else {
      this.msalBroadcastService.inProgress$.pipe(
          filter((status) => status === InteractionStatus.None),
        ).subscribe(() => {
          this.msalService.loginRedirect();
        });
    }
  }

  Logout() {
    this.IsLoggedIn = false;
    this.IsAuthorized = false;
    this.CurrentUser = null;
    this.removeSessionTokens();

    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.msalService.logoutPopup({
        mainWindowRedirectUri: '/'
      });
    } else {
      this.msalService.logoutRedirect();
    }
  }

  LoadUserPermissions(userName: string) {
    this.GetUserPermissions(userName).subscribe(
      {
        next: (data) => {
          this.SetAuthToken(data, userName);
        },
        error: () => {
          this.Logout();
        }
      });
  }

  private SetAuthToken(data: IAuthTokenResponse, userName: string) {
    if (data) {
      // Save JWT Token in sessionStorage
      const AuthToken: IAuthTokenResponse = Object.assign({}, data);
      this.storage.storeSessionItem(SessionStorageVariables.UserToken, AuthToken.Token);
      // Decode JWT token data section and set the current user
      const decoded: any = jwt_decode.default(AuthToken.Token);
      this.IsAuthorized = true;
      this.SetCurrentUser(decoded);
      this.LoadAllowedRoles();
    } else {
      alert('No data found for username: ' + userName);
      this.Logout();
    }
  }

  public GetUserPermissions(userName: string) {
    return this.api.get(`Auth/UserPermissions/${userName}`).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public RecordLogin() {
    this.api.post('Auth/Login')
      .pipe(takeUntil(this.unsubscribe))
      .subscribe();
  }

  public SetCurrentUser(decoded: any) {
    // Parse json strings to objects and set the user
    this.CurrentUser = {
      UserId: +decoded.UserID,
      User: JSON.parse(decoded.User),
      UserRoles: JSON.parse(decoded.UserRoles),
      Permissions: JSON.parse(decoded.Permissions),
      UserCompanies: JSON.parse(decoded.UserCompanies)
    } as IAuthUser;
    this.CurrentUser$.next(this.CurrentUser);
    // Set session values for request headers
    this.storage.storeSessionItem(SessionStorageVariables.UserID, this.CurrentUser.UserId.toString());
    this.storage.storeSessionItem(SessionStorageVariables.CompanyID, this.CurrentUser.User.CompanyId.toString());
  }

  public SetActiveCompany(companyId: number, companyName: string) {
    if (companyId && companyId > 0) {
      this.SwitchCompanies(companyId).subscribe({
        next: (data: boolean) => {
          if (data && data === true) {
            this.storage.storeSessionItem(SessionStorageVariables.CompanyID, companyId.toString());
            this.LoadUserPermissions(this.CurrentUser.User.Username);
            this.alertService.success('Company changed successfully. You are now logged in for ' + companyName);
            this.CurrentUser$.subscribe(() => {
              this.router.navigateByUrl('').then(() => {
              }).catch();
            });
          }
        },
        error: () => {
          this.alertService.warn('Unable to switch members.');
        }
      });
    }
  }

  public GetCurrentAuthenticatedUser() {
    // Get JWT token from session
    const token: string = this.storage.getSessionItem(SessionStorageVariables.UserToken);

    if (token) {
      // Decode JWT token data section and set the current user
      const decoded: any = jwt_decode.default(token);

      return {
        UserId: +decoded.UserID,
        User: JSON.parse(decoded.User),
        UserRoles: JSON.parse(decoded.UserRoles),
        Permissions: JSON.parse(decoded.Permissions)
      } as IAuthUser;
    } else {
      return null;
    }
  }

  public CheckPermissionByCode(code: string) {
    if (code != null && code.length > 0) {
      if (this.CurrentUser?.Permissions) {
        return this.hasPermission(this.CurrentUser.Permissions, code);
      } else {
        const authUser = this.GetCurrentAuthenticatedUser();
        if (authUser?.Permissions) {
          return this.hasPermission(authUser.Permissions, code);
        }
      }
    }
    return false;
  }

  public CheckRole(roleName: string) {
    if (roleName == null) {
      return true;
    } else {
      if (this.CurrentUser) {
        const userRoles = this.CurrentUser.UserRoles;
        const hasRole = userRoles.find((x) => x.RoleName === roleName);
        return !!hasRole;
      } else {
        return false;
      }
    }
  }

  public CheckRoleById(roleId: number) {
    if (roleId == null) {
      return true;
    } else {
      if (this.CurrentUser) {
        const userRoles = this.CurrentUser.UserRoles;
        const hasRole = userRoles.find((x) => x.RoleId === roleId);
        return !!hasRole;
      } else {
        return false;
      }
    }
  }

  hasPermission(permissions: IUserPermission[], code: string) {
    const hasPermission = permissions.find((x) => x.Code.trim() === code);
    return !!hasPermission;
  }

  /////////////////////////
  // Azure B2C Functions //
  /////////////////////////
  public GetUsers(): Observable<any> {
    return this.api.get('B2C/GetUsers').pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public GetADUserByUsername(username: string): Observable<any> {
    return this.api.get(`B2C/GetUserByUsername/${username}`).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public GetUserExists(username: string): Observable<any> {
    return this.api.get(`B2C/GetUserExists/${username}`).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  ////////////////////////
  //// User Functions ////
  ////////////////////////
  public GetUserById(userId: number): Observable<IUser> {
    return this.api.get(`User/${userId}`).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public UpsertUser(user: IAuthUser) {
    return this.api.post('User/Upsert', user).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public CreateADUserOnly(user: IUser, sendNotification: boolean): Observable<any> {
    const param = {
      Id: user.Id,
      IntranetUserId: user.IntranetUserId,
      CompanyId: user.CompanyId,
      CompanyName: user.Company.Name,
      Username: user.Username,
      UserTypeId: user.UserTypeId,
      IsVerified: user.IsVerified,
      FirstName: user.FirstName,
      LastName: user.LastName,
      Email: user.Email,
      DisplayName: user.DisplayName
    } as ICreateADUser;

    return this.api.post(`Auth/CreateADUserOnly/${sendNotification}`, param).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public SwitchCompanies(companyId: number) {
    return this.api.put(`User/SwitchCompany/${companyId}`).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public ToggleUserActive(userId: number, activate: boolean) {
    if (activate === true) {
      return this.api.put(`User/Enable/${userId}`).pipe(
        takeUntil(this.unsubscribe)
      );
    } else {
      return this.api.put(`User/Deactivate/${userId}`).pipe(
        takeUntil(this.unsubscribe)
      );
    }
  }

  public UpdateUser(user: IUser) {
    return this.api.put('User/UpdateUser', user).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public GetUserRoles(userId: number, companyId: number): Observable<IUserRoleDto[]> {
    return this.api.get(`UserRole/${userId}/${companyId}`).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public GetUsersByRoleId(roleId: number) {
    return this.api.get(`User/ByRole/${roleId}`).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  ////////////////////////
  //// User Companies ////
  ////////////////////////
  public GetUserCompanies(userId: number): Observable<IAuthUserCompany[]> {
    return this.api.get(`UserCompany/GetByUserId/${userId}/`).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public GetUserCompaniesForPermission(permissionCode: string): Observable<IAuthUserCompany[]> {
    return this.api.get(`Auth/UserCompaniesByPermission/${permissionCode}`).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  ///////////////////
  ///// Modules /////
  ///////////////////
  public LoadModules() {
    this.api.get('Module/List').pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data: IModuleOverview[]) => {
        if (data) {
          this.Modules$.next(data);
        }
      },
      error: () => {
        console.log('WARNING: Failed to load modules.');
      }
    });
  }

  public SearchModules(page: IPage, order: IOrderParam, searchText: string) {
    const param = {
      SearchText: searchText,
    } as any;

    return this.api.post(`Module/Search?pageNumber=${page.pageNumber}&pageSize=${page.pageSize}&orderBy=${order.OrderBy}&order=${order.OrderDirection}`, param).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public DeleteModule(id: number) {
    this.api.delete(`Module/${id}`).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data: IModule) => {
        if (data) {
          this.alertService.success('Module has been successfully deleted.');
          this.LoadModules();
        }
      },
      error: (err: IApiResponse) => {
        this.alertService.error('Error occurred deleting module: ' + err?.Meta?.Message);
      }
    });
  }

  ///////////////////
  /// Permissions ///
  ///////////////////
  public LoadPermissions() {
    this.api.get('Component/List').pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data: IPermission[]) => {
        if (data) {
          this.Components$.next(data);
        }
      },
      error: () => {
        console.log('WARNING: Failed to load components.');
      }
    });
  }

  public SearchPermissions(page: IPage, order: IOrderParam, param: IPermissionParam) {
    return this.api.post(`Component/Search?pageNumber=${page.pageNumber}&pageSize=${page.pageSize}&orderBy=${order.OrderBy}&order=${order.OrderDirection}`, param).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public DeletePermission(id: number) {
    this.api.delete(`Component/${id}`).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: () => {
        this.alertService.success('Permission has been successfully deleted.');
        this.LoadPermissions();
      },
      error: (err) => {
        this.alertService.error('Error occurred deleting permission: ' + err?.Meta?.Message);
      }
    });
  }

  /////////////////
  ///// Roles /////
  /////////////////
  public LoadRoles() {
    this.api.get('Role/List').pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data) => {
        if (data) {
          this.Roles$.next(data);
        }
      },
      error: () => {
        console.log('WARNING: Failed to load Roles.');
      }
    });
  }

  public LoadAllowedRoles() {
    this.api.get(`Role/Allowed`).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data: IRole[]) => {
        if (data) {
          this.AllowedRoles$.next(data);
        }
      },
      error: () => {
        console.log('WARNING: Failed to load allowed Roles.');
      }
    });
  }

  public SearchRoles(page: IPage, order: IOrderParam, searchText: string) {
    const param = {
      SearchText: searchText,
    } as any;

    return this.api.post(`Role/Search?pageNumber=${page.pageNumber}&pageSize=${page.pageSize}&orderBy=${order.OrderBy}&order=${order.OrderDirection}`, param).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public GetRolesWithPermission(code: string) {
    return this.api.get(`Role/Permission/${code}`).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public DeleteRole(id: number) {
    this.api.delete(`Role/${id}`).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: () => {
        this.alertService.success('Role has been successfully deleted.');
        this.LoadRoles();
        this.LoadAllowedRoles();
      },
      error: (err) => {
        this.alertService.error('Error occurred deleting role: ' + err?.Meta?.Message);
      }
    });
  }
}
