import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../../../../../services/alert.service';
import { AdAuthService } from '../../../../../core/ad-auth-service/ad-auth.service';
import { ApiService } from '../../../../../services/api.service';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { IPermission, IRole } from '../../../../../services/models/auth.model';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import {IApiResponse} from '../../../../../shared/models/api-reponse.model';

@Component({
  selector: 'app-permission-modal',
  templateUrl: './permission-modal.component.html',
  styleUrls: ['./permission-modal.component.scss']
})
export class PermissionModalComponent implements OnInit, OnDestroy {
  // Icons
  protected readonly faInfo = faCircleQuestion;
  protected readonly faValid = faCheck;
  protected readonly faInvalid = faXmark;

  // Component Variables
  public ComponentId: number;
  public IsNew: boolean;
  private codeChanged: Subject<string> = new Subject<string>();
  private subscription: Subscription;
  permission: IPermission;
  uniqueCodeExists: boolean = false;
  invalid = false;

  // Lookup data
  modules$ = this.authService.Modules$.asObservable();
  roles$: BehaviorSubject<IRole[]> = new BehaviorSubject<IRole[]>([]);

  // General variables
  loading: boolean = false;
  private unsubscribe: Subject<any> = new Subject<any>();

  constructor(public activeModal: NgbActiveModal,
              private api: ApiService,
              private authService: AdAuthService,
              private alertService: AlertService) {
  }

  ngOnInit(): void {
   this.subscription = this.codeChanged
      .pipe(
        debounceTime(500),
      )
      .subscribe((data) => {
        this.checkCode(data);
    });

   if (this.IsNew) {
      this.permission = {
        Id: null,
        Active: true,
        DateCreated: new Date(),
        DateModified: null,
        Name: '',
        Code: null,
        Description: null,
        ModuleId: null,
        ModuleName: null
      } as IPermission;
    } else {
      this.loadComponent();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  updateCode(event: any) {
    this.codeChanged.next(event.target.value);
  }

  loadComponent() {
    this.loading = true;

    this.api.get(`Component/Single/${this.ComponentId}`).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data: IPermission) => {
        if (data) {
          this.permission = Object.assign({}, data);
          this.getRoles();
        }
        this.loading = false;
      }, error: (err: IApiResponse) => {
        this.alertService.error(err?.Meta?.Message);
        this.loading = false;
      }
    });
  }

  checkCode(code: string) {
    this.loading = true;
    this.permission.Code = code;

    this.api.get(`Component/CheckCode/${this.permission.Code}`).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (exists: boolean) => {
        this.uniqueCodeExists = exists;
        this.loading = false;
      }, error: () => {
        this.loading = false;
      }
    });
  }

  getRoles() {
    if (this.permission.Code?.length > 0) {
      this.authService.GetRolesWithPermission(this.permission.Code).pipe(
        takeUntil(this.unsubscribe)
      ).subscribe({
        next: (data: IRole[]) => {
          if (data) {
            this.roles$.next(data);
          }
        }
      });
    }
  }

  saveComponent() {
    if (this.canSave()) {
      this.loading = true;

      this.api.post('Component/Update', this.permission).pipe(
        takeUntil(this.unsubscribe)
      ).subscribe({
        next: (data) => {
          if (data) {
            this.alertService.success('Component update successful!');
            this.activeModal.close(data);
          }
          this.loading = false;
        }, error: (err) => {
          this.alertService.error(err?.Meta?.Message);
          this.loading = false;
        }
      });
    } else {
      this.invalid = true;
    }
  }

  canSave() {
    return !!(this.permission.Name.length > 3 &&
      this.permission.ModuleId != null &&
      this.permission.Code.length === 8 &&
      !this.uniqueCodeExists);
  }

  close() {
    this.activeModal.close(null);
  }
}
