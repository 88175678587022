<!---------------------->
<!------- Header ------->
<!---------------------->
<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-2 pb-2 mb-3 border-bottom sticky-header">
  <h1 class="portal-component-header">
    Capture Rates
  </h1>
  <div class="btn-toolbar mb-2 mb-md-0">
    <div class="btn-group">
      <button type="button"
              *ngIf="activeRequest && activeRequest.Id != null"
              (click)="backToOverview()"
              class="btn btn-sm harmony-teal-outline-button">
        Back to overview
      </button>
    </div>
  </div>
</div>
<!------------------------------->
<!--- Searchbar with filters ---->
<!------------------------------->
<ng-container *ngIf="!activeRequest || activeRequest.Id == null">
  <div class="row">
    <div class="col-4">
      <div class="row">
        <div class="col-4">
          <b>Reference</b>
        </div>
        <div class="col-8">
          <input type="text"
                 numbersOnly="10.0"
                 class="form-control form-control-sm"
                 [placeholder]="'Enter RateGroup ID'"
                 [(ngModel)]="searchParam.RateGroupId">
        </div>
      </div>
    </div>
    <div class="col-4" *ngIf="userRateCaptureCompanies && userRateCaptureCompanies.length > 1">
      <div class="row">
        <div class="col-4">
          <b>Company</b>
        </div>
        <div class="col-8">
          <select class="form-select form-select-sm pointer"
                  [disabled]="!userRateCaptureCompanies"
                  [(ngModel)]="searchParam.CompanyId"
                  (change)="searchFilter()">
            <option [ngValue]="null" selected>All companies...</option>
            <option *ngFor="let company of userRateCaptureCompanies" [ngValue]="company.CompanyId">{{ company.CompanyName }}</option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-1">
    <div class="col-4">
      <div class="row">
        <div class="col-4">
          <b>Project</b>
        </div>
        <div class="col-8">
          <select class="form-select form-select-sm pointer"
                  [disabled]="(companyProjects$ | async) && (companyProjects$ | async)?.length <= 0"
                  [(ngModel)]="searchParam.ProjectId">
            <option [ngValue]="null" selected>All projects...</option>
            <option *ngFor="let project of (companyProjects$ | async)" [ngValue]="project.Id">{{ project.Name }}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="row">
        <div class="col-4">
          <b>Origin</b>
        </div>
        <div class="col-8">
          <select class="form-select form-select-sm pointer"
                  [disabled]="(originLocations$ | async) && (originLocations$ | async)?.length <= 0"
                  [(ngModel)]="searchParam.OriginLocationId">
            <option [ngValue]="null" selected>All origins...</option>
            <option *ngFor="let location of (originLocations$ | async)" [ngValue]="location.LocationId">{{ location.LocationName }}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-4 align-self-center">
      <div class="form-check form-switch float-start form-switch-sm">
        <input class="form-check-input me-1"
               type="checkbox"
               role="switch"
               [id]="'switch-expiring'"
               [title]="'Show requests with APPROVED rates that expire within the next month'"
               [(ngModel)]="searchParam.ExpiringSoon">
        <span class="position-relative">Expiring soon</span>
      </div>
    </div>
  </div>
  <div class="row mt-1">
    <div class="col-4">
      <div class="row">
        <div class="col-4">
          <b>Status</b>
        </div>
        <div class="col-8">
          <select class="form-select form-select-sm pointer"
                  [disabled]="(RateRequestStatuses$ | async) && (RateRequestStatuses$ | async)?.length <= 0"
                  [(ngModel)]="searchParam.RequestStatusId">
            <option [ngValue]="null" selected>All statuses...</option>
            <option *ngFor="let status of (RateRequestStatuses$ | async)" [ngValue]="status.Id">{{ status.Name }}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="row">
        <div class="col-4">
          <b>Destination</b>
        </div>
        <div class="col-8">
          <select class="form-select form-select-sm pointer"
                  [disabled]="(destinationLocations$ | async)?.length <= 0"
                  [(ngModel)]="searchParam.DestinationLocationId">
            <option [ngValue]="null" selected>All destinations...</option>
            <option *ngFor="let location of (destinationLocations$ | async)" [ngValue]="location.LocationId">{{ location.LocationName }}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-4 align-self-center">
      <div class="form-check form-switch float-start form-switch-sm">
        <input class="form-check-input me-1"
               type="checkbox"
               role="switch"
               [id]="'switch-expired'"
               [title]="'Show requests with APPROVED rates that have expired'"
               [(ngModel)]="searchParam.Expired">
        <span class="position-relative">Expired rates</span>
      </div>
    </div>
  </div>
  <hr class="mt-2 mb-2">
  <div class="row">
    <div class="col-4 offset-8">
      <div class="row">
        <div class="col-6 text-end pe-0">
          <button class="btn btn-sm btn-primary harmony-teal-outline-button ms-2"
                  [title]="'Reset search'"
                  (click)="resetSearch()">
            Reset
          </button>
        </div>
        <div class="col-6 text-end ps-1">
          <button class="btn btn-sm btn-primary harmony-purple-button w-100"
                  (click)="searchFilter()">
            Search
          </button>
        </div>
      </div>
    </div>
  </div>
  <hr class="mt-2 mb-0">
  <!------------------->
  <!---- Pagination --->
  <!------------------->
  <div class="row ms-1 me-1">
    <div class="col-12">
      <app-pagination [showPageNumber]=true
                      [showRowsPerPage]=true
                      [paginationData]=paginationData
                      (pageEvent)="onPage($event)"
                      [name]="'RCOverview'"
                      [page]="page">
      </app-pagination>
    </div>
  </div>
  <!--------------------->
  <!---- Result table --->
  <!--------------------->
  <div class="row mt-1 header-row">
    <div class="col-2 col-compress pointer header-title border-split-left"
         (appOrder)="orderSearch($event)"
         data-column="RateRequestType.Name"
         data-order="asc">
      Type
      <fa-icon [hidden]="orderParam.OrderBy != 'RateRequestType.Name'" class="ms-2 position-relative"></fa-icon>
    </div>
    <!-- Origin -->
    <div class="col-3 col-compress pointer header-title"
         (appOrder)="orderSearch($event)"
         data-column="OriginCity.InfoName"
         data-order="asc">
      Origin
      <fa-icon [hidden]="orderParam.OrderBy != 'OriginCity.InfoName'" class="ms-2 position-relative"></fa-icon>
    </div>
    <!-- Destination -->
    <div class="col-3 col-compress pointer header-title"
         (appOrder)="orderSearch($event)"
         data-column="DestinationCity.InfoName"
         data-order="asc">
      Destination
      <fa-icon [hidden]="orderParam.OrderBy != 'DestinationCity.InfoName'" class="ms-2 position-relative"></fa-icon>
    </div>
    <!-- Other -->
    <div class="col-2 col-compress pointer header-title"
         (appOrder)="orderSearch($event)"
         data-column="RateRequestStatus.Name"
         data-order="asc">
      Status
      <fa-icon [hidden]="orderParam.OrderBy != 'RateRequestStatus.Name'" class="ms-2 position-relative"></fa-icon>
    </div>
    <div class="col-1 col-compress pointer header-title"
         (appOrder)="orderSearch($event)"
         data-column="ResponseDate"
         data-order="asc">
      Respond By
      <fa-icon [hidden]="orderParam.OrderBy != 'ResponseDate'" [icon]="faSortDesc" style="bottom: -3px;" class="ms-2 position-relative"></fa-icon>
    </div>
    <div class="col-1 col-compress header-title border-split-right">

    </div>
  </div>
  <!----------------------->
  <!------- Results ------->
  <!----------------------->
  <ng-container *ngIf="(paginationData && paginationData.DataSet.length > 0) && !loading">
    <div class="row result-row" *ngFor='let request of paginationData.DataSet; let i = index'>
      <div class="col-2 col-compress overflow-hidden" [title]="request.ModalityTypeName">
        <b>{{ request.RateRequestTypeName }}</b>
        <br>
        <small>{{ request.AgentName }}</small>
      </div>
      <!-- Origin -->
      <div class="col-3 col-compress overflow-hidden" [title]="request.OriginLocationName">
        <small>
          <!-- Location types City, Country, State -->
          <fa-icon [icon]="faLocation" size="sm" class="me-1 harmony-purple-text"></fa-icon>
          {{ request.OriginLocationName ? request.OriginLocationName : '-' }}
          <br>
          <!-- Location types Seaport or Airport -->
          <fa-icon *ngIf="request.RateRequestTypeName?.toLowerCase().includes('air')" [icon]="faDepartureAir" size="sm" class="me-1 harmony-teal-text"></fa-icon>
          <fa-icon *ngIf="request.RateRequestTypeName?.toLowerCase().includes('sea')" [icon]="faSea" size="sm" class="me-1 harmony-teal-text"></fa-icon>
          <fa-icon *ngIf="request.RateRequestTypeName?.toLowerCase().includes('road')" [icon]="faRoad" size="sm" class="me-1 harmony-teal-text"></fa-icon>
          {{ request.DeparturePortName ?  request.DeparturePortName : '-' }}
        </small>
      </div>
      <!-- Destination -->
      <div class="col-3 col-compress overflow-hidden" [title]="request.DestinationLocationName">
        <small>
          <!-- Location types City, Country, State -->
          <fa-icon [icon]="faLocation" size="sm" class="me-1 harmony-purple-text"></fa-icon>
          {{ request.DestinationLocationName ? request.DestinationLocationName : '-' }}
          <br>
          <!-- Location types Seaport or Airport -->
          <fa-icon *ngIf="request.RateRequestTypeName?.toLowerCase().includes('air')" [icon]="faArrivalAir" size="sm" class="me-1 harmony-teal-text"></fa-icon>
          <fa-icon *ngIf="request.RateRequestTypeName?.toLowerCase().includes('sea')" [icon]="faSea" size="sm" class="me-1 harmony-teal-text"></fa-icon>
          <fa-icon *ngIf="request.RateRequestTypeName?.toLowerCase().includes('road')" [icon]="faRoad" size="sm" class="me-1 harmony-teal-text"></fa-icon>
          {{ request.ArrivalPortName?  request.ArrivalPortName : '-' }}
        </small>
      </div>
      <!-- Other -->
      <div class="col-2 col-compress overflow-hidden align-self-center"
           [ngClass]="{'harmony-green-text': request.RateRequestStatusName == 'Completed'}"
           [title]="request.RateRequestStatusName">
        {{ request.RateRequestStatusName }}
      </div>
      <div class="col-1 col-compress overflow-hidden align-self-center"
           [ngClass]="deadlineClass(request)">
        <small>{{ request.RespondByDate ? (request.RespondByDate | date: environment.FormattingStandards.ShortDateFormat) : '-' }}</small>
      </div>
      <div class="col-1 col-compress text-center align-self-center pe-0">
        <button class="btn btn-sm btn-outline-light harmony-purple-outline-button"
                [title]="'Accept/Decline request'"
                *ngIf="request.RateRequestStatusName == 'Pending'"
                (click)="reviewRequest(request)">
          <fa-icon [icon]="faEye" size="sm"></fa-icon>
        </button>
        <button class="btn btn-sm btn-outline-success ms-1"
                [title]="'Manage request'"
                *ngIf="request.RateRequestStatusName == 'Pending'"
                (click)="acceptRequest(request)">
          <fa-icon [icon]="faSuccess" size="sm"></fa-icon>
        </button>
        <button class="btn btn-sm btn-outline-danger ms-1"
                [title]="'Manage request'"
                *ngIf="request.RateRequestStatusName == 'Pending'"
                (click)="declineRequest(request)">
          <fa-icon [icon]="faDeclined" size="sm"></fa-icon>
        </button>
        <button class="btn btn-sm btn-outline-light harmony-teal-outline-button"
                [title]="'Manage request'"
                *ngIf="request.RateRequestStatusName == 'Accepted'"
                (click)="manageRequest(request)">
          <fa-icon [icon]="faEditIcon" size="sm"></fa-icon>
        </button>
        <button class="btn btn-sm btn-outline-light harmony-teal-outline-button"
                [title]="'Manage request'"
                *ngIf="request.RateRequestStatusName != 'Accepted' && request.RateRequestStatusName != 'Pending'"
                (click)="manageRequest(request)">
          <fa-icon [icon]="faEye" [size]="'1x'" [title]="'Completed'" *ngIf="request.RateRequestStatusName == 'Completed'"></fa-icon>
          <fa-icon [icon]="faWaiting" [size]="'1x'" [title]="'Awaiting review by Harmony'" class="harmony-blue-grey-text" *ngIf="request.RateRequestStatusName == 'Awaiting review'"></fa-icon>
          <fa-icon [icon]="faDeclined" [size]="'1x'" [title]="'Request declined'" class="harmony-coral-text" *ngIf="request.RateRequestStatusName == 'Declined'"></fa-icon>
          <fa-icon [icon]="faRejected" [size]="'1x'" [title]="'Rates rejected by Harmony'" class="text-danger" *ngIf="request.RateRequestStatusName == 'Rejected'"></fa-icon>
          <fa-icon [icon]="faExpired" [size]="'1x'" [title]="'Expired'" class="harmony-yellow-text" *ngIf="request.RateRequestStatusName == 'Pending' && request.RespondByDate > now"></fa-icon>
        </button>
      </div>
    </div>
  </ng-container>
  <!------------------------>
  <!------ No results ------>
  <!------------------------>
  <div class="row mt-4" *ngIf="(paginationData && paginationData.DataSet.length === 0) && !loading">
    <div class="col-12 text-center harmony-grey-text">
      <fa-icon [icon]="faEmpty" size="2x"></fa-icon>
    </div>
    <div class="col-12 text-center harmony-grey-text mt-1">
      <h4>No request for rates has yet been received...</h4>
    </div>
  </div>
  <!---------------------->
  <!------- Loader ------->
  <!---------------------->
  <br>
  <app-loader class="mt-4" *ngIf="loading" [Width]="50"></app-loader>
</ng-container>
<!------------------------>
<!---- Active Request ---->
<!------------------------>
<ng-container *ngIf="activeRequest && activeRequest.Id != null">
  <!--------------------->
  <!------ Actions ------>
  <!--------------------->
  <div class="row mt-2">
    <div class="col-12 info-text">
      <p class="mb-1">
        <fa-icon [icon]="faInfo"></fa-icon>
        Below are all the rates included in the request. Not all rates have to be filled in, but we ask that you try
        to provide as many rates as possible.
      </p>
      <p>
        <fa-icon [icon]="faInfo"></fa-icon>
        Once you are satisfied with the rates provided, the request must be <b>submitted for review</b> by a
        Harmony employee, who will then review and approve the rates so they can be be provided to members
        of the network.
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col-4 align-self-center">
      <div class="row">
        <div class="col-12">
          <h6 style="display: inline; font-weight: 600; margin-bottom: 0">Current Status: </h6>
          <h6 style="display: inline; font-weight: 500; margin-bottom: 0">
            {{ activeRequest.RateRequestStatusName }}
          </h6>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <h6 style="display: inline; font-weight: 600; margin-bottom: 0">Request Deadline: </h6>
          <h6 style="display: inline; font-weight: 500; margin-bottom: 0"
              [ngClass]="deadlineClass(activeRequest)">
            {{ activeRequest.RespondByDate | date:environment.FormattingStandards.ShortDateFormat }}
          </h6> <small class="harmony-grey-text">({{daysUntilDeadline()}} days)</small>
        </div>
      </div>
    </div>
    <div class="col-2 offset-6 align-self-center text-end pe-2">
      <button type="button"
              *ngIf="activeRequest"
              [disabled]="loading"
              (click)="manageRequest(activeRequest)"
              class="btn btn-sm btn-outline-primary harmony-teal-outline-button me-1">
        <fa-icon [icon]="faRefresh"></fa-icon>
      </button>
      <button type="button"
              *ngIf="canSubmitRates()"
              [disabled]="loading"
              (click)="submitRatesForReview()"
              class="btn btn-sm btn-primary harmony-purple-button">
        Submit for review
      </button>
    </div>
  </div>
  <hr class="my-2">
  <!--------------------->
  <!---- Rate groups ---->
  <!--------------------->
  <div class="row mt-1 header-row">
    <div class="col-2 col-compress header-title pointer border-split-left"
         (appOrder)="orderRateGroups($event)"
         data-column="Description"
         data-order="asc">
      Mode
      <fa-icon [hidden]="rateGroupOrderParam.OrderBy != 'Description'" class="ms-2 position-relative"></fa-icon>
    </div>
    <!-- Origin -->
    <div class="col-2 col-compress header-title pointer"
         (appOrder)="orderRateGroups($event)"
         data-column="OriginCity.Name"
         data-order="asc">
      Origin Location
      <fa-icon [hidden]="rateGroupOrderParam.OrderBy != 'OriginCity.Name'" class="ms-2 position-relative"></fa-icon>
    </div>
    <!-- Destination -->
    <div class="col-2 col-compress header-title pointer"
         (appOrder)="orderRateGroups($event)"
         data-column="DestinationCity.Name"
         data-order="asc">
      Destination Location
      <fa-icon [hidden]="rateGroupOrderParam.OrderBy != 'DestinationCity.Name'" class="ms-2 position-relative"></fa-icon>
    </div>
    <!-- Other -->
    <div class="col-2 col-compress header-title pointer"
         (appOrder)="orderRateGroups($event)"
         data-column="Company.Name"
         data-order="asc">
      Agent
      <fa-icon [hidden]="rateGroupOrderParam.OrderBy != 'Company.Name'" class="ms-2 position-relative"></fa-icon>
    </div>
    <div class="col-2 col-compress header-title pointer"
         (appOrder)="orderRateGroups($event)"
         data-column="ValidTo"
         data-order="desc">
      Valid Dates
      <fa-icon [hidden]="rateGroupOrderParam.OrderBy != 'ValidTo'" [icon]="faSortAsc" style="bottom: 3px;"  class="ms-2 position-relative"></fa-icon>
    </div>
    <div class="col-2 text-start col-compress header-title pointer border-split-right"
         (appOrder)="orderRateGroups($event)"
         data-column="RateVerdictID"
         data-order="asc">
      Verdict
      <fa-icon [hidden]="rateGroupOrderParam.OrderBy != 'RateVerdictID'" class="ms-2 position-relative"></fa-icon>
    </div>
  </div>
  <!----------------------->
  <!------- Results ------->
  <!----------------------->
  <ng-container *ngIf="rateGroupsPaginationData && rateGroupsPaginationData.DataSet.length > 0">
    <div class="row result-row" *ngFor="let rg of rateGroupsPaginationData.DataSet">
      <div class="col-2 col-compress overflow-hidden" [title]="rg.Description">
        <b class="fw-bold mb-0">{{ rg.Description ?? '-' }}</b>
        <br>
        <small>{{ rg.RateGroupId }}</small>
      </div>
      <!-- Origin -->
      <div class="col-2 col-compress overflow-hidden" [title]="rg.OriginLocationName">
        <small>
          <!-- Location types City, Country, State -->
          <fa-icon [icon]="faLocation" size="sm" class="me-1 harmony-purple-text"></fa-icon>
          {{ rg.OriginLocationName ? rg.OriginLocationName : '-' }}
          <br>
          <!-- Location types Seaport or Airport -->
          <fa-icon *ngIf="rg.RateRequestTypeName?.toLowerCase().includes('air')" [icon]="faDepartureAir" size="sm" class="me-1 harmony-teal-text"></fa-icon>
          <fa-icon *ngIf="rg.RateRequestTypeName?.toLowerCase().includes('sea')" [icon]="faSea" size="sm" class="me-1 harmony-teal-text"></fa-icon>
          <fa-icon *ngIf="rg.RateRequestTypeName?.toLowerCase().includes('road')" [icon]="faRoad" size="sm" class="me-1 harmony-teal-text"></fa-icon>
          {{ rg.DeparturePortCode ?  rg.DeparturePortCode : '-' }} {{ rg.DeparturePortDescription ? ' - ' + rg.DeparturePortDescription : '' }}
        </small>
      </div>
      <!-- Destination -->
      <div class="col-2 col-compress overflow-hidden" [title]="rg.DestinationLocationName ? rg.DestinationLocationName : rg.ArrivalPortCode">
        <small>
          <!-- Location types City, Country, State -->
          <fa-icon [icon]="faLocation" size="sm" class="me-1 harmony-purple-text"></fa-icon>
          {{ rg.DestinationLocationName ? rg.DestinationLocationName : '-' }}
          <br>
          <!-- Location types Seaport or Airport -->
          <fa-icon *ngIf="rg.RateRequestTypeName?.toLowerCase().includes('air')" [icon]="faArrivalAir" size="sm" class="me-1 harmony-teal-text"></fa-icon>
          <fa-icon *ngIf="rg.RateRequestTypeName?.toLowerCase().includes('sea')" [icon]="faSea" size="sm" class="me-1 harmony-teal-text"></fa-icon>
          <fa-icon *ngIf="rg.RateRequestTypeName?.toLowerCase().includes('road')" [icon]="faRoad" size="sm" class="me-1 harmony-teal-text"></fa-icon>
          {{ rg.ArrivalPortCode ?  rg.ArrivalPortCode : '-' }} {{ rg.ArrivalPortDescription ? ' - ' + rg.ArrivalPortDescription : '' }}
        </small>
      </div>
      <!-- Other -->
      <div class="col-2 col-compress overflow-hidden" [title]="rg.CompanyName">
        {{ rg.CompanyName }}
      </div>
      <div class="col-2 col-compress overflow-hidden">
        {{ rg.ValidFrom ? (rg.ValidFrom | date:environment.FormattingStandards.ShortDateFormat) : 'X' }}
        <fa-icon [icon]="faDateArrow" size="sm" class="mx-1"></fa-icon>
        {{ rg.ValidTo ? (rg.ValidTo | date:environment.FormattingStandards.ShortDateFormat) : 'X'}}
      </div>
      <div class="col-1 col-compress pe-0 overflow-hidden" [title]="rg.RateVerdictName">
        {{ rg.RateVerdictName }}
      </div>
      <div class="col-1 col-compress align-self-center text-end">
        <button class="btn btn-sm btn-outline-light me-1 harmony-blue-outline-button"
                *ngIf="rg.RateVerdictName == 'Approved'"
                [title]="'Copy rate group'"
                (click)="copyRateGroup(rg)">
          <fa-icon [icon]="faCopyRate" size="sm"></fa-icon>
        </button>
        <button class="btn btn-sm btn-outline-light harmony-teal-outline-button"
                [title]="'Manage rates'"
                *ngIf="rg.CanEdit"
                (click)="manageRateGroup(rg)">
          <fa-icon [icon]="faEditIcon" size="sm"></fa-icon>
        </button>
        <button class="btn btn-sm btn-outline-light harmony-teal-outline-button"
                [title]="'Update validity date'"
                *ngIf="rg.CanEditDate && !rg.CanEdit"
                (click)="manageRateGroup(rg)">
          <fa-icon [icon]="faCalendar" size="sm"></fa-icon>
        </button>
        <button class="btn btn-sm btn-outline-light harmony-teal-outline-button"
                [title]="'View rates'"
                *ngIf="!rg.CanEdit && !rg.CanEditDate"
                (click)="manageRateGroup(rg)">
          <fa-icon [icon]="faEye" size="sm"></fa-icon>
        </button>
      </div>
    </div>
  </ng-container>
  <!----------------------->
  <!----- No Results ------>
  <!----------------------->
  <div class="row mt-4" *ngIf="(rateGroupsPaginationData && rateGroupsPaginationData.DataSet.length === 0) && !loading">
    <div class="col-12 text-center harmony-grey-text">
      <fa-icon [icon]="faEmpty" size="2x"></fa-icon>
    </div>
    <div class="col-12 text-center harmony-grey-text mt-1">
      <h4>This request contains no rates.</h4>
    </div>
  </div>
  <!---------------------->
  <!------- Loader ------->
  <!---------------------->
  <br>
  <app-loader class="mt-4" *ngIf="loading" [Width]="50"></app-loader>
</ng-container>
