<div class="modal-header">
  <h4 class="modal-title overflow-hidden">
    {{ CategoryId ? ('Edit Document Category: ' + CategoryId) : 'New Document Category' }}
  </h4>
  <button type="button" class="btn-close" aria-label="Close" [disabled]="loading" (click)="close()"></button>
</div>
<div class="modal-body">
  <div class="row mt-1">
    <div class="col-3 align-self-center">
      <b>Name</b>
    </div>
    <div class="col-9">
      <input type="text"
             class="form-control form-control-sm"
             [ngClass]="{'is-invalid': (category.Name?.length < 3 && invalid)}"
             [minlength]="3"
             [placeholder]="'Provide a category name, e.g. \'Certificates\''"
             [(ngModel)]="category.Name">
    </div>
  </div>
  <div class="row mt-1">
    <div class="col-3 align-self-center">
      <b>Code</b>
      <fa-icon [icon]="faInfo"
               [title]="'A unique 8 character code. Text only, no numbers or special characters.'"
               class="harmony-grey-text detail-info ms-1">
      </fa-icon>
    </div>
    <div class="col-9">
      <input type="text"
             class="form-control form-control-sm text-uppercase"
             [ngClass]="{'is-invalid': uniqueCodeExists || (!category.Code && invalid)}"
             [disabled]="category.Id != null"
             (keyup)="updateCode($event)"
             [maxLength]="8"
             [minLength]="8"
             [placeholder]="'Unique 8 character code'"
             [(ngModel)]="category.Code">
      <small *ngIf="uniqueCodeExists" class="required-text">Unique code already used</small>
    </div>
  </div>
  <div class="row mt-1">
    <div class="col-3 align-self-center">
      <b>Module</b>
      <fa-icon [icon]="faInfo"
               [title]="'This is optional, but recommended. If the category applies to multiple modules, then leave it as \'All\''"
               class="harmony-grey-text detail-info ms-1">
      </fa-icon>
    </div>
    <div class="col-9">
      <select name="module"
              class="form-select form-select-sm"
              id="module"
              [(ngModel)]="category.ModuleId">
        <option [ngValue]="null" selected disabled>Select a module...</option>
        <option *ngFor="let module of (modules$ | async)" [ngValue]="module.Id">
          {{module.Name}}
        </option>
      </select>
    </div>
  </div>
  <div class="row mt-1">
    <div class="col-3 align-self-center">
      <b>Role</b>
      <fa-icon [icon]="faInfo"
               [title]="'This is optional, but will ensure that only users with the specified role can access this category and the documents it contains.'"
               class="harmony-grey-text detail-info ms-1">
      </fa-icon>
    </div>
    <div class="col-9">
      <ng-container *ngIf="roleAdmin">
        <select class="form-select form-select-sm pointer"
                [(ngModel)]="category.RoleId"
                [disabled]="loading || (roles$ | async)?.length <= 0">
          <option [ngValue]="null">Any role...</option>
          <ng-container *ngFor="let role of (roles$ | async)">
            <option [ngValue]="role.Id">
              {{ role.Name }}
            </option>
          </ng-container>
        </select>
      </ng-container>
      <ng-container *ngIf="!roleAdmin">
        <select class="form-select form-select-sm pointer"
                [(ngModel)]="category.RoleId"
                [disabled]="loading || (allowedRoles$ | async)?.length <= 0">
          <option [ngValue]="null">Any role...</option>
          <ng-container *ngFor="let role of (allowedRoles$ | async)">
            <option [ngValue]="role.Id">
              {{ role.Name }}
            </option>
          </ng-container>
        </select>
      </ng-container>
    </div>
  </div>
  <div class="row mt-1">
    <div class="col-3">
      <b class="pt-1">Description</b>
    </div>
    <div class="col-9">
      <textarea class="form-control form-control-sm"
                [rows]="3"
                [maxlength]="1024"
                [placeholder]="'Provide a description for this category regarding the purpose of the documents within.'"
                [ngClass]="{'is-invalid': (category.Description?.length < 3 && invalid)}"
                [(ngModel)]="category.Description">
      </textarea>
      <small class="harmony-grey-text">{{category.Description?.length ?? 0}}/1024</small>
    </div>
  </div>
</div>
<div class="modal-footer">
  <app-loader *ngIf="loading" class="ms-2" [Width]="20"></app-loader>
  <button type="button" class="btn btn-primary harmony-purple-button" *ngIf="!CategoryId" [disabled]="loading" (click)="update()">Create</button>
  <button type="button" class="btn btn-primary harmony-purple-button" *ngIf="CategoryId" [disabled]="loading" (click)="update()">Update</button>
  <button type="button" class="btn btn-primary harmony-teal-button" [disabled]="loading" (click)="close()">Close</button>
</div>
