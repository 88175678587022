import { AfterViewChecked, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { AlertService } from '../../../services/alert.service';
import { MapStyles } from '../../../core/constants/map-styles';
import { ICompanyOverview } from '../../../services/models/member.model';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-member-map',
  templateUrl: './member-map.component.html',
  styleUrls: ['./member-map.component.scss']
})
export class MemberMapComponent implements OnInit, OnDestroy, AfterViewChecked {
  // Map Element
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;
  // Inputs
  @Input()
  set CompanyData(companyData: ICompanyOverview[]) {
    if (companyData && companyData.length > 0) {
      this.companyData = companyData;
      this.setMarkers();
    } else {
      this.center = { lat: 15, lng: 0 };
    }
  }
  @Input() Zoom: number = 1;
  @Input() MinZoom: number = 1;
  @Input() MaxZoom: number = 15;
  @Input() CenterOnUser: boolean = false;
  @Input() MapStyle: google.maps.MapTypeStyle[] = MapStyles.MemberMap;
  @Input()
  set Center(center: google.maps.LatLngLiteral) {
    if (center && center.lat && center.lng) {
      this.center = center;
    } else {
      this.center = { lat: 15, lng: 0 };
    }

    if (this.map && this.center) {
      this.map.panTo(this.center);
    }
  }

  center: google.maps.LatLngLiteral;
  companyData: ICompanyOverview[];
  markers: any[] = [];
  mapOptions: google.maps.MapOptions;

  // Info Window
  CompanyName: string;
  CompanyLogo: string;
  CompanyID: number;
  CompanyWebsiteUrl: string;

  // General variables
  loading: boolean = false;
  env = environment;

  constructor(private cdRef: ChangeDetectorRef,
              private alertService: AlertService) { }

  ngOnInit(): void {
    // Set Map Options
    this.mapOptions = {
      zoomControl: true,
      scrollwheel: false,
      disableDoubleClickZoom: true,
      maxZoom: 15,
      minZoom: 1,
      styles: this.MapStyle,
      mapTypeId: 'terrain'
    };
    // Set Center
    if (this.CenterOnUser === true) {
      this.getCurrentLocation();
    }
    // Create markers
    this.setMarkers();
  }

  ngOnDestroy(): void {
      // throw new Error('Method not implemented.');
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  setMarkers() {
    if (this.companyData) {
      this.markers = [];
      this.companyData.forEach((company: ICompanyOverview) => {
        // Set icon according to service type
        let iconUrl = '../../../../assets/icons/map/map-marker-default.svg';
        if (company.IsMovingServiceProvider && company.IsRelocationServiceProvider) {
          iconUrl = '../../../../assets/icons/map/map-marker-all.svg';
        } else if (company.IsMovingServiceProvider && !company.IsRelocationServiceProvider) {
          iconUrl = '../../../../assets/icons/map/map-marker-default.svg';
        } else if (!company.IsMovingServiceProvider && company.IsRelocationServiceProvider) {
          iconUrl = '../../../../assets/icons/map/map-marker-relocation.svg';
        } else if (company.CompanyID === 1) {
          iconUrl = '../../../../assets/icons/map/map-marker-headoffice.svg';
        }

        // Create Marker
        if (company.Latitude && company.Longitude) {
          this.markers.push({
            companyId: company.CompanyID,
            companyLogo: company.CompanyLogo,
            companyName: company.Name,
            companyEmail: company.MemberEmail ? company.MemberEmail : '-',
            companyWebsite: this.getWebsiteUrl(company),
            position: { lat: company.Latitude, lng: company.Longitude },
            label: null,
            title: company.Name,
            options: {
              animation: google.maps.Animation.DROP,
              icon: iconUrl,
              scaledSize: new google.maps.Size(30, 30),
              origin: new google.maps.Point(0, 0),
              anchor: new google.maps.Point(0, 0)
            } as google.maps.MarkerOptions
          });
        }
      });
    }
  }

  getWebsiteUrl(company: ICompanyOverview) {
    if (company.WebSite && company.WebSite.length > 7) {
      return company.WebSite.startsWith('http://') || company.WebSite.startsWith('https://') ? company.WebSite : 'https://' + company.WebSite;
    } else {
      return this.env.website_url + '/member/' + company.CompanyID;
    }
  }

  openInfo(marker: MapMarker, markerData: any) {
    this.CompanyID = markerData.companyId;
    this.CompanyLogo = markerData.companyLogo;
    this.CompanyName = markerData.companyName;
    this.CompanyWebsiteUrl = markerData.companyWebsite;
    // Show info window
    this.infoWindow.open(marker);
  }

  getCurrentLocation() {
    navigator.geolocation.getCurrentPosition(
      (position: GeolocationPosition) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        } as google.maps.LatLngLiteral;
        this.map.panTo(this.center);
      },
      (error) => {
        if (error.PERMISSION_DENIED) {
          this.alertService.info('Permission Denied: Couldn\'t get your location');
        } else if (error.POSITION_UNAVAILABLE) {
          this.alertService.info('Position Unavailable: Couldn\'t get your location');
        } else if (error.TIMEOUT) {
          this.alertService.info('Time Out: Couldn\'t get your location');
        } else {
          this.alertService.info(`Error: ${error.code}: ${error.message}`);
        }
      },
      { enableHighAccuracy: true }
    );
  }
}
