import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdAuthService } from '../../../../core/ad-auth-service/ad-auth.service';
import { IDashboardLookup, IRateRequestReport, IRateRequestReportParam } from '../models/pricing-dashboard.model';
import { Subject } from 'rxjs';
import { ApiService} from '../../../../services/api.service';
import { takeUntil} from 'rxjs/operators';
import { PricingService } from '../../../../services/pricing.service';

@Component({
  selector: 'app-dashboard-card-rate-requests',
  templateUrl: './dashboard-card-rate-requests.component.html',
  styleUrls: ['./dashboard-card-rate-requests.component.scss'],
  host: {'[class.one-by-one]': '(column <= 1 || column > 3) && (row <= 1 || row > 4)',
    '[class.two-by-one]': 'column == 2 && row == 1',
    '[class.three-by-one]': 'column == 3 && row == 1',
    '[class.two-by-two]': 'column == 2 && row == 2',
    '[class.one-by-two]': 'column == 1 && row == 2',
    '[class.one-by-three]': 'column == 1 && row == 3',
    '[class.one-by-four]': 'column == 1 && row == 4',
    '[class.one-by-five]': 'column == 1 && row == 5',
    '[class.one-by-six]': 'column == 1 && row == 6'}
})
export class DashboardCardRateRequestsComponent implements OnInit, OnDestroy {
  @Input() column = 1;
  @Input() row = 1;

  // Component variables
  report: IRateRequestReport;
  projects$ = this.pricingService.Projects$.asObservable();
  companyProjects$: Subject<IDashboardLookup[]> = new Subject<IDashboardLookup[]>();
  locations$: Subject<IDashboardLookup[]> = new Subject<IDashboardLookup[]>();
  companyId: number = 0;
  projectId: number = null;
  cityId: number = null;

  // Permissions
  pricingAdmin: boolean = false;

  // General variables
  private unsubscribe: Subject<any> = new Subject<any>();
  loading: boolean = false;

  constructor(public router: Router,
              public authService: AdAuthService,
              private pricingService: PricingService,
              private api: ApiService) { }

  ngOnInit() {
    if (this.authService.CurrentUser) {
      this.setPermissions();
    }

    this.authService.CurrentUser$.subscribe(() => {
      this.setPermissions();
    });
  }

  ngOnDestroy() {
    // Unsubscribe to avoid memory leaks
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  setPermissions() {
    this.pricingAdmin = this.authService.CheckRole('Pricing: Admin User');

    if (!this.pricingAdmin) {
      this.companyId = this.authService.CurrentUser.User.CompanyId;
    }

    this.getProjects();
  }

  getProjects() {
    const param = this.companyId ?? 0;

    this.api.get(`Pricing/Report/Projects/${param}`).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data: IDashboardLookup[]) => {
        if (data) {
          this.companyProjects$.next([...data]);
          this.getReport();
        }
      },
      error: () => { }
    });
  }

  onProjectChanged() {
    this.getLocations();
    this.getReport();
  }

  getLocations() {
    const company = this.companyId;
    const project = this.projectId;

    this.api.get(`Pricing/Report/Locations/${company}/${project}`).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data: IDashboardLookup[]) => {
        if (data) {
          this.locations$.next([...data]);
        }
      },
      error: () => { }
    });
  }

  getReport() {
    this.loading = true;
    const param = {
      CompanyId: this.companyId,
      ProjectId: this.projectId,
      CityId: this.cityId,
    } as IRateRequestReportParam;

    this.api.post(`Pricing/Report/RateRequest`, param).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data: IRateRequestReport) => {
        if (data) {
          this.report = Object.assign({}, data);
        }
        this.loading = false;
      },
      error: () => {
        this.report = {
          PendingRequests: 0,
          AwaitingSubmission: 0,
          ExpireIn1Month: 0,
          Expired: 0
        } as IRateRequestReport;
        this.loading = false;
      }
    });
  }

  viewRequests(param: string) {
    if (param == null) {
      this.router.navigate([`pricing/rate-capture`]).then(() => {
      });
    } else {
      this.router.navigate([`pricing/rate-capture`, { status: param }])
        .then(() => {
        });
    }
  }
}
