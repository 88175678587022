<!---- Header ---->
<div class="modal-header">
  <h4 class="modal-title">
    Environmental Information
  </h4>
</div>
<!---- Add/Edit ---->
<div class="modal-body">
  <div class="row">
    <app-company-details [companyId]="companyId" [propertyCategory]="'EnvironmentalImpactData'" [autoSave]="false"
                         [viewOnly]="!hasAccess" (OnChange)="updateCompanyDetails = $event"></app-company-details>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-primary harmony-tertiary-button" (click)="update()">Save</button>
  <button type="button" class="btn btn-primary harmony-primary-button" (click)="close()">Close</button>
</div>
