<div class="dashboard-background"></div>
<div class="dashboard-wrapper">
  <div class="dashboard-content">
    <!-- Compliance Programme -->
    <div class="three-by-five d-flex align-items-start services-card">
      <div>
        <div class="services-card-header row">
          <div class="col-12">
            <!--Header-->
            <h5 class="harmony-teal-text fw-bold">Compliance Programme</h5>
          </div>
        </div>
        <hr class="ms-3 me-3 mt-0 mb-4">
        <div class="row mt-2">
          <div class="col-3">
            <div class="justify-content-center m-2">

              <img [src]="env.SiteUrls.AzureStorageBaseURL + env.ContainerNames.WebImagesServices + 'Compliance.jpg'"
                   class="service-image-rounded-borders"
                   alt="Compliance Programme">

            </div>
          </div>
          <div class="col-9 service-content">
              <p>Compliance is the number one concern of our corporate customers’ mobility departments.</p>
              <p>
              Your customers must be able to rely on their vendors and partners to take compliance seriously. Harmony Relocation has therefor set up a comprehensive compliance programme that allows you to demonstrate just that. Part of the programme is a mandatory online training.
              </p>
              <p>
              The broader goal of the programme however, is to create, preserve and protect a culture of integrity. To that effect we organise dilemma workshops for move managers, and actively communicate about ethical issues at board and shareholder meetings.
              </p>
              <p>
              Our most valuable asset is our reputation of impeccable integrity – at Harmony Relocation, we do the right thing, each and every time.
              </p>
              <p>
              In 2016 Harmony successfully launched the Harmony Compliance Programme which is an important part of our network services.
              </p>
              <p>
              The Compliance Programme consists of the following three online courses:
              </p>
              <ul class="service-list">
                <li><span>Global Anti-Corruption & Bribery</span></li>
                <li><span>General Data Protection Regulation (GDPR)</span></li>
                <li><span>Global Competition Law</span></li>
              </ul>
              <p>
              It is mandatory that every member registers two senior managers per officially registered member location to complete the online courses each year. The annual Compliance Programme can be taken by the same people each year, or can be rotated within you team. These mandatory courses are included in your Harmony membership.
              </p>
              <p>
              We encourage our members to expand participation beyond these two senior managers, and register additional employees for these courses. You can select only 1 or 2 of the courses, e.g. Global Anti-Corruption and Global Competition Law for your Sales Managers, and General Data Protection Regulation for your Move Managers.
              </p>
              <p>
              The costs are 25,- Euro, pp, per course (so if all 3 courses are followed the total will be 75,- Euro per person).
              These fees will be charged via the Netting.
              </p>
          </div>
        </div>
      </div>
    </div>
    <!-- Compliance Programme Contact -->
    <div class="one-by-one services-card">
      <div class="services-card-header row">
        <div class="col-12">
          <!--Header-->
          <h5 class="harmony-teal-text fw-bold">Compliance Programme Contact</h5>
        </div>
      </div>
      <hr class="ms-3 me-3 mt-0 mb-4">
      <div class="row">
        <div class="col-12 ms-4">
          <div class="row">
            <div class="col-12 service-contact">
              Ms Sandra Makker
            </div>
          </div>
          <div class="row my-1">
            <div class="col-1">
              <fa-icon [icon]="faEnvelope"></fa-icon>
            </div>
            <div class="col-11 nav-item">
              <a href="mailto:s.makker@harmonyrelo.com?Subject=Request information about Services/Compliance-Programme" class="harmony-blue-link">s.makker@harmonyrelo.com</a>
            </div>
          </div>
          <div class="row my-1">
            <div class="col-1">
              <fa-icon [icon]="faPhone"></fa-icon>
            </div>
            <div class="col-11">
              <a href="callto:+31 6 29439359" style="color: rgb(0,0,0)">+31 6 29439359</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Harmony Compliance Code -->
    <div class="one-by-one services-card">
      <div class="services-card-header row">
        <div class="col-12">
          <!--Header-->
          <h5 class="harmony-teal-text fw-bold">Harmony Compliance Code</h5>
        </div>
      </div>
      <hr class="ms-3 me-3 mt-0 mb-4">
      <div class="card-body row mt-2">
        <div class="col-12 service-content document-list">
          <app-document-list [ModuleName]="'Services'" [CategoryCode]="'SERVSHEE'" [Recents]="true"></app-document-list>
        </div>
      </div>
    </div>
    <!-- Link to Compliance Courses -->
    <div class="one-by-one services-card">
      <div class="services-card-header row">
        <div class="col-12">
          <!--Header-->
          <h5 class="harmony-teal-text fw-bold">Link to Compliance Courses</h5>
        </div>
      </div>
      <hr class="ms-3 me-3 mt-0 mb-4">
      <div class="card-body row mt-2">
        <div class="col-12 service-content document-list">
          <app-document-list [ModuleName]="'Services'" [CategoryCode]="'SERVHCCO'" [Recents]="true"></app-document-list>
        </div>
      </div>
    </div>
    <!-- Important Compliance Information -->
    <div class="three-by-two d-flex align-items-start services-card">
      <div>
        <div class="services-card-header row">
          <div class="col-12">
            <!--Header-->
            <h5 class="harmony-teal-text fw-bold">Important Compliance Information</h5>
          </div>
        </div>
        <hr class="ms-3 me-3 mt-0 mb-4">
        <div class="row mt-2">
          <div class="col-3">
            <div class="justify-content-center m-2">

              <img [src]="env.SiteUrls.AzureStorageBaseURL + env.ContainerNames.WebImagesServices + 'Compliance4.jpg'"
                   class="service-image-rounded-borders"
                   alt="Important Compliance Information">

            </div>
          </div>
          <div class="col-9 service-content">
            <ul class="service-list">
              <li><span>Failure to complete the mandatory Compliance Programme will result in the withdrawal of your group ISO certification, which in turn can lead to a cancelation of your membership.</span></li>
              <li><span>At the beginning of each calendar year, all members receive a unique link to register their participants, please contact compliance@harmonyrelo.com if you wish to receive your company’s registration link.</span></li>
              <li><span>The courses are given by an external supplier; LNR; they will contact the participants directly via email for the online courses.</span></li>
              <li><span>The courses are available in English only.</span></li>
              <li><span>You can register the same/or different people each year for Compliance Programme, in your company's registration link the past participants are visible to re-register, or to be deleted and replaced by new names.</span></li>
              <li><span>If you have any questions, or require more information please contact Harmony Head Office at compliance@harmonyrelo.com</span></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- Client Services Admin -->

  </div>
</div>
