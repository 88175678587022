<div class="dashboard-background"></div>
<div class="dashboard-wrapper">
  <div class="dashboard-content">
    <!-- Corporate Sales Programme -->
    <div class="three-by-two d-flex align-items-start services-card">
      <div>
        <div class="services-card-header row">
          <div class="col-12">
            <!--Header-->
            <h5 class="harmony-teal-text fw-bold">Corporate Sales Programme</h5>
          </div>
        </div>
        <hr class="ms-3 me-3 mt-0 mb-4">
        <div class="row mt-2">
          <div class="col-3">
            <div class="justify-content-center m-2">

              <img [src]="env.SiteUrls.AzureStorageBaseURL + env.ContainerNames.WebImagesServices + 'corpsales%2001.jpg'"
                   class="service-image-rounded-borders"
                   alt="Corporate Sales Programme">

            </div>
          </div>
          <div class="col-9 service-content">
            <p>
              One of the key points of our strategy is to support Member Companies to acquire and service corporate accounts. We have a menu of support services available that can be made use of as and when required. If a Member wins the business, they will sign the contract with the corporate account. Harmony is not actively selling to the corporate market.
            </p>
            <br>
            <p>
              <span><h5 class="harmony-blue-text fw-bold">Compliance</h5></span>
              The corporate sales programme is not intended in any way to limit free competition between members. Harmony will not share information about opportunities for which they provide support to one member, with any other member.
              In case multiple members are asking for support, Harmony reserves the right to refuse support and focus on only one member. That does not mean the other member cannot or should not compete.
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- Sales Support Contact -->
    <div class="one-by-one services-card">
      <div class="services-card-header row">
        <div class="col-12">
          <!--Header-->
          <h5 class="harmony-teal-text fw-bold">Sales Support Contact</h5>
        </div>
      </div>
      <hr class="ms-3 me-3 mt-0 mb-4">
      <div class="row">
        <div class="col-12 ms-4">
          <div class="row">
            <div class="col-12 service-contact">
              Ms. Vanessa Cremers
            </div>
          </div>
          <div class="row my-1">
            <div class="col-1">
              <fa-icon [icon]="faEnvelope"></fa-icon>
            </div>
            <div class="col-11 nav-item">
              <a href="mailto:v.cremers@harmonyrelo.com?Subject=Request information about Services/Corporate-Sales-Programme" class="harmony-blue-link">v.cremers@harmonyrelo.com</a>
            </div>
          </div>
          <div class="row my-1">
            <div class="col-1">
              <fa-icon [icon]="faPhone"></fa-icon>
            </div>
            <div class="col-11">
              <a href="callto:+31 294 461 019" style="color: rgb(0,0,0)">+31 294 461 019</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- CSP Documents -->
    <div class="one-by-one services-card">
      <div class="services-card-header row">
        <div class="col-12">
          <!--Header-->
          <h5 class="harmony-teal-text fw-bold">CSP Documents</h5>
        </div>
      </div>
      <hr class="ms-3 me-3 mt-0 mb-4">
      <div class="card-body row mt-2">
        <div class="col-12 service-content document-list">
          <app-document-list [ModuleName]="'Services'" [CategoryCode]="'SERVCSPD'" [Recents]="true"></app-document-list>
        </div>
      </div>
    </div>
    <!-- Basic CSP Services -->
    <div class="three-by-four d-flex align-items-start services-card">
      <div>
        <div class="services-card-header row">
          <div class="col-12">
            <!--Header-->
            <h5 class="harmony-teal-text fw-bold">Basic CSP Services</h5>
          </div>
        </div>
        <hr class="ms-3 me-3 mt-0 mb-4">
        <div class="row mt-2">
          <div class="col-3">
            <div class="justify-content-center m-2">

              <img [src]="env.SiteUrls.AzureStorageBaseURL + env.ContainerNames.WebImagesServices + 'rfp-response-requirementsv%2002.jpg'"
                   class="service-image-rounded-borders"
                   alt="Basic CSP Services">

            </div>
          </div>
          <div class="col-9 service-content">
            <p>
            Basic CSP Services are available at €63.-/hour (rate 2024), excluding additional expenses in case of presence during a presentation. A detailed quotation will be provided prior to commencement of the services, and acceptance is required. Billing will take place on completion of the services, and will be settled via netting.
            </p>
            <br>
            <span><h5 class="harmony-blue-text fw-bold">Request for Information (RFI) or Request for Proposal (RFP)</h5></span>
            <p>
            We can respond to a few selected questions only, or manage the entire project from start to finish, including correct lay-out of the submission document.
            </p>
            <br>
            <span><h5 class="harmony-blue-text fw-bold">Pricing / Rates</h5></span>
            <p>
            We can provide pricing/rates for all regular HHG Moving services, such as origin services, freight, destination services, ancillary costs etc. This pricing does not include any margin for Harmony or for the Member.
            </p>
            <br>
            <span><h5 class="harmony-blue-text fw-bold">Presentations</h5></span>
            <p>
            In case the Harmony member is invited for a presentation (either before or after the RFP phase), we can help with the correct styling of the slides. On request, one of our team members can also be present during the presentation, often to explain very specific issues about the Network, Technology, Billing etc.
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- CSP Links -->
    <div class="one-by-one services-card">
      <div class="services-card-header row">
        <div class="col-12">
          <!--Header-->
          <h5 class="harmony-teal-text fw-bold">CSP Links</h5>
        </div>
      </div>
      <hr class="ms-3 me-3 mt-0 mb-4">
      <div class="card-body row mt-2">
        <div class="col-12 service-content document-list">
          <app-document-list [ModuleName]="'Services'" [CategoryCode]="'SERVSHEE'" [Recents]="true"></app-document-list>
        </div>
      </div>
    </div>
    <!-- Additional CSP Services -->
    <div class="three-by-four d-flex align-items-start services-card">
      <div>
        <div class="services-card-header row">
          <div class="col-12">
            <!--Header-->
            <h5 class="harmony-teal-text fw-bold">Additional CSP Services</h5>
          </div>
        </div>
        <hr class="ms-3 me-3 mt-0 mb-4">
        <div class="row mt-2">
          <div class="col-3">
            <div class="justify-content-center m-2">

              <img [src]="env.SiteUrls.AzureStorageBaseURL + env.ContainerNames.WebImagesServices + 'billing.jpg'"
                   class="service-image-rounded-borders"
                   alt="Additional CSP Services">

            </div>
          </div>
          <div class="col-9 service-content">
              <p>
              The following additional CSP Services are also available to the Network.
              </p>

              <br>
              <span><h5 class="harmony-blue-text fw-bold">Financial Services</h5></span>
              <p>
              On request, we can do the billing for an entire contract. Harmony bills to, and gets paid from companies in about 80 different countries in over 20 different currencies. We do this from our IT platform RedSky. The member company is responsible for correct data entry. In case the member company is also a RedSky user, the synchronisation of data can be done through RedSky.
              These services come at an extra cost of a percentage of revenue billed. The percentage depends on parameters such as the payment terms (from account and to member), and the volume of business. A detailed quotation will be made on request.
              </p>

              <br>
              <span><h5 class="harmony-blue-text fw-bold">Technology</h5></span>
              <p>
              Harmony can offer Account & Assignee Portals. These portals have standard lay-out. This service is included in the costs of 'Financial Services'.
              Interfacing. For sharing of data with your own IT platform, or with the IT platform of your customer, Harmony can set up an interface to transfer data from one system to the other, without double entry. Please see please see the Service Section 'Tech Support'.
              </p>

              <br>
              <span><h5 class="harmony-blue-text fw-bold">Quality Control</h5></span>
              <p>
              We can measure the quality of services performed. This is done by means of an email to the assignee upon completion of service delivery.
              The assignee receives a link to a web page, where with a few clicks the assignee can rate the services received. The questions asked can be customized on request. Data are automatically available in RedSky. This service is also included in the costs of 'Financial Services' above.
              </p>

              <br>
              <span><h5 class="harmony-blue-text fw-bold">Reporting</h5></span>
              <p>
                We provide a standard set of reports that will be made available to the member company and the corporate account. This service is included in the costs of 'Financial Services'. For more elaborate reporting services, please see the Service Section 'Reporting'.
              </p>

              <br>
              <span><h5 class="harmony-blue-text fw-bold">Compliance</h5></span>
              <P>
              For a specific account, we can set up all vendors (and their designated senior and or move managers) involved in a particular contract so that they each follow a specific compliance course. For more information about our Harmony Compliance Courses, please see the Service Section 'Compliance Programme'.
              </P>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
