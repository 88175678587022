<div class="card-wrapper">
  <div class="card-content">
    <div class="card-header row">
      <div class="col-12">
        <h5 class="harmony-primary-text fw-bold mt-2">Recent documents</h5>
      </div>
    </div>
    <div class="card-body">
      <app-document-list [CompanyId]="companyId" [Recents]="true"></app-document-list>
    </div>
  </div>
</div>
