<!---------------------->
<!------- Header ------->
<!---------------------->
<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom sticky-header">
  <h1 class="portal-component-header">
    HUB Mappings
  </h1>
  <div class="justify-content-end">
    <button class="btn btn-sm btn-primary harmony-tertiary-outline-button"
            [disabled]="loading" (click)="newValueMapping()">
      Map new values +
    </button>
  </div>
</div>
<!------------------------------------>
<!---- Tab Menu for all components --->
<!------------------------------------>
<div class="row">
  <div class="col-12">
    <!------------------->
    <!---- Filtering ---->
    <!------------------->
    <div class="row mt-2">
      <div class="col-2 align-self-center">
        <b>Client Direction</b>
      </div>
      <div class="col-4">
        <select class="form-select form-select-sm pointer"
                [disabled]="!(userClients$ | async) || (userClients$ | async).length <= 0"
                [(ngModel)]="selectedClient"
                (change)="setClientData()">
          <option [ngValue]="null" *ngIf="canViewAll">All</option>
          <option *ngFor="let client of (userClients$ | async)"
                  [selected]="searchParam.SourceClientId == client.SourceClientId"
                  [ngValue]="client">
            {{ client.SourceClientName }} ({{client.SourceSystemName}})&nbsp; &DDotrahd; &nbsp;{{ client.TargetClientName }} ({{client.TargetSystemName}})
          </option>
        </select>
      </div>
    </div>
    <ng-container *ngIf="canViewAll">
      <div class="row mt-1">
        <div class="col-2 align-self-center">
          <b>Schema</b>
        </div>
        <div class="col-2">
          <select class="form-select form-select-sm pointer"
                  [disabled]="!(hubSchemaTypes$ | async) || (hubSchemaTypes$ | async).length <= 0 || !selectedClient?.SourceSystemId"
                  [(ngModel)]="sourceSchemaTypeId"
                  (change)="loadSourceElements()">
            <option [ngValue]="null">All source schemas</option>
            <option *ngFor="let schemaType of (hubSchemaTypes$ | async)"
                    [hidden]="schemaType.SystemId != selectedClient?.SourceSystemId"
                    [ngValue]="schemaType.Id">
              {{ schemaType.SchemaTypeName }}
            </option>
          </select>
        </div>
        <div class="col-2">
          <select class="form-select form-select-sm pointer"
                  [disabled]="!(hubSchemaTypes$ | async) || (hubSchemaTypes$ | async).length <= 0 || !selectedClient?.TargetSystemId"
                  [(ngModel)]="targetSchemaTypeId"
                  (change)="loadTargetElements()">
            <option [ngValue]="null">All target schemas</option>
            <option *ngFor="let schemaType of (hubSchemaTypes$ | async)"
                    [hidden]="schemaType.SystemId != selectedClient?.TargetSystemId"
                    [ngValue]="schemaType.Id">
              {{ schemaType.SchemaTypeName }}
            </option>
          </select>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-2 align-self-center">
          <b>Element</b>
        </div>
        <div class="col-2">
          <select class="form-select form-select-sm pointer"
                  [disabled]="!(sourceElements$ | async) || (sourceElements$ | async).length <= 0 || searchParam.SourceSystemId == null || loading"
                  [(ngModel)]="searchParam.SourceElementId">
            <option [ngValue]="null" [disabled]="true">Select...</option>
            <option *ngFor="let element of (sourceElements$ | async)"
                    [ngValue]="element.ElementId">
              {{ element.ElementPath }}
            </option>
          </select>
        </div>
        <div class="col-2">
          <select class="form-select form-select-sm pointer"
                  [disabled]="!(targetElements$ | async) || (targetElements$ | async).length <= 0 || searchParam.TargetSystemId == null || loading"
                  [(ngModel)]="searchParam.TargetElementId">
            <option [ngValue]="null" [disabled]="true">Select...</option>
            <option *ngFor="let element of (targetElements$ | async)"
                    [ngValue]="element.ElementId">
              {{ element.ElementPath }}
            </option>
          </select>
        </div>
      </div>
    </ng-container>
    <div class="row mt-1">
      <div class="col-2 align-self-center">
        <b>Value</b>
      </div>
      <div class="col-2">
        <input class="form-control form-control-sm"
               id="SourceValue"
               [placeholder]="'Enter a source value...'"
               [(ngModel)]="searchParam.SourceValue"
               name="SearchValue">
      </div>
      <div class="col-2">
        <input class="form-control form-control-sm"
               id="TargetValue"
               [placeholder]="'Enter a target value...'"
               [(ngModel)]="searchParam.TargetValue"
               name="SearchValue">
      </div>
    </div>
    <hr class="mt-2 mb-0">
    <div class="row mt-1">
      <div class="col-6">
        <input type="text"
               class="filter-item form-control form-control-sm"
               [(ngModel)]="searchParam.ValueListId"
               numbersOnly="10.0"
               (keyup.enter)="searchMappings()"
               placeholder="Search by ID...">
      </div>
      <div class="col-1 offset-3 text-end pe-0">
        <button class="btn btn-sm btn-outline-primary harmony-primary-outline-button me-1"
                (click)="resetSearch()">
          Reset
        </button>
      </div>
      <div class="col-2 text-end">
        <button type="button"
                class="btn btn-sm btn-primary harmony-tertiary-button w-100"
                (click)="searchMappings()">
          Search
        </button>
      </div>
    </div>
    <hr class="mt-1 mb-0">
    <!------------------->
    <!---- Pagination --->
    <!------------------->
    <div class="row ms-1 me-1">
      <div class="col-12">
        <app-pagination [showPageNumber]=true
                        [showRowsPerPage]=true
                        [paginationData]=paginationData
                        (pageEvent)="onPage($event)"
                        [name]="'HubMissingValues'"
                        [page]="page">
        </app-pagination>
      </div>
    </div>
    <!------------------->
    <!----- Results ----->
    <!------------------->
    <div class="row mt-1">
      <div class="col-6 text-center harmony-primary-text">
        <h5>
          Source
        </h5>
      </div>
      <div class="col-6 text-center harmony-primary-text">
        <h5>
          Target
        </h5>
      </div>
    </div>
    <div class="row mt-1 header-row">
      <div class="col-1 col-compress pointer header-title border-split-left"
           (appOrder)="orderSearch($event)"
           data-column="SourceSystemName"
           data-order="desc">
        System
        <fa-icon [hidden]="orderParam.OrderBy != 'SourceSystemName'" [icon]="faSortAsc" style="bottom: 3px;" class="ms-2 position-relative"></fa-icon>
      </div>
      <div class="col-1 col-compress pointer header-title"
           (appOrder)="orderSearch($event)"
           data-column="SourceClientName"
           data-order="desc">
        Client
        <fa-icon [hidden]="orderParam.OrderBy != 'SourceClientName'" class="ms-2 position-relative"></fa-icon>
      </div>
      <div class="col-2 col-compress pointer header-title"
           (appOrder)="orderSearch($event)"
           data-column="SourceFieldName"
           data-order="asc">
        Element
        <fa-icon [hidden]="orderParam.OrderBy != 'SourceFieldName'" class="ms-2 position-relative"></fa-icon>
      </div>
      <div class="col-2 col-compress pointer header-title border-split-right"
           (appOrder)="orderSearch($event)"
           data-column="SourceValue"
           data-order="desc">
        Value
        <fa-icon [hidden]="orderParam.OrderBy != 'SourceValue'" class="ms-2 position-relative"></fa-icon>
      </div>
      <div class="col-1 col-compress pointer header-title border-split-left"
           (appOrder)="orderSearch($event)"
           data-column="TargetSystemName"
           data-order="desc">
        System
        <fa-icon [hidden]="orderParam.OrderBy != 'TargetSystemName'" class="ms-2 position-relative"></fa-icon>
      </div>
      <div class="col-1 col-compress pointer header-title"
           (appOrder)="orderSearch($event)"
           data-column="TargetClientName"
           data-order="desc">
        Client
        <fa-icon [hidden]="orderParam.OrderBy != 'TargetClientName'" class="ms-2 position-relative"></fa-icon>
      </div>
      <div class="col-2 col-compress pointer header-title"
           (appOrder)="orderSearch($event)"
           data-column="TargetFieldName"
           data-order="asc">
        Element
        <fa-icon [hidden]="orderParam.OrderBy != 'TargetFieldName'" class="ms-2 position-relative"></fa-icon>
      </div>
      <div class="col-2 col-compress pointer header-title border-split-right"
           (appOrder)="orderSearch($event)"
           data-column="TargetValue"
           data-order="desc">
        Value
        <fa-icon [hidden]="orderParam.OrderBy != 'TargetValue'" class="ms-2 position-relative"></fa-icon>
      </div>
    </div>
    <ng-container *ngIf="!loading && paginationData && paginationData.DataSet.length > 0">
      <div class="row result-row" *ngFor="let mapping of paginationData.DataSet">
        <div class="col-1 col-compress overflow-hidden" [title]="mapping.SourceSystemName">
          <b>{{ mapping.SourceSystemName }}</b>
        </div>
        <div class="col-1 col-compress overflow-hidden">
          {{ mapping.SourceClientName }}
        </div>
        <div class="col-2 col-compress overflow-hidden" [title]="mapping.SourceFieldName ?? '-'">
          <small>{{ mapping.SourceFieldName ?? '-' }}</small>
        </div>
        <div class="col-2 col-compress overflow-hidden" [title]="mapping.SourceValue ?? '-'">
          <span class="float-start">
            <small>{{ mapping.SourceValue ?? '-' }}</small>
          </span>
          <button class="btn btn-sm btn-outline-light harmony-tertiary-outline-button float-end"
                  (click)="editValue(mapping.SourceValueListId)"
                  *ngIf="canEditValues"
                  [title]="'Edit Value ID: ' + mapping.SourceValueListId">
            <fa-icon [icon]="faEditIcon"></fa-icon>
          </button>
        </div>
        <div class="col-1 col-compress overflow-hidden" [title]="mapping.TargetSystemName">
          <b>{{ mapping.TargetSystemName }}</b>
        </div>
        <div class="col-1 col-compress overflow-hidden">
          {{ mapping.TargetClientName }}
        </div>
        <div class="col-2 col-compress overflow-hidden" [title]="mapping.TargetFieldName ?? '-'">
          <small>{{ mapping.TargetFieldName ?? '-' }}</small>
        </div>
        <div class="col-2 col-compress overflow-hidden" [title]="mapping.TargetValue ?? '-'">
          <span class="float-start">
            <small>{{ mapping.TargetValue ?? '-' }}</small>
          </span>
          <button class="btn btn-sm btn-outline-light harmony-tertiary-outline-button float-end"
                  (click)="editValue(mapping.TargetValueListId)"
                  *ngIf="canEditValues"
                  [title]="'Edit Value ID: ' + mapping.TargetValueListId">
            <fa-icon [icon]="faEditIcon"></fa-icon>
          </button>
        </div>
      </div>
    </ng-container>
    <!------------------->
    <!----- Loader ------>
    <!------------------->
    <ng-container *ngIf="loading">
      <div class="row mt-4">
        <div class="col-12 text-center">
          <app-loader [Width]="50"></app-loader>
        </div>
      </div>
    </ng-container>
    <!------------------->
    <!--- No Results ---->
    <!------------------->
    <ng-container *ngIf="!loading && paginationData.DataSet.length <= 0">
      <div class="row mt-4" *ngIf="!loading && (paginationData.DataSet && paginationData.DataSet.length === 0)">
        <div class="col-12 text-center harmony-grey-text">
          <fa-icon [icon]="faEmpty" size="2x"></fa-icon>
        </div>
        <div class="col-12 text-center harmony-grey-text mt-1">
          <h4>No results...</h4>
        </div>
      </div>
    </ng-container>
  </div>
</div>
