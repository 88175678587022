import {
  Component,
  EventEmitter,
  Input, OnChanges,
  OnDestroy,
  OnInit,
  Output, SimpleChanges
} from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ApiService } from '../../../services/api.service';
import { ICountry } from '../../../services/models/pricing/location.model';

@Component({
  selector: 'app-country-search',
  templateUrl: './country-search.component.html',
  styleUrls: ['./country-search.component.scss']
})
export class CountrySearchComponent implements OnInit, OnChanges, OnDestroy {
  // Input Variables
  @Input() Identifier: string = '';
  @Input() Disabled: boolean = false;
  @Input() ShowError: boolean = false;

  @Input()
  set SelectedID(id: number) {
    if (id && id > 0) {
      this.selectedId = id;
      this.loadCountryByID(this.selectedId);
    } else {
      this.clear();
    }
  }

  get SelectedID(): number {
    return this.selectedId;
  }

  // Output Emitters
  @Output() OnSelect: EventEmitter<number> = new EventEmitter<number>();
  @Output() OnSelectObject: EventEmitter<ICountry> = new EventEmitter<ICountry>();

  // Component Variables
  selectedId: number;
  public SelectedCountry: ICountry = null;

  // General variables
  errorColor: string = '#dc3545';
  searching: boolean = false;
  searchFailed: boolean = false;
  private unsubscribe: Subject<any> = new Subject<any>();

  // Formatter for search, decides what to show in typeahead list
  formatter = (result: ICountry) => result.Name;

  constructor(private api: ApiService) { }

  ngOnInit() {
    if (this.SelectedID && this.SelectedID > 0) {
      this.loadCountryByID(this.SelectedID);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.SelectedID && changes.SelectedID.currentValue == null) {
      this.clear();
    }
  }

  searchCountry = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(() => {
        this.searching = true;
        this.searchFailed = false;
      }),
      switchMap(term =>
        this.getCountry(term).pipe(
          tap(() => {
            this.searching = false;
          }),
          catchError(() => {
            this.searching = false;
            this.searchFailed = true;
            return of([]);
          }))
      )
    );
  };

  getCountry(searchText: string): Observable<ICountry[]> {
    if (searchText && searchText.length > 2) {
      // Call City controller "List" endpoint
      return this.api.get(`Country/Search/${searchText}`).pipe(
        map((data) => data)
      );
    }  else if (!searchText) {
      this.clear();
    }
    return of([]);
  }

  loadCountryByID(Id: number) {
    this.searching = true;

    this.api.get('Country/Single/' + Id).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe((data: ICountry) => {
      if (data) {
        this.SelectedCountry = Object.assign({}, data);
      }
      this.searching = false;
    });
  }

  onSelect(selectedCountry: ICountry) {
    if (selectedCountry && selectedCountry.Id) {
      this.OnSelect.emit(selectedCountry.Id);
      this.OnSelectObject.emit(selectedCountry);
    }
  }

  clear() {
    this.searching = false;
    this.selectedId = null;
    this.SelectedCountry = null;
    this.OnSelect.emit(null);
    this.OnSelectObject.emit(null);
  }
}
