import { Component, OnInit } from '@angular/core';
import { IPage, IPaginationData } from '../../../shared/models/pagination-data.model';
import { takeUntil } from 'rxjs/operators';
import { IApplicationLogDto, IApplicationLogSearchDto } from '../../../services/models/portal/application-log.model';
import { Subject } from 'rxjs';
import { ApiService } from '../../../services/api.service';
import { IOrderParam } from '../../../shared/directives/sort/order.directive';
import { environment } from '../../../../environments/environment';
import { faBoxOpen, faEye, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { ApplicationLogModalComponent } from './application-log-modal/application-log-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-application-log',
  templateUrl: './application-log.component.html',
  styleUrls: ['./application-log.component.scss']
})
export class ApplicationLogComponent implements OnInit {
  // Icons
  faView = faEye
  faEmpty = faBoxOpen;
  faSortDesc = faSortUp;

  // Component variables
  page: IPage;
  searchParam: IApplicationLogSearchDto;
  paginationData: IPaginationData<IApplicationLogDto>;
  orderParam: IOrderParam;

  // General variables
  loading: boolean = false;
  private unsubscribe: Subject<boolean> = new Subject<boolean>();
  env = environment;

  constructor(private api: ApiService,
              private modalService: NgbModal) { }

  ngOnInit(): void {
    // Init pagination data
    this.paginationData = {
      DataSet: [],
      CurrentPage: 1,
      PageSize: 30,
      TotalPages: 1
    } as IPaginationData<IApplicationLogDto>;
    // Init page
    this.page = {
      pageNumber: this.paginationData.CurrentPage,
      pageSize: this.paginationData.PageSize
    } as IPage;
    // Init search param
    this.searchParam = {
      SearchText: null,
      ToDate: null,
      FromDate: null,
      Source: null,
      Level: null
    } as IApplicationLogSearchDto;
    // Set default ordering
    this.orderParam = {
      OrderBy: 'Date',
      OrderDirection: 'desc'
    } as IOrderParam;

    this.searchApplicationLog();
  }

  searchApplicationLog() {
    this.loading = true;
    const p = this.page;
    const o = this.orderParam;

    this.api.post(`ApplicationLog/SearchLogs?pageNumber=${p.pageNumber}&pageSize=${p.pageSize}
    &orderBy=${o.OrderBy}&order=${o.OrderDirection}`, this.searchParam)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (data) => {
          this.paginationData = Object.assign({}, data);
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        }
      });
  }

  getClass(level: string) {
    switch (level) {
      case 'Trace':
        return 'harmony-accent-green-text';
      case 'Debug':
        return 'harmony-accent-blue-grey-text';
      case 'Info':
        return 'text-info';
      case 'Warn':
        return 'harmony-accent-coral-text';
      case 'Error':
        return 'text-danger';
      default:
        return 'harmony-primary-text';
    }
  }

  onPage(page: IPage) {
    this.page = page;
    this.searchApplicationLog();
  }

  orderSearch(param: IOrderParam) {
    this.orderParam = param;
    this.searchApplicationLog();
  }

  viewLog(log: IApplicationLogDto) {
    // Open modal to Add/Edit a module
    const modalRef = this.modalService.open(ApplicationLogModalComponent, { size: 'xl', backdrop: 'static' });
    modalRef.componentInstance.Log = log;
  }
}
