<div class="card-wrapper">
  <div class="card-content">
    <div class="card-header row">
      <div class="col-12">
        <h5 class="harmony-teal-text fw-bold mt-2">Check Rates</h5>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <ng-container *ngIf="pricingAdmin">
            <select class="form-select form-select-sm form-control-xs pointer"
                    [(ngModel)]="projectId"
                    [disabled]="loading || !(projects$ | async)"
                    (ngModelChange)="onProjectChanged()">
              <option [ngValue]="null" selected>All projects...</option>
              <option *ngFor="let project of (projects$ | async)"
                      [ngValue]="project.Id">{{ project.Name }}</option>
            </select>
          </ng-container>
          <ng-container *ngIf="!pricingAdmin">
            <select class="form-select form-select-sm form-control-xs pointer"
                    [(ngModel)]="projectId"
                    [disabled]="loading || !(companyProjects$ | async)"
                    (ngModelChange)="onProjectChanged()">
              <option [ngValue]="null" selected>All projects...</option>
              <option *ngFor="let project of (companyProjects$ | async)"
                      [ngValue]="project.Id">{{ project.Name }}</option>
            </select>
          </ng-container>
        </div>
      </div>
      <ng-container *ngIf="loading">
        <br>
        <app-loader [Width]="30"></app-loader>
      </ng-container>
      <ng-container *ngIf="!loading && report?.length > 0">
        <div class="check-rate-container">
          <div class="row mt-1 result-row check-rate-row pointer" *ngFor="let rateGroup of report" (click)="viewRateGroup(rateGroup.RateGroupId)">
            <div class="col-4 align-self-center">
              <b class="check-rate-name ms-1">{{ rateGroup.Description }}</b>
            </div>
            <div class="col-3 align-self-center overflow-hidden"
                 [title]="rateGroup.Origin ?? 'No origin'"
                 style="text-overflow: ellipsis; white-space: nowrap;">
              <small>{{ rateGroup.Origin ?? '-' }}</small>
            </div>
            <div class="col-1 align-self-center harmony-blue-grey-text">
              <fa-icon [icon]="faDirection" [size]="'1x'"></fa-icon>
            </div>
            <div class="col-3 align-self-center overflow-hidden"
                 [title]="rateGroup.Destination ?? 'No destination'"
                 style="text-overflow: ellipsis; white-space: nowrap;">
              <small>{{ rateGroup.Destination ?? '-' }}</small>
            </div>
            <div class="col-1 text-end align-self-center">
              <fa-icon [icon]="faInfo"
                       size="xl"
                       class="harmony-yellow-text me-1 position-relative pointer"
                       *ngIf="rateGroup.Comment?.length > 0"
                       [title]="rateGroup.Comment"></fa-icon>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!loading && report?.length <= 0">
        <div class="row mt-4 mb-2">
          <div class="col-12 text-center harmony-grey-text">
            <fa-icon [icon]="faNoCheckRate" size="2x"></fa-icon>
          </div>
          <div class="col-12 text-center harmony-grey-text mt-1">
            <h5>Your rates look good, no issues found!</h5>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
