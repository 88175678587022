import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { faCheck, faPlus, faXmark } from '@fortawesome/free-solid-svg-icons';
import { IRateRequestDto, IRateRequestGenerateParam } from '../../../../services/models/pricing/rate-request.model';
import { PricingService } from '../../../../services/pricing.service';
import { AlertService } from '../../../../services/alert.service';

@Component({
  selector: 'app-create-rate-request-modal',
  templateUrl: './create-rate-request-modal.component.html',
  styleUrls: ['./create-rate-request-modal.component.scss']
})
export class CreateRateRequestModalComponent implements OnInit, OnDestroy {
  // Icons
  faExists = faCheck;
  faInactive = faXmark;
  faNew = faPlus;

  // Component variables
  ProjectID: number = null;
  rateRequestParam: IRateRequestGenerateParam;
  generatedRateRequests: IRateRequestDto[] = [];
  saveResultMessage: string = '';

  // Lookup data
  projects$ = this.pricingService.Projects$.asObservable();
  rateRequestTypes$ = this.pricingService.RateRequestTypes$.asObservable();

  // General variables
  private unsubscribe: Subject<any> = new Subject<any>();
  public loading: boolean;

  constructor(public activeModal: NgbActiveModal,
              public pricingService: PricingService,
              private alertService: AlertService) { }

  ngOnInit(): void {
    // Init params
    this.rateRequestParam = {
      RateRequestTypes: [],
      AgentId: null,
      CityId: null,
      IgnorePorts: true
    } as IRateRequestGenerateParam;
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  setCity(event: any) {
    this.rateRequestParam.CityId = event;
  }

  setAgent(event: any) {
    this.rateRequestParam.AgentId = event;
  }

  setIgnorePort(event: any) {
    this.rateRequestParam.IgnorePorts = event.target.checked !== false;
  }

  generateNewRequests() {
    this.loading = true;
    this.saveResultMessage = '';

    this.pricingService.GenerateRateRequests(this.rateRequestParam).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data: IRateRequestDto[]) => {
        if (data) {
          this.generatedRateRequests = Object.assign([], data);
        }
        this.loading = false;
      }, error: () => {
        this.alertService.warn('Unable to generate new requests');
        this.loading = false;
      }
    });
  }

  canSave() {
    const rateRequestsToAdd = this.generatedRateRequests.filter((r) => r.Id === null || r.Active === false);
    return !(rateRequestsToAdd && rateRequestsToAdd.length > 0);
  }

  applyNewRequests() {
    this.loading = true;

    if (this.generatedRateRequests && this.generatedRateRequests.length > 0) {
      const existingRequests = this.generatedRateRequests.filter((r) => r.Id !== null);
      const rateRequestsToAdd = this.generatedRateRequests.filter((r) => r.Id === null);

      this.pricingService.CreateRequests(rateRequestsToAdd).pipe(
        takeUntil(this.unsubscribe)
      ).subscribe({
        next: (data: IRateRequestDto[]) => {
          if (data) {
            this.generatedRateRequests = Object.assign([], data);
            this.saveResultMessage = 'Successfully created ' + this.generatedRateRequests.length + ' new requests.';
            // Re-add existing requests
            this.generatedRateRequests.push(...existingRequests);
          }
          this.loading = false;
        },
        error: () => {
          this.alertService.warn('Unable to save new requests');
          this.loading = false;
        }
      });
    }
  }

  close() {
    this.activeModal.dismiss();
  }
}
