<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom sticky-header">
  <h1 class="portal-component-header">
    Article Review
  </h1>
  <div class="justify-content-end" *ngIf="articleReview">
    <button (click)="resetArticle()"
            *ngIf="hasReviewPermissions && articleReview.Review != null && !loading && !disable"
            [title]="'Reset to the original version'"
            class="btn btn-sm btn-outline-primary harmony-primary-outline-button me-1">
      <fa-icon [icon]="faReset" size="lg"></fa-icon>
    </button>
    <button (click)="saveReview()"
            *ngIf="hasReviewPermissions && !loading && !disable"
            [title]="'Save to continue later'"
            class="btn btn-sm btn-outline-primary harmony-tertiary-outline-button me-1">
      <fa-icon [icon]="faSave" size="lg"></fa-icon>
      Save
    </button>
    <button (click)="requestChanges()"
            *ngIf="hasReviewPermissions && !loading && !disable"
            [title]="'Request changes for review by the author'"
            class="btn btn-sm btn-outline-primary harmony-secondary-outline-button me-1">
      <fa-icon [icon]="faCompare" size="lg"></fa-icon>
      Send Request
    </button>
    <button (click)="approveArticle()"
            *ngIf="!loading && !disable" [title]="'Approve the article without changes'"
            class="btn btn-sm btn-outline-primary harmony-accent-green-outline-button ms-2 me-1">
      <fa-icon [icon]="faThumbsUp" size="lg"></fa-icon>
      Approve
    </button>
    <button (click)="rejectArticle()"
            *ngIf="!loading && !disable" [title]="'Reject the article'"
            class="btn btn-sm btn-outline-primary harmony-accent-coral-outline-button me-1">
      <fa-icon [icon]="faThumbsDown" size="lg"></fa-icon>
      Reject
    </button>
    <button (click)="back()"
            [disabled]="loading"
            [title]="'Back to article management'" class="btn btn-sm btn-primary harmony-primary-outline-button ms-2 me-1">
      Back
    </button>
  </div>
</div>
<!-------------------------------->
<!------ Awaiting Approval ------->
<!-------------------------------->
<ng-container *ngIf="hasReviewPermissions && articleReview?.Review">
  <!---------------------->
  <!------ Review -------->
  <!---------------------->
  <div class="row">
    <div class="col-4">
      <div class="row">
        <div class="col-8 align-self-center">
          <h5 class="harmony-primary-text fw-bold mb-0">
            Cover Image
          </h5>
        </div>
        <div class="col-4 text-end">
          <button (click)="removeImage()"
                  *ngIf="articleReview.Article.ImageReference && !disable"
                  class="btn btn-sm btn-outline-danger ms-1"
                  [disabled]="loading"
                  type="button"
                  [title]="'Remove image'">
            <fa-icon [icon]="faDelete"></fa-icon>
          </button>
        </div>
      </div>
      <hr class="my-1">
      <div class="row mt-1">
        <div class="col-12">
          <div class="cover-image-canvas">
            <img [src]="imageBaseUrl + articleReview.Article.ArticleId + '/'+ articleReview.Article.ImageReference"
                 *ngIf="articleReview.Article.ImageReference"
                 [alt]="articleReview.Article.Title"
                 alt="article-cover-image"
                 class="cover-image">
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12 align-self-center">
          <h5 class="harmony-primary-text fw-bold mb-0">Settings</h5>
        </div>
      </div>
      <hr class="my-1">
      <div class="row mt-1">
        <div class="col-6 align-self-center">
          <b>Featured</b>
          <fa-icon [icon]="faInfo"
                   class="harmony-grey-text detail-info pointer ms-1"
                   [title]="'Featured articles will be shown on certain dashboards throughout the portal'"></fa-icon>
        </div>
        <div class="col-6">
          <div class="form-check form-switch form-switch-md">
            <input class="form-check-input"
                   type="checkbox"
                   role="switch"
                   [disabled]="loading || disable"
                   [id]="'IsArticleFeatured'"
                   [checked]="articleReview.Review.Featured"
                   (change)="setFeaturedStatus($event)">
          </div>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-6 align-self-center">
          <b>Show on profile</b>
          <fa-icon [icon]="faInfo"
                   class="harmony-grey-text detail-info pointer ms-1"
                   [title]="'The article will be shown on the member profile page of your company in the Harmony corporate website. \n This will be publicly available to anyone who visits the Harmony website.'"></fa-icon>
        </div>
        <div class="col-6">
          <div class="form-check form-switch form-switch-md">
            <input class="form-check-input"
                   type="checkbox"
                   role="switch"
                   [disabled]="loading || disable"
                   [id]="'IsArticleFeatured'"
                   [checked]="articleReview.Review.Profile"
                   (change)="setProfileStatus($event)">
          </div>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-6 align-self-center">
          <b>Show on website</b>
          <fa-icon [icon]="faInfo"
                   class="harmony-grey-text detail-info pointer ms-1"
                   [title]="'The article will be shown on the member profile page of your company in the Harmony corporate website. This will be publicly available to anyone who visits the Harmony website.'"></fa-icon>
        </div>
        <div class="col-6">
          <div class="form-check form-switch form-switch-md">
            <input class="form-check-input"
                   type="checkbox"
                   role="switch"
                   [disabled]="loading || disable"
                   [id]="'IsArticleFeatured'"
                   [checked]="articleReview.Review.Website"
                   (change)="setWebsiteStatus($event)">
          </div>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-6 align-self-center">
          <b>Publish date</b>
          <fa-icon [icon]="faInfo"
                   class="harmony-grey-text detail-info pointer ms-1"
                   [title]="'Preferred date for when the article should be visible to other members'"></fa-icon>
        </div>
        <div class="col-6 text-start">
          <div class="form-check form-switch form-switch-md ps-0">
            <app-date-picker [identifier]="'articleExpiryDate'"
                             [disabled]="loading || disable"
                             (onChange)="articleReview.Review.PublishDate = $event"
                             [value]="articleReview.Review.PublishDate"
                             [placeholder]="'Select an expiry date'">
            </app-date-picker>
          </div>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-6 align-self-center">
          <b>Expire date</b>
          <fa-icon [icon]="faInfo"
                   class="harmony-grey-text detail-info pointer ms-1"
                   [title]="'Optional date for when the article should expire and no longer be visible to other members'"></fa-icon>
        </div>
        <div class="col-6 text-start">
          <div class="form-check form-switch form-switch-md ps-0">
            <app-date-picker [identifier]="'articleExpiryDate'"
                             [disabled]="loading || disable"
                             (onChange)="articleReview.Review.ExpireDate = $event"
                             [value]="articleReview.Review.ExpireDate"
                             [placeholder]="'Select an expiry date'">
            </app-date-picker>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-6 align-self-center">
          <h5 class="harmony-primary-text fw-bold mb-0">Attachments</h5>
        </div>
        <div class="col-6 align-self-center text-end harmony-secondary-text">
          <fa-icon [icon]="faAttachment" size="sm"></fa-icon>
          <span class="mx-2">{{ articleReview.Article.TotalAttachments ?? 0 }}</span>
        </div>
      </div>
      <hr class="my-1">
      <ng-container *ngIf="attachments?.length <= 0">
        <div class="row">
          <div class="col-12">
            <small class="harmony-grey-text">Article has no attachments...</small>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="attachments?.length > 0">
        <div *ngFor="let a of attachments" class="row mb-1">
          <div class="col-9 align-self-center overflow-hidden">
            {{ a.Name }}
          </div>
          <div class="col-3">
            <button (click)="downloadAttachment(a.Reference)"
                    class="btn btn-sm btn-primary harmony-tertiary-outline-button border-0 float-end"
                    type="button" [title]="'Download Attachment'">
              <fa-icon [icon]="faDownload"></fa-icon>
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="articleLogs?.length > 0">
        <div class="row mt-4">
          <div class="col-12 align-self-center">
            <h5 class="harmony-primary-text fw-bold mb-0">Review History</h5>
          </div>
        </div>
        <hr class="mt-1 mb-2">
        <div class="row" *ngFor="let log of articleLogs">
          <div class="col-8 align-self-center">
            <p class="mb-0 fw-bold" style="margin-bottom: -5px !important;">
              {{ log.UserDisplayName }}
            </p>
            <small class="mb-0 harmony-grey-text fst-italic">
              {{ log.StatusName }}
            </small>
          </div>
          <div class="col-4 harmony-grey-text align-self-center">
            <small class="float-end" style="font-size: 9pt;">{{ log.DateCreated | date:env.FormattingStandards.LongDateFormat }}</small>
          </div>
          <div class="col-12 align-self-center">
            <p class="mb-0">{{ log.Comment }}</p>
          </div>
          <div class="col-6 offset-3">
            <hr class="my-1">
          </div>
        </div>
      </ng-container>
    </div>
    <div class="col-8">
      <div class="row mt-2">
        <div class="col-12 align-self-center">
          <h5 class="harmony-primary-text fw-bold mb-0">
            Article
          </h5>
        </div>
      </div>
      <hr class="my-1">
      <div class="row mt-1">
        <div class="col-12">
          <textarea [(ngModel)]="articleReview.Review.Title" class="form-control form-control-sm"
                    [ngClass]="{'is-invalid': articleReview.Review.Title?.length < 1 }"
                    name="title" style="font-size: 1.5em;" id="article-title"
                    [placeholder]="'Provide a title/headline for your article...'"
                    rows="1"></textarea>
        </div>
      </div>
      <div class="row mb-2 mt-1">
        <div class="col-12">
          <ng-select *ngIf="articleReview.Review.Categories && (categories$ | async)"
                     [(ngModel)]="articleReview.Review.Categories"
                     [closeOnSelect]="false"
                     [items]="(categories$| async)"
                     [placeholder]="'Select categories that apply to the article...'"
                     [disabled]="disable"
                     [multiple]="true"
                     [searchable]="false"
                     [bindLabel]="'Name'">
          </ng-select>
        </div>
      </div>
      <!---------------------->
      <!------ Editor -------->
      <!---------------------->
      <editor (onInit)="initTinymce($event)"
              [(ngModel)]="articleReview.Review.HtmlContent"
              [init]="editorConfig"
              [disabled]="disable"
              apiKey="{{env.TinyApiKey}}"></editor>
      <div class="row mt-1">
        <div class="col-6 offset-3 text-center">
          <button (click)="preview()"
                  [disabled]="articleReview.Review?.HtmlContent?.length <= 0 && articleReview.Review?.Title?.length <= 0"
                  class="btn btn-sm btn-primary harmony-secondary-outline-button w-100 me-1"
                  type="button">
            Preview
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<!------------------------------->
<!---------- Not Found ---------->
<!------------------------------->
<ng-container *ngIf="!articleReview && !loading">
  <div class="row mb-5 pb-5 mt-5 pt-5">
    <div class="col-12 col-lg-6 offset-lg-3 text-center">
      <h5 class="harmony-secondary-text fw-bold">
        Review can not be found.
        <br>
        Article has been removed or the status has changed.
      </h5>
    </div>
  </div>
</ng-container>
<!------------------------------->
<!-------- Access Denied -------->
<!------------------------------->
<ng-container *ngIf="!hasReviewPermissions">
  <div class="row mb-5 pb-5 mt-5 pt-5">
    <div class="col-12 col-lg-6 offset-lg-3 text-center">
      <h5 class="harmony-secondary-text fw-bold">
        Article is in the review process
        <br>
        You will receive a notification when the article status has been updated.
      </h5>
    </div>
  </div>
</ng-container>
