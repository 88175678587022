<!--------------------------->
<!---- Filter & Refresh ----->
<!--------------------------->
<div class="row">
  <div class="col-2">
    <select class="form-select form-select-sm"
            id="tenderStatus"
            [disabled]="!(statuses$ | async)"
            [(ngModel)]="searchParam.TenderStatusId"
            (change)="searchTenders()">
      <option [ngValue]="null">All statuses...</option>
      <option *ngFor="let status of (statuses$ | async)" [ngValue]="status.Id">{{status.Name}}</option>
    </select>
  </div>
  <div class="col-2">
    <select class="form-select form-select-sm"
            id="tenderStep"
            [disabled]="!(steps$ | async)"
            [(ngModel)]="searchParam.TenderStepId"
            (change)="searchTenders()">
      <option [ngValue]="null">All steps...</option>
      <option *ngFor="let step of (steps$ | async)" [ngValue]="step.Id">{{step.Name}}</option>
    </select>
  </div>
</div>
<hr class="my-1">
<div class="row mt-1">
  <div class="col-4">
    <input type="text"
           class="filter-item form-control form-control-sm"
           [(ngModel)]="searchParam.TenderName"
           (keyup.enter)="searchTenders()"
           placeholder="Search tenders...">
  </div>
  <div class="col-3 offset-5 text-end">
    <button class="btn btn-sm btn-outline-primary harmony-primary-outline-button me-1"
            (click)="resetSearch()">
      Reset
    </button>
    <button class="btn btn-sm btn-primary harmony-tertiary-button w-50"
            (click)="searchTenders()">
      Search
    </button>
  </div>
</div>
<hr class="my-1">
<!------------------->
<!---- Pagination --->
<!------------------->
<div class="row ms-1 me-1">
  <div class="col-12">
    <app-pagination [showPageNumber]=true
                    [showRowsPerPage]=true
                    [paginationData]=paginationData
                    (pageEvent)="onPage($event)"
                    [name]="'TenderOverview'"
                    [page]="page">
    </app-pagination>
  </div>
</div>
<!--------------------->
<!---- Result table --->
<!--------------------->
<div class="flex-wrap flex-md-nowrap align-items-center mt-1 pb-3">
  <div class="row header-row align-content-start">
    <div class="col-3 header-title border-split-left">
      Tender Name
    </div>
    <div class="col-2 header-title">
      Status
    </div>
    <div class="col-2 header-title">
      Step
    </div>
    <div class="col-1 header-title">
      Locations
    </div>
    <div class="col-1 header-title">
      Expires
    </div>
    <div class="col-1 header-title">
      Quote
    </div>
    <div class="col-2 header-title border-split-right">
      Quote Accepted
    </div>
  </div>
  <ng-container *ngIf="paginationData && !loading">
    <div class="row result-row" *ngFor="let tender of paginationData.DataSet; let i = index">
      <div class="col-3 text-capitalize overflow-hidden fw-bold">
        {{ tender.Name ?? '-' }}
        <img *ngIf="tender.QuoteAcceptedDate != null && tender.IsProcessed == false"
             src="../../../../../assets/loaders/tail-spin.svg"
             class="ms-2 me-2"
             width="20"
             alt=""/>
      </div>
      <div class="col-2 overflow-hidden" data-bs-toggle="tooltip">
        {{ tender.TenderStatusName ?? '-' }}
      </div>
      <div class="col-2 overflow-hidden" data-bs-toggle="tooltip">
        {{ tender.TenderStepName ?? '-' }}
      </div>
      <div class="col-1 fw-bold" data-bs-toggle="tooltip">
        {{ tender.LocationCount }}
      </div>
      <div class="col-1" data-bs-toggle="tooltip">
        <small [ngClass]="{
            'harmony-accent-green-text': isDayGreaterThan(tender.ExpiryDate, 30),
            'text-warning': isDaySmallerThan(tender.ExpiryDate, 30),
            'text-danger': getDayDifference(tender.ExpiryDate) <= 0}">
          {{ tender.ExpiryDate | date: environment.FormattingStandards.ShortDateFormat }}
        </small>
      </div>
      <div class="col-1" data-bs-toggle="tooltip">
        <b>&euro;</b> {{ tender.QuoteAmount ? tender.QuoteAmount : '-'}}
      </div>
      <div class="col-1" data-bs-toggle="tooltip">
        <small>
          {{ tender.QuoteAcceptedDate ? (tender.QuoteAcceptedDate | date:environment.FormattingStandards.ShortDateFormat) : '-' }}
        </small>
      </div>
      <div class="col-1 text-end pe-0">
        <button class="btn btn-sm harmony-tertiary-outline-button me-2"
                data-bs-toggle="tooltip"
                title="Reset Tender"
                *ngIf="canReProcess && tender.QuoteAcceptedDate != null"
                (click)="resetTender(tender)">
          <fa-icon [icon]="faReRun" size="sm"></fa-icon>
        </button>
        <button class="btn btn-sm harmony-primary-outline-button"
                data-bs-toggle="tooltip"
                title="Open Tender"
                (click)="editTender(tender)">
          <fa-icon [icon]="faEditIcon" size="sm"></fa-icon>
        </button>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="paginationData && paginationData.DataSet.length <= 0 && !loading">
    <div class="row text-center m-3">
      <div class="col-md-12 tender-item harmony-grey-text">
        No active tenders found. Start a <b (click)="createNew()" class="pointer harmony-tertiary-link">new tender</b>?
      </div>
    </div>
  </ng-container>
  <app-loader *ngIf="loading" class="m-3"></app-loader>
</div>
