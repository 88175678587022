<div class="row">
  <div class="col-md-12">
    <div class="input-group input-group-sm">
      <button class="btn btn-outline-secondary harmony-grey-outline-button dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
        {{ selectedCountry }}
      </button>
      <ul class="dropdown-menu">
        <li>
          <a class="dropdown-item" (click)="selectedCountry = 'All'">
            All
          </a>
        </li>
        <li *ngFor="let country of (countries$ | async)">
          <a class="dropdown-item pointer" (click)="selectedCountry = country.Iso3">
            <small><b>{{ country.Iso3 }}</b> : {{ country.Name }}</small>
          </a>
        </li>
      </ul>
      <input id="{{Identifier}}"
             type="text"
             class="form-control form-control-sm"
             appAutocompleteOff
             [disabled]="Disabled"
             [ngModel]="SelectedState"
             [ngStyle]="{'border' : ShowError ? '1px solid ' + errorColor : '1px solid #ccc' }"
             [ngbTypeahead]="searchState"
             [inputFormatter]="formatter"
             [resultFormatter]="formatter"
             [placeholder]="'Type to search states...'"
             (selectItem)="onSelect($event.item)"
             [editable]='false' />
        <span class="input-group-text" *ngIf="searching" >
          <img src="../../../../assets/loaders/tail-spin.svg" width="20" alt="" />
        </span>
        <button
          class="btn btn-outline-primary harmony-tertiary-outline-button"
          type="button"
          [title]="'Clear'"
          [disabled]="Disabled"
          (click)="clear()">X</button>
      </div>
  </div>
</div>
