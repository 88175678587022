<!---------------------->
<!------- Header ------->
<!---------------------->
<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-2 border-bottom sticky-header">
  <h1 class="portal-component-header">
    Manage Rate Requests
  </h1>
  <div>
    <button class="btn btn-sm btn-outline-primary harmony-tertiary-outline-button"
            [title]="'Generate new rate requests'"
            *ngIf="!activeRequest || activeRequest.Id == null"
            (click)="generateRequests()">
      <fa-icon [icon]="faAdd"></fa-icon> Create new requests
    </button>
    <button class="btn btn-sm btn-outline-primary harmony-tertiary-outline-button ms-2"
            [title]="'Generate new rate requests'"
            *ngIf="!activeRequest || activeRequest.Id == null"
            (click)="createNewTemplate()">
      <fa-icon [icon]="faAdd"></fa-icon> New template
    </button>
    <button type="button"
            class="btn btn-sm harmony-primary-outline-button ms-2"
            *ngIf="activeRequest && activeRequest.Id != null"
            (click)="backToOverview()">
      Back to overview
    </button>
  </div>
</div>
<!------------------------------->
<!---- Manage Requests Tabs ----->
<!------------------------------->
<div class="row">
  <div class="col-12">
    <ul class="nav nav-tabs" id="rateRequestManager" role="tablist">
      <li class="nav-item" role="presentation">
        <a class="nav-link"
           [ngClass]="{'active': activeTab == 'manage'}"
           (click)="activeTab = 'manage'"
           id="request-overview-tab"
           data-bs-toggle="tab"
           href="#request-overview"
           role="tab"
           aria-controls="request-overview"
           aria-selected="true">
          Manage Requests
        </a>
      </li>
      <li class="nav-item" role="presentation">
        <a class="nav-link"
           [ngClass]="{'active': activeTab == 'send'}"
           (click)="activeTab = 'send'"
           id="send-requests-tab"
           data-bs-toggle="tab"
           href="#send-requests"
           [hidden]="activeRequest && activeRequest.Id != null"
           role="tab"
           aria-controls="send-requests"
           aria-selected="false">
          Send Requests
        </a>
      </li>
      <li class="nav-item" role="presentation">
        <a class="nav-link"
           [ngClass]="{'active': activeTab == 'templates'}"
           (click)="activeTab = 'templates'"
           id="manage-templates-tab"
           data-bs-toggle="tab"
           href="#manage-templates"
           [hidden]="activeRequest && activeRequest.Id != null"
           role="tab"
           aria-controls="manage-templates"
           aria-selected="false">
          Request Templates
        </a>
      </li>
    </ul>
    <div class="tab-content mt-2" id="rateRequestManagerContent">
      <!--------------------------------->
      <!----------- Overview ------------>
      <!--------------------------------->
      <div class="tab-pane fade"
           [ngClass]="{'active show': activeTab == 'manage'}"
           id="request-overview"
           role="tabpanel"
           aria-labelledby="request-overview-tab">
        <!------------------------------->
        <!---- Rate Request Overview ---->
        <!------------------------------->
        <ng-container *ngIf="!activeRequest || activeRequest.Id == null">
          <div class="row mb-5">
            <div class="col-12">
              <div class="row mt-1">
                <div class="col-4">
                  <div class="row">
                    <div class="col-4 overflow-hidden">
                      <b>Request ID</b>
                    </div>
                    <div class="col-8">
                      <input type="text"
                             class="form-control form-control-sm"
                             [placeholder]="'Enter rate request ID'"
                             [(ngModel)]="searchParam.Id">
                    </div>
                  </div>
                  <div class="row mt-1">
                    <div class="col-4 overflow-hidden">
                      <b>Project</b>
                    </div>
                    <div class="col-8">
                      <select class="form-select form-select-sm pointer"
                              [disabled]="!(projects$ | async)"
                              [(ngModel)]="projectID"
                              (change)="searchParam.RateRequestTypeId = null">
                        <option [ngValue]="null" selected>Select a project...</option>
                        <option *ngFor="let project of (projects$ | async)" [ngValue]="project.Id">{{ project.Name }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="row mt-1">
                    <div class="col-4 overflow-hidden">
                      <b>Request Type</b>
                    </div>
                    <div class="col-8">
                      <select class="form-select form-select-sm pointer"
                              [disabled]="!projectID || !(rateRequestTypes$ | async)"
                              [(ngModel)]="searchParam.RateRequestTypeId">
                        <option [ngValue]="null" selected>Select a rate request type...</option>
                        <option *ngFor="let rateRequestType of (rateRequestTypes$ | async)"
                                [hidden]="rateRequestType.ProjectId != projectID"
                                [ngValue]="rateRequestType.Id">
                          {{ rateRequestType.Name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="row mt-1">
                    <div class="col-4 overflow-hidden">
                      <b>Modality Type</b>
                    </div>
                    <div class="col-8">
                      <select class="form-select form-select-sm pointer"
                              [disabled]="!(modalityTypes$ | async)"
                              (change)="setPortType()"
                              [(ngModel)]="modalityTypeID">
                        <option [ngValue]="null" selected>Select a modality type...</option>
                        <option *ngFor="let modalityType of (modalityTypes$ | async)" [ngValue]="modalityType.Id">{{ modalityType.Name }}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <div class="row mt-1">
                    <div class="col-4 overflow-hidden">
                      <b>Agent</b>
                    </div>
                    <div class="col-8">
                      <app-agent-search
                        class="d-inline"
                        [Identifier]="'AgentID'"
                        [SelectedID]="searchParam.AgentId"
                        (OnSelect)="searchParam.AgentId = $event">
                      </app-agent-search>
                    </div>
                  </div>
                  <div class="row mt-1">
                    <div class="col-4 overflow-hidden">
                      <b>Origin Country</b>
                    </div>
                    <div class="col-8">
                      <!-- Country Search -->
                      <app-country-search
                        class="d-inline"
                        [Identifier]="'OriginCountryID'"
                        [SelectedID]="searchParam.OriginCountryId"
                        (OnSelect)="searchParam.OriginCountryId = $event">
                      </app-country-search>
                    </div>
                  </div>
                  <div class="row mt-1">
                    <div class="col-4 overflow-hidden">
                      <b>Origin City</b>
                    </div>
                    <div class="col-8">
                      <!-- City Search -->
                      <app-city-search
                        class="d-inline"
                        [Identifier]="'OriginLocationCityID'"
                        [SelectedID]="searchParam.OriginCityId"
                        (OnSelect)="searchParam.OriginCityId = $event">
                      </app-city-search>
                    </div>
                  </div>
                  <div class="row mt-1">
                    <div class="col-4 overflow-hidden">
                      <b>Departure Port</b>
                    </div>
                    <div class="col-8">
                      <app-port-search
                        class="d-inline"
                        [Disabled]="modalityTypeID === null || modalityTypeID > 3"
                        [Identifier]="'DeparturePort'"
                        [SelectedID]="searchParam.DeparturePortId"
                        [IsAirport]="portType"
                        [ShowError]="false"
                        (OnSelect)="searchParam.DeparturePortId = $event">
                      </app-port-search>
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <div class="row mt-1">
                    <div class="col-4 overflow-hidden">
                      <b>Request Status</b>
                    </div>
                    <div class="col-8">
                      <select class="form-select form-select-sm pointer"
                              [disabled]="!(rateRequestStatuses$ | async)"
                              [(ngModel)]="searchParam.RateRequestStatusId">
                        <option [ngValue]="null" selected>Select a status...</option>
                        <option *ngFor="let status of (rateRequestStatuses$ | async)" [ngValue]="status.Id">{{ status.Name }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="row mt-1">
                    <div class="col-4 overflow-hidden">
                      <b>Destination Country</b>
                    </div>
                    <div class="col-8">
                      <!-- Country Search -->
                      <app-country-search
                        class="d-inline"
                        [Identifier]="'DestinationCountryID'"
                        [SelectedID]="searchParam.DestinationCountryId"
                        (OnSelect)="searchParam.DestinationCountryId = $event">
                      </app-country-search>
                    </div>
                  </div>
                  <div class="row mt-1">
                    <div class="col-4 overflow-hidden">
                      <b>Destination City</b>
                    </div>
                    <div class="col-8">
                      <!-- City Search -->
                      <app-city-search
                        class="d-inline"
                        [Identifier]="'DestinationLocationCityID'"
                        [SelectedID]="searchParam.DestinationCityId"
                        (OnSelect)="searchParam.DestinationCityId = $event">
                      </app-city-search>
                    </div>
                  </div>
                  <div class="row mt-1">
                    <div class="col-4 overflow-hidden">
                      <b>Arrival Port</b>
                    </div>
                    <div class="col-8">
                      <app-port-search
                        class="d-inline"
                        [Disabled]="modalityTypeID === null || modalityTypeID > 3"
                        [Identifier]="'ArrivalPort'"
                        [SelectedID]="searchParam.ArrivalPortId"
                        [IsAirport]="portType"
                        [ShowError]="false"
                        (OnSelect)="searchParam.ArrivalPortId = $event">
                      </app-port-search>
                    </div>
                  </div>
                </div>
              </div>
              <!------------------->
              <!---- Action bar --->
              <!------------------->
              <hr class="mt-2 mb-1">
              <div class="row me-1">
                <div class="col-2 align-self-center text-start">
                  <div class="form-check form-switch form-switch-sm">
                    <input class="form-check-input pointer float-start"
                           type="checkbox"
                           role="switch"
                           [title]="'Show active requests only'"
                           [id]="'active-rr-only'"
                           [checked]="searchParam.ActiveOnly"
                           (change)="searchParam.ActiveOnly = !searchParam.ActiveOnly">
                    <label class="form-check-label pointer" [for]="'active-rr-only'">
                      <b>{{ searchParam.ActiveOnly == true ? 'Active only' : 'Active & Inactive' }}</b>
                    </label>
                  </div>
                </div>
                <div class="col-4 offset-4 text-end pe-0">
                  <button class="btn btn-sm btn-primary harmony-primary-outline-button ms-2"
                          [title]="'Reset Search'"
                          (click)="resetSearch()">
                    Reset
                  </button>
                </div>
                <div class="col-2 text-end pe-0 ps-1">
                  <button class="btn btn-sm btn-primary harmony-tertiary-button w-100"
                          style="bottom: 0; right: 1.5em;"
                          (click)="updateSearch()">
                    Search
                  </button>
                </div>
              </div>
              <!------------------->
              <!---- Pagination --->
              <!------------------->
              <hr class="mt-1 mb-0">
              <div class="row ms-1 me-1">
                <div class="col-12">
                  <app-pagination [showPageNumber]=true
                                  [showRowsPerPage]=true
                                  [paginationData]=paginationData
                                  (pageEvent)="onPage($event)"
                                  [name]="'RCRateGroups'"
                                  [page]="page">
                  </app-pagination>
                </div>
              </div>
              <!--------------------->
              <!---- Result table --->
              <!--------------------->
              <div class="row mt-1 header-row">
                <div class="col-2 overflow-hidden col-compress header-title border-split-left"
                     (appOrder)="orderSearch($event)"
                     data-column="RateRequestType.Name"
                     data-order="asc">
                  Request Type
                  <fa-icon [hidden]="orderParam.OrderBy != 'RateRequestType.Name'" class="ms-2 position-relative"></fa-icon>
                </div>
                <div class="col-1 overflow-hidden col-compress header-title"
                     (appOrder)="orderSearch($event)"
                     data-column="DateCreated"
                     data-order="asc">
                  Created
                  <fa-icon [hidden]="orderParam.OrderBy != 'DateCreated'" [icon]="faSortDesc" style="bottom: -3px;" class="ms-2 position-relative"></fa-icon>
                </div>
                <!-- Origin -->
                <div class="col-2 overflow-hidden col-compress header-title"
                     (appOrder)="orderSearch($event)"
                     data-column="OriginCity.Name"
                     data-order="asc">
                  Origin Location
                  <fa-icon [hidden]="orderParam.OrderBy != 'OriginCity.Name'" class="ms-2 position-relative"></fa-icon>
                </div>
                <!-- Destination -->
                <div class="col-2 overflow-hidden col-compress header-title"
                     (appOrder)="orderSearch($event)"
                     data-column="DestinationCity.Name"
                     data-order="asc">
                  Destination Location
                  <fa-icon [hidden]="orderParam.OrderBy != 'DestinationCity.Name'" class="ms-2 position-relative"></fa-icon>
                </div>
                <!-- Other -->
                <div class="col-2 overflow-hidden col-compress header-title"
                     (appOrder)="orderSearch($event)"
                     data-column="Agent.Name"
                     data-order="asc">
                  Agent
                  <fa-icon [hidden]="orderParam.OrderBy != 'Agent.Name'" class="ms-2 position-relative"></fa-icon>
                </div>
                <div class="col-2 overflow-hidden col-compress header-title"
                     (appOrder)="orderSearch($event)"
                     data-column="RateRequestStatus.Name"
                     data-order="asc">
                  Status
                  <fa-icon [hidden]="orderParam.OrderBy != 'RateRequestStatus.Name'" class="ms-2 position-relative"></fa-icon>
                </div>
                <div class="col-1 overflow-hidden col-compress header-title border-split-right">

                </div>
              </div>
              <div class="row result-row" *ngFor='let rr of paginationData.DataSet; let i = index'>
                <div class="col-2 col-compress overflow-hidden">
                  <b>{{ rr.RateRequestTypeName }}</b>
                </div>
                <div class="col-1 col-compress overflow-hidden" [title]="rr.AgentName">
                  <small>{{ rr.DateCreated | date: environment.FormattingStandards.ShortDateFormat }}</small>
                </div>
                <!-- Origin -->
                <div class="col-2 col-compress overflow-hidden" [title]="pricingService.GetRequestOriginLocationName(rr)">
                  <small>
                    <!-- Location types City, Country, State -->
                    <fa-icon [icon]="faLocation" size="sm" class="me-1 harmony-tertiary-text"></fa-icon>
                    {{ pricingService.GetRequestOriginLocationName(rr) }}
                    <br>
                    <!-- Location types Seaport or Airport -->
                    <fa-icon *ngIf="rr.RateRequestTypeName?.toLowerCase().includes('air')" [icon]="faDepartureAir" size="sm" class="me-1 harmony-primary-text"></fa-icon>
                    <fa-icon *ngIf="rr.RateRequestTypeName?.toLowerCase().includes('sea')" [icon]="faSea" size="sm" class="me-1 harmony-primary-text"></fa-icon>
                    <fa-icon *ngIf="rr.RateRequestTypeName?.toLowerCase().includes('road')" [icon]="faRoad" size="sm" class="me-1 harmony-primary-text"></fa-icon>
                    {{ rr.DeparturePortName ?? '-' }}
                  </small>
                </div>
                <!-- Destination -->
                <div class="col-2 col-compress overflow-hidden" [title]="pricingService.GetRequestDestinationLocationName(rr)">
                  <small>
                    <!-- Location types City, Country, State -->
                    <fa-icon [icon]="faLocation" size="sm" class="me-1 harmony-tertiary-text"></fa-icon>
                    {{ pricingService.GetRequestDestinationLocationName(rr) }}
                    <br>
                    <!-- Location types Seaport or Airport -->
                    <fa-icon *ngIf="rr.RateRequestTypeName?.toLowerCase().includes('air')" [icon]="faArrivalAir" size="sm" class="me-1 harmony-primary-text"></fa-icon>
                    <fa-icon *ngIf="rr.RateRequestTypeName?.toLowerCase().includes('sea')" [icon]="faSea" size="sm" class="me-1 harmony-primary-text"></fa-icon>
                    <fa-icon *ngIf="rr.RateRequestTypeName?.toLowerCase().includes('road')" [icon]="faRoad" size="sm" class="me-1 harmony-primary-text"></fa-icon>
                    {{ rr.ArrivalPortName ?? '-' }}
                  </small>
                </div>
                <!-- Other -->
                <div class="col-2 col-compress overflow-hidden" [title]="rr.AgentName">
                  <small>{{ rr.AgentName ?? '-' }}</small>
                </div>
                <div class="col-2 col-compress overflow-hidden" [title]="rr.AgentName">
                  <small>{{ rr.RateRequestStatusName ?? '-' }}</small>
                </div>
                <div class="col-1 text-end col-compress pe-0">
                  <button class="btn btn-sm btn-primary harmony-tertiary-outline-button me-1"
                          [title]="'Review Rates'"
                          (click)="editRequest(rr)">
                    <fa-icon [icon]="faEditIcon" size="sm"></fa-icon>
                  </button>
                  <button class="btn btn-sm btn-primary harmony-primary-outline-button"
                          [title]="'View Action Log'"
                          (click)="viewActionLog(rr)">
                    <fa-icon [icon]="faActions" size="sm"></fa-icon>
                  </button>
                  <ng-container *ngIf="isRateAdmin">
                    <button type="button"
                            class="btn btn-sm btn-outline-success ms-1"
                            *ngIf="rr.Active === false"
                            (click)="activateRateRequest(rr)">
                      <fa-icon [icon]="faAdd" size="sm"></fa-icon>
                    </button>
                    <button type="button"
                            class="btn btn-sm btn-outline-danger ms-1"
                            *ngIf="rr.Active === true"
                            (click)="removeRateRequest(rr)">
                      <fa-icon [icon]="faRemove" size="sm"></fa-icon>
                    </button>
                  </ng-container>
                </div>
              </div>
              <!---------------------->
              <!------ No Result ----->
              <!---------------------->
              <div class="row mt-4" *ngIf="paginationData.DataSet && paginationData.DataSet.length === 0 && !loading">
                <div class="col-12 text-center harmony-grey-text">
                  <fa-icon [icon]="faEmpty" size="2x"></fa-icon>
                </div>
                <div class="col-12 text-center harmony-grey-text mt-1">
                  <h4>No rate requests found, adjust filters and try again...</h4>
                </div>
              </div>
              <!---------------------->
              <!------- Loader ------->
              <!---------------------->
              <div class="row mt-4" *ngIf="loading">
                <div class="col-12 text-center">
                  <app-loader></app-loader>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <!------------------------>
        <!---- Active Request ---->
        <!------------------------>
        <ng-container *ngIf="activeRequest && activeRequest.Id != null">
          <!--------------------->
          <!------ Actions ------>
          <!--------------------->
          <div class="row">
            <div class="col-4">
              <div class="row">
                <div class="col-4 align-self-center">
                  <b>Request Status</b>
                </div>
                <div class="col-8">
                  <select class="form-select form-select-sm pointer"
                          [disabled]="!(rateRequestStatuses$ | async)"
                          [(ngModel)]="activeRequest.RateRequestStatusName">
                    <option [ngValue]="null" selected disabled>Select a status...</option>
                    <option *ngFor="let status of (rateRequestStatuses$ | async)" [ngValue]="status.Name">{{ status.Name }}</option>
                  </select>
                </div>
              </div>
              <div class="row mt-1">
                <div class="col-4 align-self-center">
                  <b>Response Date</b>
                </div>
                <div class="col-8">
                  <app-date-picker [identifier]="'responseDate'" [value]="activeRequest.ResponseDate"
                                   (onChange)="activeRequest.ResponseDate = $event">
                  </app-date-picker>
                </div>
              </div>
              <div class="row mt-1">
                <div class="col-4 align-self-center">
                  <b>Agent</b>
                </div>
                <div class="col-8">
                  <app-agent-search
                    class="d-inline"
                    [Identifier]="'RequestAgentID'"
                    [SelectedID]="activeRequest.AgentId"
                    (OnSelectObject)="selectAgent($event)">
                  </app-agent-search>
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="row">
                <div class="col-12">
                  <textarea class="form-control form-control-sm"
                      [rows]="4"
                      [placeholder]="'Optional rate request comment...'"
                      [(ngModel)]="activeRequest.Comment">
                  </textarea>
                </div>
              </div>
            </div>
            <div class="col-3" *ngIf="activeRequest.RateRequestTypeName?.toLowerCase().includes('air') || activeRequest.RateRequestTypeName?.toLowerCase().includes('sea')">
              <div class="row">
                <div class="col-4 align-self-center">
                  <b>Origin</b>
                </div>
                <div class="col-8">
                  <app-port-search
                    class="d-inline"
                    [Disabled]="!activeRequest.RateRequestTypeName?.toLowerCase().includes('origin') && !activeRequest.RateRequestTypeName?.toLowerCase().includes('freight')"
                    *ngIf="activeRequest.RateRequestTypeName?.toLowerCase().includes('air')"
                    [Identifier]="'DepartureAirport'"
                    [SelectedID]="activeRequest.DeparturePortId"
                    [IsAirport]="true"
                    [ShowError]="false"
                    (OnSelectObject)="selectOriginPort($event)">
                  </app-port-search>
                  <app-port-search
                    class="d-inline"
                    [Disabled]="!activeRequest.RateRequestTypeName?.toLowerCase().includes('origin') && !activeRequest.RateRequestTypeName?.toLowerCase().includes('freight')"
                    *ngIf="activeRequest.RateRequestTypeName?.toLowerCase().includes('sea')"
                    [Identifier]="'DepartureAirport'"
                    [SelectedID]="activeRequest.DeparturePortId"
                    [IsAirport]="false"
                    [ShowError]="false"
                    (OnSelectObject)="selectOriginPort($event)">
                  </app-port-search>
                </div>
              </div>
              <div class="row mt-1">
                <div class="col-4 align-self-center">
                  <b>Destination</b>
                </div>
                <div class="col-8">
                  <app-port-search
                    class="d-inline"
                    [Disabled]="!activeRequest.RateRequestTypeName?.toLowerCase().includes('destination') && !activeRequest.RateRequestTypeName?.toLowerCase().includes('freight')"
                    *ngIf="activeRequest.RateRequestTypeName?.toLowerCase().includes('air')"
                    [Identifier]="'DepartureAirport'"
                    [SelectedID]="activeRequest.ArrivalPortId"
                    [IsAirport]="true"
                    [ShowError]="false"
                    (OnSelectObject)="selectDestinationPort($event)">
                  </app-port-search>
                  <app-port-search
                    class="d-inline"
                    [Disabled]="!activeRequest.RateRequestTypeName?.toLowerCase().includes('destination') && !activeRequest.RateRequestTypeName?.toLowerCase().includes('freight')"
                    *ngIf="activeRequest.RateRequestTypeName?.toLowerCase().includes('sea')"
                    [Identifier]="'DepartureAirport'"
                    [SelectedID]="activeRequest.ArrivalPortId"
                    [IsAirport]="false"
                    [ShowError]="false"
                    (OnSelectObject)="selectDestinationPort($event)">
                  </app-port-search>
                </div>
              </div>
            </div>
          </div>
          <hr class="my-1">
          <div class="row">
            <div class="col-2 align-self-center">
              <div class="form-check form-switch center-switch">
                <input class="form-check-input pointer"
                       type="checkbox"
                       role="switch"
                       [id]="'switch-request-active'"
                       [(ngModel)]="activeRequest.Active">
                <label class="form-check-label pointer" [for]="'switch-request-active'">
                  <b>{{ activeRequest.Active == true ? 'Active' : 'Inactive' }}</b>
                </label>
              </div>
            </div>
            <div class="col-2 offset-8">
              <button type="button"
                      (click)="updateRequest()"
                      class="btn btn-sm btn-primary harmony-tertiary-button w-100">
                Update Request
              </button>
            </div>
          </div>
          <hr class="my-1">
          <!--------------------->
          <!---- Rate groups ---->
          <!--------------------->
          <div class="row mt-1 header-row">
            <div class="col-2 col-compress header-title pointer border-split-left"
                 (appOrder)="orderSearch($event)"
                 data-column="Description"
                 data-order="asc">
              Modality
              <fa-icon [hidden]="orderParam.OrderBy != 'Description'" class="ms-2 position-relative"></fa-icon>
            </div>
            <!-- Origin -->
            <div class="col-3 col-compress header-title pointer"
                 (appOrder)="orderSearch($event)"
                 data-column="OriginCity.Name"
                 data-order="asc">
              Origin Location
              <fa-icon [hidden]="orderParam.OrderBy != 'OriginCity.Name'" class="ms-2 position-relative"></fa-icon>
            </div>
            <!-- Destination -->
            <div class="col-3 col-compress header-title pointer"
                 (appOrder)="orderSearch($event)"
                 data-column="DestinationCity.Name"
                 data-order="asc">
              Destination Location
              <fa-icon [hidden]="orderParam.OrderBy != 'DestinationCity.Name'" class="ms-2 position-relative"></fa-icon>
            </div>
            <div class="col-2 col-compress header-title pointer"
                 (appOrder)="orderSearch($event)"
                 data-column="ValidTo"
                 data-order="desc">
              Valid Dates
              <fa-icon [hidden]="orderParam.OrderBy != 'ValidTo'" [icon]="faSortAsc" style="bottom: 3px;"  class="ms-2 position-relative"></fa-icon>
            </div>
            <div class="col-1 text-start col-compress header-title pointer"
                 (appOrder)="orderSearch($event)"
                 data-column="RateVerdictID"
                 data-order="asc">
              Verdict
              <fa-icon [hidden]="orderParam.OrderBy != 'RateVerdictID'" class="ms-2 position-relative"></fa-icon>
            </div>
            <div class="col-1 text-start col-compress header-title pointer border-split-right">

            </div>
          </div>
          <!----------------------->
          <!------- Results ------->
          <!----------------------->
          <ng-container *ngIf="rateGroupsPaginationData && rateGroupsPaginationData.DataSet.length > 0">
            <div class="row result-row" *ngFor="let rg of rateGroupsPaginationData.DataSet">
              <div class="col-2 col-compress overflow-hidden" [title]="rg.Description">
                <b>{{ rg.Description }}</b>
              </div>
              <!-- Origin -->
              <div class="col-3 col-compress overflow-hidden" [title]="rg.OriginLocationName">
                <small>
                  <!-- Location types City, Country, State -->
                  <fa-icon [icon]="faLocation" size="sm" class="me-1 harmony-tertiary-text"></fa-icon>
                  {{ rg.OriginLocationName ? rg.OriginLocationName : '-' }}
                  <br>
                  <!-- Location types Seaport or Airport -->
                  <fa-icon *ngIf="rg.RateRequestTypeName?.toLowerCase().includes('air')" [icon]="faDepartureAir" size="sm" class="me-1 harmony-primary-text"></fa-icon>
                  <fa-icon *ngIf="rg.RateRequestTypeName?.toLowerCase().includes('sea')" [icon]="faSea" size="sm" class="me-1 harmony-primary-text"></fa-icon>
                  <fa-icon *ngIf="rg.RateRequestTypeName?.toLowerCase().includes('road')" [icon]="faRoad" size="sm" class="me-1 harmony-primary-text"></fa-icon>
                  {{ rg.DeparturePortCode ?  rg.DeparturePortCode : '-' }} {{ rg.DeparturePortDescription ? ' - ' + rg.DeparturePortDescription : '' }}
                </small>
              </div>
              <!-- Destination -->
              <div class="col-3 col-compress overflow-hidden" [title]="rg.DestinationLocationName ? rg.DestinationLocationName : rg.ArrivalPortCode">
                <small>
                  <!-- Location types City, Country, State -->
                  <fa-icon [icon]="faLocation" size="sm" class="me-1 harmony-tertiary-text"></fa-icon>
                  {{ rg.DestinationLocationName ? rg.DestinationLocationName : '-' }}
                  <br>
                  <!-- Location types Seaport or Airport -->
                  <fa-icon *ngIf="rg.RateRequestTypeName?.toLowerCase().includes('air')" [icon]="faArrivalAir" size="sm" class="me-1 harmony-primary-text"></fa-icon>
                  <fa-icon *ngIf="rg.RateRequestTypeName?.toLowerCase().includes('sea')" [icon]="faSea" size="sm" class="me-1 harmony-primary-text"></fa-icon>
                  <fa-icon *ngIf="rg.RateRequestTypeName?.toLowerCase().includes('road')" [icon]="faRoad" size="sm" class="me-1 harmony-primary-text"></fa-icon>
                  {{ rg.ArrivalPortCode ?  rg.ArrivalPortCode : '-' }} {{ rg.ArrivalPortDescription ? ' - ' + rg.ArrivalPortDescription : '' }}
                </small>
              </div>
              <!-- Other -->
              <div class="col-2 col-compress overflow-hidden">
                <small>
                  {{ rg.ValidFrom ? (rg.ValidFrom | date:environment.FormattingStandards.ShortDateFormat) : 'X' }}
                  <fa-icon [icon]="faDateArrow" size="sm" class="mx-1"></fa-icon>
                  {{ rg.ValidTo ? (rg.ValidTo | date:environment.FormattingStandards.ShortDateFormat) : 'X'}}
                </small>
              </div>
              <div class="col-1 col-compress">
                {{ rg.RateVerdictName }}
              </div>
              <div class="col-1 col-compress text-end pe-0">
                <button class="btn btn-sm btn-outline-light harmony-primary-outline-button"
                        [title]="'Manage rates'"
                        (click)="manageRateGroup(rg.RateGroupId)">
                  <fa-icon [icon]="faEditIcon" size="sm"></fa-icon>
                </button>
              </div>
            </div>
          </ng-container>
          <!---------------------->
          <!------- Loader ------->
          <!---------------------->
          <div class="row mt-4" *ngIf="loading">
            <div class="col-12 text-center">
              <app-loader [Width]="50"></app-loader>
            </div>
          </div>
          <!---------------------->
          <!----- No Results ----->
          <!---------------------->
          <div class="row mt-4" *ngIf="rateGroupsPaginationData && rateGroupsPaginationData.DataSet.length === 0 && !loading">
            <div class="col-12 text-center harmony-grey-text">
              <fa-icon [icon]="faEmpty" size="2x"></fa-icon>
            </div>
            <div class="col-12 text-center harmony-grey-text mt-1">
              <h4>This request contains no rates.</h4>
            </div>
          </div>
        </ng-container>
      </div>
      <!--------------------------------->
      <!--------- Send Requests --------->
      <!--------------------------------->
      <div class="tab-pane fade"
           [ngClass]="{'active show': activeTab == 'send'}"
           id="send-requests"
           role="tabpanel"
           aria-labelledby="send-requests-tab">
        <app-send-rate-request></app-send-rate-request>
      </div>
      <!--------------------------------->
      <!------- Manage Templates -------->
      <!--------------------------------->
      <div class="tab-pane fade"
           [ngClass]="{'active show': activeTab == 'templates'}"
           id="manage-templates"
           role="tabpanel"
           aria-labelledby="manage-templates-tab">
        <app-request-template-management></app-request-template-management>
      </div>
    </div>
  </div>
</div>
