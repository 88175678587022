<!------------------------------->
<!--- Searchbar with filters ---->
<!------------------------------->
<div class="row mt-1">
  <div class="col-4">
    <div class="row mt-1">
      <div class="col-4 overflow-hidden align-self-center">
        <b>Active</b>
      </div>
      <div class="col-8">
        <select class="form-select form-select-sm pointer"
                [(ngModel)]="searchParam.Active">
          <option [ngValue]="null">All</option>
          <option [ngValue]="true" selected>Active only</option>
          <option [ngValue]="false">Disabled only</option>
        </select>
      </div>
    </div>
  </div>
  <div class="col-2 offset-6">
<!--    <button class="btn btn-sm btn-outline-primary harmony-tertiary-outline-button w-100"-->
<!--            (click)="newCountry()">-->
<!--      + Add Country-->
<!--    </button>-->
  </div>
</div>
<hr class="mt-2 mb-1">
<div class="row">
  <div class="col-4 overflow-hidden">
    <input type="text"
           class="form-control form-control-sm"
           [placeholder]="'Search countries...'"
           (keyup.enter)="searchFilter()"
           [(ngModel)]="searchParam.SearchText">
  </div>
  <div class="col-4 offset-2 text-end pe-0">
    <button class="btn btn-sm btn-primary harmony-primary-outline-button ms-2"
            [title]="'Reset search'"
            (click)="resetSearch()">
      Reset
    </button>
  </div>
  <div class="col-2 text-end ps-1">
    <button class="btn btn-sm btn-primary harmony-tertiary-button w-100"
            (click)="searchFilter()">
      Search
    </button>
  </div>
</div>
<hr class="mt-1 mb-0">
<!-------------------->
<!---- Pagination ---->
<!-------------------->
<div class="row ms-1 me-1 mb-2">
  <div class="col-12">
    <app-pagination [showPageNumber]=true
                    [showRowsPerPage]=true
                    [paginationData]=paginationData
                    (pageEvent)="onPage($event)"
                    [name]="'CountryManagement'"
                    [page]="page">
    </app-pagination>
  </div>
</div>
<!---------------------->
<!---- Result table ---->
<!---------------------->
<div class="row mt-1 header-row">
  <div class="col-4 col-compress header-title border-split-left pointer"
       (appOrder)="orderSearch($event)"
       data-column="Name"
       data-order="desc">
    Name
    <fa-icon [hidden]="orderParam.OrderBy != 'Name'" [icon]="faSortAsc" style="bottom: 3px;" class="ms-2 position-relative"></fa-icon>
  </div>
  <div class="col-1 col-compress header-title pointer"
       (appOrder)="orderSearch($event)"
       data-column="Iso2"
       data-order="asc">
    ISO2
    <fa-icon [hidden]="orderParam.OrderBy != 'Iso2'" class="ms-2 position-relative"></fa-icon>
  </div>
  <div class="col-1 col-compress header-title pointer"
       (appOrder)="orderSearch($event)"
       data-column="Iso3"
       data-order="asc">
    ISO3
    <fa-icon [hidden]="orderParam.OrderBy != 'Iso3'" class="ms-2 position-relative"></fa-icon>
  </div>
  <div class="col-2 col-compress header-title pointer"
       (appOrder)="orderSearch($event)"
       data-column="ContinentCode"
       data-order="asc">
    Continent
    <fa-icon [hidden]="orderParam.OrderBy != 'ContinentCode'" class="ms-2 position-relative"></fa-icon>
  </div>
  <div class="col-2 col-compress header-title pointer"
       (appOrder)="orderSearch($event)"
       data-column="IsEu"
       data-order="asc">
    EU
    <fa-icon [hidden]="orderParam.OrderBy != 'IsEu'" class="ms-2 position-relative"></fa-icon>
  </div>
  <div class="col-1 col-compress header-title pointer"
       (appOrder)="orderSearch($event)"
       data-column="DateModified"
       data-order="asc">
    Modified
    <fa-icon [hidden]="orderParam.OrderBy != 'DateModified'" class="ms-2 position-relative"></fa-icon>
  </div>
  <div class="col-1 text-end col-compress header-title border-split-right">

  </div>
</div>
<!----------------------->
<!------- Results ------->
<!----------------------->
<ng-container *ngIf="!loading">
  <div class="row result-row" style="margin-bottom: -0.25rem;" *ngFor="let country of paginationData.DataSet; let i = index">
    <div class="col-4 col-compress overflow-hidden">
      <b>{{ country.Name }}</b>
    </div>
    <div class="col-1 col-compress overflow-hidden">
      {{ country.Iso2 ?? '-' }}
    </div>
    <div class="col-1 col-compress overflow-hidden">
      {{ country.Iso3 ?? '-' }}
    </div>
    <div class="col-2 col-compress overflow-hidden">
      {{ country.Continent ?? '-' }}
    </div>
    <div class="col-2 col-compress overflow-hidden">
      <fa-icon [icon]="faCheck" class="harmony-accent-green-text m-1 float-start" *ngIf="country.IsEu"></fa-icon>
      <fa-icon [icon]="faFalse" class="harmony-grey-text m-1 float-start" *ngIf="!country.IsEu"></fa-icon>
    </div>
    <div class="col-1 col-compress overflow-hidden">
      <small>{{ (country.DateModified | date: env.FormattingStandards.ShortDateFormat) ?? '-' }}</small>
    </div>
    <div class="col-1 col-compress text-end pe-1">
      <button class="btn btn-sm btn-outline-light harmony-primary-outline-button"
              [title]="'Edit City: ' + country.Name"
              (click)="editCountry(country)">
        <fa-icon [icon]="faEditIcon" size="sm"></fa-icon>
      </button>
<!--      <ng-container *ngIf="hasDeletePermission">-->
<!--        <button class="btn btn-sm btn-outline-danger ms-1"-->
<!--                *ngIf="country.Active === true"-->
<!--                [title]="'Deactivate User'"-->
<!--                (click)="toggleCountry(country)">-->
<!--          <fa-icon [icon]="faRemove" size="xs"></fa-icon>-->
<!--        </button>-->
<!--        <button class="btn btn-sm btn-outline-success ms-1"-->
<!--                *ngIf="country.Active === false"-->
<!--                (click)="toggleCountry(country)">-->
<!--          <fa-icon [icon]="faAdd" size="xs"></fa-icon>-->
<!--        </button>-->
<!--      </ng-container>-->
    </div>
  </div>
</ng-container>
<div class="row mt-4" *ngIf="paginationData && paginationData.DataSet.length === 0 && !loading">
  <div class="col-12 text-center harmony-grey-text">
    <fa-icon [icon]="faEmpty" size="2x"></fa-icon>
  </div>
  <div class="col-12 text-center harmony-grey-text mt-1">
    <h4>No cities found, change filters and try again...</h4>
  </div>
</div>
<!---------------------->
<!------- Loader ------->
<!---------------------->
<div class="row mt-4" *ngIf="loading">
  <div class="col-12 text-center">
    <app-loader [Width]="50"></app-loader>
  </div>
</div>
<br>
