import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { AuthenticationModule } from './modules/authentication/authentication.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RecaptchaModule } from 'ng-recaptcha';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { RequestInterceptor } from './core/interceptors/request.interceptor';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule, MsalRedirectComponent,
  MsalService
} from '@azure/msal-angular';
import { InteractionType, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { AutocompleteOffDirective } from './shared/directives/autocomplete-off/autocomplete-off.directive';
import { msalConfig } from './core/ad-auth-service/auth-config';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AdAuthService } from './core/ad-auth-service/ad-auth.service';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';
import { CookieConfig } from './core/cookie-policy/cookieConfig';
import { environment } from '../environments/environment';
import { GoogleMapsModule } from '@angular/google-maps';
import { EditorModule } from '@tinymce/tinymce-angular';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import {
  VerifyCompanyDetailsModalComponent
} from './modules/verification/verify-company-details-modal/verify-company-details-modal.component';
import {
  VerifyCompanyUsersModalComponent
} from './modules/verification/verify-company-users-modal/verify-company-users-modal.component';
import { CompanyService } from './services/company.service';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import {
  DashboardCompanyInfoComponent
} from './modules/dashboard/dashboard-company-info/dashboard-company-info.component';
import { DashboardArticlesComponent } from './modules/dashboard/dashboard-articles/dashboard-articles.component';
import { PricingModule } from './modules/pricing/pricing.module';
import { AdminModule } from './modules/admin/admin.module';
import { HseModule } from './modules/hse/hse.module';
import { NetworkModule } from './modules/network/network.module';
import { AnnualReviewModule } from './modules/annual-review/annual-review.module';
import { HubModule } from './modules/hub/hub.module';
import { NewsModule } from './modules/news/news.module';
import {
  ArticleItemComponent
} from './shared/components/article-item/article-item.component';
import { SplashScreenComponent } from './core/splash-screen/splash-screen.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import {
  DashboardCompanyDocumentsComponent
} from './modules/dashboard/dashboard-company-documents/dashboard-company-documents.component';
import { DocumentListComponent } from './shared/components/document-list/document-list.component';
import { HqmsModule } from './modules/hqms/hqms.module';
import {EventsModule} from './modules/events/events.module';
import {ServicesModule} from './modules/services/services.module';

export function loggerCallback() {
}

// Create Instance Factory
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string> | null>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);
  const apiScope = `${environment.b2cPolicies.authorities.instanceUrl}/api/read_api`;

  const protectedRoutes = [
    'User',
    'Iata',
    'AnnualReviewCompanyReview',
    'DangerousSubstanceRegister',
    'HSELookup',
    'PulseChart',
    'Branch',
    'RateCapture',
    'Article',
    'Category',
    'JobInspection'
  ];
  protectedRoutes.forEach(route => {
    protectedResourceMap.set(`${environment.api_url}${route}`, [apiScope]);
  });

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    loginFailedRoute: '/'
  };
}

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    VerifyCompanyDetailsModalComponent,
    VerifyCompanyUsersModalComponent,
    DashboardCompanyInfoComponent,
    DashboardArticlesComponent,
    SplashScreenComponent,
    DashboardCompanyDocumentsComponent
  ],
  imports: [
    // Core modules
    CoreModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FontAwesomeModule,
    RouterModule,
    NgbTooltipModule,
    AuthenticationModule,
    HttpClientModule,
    RecaptchaModule,
    MsalModule,
    NgcCookieConsentModule.forRoot(CookieConfig),
    GoogleMapsModule,
    // Project Modules
    AdminModule,
    HubModule,
    NewsModule,
    PricingModule,
    SharedModule,
    PricingModule,
    NetworkModule,
    HseModule,
    AnnualReviewModule,
    HqmsModule,
    // Routing modules
    AppRoutingModule,
    ArticleItemComponent,
    GoogleMapsModule,
    EditorModule,
    ClipboardModule,
    DocumentListComponent,
    EventsModule,
    ServicesModule
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true},
    {provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory},
    {provide: MSAL_GUARD_CONFIG, useFactory: MSALGuardConfigFactory},
    {provide: MSAL_INTERCEPTOR_CONFIG, useFactory: MSALInterceptorConfigFactory},
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    AdAuthService,
    CompanyService
  ],
  exports: [
      AutocompleteOffDirective
  ],
  bootstrap: [
    AppComponent,
    MsalRedirectComponent
  ]
})
export class AppModule {
}
