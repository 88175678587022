<!---- Header ---->
<div class="modal-header" xmlns="http://www.w3.org/1999/html">
  <h4 class="modal-title" >
    {{ Branch.Id > 0 ? Branch.Name : 'New Company branch' }}
  </h4>
</div>

<!-- Body -->
<div class="modal-body">
  <div class="row mb-1">
    <div class="col-2 fw-bold">
      Company
    </div>
    <div class="col-6">
      <select class="form-select form-select-sm pointer"
              [(ngModel)]="Branch.CompanyId"
              [disabled]="loading || (Branch?.CompanyId != 0 && Branch?.Name != null) || !(companies$ | async)"
              [ngClass]="{ 'is-invalid': (Branch.CompanyId == 0 && isInvalid) }">
        <option [ngValue]="null" selected>All companies...</option>
        <option *ngFor="let company of (companies$ | async)"
                [ngValue]="company.CompanyId">{{ company.CompanyName }}</option>
      </select>
    </div>
  </div>
  <div class="row mb-1">
    <div class="col-2 fw-bold">
      Name
    </div>
    <div class="col-6">
      <input class="form-control form-control-sm" type="text"
             [(ngModel)]="Branch.Name"
             [ngClass]="{ 'is-invalid': Branch.Name == null || Branch.Name == '' && isInvalid}">
    </div>
  </div>
  <div class="row mb-1">
    <div class="col-2 fw-bold">
      Address1
    </div>
    <div class="col-6">
      <input class="form-control form-control-sm" type="text"
             [(ngModel)]="Branch.Address1"
             [ngClass]="{ 'is-invalid': Branch.Address1 == null || Branch.Address1 == '' && isInvalid}">
    </div>
  </div>
  <div class="row mb-1">
    <div class="col-2 fw-bold">
      Address2
    </div>
    <div class="col-6">
      <input class="form-control form-control-sm" type="text"
             [(ngModel)]="Branch.Address2">
    </div>
  </div>
  <div class="row mb-1">
    <div class="col-2 fw-bold">
      ZIP Code
    </div>
    <div class="col-6">
      <input class="form-control form-control-sm" type="text"
             [(ngModel)]="Branch.ZipCode">
    </div>
  </div>
  <div class="row mb-1">
    <div class="col-2 fw-bold">
      City
    </div>
    <div class="col-6">
      <app-city-search [Identifier]="'BranchCityID'"
                       [ShowError]="Branch.CityId == 0 || Branch.CityId == null && isInvalid"
                       [SelectedID]="Branch.CityId"
                       (OnSelect)="Branch.CityId = $event">
      </app-city-search>
    </div>
  </div>
  <div class="row mb-1">
    <div class="col-2 fw-bold">
      Country
    </div>
    <div class="col-6">
      <app-country-search [Identifier]="'BranchCountryID'"
                          [ShowError] = "Branch.CountryId == 0 || Branch.CountryId == null && isInvalid"
                          [SelectedID]="Branch.CountryId"
                          (OnSelect)="Branch.CountryId = $event">
      </app-country-search>
    </div>
  </div>
</div>

<!---- Footer ---->
<div class="modal-footer" >
  <button type="button" class="btn btn-primary harmony-tertiary-button" (click)="updateBranch()">Save</button>
  <button type="button" class="btn btn-primary harmony-primary-button" (click)="close()">Close</button>
</div>

