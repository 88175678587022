<ng-container *ngIf="userContact">
  <div class="row" style="min-height: 11em">
    <div class="col-4">
      <img *ngIf="userContact.ProfilePicture"
           class="contact-profile-pic"
           [src]="getFullPictureName() + token"
           alt="my-profile-picture">
      <img *ngIf="!userContact.ProfilePicture"
           class="contact-profile-pic"
           [src]="defaultImage"
           alt="default-profile-picture">
    </div>
    <div class="col-8">
      <div class="row">
        <div class="col-12">
          <h5 class="harmony-secondary-text mb-0 fw-bold">{{ userContact.DisplayName ?? '-' }}</h5>
          <small>{{ userContact.JobTitle ?? '' }}</small>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <b>{{ userContact.CompanyName ?? '-' }}</b>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 overflow-hidden text-nowrap" style="text-overflow: ellipsis">
          <a class="harmony-secondary-link" [title]="userContact.Username" href="mailto:{{ userContact.Username }}">{{ userContact.Username }}</a>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <a class="harmony-secondary-link" href="tel:{{ userContact.ContactNumber }}">{{ userContact.ContactNumber }}</a>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!userContact">
  <app-loader *ngIf="loading" class="mt-3" [Width]="50"></app-loader>
</ng-container>
