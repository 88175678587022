import { Component, OnDestroy, OnInit } from '@angular/core';
import { IPage, IPaginationData } from '../../../shared/models/pagination-data.model';
import {
    faBoxOpen,
    faNewspaper,
    faPencil,
    faSortUp,
    faTrash
} from '@fortawesome/free-solid-svg-icons';
import { environment } from '../../../../environments/environment';
import { IArticleOverview, IArticleSearchParam } from '../../../services/models/news/article.model';
import { ArticleService } from '../../../services/article.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { AdAuthService } from '../../../core/ad-auth-service/ad-auth.service';
import { NewsRoutes } from 'src/app/core/constants/routes';
import { IOrderParam } from '../../../shared/directives/sort/order.directive';
import { UserContactModalComponent } from '../../network/member-search/member/user-contact-modal/user-contact-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-article-management',
  templateUrl: './network-articles.component.html',
  styleUrls: ['./network-articles.component.scss']
})
export class NetworkArticlesComponent implements OnInit, OnDestroy {
  // Icons
  faEdit = faPencil;
  faRead = faNewspaper;
  faEmpty = faBoxOpen;
  faDelete = faTrash;
  faSortUp = faSortUp;

  // Component variables
  paginationData: IPaginationData<IArticleOverview> = null;
  searchParam: IArticleSearchParam;
  page: IPage;
  order: IOrderParam;

  // General variables
  noResult: boolean = false;
  loading: boolean = true;
  imageBaseUrl: string = '';
  placeholderImage: string;
  private unsubscribe: Subject<any> = new Subject<any>();
  protected readonly env = environment;

  constructor(private articleService: ArticleService,
              private router: Router,
              private modalService: NgbModal,
              private authService: AdAuthService) {
  }

  ngOnInit() {
    this.imageBaseUrl = `${this.env.SiteUrls.AzureStorageBaseURL}${this.env.ContainerNames.ArticleImages}/`;
    this.placeholderImage = `${this.env.SiteUrls.AzureStorageBaseURL}${environment.SiteUrls.NewsImagePlaceHolderUrl}`;

    if (this.authService.CurrentUser) {
      this.initPage();
    }

    this.authService.CurrentUser$
      .subscribe(() => {
        this.initPage();
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  initPage() {
    this.page = {
      pageNumber: 1,
      pageSize: 30,
      batched: false
    } as IPage;

    this.order = {
      OrderBy: 'PublishDate',
      OrderDirection: 'desc'
    } as IOrderParam;

    this.searchParam = {
      SearchText: null,
      Featured: null,
      Website: null,
      ActiveOnly: true,
      Status: 'Published',
      CompanyId: null,
      UserId: null
    } as IArticleSearchParam;

    this.getArticles();
  }

  onOrderChange(param: IOrderParam) {
    this.order = param;
    this.search();
  }

  onPageChange($event: IPage) {
    this.page = $event;
    this.getArticles();
  }

  search() {
    this.page.pageNumber = 1;
    this.getArticles();
  }

  private getArticles() {
    this.loading = true;

    this.articleService.GetArticles(this.page, this.order, this.searchParam)
      .subscribe({
        next: (data) => {
          this.loading = false;
          this.paginationData = {...data};
          this.page.pageNumber = this.paginationData.CurrentPage;
          this.noResult = this.paginationData.DataSet.length === 0;
        }, error: () => {
          this.paginationData = null;
          this.noResult = true;
          this.loading = false;
        }
      });
  }

  viewContactCard(userId: number) {
    // Open modal to edit user
    const modalRef = this.modalService.open(UserContactModalComponent, { size: 'md', centered: true, backdrop: 'static' });
    modalRef.componentInstance.UserID = userId;
  }

  redirectReadArticle(articleId: number) {
    this.router.navigate([`${NewsRoutes.Article_Read}/${articleId}`])
      .then(() => {
      });
  }
}
