<!---------------------->
<!------- Header ------->
<!---------------------->
<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom sticky-header">
  <h1 class="portal-component-header">
    Environmental Impact | {{ selectedCompany?.CompanyName ?? '-' }}
  </h1>
  <div class="justify-content-end align-items-center">
    <div class="form-check form-switch form-switch-sm" style="display: inline-block; position: relative; top: 0.6em; right: 0.4em;">
      <input class="form-check-input pointer float-end"
             type="checkbox"
             role="switch"
             [title]="'Watermark'"
             [id]="'impact-data-watermark-switch'"
             [checked]="showWatermark"
             [disabled]="selectedCompany == null"
             (change)="showWatermark = !showWatermark">
    </div>
    <button (click)="openInfoModal()"
            [disabled]="selectedCompany == null"
            class="btn btn-sm btn-primary harmony-primary-outline-button me-1">
      Information
    </button>
    <button (click)="addImpactChart()" *ngIf="canAdd"
            [disabled]="selectedCompany == null"
            class="btn btn-sm btn-primary harmony-tertiary-outline-button">
      + Add Year
    </button>
  </div>
</div>
<!----------------------->
<!------- Filters ------->
<!----------------------->
<div *ngIf="canFilterCompany" class="row me-1">
  <div class="col-3">
    <select (ngModelChange)="selectCompany($event)"
            [(ngModel)]="selectedCompany" [disabled]="loading || !(companies$ | async)"
            class="form-select form-select-sm pointer">
      <option [ngValue]="null" selected>Select a company...</option>
      <option *ngFor="let company of (companies$ | async)"
              [ngValue]="company">{{ company.CompanyName }}
      </option>
    </select>
  </div>
</div>
<hr *ngIf="canFilterCompany" class="my-2">
<!----------------------->
<!------- Results ------->
<!----------------------->
<app-loader *ngIf="loading"></app-loader>
<app-no-result [showNoResults]="noResult"></app-no-result>
<app-watermark *ngIf="companyImages && showWatermark" [image]="companyImages.Logo"></app-watermark>
<div *ngIf="!noResult" class="accordion" id="accordion-impact-data">
  <!-- Company Basics -->
  <ng-container>
    <div class="row">
      <div class="col-12">
        <div class="card my-1">
          <div class="card-header p-0" [attr.id]="'heading-company-basics'">
            <div class="row pointer" (click)="companyBtn.click(); toggleTab('CB');">
              <div class="col-10 text-start">
                <h3 class="mb-0">
                  <button class="btn chapter-header mb-0 pt-2 fw-bold" #companyBtn
                          type="button"
                          (click)="$event.stopPropagation()"
                          data-bs-toggle="collapse"
                          [attr.data-bs-target]="'#collapse-company-basics'"
                          [attr.aria-expanded]="activeTab === 'CB'"
                          [attr.aria-controls]="'collapse-company-basics'">
                    Company Basics
                  </button>
                </h3>
              </div>
              <div class="col-2 text-center align-middle">
                <div class="mb-0 pt-2 fs-6">

                </div>
              </div>
            </div>
          </div>
          <div [attr.id]="'collapse-company-basics'"
               class="collapse"
               [ngClass]="{'show': activeTab === 'CB'}"
               [attr.aria-labelledby]="'heading-company-basics'"
               data-bs-parent="#accordion-impact-data">
            <div class="card-body">
              <div class="row">
                <div class="col-2">
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Year
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'The year the data is relevant to'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Company Average FTE
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'Average number of FTE (full time equivalent) at the company over the year'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Office Average FTE
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'Average number of FTE (full time equivalent) working at the office over the year'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Warehouse Floor Surface
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'The total surface area of your warehouse in square meters'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Office Floor Surface
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'The total surface area of your office in square meters'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Small Removal Trucks
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'Total trucks owned smaller than 3.5 ton'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Large Removal Trucks
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'Total trucks owned larger than 3.5 ton'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Cars
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'Total cars owned'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Degree Days
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'Unit used to determine heating requirements of buildings, representing a fall of one degree below a specified outdoor average per one day'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Comment
                      </small>
                    </div>
                    <div class="col-2 align-self-center">

                    </div>
                  </div>
                </div>
                <div class="col-10">
                  <div class="horizontal-scrollable d-inline-flex">
                    <div *ngFor='let item of impactData; let i = index' class="float-start horizontal-result">
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <input [(ngModel)]="item.Year"
                                 [ngClass]="{'is-invalid': (!item.Year || item.Year < 2000) && invalid}"
                                 [placeholder]="'e.g. ' + currentYear"
                                 [numbersOnly]="'4.0'"
                                 class="form-control form-control-sm fw-bold" type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <input [(ngModel)]="item.CompanyAverageFte"
                                 (change)="calcValues(item)"
                                 [numbersOnly]="'10.2'"
                                 [placeholder]="'0.0'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <input [(ngModel)]="item.CompanyOfficeAverageFte"
                                 [numbersOnly]="'10.2'"
                                 [placeholder]="'0.0'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <div class="input-group input-group-sm">
                            <input type="text"
                                   [(ngModel)]="item.WarehouseFloorSurfaceArea"
                                   [numbersOnly]="'10.2'"
                                   [placeholder]="'0.0'"
                                   class="form-control" aria-describedby="WarehouseFloorSurfaceArea">
                            <span class="input-group-text" id="WarehouseFloorSurfaceArea">m²</span>
                          </div>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <div class="input-group input-group-sm">
                            <input type="text"
                                   [(ngModel)]="item.OfficeFloorSurfaceArea"
                                   (change)="calcValues(item)"
                                   [numbersOnly]="'10.2'"
                                   [placeholder]="'0.0'"
                                   class="form-control" aria-describedby="OfficeFloorSurfaceArea">
                            <span class="input-group-text" id="OfficeFloorSurfaceArea">m²</span>
                          </div>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <input [(ngModel)]="item.SmallRemovalTrucks"
                                 [numbersOnly]="'10.0'"
                                 [placeholder]="'0'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <input [(ngModel)]="item.LargeRemovalTrucks"
                                 [numbersOnly]="'10.0'"
                                 [placeholder]="'0'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <input [(ngModel)]="item.Cars"
                                 [numbersOnly]="'10.0'"
                                 [placeholder]="'0'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <input [(ngModel)]="item.DegreeDays"
                                 (change)="calcValues(item)"
                                 [numbersOnly]="'10.0'"
                                 [placeholder]="'0'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <textarea [(ngModel)]="item.CompanyBasicsComment"
                                    [placeholder]="'Add a comment'"
                            class="form-control form-control-sm"
                            id="company-basic-comment" name="company-basic-comment"
                            rows="3"></textarea>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12 align-self-center text-end">
                          <img src="../../../../assets/loaders/tail-spin.svg" class="me-2" *ngIf="item.loading == true" width="20" alt="" />
                          <button (click)="upsert(item)" *ngIf="canEdit"
                                  [disabled]="loading"
                                  class="btn btn-sm btn-primary harmony-tertiary-outline-button me-1"
                                  type="button">
                            Save
                          </button>
                          <button (click)="delete(item.Id, item.IsNew)"
                                  *ngIf="canDelete"
                                  [disabled]="loading"
                                  [title]="'Delete'" class="btn btn-sm btn-outline-danger">
                            <fa-icon [icon]="faDelete"></fa-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- Offices and Warehouses -->
  <ng-container>
    <div class="row">
      <div class="col-12">
        <div class="card my-1">
          <div class="card-header p-0" [attr.id]="'heading-offices'">
            <div class="row pointer" (click)="officesBtn.click(); toggleTab('OF');">
              <div class="col-10 text-start">
                <h3 class="mb-0">
                  <button class="btn chapter-header mb-0 pt-2 fw-bold" #officesBtn
                          type="button"
                          (click)="$event.stopPropagation()"
                          data-bs-toggle="collapse"
                          [attr.data-bs-target]="'#collapse-offices'"
                          [attr.aria-expanded]="activeTab === 'OF'"
                          [attr.aria-controls]="'collapse-offices'">
                    Offices and Warehouses
                  </button>
                </h3>
              </div>
              <div class="col-2 text-center align-middle">
                <div class="mb-0 pt-2 fs-6">

                </div>
              </div>
            </div>
          </div>
          <div [attr.id]="'collapse-offices'"
               class="collapse"
               [ngClass]="{'show': activeTab === 'OF'}"
               [attr.aria-labelledby]="'heading-offices'"
               data-bs-parent="#accordion-impact-data">
            <div class="card-body">
              <div class="row ps-1">
                <div class="col-2">
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Year
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'The year the data is relevant to'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Electricity Usage (Grey)
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'Total kilowatt-hour of electricity used provided by a non-renewable energy source, per year in the office and warehouse'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Electricity Usage (Green)
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'Total kilowatt-hour of electricity used provided by a renewable energy source, per year in the office and warehouse'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Electricity Kwh/FTE (Office)
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'Statistic of electricity used at the office per 1 full time equivalent employee'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Natural Gas Usage
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'Total cubic meters of gas used per year in the office or warehouse'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Natural Gas Usage / Day
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'Statistic of gas used per day, compensated for temperature variations'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Natural Gas Use / m²
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'Total cubic meters of gas used per square meter per year'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Fuel Oil Usage
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'Total litres of oil used per year'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Fuel Oil Usage L/Day
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'Total litres of oil used per day in a year'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Fuel Oil Usage l/m²
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'Total litres of oil used per square meter in a year'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Water Usage
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'Total cubic meters of water used per year'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Coolant Replacement
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'Total kilograms of coolant used to fill up the air-conditioning unit (CFK, HFK)'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Comment
                      </small>
                    </div>
                    <div class="col-2 align-self-center">

                    </div>
                  </div>
                </div>
                <div class="col-10">
                  <div class="horizontal-scrollable d-inline-flex">
                    <div *ngFor='let item of impactData; let i = index' class="float-start horizontal-result">
                      <div class="row me-1 mb-1">
                        <div class="col-8">
                          <span class="form-control-sm fw-bold horizontal-header-sm">
                            {{ item.Year }}
                          </span>
                        </div>
                        <div class="col-4 text-center">
                          <span class="form-control-sm fw-bold">
                            CO²
                          </span>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-8">
                          <div class="input-group input-group-sm">
                            <input type="text"
                                   [(ngModel)]="item.GreyElectricUse"
                                   (change)="calcValues(item);calcCo2(item);"
                                   [numbersOnly]="'10.2'"
                                   [placeholder]="'0.0'"
                                   class="form-control" aria-describedby="GreyElectricUse">
                            <span class="input-group-text" id="GreyElectricUse">kWh</span>
                          </div>
                        </div>
                        <div [ngClass]="getColorIndicator(item?.GreyElectricUse, impactData[i +1]?.GreyElectricUse)"
                             class="col-4 text-center">
                          <span class="form-control-sm">
                            {{ item.GreyElectricUseCo2 ?? 0 }} t
                          </span>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-8">
                          <div class="input-group input-group-sm">
                            <input type="text"
                                   [(ngModel)]="item.GreenElectricUse"
                                   (change)="calcValues(item);calcCo2(item);"
                                   [numbersOnly]="'10.2'"
                                   [placeholder]="'0.0'"
                                   class="form-control" aria-describedby="GreenElectricUse">
                            <span class="input-group-text" id="GreenElectricUse">kWh</span>
                          </div>
                        </div>
                        <div [ngClass]="getColorIndicator(item?.GreenElectricUse, impactData[i +1]?.GreenElectricUse)"
                             class="col-4 text-center">
                          <span class="form-control-sm">
                            {{ item.GreenElectricUseCo2 }} t
                          </span>
                        </div>
                      </div>
                      <div class="row me-1 mb-1" style="height: 31px; margin-bottom: 0.25em !important;">
                        <div class="col-8 align-self-center">
                          <small class="ms-2 harmony-secondary-text">
                            <strong>{{ item.OfficeElectricUsePerFte ?? 0 | number:'1.1-2' }}</strong> kWh/FTE
                          </small>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-8">
                          <div class="input-group input-group-sm">
                            <input type="text"
                                   [(ngModel)]="item.NaturalGasUse"
                                   (change)="calcValues(item);calcCo2(item);"
                                   [numbersOnly]="'10.2'"
                                   [placeholder]="'0.0'"
                                   class="form-control" aria-describedby="NaturalGasUse">
                            <span class="input-group-text" id="NaturalGasUse">m³</span>
                          </div>
                        </div>
                        <div [ngClass]="getColorIndicator(item?.NaturalGasUse, impactData[i +1]?.NaturalGasUse)"
                             class="col-4 text-center">
                          <span class="form-control-sm">
                            {{ item.NaturalGasUseCo2 ?? 0 }} t
                          </span>
                        </div>
                      </div>
                      <div class="row me-1 mb-1" style="height: 31px; margin-bottom: 0.25em !important;">
                        <div class="col-8 align-self-center">
                          <small class="ms-2 harmony-secondary-text">
                            <strong>{{ item.NaturalGasUsePerDegreeDay ?? 0 | number:'1.1-2' }}</strong> m³/day
                          </small>
                        </div>
                        <div
                          [ngClass]="getColorIndicator(item?.NaturalGasUsePerDegreeDay, impactData[i +1]?.NaturalGasUsePerDegreeDay)"
                          class="col-4 text-center">
                          <span class="form-control-sm">
                            {{ item.NaturalGasUsePerDegreeDayCo2 ?? 0 }} t
                          </span>
                        </div>
                      </div>
                      <div class="row me-1 mb-1" style="height: 31px; margin-bottom: 0.25em !important;">
                        <div class="col-8 align-self-center">
                          <small class="ms-2 harmony-secondary-text">
                            <strong>{{ item.NaturalGasUsePerSquareMeter ?? 0 | number:'1.1-2' }}</strong> m³/m²
                          </small>
                        </div>
                        <div
                          [ngClass]="getColorIndicator(item?.NaturalGasUsePerSquareMeter, impactData[i +1]?.NaturalGasUsePerSquareMeter)"
                          class="col-4 text-center">
                          <span class="form-control-sm">
                            {{ item.NaturalGasUsePerSquareMeterCo2 ?? 0 }} t
                          </span>
                        </div>
                      </div>
                      <div class="row me-1 my-1">
                        <div class="col-8">
                          <div class="input-group input-group-sm">
                            <input type="text"
                                   [(ngModel)]="item.FuelOilUse"
                                   (change)="calcValues(item);calcCo2(item);"
                                   [numbersOnly]="'10.2'"
                                   [placeholder]="'0.0'"
                                   class="form-control" aria-describedby="FuelOilUse">
                            <span class="input-group-text" id="FuelOilUse">L</span>
                          </div>
                        </div>
                        <div [ngClass]="getColorIndicator(item?.FuelOilUse, impactData[i +1]?.FuelOilUse)"
                             class="col-4 text-center">
                        <span class="form-control-sm">
                            {{ item.FuelOilUseCo2 ?? 0 }} t
                        </span>
                        </div>
                      </div>
                      <div class="row me-1 mb-1" style="height: 31px; margin-bottom: 0.25em !important;">
                        <div class="col-8 align-self-center">
                          <small class="ms-2 harmony-secondary-text">
                            <strong>{{ item.FuelOilUsePerDegreeDay ?? 0 | number:'1.1-2' }}</strong> L/day
                          </small>
                        </div>
                        <div [ngClass]="getColorIndicator(item?.FuelOilUsePerDegreeDay, impactData[i +1]?.FuelOilUsePerDegreeDay)"
                             class="col-4 text-center">
                        <span class="form-control-sm">
                            {{ item.FuelOilUsePerDegreeDayCo2 ?? 0 }} t
                        </span>
                        </div>
                      </div>
                      <div class="row me-1 mb-1" style="height: 31px; margin-bottom: 0.25em !important;">
                        <div class="col-8 align-self-center">
                          <small class="ms-2 harmony-secondary-text">
                            <strong>{{ item.FuelOilUsePerSquareMeter ?? 0 | number:'1.1-2' }}</strong> L/m²
                          </small>
                        </div>
                        <div [ngClass]="getColorIndicator(item?.FuelOilUsePerSquareMeter, impactData[i +1]?.FuelOilUsePerSquareMeter)"
                             class="col-4 text-center">
                          <span class="form-control-sm">
                            {{ item.FuelOilUsePerSquareMeterCo2 ?? 0 }} t
                          </span>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-8">
                          <div class="input-group input-group-sm">
                            <input type="text"
                                   [(ngModel)]="item.WaterUse"
                                   (change)="calcValues(item);calcCo2(item);"
                                   [numbersOnly]="'10.2'"
                                   [placeholder]="'0.0'"
                                   class="form-control" aria-describedby="WaterUse">
                            <span class="input-group-text" id="WaterUse">L</span>
                          </div>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-8">
                          <div class="input-group input-group-sm">
                            <input type="text"
                                   [(ngModel)]="item.CoolantReplacement"
                                   (change)="calcValues(item);calcCo2(item);"
                                   [numbersOnly]="'10.2'"
                                   [placeholder]="'0.0'"
                                   class="form-control" aria-describedby="CoolantReplacement">
                            <span class="input-group-text" id="CoolantReplacement">L</span>
                          </div>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <textarea [(ngModel)]="item.OfficeComment" [placeholder]="'Add a comment'"
                              class="form-control form-control-sm"
                              id="office-comment" name="office-comment"
                              rows="3"></textarea>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12 align-self-center text-end">
                          <img src="../../../../assets/loaders/tail-spin.svg" class="me-2" *ngIf="item.loading == true" width="20" alt="" />
                          <button (click)="upsert(item)" *ngIf="canEdit"
                                  [disabled]="loading"
                                  class="btn btn-sm btn-primary harmony-tertiary-outline-button me-1"
                                  type="button">
                            Save
                          </button>
                          <button (click)="delete(item.Id, item.IsNew)"
                                  *ngIf="canDelete" [disabled]="loading"
                                  [title]="'Delete'" class="btn btn-sm btn-outline-danger">
                            <fa-icon [icon]="faDelete"></fa-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- Vehicles and Travel -->
  <ng-container>
    <div class="row">
      <div class="col-12">
        <div class="card my-1">
          <div class="card-header p-0" [attr.id]="'heading-vehicles'">
            <div class="row pointer" (click)="vehiclesBtn.click(); toggleTab('VH');">
              <div class="col-10 text-start">
                <h3 class="mb-0">
                  <button class="btn chapter-header mb-0 pt-2 fw-bold" #vehiclesBtn
                          type="button"
                          (click)="$event.stopPropagation()"
                          data-bs-toggle="collapse"
                          [attr.data-bs-target]="'#collapse-vehicles'"
                          [attr.aria-expanded]="activeTab === 'VH'"
                          [attr.aria-controls]="'collapse-vehicles'">
                    Vehicles and Travel
                  </button>
                </h3>
              </div>
              <div class="col-2 text-center align-middle">
                <div class="mb-0 pt-2 fs-6">

                </div>
              </div>
            </div>
          </div>
          <div [attr.id]="'collapse-vehicles'"
               class="collapse"
               [ngClass]="{'show': activeTab === 'VH'}"
               [attr.aria-labelledby]="'heading-vehicles'"
               data-bs-parent="#accordion-impact-data">
            <div class="card-body">
              <div class="row">
                <div class="col-2">
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Year
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'The year the data is relevant to'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-12 text-end align-self-center">
                      <b class="harmony-secondary-text">
                        Diesel Consumption
                      </b>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap"
                         style="text-overflow: ellipsis">
                      <small>
                        Removal Truck > 3.5t Usage
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'Total litres of diesel consumed by contracted removal trucks larger than 3.5 ton per year'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        KM
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'Total kilometers travelled by contracted removal trucks larger than 3.5 ton per year'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap"
                         style="text-overflow: ellipsis">
                      <small>
                        Removal Truck < 3.5t Usage
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'Total litres of diesel consumed by contracted removal trucks smaller than 3.5 ton per year'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        KM
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'Total kilometers travelled by contracted removal trucks smaller than 3.5 ton per year'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Diesel Cars Usage
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'Total litres of diesel consumed by contracted cars per year'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        KM
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'Total kilometers travelled by diesel contracted cars per year'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Diesel Subcontractors Usage
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'Total litres of diesel consumed by subcontracted cars per year'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        KM
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'Total kilometers travelled by diesel subcontracted cars per year'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-12 text-end align-self-center">
                      <b class="harmony-secondary-text">
                        Petrol Consumption
                      </b>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap"
                         [title]="'Petrol Consumption Cars'"
                         style="text-overflow: ellipsis">
                      <small>
                        Petrol Cars Usage
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'Total litres of the petrol consumed by contracted cars per year'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        KM
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'Total kilometers travelled by petrol contracted cars per year'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-12 text-end align-self-center">
                      <b class="harmony-secondary-text">
                        LPG Consumption
                      </b>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        LPG Cars Usage
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'Total litres of LPG consumed by cars per year'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        KM
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'Total kilometers travelled by LPG cars per year'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-12 text-end align-self-center">
                      <b class="harmony-secondary-text">
                        Electricity Consumption
                      </b>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Electric Cars (Grey | kWh)
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'Total kilowatt-hours used for full electric contracted cars provided by a non-renewable energy source per year'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        KM
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'Total kilometers travelled by electric contracted cars provided by a non-renewable energy source per year'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Electric Cars (Green | kWh)
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'Total kilowatt-hour used for full electric contracted cars provided by a renewable energy source per year'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        KM
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'Total kilometers travelled by electric contracted cars provided by a renewable energy source per year'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-12 text-end align-self-center">
                      <b class="harmony-secondary-text">
                        Other
                      </b>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Average Euro Class
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'The average of the euro categorisation of vehicles'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Average Emission Class
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'The average emission class car outside of a euro class car'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Air Travel KM
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'Total kilometers travelled via air transport'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Air Travel CO²
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'Total kilograms of carbon dioxide used via air travel (e.g. plane)'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Rail Travel
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'Total kilometers travelled via railway (e.g. train)'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Comment
                      </small>
                    </div>
                    <div class="col-2 align-self-center">

                    </div>
                  </div>
                </div>
                <div class="col-10">
                  <div class="horizontal-scrollable d-inline-flex">
                    <div *ngFor='let item of impactData; let i = index' class="float-start horizontal-result">
                      <div class="row me-1 mb-1">
                        <div class="col-8">
                          <span class="form-control-sm fw-bold horizontal-header-sm">
                            {{ item.Year }}
                          </span>
                        </div>
                        <div class="col-4 text-center">
                          <span class="form-control-sm fw-bold horizontal-header-sm">
                            CO²
                          </span>
                        </div>
                      </div>
                      <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                        <div class="col-12 text-end align-self-center">

                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-8">
                          <div class="input-group input-group-sm">
                            <input type="text"
                                   [(ngModel)]="item.LargeDieselConsRemovalTrucks"
                                   (change)="calcCo2(item)"
                                   [numbersOnly]="'10.2'"
                                   [placeholder]="'0.0'"
                                   class="form-control" aria-describedby="LargeDieselConsRemovalTrucks">
                            <span class="input-group-text" id="LargeDieselConsRemovalTrucks">L</span>
                          </div>
                        </div>
                        <div [ngClass]="getColorIndicator(item?.LargeDieselConsRemovalTrucks, impactData[i +1]?.LargeDieselConsRemovalTrucks)"
                          class="col-4 text-center">
                          <span class="form-control-sm">
                            {{ item.LargeDieselConsRemovalTrucksCo2 ?? 0 }} t
                          </span>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-8">
                          <div class="input-group input-group-sm">
                            <input type="text"
                                   [(ngModel)]="item.LargeDieselTruckTotalKm"
                                   [numbersOnly]="'10.2'"
                                   [placeholder]="'0.0'"
                                   class="form-control" aria-describedby="LargeDieselTruckTotalKm">
                            <span class="input-group-text" id="LargeDieselTruckTotalKm">km</span>
                          </div>
                        </div>
                        <div class="col-4 text-center">

                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-8">
                          <div class="input-group input-group-sm">
                            <input type="text"
                                   [(ngModel)]="item.SmallDieselConsRemovalTrucks"
                                   (change)="calcCo2(item)"
                                   [numbersOnly]="'10.2'"
                                   [placeholder]="'0.0'"
                                   class="form-control" aria-describedby="SmallDieselConsRemovalTrucks">
                            <span class="input-group-text" id="SmallDieselConsRemovalTrucks">L</span>
                          </div>
                        </div>
                        <div [ngClass]="getColorIndicator(item?.SmallDieselConsRemovalTrucks, impactData[i +1]?.SmallDieselConsRemovalTrucks)"
                          class="col-4 text-center">
                          <span class="form-control-sm">
                            {{ item.SmallDieselConsRemovalTrucksCo2 ?? 0 }} t
                          </span>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-8">
                          <div class="input-group input-group-sm">
                            <input type="text"
                                   [(ngModel)]="item.SmallDieselTrucksTotalKm"
                                   [numbersOnly]="'10.2'"
                                   [placeholder]="'0.0'"
                                   class="form-control" aria-describedby="SmallDieselTrucksTotalKm">
                            <span class="input-group-text" id="SmallDieselTrucksTotalKm">km</span>
                          </div>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-8">
                          <div class="input-group input-group-sm">
                            <input type="text"
                                   [(ngModel)]="item.DieselConsCars"
                                   (change)="calcCo2(item)"
                                   [numbersOnly]="'10.2'"
                                   [placeholder]="'0.0'"
                                   class="form-control" aria-describedby="DieselConsCars">
                            <span class="input-group-text" id="DieselConsCars">L</span>
                          </div>
                        </div>
                        <div [ngClass]="getColorIndicator(item?.DieselConsCars, impactData[i +1]?.DieselConsCars)"
                             class="col-4 text-center">
                          <span class="form-control-sm">
                            {{ item.DieselConsCarsCo2 ?? 0 }} t
                          </span>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-8">
                          <div class="input-group input-group-sm">
                            <input type="text"
                                   [(ngModel)]="item.DieselCarsTotalKm"
                                   [numbersOnly]="'10.2'"
                                   [placeholder]="'0.0'"
                                   class="form-control" aria-describedby="DieselCarsTotalKm">
                            <span class="input-group-text" id="DieselCarsTotalKm">km</span>
                          </div>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-8">
                          <div class="input-group input-group-sm">
                            <input type="text"
                                   [(ngModel)]="item.DieselConsCarsSubcontract"
                                   (change)="calcCo2(item)"
                                   [numbersOnly]="'10.2'"
                                   [placeholder]="'0.0'"
                                   class="form-control" aria-describedby="DieselConsCarsSubcontract">
                            <span class="input-group-text" id="DieselConsCarsSubcontract">L</span>
                          </div>
                        </div>
                        <div [ngClass]="getColorIndicator(item?.DieselConsCarsSubcontract, impactData[i +1]?.DieselConsCarsSubcontract)"
                          class="col-4 text-center">
                          <span class="form-control-sm">
                            {{ item.DieselConsCarsSubcontractCo2 ?? 0 }} t
                          </span>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-8">
                          <div class="input-group input-group-sm">
                            <input type="text"
                                   [(ngModel)]="item.DieselCarsSubcontractTotalKm"
                                   [numbersOnly]="'10.2'"
                                   [placeholder]="'0.0'"
                                   class="form-control" aria-describedby="DieselCarsSubcontractTotalKm">
                            <span class="input-group-text" id="DieselCarsSubcontractTotalKm">km</span>
                          </div>
                        </div>
                      </div>
                      <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                        <div class="col-12 text-end align-self-center">

                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-8">
                          <div class="input-group input-group-sm">
                            <input type="text"
                                   [(ngModel)]="item.PetrolConsCars"
                                   (change)="calcCo2(item)"
                                   [numbersOnly]="'10.2'"
                                   [placeholder]="'0.0'"
                                   class="form-control" aria-describedby="PetrolConsCars">
                            <span class="input-group-text" id="PetrolConsCars">L</span>
                          </div>
                        </div>
                        <div [ngClass]="getColorIndicator(item?.PetrolConsCars, impactData[i +1]?.PetrolConsCars)"
                             class="col-4 text-center">
                          <span class="form-control-sm">
                            {{ item.PetrolConsCarsCo2 ?? 0 }} t
                          </span>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-8">
                          <div class="input-group input-group-sm">
                            <input type="text"
                                   [(ngModel)]="item.PetrolCarsTotalKm"
                                   [numbersOnly]="'10.2'"
                                   [placeholder]="'0.0'"
                                   class="form-control" aria-describedby="PetrolCarsTotalKm">
                            <span class="input-group-text" id="PetrolCarsTotalKm">km</span>
                          </div>
                        </div>
                      </div>
                      <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                        <div class="col-12 text-end align-self-center">

                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-8">
                          <div class="input-group input-group-sm">
                            <input type="text"
                                   [(ngModel)]="item.LpgConsCars"
                                   (change)="calcCo2(item)"
                                   [numbersOnly]="'10.2'"
                                   [placeholder]="'0.0'"
                                   class="form-control" aria-describedby="LpgConsCars">
                            <span class="input-group-text" id="LpgConsCars">km</span>
                          </div>
                        </div>
                        <div [ngClass]="getColorIndicator(item?.LpgConsCars, impactData[i +1]?.LpgConsCars)"
                             class="col-4 text-center">
                          <span class="form-control-sm">
                            {{ item.LpgConsCarsCo2 ?? 0 }} t
                          </span>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-8">
                          <div class="input-group input-group-sm">
                            <input type="text"
                                   [(ngModel)]="item.LpgCarsTotalKm"
                                   [numbersOnly]="'10.2'"
                                   [placeholder]="'0.0'"
                                   class="form-control" aria-describedby="LpgCarsTotalKm">
                            <span class="input-group-text" id="LpgCarsTotalKm">km</span>
                          </div>
                        </div>
                      </div>
                      <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                        <div class="col-12 text-end align-self-center">

                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-8">
                          <div class="input-group input-group-sm">
                            <input type="text"
                                   [(ngModel)]="item.ElectricConsCarsGrey"
                                   (change)="calcCo2(item)"
                                   [numbersOnly]="'10.2'"
                                   [placeholder]="'0.0'"
                                   class="form-control" aria-describedby="ElectricConsCarsGrey">
                            <span class="input-group-text" id="ElectricConsCarsGrey">kWh</span>
                          </div>
                        </div>
                        <div [ngClass]="getColorIndicator(item?.ElectricConsCarsGrey, impactData[i +1]?.ElectricConsCarsGrey)"
                             class="col-4 text-center">
                          <span class="form-control-sm">
                            {{ item.ElectricConsCarsGreyCo2 ?? 0 }} t
                          </span>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-8">
                          <div class="input-group input-group-sm">
                            <input type="text"
                                   [(ngModel)]="item.ElectricCarsGreyTotalKm"
                                   [numbersOnly]="'10.2'"
                                   [placeholder]="'0.0'"
                                   class="form-control" aria-describedby="ElectricCarsGreyTotalKm">
                            <span class="input-group-text" id="ElectricCarsGreyTotalKm">km</span>
                          </div>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-8">
                          <div class="input-group input-group-sm">
                            <input type="text"
                                   [(ngModel)]="item.ElectricConsCarsGreen"
                                   (change)="calcCo2(item)"
                                   [numbersOnly]="'10.2'"
                                   [placeholder]="'0.0'"
                                   class="form-control" aria-describedby="ElectricConsCarsGreen">
                            <span class="input-group-text" id="ElectricConsCarsGreen">kWh</span>
                          </div>
                        </div>
                        <div [ngClass]="getColorIndicator(item?.ElectricConsCarsGreen, impactData[i +1]?.ElectricConsCarsGreen)"
                             class="col-4 text-center">
                          <span class="form-control-sm">
                            {{ item.ElectricConsCarsGreenCo2 ?? 0 }} t
                          </span>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-8">
                          <div class="input-group input-group-sm">
                            <input type="text"
                                   [(ngModel)]="item.ElectricCarsGreenTotalKm"
                                   [numbersOnly]="'10.2'"
                                   [placeholder]="'0.0'"
                                   class="form-control" aria-describedby="ElectricCarsGreenTotalKm">
                            <span class="input-group-text" id="ElectricCarsGreenTotalKm">km</span>
                          </div>
                        </div>
                      </div>
                      <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                        <div class="col-12 text-end align-self-center">

                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-8">
                          <input [(ngModel)]="item.LargeAverageEuroClass" [numbersOnly]="'10.0'"
                                 [placeholder]="'0'"
                                 class="form-control form-control-sm"
                                 type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-8">
                          <input [(ngModel)]="item.SmallAverageEmission" [numbersOnly]="'10.0'"
                                 [placeholder]="'0'"
                                 class="form-control form-control-sm"
                                 type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-8">
                          <div class="input-group input-group-sm">
                            <input type="text"
                                   [(ngModel)]="item.AirTravelKm"
                                   (change)="calcCo2(item)"
                                   [numbersOnly]="'10.2'"
                                   [placeholder]="'0.0'"
                                   class="form-control" aria-describedby="AirTravelKm">
                            <span class="input-group-text" id="AirTravelKm">km</span>
                          </div>
                        </div>
                        <div [ngClass]="getColorIndicator(item?.AirTravelKm, impactData[i +1]?.AirTravelKm)"
                             class="col-4 text-center">
                          <span class="form-control-sm">
                            {{ item.AirTravelKmCo2 ?? 0 }} t
                          </span>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-8">
                          <div class="input-group input-group-sm">
                            <input type="text"
                                   [(ngModel)]="item.AirTravelCarbonDioxide"
                                   [numbersOnly]="'10.2'"
                                   [placeholder]="'0.0'"
                                   class="form-control" aria-describedby="AirTravelCarbonDioxide">
                            <span class="input-group-text" id="AirTravelCarbonDioxide">kg</span>
                          </div>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-8">
                          <div class="input-group input-group-sm">
                            <input type="text"
                                   [(ngModel)]="item.RailTravel"
                                   [numbersOnly]="'10.2'"
                                   [placeholder]="'0.0'"
                                   class="form-control" aria-describedby="RailTravel">
                            <span class="input-group-text" id="RailTravel">km</span>
                          </div>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                           <textarea [(ngModel)]="item.TravelComment" [placeholder]="'Add a comment'"
                                     class="form-control form-control-sm"
                                     id="travel-comment" name="travel-comment"
                                     rows="3"></textarea>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12 align-self-center text-end">
                          <img src="../../../../assets/loaders/tail-spin.svg" class="me-2" *ngIf="item.loading == true" width="20" alt="" />
                          <button (click)="upsert(item)" *ngIf="canEdit"
                                  [disabled]="loading"
                                  class="btn btn-sm btn-primary harmony-tertiary-outline-button me-1"
                                  type="button">
                            Save
                          </button>
                          <button (click)="delete(item.Id, item.IsNew)"
                                  *ngIf="canDelete" [disabled]="loading"
                                  [title]="'Delete'" class="btn btn-sm btn-outline-danger">
                            <fa-icon [icon]="faDelete"></fa-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- Home/Work Travel -->
  <ng-container>
    <div class="row">
      <div class="col-12">
        <div class="card my-1">
          <div class="card-header p-0" [attr.id]="'heading-home'">
            <div class="row pointer" (click)="homeBtn.click(); toggleTab('HO');">
              <div class="col-10 text-start">
                <h3 class="mb-0">
                  <button class="btn chapter-header mb-0 pt-2 fw-bold" #homeBtn
                          type="button"
                          (click)="$event.stopPropagation()"
                          data-bs-toggle="collapse"
                          [attr.data-bs-target]="'#collapse-home'"
                          [attr.aria-expanded]="activeTab === 'HO'"
                          [attr.aria-controls]="'collapse-home'">
                    Home/Work Travel
                  </button>
                </h3>
              </div>
              <div class="col-2 text-center align-middle">
                <div class="mb-0 pt-2 fs-6">

                </div>
              </div>
            </div>
          </div>
          <div [attr.id]="'collapse-home'"
               class="collapse"
               [ngClass]="{'show': activeTab === 'HO'}"
               [attr.aria-labelledby]="'heading-home'"
               data-bs-parent="#accordion-impact-data">
            <div class="card-body">
              <div class="row">
                <div class="col-2">
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Year
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'The year the data is relevant to'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Total Employees Walk/Cycle
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'Total employees that commute to the workplace via walking or cycling per year'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Total Employees Car or Scooter
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'Total employees that commute to the workplace via car or scooter per year'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Total Employees Public Transport
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'Total employees that commute to the workplace via public transport per year'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Total Flexible/Home Employees
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'Total employees that work from home partially or completely'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Comment
                      </small>
                    </div>
                    <div class="col-2 align-self-center">

                    </div>
                  </div>
                </div>
                <div class="col-10">
                  <div class="horizontal-scrollable d-inline-flex">
                    <div *ngFor='let item of impactData; let i = index' class="float-start horizontal-result">
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <span class="form-control-sm fw-bold horizontal-header-sm">
                            {{ item.Year }}
                          </span>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <input [(ngModel)]="item.EmployeesWalkOrCycle" [numbersOnly]="'10.0'"
                                 [placeholder]="'0'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <input [(ngModel)]="item.EmployeesCarOrScooter" [numbersOnly]="'10.0'"
                                 [placeholder]="'0'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <input [(ngModel)]="item.EmployeesPublicTransport" [numbersOnly]="'10.0'"
                                 [placeholder]="'0'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <input [(ngModel)]="item.EmployeesFlexibleHomeWorker" [numbersOnly]="'10.0'"
                                 [placeholder]="'0'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <textarea [(ngModel)]="item.EmployeeTravelComment" [placeholder]="'Add a comment'"
                                    class="form-control form-control-sm"
                                    id="employee-travel-comment" name="employee-travel-comment" rows="3"></textarea>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12 align-self-center text-end">
                          <img src="../../../../assets/loaders/tail-spin.svg" class="me-2" *ngIf="item.loading == true" width="20" alt="" />
                          <button (click)="upsert(item)" *ngIf="canEdit"
                                  [disabled]="loading"
                                  class="btn btn-sm btn-primary harmony-tertiary-outline-button me-1"
                                  type="button">
                            Save
                          </button>
                          <button (click)="delete(item.Id, item.IsNew)"
                                  *ngIf="canDelete" [disabled]="loading"
                                  [title]="'Delete'" class="btn btn-sm btn-outline-danger">
                            <fa-icon [icon]="faDelete"></fa-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- Waste -->
  <ng-container>
    <div class="row">
      <div class="col-12">
        <div class="card my-1">
          <div class="card-header p-0" [attr.id]="'heading-waste'">
            <div class="row pointer" (click)="wasteBtn.click(); toggleTab('WS');">
              <div class="col-10 text-start">
                <h3 class="mb-0">
                  <button class="btn chapter-header mb-0 pt-2 fw-bold" #wasteBtn
                          type="button"
                          (click)="$event.stopPropagation()"
                          data-bs-toggle="collapse"
                          [attr.data-bs-target]="'#collapse-waste'"
                          [attr.aria-expanded]="activeTab === 'WS'"
                          [attr.aria-controls]="'collapse-waste'">
                    Waste
                  </button>
                </h3>
              </div>
              <div class="col-2 text-center align-middle">
                <div class="mb-0 pt-2 fs-6">

                </div>
              </div>
            </div>
          </div>
          <div [attr.id]="'collapse-waste'"
               class="collapse"
               [ngClass]="{'show': activeTab === 'WS'}"
               [attr.aria-labelledby]="'heading-waste'"
               data-bs-parent="#accordion-impact-data">
            <div class="card-body">
              <div class="row">
                <div class="col-2">
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Year
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'The year the data is relevant to'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Customer Waste Collected
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'Total kilograms of waste collected from customers per year'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Customer Paper/Cardboard Waste
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'Total kilograms paper or cardboard waste collected from customers per year'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Paper/Cardboard Recycled
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'Total kilograms of paper or cardboard waste that was recycled for customers per year'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Company Waste
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'Total kilograms of waste collected from your company per year'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Company Paper/Cardboard Waste
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'Total kilograms of paper or cardboard waste collected from your company per year'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Company Paper/Cardboard Recycled
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'Total kilograms of paper or cardboard waste that was recycled for your company per year'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Comment
                      </small>
                    </div>
                    <div class="col-2 align-self-center">

                    </div>
                  </div>
                </div>
                <div class="col-10">
                  <div class="horizontal-scrollable d-inline-flex">
                    <div *ngFor='let item of impactData; let i = index' class="float-start horizontal-result">
                      <div class="row me-1 mb-1">
                        <div class="col-8">
                          <span class="form-control-sm fw-bold horizontal-header-sm">
                            {{ item.Year }}
                          </span>
                        </div>
                        <div class="col-4 text-center">
                          <span class="form-control-sm fw-bold">
                            CO²
                          </span>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-8">
                          <div class="input-group input-group-sm">
                            <input type="text"
                                   [(ngModel)]="item.CustomerWasteCollected"
                                   [numbersOnly]="'10.2'"
                                   [placeholder]="'0.0'"
                                   class="form-control" aria-describedby="CustomerWasteCollected">
                            <span class="input-group-text" id="CustomerWasteCollected">kg</span>
                          </div>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-8">
                          <div class="input-group input-group-sm">
                            <input type="text"
                                   [(ngModel)]="item.CustomerPaperWasteCollected"
                                   [numbersOnly]="'10.2'"
                                   [placeholder]="'0.0'"
                                   class="form-control" aria-describedby="CustomerPaperWasteCollected">
                            <span class="input-group-text" id="CustomerPaperWasteCollected">kg</span>
                          </div>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-8">
                          <div class="input-group input-group-sm">
                            <input type="text"
                                   [(ngModel)]="item.CustomerPaperWasteRecycled"
                                   [numbersOnly]="'10.2'"
                                   [placeholder]="'0.0'"
                                   class="form-control" aria-describedby="CustomerPaperWasteRecycled">
                            <span class="input-group-text" id="CustomerPaperWasteRecycled">kg</span>
                          </div>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-8">
                          <div class="input-group input-group-sm">
                            <input type="text"
                                   [(ngModel)]="item.CompanyWaste"
                                   [numbersOnly]="'10.2'"
                                   [placeholder]="'0.0'"
                                   class="form-control" aria-describedby="CompanyWaste">
                            <span class="input-group-text" id="CompanyWaste">kg</span>
                          </div>
                        </div>
                        <div [ngClass]="getColorIndicator(item?.CompanyWaste, impactData[i +1]?.CompanyWaste)"
                             class="col-4 text-center">
                          <span class="form-control-sm">
                            {{ item.CompanyWasteCo2 ?? 0 }} t
                          </span>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-8">
                          <div class="input-group input-group-sm">
                            <input type="text"
                                   [(ngModel)]="item.CompanyPaperWaste"
                                   [numbersOnly]="'10.2'"
                                   [placeholder]="'0.0'"
                                   class="form-control" aria-describedby="CompanyPaperWaste">
                            <span class="input-group-text" id="CompanyPaperWaste">kg</span>
                          </div>
                        </div>
                        <div [ngClass]="getColorIndicator(item?.CompanyPaperWaste, impactData[i +1]?.CompanyPaperWaste)"
                             class="col-4 text-center">
                          <span class="form-control-sm">
                            {{ item.CompanyPaperWasteCo2 ?? 0 }} t
                          </span>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-8">
                          <div class="input-group input-group-sm">
                            <input type="text"
                                   [(ngModel)]="item.CompanyPaperWasteRecycled"
                                   [numbersOnly]="'10.2'"
                                   [placeholder]="'0.0'"
                                   class="form-control" aria-describedby="CompanyPaperWasteRecycled">
                            <span class="input-group-text" id="CompanyPaperWasteRecycled">kg</span>
                          </div>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <textarea [(ngModel)]="item.WasteComment" [placeholder]="'Add a comment'"
                                    class="form-control form-control-sm"
                                    id="waste-comment" name="waste-comment" rows="3"></textarea>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12 align-self-center text-end">
                          <img src="../../../../assets/loaders/tail-spin.svg" class="me-2" *ngIf="item.loading == true" width="20" alt="" />
                          <button (click)="upsert(item)" *ngIf="canEdit"
                                  [disabled]="loading"
                                  class="btn btn-sm btn-primary harmony-tertiary-outline-button me-1"
                                  type="button">
                            Save
                          </button>
                          <button (click)="delete(item.Id, item.IsNew)"
                                  *ngIf="canDelete" [disabled]="loading"
                                  [title]="'Delete'" class="btn btn-sm btn-outline-danger">
                            <fa-icon [icon]="faDelete"></fa-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- Procurements -->
  <ng-container>
    <div class="row">
      <div class="col-12">
        <div class="card my-1">
          <div class="card-header p-0" [attr.id]="'heading-procurements'">
            <div class="row pointer" (click)="procurementsBtn.click(); toggleTab('PR');">
              <div class="col-10 text-start">
                <h3 class="mb-0">
                  <button class="btn chapter-header collapsed mb-0 pt-2 fw-bold" #procurementsBtn
                          type="button"
                          (click)="$event.stopPropagation()"
                          data-bs-toggle="collapse"
                          [attr.data-bs-target]="'#collapse-procurements'"
                          [attr.aria-expanded]="activeTab === 'PR'"
                          [attr.aria-controls]="'collapse-procurements'">
                    Procurements
                  </button>
                </h3>
              </div>
              <div class="col-2 text-center align-middle">
                <div class="mb-0 pt-2 fs-6">

                </div>
              </div>
            </div>
          </div>
          <div [attr.id]="'collapse-procurements'"
               class="collapse"
               [ngClass]="{'show': activeTab === 'PR'}"
               [attr.aria-labelledby]="'heading-procurements'"
               data-bs-parent="#accordion-impact-data">
            <div class="card-body">
              <div class="row">
                <div class="col-2">
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Year
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'The year the data is relevant to'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Single Packaging Cardboard A
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'Total kilograms of single packaging made of cardboard type A. Type A is packing materials made of entirely recycled materials or for which a GMP certificate can be provided'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Single Packaging Cardboard B
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'Total kilograms of single packaging made of cardboard type B. Type B is packing materials made out of some or non-recyclable materials'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Single Use Plastic
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'Total kilograms of single use plastic used per year'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Office Paper Use
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'Total kilograms of paper used within the office per year'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Print Matter
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'Total kilograms of paper that has been printed per year'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Comment
                      </small>
                    </div>
                    <div class="col-2 align-self-center">

                    </div>
                  </div>
                </div>
                <div class="col-10">
                  <div class="horizontal-scrollable d-inline-flex">
                    <div *ngFor='let item of impactData; let i = index' class="float-start horizontal-result">
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <span class="form-control-sm fw-bold horizontal-header-sm">
                            {{ item.Year }}
                          </span>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <div class="input-group input-group-sm">
                            <input type="text"
                                   [(ngModel)]="item.SingleUsePackagingCardboardA"
                                   [numbersOnly]="'10.2'"
                                   [placeholder]="'0.0'"
                                   class="form-control" aria-describedby="SingleUsePackagingCardboardA">
                            <span class="input-group-text" id="SingleUsePackagingCardboardA">kg</span>
                          </div>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <div class="input-group input-group-sm">
                            <input type="text"
                                   [(ngModel)]="item.SingleUsePackagingCardboardB"
                                   [numbersOnly]="'10.2'"
                                   [placeholder]="'0.0'"
                                   class="form-control" aria-describedby="SingleUsePackagingCardboardB">
                            <span class="input-group-text" id="SingleUsePackagingCardboardB">kg</span>
                          </div>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <div class="input-group input-group-sm">
                            <input type="text"
                                   [(ngModel)]="item.SingleUsePackagingPlastic"
                                   [numbersOnly]="'10.2'"
                                   [placeholder]="'0.0'"
                                   class="form-control" aria-describedby="SingleUsePackagingPlastic">
                            <span class="input-group-text" id="SingleUsePackagingPlastic">kg</span>
                          </div>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <div class="input-group input-group-sm">
                            <input type="text"
                                   [(ngModel)]="item.OfficePaperUse"
                                   [numbersOnly]="'10.2'"
                                   [placeholder]="'0.0'"
                                   class="form-control" aria-describedby="OfficePaperUse">
                            <span class="input-group-text" id="OfficePaperUse">kg</span>
                          </div>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <div class="input-group input-group-sm">
                            <input type="text"
                                   [(ngModel)]="item.PrintMatter"
                                   [numbersOnly]="'10.2'"
                                   [placeholder]="'0.0'"
                                   class="form-control" aria-describedby="PrintMatter">
                            <span class="input-group-text" id="PrintMatter">kg</span>
                          </div>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <textarea [(ngModel)]="item.ProcurementsComment" [placeholder]="'Add a comment'"
                                    class="form-control form-control-sm"
                                    id="procurement-comment" name="procurement-comment" rows="3"></textarea>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12 align-self-center text-end">
                          <img src="../../../../assets/loaders/tail-spin.svg" class="me-2" *ngIf="item.loading == true" width="20" alt="" />
                          <button (click)="upsert(item)" *ngIf="canEdit"
                                  [disabled]="loading"
                                  class="btn btn-sm btn-primary harmony-tertiary-outline-button me-1"
                                  type="button">
                            Save
                          </button>
                          <button (click)="delete(item.Id, item.IsNew)"
                                  *ngIf="canDelete" [disabled]="loading"
                                  [title]="'Delete'" class="btn btn-sm btn-outline-danger">
                            <fa-icon [icon]="faDelete"></fa-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- Planning and Control -->
  <ng-container>
    <div class="row">
      <div class="col-12">
        <div class="card my-1">
          <div class="card-header p-0" [attr.id]="'heading-planning'">
            <div class="row pointer" (click)="planningBtn.click(); toggleTab('PL');">
              <div class="col-10 text-start">
                <h3 class="mb-0">
                  <button class="btn chapter-header collapsed mb-0 pt-2 fw-bold" #planningBtn
                          type="button"
                          (click)="$event.stopPropagation()"
                          data-bs-toggle="collapse"
                          [attr.data-bs-target]="'#collapse-planning'"
                          [attr.aria-expanded]="activeTab === 'PL'"
                          [attr.aria-controls]="'collapse-planning'">
                    Planning and Control
                  </button>
                </h3>
              </div>
              <div class="col-2 text-center align-middle">
                <div class="mb-0 pt-2 fs-6">

                </div>
              </div>
            </div>
          </div>
          <div [attr.id]="'collapse-planning'"
               class="collapse"
               [ngClass]="{'show': activeTab === 'PL'}"
               [attr.aria-labelledby]="'heading-planning'"
               data-bs-parent="#accordion-impact-data">
            <div class="card-body">
              <div class="row">
                <div class="col-2">
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Year
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'The year the data is relevant to'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Environmental Audits Held
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'Number of environment audits held'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Work Place Inspections Held
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'Number of work place inspections held'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Comment
                      </small>
                    </div>
                    <div class="col-2 align-self-center">

                    </div>
                  </div>
                </div>
                <div class="col-10">
                  <div class="horizontal-scrollable d-inline-flex">
                    <div *ngFor='let item of impactData; let i = index' class="float-start horizontal-result">
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <span class="form-control-sm fw-bold horizontal-header-sm">
                            {{ item.Year }}
                          </span>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <input [(ngModel)]="item.EnvironmentalAuditsHeld" [numbersOnly]="'10.0'"
                                 [placeholder]="'0'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <input [(ngModel)]="item.WorkPlaceInspectionsHeld" [numbersOnly]="'10.0'"
                                 [placeholder]="'0'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <textarea [(ngModel)]="item.PlanningComment" [placeholder]="'Add a comment'"
                                    class="form-control form-control-sm"
                                    id="planning-comment" name="planning-comment" rows="3"></textarea>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12 align-self-center text-end">
                          <img src="../../../../assets/loaders/tail-spin.svg" class="me-2" *ngIf="item.loading == true" width="20" alt="" />
                          <button (click)="upsert(item)" *ngIf="canEdit"
                                  [disabled]="loading"
                                  class="btn btn-sm btn-primary harmony-tertiary-outline-button me-1"
                                  type="button">
                            Save
                          </button>
                          <button (click)="delete(item.Id, item.IsNew)"
                                  *ngIf="canDelete" [disabled]="loading"
                                  [title]="'Delete'" class="btn btn-sm btn-outline-danger">
                            <fa-icon [icon]="faDelete"></fa-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- Compensation -->
  <ng-container>
    <div class="row">
      <div class="col-12">
        <div class="card my-1">
          <div class="card-header p-0" [attr.id]="'heading-compensation'">
            <div class="row pointer" (click)="compensationBtn.click(); toggleTab('CO');">
              <div class="col-10 text-start">
                <h3 class="mb-0">
                  <button class="btn chapter-header collapsed mb-0 pt-2 fw-bold" #compensationBtn
                          type="button"
                          (click)="$event.stopPropagation()"
                          data-bs-toggle="collapse"
                          [attr.data-bs-target]="'#collapse-compensation'"
                          [attr.aria-expanded]="activeTab === 'CO'"
                          [attr.aria-controls]="'collapse-compensation'">
                    Compensation
                  </button>
                </h3>
              </div>
              <div class="col-2 text-center align-middle">
                <div class="mb-0 pt-2 fs-6">

                </div>
              </div>
            </div>
          </div>
          <div [attr.id]="'collapse-compensation'"
               class="collapse"
               [ngClass]="{'show': activeTab === 'CO'}"
               [attr.aria-labelledby]="'heading-compensation'"
               data-bs-parent="#accordion-impact-data">
            <div class="card-body">
              <div class="row">
                <div class="col-2">
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Year
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'The year the data is relevant to'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        CO² Compensated Removals
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'Number of removals carried out for which CO² emissions were compensated (e.g. EcoLegit)'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        CO² Compensations
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'Total kilograms of CO² that was compensated'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        CO² Compensations Euro
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'CO² that was compensated for in euro currency'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Comment
                      </small>
                    </div>
                    <div class="col-2 align-self-center">

                    </div>
                  </div>
                </div>
                <div class="col-10">
                  <div class="horizontal-scrollable d-inline-flex">
                    <div *ngFor='let item of impactData; let i = index' class="float-start horizontal-result">
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <span class="form-control-sm fw-bold horizontal-header-sm">
                            {{ item.Year }}
                          </span>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <input [(ngModel)]="item.CarbonDioxideRemovals"
                                 [numbersOnly]="'10.0'"
                                 [placeholder]="'0'"
                                 class="form-control form-control-sm" type="text">
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <div class="input-group input-group-sm">
                            <input type="text"
                                   [(ngModel)]="item.CarbonDioxideCompensation"
                                   (change)="calcCo2(item)"
                                   [numbersOnly]="'10.2'"
                                   [placeholder]="'0.0'"
                                   class="form-control" aria-describedby="CarbonDioxideCompensation">
                            <span class="input-group-text" id="CarbonDioxideCompensation">kg</span>
                          </div>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <div class="input-group input-group-sm">
                            <input type="text"
                                   [(ngModel)]="item.CarbonDioxideCompensationEuro"
                                   [numbersOnly]="'10.2'"
                                   [placeholder]="'0.0'"
                                   class="form-control" aria-describedby="CarbonDioxideCompensationEuro">
                            <span class="input-group-text" id="CarbonDioxideCompensationEuro">EUR</span>
                          </div>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <textarea [(ngModel)]="item.CompensationComment" [placeholder]="'Add a comment'"
                                    class="form-control form-control-sm"
                                    id="compensation-comment" name="compensation-comment" rows="3"></textarea>
                        </div>
                      </div>
                      <div class="row me-1 mb-1">
                        <div class="col-12 align-self-center text-end">
                          <img src="../../../../assets/loaders/tail-spin.svg" class="me-2" *ngIf="item.loading == true" width="20" alt="" />
                          <button (click)="upsert(item)" *ngIf="canEdit"
                                  [disabled]="loading"
                                  class="btn btn-sm btn-primary harmony-tertiary-outline-button me-1"
                                  type="button">
                            Save
                          </button>
                          <button (click)="delete(item.Id, item.IsNew)"
                                  *ngIf="canDelete" [disabled]="loading"
                                  [title]="'Delete'" class="btn btn-sm btn-outline-danger">
                            <fa-icon [icon]="faDelete"></fa-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- Balance -->
  <ng-container>
    <div class="row">
      <div class="col-12">
        <div class="card my-1">
          <div class="card-header p-0" [attr.id]="'heading-balance'">
            <div class="row pointer" (click)="balanceBtn.click(); toggleTab('BL');">
              <div class="col-10 text-start">
                <h3 class="mb-0">
                  <button class="btn chapter-header collapsed mb-0 pt-2 fw-bold" #balanceBtn
                          type="button"
                          (click)="$event.stopPropagation()"
                          data-bs-toggle="collapse"
                          [attr.data-bs-target]="'#collapse-balance'"
                          [attr.aria-expanded]="activeTab === 'BL'"
                          [attr.aria-controls]="'collapse-balance'">
                    Balance
                  </button>
                </h3>
              </div>
              <div class="col-2 text-center align-middle">
                <div class="mb-0 pt-2 fs-6">

                </div>
              </div>
            </div>
          </div>
          <div [attr.id]="'collapse-balance'"
               class="collapse"
               [ngClass]="{'show': activeTab === 'BL'}"
               [attr.aria-labelledby]="'heading-balance'"
               data-bs-parent="#accordion-impact-data">
            <div class="card-body">
              <div class="row">
                <div class="col-2">
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        Year
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'The year the data is relevant to'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        CO² Emission
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'Total tons of carbon dioxide emission produced per year'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        CO² Compensation
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'Total tons of carbon dioxide emission compensated for per year'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                  <div class="row" style="height: 31px; margin-bottom: 0.25rem;">
                    <div class="col-10 text-end align-self-center overflow-hidden text-nowrap" style="text-overflow: ellipsis">
                      <small>
                        CO² Net Emission
                      </small>
                    </div>
                    <div class="col-2 align-self-center">
                      <fa-icon [icon]="faInfo" [title]="'The remaining carbon dioxide produced in ton per year. The total emission minus what was compensated for'"
                               class="harmony-grey-text detail-info">
                      </fa-icon>
                    </div>
                  </div>
                </div>
                <div class="col-10">
                  <div class="horizontal-scrollable d-inline-flex">
                    <div *ngFor='let item of impactData; let i = index' class="float-start horizontal-result">
                      <div class="row me-1 mb-1">
                        <div class="col-12">
                          <span class="form-control-sm fw-bold horizontal-header-sm">{{ item.Year }}</span>
                        </div>
                      </div>
                      <div class="row me-1 mb-1" style="height: 31px; margin-bottom: 0.25em !important;">
                        <div class="col-12 align-self-center">
                          <small class="ms-2 harmony-secondary-text">
                            <strong>{{ item.TotalCarbonDioxideEmission ?? 0 | number:'1.1-2' }}</strong> tons
                          </small>
                        </div>
                      </div>
                      <div class="row me-1 mb-1" style="height: 31px; margin-bottom: 0.25em !important;">
                        <div class="col-12 align-self-center">
                          <small class="ms-2 harmony-secondary-text">
                            <strong>{{ ((item.TotalCarbonDioxideCompensation ?? 0) / 1000) | number:'1.1-2' }}</strong> tons
                          </small>
                        </div>
                      </div>
                      <div class="row me-1 mb-1" style="height: 31px; margin-bottom: 0.25em !important;">
                        <div class="col-12 align-self-center">
                          <small class="ms-2 harmony-secondary-text">
                            <strong>{{ item.NetCarbonDioxideEmission ?? 0 | number:'1.1-2' }}</strong> tons
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
