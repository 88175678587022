<div class="modal-header" xmlns="http://www.w3.org/1999/html">
  <h4 class="modal-title">
    {{ cityFunction && CityFunctionId ? 'Edit City Function: ' + cityFunction.Description : 'Create New City Function' }}
  </h4>
</div>
<div class="modal-body">
  <ng-container *ngIf="cityFunction && !loading">
    <div class="row">
      <div class="col-4">
        <b>Description</b>
      </div>
      <div class="col-8">
        <input type="text"
               class="form-control form-control-sm"
               [ngClass]="{'is-invalid': !cityFunction.Description || cityFunction.Description.length <= 0}"
               [(ngModel)]="cityFunction.Description">
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-4">
        <b>Code</b>
      </div>
      <div class="col-8">
        <input type="text"
               class="form-control form-control-sm"
               [ngClass]="{'is-invalid': !cityFunction.Code || cityFunction.Code.length <= 0}"
               [(ngModel)]="cityFunction.Code">
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-4">
        <b>Function</b>
      </div>
      <div class="col-8">
        <select class="form-select form-select-sm pointer"
                [ngClass]="{'is-invalid': !cityFunction.FunctionTypeId || cityFunction.FunctionTypeId <= 0}"
                [(ngModel)]="cityFunction.FunctionTypeId">
          <option [ngValue]="null">None...</option>
          <option *ngFor="let functionType of FunctionTypes" [ngValue]="functionType.Id">{{ functionType.Description }}</option>
        </select>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-4">
        <b>City</b>
      </div>
      <div class="col-8">
        <app-city-search [Identifier]="'StateID'"
                         [ShowError]="cityFunction.CityId == null"
                         [SelectedID]="cityFunction.CityId"
                         (OnSelect)="cityFunction.CityId = $event">
        </app-city-search>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!cityFunction && loading">
    <app-loader *ngIf="loading" class="ms-2" [Width]="40"></app-loader>
  </ng-container>
</div>
<div class="modal-footer">
  <app-loader *ngIf="loading" class="ms-2" [Width]="20"></app-loader>
  <button type="button" class="btn btn-primary harmony-tertiary-button" (click)="save()">Save</button>
  <button type="button" class="btn btn-primary harmony-primary-button" (click)="cancel()">Close</button>
</div>
