<div class="modal-header">
  <h4 class="modal-title overflow-hidden">
    {{ DocumentId ? ('Edit Document: ' + DocumentId) : 'New Document' }}
  </h4>
  <button type="button" class="btn-close" aria-label="Close" [disabled]="loading" (click)="close()"></button>
</div>
<div class="modal-body" *ngIf="document">
  <div class="row mt-1">
    <div class="col-3">
      <b>Category</b>
    </div>
    <div class="col-9">
      <select class="form-select form-select-sm pointer"
              [ngClass]="{'is-invalid': (document.DocumentCategoryId == null && invalid)}"
              [disabled]="!(documentCategories$ | async)"
              [(ngModel)]="document.DocumentCategoryId">
        <option [ngValue]="null" selected>Select a category...</option>
        <option *ngFor="let category of (documentCategories$ | async)" [ngValue]="category.Id">{{ category.Name }}</option>
      </select>
    </div>
  </div>
  <div class="row mt-1">
    <div class="col-3">
      <b>Display Name</b>
    </div>
    <div class="col-9">
      <input type="text"
             class="form-control form-control-sm"
             [ngClass]="{'is-invalid': (document.DisplayName?.length < 3 && invalid)}"
             [minlength]="3"
             [(ngModel)]="document.DisplayName"
             [placeholder]="'Provide a document name, e.g. \'ISO 21007 Certificate\''">
    </div>
  </div>
  <div class="row mt-1">
    <div class="col-3">
      <b>Company</b>&nbsp;<small class="harmony-grey-text">(optional)</small>
    </div>
    <div class="col-9">
      <app-company-search class="d-inline"
                          [Identifier]="'DocumentCompanyID'"
                          [MembersOnly]="true"
                          [SelectedID]="document.CompanyId"
                          (OnSelect)="setCompanyId($event)">
      </app-company-search>
    </div>
  </div>
  <div class="row mt-1">
    <div class="col-3">
      <b>User</b>&nbsp;<small class="harmony-grey-text">(optional)</small>
    </div>
    <div class="col-9">
      <app-user-search class="d-inline"
                        [Identifier]="'DocumentCompanyID'"
                        [SelectedID]="document.DocumentUserId"
                        (OnSelect)="setUserId($event)">
      </app-user-search>
    </div>
  </div>
  <div class="row mt-1">
    <div class="col-3">
      <b>Description</b>
    </div>
    <div class="col-9">
      <textarea class="form-control form-control-sm"
                [rows]="3"
                [maxlength]="1024"
                [placeholder]="'Provide a description for this document describing its purpose.'"
                [ngClass]="{'is-invalid': (document.Description?.length < 3 && invalid)}"
                [(ngModel)]="document.Description">
      </textarea>
      <small class="harmony-grey-text">{{document.Description?.length ?? 0}}/1024</small>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-3 text-center">
      <button class="btn btn-primary btn-sm harmony-secondary-button w-100"
              type="button"
              (click)="fileUploader.click()">
        Select file
      </button>
      <input [hidden]="true" #fileUploader (change)="setFile($event)" type="file" accept="*/*">
    </div>
    <div class="col-9 align-self-center">
      <div class="row">
        <div class="col-10">
          {{ document.FileName ?? '-' }}
        </div>
        <div class="col-2 fw-bold text-end">
          <small>{{ fileSize ?? 0 | number:'1.1-2' }}mb</small>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="documentData">
    <hr>
    <div class="row mt-2">
      <div class="col-12">
        <small>Created on {{ documentData.DateCreated | date: env.FormattingStandards.LongDateFormat }} by {{ documentData.CreatedByUserName }}</small>
      </div>
    </div>
    <div class="row mt-2" *ngIf="documentData?.DateModified">
      <div class="col-12">
        <small>Last updated on {{ documentData.DateModified | date: env.FormattingStandards.LongDateFormat }} by {{ documentData.ModifiedByUserName }}</small>
      </div>
    </div>
  </ng-container>
</div>
<div class="modal-footer">
  <app-loader *ngIf="loading" class="ms-2" [Width]="20"></app-loader>
  <button type="button" class="btn btn-primary harmony-tertiary-button" *ngIf="!DocumentId" [disabled]="loading" (click)="create()">Create</button>
  <button type="button" class="btn btn-primary harmony-tertiary-button" *ngIf="DocumentId" [disabled]="loading" (click)="update()">Update</button>
  <button type="button" class="btn btn-primary harmony-primary-button" [disabled]="loading" (click)="close()">Close</button>
</div>
