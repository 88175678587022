<!---- Header ---->
<div class="modal-header">
  <h4 class="modal-title" *ngIf="isNew">
    Add New Question
  </h4>
  <h4 class="modal-title" *ngIf="!isNew">
    Editing Question: <b>{{ question.Id }}</b>
  </h4>
</div>
<!---- Add/Edit Question---->
<form [formGroup]="questionForm" *ngIf="questionForm">
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <div class="row mt-1">
          <div class="col-3 fw-bold">
            Review <span class="text-danger">*</span>
          </div>
          <div class="col-9">
            <select class="form-select form-select-sm"
                    [formControlName]="'reviewId'"
                    [ngClass]="{'is-invalid': questionForm.controls.reviewId.touched && questionForm.controls.reviewId.invalid}"
                    (selectionchange)="getChapters()">
              <option [ngValue]="null" disabled selected>Select a review year</option>
              <option *ngFor="let r of reviewSearchFilter" [value]="r.Id">
                {{ r.ReviewYear }}
              </option>
            </select>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-3 fw-bold">
            Chapter <span class="text-danger">*</span>
          </div>
          <div class="col-9">
            <select [formControlName]="'chapterId'"
                    [ngClass]="{'is-invalid': questionForm.controls.chapterId.touched && questionForm.controls.chapterId.invalid}"
                    class="form-select form-select-sm input-picker-value">
              <option [ngValue]="null" disabled>Select a chapter</option>
              <option *ngFor="let c of chapters" [value]="c.Id">
                {{ c.Name }}
              </option>
            </select>
            <button *ngIf="isNew"
                    class="btn btn-sm btn-primary harmony-primary-outline-button float-end input-picker-button"
                    [title]="'Add chapter'" (click)="openChapterModal(true)">
              <fa-icon [icon]="faAdd"></fa-icon>
            </button>
            <button *ngIf="!isNew"
                    class="btn btn-sm btn-primary harmony-primary-outline-button float-end input-picker-button"
                    [title]="'Edit chapter'" (click)="openChapterModal(false)">
              <fa-icon [icon]="faEdit"></fa-icon>
            </button>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-3 fw-bold">
            Order
          </div>
          <div class="col-9">
            <input [formControlName]="'order'"
                   [ngClass]="{'is-invalid': questionForm.controls.order.touched && questionForm.controls.order.invalid}"
                   type="text"
                   numbersOnly="3.0"
                   class="form-control form-control-sm"
                   placeholder="Optional question order number">
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-3 fw-bold">
            Label <span class="text-danger">*</span>
          </div>
          <div class="col-9">
            <input [formControlName]="'label'"
                   [ngClass]="{'is-invalid': questionForm.controls.label.touched && questionForm.controls.label.invalid}"
                   class="form-control form-control-sm"
                   type="text"
                   placeholder="Number/label">
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-3 fw-bold">
            Question <span class="text-danger">*</span>
          </div>
          <div class="col-9">
            <textarea [formControlName]="'value'"
                      [ngClass]="{'is-invalid': questionForm.controls.value.touched && questionForm.controls.value.invalid}"
                      appAutocompleteOff
                      rows="4"
                      class="form-control form-control-sm"
                      type="text"
                      placeholder="Enter a question"></textarea>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-3 fw-bold">
            Requirement
          </div>
          <div class="col-9">
          <textarea [formControlName]="'requirement'"
                    appAutocompleteOff
                    rows="3"
                    class="form-control form-control-sm"
                    type="text"
                    placeholder="Enter a optional requirement"></textarea>
          </div>
        </div>
        <hr>
        <div class="row mt-1">
          <div class="col-3 fw-bold">
            Mandatory
          </div>
          <div class="col-9 text-start">
            <div class="form-check form-switch form-switch-sm float-start pointer">
              <input class="form-check-input pointer"
                     type="checkbox"
                     role="switch"
                     [id]="'mandatory'"
                     [checked]="question.Mandatory"
                     [title]="(question.Mandatory == false ? 'Mark question as mandatory' : 'Mark question as optional')"
                     (change)="questionForm.controls.mandatory.setValue($event.target['checked'])">
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-3 fw-bold">
            Member Type
          </div>
          <div class="col-9">
            <select [formControlName]="'companyType'"
                    [ngClass]="{'is-invalid': questionForm.controls.companyType.touched && questionForm.controls.companyType.invalid}"
                    required name="company-type" id="company-type" class="form-select form-select-sm">
              <option disabled selected>Select a which member type the question applies to</option>
              <option [ngValue]="1">All</option>
              <option [ngValue]="2">DSP</option>
              <option [ngValue]="3">Removals</option>
            </select>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-3 fw-bold">
            <label for="question-type">
              Question Type
            </label>
          </div>
          <div class="col-9">
            <select [formControlName]="'type'"
                    [ngClass]="{'is-invalid': questionForm.controls.type.touched && questionForm.controls.type.invalid}"
                    required name="question-type"
                    id="question-type"
                    class="form-select form-select-sm"
                    (change)="setQuestionType($event)">
              <option disabled selected>Select a question type</option>
              <option [ngValue]="1">Text</option>
              <option [ngValue]="2">Yes No</option>
              <option [ngValue]="3">Yes No NA</option>
              <option [ngValue]="4">Number</option>
              <option [ngValue]="5">Date</option>
              <option [ngValue]="6">Yes No First</option>
              <option [ngValue]="7">Multiple Choice</option>
            </select>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12">
            <ng-container *ngIf="question.Type == 7 || questionForm.controls.type.value == 7">
              <div class="row mt-1 mb-2">
                <div class="col-12">
                  <div class="row mb-1">
                    <div class="col-2 offset-3">
                      <input type="text"
                             [formControlName]="'optionLabel'"
                             class="form-control form-control-sm"
                             name="option-label"
                             placeholder="Enter a label">
                    </div>
                    <div class="col-6">
                      <input type="text"
                             class="form-control form-control-sm"
                             [formControlName]="'option'"
                             name="option-text-area"
                             placeholder="Enter an option in text">
                    </div>
                    <div class="col-1 text-end">
                      <button class="btn btn-sm btn-outline-primary harmony-primary-outline-button"
                              [title]="'Create a new option'"
                              [disabled]="!questionForm.controls.option.value"
                              (click)="addOption(questionForm.controls.option.value, questionForm.controls.optionLabel.value)">
                        <fa-icon [icon]="faAdd"></fa-icon>
                      </button>
                    </div>
                  </div>
                  <!-- OPTIONS FOR QUESTION THAT DOES NOT YET EXIST-->
                  <div class="row mt-1" *ngFor="let o of options; let i = index">
                    <div class="col-2 offset-3">
                      <input type="text"
                             class="form-control form-control-sm"
                             name="option-label"
                             [value]="o.Label"
                             (change)="updateOptionLabel(i, $event)"
                             placeholder="Enter a label">
                    </div>
                    <div class="col-6">
                      <input type="text"
                             class="form-control form-control-sm"
                             [value]="o.Option"
                             (change)="updateOption(i, $event)"
                             name="option-text-area">
                    </div>
                    <div class="col-1 text-end">
                      <button class="btn btn-sm btn-outline-danger"
                              [title]="'Delete question option'" (click)="deleteOption(i)">
                        <fa-icon [icon]="faDelete"></fa-icon>
                      </button>
                    </div>
                  </div>
                  <!-- OPTIONS FOR EXISTING QUESTION-->
                  <div class="row mt-1" *ngFor="let o of question.Options; let i = index">
                    <div class="col-2 offset-3">
                      <input type="text"
                             class="form-control form-control-sm"
                             [value]="o.Label"
                             (change)="o.Label = $event.target['value']"
                             name="option-label"
                             placeholder="Enter a label">
                    </div>
                    <div class="col-6">
                      <input type="text"
                             class="form-control form-control-sm"
                             [value]="o.Option"
                             (change)="o.Option = $event.target['value']"
                             name="option-text-area">
                    </div>
                    <div class="col-1 text-end">
                      <button class="btn btn-sm btn-outline-danger"
                              [title]="'Delete question option'"
                              (click)="deleteExistingOption(o.Id, i)">
                        <fa-icon [icon]="faDelete"></fa-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
<!---- Footer ---->
<div class="modal-footer">
  <p *ngIf="!isValid">
    Please fill in all required fields!
  </p>
  <button type="button" class="btn btn-primary harmony-tertiary-button" *ngIf="!isNew" (click)="update()">Save</button>
  <button type="button" class="btn btn-primary harmony-tertiary-button" *ngIf="isNew" (click)="add()">Add</button>
  <button type="button" class="btn btn-primary harmony-primary-button" (click)="close()">Close</button>
</div>
