import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { faEdit, faAdd, faRefresh, faBoxOpen } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PricingService } from 'src/app/services/pricing.service';
import { IPage, IPaginationData } from 'src/app/shared/models/pagination-data.model';
import { ViewAgentModalComponent } from './view-agent-modal/view-agent-modal.component';
import { environment } from 'src/environments/environment';
import { IPreferredAgentRequest, IPreferredAgentResponse } from '../../../services/models/pricing/preferred-data.model';
import { ILocationType } from '../../../services/models/pricing/location.model';

@Component({
  selector: 'app-preferred-agent-management',
  templateUrl: './preferred-agent-management.component.html',
  styleUrls: ['./preferred-agent-management.component.scss']
})
export class PreferredAgentManagementComponent implements OnInit {
  // Icons
  faEdit = faEdit;
  faAdd = faAdd;
  faRefresh = faRefresh;
  faBoxOpen = faBoxOpen;

  // Component variables
  searchRequest = {
    Active: true
  } as IPreferredAgentRequest;
  showNoResults: boolean = false;
  paginationData: IPaginationData<IPreferredAgentResponse>;
  page: IPage;
  locationType: ILocationType = null;
  locationTypes$ = this.pricingService.LocationTypes$.asObservable();

  // General variables
  public loading: boolean;
  environment = environment;

  constructor(public pricingService: PricingService,
              private ref: ChangeDetectorRef,
              private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.paginationData = {
      CurrentPage: 1,
      TotalPages: 1,
      DataSet: [],
      PageSize: 30
    } as IPaginationData<IPreferredAgentResponse>;

    this.page = {
      pageNumber: 1,
      pageSize: this.paginationData.PageSize,
    } as IPage;

    // Default location type to City
    this.pricingService.LocationTypes$.subscribe(() => {
      this.locationType = this.pricingService.GetLocationTypeByName('Country');
      this.searchRequest.LocationTypeId = this.locationType.Id;
      this.loadAgents();
      this.ref.detectChanges();
    });
  }

  clearLocationIds() {
    this.searchRequest.CountryId = null;
    this.searchRequest.CityId = null;
    this.searchRequest.StateId = null;
  }

  setAgent(event: any) {
    this.searchRequest.AgentId = event;
  }

  resetSearch() {
    this.searchRequest = {Active: true} as IPreferredAgentRequest;
    this.searchPreferredAgents();
    this.locationType = null;
  }

  searchPreferredAgents() {
    this.paginationData.CurrentPage = 1;
    this.page.pageNumber = 1;

    this.loadAgents();
  }

  loadAgents() {
    this.loading = true;

    this.pricingService.GetPreferredAgents(this.searchRequest, this.page)
      .subscribe({
        next: (data) => {
          if (data) {
            this.paginationData = Object.assign([], data);
            this.showNoResults = this.paginationData.DataSet && this.paginationData.DataSet.length === 0;
          }
          this.loading = false;
        }, error: () => {
          this.loading = false;
        }
      });
  }

  onPage(page: IPage) {
    this.paginationData.CurrentPage = page.pageNumber;
    this.page = page;

    this.loadAgents();
  }

  openPreferredAgentModal(isNew: boolean, preferredAgent: IPreferredAgentResponse = null) {
    const modalRef = this.modalService.open(ViewAgentModalComponent, {size: 'xl', backdrop: 'static'});
    modalRef.componentInstance.isNew = isNew;
    if (!isNew) {
      modalRef.componentInstance.preferredAgent = preferredAgent;
    }
    modalRef.result.then((result: IPreferredAgentResponse) => {
      this.loadAgents();
    }, () => {
    });
  }
}
