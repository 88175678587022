<div class="modal-header">
  <h4 class="modal-title" *ngIf="!isNew && !isClone">
    Edit Review | {{ review.Id }}
  </h4>
  <h4 class="modal-title" *ngIf="isNew">
    Add New Review
  </h4>
  <h4 class="modal-title" *ngIf="isClone">
    Clone the review | {{ reviewIdClone }}
  </h4>
</div>
<form [formGroup]="reviewForm">
  <div class="modal-body">
    <div class="row">
      <div class="col-12 border-bottom">
        <h5 class="harmony-primary-text mb-1">
          Review Configuration
        </h5>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-3">
        <b>Year</b>
      </div>
      <div class="col-4">
        <input [formControlName]="'year'"
               type="text"
               numbersOnly="4.0"
               class="form-control form-control-sm fw-bold"
               placeholder="Enter year: yyyy"
               [title]="'Type the review year'"
               [ngClass]="{'is-invalid': reviewForm.controls.year.touched && reviewForm.controls.year.invalid}"/>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-3">
        <b>Start Date</b>
      </div>
      <div class="col-4">
        <app-date-picker [identifier]="'startDate'" (onChange)="setStartDate($event)"
                         [placeholder]="'Select a start date'" [value]="review.StartDate">
        </app-date-picker>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-3">
        <b>End Date</b>
      </div>
      <div class="col-4">
        <app-date-picker [identifier]="'endDate'" (onChange)="setEndDate($event)"
                         [placeholder]="'Select an end date'" [value]="review.EndDate">
        </app-date-picker>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-3">
        <b>Harmony Document</b>
      </div>
      <div class="col-4">
        <app-date-picker [identifier]="'harmonyDocDate'" (onChange)="setHarmonyDate($event)"
                         [placeholder]="'Select an enable date'" [value]="review.HarmonyDocumentEnableDate">
        </app-date-picker>
      </div>
      <div class="col-4 align-self-center text-nowrap overflow-hidden"
           style="text-overflow: ellipsis"
           [title]="review.HarmonyDocument ?? 'Upload harmony document...'">
        <fa-icon *ngIf="review.HarmonyDocument" [icon]="faCheck" class="harmony-accent-green-text" size="lg"></fa-icon>
        <fa-icon *ngIf="!review.HarmonyDocument" [icon]="faNotFeatured" class="harmony-grey-text" size="lg"></fa-icon>
        <small class="harmony-grey-text ms-1">{{ review.HarmonyDocument ?? 'Upload harmony document...' }}</small>
      </div>
      <div class="col-1">
        <input [hidden]="true" #ar1 (change)="uploadHarmonyDoc($event)" class="form-control-file" type="file">
        <button class="btn btn-sm btn-primary input-picker-button harmony-secondary-outline-button border-0"
                (click)="ar1.click()"
                [title]="'Upload harmony document'">
          <fa-icon [icon]="faUpload"></fa-icon>
        </button>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-3">
        <b>Network Company Document</b>
      </div>
      <div class="col-4">
        <app-date-picker [identifier]="'networkDocDate'" (onChange)="setNetworkDate($event)"
                         [placeholder]="'Select an enable date'" [value]="review.NetworkCompanyDocumentEnableDate">
        </app-date-picker>
      </div>
      <div class="col-4 align-self-center text-nowrap overflow-hidden"
           style="text-overflow: ellipsis"
           [title]="review.NetworkCompanyDocument ?? 'Upload network document...'">
        <fa-icon *ngIf="review.NetworkCompanyDocument" [icon]="faCheck" class="harmony-accent-green-text" size="lg"></fa-icon>
        <fa-icon *ngIf="!review.NetworkCompanyDocument" [icon]="faNotFeatured" class="harmony-grey-text" size="lg"></fa-icon>
        <small class="harmony-grey-text ms-1">{{ review.NetworkCompanyDocument ?? 'Upload network document...' }}</small>
      </div>
      <div class="col-1">
        <input [hidden]="true" #ar2 (change)="uploadNetworkDoc($event)" class="form-control-file" type="file">
        <button class="btn btn-sm btn-primary input-picker-button harmony-secondary-outline-button border-0"
                (click)="ar2.click()"
                [title]="'Upload network document'">
          <fa-icon [icon]="faUpload"></fa-icon>
        </button>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-3">
        <b>Company Document</b>
      </div>
      <div class="col-4">
        <app-date-picker [identifier]="'companyDocDate'" (onChange)="setCompanyDate($event)"
                         [placeholder]="'Select an enable date'" [value]="review.CompanyDocumentEnableDate">
        </app-date-picker>
      </div>
      <div class="col-4 align-self-center text-nowrap overflow-hidden"
           style="text-overflow: ellipsis"
           [title]="review.CompanyDocument ?? 'Upload company document...'">
        <fa-icon *ngIf="review.CompanyDocument" [icon]="faCheck" class="harmony-accent-green-text" size="lg"></fa-icon>
        <fa-icon *ngIf="!review.CompanyDocument" [icon]="faNotFeatured" class="harmony-grey-text" size="lg"></fa-icon>
        <small class="harmony-grey-text ms-1">{{ review.CompanyDocument ?? 'Upload company document...' }}</small>
      </div>
      <div class="col-1 template-button">
        <input [hidden]="true" #ar3 (change)="uploadCompanyDoc($event)" class="form-control-file" type="file">
        <button class="btn btn-sm btn-primary input-picker-button harmony-secondary-outline-button border-0"
                (click)="ar3.click()"
                [title]="'Upload company document'">
          <fa-icon [icon]="faUpload"></fa-icon>
        </button>
      </div>
    </div>
    <div class="row mt-2 border-bottom">
      <div class="col-12">
        <h5 class="harmony-primary-text mb-1">
          Audit Configuration
        </h5>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-3 ">
        <b>Audit Start Date</b>
      </div>
      <div class="col-4">
        <app-date-picker [identifier]="'auditStartDate'" (onChange)="setAuditStartDate($event)"
                         [placeholder]="'Select a start date'" [value]="review.AuditStartDate">
        </app-date-picker>
      </div>
      <div class="col-4 align-self-center text-nowrap overflow-hidden"
           style="text-overflow: ellipsis"
           [title]="review.AuditDocument ?? 'Upload audit document...'">
        <fa-icon *ngIf="review.AuditDocument" [icon]="faCheck" class="harmony-accent-green-text" size="lg"></fa-icon>
        <fa-icon *ngIf="!review.AuditDocument" [icon]="faNotFeatured" class="harmony-grey-text" size="lg"></fa-icon>
        <small class="harmony-grey-text ms-1">{{ review.AuditDocument ?? 'Upload audit document...' }}</small>
      </div>
      <div class="col-1 template-button">
        <input [hidden]="true" #ara (change)="uploadAuditDoc($event)" class="form-control-file" type="file">
        <button class="btn btn-sm btn-primary input-picker-button harmony-secondary-outline-button border-0"
                (click)="ara.click()"
                [title]="'Upload Audit document'">
          <fa-icon [icon]="faUpload"></fa-icon>
        </button>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-3 ">
        <b>Audit End Date</b>
      </div>
      <div class="col-4">
        <app-date-picker [identifier]="'auditEndDate'" (onChange)="setAuditEndDate($event)"
                         [placeholder]="'Select an end date'" [value]="review.AuditEndDate">
        </app-date-picker>
      </div>
    </div>
  </div>
</form>
<app-loader *ngIf="loading"></app-loader>
<!---- Footer ---->
<div class="modal-footer" *ngIf="!isNew && !isClone">
  <button type="button" class="btn btn-primary harmony-tertiary-button" (click)="update()">Save</button>
  <button type="button" class="btn btn-primary harmony-primary-button" (click)="close(false)">Close</button>
</div>
<div class="modal-footer" *ngIf="isNew">
  <button type="button" class="btn btn-primary harmony-tertiary-button" (click)="add()">Add</button>
  <button type="button" class="btn btn-primary harmony-primary-button" (click)="close(false)">Close</button>
</div>
<div class="modal-footer" *ngIf="isClone">
  <button type="button" class="btn btn-primary harmony-tertiary-button" (click)="clone()">Clone</button>
  <button type="button" class="btn btn-primary harmony-primary-button" (click)="close(false)">Close</button>
</div>
