<!---------------------->
<!------- Header ------->
<!---------------------->
<div
  class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom sticky-header">
  <h1 class="portal-component-header">
    Protection Safety Phrases
  </h1>
  <div class="justify-content-end">
    <button class="btn btn-sm btn-primary harmony-purple-outline-button"
            *ngIf="isAdmin"
            [disabled]="loading" (click)="addPhrase()">
      Add phrase
    </button>
  </div>
</div>
<!--------------------------------->
<!-- Company branch Overview ----->
<!--------------------------------->
<ng-container *ngIf="isAdmin">
  <div class="risk-assessment">
    <!----------------------->
    <!------- Filters ------->
    <!----------------------->
    <ng-container>
      <div class="row">
        <div class="col-3">
          <input class="form-control form-control-sm"
                 type="text"
                 placeholder="Search phrases..."
                 [(ngModel)]="phraseSearchParams.Description">
        </div>
        <div class="col-1">
          <b>Language</b>
        </div>
        <div class="col-2">
          <select class="form-select form-select-sm pointer"
                  [(ngModel)]="phraseSearchParams.LanguageCode"
                  [disabled]="loading">
            <option [ngValue]="null" selected>All languages...</option>
            <option [ngValue]="'NL'" selected>NL</option>
            <option [ngValue]="'EN'" selected>EN</option>
            <option [ngValue]="'DE'" selected>DE</option>
            <option [ngValue]="'PT'" selected>PT</option>
          </select>
        </div>
        <div class="col-2 offset-2 text-end pe-0">
          <button class="btn btn-sm btn-primary harmony-teal-outline-button ms-2"
                  [title]="'Reset search'"
                  (click)="resetSearch()">
            Reset
          </button>
        </div>
        <div class="col-2 mb-1">
          <button class="btn btn-sm btn-primary harmony-purple-button w-100 float-end"
                  (click)="searchPhrases()">
            Search
          </button>
        </div>
      </div>
      <hr class="my-1">
    </ng-container>
    <!------------------->
    <!---- Pagination --->
    <!------------------->
    <div class="row ms-1 me-1">
      <div class="col-12">
        <app-pagination [showPageNumber]=true
                        [showRowsPerPage]=true
                        [paginationData]=paginationData
                        (pageEvent)="onPage($event)"
                        [name]="'PSPhrases'"
                        [page]="page">
        </app-pagination>
      </div>
    </div>
    <!----------------------->
    <!------- Results ------->
    <!----------------------->
    <div class="row mt-2 header-row">
      <div class="col-9 header-title border-split-left"
           (appOrder)="orderSearch($event)"
           data-column="Description"
           data-order="desc">
        Description
        <fa-icon [hidden]="orderParam.OrderBy != 'Description'" [icon]="faSortAsc" style="bottom: 3px;" class="ms-2 position-relative"></fa-icon>
      </div>
      <div class="col-2 header-title"
           (appOrder)="orderSearch($event)"
           data-column="LanguageCode"
           data-order="asc">
        Language
        <fa-icon [hidden]="orderParam.OrderBy != 'LanguageCode'" [icon]="faSortAsc" style="bottom: 3px;" class="ms-2 position-relative"></fa-icon>
      </div>
      <div class="col-1 header-title border-split-right text-center">
      </div>
    </div>
    <app-loader *ngIf="loading"></app-loader>
    <app-no-result [showNoResults]="noResult"></app-no-result>
    <div class="row result-row my-0" *ngFor='let phrase of paginationData.DataSet; let i = index'>
      <div class="col-9 overflow-hidden" [title]="phrase.Description">
        {{ phrase.Description }}
      </div>
      <div class="col-2 fw-bold overflow-hidden">
        {{ phrase.LanguageCode }}
      </div>
      <div class="col-1 text-end">
        <button class="btn btn-sm btn-primary harmony-teal-outline-button me-1"
                [title]="'View or edit phrase'" (click)="openPhrase(phrase)">
          <fa-icon [icon]="faEdit"></fa-icon>
        </button>
        <button *ngIf="authService.CheckPermissionByCode(PermissionCodes.HSE_Admin)"
                class="btn btn-sm btn-outline-danger"
                [title]="'Delete phrase'" (click)="deletePhrase(phrase)">
          <fa-icon [icon]="faDelete"></fa-icon>
        </button>
      </div>
    </div>
  </div>
</ng-container>


