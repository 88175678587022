import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ICategoryDocument, ICategoryDocumentParam } from '../../../services/models/document/document.model';
import { DocumentService } from '../../../services/document.service';
import { AlertService } from '../../../services/alert.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { SharedModule } from '../../shared.module';
import { faCircleQuestion, faFolder, faFolderOpen } from '@fortawesome/free-regular-svg-icons';
import { DocumentTypeIconComponent } from '../document-type-icon/document-type-icon.component';
import { DocumentListComponent } from '../document-list/document-list.component';
import { Subject } from 'rxjs';
import { AdAuthService } from '../../../core/ad-auth-service/ad-auth.service';

@Component({
  selector: 'app-document-category-list',
  standalone: true,
  templateUrl: './document-category-list.component.html',
  imports: [
    FontAwesomeModule,
    NgForOf,
    SharedModule,
    NgIf,
    DocumentTypeIconComponent,
    AsyncPipe,
    DocumentListComponent
  ],
  styleUrls: ['./document-category-list.component.scss']
})
export class DocumentCategoryListComponent implements OnInit {
  // Inputs
  @Input() Module: string = null;
  @Input() Categories: string[] = null;
  @Input()
  set CompanyId(companyId: number) {
    if (companyId && companyId > 0) {
      this.searchParam.CompanyId = companyId;
      this.loadCategoryDocuments();
    }
  }
  @Input() DocumentUserId: number = null;

  // Icons
  protected readonly faInfo = faCircleQuestion;
  protected readonly faCategoryIcon = faFolder;
  protected readonly faCategoryOpenIcon = faFolderOpen;

  // Component variables
  searchParam = {
    Categories: null,
    ModuleName: null,
    CompanyId: null,
    DocumentUserId: null,
  } as ICategoryDocumentParam;
  categories$: Subject<ICategoryDocument[]> = new Subject<ICategoryDocument[]>();
  openCategory: string = null;

  // General variables
  loading: boolean = false;
  protected readonly env = environment;

  constructor(private alertService: AlertService,
              private auth: AdAuthService,
              private documentService: DocumentService) { }

  ngOnInit(): void {
    this.loadCategoryDocuments();
  }

  loadCategoryDocuments() {
    this.searchParam.ModuleName = this.Module;
    this.searchParam.Categories = this.Categories;
    this.searchParam.DocumentUserId = this.DocumentUserId;

    this.documentService.loadCategoryDocuments(this.searchParam).subscribe({
      next: (data: ICategoryDocument[]) => {
        if (data) {
          // Populate extensions
          data.forEach(c => c.Documents.forEach(x => x.Extension = this.getFileType(x.FileName).toLowerCase()));
          // Check permissions
          const authorizedData = data.filter(c => this.checkRole(c) === true);
          this.categories$.next(authorizedData);
        }
        this.loading = false;
      }, error: () => {
        this.loading = false;
        this.alertService.error('Failed to search documents.');
      }
    });
  }

  checkRole(category: ICategoryDocument) {
    let result = true;

    if (category.Role != null) {
      result = this.auth.CheckRole(category.Role);
    }

    return result;
  }

  setActiveCategory(categoryCode: string) {
    if (this.openCategory === categoryCode) {
      this.openCategory = null;
    } else {
      this.openCategory = categoryCode;
    }
  }

  getFileType(fileName: string) {
    return fileName.substring(fileName.lastIndexOf('.') + 1);
  }
}
