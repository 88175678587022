<div class="dashboard-background"></div>
<div class="dashboard-wrapper">
  <div class="dashboard-content">
    <!-- Business Intelligence -->
    <div class="three-by-two d-flex align-items-start services-card">
      <div>
        <div class="services-card-header row">
          <div class="col-12">
            <!--Header-->
            <h5 class="harmony-primary-text fw-bold">Business Intelligence</h5>
            <hr class="mt-0 mb-1">
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-3">
            <div class="justify-content-center m-2">
                <img [src]="env.SiteUrls.AzureStorageBaseURL + env.ContainerNames.WebImagesServices + 'businessintelligence.png'"
                     class="service-image"
                     alt="Business Intelligence">
            </div>
          </div>
          <div class="col-9 service-content">
            <p>
              The amount of data available in today’s companies is growing exponentially. Many companies are struggling to obtain the desired information from this vast amount of available data.
              Which reporting tools should I use? Is someone available within the company to maintain the existing reports and infrastructure?
            </p>
            <p>
              Looking at the Business Intelligence Tools market place there are many options to choose from. But which tool works best?
              At Harmony we keep a close eye on all current developments and we will make sure to use the correct tools that fit the organisation’s needs.
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- Business Intelligence Contact -->
    <div class="one-by-one services-card">
      <div class="services-card-header row">
        <div class="col-12">
          <!--Header-->
          <h5 class="harmony-primary-text fw-bold">Business Intelligence Contact</h5>
          <hr class="mt-0 mb-1">
        </div>
      </div>
      <div class="row">
        <div class="col-12 ms-4">
          <div class="row">
            <div class="col-12 service-contact">
              Mr Willem-Frederik van der Heijden
            </div>
          </div>
          <div class="row my-1">
            <div class="col-1">
              <fa-icon [icon]="faEnvelope"></fa-icon>
            </div>
            <div class="col-11 nav-item">
              <a href="mailto:w.heijden@harmonyrelo.com?Subject=Request information about Services/Business-Intelligence" class="harmony-secondary-link">w.heijden@harmonyrelo.com</a>
            </div>
          </div>
          <div class="row my-1">
            <div class="col-1">
              <fa-icon [icon]="faPhone"></fa-icon>
            </div>
            <div class="col-11">
              <a href="callto:+31 294 299 066" class="harmony-secondary-link">+31 294 299 066</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Output Examples -->
    <div class="one-by-one services-card">
      <div class="services-card-header row">
        <div class="col-12">
          <!--Header-->
          <h5 class="harmony-primary-text fw-bold">Output Examples</h5>
          <hr class="mt-0 mb-1">
        </div>
      </div>
      <div class="card-body row mt-2">
        <div class="col-12 service-content document-list">
          <app-document-list [ModuleName]="'Services'" [CategoryCode]="'SERVSHEE'" [Recents]="true"></app-document-list>
        </div>
      </div>
    </div>
    <!-- Reporting -->
    <div class="three-by-five d-flex align-items-start services-card ">
      <div>
        <div class="services-card-header row">
          <div class="col-12">
            <!--Header-->
            <h5 class="harmony-primary-text fw-bold">Reporting</h5>
            <hr class="mt-0 mb-1">
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-3">
            <div class="justify-content-center m-2">
              <div>
                <img [src]="env.SiteUrls.AzureStorageBaseURL + env.ContainerNames.WebImagesServices + 'reporting.jpg'"
                     class="service-image-rounded-borders"
                     alt="Reporting">
              </div>
            </div>
          </div>
          <div class="col-9 service-content">
            <div>
              <p>
                Providing data, data analysis and reports to our customers is the perfect way to distinguish ourselves from the competition.
                Having data available at a mouse click or granting access to reports via a portal shows the company is in control and confirms transparency.
              </p>
              <p>
                Reports come in different shapes and sizes. From management dashboards to invoice specifications.
                Here are a few examples of existing reports;
              </p>
              <ul class="service-list">
                <li><span class="harmony-secondary-text fw-semibold">Management Review</span> - Set targets and compare the developments of the numbers through the years. Track the effectiveness of implemented changes and determine if additional action is required.</li>
                <li><span class="harmony-secondary-text fw-semibold">Vendor Management</span> - In order to deliver high quality services to your clients it is vital that you monitor the performance of your supply chain.</li>
                <li><span class="harmony-secondary-text fw-semibold">Corporate Client Dashboard</span> - Monthly reports to your account managers and corporate clients providing information on number of moves, the customer feedback, and invoiced amounts.</li>
                <li><span class="harmony-secondary-text fw-semibold">Quarterly Review</span> - Creating powerpoint presentations for quarterly reviews with the corporate client can take a lot of time. Predefined presentations can be updated with new data in minutes. The current presentation shows activities, transit time, quality, claims and financial data.</li>
              </ul>
              <br>
              <p>
                <span><h5 class="harmony-secondary-text fw-bold">Distribution</h5></span>
                Having the right reports at the right time helps drive your business. With our reporting platform, reports can be made available via:
              </p>
              <ul class="service-list">
                <li>Online portal</li>
                <li>Mobile devices</li>
                <li>Email</li>
                <li>Files</li>
              </ul>
              <p>
                Reports can be sent on a schedule that meets your requirements, e.g. every quarter. Another powerful function is sending reports based on predefined rules. If the quality level of a supplier drops below your threshold an email with the details can be send to your quality manager.
              </p>
              <br>
              <p>
                <span><h5 class="harmony-secondary-text fw-bold">Redsky</h5></span>
                Companies on RedSky have the advantage that the infrastructure to load the data from the RedSky into the Harmony report environment already exists.

                For non RedSky users only small adjustments need to be made to enable reporting.
                You can choose from the existing reports we offer, which can be copied to your environment allowing you to benefit from the functionality right from the start.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Member Support Reporting -->
    <div class="three-by-two d-flex align-items-start services-card">
      <div>
        <div class="services-card-header row">
          <div class="col-12">
            <!--Header-->
            <h5 class="harmony-primary-text fw-bold">Member Support Reporting</h5>
            <hr class="mt-0 mb-1">
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-3">
            <div class="justify-content-center m-2">
              <div>
                <img [src]="env.SiteUrls.AzureStorageBaseURL + env.ContainerNames.WebImagesServices + 'reporting%2002.jpg'"
                     class="service-image-rounded-borders"
                     alt="Member Support Reporting">
              </div>
            </div>
          </div>
          <div class="col-9 service-content">
            <div>
              <p>
                Setting up a reporting environment is complex and requires specific knowledge. We have the infrastructure in place, and trained staff available. In other words, there’s no need to train your own staff or hire expensive consultants.

                The reporting environment runs in a high availability and secure data centre close to one of the largest internet hubs. We follow the latest technologies and upgrade our software regularly. Therefore ensuring your data is safe and protected as well as available when you need it at very competitive rates.

                Our team have years of experience and based on best practices we can help you in your quest to build the reports you need.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

