<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-2 border-bottom sticky-header">
  <h1 class="portal-component-header">
    Missing Values
  </h1>
</div>
<div class="row mb-5">
  <div class="col-12">
    <!------------------->
    <!---- Filtering ---->
    <!------------------->
    <div class="row">
      <div class="col-2 align-self-center">
        <b>Client Direction</b>
      </div>
      <div class="col-3">
        <select class="form-select form-select-sm pointer"
                [disabled]="!(userClients$ | async) || (userClients$ | async).length <= 0"
                [(ngModel)]="selectedClient"
                (change)="setClientData()">
          <option [ngValue]="null" *ngIf="canViewAll">All</option>
          <option *ngFor="let client of (userClients$ | async)"
                  [selected]="searchParam.SourceClientId == client.SourceClientId && searchParam.TargetClientId == client.TargetClientId"
                  [ngValue]="client">
            {{ client.SourceClientName }} ({{client.SourceSystemName}})&nbsp; &DDotrahd; &nbsp;{{ client.TargetClientName }} ({{client.TargetSystemName}})
          </option>
        </select>
      </div>
    </div>
    <hr class="mt-2 mb-0">
    <div class="row mt-1">
      <div class="col-5">
        <input type="text"
               class="filter-item form-control form-control-sm"
               [(ngModel)]="searchParam.SearchText"
               (keyup.enter)="searchMissingValues()"
               placeholder="Search by source value...">
      </div>
      <div class="col-2 offset-5 text-end">
        <button type="button"
                class="btn btn-sm btn-primary harmony-tertiary-button w-100"
                (click)="searchMissingValues()">
          Search
        </button>
      </div>
    </div>
    <hr class="mt-1 mb-0">
    <!------------------->
    <!---- Pagination --->
    <!------------------->
    <div class="row ms-1 me-1">
      <div class="col-12">
        <app-pagination [showPageNumber]=true
                        [showRowsPerPage]=true
                        [paginationData]=paginationData
                        (pageEvent)="onPage($event)"
                        [name]="'HubMissingValues'"
                        [page]="page">
        </app-pagination>
      </div>
    </div>
    <!------------------->
    <!----- Results ----->
    <!------------------->
    <div class="row mt-1 harmony-primary-text">
      <div class="col-5 text-center">
        <h5>Source</h5>
      </div>
      <div class="col-4 text-center">
        <h5>Target</h5>
      </div>
    </div>
    <div class="row header-row">
      <div class="col-1 col-compress pointer header-title border-split-left"
           (appOrder)="orderSearch($event)"
           data-column="SourceElement.System.SystemName"
           data-order="desc">
        System
        <fa-icon [hidden]="orderParam.OrderBy != 'SourceElement.System.SystemName'" [icon]="faSortASc" style="bottom: 3px;" class="ms-2 position-relative"></fa-icon>
      </div>
      <div class="col-1 col-compress pointer header-title"
           (appOrder)="orderSearch($event)"
           data-column="SourceClient.ClientName"
           data-order="asc">
        Client
        <fa-icon [hidden]="orderParam.OrderBy != 'SourceClient.ClientName'" class="ms-2 position-relative"></fa-icon>
      </div>
      <div class="col-2 col-compress pointer header-title"
           (appOrder)="orderSearch($event)"
           data-column="SourceElement.HubSchema.ObjectTypePath"
           data-order="asc">
        Element
        <fa-icon [hidden]="orderParam.OrderBy != 'SourceElement.HubSchema.ObjectTypePath'" class="ms-2 position-relative"></fa-icon>
      </div>
      <div class="col-1 col-compress pointer header-title border-split-right"
           (appOrder)="orderSearch($event)"
           data-column="SourceValue"
           data-order="asc">
        Value
        <fa-icon [hidden]="orderParam.OrderBy != 'SourceValue'" class="ms-2 position-relative"></fa-icon>
      </div>
      <div class="col-1 col-compress pointer header-title border-split-left"
           (appOrder)="orderSearch($event)"
           data-column="TargetElement.System.SystemName"
           data-order="desc">
        System
        <fa-icon [hidden]="orderParam.OrderBy != 'TargetElement.System.SystemName'" [icon]="faSortASc" style="bottom: 3px;" class="ms-2 position-relative"></fa-icon>
      </div>
      <div class="col-1 col-compress pointer header-title"
           (appOrder)="orderSearch($event)"
           data-column="SourceMessage.TargetClient.ClientName"
           data-order="asc">
        Client
        <fa-icon [hidden]="orderParam.OrderBy != 'SourceMessage.TargetClient.ClientName'" class="ms-2 position-relative"></fa-icon>
      </div>
      <div class="col-2 col-compress pointer header-title border-split-right"
           (appOrder)="orderSearch($event)"
           data-column="TargetElement.HubSchema.ObjectTypePath"
           data-order="asc">
        Element
        <fa-icon [hidden]="orderParam.OrderBy != 'TargetElement.HubSchema.ObjectTypePath'" class="ms-2 position-relative"></fa-icon>
      </div>
      <div class="col-2 col-compress pointer header-title border-split-left"
           (appOrder)="orderSearch($event)"
           data-column="Comment"
           data-order="asc">
        Comment
        <fa-icon [hidden]="orderParam.OrderBy != 'Comment'" class="ms-2 position-relative"></fa-icon>
      </div>
      <div class="col-1 col-compress header-title text-end border-split-right">

      </div>
    </div>
    <ng-container *ngIf="!loading && paginationData && paginationData.DataSet.length > 0">
      <div class="row result-row" *ngFor="let missing of paginationData.DataSet">
        <div class="col-1 col-compress overflow-hidden">
          <b>{{ missing.SourceSystemName }}</b>
        </div>
        <div class="col-1 col-compress overflow-hidden">
          {{ missing.SourceClientName ?? '-' }}
        </div>
        <div class="col-2 col-compress overflow-hidden" [title]="missing.SourceElementPath ?? '-'">
          <small>{{ missing.SourceElementPath ?? '-' }}</small>
        </div>
        <div class="col-1 col-compress overflow-hidden">
          <small>{{ missing.SourceValue }}</small>
        </div>
        <div class="col-1 col-compress overflow-hidden">
          <b>{{ missing.TargetSystemName }}</b>
        </div>
        <div class="col-1 col-compress overflow-hidden">
          {{ missing.TargetClientName ?? '-' }}
        </div>
        <div class="col-2 col-compress overflow-hidden" [title]="missing.TargetElementPath ?? '-'">
          <small>{{ missing.TargetElementPath ?? '-' }}</small>
        </div>
        <div class="col-2 col-compress overflow-hidden">
          <small>{{ missing.Comment }}</small>
        </div>
        <div class="col-1 col-compress text-end pe-0">
          <button class="btn btn-sm btn-outline-warning harmony-tertiary-outline-button me-1"
                  [title]="'Map value'"
                  (click)="mapMissingValue(missing)">
            <fa-icon [icon]="faView" size="xs"></fa-icon>
          </button>
          <button class="btn btn-sm btn-outline-warning harmony-accent-blue-grey-outline-button me-1"
                  [title]="'Ignore value mapping'"
                  (click)="markAsIgnore(missing)">
            <fa-icon [icon]="faIgnore" size="xs"></fa-icon>
          </button>
        </div>
      </div>
    </ng-container>
    <!------------------->
    <!----- Loader ------>
    <!------------------->
    <ng-container *ngIf="loading">
      <div class="row mt-4">
        <div class="col-12 text-center">
          <app-loader [Width]="50"></app-loader>
        </div>
      </div>
    </ng-container>
    <!------------------->
    <!--- No Results ---->
    <!------------------->
    <ng-container *ngIf="!loading && paginationData.DataSet.length <= 0">
      <div class="row mt-4" *ngIf="!loading && (paginationData.DataSet && paginationData.DataSet.length === 0)">
        <div class="col-12 text-center harmony-grey-text">
          <fa-icon [icon]="faEmpty" size="2x"></fa-icon>
        </div>
        <div class="col-12 text-center harmony-grey-text mt-1">
          <h4>No results...</h4>
        </div>
      </div>
    </ng-container>
  </div>
</div>
