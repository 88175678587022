<div id="hse-accordion">
  <div class="card portal-menu-collapse-card">
    <a [routerLink]="['hse/dashboard']">
      <div class="portal-menu-collapse-header bg-dark" id="headingHse" data-bs-toggle="collapse" data-bs-target="#collapseHse"
           aria-expanded="true" aria-controls="collapseHse">
          <h5 class="portal-menu-heading p-2 mb-0 text-muted">
            <span class="heading-icon icon-color">
              <fa-icon [icon]="faHSE" [size]="'xs'"></fa-icon>
            </span>
            <span class="portal-menu-module-name"
                  [ngClass]="{'harmony-primary-text fw-bold': router.url.includes('hse/')}">
              HSE
            </span>
          </h5>
      </div>
    </a>
    <div id="collapseHse" class="collapse" aria-labelledby="headingHse" data-bs-parent="#hse-accordion">
      <div class="portal-menu-collapse-body">
        <nav class="col-md-12 d-none d-md-block ps-4">
          <ul class="nav flex-column mb-0">
            <li class="nav-item" *ngIf="dashboard">
              <a class="nav-link pointer"
                 [routerLink]="['hse/dashboard']"
                 [ngClass]="{'active': router.url.includes('dashboard')}">
                <fa-icon class="link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Dashboard
              </a>
            </li>
            <li class="nav-item" *ngIf="incidentImprovement">
              <a class="nav-link pointer"
                 [routerLink]="['hse/incident-improvement']"
                 [ngClass]="{'active': router.url.includes('incident-improvement')}">
                <fa-icon class="link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Incident Improvement
              </a>
            </li>
            <li class="nav-item" *ngIf="jobInspection">
              <a class="nav-link pointer"
                 [routerLink]="['hse/job-inspection']"
                 [ngClass]="{'active': router.url.includes('job-inspection')}">
                <fa-icon class="link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Job Inspection
              </a>
            </li>
            <li class="nav-item" *ngIf="dsr">
              <a class="nav-link pointer"
                 [routerLink]="['hse/dsr']"
                 [ngClass]="{'active': router.url.includes('dsr')}">
                <fa-icon class="link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Dangerous Substance Register
              </a>
            </li>
            <li class="nav-item" *ngIf="pulseChart">
              <a class="nav-link pointer"
                 [routerLink]="['hse/pulse-chart']"
                 [ngClass]="{'active': router.url.includes('pulse-chart')}">
                <fa-icon class="link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Pulse Chart
              </a>
            </li>
            <li class="nav-item" *ngIf="emergency">
              <a class="nav-link pointer"
                 [routerLink]="['hse/emergency']"
                 [ngClass]="{'active': router.url.includes('emergency')}">
                <fa-icon class="link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon>
                Emergency
              </a>
            </li>
            <li class="nav-item" *ngIf="companyChart">
              <a class="nav-link pointer"
                 [routerLink]="['hse/company-chart']"
                 [ngClass]="{'active': router.url.includes('company-chart')}">
                <fa-icon class="link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon>
                Company Chart
              </a>
            </li>
            <li class="nav-item" *ngIf="riskAssessment">
              <div class="sub-menu">
                <div class="row">
                  <div class="col-12">
                    <a class="nav-link pointer"
                       (click)="hsSubMenu.hidden = !hsSubMenu.hidden"
                       [ngClass]="{'active': router.url.includes('hs-risk-assessment')}">
                      <fa-icon [rotate]="hsSubMenu.hidden == false ? 90 : undefined" class="link-icon me-2" [icon]="faExpandable" size="1x"></fa-icon> Health & Safety
                    </a>
                  </div>
                </div>
                <div #hsSubMenu
                     [hidden]="true"
                     class="sub-menu-list">
                  <ul class="nav flex-column mb-0">
                    <li class="nav-item sub-nav-item pointer"
                        *ngIf="riskAssessment">
                      <a class="sub-link"
                         [routerLink]="['hse/hs-risk-assessment']"
                         [ngClass]="{'active': router.url.includes('hs-risk-assessment')}">
                        <fa-icon class="sub-link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Risk Assessment
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li class="nav-item" *ngIf="environmentalAssessment || environmentalImpactData">
              <div class="sub-menu">
                <div class="row">
                  <div class="col-12">
                    <a class="nav-link pointer"
                       (click)="environmentalSubMenu.hidden = !environmentalSubMenu.hidden"
                       [ngClass]="{'active': router.url.includes('environmental-risk-assessment') || router.url.includes('environmental-impact-data')}">
                      <fa-icon [rotate]="environmentalSubMenu.hidden == false ? 90 : undefined" class="link-icon me-2" [icon]="faExpandable" size="1x">
                      </fa-icon> Environmental
                    </a>
                  </div>
                </div>
                <div #environmentalSubMenu
                     [hidden]="true"
                     class="sub-menu-list">
                  <ul class="nav flex-column mb-0">
                    <li *ngIf="environmentalAssessment"
                        class="nav-item sub-nav-item pointer">
                      <a class="sub-link"
                         [routerLink]="['hse/environmental-risk-assessment']"
                         [ngClass]="{'active': router.url.includes('environmental-risk-assessment')}">
                        <fa-icon class="sub-link-icon me-2" [icon]="faMenuItem" size="2xs">
                        </fa-icon> Risk Assessment
                      </a>
                    </li>
                    <li *ngIf="environmentalImpactData"
                        class="nav-item sub-nav-item pointer">
                      <a class="sub-link"
                         [routerLink]="['hse/environmental-impact-data']"
                         [ngClass]="{'active': router.url.includes('environmental-impact-data')}">
                        <fa-icon class="sub-link-icon me-2" [icon]="faMenuItem" size="2xs">
                        </fa-icon> Impact Data
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li class="nav-item" *ngIf="documents">
              <a class="nav-link pointer"
                 [routerLink]="['hse/documents']"
                 [ngClass]="{'active': router.url.endsWith('hse/documents')}">
                <fa-icon class="link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Documents
              </a>
            </li>
            <li class="nav-item" *ngIf="hseAdmin">
              <div class="sub-menu">
                <div class="row">
                  <div class="col-12">
                    <a class="nav-link pointer"
                       (click)="admSubMenu.hidden = !admSubMenu.hidden"
                       [ngClass]="{'active': router.url.includes('risk-assessment-admin') || router.url.includes('environmental-assessment-admin') || router.url.includes('branch-admin') }">
                      <fa-icon [rotate]="admSubMenu.hidden == false ? 90 : undefined" class="link-icon me-2" [icon]="faExpandable" size="1x"></fa-icon> HSE Admin
                    </a>
                  </div>
                </div>
                <div #admSubMenu
                     [hidden]="true"
                     class="sub-menu-list">
                  <ul class="nav flex-column mb-0">
                    <li class="nav-item sub-nav-item pointer"
                        *ngIf="hseAdmin">
                      <a class="sub-link"
                         [routerLink]="['hse/risk-assessment-admin']"
                         [ngClass]="{'active': router.url.includes('risk-assessment-admin')}">
                        <fa-icon class="sub-link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Risk Assessment items
                      </a>
                    </li>
                    <li class="nav-item sub-nav-item pointer"
                        *ngIf="hseAdmin">
                      <a class="sub-link"
                         [routerLink]="['hse/environmental-assessment-admin']"
                         [ngClass]="{'active': router.url.includes('environmental-assessment-admin')}">
                        <fa-icon class="sub-link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Environmental Assessment items
                      </a>
                    </li>
                    <li class="nav-item sub-nav-item pointer"
                        *ngIf="hseAdmin">
                      <a class="sub-link"
                         [routerLink]="['hse/branch-admin']"
                         [ngClass]="{'active': router.url.includes('branch-admin')}">
                        <fa-icon class="sub-link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Company Branches
                      </a>
                    </li>
                    <li class="nav-item sub-nav-item pointer"
                        *ngIf="hseAdmin">
                      <a class="sub-link"
                         [routerLink]="['hse/ps-phrases-admin']"
                         [ngClass]="{'active': router.url.includes('ps-phrases-admin')}">
                        <fa-icon class="sub-link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Protection Safety Phrases
                      </a>
                    </li>
                    <li class="nav-item sub-nav-item pointer"
                        *ngIf="hseAdmin">
                      <a class="sub-link"
                         [routerLink]="['hse/hr-phrases-admin']"
                         [ngClass]="{'active': router.url.includes('hr-phrases-admin')}">
                        <fa-icon class="sub-link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Hazard Risk Phrases
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</div>
