<div id="network-accordion">
  <div class="card portal-menu-collapse-card">
    <div class="portal-menu-collapse-header bg-dark"
         id="headingNetwork"
         data-bs-toggle="collapse"
         data-bs-target="#collapseNetwork"
         aria-expanded="true"
         aria-controls="collapseNetwork">
      <h5 class="portal-menu-heading">
        <span class="heading-icon icon-color">
          <fa-icon [icon]="faNetwork" [size]="'xs'"></fa-icon>
        </span>
        <span class="portal-menu-module-name"
              [ngClass]="{'harmony-primary-text fw-bold': router.url.includes('network/')}">
          Network
        </span>
      </h5>
    </div>
    <div id="collapseNetwork" class="collapse" aria-labelledby="headingNetwork" data-bs-parent="#network-accordion">
      <div class="portal-menu-collapse-body">
        <nav class="col-md-12 d-none d-md-block ps-4">
          <ul class="nav flex-column mb-0">
            <li class="nav-item" *ngIf="memberSearch">
              <a class="nav-link pointer"
                 [routerLink]="['network/search-members']"
                 [ngClass]="{'active': router.url.includes('search-members')}">
                <fa-icon class="link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Member Search
              </a>
            </li>
            <li class="nav-item" *ngIf="memberProfile">
              <a class="nav-link pointer"
                 [routerLink]="['network/edit-member']"
                 [ngClass]="{'active': router.url.includes('edit-member')}">
                <fa-icon class="link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Member Profile
              </a>
            </li>
            <li class="nav-item" *ngIf="memberUserManagement">
              <a class="nav-link pointer"
                 [routerLink]="['network/company-users']"
                 [ngClass]="{'active': router.url.includes('company-users')}">
                <fa-icon class="link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Users
              </a>
            </li>
            <li class="nav-item" *ngIf="training">
              <a class="nav-link pointer"
                 [routerLink]="['network/training']"
                 [ngClass]="{'active': router.url.includes('training')}">
                <fa-icon class="link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Training
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</div>
