import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { IUser, IUserRoleDto } from '../../../services/models/user.model';
import { AlertService } from '../../../services/alert.service';
import { AdAuthService } from '../../../core/ad-auth-service/ad-auth.service';
import { FileService } from '../../../services/file.service';
import { IAuthUser } from '../../../services/models/auth.model';
import { environment } from '../../../../environments/environment';
import { AuthenticationResult, PopupRequest } from '@azure/msal-browser';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { Subject } from 'rxjs';
import { ICompany, IAuthUserCompany } from '../../../services/models/member.model';
import { CompanyService } from '../../../services/company.service';
import { LogService } from '../../../services/log.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OpenConfirmationModal } from '../../../shared/components/confirmation-modal/confirmation-modal-functions';
import { takeUntil } from 'rxjs/operators';
import { faEye } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit, OnDestroy {
  // Icons
  faView = faEye;

  // Component variables
  user: IAuthUser = null;
  userProfile: IUser = null;
  userCompanies: IAuthUserCompany[] = [];
  activeRoles: IUserRoleDto[] = [];
  selectedCompany: IAuthUserCompany = null;
  picture: any = null;

  // General variables
  env = environment;
  loading: boolean = false;
  private unsubscribe: Subject<any> = new Subject<any>();
  public logger = (this as any).constructor.name as string;

  constructor(public auth: AdAuthService,
              public companyService: CompanyService,
              private alertService: AlertService,
              private fileService: FileService,
              private msalService: MsalService,
              @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
              private log: LogService,
              private modalService: NgbModal) {
  }

  ngOnInit() {
    if (this.auth.CurrentUser) {
      this.setUserInfo(this.auth.CurrentUser);
    }

    this.auth.CurrentUser$.subscribe((user: IAuthUser) => {
      this.setUserInfo(user);
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  setUserInfo(currentUser: IAuthUser) {
    // Set original user for change detection
    this.user = JSON.parse(JSON.stringify(currentUser));
    this.userProfile = currentUser.User;
    this.getProfilePicture();
    // Load company data
    this.getUserCompanies();
    this.loadCompanyData();
  }

  loadCompanyData() {
    this.companyService.getCompany(this.user.User.CompanyId)
      .subscribe({
        next: (data: ICompany) => {
          if (data) {
            this.user.User.Company = Object.assign({}, data);
            this.userProfile.Company = Object.assign({}, data);
          }
          this.loading = false;
        },
        error: (error) => {
          this.alertService.warn('An error occurred while loading company data: ' + error.Message);
          this.loading = false;
        }
      });
  }

  ///////////////////////////////
  ///// Profile Information /////
  ///////////////////////////////
  getProfilePicture() {
    if (this.userProfile.ProfilePicture && !this.picture) {
      this.fileService.GetFileBlobUrl(this.env.ContainerNames.UserProfilePictures, this.getFullPictureName())
        .subscribe({
          next: (data) => {
            if (data) {
              this.picture = data;
            }
          }, error: (err) => {
            this.log.error('Failed to get profile picture ', this.logger, 'getProfilePicture', err);
            this.picture = null;
          }
        });
    }
  }

  onProfilePictureChange(fileUrl: string) {
    this.picture = null;
    this.user.User.ProfilePicture = fileUrl;
    this.alertService.info('Profile picture successfully updated!');
    this.saveProfile();
  }

  onProfilePictureDeleted() {
    this.picture = null;
    this.user.User.ProfilePicture = null;
    this.auth.CurrentUser.User.ProfilePicture = null;
    this.alertService.info('Profile picture successfully deleted!');
    this.saveProfile();
  }

  getFullPictureName() {
    const pictureName = this.userProfile.ProfilePicture.substring(
      this.userProfile.ProfilePicture.lastIndexOf('/') + 1);
    // Includes sub-folder of the userId
    return `${this.userProfile.Id}/${pictureName}`;
  }

  setDefaultCompany() {
    if (this.user.User && this.user.User.CompanyId) {
      this.userProfile.CompanyId = this.user.User.CompanyId;
      this.loadCompanyData();
      this.getUserRoles(this.user.User.CompanyId);
    }
  }

  setContactNumber(event: any) {
    console.log(event);
    this.user.User.ContactNumber = event;
    this.userProfile.ContactNumber = event;
  }

  saveProfile() {
    this.loading = true;
    this.userProfile = this.user.User;
    this.auth.UpdateUser(this.userProfile)
      .subscribe({
        next: (data: IUser) => {
          if (data) {
            this.auth.LoadUserPermissions(data.Username);
            this.alertService.success('Profile information updated successfully.');
          }
          this.loading = false;
        },
        error: () => {
          this.alertService.warn('An error occurred while updating user info.');
          this.loading = false;
        }
      });
  }

  ////////////////////////////
  ///// Linked Companies /////
  ////////////////////////////
  getUserRoles(companyId: number) {
    this.activeRoles = [];
    this.auth.GetUserRoles(this.user.UserId, companyId)
      .subscribe({
        next: (data) => {
          if (data) {
            this.activeRoles = Object.assign([], data);
          }
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        }
      });
  }

  selectUserCompany(userCompany: IAuthUserCompany) {
    this.selectedCompany = userCompany;
    this.getUserRoles(this.selectedCompany.CompanyId);
  }

  getUserCompanies() {
    this.auth.GetUserCompanies(this.user.UserId).subscribe({
      next: (data: IAuthUserCompany[]) => {
        this.userCompanies = Object.assign([], data);
        const company = this.userCompanies.find(x => x.CompanyId === this.user.User.CompanyId);
        if (company) {
          this.selectUserCompany(company);
        }
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  ///////////////////////////
  //////// Security /////////
  ///////////////////////////
  resetPassword() {
    const message = 'Are you sure you want to reset your password?';
    OpenConfirmationModal(this.modalService, message)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((answer: boolean) => {
        if (answer) {
          this.msalService.loginPopup({
            ...this.msalGuardConfig.authRequest,
            authority: environment.b2cPolicies.authorities.forgotPassword.authority,
            redirectUri: environment.b2cPolicies.redirectUrl
          } as PopupRequest).subscribe((response: AuthenticationResult) => {
            this.msalService.instance.setActiveAccount(response.account);
            this.auth.SetLoggedInStatus();
            this.alertService.success('Password has been reset successfully.');
          });
        }
      });
  }
}
