<div class="row header-row mt-4">
  <div class="col-3 header-title text-start border-split-left">
    Tariff Type
  </div>
  <div class="col-2 header-title text-center">
    Quantity
  </div>
  <div class="col-7 header-title text-end border-split-right">
    Total
  </div>
</div>
<app-loader *ngIf="loading" class="m-2"></app-loader>
<div *ngIf="tenderService.MultiLaneQuote">
  <div class="row result-row quote-line" *ngFor="let quote of getQuoteItems()">
    <div class="col-4 text-start">
      <b class="mb-0">
        {{ quote.Description }}
      </b>
    </div>
    <div class="col-1 offset-6 text-center">
        x {{ quote.Quantity }}
    </div>
    <div class="col-1">
      <b>EUR</b> <span class="float-end">{{ quote.Amount | number:'1.2-2' }}</span>
    </div>
  </div>
</div>
<div class="row ms-1 me-1 quote-total-row">
  <div class="col-10 align-self-center text-start">
    <span class="py-2 fs-4">Total: </span>
  </div>
  <div class="col-2 align-self-center text-end">
    <span class="py-2 fs-5"><b class="fs-6">EUR</b> {{ getTotal() | number:'1.2-2' }}</span>
  </div>
</div>
<div class="row mt-5 mb-5">
  <div class="col-md-12 text-center">
    <button class="btn btn-lg btn-outline-success harmony-green-outline-button w-25"
      (click)="acceptQuote()">
      Accept Quote
    </button>
  </div>
</div>
