import { Component, OnDestroy, OnInit } from '@angular/core';
import { IPage, IPaginationData } from '../../../shared/models/pagination-data.model';
import {
  faBoxOpen, faCircleCheck, faCircleXmark, faCodeCompare,
  faCodePullRequest, faNewspaper,
  faPencil, faSortAsc, faTrash
} from '@fortawesome/free-solid-svg-icons';
import { environment } from '../../../../environments/environment';
import { IArticleOverview, IArticleSearchParam } from '../../../services/models/news/article.model';
import { ArticleService } from '../../../services/article.service';
import { Router } from '@angular/router';
import { OpenConfirmationModal } from '../../../shared/components/confirmation-modal/confirmation-modal-functions';
import { takeUntil } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { IAuthUser } from '../../../services/models/auth.model';
import { AdAuthService } from '../../../core/ad-auth-service/ad-auth.service';
import { NewsRoutes } from 'src/app/core/constants/routes';
import { PermissionCodes } from '../../../core/constants/permission-codes';
import { IOrderParam } from '../../../shared/directives/sort/order.directive';

@Component({
  selector: 'app-article-management',
  templateUrl: './article-management.component.html',
  styleUrls: ['./article-management.component.scss']
})
export class ArticleManagementComponent implements OnInit, OnDestroy {
  // Icons
  faEdit = faPencil;
  faRead = faNewspaper;
  faEmpty = faBoxOpen;
  faDelete = faTrash;
  faCheck = faCircleCheck;
  faReview = faCodePullRequest;
  faCompare = faCodeCompare;
  faSortUp = faSortAsc;
  faNotFeatured = faCircleXmark;

  // Component variables
  userId: number;
  imageBaseUrl: string = '';
  placeholderImage: string;
  articleStatuses$ = this.articleService.Statuses$.asObservable();

  // Shared Components
  paginationData: IPaginationData<IArticleOverview> = null;
  searchParam: IArticleSearchParam;
  order: IOrderParam;
  page: IPage;
  noResult: boolean = false;
  loading: boolean = true;

  // Permissions
  canApprove: boolean = false;
  canDelete: boolean = false;

  // General variables
  private unsubscribe: Subject<any> = new Subject<any>();
  protected readonly env = environment;

  constructor(private articleService: ArticleService,
              private router: Router,
              private modalService: NgbModal,
              private auth: AdAuthService) {
  }

  ngOnInit() {
    this.imageBaseUrl = `${this.env.SiteUrls.AzureStorageBaseURL}${this.env.ContainerNames.ArticleImages}/`;
    this.placeholderImage = `${this.env.SiteUrls.AzureStorageBaseURL}${environment.SiteUrls.NewsImagePlaceHolderUrl}`;

    if (this.auth.CurrentUser) {
      this.userId = this.auth.CurrentUser.User.Id;
      this.setPermissions();
    }

    this.auth.CurrentUser$
      .subscribe((user: IAuthUser) => {
        this.userId = user.User.Id;
        this.setPermissions();
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  setPermissions() {
    this.canApprove = this.auth.CheckPermissionByCode(PermissionCodes.News_ApproveArticles);
    this.canDelete = this.auth.CheckPermissionByCode(PermissionCodes.News_DeleteArticles);

    this.initPage();
  }

  initPage() {
    this.page = {
      pageNumber: 1,
      pageSize: 30,
      batched: false
    } as IPage;

    this.order = {
      OrderBy: 'DateCreated',
      OrderDirection: 'desc'
    } as IOrderParam;

    this.searchParam = {
      SearchText: null,
      Featured: null,
      Website: null,
      ActiveOnly: false,
      Status: null,
      CompanyId: null,
      UserId: this.canApprove === true ? null : this.auth.CurrentUser.UserId
    } as IArticleSearchParam;

    this.getArticles();
  }

  onOrderChange(param: IOrderParam) {
    this.order = param;
    this.search();
  }

  onPageChange($event: IPage) {
    this.page = $event;
    this.getArticles();
  }

  resetSearch() {
    this.initPage();
  }

  search() {
    this.page.pageNumber = 1;
    this.getArticles();
  }

  private getArticles() {
    this.loading = true;

    this.articleService.GetArticles(this.page, this.order, this.searchParam)
      .subscribe({
        next: (data) => {
          this.loading = false;
          this.paginationData = {...data};
          this.page.pageNumber = this.paginationData.CurrentPage;
          this.noResult = this.paginationData.DataSet.length === 0;
        }, error: () => {
          this.paginationData = null;
          this.noResult = true;
          this.loading = false;
        }
      });
  }

  redirectReadArticle(articleId: number) {
    this.router.navigate([`${NewsRoutes.Article_Read}/${articleId}`])
      .then(() => {
      });
  }

  redirectArticleEditor(articleId: number) {
    this.router.navigate([`${NewsRoutes.Article_Editor}/${articleId}`])
      .then(() => {
      });
  }

  redirectArticleReview(articleId: number) {
    this.router.navigate([`${NewsRoutes.Article_Review}/${articleId}`])
      .then(() => {
      });
  }

  redirectArticleChangeReview(articleId: number) {
    this.router.navigate([`${NewsRoutes.Article_ReviewCompare}/${articleId}`])
      .then(() => {
      });
  }

  deleteArticle(id: number) {
    this.loading = true;
    const message = 'You are about to permanently delete the article, this cannot be undone. Do you want to continue?';
    OpenConfirmationModal(this.modalService, message)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((answer: boolean) => {
        if (answer) {
          this.articleService.delete(id)
            .subscribe(() => {
              this.getArticles();
            });
        }
      });
  }
}
