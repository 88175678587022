import {Component, OnDestroy, OnInit} from '@angular/core';
import { ICompanyOverview } from '../../../services/models/member.model';
import { Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ApiService } from '../../../services/api.service';
import { takeUntil } from 'rxjs/operators';
import {faCircleDot, faEye, faFileExcel, faFilter} from '@fortawesome/free-solid-svg-icons';
import {FileService} from '../../../services/file.service';
import {AlertService} from '../../../services/alert.service';
import {AdAuthService} from '../../../core/ad-auth-service/ad-auth.service';
import {PermissionCodes} from '../../../core/constants/permission-codes';

@Component({
  selector: 'app-member-search',
  templateUrl: './member-search.component.html',
  styleUrls: ['./member-search.component.scss']
})
export class MemberSearchComponent implements OnInit, OnDestroy {
  // Icons
  protected readonly faView = faEye;
  protected readonly faMenuItem = faCircleDot;
  protected readonly faExcel = faFileExcel;
  protected readonly faFilter = faFilter;

  // Component Variables
  companyData: ICompanyOverview[];
  mapData: ICompanyOverview[];
  zoom: number = 2.5;
  centerLat: number = 15;
  centerLong: number = 0;
  searchText: string = null;
  canExport: boolean = false;

  // General variables
  loading: boolean = false;
  loadingExportData: boolean = false;
  private unsubscribe: Subject<any> = new Subject<any>();
  env = environment;
  movingServicesIsSelected: boolean = false;
  relocationServicesIsSelected: boolean = false;
  movingAndRelocationServicesIsSelected: boolean = false;

  constructor(private apiService: ApiService, private fileService: FileService,
              private alertService: AlertService, private authService: AdAuthService) {
  }

  ngOnInit(): void {
    this.loadMarkers();
    if (this.authService.CurrentUser) {
      this.canExport = this.authService.CheckPermissionByCode(PermissionCodes.Network_MemberExport);
    }
    // Subscribe to user to always check if something changes
    this.authService.CurrentUser$.subscribe(() => {
      this.canExport = this.authService.CheckPermissionByCode(PermissionCodes.Network_MemberExport);
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  loadMarkers() {
    this.loading = true;

    this.apiService.get('Map/GetData').pipe(
      takeUntil(this.unsubscribe)
    ).subscribe((data: ICompanyOverview) => {
      if (data) {
        this.companyData = Object.assign([], data);
        this.mapData = [...this.companyData];
      }
      this.loading = false;
    });
  }

  searchMembers() {
    this.zoom = 2.5;
    this.setCenter(15, 0);
    const s = this.searchText.toLowerCase();

    if (s && s.length > 0) {
      const result = this.companyData.filter(x => x.Name.toLowerCase().includes(s) ||
        x.Country.toLowerCase().includes(s) ||
        x.City.toLowerCase().includes(s));
      if (result && result.length > 0) {
        this.mapData = [...result];
      } else {
        this.mapData = [];
      }
    } else {
      this.resetMap();
      this.searchText = null;
    }
  }

  goToMemberLocation(member: ICompanyOverview) {
    this.setCenter(member.Latitude, member.Longitude);
    this.zoom = 6;
  }

  resetMap() {
    this.mapData = [...this.companyData];
  }

  getCountryCount() {
    const array = this.companyData;

    if (array && array.length > 0) {
      const unique = [...new Set(array.map(item => item.Country))];
      return unique.length;
    }

    return '-';
  }

  setCenter(lat: number, long: number) {
    this.centerLat = lat;
    this.centerLong = long;
  }

  filterBy(category: string) {
    if (category === 'Moving Services') {
      this.movingServicesIsSelected = true;
      this.relocationServicesIsSelected = this.movingAndRelocationServicesIsSelected = false;
      if (this.movingServicesIsSelected) {
        this.mapData = this.companyData.filter((companyOverview: ICompanyOverview ) => companyOverview.IsMovingServiceProvider &&
          !companyOverview.IsRelocationServiceProvider);
      }
    }
    if (category === 'Relocation Services') {
      this.relocationServicesIsSelected = true;
      this.movingServicesIsSelected = this.movingAndRelocationServicesIsSelected = false;
      if (this.relocationServicesIsSelected) {
        this.mapData = this.companyData.filter((companyOverview: ICompanyOverview ) => companyOverview.IsRelocationServiceProvider &&
          !companyOverview.IsMovingServiceProvider);
      }
    }
    if (category === 'Moving and Relocation Services') {
      this.movingAndRelocationServicesIsSelected = true;
      this.movingServicesIsSelected = this.relocationServicesIsSelected = false;
      if (this.movingAndRelocationServicesIsSelected) {
        this.mapData = this.companyData.filter((companyOverview: ICompanyOverview ) => companyOverview.IsMovingServiceProvider &&
          companyOverview.IsRelocationServiceProvider);
      }
    }
  }

  exportMembersToExcel() {
    this.loadingExportData = true;
    this.apiService.getFile('Company/ExportData').pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (response) => {
        const fileName = this.fileService.GetFileName(response);
        this.fileService.DownloadFile(response, fileName);
        this.loadingExportData = false;
      },
      error: () => {
        this.alertService.error('An error occurred while trying to export to Excel.');
        this.loadingExportData = false;
      }
    });
  }

  clearFilter() {
      this.resetMap();
      this.movingServicesIsSelected = this.relocationServicesIsSelected = this.movingAndRelocationServicesIsSelected = false;
  }
}
