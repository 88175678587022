<div class="row">
  <div class="col-4">
    <div class="row mt-1">
      <div class="col-6">
        <b>Request Type</b>
      </div>
      <div class="col-6">
        <select class="form-select form-select-sm" id="seaRatesType" [(ngModel)]="selectedRateType">
          <option *ngFor="let t of ratesTypes | keyvalue" [ngValue]="t.key">{{t.key}}</option>
        </select>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-6">
        <b>Freight Only</b>
      </div>
      <div class="col-6">
        <div class="form-check form-switch form-switch-md float-end">
          <input class="form-check-input pointer"
                 type="checkbox"
                 role="switch"
                 [id]="'switch-freight-only'"
                 [title]="'Select to only query freights'"
                 [(ngModel)]="freightOnly">
        </div>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-6">
        <b>Container Type</b>
      </div>
      <div class="col-6">
        <select class="form-select form-select-sm"
                [(ngModel)]="selectedContainerTypes" [multiple]="true">
          <option *ngFor="let t of containerTypes | keyvalue" [ngValue]="t.key">{{t.key}}</option>
        </select>
      </div>
    </div>
  </div>
  <div class="col-4">
    <div class="row mt-1">
      <div class="col-4">
        <b>Origin</b>
      </div>
      <div class="col-8">
        <app-city-search (OnSelectCityObject)="setFromCity($event)"></app-city-search>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-4">
        <b>Destination</b>
      </div>
      <div class="col-8">
        <app-city-search (OnSelectCityObject)="setToCity($event)"></app-city-search>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-4">
        <b>Ready to load</b>
      </div>
      <div class="col-8">
        <app-date-picker [identifier]="'inputFromDateFlc'" (onChange)="fclDate = $event"
                         [title]="'Select a date'"></app-date-picker>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-4">
        <b>Currency</b>
      </div>
      <div class="col-8">
        <select class="form-select form-select-sm pointer"
                [disabled]="!(Currencies$ | async)"
                [(ngModel)]="currency">
          <option *ngFor="let currency of (Currencies$ | async)"
                  [ngValue]="currency.Currency.Code">{{ currency.Currency.Code }}</option>
        </select>
      </div>
    </div>
  </div>
  <div class="col-4">
    <div class="row mt-1">
      <div class="col-3">
        <b>Coordinates</b>
      </div>
      <div class="col-9">
        <input class="form-control form-control-sm" type="text"
               [ngClass]="{'attention-required': !from && isInvalid}"
               [(ngModel)]="from">
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-3">
        <b>Coordinates</b>
      </div>
      <div class="col-9">
        <input class="form-control form-control-sm" type="text"
               [ngClass]="{'attention-required': !to && isInvalid }"
               [(ngModel)]="to">
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-6 offset-6">
        <button class="btn btn-sm btn-primary harmony-primary-button w-100"
                (click)="importSeaRates()">
          Import
        </button>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-6 offset-6">
        <button class="btn btn-sm btn-primary harmony-tertiary-button w-100"
                (click)="getFlcRates()">
          Search
        </button>
      </div>
    </div>
  </div>
</div>
<hr>
<!----- Table Header ----->
<div class="row header-row mt-2">
  <div class="col-3 header-title border-split-left">
    City Origin
  </div>
  <div class="col-1 header-title">
    Port Origin
  </div>
  <div class="col-3 header-title">
    City Destination
  </div>
  <div class="col-1 header-title">
    Port Dest.
  </div>
  <div class="col-1 header-title">
    Valid To
  </div>
  <div class="col-2 header-title">
    Price
  </div>
  <div class="col-1 header-title border-split-right text-end">
  </div>
</div>
<app-loader *ngIf="loading"></app-loader>
<app-no-result [showNoResults]="noResultFcl"></app-no-result>
<!----- Table Data ----->
<div class="row p-1" *ngFor='let rate of fclResponse?.data?.shipment; let i = index'>
  <div class="col-12">
    <div class="row result-row">
      <div class="col-3 overflow-hidden">
        <div class="freight-logo-box">
          <img src="{{rate.freight[0].logo}}" alt="freight logo" class="freight-logo">
          {{ rate.cityFrom?.name }}
        </div>
      </div>
      <div class="col-1 overflow-hidden align-self-center">
        <b>{{ rate.portFrom.code}}</b>
      </div>
      <div class="col-3 overflow-hidden align-self-center">
        {{ rate.cityTo?.name }}
      </div>
      <div class="col-1 overflow-hidden align-self-center">
        <b>{{ rate.portTo.code}}</b>
      </div>
      <div class="col-1 overflow-hidden align-self-center">
        {{ rate.freight[0].validTo | date: 'dd-MM-yyyy'}}
      </div>
      <div class="col-1 overflow-hidden align-self-center">
        {{ getFullFclPrice(rate.freight) }} {{ rate.currency }}
      </div>
      <div class="col-2 overflow-hidden align-self-center">
        <button class="btn btn-sm btn-outline-light harmony-primary-outline-button float-end ms-1"
                (click)="jsonViewRate(rate)" [title]="'View entire Rate as JSON'">
          <b>{{ '{ }' }}</b>
        </button>
        <button class="btn btn-sm btn-outline-light harmony-primary-outline-button float-end ms-1"
                (click)="showSelectedRateInfo(i)" [title]="'View Rates'">
          <fa-icon [icon]="faEye"></fa-icon>
        </button>
        <div class="form-check form-switch form-switch-md">
          <input class="form-check-input float-end" type="checkbox" role="switch" [id]="'switch-' + i"
                 [title]="'Select rate to be imported'" [checked]="rate.selected"
                 (change)="selectRate(rate, $event)">
        </div>
      </div>
    </div>
  </div>
  <div class="col-12" *ngIf="rate.showSection">
    <div class="col-12" *ngIf="rate.showSection">
      <div class="row mt-1">
        <div class="col-3 header-title-secondary border-split-left">
          Service Type
        </div>
        <div class="col-7 header-title-secondary">
          <div class="row">
            <div class="col-9 header-title-secondary">
              Information
            </div>
            <div class="col-3 header-title-secondary">
              Price
            </div>
          </div>
        </div>
        <div class="col-2 header-title-secondary border-split-right">
          CO2 (kg)
          <button class="btn btn-sm harmony-tertiary-button float-end"
                  (click)="showSelectedRateInfo(i)" [title]="'Close'">
            <fa-icon [icon]="faExit" class="text-white"></fa-icon>
          </button>
        </div>
      </div>
      <div class="col-12">
        <div class="row" *ngFor='let freight of rate.freight; let i = index'>
          <div class="col-12">
            <div class="row mb-2 result-row" *ngIf="freight.truckFrom?.price">
              <div class="col-3">
                <fa-icon [icon]="faTruck" class="text-danger"></fa-icon>
                Truck
              </div>
              <div class="col-7">
                <div class="row">
                  <div class="col-9">
                    {{ freight.truckFrom.distance}}
                  </div>
                  <div class="col-3">
                    <b>{{ freight.truckFrom.price}} {{ rate.currency }}</b>
                  </div>
                </div>
              </div>
              <div class="col-2">
                <b>{{ freight.truckFrom.co2 / 1000 | number: '1.0-0' }} </b>
                <fa-icon [icon]="faSeed" class="light-green"></fa-icon>
              </div>
            </div>
            <div class="row mb-2 result-row" *ngIf="freight.railFrom?.price">
              <div class="col-3">
                <fa-icon [icon]="faTrain" class="text-danger"></fa-icon>
                Rail
              </div>
              <div class="col-7">
                <div class="row">
                  <div class="col-3 offset-9">
                    <b>{{ freight.railFrom.price }} {{ rate.currency }}</b>
                  </div>
                </div>
              </div>
              <div class="col-2">
                <b>{{ freight.railFrom.co2 / 1000  | number: '1.0-0'}} </b>
                <fa-icon [icon]="faSeed" class="light-green"></fa-icon>
              </div>
            </div>
            <div class="row result-row" *ngIf="freight.portFeesFrom?.length >0">
              <div class="col-3 align-self-center">
                <fa-icon [icon]="faAnchor" class="harmony-secondary-text"></fa-icon>
                Port Origin
              </div>
              <div class="col-7">
                <div class="row" *ngFor='let portFrom of freight.portFeesFrom'>
                  <div class="col-9">
                    <b>{{ portFrom.abbr}}</b> - {{ portFrom.title}}
                  </div>
                  <div class="col-3">
                    <b>{{ portFrom.price}} {{ rate.currency }}</b>
                    <span *ngIf="portFrom.perLot"> / per lot</span>
                  </div>
                </div>
              </div>
              <div class="col-2 align-self-center">
                <b>{{ freight.portFeesFrom[0].co2 / 1000  | number: '1.0-0'}} </b>
                <fa-icon [icon]="faSeed" class="light-green"></fa-icon>
              </div>
            </div>
            <div class="row result-row mb-2 mt-2">
              <div class="col-3">
                <fa-icon [icon]="faShip" class="harmony-tertiary-text"></fa-icon>
                Freight
              </div>
              <div class="col-7">
                <div class="row">
                  <div class="col-9">
                    <b>{{ freight.containerType }}</b>
                  </div>
                  <div class="col-3 align-self-center">
                    <b>{{ freight.price }} {{ rate.currency }}</b>
                  </div>
                </div>
              </div>
              <div class="col-2 align-self-center">
                <b>{{ freight.co2 / 1000 | number: '1.0-0' }} </b>
                <fa-icon [icon]="faSeed" class="light-green"></fa-icon>
              </div>
            </div>
            <div class="row result-row" *ngIf="freight.portFeesTo?.length >0">
              <div class="col-3 align-self-center">
                <fa-icon [icon]="faAnchor" class="harmony-secondary-text"></fa-icon>
                Port Destination
              </div>
              <div class="col-7">
                <div class="row" *ngFor='let portTo of freight.portFeesTo'>
                  <div class="col-9">
                    <b>{{ portTo.abbr }}</b> - {{ portTo.title}}
                  </div>
                  <div class="col-3">
                    <b>{{portTo.price}} {{ rate.currency }}</b>
                    <span *ngIf="portTo.perLot"> / per lot</span>
                  </div>
                </div>
              </div>
              <div class="col-2 align-self-center">
                <b>{{ freight.portFeesTo[0]?.co2 / 1000  | number: '1.0-0'}} </b>
                <fa-icon [icon]="faSeed" class="light-green"></fa-icon>
              </div>
            </div>
            <div class="row result-row mb-2 mt-2" *ngIf="freight.truckTo?.price">
              <div class="col-3">
                <fa-icon [icon]="faTruck" class="text-danger"></fa-icon>
                Truck
              </div>
              <div class="col-7">
                <div class="row">
                  <div class="col-9">
                    {{ freight.truckTo.distance }}
                  </div>
                  <div class="col-3">
                    <b>{{ freight.truckTo.price}} {{ rate.currency }}</b>
                  </div>
                </div>
              </div>
              <div class="col-2">
                <b>{{ freight.truckTo.co2 / 1000  | number: '1.0-0'}} </b>
                <fa-icon [icon]="faSeed" class="light-green"></fa-icon>
              </div>
            </div>
            <div class="row result-row" *ngIf="freight.railTo?.price">
              <div class="col-3">
                <fa-icon [icon]="faTrain" class="text-danger"></fa-icon>
                Rail
              </div>
              <div class="col-7">
                <div class="row">
                  <div class="col-3 offset-9">
                    <b>{{ freight.railTo.price }}  {{ rate.currency }}</b>
                  </div>
                </div>
              </div>
              <div class="col-2">
                <b>{{ freight.railTo.co2 / 1000  | number: '1.0-0'}} </b>
                <fa-icon [icon]="faSeed" class="light-green"></fa-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
