<!---------------------->
<!----- Header Bar ----->
<!---------------------->
<div class="flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 border-bottom">
  <div class="row align-items-center">
    <div class="col-sm-12 col-md-6 col-lg-4">
      <h1 class="portal-component-header">
        Failed Rate Mapping
      </h1>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-8">
      <div class="btn-toolbar mb-2 mb-md-0 justify-content-end">
        <button type="button"
                class="btn btn-sm btn-outline-primary harmony-primary-outline-button me-2"
                (click)="refresh()"
                [title]="'Refresh list'">
          <fa-icon [icon]="faRefresh"></fa-icon>
        </button>
<!--        <div class="btn-group me-2" *ngIf="causes.length > 0">-->
<!--          <button type="button" class="btn btn-outline-dark">{{activeCause.Code}}</button>-->
<!--          <button type="button" class="btn btn-outline-dark dropdown-toggle dropdown-toggle-split" id="causeDropDown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
<!--            <span class="sr-only">Toggle Dropdown</span>-->
<!--          </button>-->
<!--          <div class="dropdown-menu" aria-labelledby="causeDropDown">-->
<!--            <a *ngFor="let cause of causes" class="dropdown-item" (click)="setActiveCause(cause)">{{cause.Name}}</a>-->
<!--          </div>-->
<!--        </div>-->
        <div class="btn-group me-2">
          <button type="button" class="btn btn-sm btn-outline-primary harmony-tertiary-outline-button" [disabled]="!hasChanges()" (click)="apply()">Apply</button>
        </div>
        <div class="btn-group me-2">
          <button type="button" class="btn btn-sm btn-primary harmony-tertiary-button" (click)="processRates()">Retry processing</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!---------------------->
<!---- Content Area ---->
<!---------------------->
<div class="flex-wrap flex-md-nowrap align-items-center pt-2 pb-0">
  <div class="row">
    <div class="col-4 text-start">
      <div class="row mt-1">
        <div class="col-4">
          Project
        </div>
        <div class="col-8">
          <select class="form-select form-select-sm pointer"
                  [disabled]="!(projects$ | async)"
                  (ngModelChange)="setActiveProject($event)"
                  [(ngModel)]="activeProject">
            <option [ngValue]="null" selected>Select a project...</option>
            <option *ngFor="let project of (projects$ | async)" [ngValue]="project">{{ project.Name }}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-8 text-end">
      <b>Count:</b> {{failedRates.length}}
      <br>
      <b>Updated:</b> {{getUpdatedCount()}}
    </div>
  </div>
  <hr class="mt-1">
  <div class="row header-row">
    <div class="col-2 header-title border-split-left">
      Partner Value
    </div>
    <div class="col-3 header-title">
      Reason for Failing
    </div>
    <div class="col-2 header-title">
      Map From
    </div>
    <div class="col-1 header-title">
      Map To
    </div>
    <div class="col-1 header-title text-center">
      Ignore
    </div>
    <div class="col-3 header-title border-split-right">
      Search for correct option
    </div>
  </div>
  <app-loader *ngIf="loading" class="m-3"></app-loader>
  <div class="row result-row border-bottom" *ngFor="let failure of failedRates; let i = index">
    <div class="col-2 failed-item overflow-hidden" data-bs-toggle="tooltip" title="{{failure.FailedValue}}" [ngClass]="{
      'map-changed': failure.MapToValue !== null || failure.NoMapping == true,
      'map-success': (failure.MapToValue !== null || failure.NoMapping !== null) && failure.UpdateSuccess === true,
      'map-failed': (failure.MapToValue !== null || failure.NoMapping !== null) && failure.UpdateSuccess === false
      }">
      <b>{{failure.FailedValue}}</b>
    </div>
    <div class="col-3 failed-item overflow-hidden" data-bs-toggle="tooltip" title="{{failure.Problem}}">
      <small>{{failure.Problem}}</small>
    </div>
    <div class="col-2 failed-item overflow-hidden" data-bs-toggle="tooltip" title="{{failure.ProblemTableColumn}}">
      <small>{{failure.ProblemTableColumn}}</small>
    </div>
    <div class="col-1 failed-item overflow-hidden" data-bs-toggle="tooltip" title="{{failure.MapToColumn}}">
      <small>{{failure.MapToColumn}}</small>
    </div>
    <div class="col-1 failed-item text-center">
      <label class="form-check-label">
        <input type="checkbox" [(ngModel)]="failure.NoMapping">
      </label>
    </div>
    <div class="col-3 failed-item d-inline">
      <app-rate-mapping-search
        class="d-inline"
        [TableName]="failure.MapToTable"
        [SearchLabel]="'Search for ' + failure.MapToTable + ' (' + (failure.MapToValue === null ? '-' : failure.MapToValue) + ')'"
        [Identifier]="i.toString()"
        (OnSelect)="failure.MapToValue = $event">
      </app-rate-mapping-search>
    </div>
  </div>
</div>
