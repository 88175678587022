import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { faEdit, faSortDesc, faSyncAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Subject, takeUntil } from 'rxjs';
import { AdAuthService } from '../../../../core/ad-auth-service/ad-auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IPermission, IPermissionParam } from '../../../../services/models/auth.model';
import { PermissionModalComponent } from './permission-modal/permission-modal.component';
import { OpenConfirmationModal } from '../../../../shared/components/confirmation-modal/confirmation-modal-functions';
import { IPage, IPaginationData } from '../../../../shared/models/pagination-data.model';
import { IOrderParam } from '../../../../shared/directives/sort/order.directive';

@Component({
  selector: 'app-permission-management',
  templateUrl: './permission-management.component.html',
  styleUrls: ['./permission-management.component.scss']
})
export class PermissionManagementComponent implements OnInit, OnDestroy {
  // Icons
  protected readonly faEditIcon = faEdit;
  protected readonly faDelete = faTrash;
  protected readonly faRefresh = faSyncAlt;
  protected readonly faSortDown = faSortDesc;

  // Component variables
  paginationData: IPaginationData<IPermission> = null;
  searchParam: IPermissionParam;
  order: IOrderParam;
  page: IPage;

  // Lookup data
  modules$ = this.authService.Modules$.asObservable();

  // General variables
  private unsubscribe: Subject<any> = new Subject<any>();
  environment = environment;
  loading: boolean = false;

  constructor(public authService: AdAuthService,
              private modalService: NgbModal,
              private ref: ChangeDetectorRef) { }

  ngOnInit() {
    this.initPage();
    this.ref.markForCheck();
  }

  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  initPage() {
    this.page = {
      pageNumber: 1,
      pageSize: 30,
      batched: false
    } as IPage;

    this.order = {
      OrderBy: 'Name',
      OrderDirection: 'asc'
    } as IOrderParam;

    this.searchParam = {
      Active: true,
      SearchText: null,
      ModuleId: null
    } as IPermissionParam;

    this.searchPermissions();
  }

  onOrderChange(param: IOrderParam) {
    this.order = param;
    this.search();
  }

  onPageChange($event: IPage) {
    this.page = $event;
    this.searchPermissions();
  }

  resetSearch() {
    this.initPage();
  }

  search() {
    this.page.pageNumber = 1;
    this.searchPermissions();
  }

  private searchPermissions() {
    this.loading = true;

    this.authService.SearchPermissions(this.page, this.order, this.searchParam)
      .subscribe({
        next: (data: IPaginationData<IPermission>) => {
          this.paginationData = Object.assign({}, data);
          this.page.pageNumber = this.paginationData.CurrentPage;
          this.ref.markForCheck();
          this.loading = false;
        }, error: () => {
          this.paginationData = null;
          this.loading = false;
        }
      });
  }

  managePermission(componentId: number, isNew: boolean) {
    // Open modal to Add/Edit a module
    const modalRef = this.modalService.open(PermissionModalComponent, {size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.IsNew = isNew;
    modalRef.componentInstance.ComponentId = componentId;
    // On modal close, read the result and apply logic
    modalRef.result.then((result: IPermission) => {
      if (result) {
        this.authService.LoadPermissions();
      }
      this.ref.markForCheck();
    }, () => {
      this.ref.markForCheck();
    });
  }

  deletePermission(id: number) {
    const message = 'Deleting the permission cannot be undone and will remove all references to it.\nWould you like to continue?';
    OpenConfirmationModal(this.modalService, message)
      .pipe(takeUntil(this.unsubscribe)
      ).subscribe((answer: boolean) => {
      if (answer) {
        this.authService.DeletePermission(id);
      }
    });
  }
}
