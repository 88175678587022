import { Component } from '@angular/core';
import { DocumentService } from '../../../services/document.service';

@Component({
  selector: 'app-hse-documents',
  templateUrl: './hse-documents.component.html',
  styleUrls: ['./hse-documents.component.scss']
})
export class HseDocumentsComponent {

  // Component variables
  documentCategories$ = this.documentService.documentCategories$.asObservable();

  // General Variables
  loading: boolean;
  noResult: boolean = true;

  constructor(private documentService: DocumentService) { }
}
