import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../core/guards/auth-guard.guard';
import { PermissionCodes } from '../../core/constants/permission-codes';
import { QualityManualComponent } from './quality-manual/quality-manual.component';

const routes: Routes = [
  {
    path: 'manual',
    component: QualityManualComponent,
    canActivate: [AuthGuard],
    data: {
      ExpectedPermission: PermissionCodes.Qms_QualityManual
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class QmsRoutingModule { }
