import { NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { environment } from '../../../environments/environment';

// This defines how the cookie consent pop-up looks
export const CookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: environment.domain
  },
  position: 'bottom-left',
  theme: 'classic',
  palette: {
    popup: {
      background: '#8a8a8a',
      text: '#ffffff',
      link: '#ffffff'
    },
    button: {
      background: '#A22B9D',
      text: '#fff',
      border: 'transparent'
    },
    highlight: {
      background: '#8a8a8a',
      border: '#8a8a8a',
      text: '#fff'
    }
  },
  type: 'opt-in',

  layout: 'my-custom-layout',
  layouts: {
    'my-custom-layout': '{{messagelink}}{{compliance}}'
  },
  elements: {
    messagelink: `
    <span id="cookieconsent:desc" class="cc-message">{{message}}
      <a aria-label="learn more about cookies" tabindex="0" class="cc-link" href="{{cookiePolicyHref}}" target="_blank">{{cookiePolicyLink}}</a>
    </span>
    `,
    allow: `
    <div class="cc-btn cc-allow" >
        <a>Accept</a>
    </div>
    `,
    deny: `
     <div class="cc-btn cc-deny" style="text-decoration: none !important;">
        <a>Decline</a>
    </div>
    `
  },
  content: {
    message: 'This website uses cookies to ensure you get the best experience on our website.',
    cookiePolicyLink: 'Learn more',
    cookiePolicyHref: '/policies/cookies',
  }
};
