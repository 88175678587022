import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { PricingService } from '../../../../services/pricing.service';
import {
  ICloneRateGroupParam,
  IRateGroup
} from '../../../../services/models/pricing/rates.model';
import { AlertService } from '../../../../services/alert.service';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from '../../../../services/api.service';

@Component({
  selector: 'app-review-request-modal',
  templateUrl: './clone-rate-group-modal.component.html',
  styleUrls: ['./clone-rate-group-modal.component.scss']
})
export class CloneRateGroupModalComponent implements OnInit, OnDestroy {
  // Icons

  // Variables
  public RateGroupId: number;
  rateGroup: IRateGroup = null;
  cloneParam: ICloneRateGroupParam;
  rateDeviation: number = 0;
  invalid: boolean = false;

  // General variables
  private unsubscribe: Subject<any> = new Subject<any>();
  public loading: boolean;
  today: any = null;

  constructor(public activeModal: NgbActiveModal,
              public pricingService: PricingService,
              private alertService: AlertService,
              private api: ApiService) { }

  ngOnInit(): void {
    this.setToday();
    this.cloneParam = {
      RateGroupId: this.RateGroupId,
      ValidFrom: null,
      ValidTo: null,
      RateDeviation: 0
    };
    this.setRateGroup();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  setRateGroup() {
    this.loading = true;

    this.api.get(`RateGroup/Single/${this.RateGroupId}`).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data) => {
        if (data) {
          this.rateGroup = Object.assign({} as IRateGroup, data);
          if (data.ValidTo) {
            // Set valid FROM default date
            const newValidFromDate = new Date(data.ValidTo);
            newValidFromDate.setDate(newValidFromDate.getDate() + 1);
            this.cloneParam.ValidFrom = newValidFromDate.toDateString();
            // Set valid TO default date
            const newValidToDate = new Date(this.cloneParam.ValidFrom);
            newValidToDate.setFullYear(newValidToDate.getFullYear() + 1);
            const month = newValidToDate.getMonth();
            if (month < 3) {
              newValidToDate.setMonth(3);
            } else if (month < 6) {
              newValidToDate.setMonth(6);
            } else if (month < 9) {
              newValidToDate.setMonth(9);
            } else {
              newValidToDate.setMonth(12);
            }
            this.cloneParam.ValidTo = newValidToDate.toDateString();
          }
        }
        this.loading = false;
      },
      error: () => {
        this.alertService.error('Unable to retrieve data for rate group ID: ' + this.rateGroup.ID);
        this.loading = false;
      }
    });
  }

  setToday() {
    const now = new Date();
    this.today = {
      year: now.getFullYear(),
      month: now.getMonth() + 1,
      day: now.getDate()
    } as any;
  }

  canClone() {
    this.invalid = (this.cloneParam.RateGroupId == null ||
      this.cloneParam.ValidFrom == null ||
      this.cloneParam.ValidTo == null ||
      this.cloneParam.RateDeviation == null);

    return !this.invalid;
  }

  clone() {
    this.cloneParam.RateDeviation = (this.rateDeviation / 100);

    if (this.canClone()) {
      this.loading = true;
      this.pricingService.CloneRateGroup(this.cloneParam).subscribe({
        next: (data: IRateGroup) => {
          if (data) {
            this.alertService.success('Rate group successfully copied!');
            this.activeModal.close(true);
          }
          this.loading = false;
        },
        error: () => {
          this.alertService.error('Unable to copy rate group ID: ' + this.RateGroupId);
          this.loading = false;
        }
      });
    }
  }

  cancel() {
    this.activeModal.dismiss(null);
  }
}
