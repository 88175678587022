import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QualityManualComponent } from './quality-manual/quality-manual.component';
import { QmsMenuComponent } from './qms-menu/qms-menu.component';
import { DocumentListComponent } from '../../shared/components/document-list/document-list.component';
import { SharedModule } from '../../shared/shared.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterLink } from '@angular/router';
import { QmsRoutingModule } from './qms-routing.module';
import { DocumentCategoryListComponent } from '../../shared/components/document-category-list/document-category-list.component';

@NgModule({
  declarations: [
    QualityManualComponent,
    QmsMenuComponent
  ],
  exports: [
    QmsMenuComponent
  ],
  imports: [
      CommonModule,
      DocumentListComponent,
      SharedModule,
      FontAwesomeModule,
      RouterLink,
      QmsRoutingModule,
      DocumentCategoryListComponent
  ]
})
export class QmsModule { }
