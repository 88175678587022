
<div class="card-wrapper">
  <div class="card-content">
    <div class="card-header row">
      <div class="col-12">
        <!--Header-->
        <h3 class="harmony-primary-text fw-bold">Header</h3>
      </div>
    </div>
    <div class="card-body row">
      <div class="col-12">
        <!-- This is where you have to develop the component-->
        <!-- Code or text goes here-->
      </div>
    </div>
  </div>
</div>

