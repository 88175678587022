<div class="row result-row px-0" *ngFor="let document of documents">
  <div class="col-10 ps-0 align-self-center overflow-hidden">
    <app-document-type-icon [Extension]="document.Extension"></app-document-type-icon>
    <b class="ms-1" style="font-size: 1em !important;">{{ document.DisplayName }}</b>
    <fa-icon [icon]="faInfo"
             [title]="document.DisplayName + '\n\nFile Type: ' + document.Extension + '\nCategory: ' + document.DocumentCategoryName + '\nDescription:\n' + document.Description"
             style="font-size: 1.1em !important;"
             class="harmony-grey-text detail-info ms-2"></fa-icon>
  </div>
  <div class="col-2 align-self-center pe-0 text-end">
    <fa-icon [icon]="faDownloadIcon"
             [title]="'Download document'"
             (click)="downloadDocument(document)"
             class="pointer harmony-blue-text"
             [size]="'sm'"></fa-icon>
  </div>
</div>
<ng-container>
  <div class="row mt-3 mb-2" *ngIf="documents && documents.length <= 0">
    <div class="col-12 text-center harmony-grey-text">
      <fa-icon [icon]="faNoFile" size="2x"></fa-icon>
    </div>
    <div class="col-12 text-center harmony-grey-text mt-1">
      <h5>{{ NoDocumentMessage }}</h5>
    </div>
  </div>
</ng-container>
