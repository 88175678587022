import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { PricingService } from '../../../services/pricing.service';
import { ApiService } from '../../../services/api.service';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import {
    faArrowRight, faBoxOpen, faCopy,
    faEdit, faMapPin, faPlaneArrival,
    faPlaneDeparture, faPlus,
    faShip, faSortUp,
    faSyncAlt, faTrash, faTruck
} from '@fortawesome/free-solid-svg-icons';
import { RateGroupEditModalComponent } from './rategroup-edit-modal/rate-group-edit-modal.component';
import { IPage, IPaginationData } from '../../../shared/models/pagination-data.model';
import {
  IProject,
  IRateGroupPagination,
  IRateGroupParam
} from '../../../services/models/pricing/rates.model';
import { AdAuthService } from '../../../core/ad-auth-service/ad-auth.service';
import { PermissionCodes } from '../../../core/constants/permission-codes';
import { environment } from 'src/environments/environment';
import { IOrderParam } from '../../../shared/directives/sort/order.directive';
import { OpenConfirmationModal } from '../../../shared/components/confirmation-modal/confirmation-modal-functions';
import { AlertService } from '../../../services/alert.service';
import {CloneRateGroupModalComponent} from '../rate-capture/clone-rate-group-modal/clone-rate-group-modal.component';

@Component({
  selector: 'app-rate-group-manager',
  templateUrl: './rate-group-manager.component.html',
  styleUrls: ['./rate-group-manager.component.scss'],
  providers: [DatePipe]
})
export class RateGroupManagerComponent implements OnInit, OnDestroy {
  // Icons
  protected readonly faEditIcon = faEdit;
  protected readonly faEmpty = faBoxOpen;
  protected readonly faRefresh = faSyncAlt;
  protected readonly faDateArrow = faArrowRight;
  protected readonly faLocation = faMapPin;
  protected readonly faDepartureAir = faPlaneDeparture;
  protected readonly faArrivalAir = faPlaneArrival;
  protected readonly faSea = faShip;
  protected readonly faRoad = faTruck;
  protected readonly faSortDesc = faSortUp;
  protected readonly faRemove = faTrash;
  protected readonly faAdd = faPlus;
  protected readonly faCopyRate = faCopy;

  // Component variables
  paginationData: IPaginationData<IRateGroupPagination>;
  orderParam: IOrderParam;
  page: IPage;
  searchParam: IRateGroupParam;
  PortType: boolean = true;

  // Lookup data
  companyProjects$: Observable<IProject[]>;
  projects$ = this.pricingService.Projects$.asObservable();
  rateRequestTypes$ = this.pricingService.RateRequestTypes$.asObservable();
  modalityTypes$ = this.pricingService.ModalityTypes$.asObservable();
  rateVerdicts$ = this.pricingService.RateVerdicts$.asObservable();

  // Permissions
  isRateAdmin: boolean = false;

  // General variables
  private unsubscribe: Subject<any> = new Subject<any>();
  loading: boolean = false;
  environment = environment;

  constructor(public pricingService: PricingService,
              public authService: AdAuthService,
              private modalService: NgbModal,
              private alertService: AlertService,
              private api: ApiService) {
  }

  ngOnInit() {
    this.resetSearch();
    if (this.authService.CurrentUser) {
      this.setPermissions();
    }
    this.authService.CurrentUser$.subscribe(() => {
      this.setPermissions();
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  setPermissions() {
    this.isRateAdmin = this.authService.CheckPermissionByCode(PermissionCodes.Pricing_RateGroup_Admin);
    const agentId = this.authService.CurrentUser.User.Company.AgentId;
    if (agentId != null && agentId > 0) {
      if (!this.isRateAdmin) {
        this.getProjects(agentId);
      }
    }
  }

  // Search bar functions
  updateSearch() {
    if (this.canSearch()) {
      // Reset data
      this.page.pageNumber = 1;
      this.paginationData.DataSet = [];
      this.paginationData.TotalPages = 1;
      this.paginationData.CurrentPage = 1;

      this.searchRateGroup();
    } else {
      this.alertService.info('Please select a project.');
    }
  }

  canSearch() {
    return (
      this.isRateAdmin === true || (
      this.isRateAdmin === false &&
      this.searchParam.ProjectID != null
      )
    );
  }

  resetSearch() {
    // Init pagination data
    this.paginationData = {
      DataSet: [],
      Data: null,
      CurrentPage: 1,
      PageSize: 15,
      TotalPages: 1
    };
    // Set default ordering
    this.orderParam = {
      OrderBy: 'ValidTo',
      OrderDirection: 'desc'
    } as IOrderParam;
    // Init page
    this.page = {
      pageNumber: this.paginationData.CurrentPage,
      pageSize: this.paginationData.PageSize,
      batched: false
    };
    // Reset search param object
    this.searchParam = {
      ProjectID: null,
      ModalityTypeID: null,
      RateRequestTypeID: null,
      OriginCityID: null,
      OriginCountryID: null,
      DeparturePortID: null,
      ArrivalPortID: null,
      DestinationCityID: null,
      DestinationCountryID: null,
      RateRequestID: null,
      ValidFrom: null,
      ValidTo: null,
      AgentID: null,
      RateVerdictID: null,
      ActiveOnly: true
    } as IRateGroupParam;
  }

  getProjects(agentId: number) {
    this.companyProjects$ = this.api.get(`RateGroup/CompanyRateGroupProjects/${agentId}`).pipe(
      takeUntil(this.unsubscribe)
    );

    this.companyProjects$.subscribe({
      next: (data) => {
        if (data && data.length > 0) {
          this.searchParam.ProjectID = data[0].Id;
          this.updateSearch();
        }
      },
      error: () => { }
    });
  }

  searchRateGroup() {
    this.loading = true;
    const o = this.orderParam;
    const p = this.page;
    this.pricingService.SearchRateGroups(this.searchParam, p, o).subscribe({
      next: (data: IPaginationData<IRateGroupPagination>) => {
        if (data) {
          this.paginationData = Object.assign([], data);
        }
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  orderSearch(param: IOrderParam) {
    this.orderParam = param;
    this.searchRateGroup();
  }

  onPage(page: IPage) {
    this.paginationData.CurrentPage = page.pageNumber;
    this.page = page;
    this.searchRateGroup();
  }

  setPortType() {
    this.PortType = this.searchParam.ModalityTypeID && this.searchParam.ModalityTypeID === 1;
    // reset port id
    this.searchParam.DeparturePortID = null;
    this.searchParam.ArrivalPortID = null;
  }

  // Data handling functions
  createNewRateGroup() {
    // Open modal to create new rate group
    const modalRef = this.modalService.open(RateGroupEditModalComponent, {size: 'xl', windowClass: 'modal-2xl', backdrop: 'static'});
    modalRef.componentInstance.RateGroupID = null;
    modalRef.componentInstance.NewRateGroup();
    // On modal close, read the result and apply logic
    modalRef.result.then((result: boolean) => {
      if (result) {
        this.updateSearch();
      }
    }, () => {
    });
  }

  copyRateGroup(rateGroupId: number) {
    // Open modal to manage a RateGroup
    const modalRef = this.modalService.open(CloneRateGroupModalComponent, {size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.RateGroupId = rateGroupId;
    // On modal close, read the result and apply logic
    modalRef.result.then((refresh: boolean) => {
      if (refresh) {
        this.updateSearch();
      }
    }, () => { });
  }

  manageRateGroup(rg: IRateGroupPagination) {
    // Open modal to edit the rate group
    const modalRef = this.modalService.open(RateGroupEditModalComponent, {size: 'xl', windowClass: 'modal-2xl', backdrop: 'static'});
    modalRef.componentInstance.RateGroupID = rg.RateGroupId;
    modalRef.componentInstance.LoadRateGroup();
    // On modal close, read the result and apply logic
    modalRef.result.then((result: boolean) => {
      if (result) {
        this.updateSearch();
      }
    }, () => {
    });
  }

  removeRateGroup(rateGroup: IRateGroupPagination) {
    if (rateGroup) {
      const message = 'Are you sure you want to de-activate this rate group?';
      OpenConfirmationModal(this.modalService, message)
        .pipe(
          takeUntil(this.unsubscribe),
          switchMap(answer => {
            if (answer) {
              const successMessage = `Rate group successfully de-activated!`;
              return this.pricingService.ToggleActiveRateGroup(rateGroup.RateGroupId)
                .pipe(
                  tap(() => {
                    rateGroup.Active = false;
                    this.alertService.success(successMessage);
                  }));
            }
          })
        ).subscribe();
    }
  }

  activateRateGroup(rateGroup: IRateGroupPagination) {
    if (rateGroup) {
      const message = 'Are you sure you want to re-activate this rate group?';
      OpenConfirmationModal(this.modalService, message)
        .pipe(
          takeUntil(this.unsubscribe),
          switchMap(answer => {
            if (answer) {
              const successMessage = `Rate group successfully activated!`;
              return this.pricingService.ToggleActiveRateGroup(rateGroup.RateGroupId)
                .pipe(
                  tap(() => {
                    rateGroup.Active = true;
                    this.alertService.success(successMessage);
                  }));
            }
          })
        ).subscribe();
    }
  }
}
