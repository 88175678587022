import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServicesRoutingModule } from './services-routing.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ServicesMenuComponent } from './services-menu/services-menu.component';
import { DashboardContainerServicesComponent } from './services-dashboard/dashboard-container-services/dashboard-container-services.component';
import { DashboardCardServicesOverviewComponent } from './services-dashboard/dashboard-card-services-overview/dashboard-card-services-overview.component';
import { ServicesBusinessIntelligenceComponent } from './services-business-intelligence/services-business-intelligence.component';
import { ServicesClientServicesComponent } from './services-client-services/services-client-services.component';
import { ServicesComplianceComponent } from './services-compliance/services-compliance.component';
import { ServicesCorporateSalesComponent } from './services-corporate-sales/services-corporate-sales.component';
import { ServicesCustomerSatisfactionComponent } from './services-customer-satisfaction/services-customer-satisfaction.component';
import { ServicesEurotunnelComponent } from './services-eurotunnel/services-eurotunnel.component';
import { ServicesInsuranceComponent } from './services-insurance/services-insurance.component';
import { ServicesMarketingComponent } from './services-marketing/services-marketing.component';
import { ServicesNettingComponent } from './services-netting/services-netting.component';
import { ServicesIntegrationComponent } from './services-integration/services-integration.component';
import {DocumentListComponent} from "../../shared/components/document-list/document-list.component";

@NgModule({
  declarations: [
    DashboardCardServicesOverviewComponent,
    DashboardContainerServicesComponent,
    ServicesMenuComponent,
    ServicesBusinessIntelligenceComponent,
    ServicesClientServicesComponent,
    ServicesComplianceComponent,
    ServicesCorporateSalesComponent,
    ServicesCustomerSatisfactionComponent,
    ServicesEurotunnelComponent,
    ServicesInsuranceComponent,
    ServicesMarketingComponent,
    ServicesNettingComponent,
    ServicesIntegrationComponent
  ],
  imports: [
    CommonModule,
    ServicesRoutingModule,
    FontAwesomeModule,
    DocumentListComponent
  ],
  exports: [
    ServicesMenuComponent
  ]
})
export class ServicesModule { }
