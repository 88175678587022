<!---- Header ---->
<div class="modal-header">
  <h4 class="modal-title">
    Dangerous Substance Information
  </h4>
</div>
<!---- Add/Edit ---->
<div class="modal-body">
  <div class="row mb-1">
    <div class="col-6" *ngIf="branch">
      <div class="row mb-1">
        <div class="col-6">
          Company Name <span class="text-danger">*</span>
        </div>
        <div class="col-6">
          <input class="form-control form-control-sm" type="text"
                 [ngClass]="{'is-invalid': !this.branch.Name && invalid}"
                 [disabled]="!editBranch || !hasAccess" [(ngModel)]="branch.Name">
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-6">
          Phone number
        </div>
        <div class="col-6">
          <app-mobile-number [Disabled]="!editBranch || !hasAccess" [Number]="branch.PrimaryContact"
                             (OnChange)="branch.PrimaryContact = $event"></app-mobile-number>
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-6">
          Address 1 <span class="text-danger">*</span>
        </div>
        <div class="col-6">
          <input class="form-control form-control-sm" type="text"
                 [ngClass]="{'is-invalid': !this.branch.Address1 && invalid}"
                 [disabled]="!editBranch || !hasAccess" [(ngModel)]="branch.Address1">
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-6">
          Address 2
        </div>
        <div class="col-6">
          <input class="form-control form-control-sm" type="text"
                 [disabled]="!editBranch || !hasAccess" [(ngModel)]="branch.Address2">
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-6">
          Zip code <span class="text-danger">*</span>
        </div>
        <div class="col-6">
          <input class="form-control form-control-sm" type="text"
                 [ngClass]="{'is-invalid': !this.branch.ZipCode && invalid}"
                 [disabled]="!editBranch || !hasAccess" [(ngModel)]="branch.ZipCode">
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-6">
          City <span class="text-danger">*</span>
        </div>
        <div class="col-6" *ngIf="!editBranch">
          <input class="form-control form-control-sm"  type="text"
                 [value]="company?.CityPhysical" [disabled]="true">
        </div>
        <div class="col-6" *ngIf="editBranch">
          <app-city-search [Disabled]="!editBranch || !hasAccess" [SelectedID]="branch.CityId"
                           [ShowError]="!this.branch.CityId && invalid"
                           (OnSelect)="branch.CityId = $event">
          </app-city-search>
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-6">
          Country <span class="text-danger">*</span>
        </div>
        <div class="col-6">
          <app-country-search [Disabled]="!editBranch || !hasAccess" [SelectedID]="branch.CountryId"
                              [ShowError]="!this.branch.CityId && invalid"
                              (OnSelect)="branch.CountryId = $event">
          </app-country-search>
        </div>
      </div>
    </div>
    <div class="col-6">
      <app-company-details [companyId]="companyId" [branchId]="branch?.Id" [propertyCategory]="'DangerousGoods'"
                           [autoSave]="false" (OnChange)="updateCompanyDetails = $event"
                           [viewOnly]="!hasAccess"></app-company-details>
    </div>
  </div>
</div>
<!---- Footer ---->
<div class="modal-footer">
  <button type="button" class="btn btn-primary harmony-tertiary-button" (click)="update()">Save</button>
  <button type="button" class="btn btn-primary harmony-primary-button" (click)="close()">Close</button>
</div>
