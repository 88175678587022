<!---- Header ---->
<div class="modal-header" xmlns="http://www.w3.org/1999/html">
  <h4 class="modal-title">
    New Company Chart
  </h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="close(true)"> </button>
</div>
<!-- Body -->
<div class="modal-body">
  <div class="row ms-1 me-1 my-1">
    <div class="col-5">
      <b>Chart name</b>
    </div>
    <div class="col-7">
      <input type="text"
             class="form-control form-control-sm"
             [(ngModel)]="companyChart.Name"
             [ngClass]="{'is-invalid': companyChart.Name?.length <= 0 && invalid}"
             [placeholder]="'Name your company chart...'">
    </div>
  </div>
  <div class="row ms-1 me-1 my-1">
    <div class="col-5">
      <b>Company</b>
    </div>
    <div class="col-7">
      <ng-container *ngIf="canViewAllCompanies">
        <select class="form-select form-select-sm pointer"
                [(ngModel)]="companyChart.CompanyId"
                [ngClass]="{'is-invalid': companyChart.CompanyId <= 0 && invalid}"
                [disabled]="loading || !(companies$ | async)"
                (change)="getBranches()">
          <option [ngValue]="0" disabled selected>Select a company...</option>
          <option *ngFor="let company of (companies$ | async)"
                  [ngValue]="company.CompanyId">{{ company.CompanyName }}</option>
        </select>
      </ng-container>
      <ng-container *ngIf="!canViewAllCompanies">
        <div class="ms-1 my-1">
          {{ companyName }}
        </div>
      </ng-container>
    </div>
  </div>
  <div class="row ms-1 me-1 my-1" *ngIf="branches.length > 0">
    <div class="col-5">
      <b>Branch</b>
    </div>
    <div class="col-7">
      <div *ngIf="branches.length == 0">
        <select class="form-select form-select-sm form-select-sm pointer" aria-label="Disabled select example"
                disabled>
          <option selected>Default</option>
        </select>
      </div>
      <div *ngIf="branches.length > 0">
        <select [(ngModel)]="companyChart.BranchId"
                [ngClass]="{'is-invalid': companyChart.BranchId <= 0 && invalid}"
                class="form-select form-select-sm form-select-sm pointer">
          <option [ngValue]="null" selected>Select a branch...</option>
          <option *ngFor="let branch of branches"
                  [ngValue]="branch.Id">{{ branch.Name }}</option>
        </select>
      </div>
    </div>
  </div>
  <div class="row ms-1 me-1 my-1">
    <div class="col-5">
      <b>Contact person email</b>
    </div>
    <div class="col-7">
      <app-email-capture [EmailString]="companyChart.ContactEmail"
                         (OnValidate)="contactValid = $event"
                         (OnResult)="companyChart.ContactEmail = $event"></app-email-capture>
    </div>
  </div>
  <div class="row ms-1 me-1 my-1">
    <div class="col-5">
      <b>Use default categories</b>
    </div>
    <div class="col-7">
      <div class="form-check form-switch form-switch-md">
        <input class="form-check-input float-start" type="checkbox" role="switch" [id]="'switch-default'"
               [title]="'Use default categories'" [checked]="useDefaultCategories"
               (change)="setUseDefaultCategories($event)">
      </div>
    </div>
  </div>
</div>
<!---- Footer ---->
<div class="modal-footer" >
  <button type="button" class="btn btn-primary harmony-tertiary-button" [disabled]="!canCreate()" (click)="createCompanyChart()">Save</button>
  <button type="button" class="btn btn-primary harmony-primary-button" (click)="close(false)">Close</button>
</div>
