import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  ICompanyChartCategories,
  ICompanyChartCategory,
  ICompanyChartDto
} from '../../../../services/models/hse/hse.model';
import { HseService } from '../../../../services/hse.service';
import { AlertService } from '../../../../services/alert.service';
import {IApiResponse} from '../../../../shared/models/api-reponse.model';

@Component({
  selector: 'app-company-chart',
  templateUrl: './company-chart-category-modal.component.html',
  styleUrls: ['./company-chart-category-modal.component.scss']
})
export class CompanyChartCategoryModalComponent implements OnInit {
  // Component Variables
  category: ICompanyChartCategory;
  companyChart: ICompanyChartDto;
  companyChartCategories: ICompanyChartCategories[];
  invalid: boolean = false;

  // General variables
  loading: boolean = false;

  constructor(private hseService: HseService,
              private alertService: AlertService,
              private activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.loadCompanyChartCategories(this.companyChart.Id);
  }

  saveCategory() {
    if (this.category?.Description?.length > 0) {
      this.invalid = false;
      this.hseService.updateCompanyChartCategory(this.category)
        .subscribe({
          next: () => {
            this.alertService.info('Company chart category successfully saved.');
            this.close(true);
          },
          error: (err: IApiResponse) => {
            this.alertService.success(err?.Meta?.Message);
          }
        });
    } else {
      this.invalid = true;
    }
  }

  loadCompanyChartCategories(companyChartId: number) {
    this.loading = true;
    this.hseService.getCompanyChartCategoriesByCompanyChartId(companyChartId)
      .subscribe({
        next: (data): void => {
          this.companyChartCategories = Object.assign([], data);
          this.loading = false;
        },
        error: (): void => {
          this.loading = false;
        }
      });
  }

  close(shouldReload: boolean) {
    this.activeModal.close(shouldReload);
  }
}
