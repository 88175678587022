import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { AdAuthService } from 'src/app/core/ad-auth-service/ad-auth.service';
import { IAuthUser } from '../../../services/models/auth.model';
import { faBoxOpen, faCancel, faLink, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { IPage, IPaginationData } from '../../../shared/models/pagination-data.model';
import { IOrderParam } from '../../../shared/directives/sort/order.directive';
import { environment } from '../../../../environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  IHubCreateMapping,
  IHubMissingValue,
  IHubMissingValueSearchParam
} from '../../../services/models/hub/hub-values.model';
import { HubAdminService } from '../../../services/hub-admin.service';
import { takeUntil } from 'rxjs/operators';
import { MapMissingValueModalComponent } from './map-missing-value-modal/map-missing-value-modal.component';
import { OpenConfirmationModal } from '../../../shared/components/confirmation-modal/confirmation-modal-functions';
import { AlertService } from '../../../services/alert.service';
import { IHubClientSystemOverview } from '../../../services/models/hub/hub.model';
import { PermissionCodes } from '../../../core/constants/permission-codes';
import {IApiResponse} from '../../../shared/models/api-reponse.model';

@Component({
  selector: 'app-missing-values',
  templateUrl: './missing-values.component.html',
  styleUrls: ['./missing-values.component.scss']
})
export class MissingValuesComponent implements OnInit {
  // Icons
  faView = faLink;
  faIgnore = faCancel;
  faEmpty = faBoxOpen;
  faSortASc = faSortDown;

  // Component variables
  searchParam: IHubMissingValueSearchParam;
  paginationData: IPaginationData<IHubMissingValue>;
  orderParam: IOrderParam;
  page: IPage;
  clientSystemOverview$ = this.hubAdmin.ClientSystemOverview$.asObservable();
  userClients$: BehaviorSubject<IHubClientSystemOverview[]> = new BehaviorSubject<IHubClientSystemOverview[]>([]);
  selectedClient: IHubClientSystemOverview = null;
  canViewAll: boolean = false;
  ucid: number = null;

  // General variables
  loading: boolean = false;
  private unsubscribe: Subject<boolean> = new Subject<boolean>();
  env = environment;

  constructor(private api: ApiService,
              private auth: AdAuthService,
              private hubAdmin: HubAdminService,
              private alertService: AlertService,
              private modalService: NgbModal) { }

  ngOnInit() {
    this.initPage();

    if (this.auth.CurrentUser) {
      this.ucid = this.auth.CurrentUser.User.CompanyId;
      this.setPermissions();
    }

    this.auth.CurrentUser$.subscribe((user: IAuthUser) => {
      this.ucid = user.User.CompanyId;
      this.setPermissions();
    });

    this.clientSystemOverview$.subscribe((data: IHubClientSystemOverview[]) => {
      this.setPermissions();
    });
  }

  initPage() {
    this.searchParam = {
      SourceSystemId: null,
      SourceClientId: null,
      TargetClientId: null,
      SearchText: null
    } as IHubMissingValueSearchParam;

    // Init pagination data
    this.paginationData = {
      DataSet: [],
      CurrentPage: 1,
      PageSize: 30,
      TotalPages: 1
    } as IPaginationData<IHubMissingValue>;

    // Init page
    this.page = {
      pageNumber: this.paginationData.CurrentPage,
      pageSize: this.paginationData.PageSize
    } as IPage;

    // Set default ordering
    this.orderParam = {
      OrderBy: 'SourceElement.System.SystemName',
      OrderDirection: 'asc'
    } as IOrderParam;
  }

  setPermissions() {
    this.canViewAll = this.auth.CheckPermissionByCode(PermissionCodes.Hub_ViewAllData);

    // Set applicable clients only
    this.clientSystemOverview$.subscribe((data) => {
      if (this.canViewAll) {
        this.ucid = null;
        this.userClients$.next(data);
        this.searchMissingValues();
      } else {
        const clients = data.filter(x => x.TargetCompanyId === this.ucid);
        this.userClients$.next(clients);
        if (clients.length >= 1) {
          this.selectedClient = clients[0];
          this.setClientData();
          this.searchMissingValues();
        }
      }
    });
  }

  setClientData() {
    if (this.selectedClient) {
      this.searchParam.SourceSystemId = this.selectedClient.SourceSystemId;
      this.searchParam.SourceClientId = this.selectedClient.SourceClientId;
      this.searchParam.TargetClientId = this.selectedClient.TargetClientId;
    } else {
      this.searchParam.SourceSystemId = null;
      this.searchParam.SourceClientId = null;
      this.searchParam.TargetClientId = null;
    }

    this.searchMissingValues();
  }

  searchMissingValues() {
    this.loading = true;
    const p = this.page;
    const o = this.orderParam;

    this.api.post(`Hub/Values/MissingValues?pageNumber=${p.pageNumber}&pageSize=${p.pageSize}&orderBy=${o.OrderBy}&order=${o.OrderDirection}`, this.searchParam)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (data: IPaginationData<IHubMissingValue>) => {
          this.paginationData = Object.assign({}, data);
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        }
    });
  }

  onPage(page: IPage) {
    this.page = page;
    this.searchMissingValues();
  }

  orderSearch(param: IOrderParam) {
    this.orderParam = param;
    this.searchMissingValues();
  }

  mapMissingValue(missingValue: IHubMissingValue) {
    // Open modal to create the missing mapping
    const modalRef = this.modalService.open(MapMissingValueModalComponent, {size: 'xl', backdrop: 'static'});
    modalRef.componentInstance.MissingValue = missingValue;
    modalRef.componentInstance.UserClients = this.userClients$.getValue();
    // On modal close, read the result and apply logic
    modalRef.result.then((result: boolean) => {
      if (result) {
        this.searchMissingValues();
      }
    }, () => {
    });
  }

  markAsIgnore(missingValue: IHubMissingValue) {
    OpenConfirmationModal(this.modalService, `The value will be mapped to itself for ${missingValue.TargetClientName} and thus ignoring any specific value conversion. \n\n Continue?`)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((answer: boolean) => {
        if (answer) {
          const param = {
            SourceValue: missingValue.SourceValue,
            SourceElementId: missingValue.SourceElementId,
            SourceClientId: missingValue.SourceClientId,
            TargetValue: missingValue.SourceValue,
            TargetElementId: missingValue.TargetElementId,
            TargetClientId: missingValue.TargetClientId
          } as IHubCreateMapping;

          this.hubAdmin.CreateMapping(param).subscribe({
            next: (data) => {
              this.alertService.success(`Value mapping successfully ignored for ${missingValue.TargetClientName}.`);
              this.loading = false;
              this.searchMissingValues();
            },
            error: (err: IApiResponse) => {
              this.alertService.error(err?.Meta?.Message);
              this.loading = false;
            }
          });
        }
      });
  }
}
