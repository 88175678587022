<div class="card-wrapper">
  <div class="card-content">
    <div class="card-header row">
      <div class="col-12">
        <h5 class="harmony-teal-text fw-bold mt-2">Featured Articles</h5>
      </div>
    </div>
    <div class="card-body overflow-mask">
      <ng-container *ngIf="articles && articles.length > 0">
        <div class="row" *ngFor="let article of articles ">
          <div class="col-12">
            <app-article-item [Article]="article" [Class]="'headline-odd'"></app-article-item>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!articles || articles.length <= 0">
        <div class="row mt-3 mb-2">
          <div class="col-12 text-center harmony-grey-text">
            <fa-icon [icon]="faNoArticles" size="2x"></fa-icon>
          </div>
          <div class="col-12 text-center harmony-grey-text mt-1">
            <h5>No featured articles</h5>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

