
<div class="card-wrapper">
  <div class="card-content services-card">
    <div class="card-header row">
      <div class="col-12">
        <!--Header-->
        <h5 class="harmony-primary-text fw-bold">Services</h5>
      </div>
    </div>
    <hr class="ms-3 me-3 mt-0 mb-4">
    <div class="dashboard-content ms-3 mb-3">
      <a class="one-by-one services-card nav-link pointer"
         [routerLink]="['../business-intelligence']"
         [ngClass]="{'active': router.url.endsWith('services/business-intelligence')}">
        <div class="row">
          <div class="col-12 d-flex justify-content-center my-2">
            <img src="https://sadevelopment1.blob.core.windows.net/webimages/services/pie-chart.svg"
                 class="overview-icon"
                 alt="Business Intelligence">
          </div>
          <div class="col-12 fw-bolder fs-5 text-center my-3">
            Business Intelligence
          </div>
        </div>
      </a>
      <a class="one-by-one services-card nav-link pointer"
         [routerLink]="['../client-services']"
         [ngClass]="{'active': router.url.endsWith('services/client-services')}">
        <div class="row">
          <div class="col-12 d-flex justify-content-center my-2">
            <img src="https://sadevelopment1.blob.core.windows.net/webimages/services/price-tag.svg"
                 class="overview-icon"
                 alt="Client Services">
          </div>
          <div class="col-12 fw-bolder fs-5 text-center my-3">
            Client Services
          </div>
        </div>
      </a>
      <a class="one-by-one services-card nav-link pointer"
         [routerLink]="['../compliance']"
         [ngClass]="{'active': router.url.endsWith('services/compliance')}">
        <div class="row">
          <div class="col-12 d-flex justify-content-center my-2">
            <img src="https://sadevelopment1.blob.core.windows.net/webimages/services/price-ribbon.svg"
                 class="overview-icon"
                 alt="Compliance Programme">
          </div>
          <div class="col-12 fw-bolder fs-5 text-center my-3">
            Compliance Programme
          </div>
        </div>
      </a>
      <a class="one-by-one services-card nav-link pointer"
         [routerLink]="['../corporate-sales']"
         [ngClass]="{'active': router.url.endsWith('services/corporate-sales')}">
        <div class="row">
          <div class="col-12 d-flex justify-content-center my-2">
            <img src="https://sadevelopment1.blob.core.windows.net/webimages/services/line-graph.svg"
                 class="overview-icon"
                 alt="Corporate Sales Programme">
          </div>
          <div class="col-12 fw-bolder fs-5 text-center my-3">
            Corporate Sales Programme
          </div>
        </div>
     </a>
      <a class="one-by-one services-card nav-link pointer"
         [routerLink]="['../customer-satisfaction']"
         [ngClass]="{'active': router.url.endsWith('services/customer-satisfaction')}">
        <div class="row">
          <div class="col-12 d-flex justify-content-center my-2">
            <img src="https://sadevelopment1.blob.core.windows.net/webimages/services/thumbs-up.svg"
                 class="overview-icon"
                 alt="Customer Satisfaction">
          </div>
          <div class="col-12 fw-bolder fs-5 text-center my-3">
            Customer Satisfaction
          </div>
        </div>
      </a>
      <a class="one-by-one services-card nav-link pointer"
         [routerLink]="['../eurotunnel']"
         [ngClass]="{'active': router.url.endsWith('services/eurotunnel')}">
        <div class="row">
          <div class="col-12 d-flex justify-content-center my-2">
            <img src="https://sadevelopment1.blob.core.windows.net/webimages/services/network.svg"
                 class="overview-icon"
                 alt="Marketing">
          </div>
          <div class="col-12 fw-bolder fs-5 text-center my-3">
            Eurotunnel
          </div>
        </div>
      </a>
      <a class="one-by-one services-card nav-link pointer"
         [routerLink]="['../marketing']"
         [ngClass]="{'active': router.url.endsWith('services/marketing')}">
        <div class="row">
          <div class="col-12 d-flex justify-content-center my-2">
            <img src="https://sadevelopment1.blob.core.windows.net/webimages/services/megaphone.svg"
                 class="overview-icon"
                 alt="Marketing">
          </div>
          <div class="col-12 fw-bolder fs-5 text-center my-3">
            Marketing
          </div>
        </div>
      </a>
      <a class="one-by-one services-card nav-link pointer"
         [routerLink]="['../netting']"
         [ngClass]="{'active': router.url.endsWith('services/netting')}">
        <div class="row">
          <div class="col-12 d-flex justify-content-center my-2">
            <img src="https://sadevelopment1.blob.core.windows.net/webimages/services/calculator.svg"
                 class="overview-icon"
                 alt="Marketing">
          </div>
          <div class="col-12 fw-bolder fs-5 text-center my-3">
            Netting
          </div>
        </div>
      </a>
      <a class="one-by-one services-card nav-link pointer"
         [routerLink]="['../insurance']"
         [ngClass]="{'active': router.url.endsWith('services/insurance')}">
        <div class="row">
          <div class="col-12 d-flex justify-content-center my-2">
            <img src="https://sadevelopment1.blob.core.windows.net/webimages/services/shield.svg"
                 class="overview-icon"
                 alt="Marketing">
          </div>
          <div class="col-12 fw-bolder fs-5 text-center my-3">
            Insurance
          </div>
        </div>
      </a>
      <a class="one-by-one services-card nav-link pointer"
         [routerLink]="['../integration']"
         [ngClass]="{'active': router.url.endsWith('services/integration')}">
        <div class="row  d-flex justify-content-center">
          <div class="col-12 d-flex justify-content-center my-2">
            <img src="https://sadevelopment1.blob.core.windows.net/webimages/services/keyboard.svg"
                 class="overview-icon"
                 alt="Marketing">
          </div>
          <div class="col-12 fw-bolder fs-5 text-center my-3">
            Integration
          </div>
        </div>
      </a>
    </div>
  </div>
</div>
