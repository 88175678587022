import { Component } from '@angular/core';
import { AdAuthService } from '../ad-auth-service/ad-auth.service';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss']
})
export class SplashScreenComponent {
  windowWidth: string;
  opacityChange = 1;
  showSplash = true;

  constructor(private authService: AdAuthService) {
    this.authService.CurrentUser$.subscribe(currentUser => {
      if (currentUser) {
        this.opacityChange = 0;
        setTimeout(() => {
          this.showSplash = false;
        }, 500);
      }
    });
  }
}
