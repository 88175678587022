import { Injectable, OnDestroy } from '@angular/core';
import { StorageService } from './storage.service';
import { ApiService } from './api.service';
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AlertService } from './alert.service';
import { ILocationType } from './models/pricing/location.model';
import { ITenderCurrency } from './models/pricing/tender.model';
import {
  IRateRequestDto, IRateRequestActionLogParam,
  IRateRequestActionType, IRateRequestGenerateParam,
  IRateRequestSearchParam, IRateRequestType,
  IRateRequestStatus, IRateTypeDto
} from './models/pricing/rate-request.model';
import {
  ICalculationMethod, ICloneRateGroupParam, IModalityType,
  IModalityTypeUnitType, IProject, IRateGroupCheckResult,
  IRateGroup, IRateGroupParam, IRateGroupSampleParam,
  IRateLimitType, IRateSource, IRateVerdict,
  IServiceType, IServiceTypeUnitType, IUnitType, IRateGroupPagination
} from './models/pricing/rates.model';
import { IPage, IPaginationData } from '../shared/models/pagination-data.model';
import { IOrderParam } from '../shared/directives/sort/order.directive';
import {
  IPreferredAgentCreateRequest,
  IPreferredAgentRequest,
  IPreferredAgentUpdateRequest,
  IPreferredProject,
  IPreferredProjectSearchParam
} from './models/pricing/preferred-data.model';

@Injectable({
  providedIn: 'root'
})
export class PricingService implements OnDestroy {
  // Lookup variables
  UnitTypes$: BehaviorSubject<IUnitType[]> = new BehaviorSubject<IUnitType[]>([]);
  ModalityUnitTypes$: BehaviorSubject<IModalityTypeUnitType[]> = new BehaviorSubject<IModalityTypeUnitType[]>([]);
  ServiceTypes$: BehaviorSubject<IServiceType[]> = new BehaviorSubject<IServiceType[]>([]);
  ServiceUnitTypes$: BehaviorSubject<IServiceTypeUnitType[]> = new BehaviorSubject<IServiceTypeUnitType[]>([]);
  ModalityTypes$: BehaviorSubject<IModalityType[]> = new BehaviorSubject<IModalityType[]>([]);
  Currencies$: BehaviorSubject<ITenderCurrency[]> = new BehaviorSubject<ITenderCurrency[]>([]);
  LocationTypes$: BehaviorSubject<ILocationType[]> = new BehaviorSubject<ILocationType[]>([]);
  Projects$: BehaviorSubject<IProject[]> = new BehaviorSubject<IProject[]>([]);
  RateSources$: BehaviorSubject<IRateSource[]> = new BehaviorSubject<IRateSource[]>([]);
  CalculationMethods$: BehaviorSubject<ICalculationMethod[]> = new BehaviorSubject<ICalculationMethod[]>([]);
  RateLimitTypes$: BehaviorSubject<IRateLimitType[]> = new BehaviorSubject<IRateLimitType[]>([]);
  RateVerdicts$: BehaviorSubject<IRateVerdict[]> = new BehaviorSubject<IRateVerdict[]>([]);
  RateRequestTypes$: BehaviorSubject<IRateRequestType[]> = new BehaviorSubject<IRateRequestType[]>([]);
  RateRequestActionTypes$: BehaviorSubject<IRateRequestActionType[]> = new BehaviorSubject<IRateRequestActionType[]>([]);
  RateRequestStatuses$: BehaviorSubject<IRateRequestStatus[]> = new BehaviorSubject<IRateRequestStatus[]>([]);
  RateTypes$: BehaviorSubject<IRateTypeDto[]> = new BehaviorSubject<IRateTypeDto[]>([]);

  // General Variables
  loading: boolean = false;
  private unsubscribe: Subject<any> = new Subject<any>();

  constructor(private alertService: AlertService,
              public storage: StorageService,
              public api: ApiService) {
      this.loadUnitTypes();
      this.loadTenderCurrencies();
      this.loadModalityTypes();
      this.loadLocationTypes();
      this.loadModalityUnitTypes();
      this.loadServiceTypes();
      this.loadServiceTypeUnitTypes();
      this.loadProjects();
      this.loadRateSources();
      this.loadCalculationMethods();
      this.loadRateLimitTypes();
      this.loadRateVerdicts();
      this.loadRateRequestTypes();
      this.loadRateRequestActionTypes();
      this.loadRateRequestStatuses();
      this.loadRateTypes();
  }

  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  //region Public Functions
  public SearchRateRequests(param: IRateRequestSearchParam, p: IPage, o: IOrderParam) {
    return this.api.post(`RateRequest/Search?pageNumber=${p.pageNumber}&pageSize=${p.pageSize}&orderBy=${o.OrderBy}&order=${o.OrderDirection}`, param).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public GenerateRateRequests(param: IRateRequestGenerateParam) {
    return this.api.post('RateRequest/GenerateRequests', param).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public CreateRequests(param: IRateRequestDto[]) {
    return this.api.post('RateRequest/CreateNewRequests', param).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public GetRateRequestActionLog(param: IRateRequestActionLogParam) {
    return this.api.post('RateRequest/Action/Log/List', param).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public GetRateGroupSample(param: IRateGroupSampleParam) {
    return this.api.post('RateGroup/RateByRateGroup', param).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public GetRequestOriginLocationName(rr: IRateRequestDto) {
    switch (rr.OriginLocationTypeId) {
      case 3:
        return rr.OriginCountryName;
      case 4:
        return rr.OriginStateName;
      case 5:
        return rr.OriginCityName;
      default:
        return '-';
    }
  }

  public GetRequestDestinationLocationName(rr: IRateRequestDto) {
    switch (rr.DestinationLocationTypeId) {
      case 3:
        return rr.DestinationCountryName;
      case 4:
        return rr.DestinationStateName;
      case 5:
        return rr.DestinationCityName;
      default:
        return '-';
    }
  }

  public SearchRateGroups(param: IRateGroupParam, p: IPage, o: IOrderParam): Observable<IPaginationData<IRateGroupPagination>> {
    return this.api.post(`RateGroup/Search?pageNumber=${p.pageNumber}&pageSize=${p.pageSize}&orderBy=${o.OrderBy}&order=${o.OrderDirection}`, param).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public UpsertRateGroup(rateGroup: IRateGroup): Observable<IRateGroup> {
    return this.api.post(`RateGroup/Update`, rateGroup).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public CheckRateGroup(rateGroupId: number): Observable<IRateGroupCheckResult[]> {
    return this.api.get(`RateGroup/Check/${rateGroupId}`).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public CloneRateGroup(param: ICloneRateGroupParam): Observable<IRateGroup> {
    return this.api.post('RateGroup/Copy', param).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public ToggleActiveRateGroup(rateGroupId: number): Observable<boolean> {
    return this.api.put(`RateGroup/Active/${rateGroupId}`).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public ToggleActiveRateRequest(rateRequestId: number): Observable<boolean> {
    return this.api.put(`RateRequest/Active/${rateRequestId}`).pipe(
      takeUntil(this.unsubscribe)
    );
  }
  //endregion

  //region Preferred Data
  public GetPreferredAgents(body: IPreferredAgentRequest, page: IPage) {
    return this.api.post(`Agent/Preferred?pageNumber=${page.pageNumber}&pageSize=${page.pageSize}`, body).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public CreatePreferredAgent(body: IPreferredAgentCreateRequest) {
    return this.api.post('Agent/Preferred/Create', body).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public UpdatePreferredAgent(body: IPreferredAgentUpdateRequest) {
    return this.api.put('Agent/Preferred/Update', body).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public GetPreferredProjects(body: IPreferredProjectSearchParam, page: IPage) {
    return this.api.post(`Project/Preferred?pageNumber=${page.pageNumber}&pageSize=${page.pageSize}`, body).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public CreatePreferredProject(body: IPreferredProject) {
    return this.api.post('Project/Preferred/Create', body).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public UpdatePreferredProject(body: IPreferredProject) {
    return this.api.put('Project/Preferred/Update', body).pipe(
      takeUntil(this.unsubscribe)
    );
  }
  //endregion

  //region Lookups & Data

  loadUnitTypes() {
    this.loading = true;

    this.api.get('Lookup/UnitTypes').pipe(
      takeUntil(this.unsubscribe),
    ).subscribe({
      next: (data) => {
        if (data) {
          // this.UnitTypes = Object.assign([], data);
          this.UnitTypes$.next(data);
        }
        this.loading = false;
      },
      error: () => {
        this.alertService.error('An Error Occurred: LoadUnitTypes');
        this.loading = false;
      }
    });
  }

  public GetUnitTypeName(Id: number) {
    const unitTypes = this.UnitTypes$.getValue();
    if (Id && unitTypes?.length > 0) {
      const unitType = unitTypes.find((x) => x.Id === Id);
      return unitType.Abbreviation; // + ': ' + unitType.Name;
    } else {
      return '-';
    }
  }

  // Load all TenderCurrencies
  loadTenderCurrencies() {
    this.loading = true;

    this.api.get('TenderCurrency/List').pipe(
      takeUntil(this.unsubscribe),
    ).subscribe({
      next: (data: ITenderCurrency[]) => {
        if (data) {
          this.Currencies$.next(data);
        }
        this.loading = false;
      },
      error: () => {
        this.alertService.error('An Error Occurred: loadTenderCurrencies');
        this.loading = false;
      }
    });
  }

  // Load all ModalityTypes
  loadModalityTypes() {
    this.loading = true;

    this.api.get('lookup/ModalityTypes').pipe(
      takeUntil(this.unsubscribe),
    ).subscribe({
      next: (data) => {
        if (data) {
          this.ModalityTypes$.next(data);
        }
        this.loading = false;
      },
      error: () => {
        this.alertService.error('An Error Occurred: loadModalityTypes');
        this.loading = false;
      }
    });
  }

  // Load all LocationTypes
  loadLocationTypes() {
    this.loading = true;

    this.api.get('LocationType/List').pipe(
      takeUntil(this.unsubscribe),
    ).subscribe({
      next: (data) => {
        if (data) {
          this.LocationTypes$.next(data);
        }
        this.loading = false;
      },
      error: () => {
        this.alertService.error('An Error Occurred: loadLocationTypes');
        this.loading = false;
      }
    });
  }

  loadRateTypes() {
    this.loading = true;

    this.api.get('RateType/Search').pipe(
      takeUntil(this.unsubscribe),
    ).subscribe({
      next: (data) => {
        if (data) {
          this.RateTypes$.next(data);
        }
        this.loading = false;
      },
      error: () => {
        this.alertService.error('An Error Occurred: loadRateTypes');
        this.loading = false;
      }
    });
  }

  GetLocationTypeByName(name: string) {
    const locationTypes = this.LocationTypes$.getValue();

    if (locationTypes?.length > 0) {
      const result = locationTypes.find(l => l.Name === name);
      if (result) {
        return result;
      }
    }

    return {
      Id: 0,
      Active: false,
      DateCreated: null,
      DateModified: null,
      Name: 'N/A',
      Administration: false
    } as ILocationType;
  }

  // Load all ModalityUnitTypes
  loadModalityUnitTypes() {
    this.loading = true;

    this.api.get('lookup/ModalityTypeUnitTypes').pipe(
      takeUntil(this.unsubscribe),
    ).subscribe({
      next: (data) => {
        if (data) {
          this.ModalityUnitTypes$.next(data);
        }
        this.loading = false;
      },
      error: () => {
        this.alertService.error('An Error Occurred: loadModalityUnitTypes');
        this.loading = false;
      }
    });
  }

  // Load all service types
  loadServiceTypes() {
    this.loading = true;

    this.api.get('ServiceType/List').pipe(
      takeUntil(this.unsubscribe),
    ).subscribe({
      next: (data) => {
        if (data) {
          this.ServiceTypes$.next(data);
        }
        this.loading = false;
      },
      error: () => {
        this.alertService.error('An Error Occurred: loadServiceTypes');
        this.loading = false;
      }
    });
  }

  // Load all service type unit types
  loadServiceTypeUnitTypes() {
    this.loading = true;

    this.api.get('Lookup/ServiceTypeUnitTypes').pipe(
      takeUntil(this.unsubscribe),
    ).subscribe({
      next: (data) => {
        if (data) {
          this.ServiceUnitTypes$.next(data);
        }
        this.loading = false;
      },
      error: () => {
        this.alertService.error('An Error Occurred: loadServiceTypeUnitTypes');
        this.loading = false;
      }
    });
  }

  // Get unit types for a service type
  GetServiceUnitTypes(serviceTypeID: number) {
    const stut = this.ServiceUnitTypes$.getValue();
    if (stut?.length > 0) {
      const serviceUnitTypes = stut.filter(m => m.ServiceTypeId === serviceTypeID);

      if (serviceUnitTypes) {
        return serviceUnitTypes;
      } else {
        return [];
      }
    }
  }

  // Load all projects
  loadProjects() {
    this.loading = true;

    this.api.get('Project/List').pipe(
      takeUntil(this.unsubscribe),
    ).subscribe({
      next: (data) => {
        if (data) {
          this.Projects$.next(data);
        }
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  // Load all RateSources
  loadRateSources() {
    this.loading = true;

    this.api.get('Lookup/RateSources').pipe(
      takeUntil(this.unsubscribe),
    ).subscribe({
      next: (data) => {
        if (data) {
          this.RateSources$.next(data);
        }
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  // Get the Project object for an ID
  GetRateSourceById(rateSourceID: number): IRateSource {
    const rateSources = this.RateSources$.getValue();

    if (rateSourceID && rateSources?.length > 0) {
      const rateSource = rateSources.find((p) => p.Id === rateSourceID);

      if (rateSource) {
        return rateSource;
      }
    }

    return {
      Id: null,
      Name: '-',
      Description: 'No rate source found for this ID.'
    } as IRateSource;
  }

  // Load all Calculation Methods
  loadCalculationMethods() {
    this.loading = true;

    this.api.get('lookup/CalculationMethods').pipe(
      takeUntil(this.unsubscribe),
    ).subscribe({
      next: (data: ICalculationMethod[]) => {
        if (data) {
          this.CalculationMethods$.next(data);
        }
        this.loading = false;
      },
      error: () => {
        this.alertService.error('An Error Occurred: loadCalculationMethods');
        this.loading = false;
      }
    });
  }

  // Load all Rate Limit Types
  loadRateLimitTypes() {
    this.loading = true;

    this.api.get('lookup/RateLimitTypes').pipe(
      takeUntil(this.unsubscribe),
    ).subscribe({
      next: (data) => {
        if (data) {
          this.RateLimitTypes$.next(data);
        }
        this.loading = false;
      },
      error: () => {
        this.alertService.error('An Error Occurred: loadRateLimitTypes');
        this.loading = false;
      }
    });
  }

  // Load all Rate Verdicts
  loadRateVerdicts() {
    this.loading = true;

    this.api.get('lookup/RateVerdicts').pipe(
      takeUntil(this.unsubscribe),
    ).subscribe({
      next: (data) => {
        if (data) {
          this.RateVerdicts$.next(data);
        }
        this.loading = false;
      },
      error: () => {
        this.alertService.error('An Error Occurred: loadRateVerdicts');
        this.loading = false;
      }
    });
  }

  GetRateVerdictByName(name: string): IRateVerdict {
    const rateVerdicts = this.RateVerdicts$.getValue();
    if (rateVerdicts?.length > 0) {
      const rateVerdict = rateVerdicts.find(p => p.Name === name);

      if (rateVerdict) {
        return rateVerdict;
      }
    }

    return {
      Id: null,
      Name: '-',
      Description: 'No rate verdict found.',
      IncludeInOverview: false,
      AgentEdit: false
    } as IRateVerdict;
  }

  // Load all Rate Request Types
  loadRateRequestTypes() {
    this.loading = true;

    this.api.get('RateRequestType/List').pipe(
      takeUntil(this.unsubscribe),
    ).subscribe({
      next: (data: IRateRequestType[]) => {
        if (data) {
          this.RateRequestTypes$.next(data);
        }
        this.loading = false;
      },
      error: () => {
        this.alertService.error('An Error Occurred: loadRateRequestTypes');
        this.loading = false;
      }
    });
  }

  // Load all Rate Request Action Types
  loadRateRequestActionTypes() {
    this.loading = true;

    this.api.get('RateRequest/Action/Type/List').pipe(
      takeUntil(this.unsubscribe),
    ).subscribe({
      next: (data: IRateRequestActionType[]) => {
        if (data) {
          this.RateRequestActionTypes$.next(data);
        }
        this.loading = false;
      },
      error: () => {
        this.alertService.error('An Error Occurred: loadRateRequestActionTypes');
        this.loading = false;
      }
    });
  }

  // Load all Rate Request Statuses
  loadRateRequestStatuses() {
    this.loading = true;

    this.api.get('RateRequestStatus/List').pipe(
      takeUntil(this.unsubscribe),
    ).subscribe({
      next: (data: IRateRequestStatus[]) => {
        if (data) {
          this.RateRequestStatuses$.next(data);
        }
        this.loading = false;
      },
      error: () => {
        this.alertService.error('An Error Occurred: loadRateRequestStatuses');
        this.loading = false;
      }
    });
  }

  //endregion

}
