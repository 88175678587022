<div id="hub-accordion">
  <div class="card portal-menu-collapse-card">
    <div class="portal-menu-collapse-header bg-dark"
         id="headingHub"
         data-bs-toggle="collapse"
         data-bs-target="#collapseHub"
         aria-expanded="true"
         aria-controls="collapseHub">
      <h5 class="portal-menu-heading">
        <span class="heading-icon icon-color">
          <fa-icon [icon]="faHub" [size]="'xs'"></fa-icon>
        </span>
        <span class="portal-menu-module-name"
              [ngClass]="{'harmony-primary-text fw-bold': router.url.includes('hub/')}">
          Integration Hub
        </span>
      </h5>
    </div>
    <div id="collapseHub" class="collapse" aria-labelledby="headingHub" data-bs-parent="#hub-accordion">
      <div class="portal-menu-collapse-body">
        <nav class="col-md-12 d-none d-md-block ps-4">
          <ul class="nav flex-column mb-0">
            <li class="nav-item" *ngIf="messageLogs">
              <a class="nav-link pointer"
                 [routerLink]="['hub/message-log']"
                 [ngClass]="{'active': router.url.includes('message-log')}">
                <fa-icon class="link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Messages
              </a>
            </li>
            <li class="nav-item" *ngIf="missingValues || defaultValues || manageMappings">
              <div class="sub-menu">
                <div class="row">
                  <div class="col-12">
                    <a class="nav-link pointer"
                       (click)="valuesSubMenu.hidden = !valuesSubMenu.hidden"
                       [ngClass]="{'active': router.url.includes('missing-values') || router.url.includes('default-values') || router.url.includes('value-mappings')}">
                      <fa-icon [rotate]="valuesSubMenu.hidden == false ? 90 : undefined" class="link-icon me-2" [icon]="faExpandable" size="1x"></fa-icon> Values
                    </a>
                  </div>
                </div>
                <div #valuesSubMenu
                     [hidden]="true"
                     class="sub-menu-list">
                  <ul class="nav flex-column mb-0">
                    <li class="nav-item sub-nav-item pointer"
                        *ngIf="missingValues">
                      <a class="nav-link pointer"
                         [routerLink]="['hub/missing-values']"
                         [ngClass]="{'active': router.url.includes('missing-values')}">
                        <fa-icon class="link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Missing values
                      </a>
                    </li>
                    <li class="nav-item sub-nav-item pointer"
                        *ngIf="defaultValues">
                      <a class="nav-link pointer"
                         [routerLink]="['hub/default-values']"
                         [ngClass]="{'active': router.url.includes('default-values')}">
                        <fa-icon class="link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Default values
                      </a>
                    </li>
                    <li class="nav-item sub-nav-item pointer"
                        *ngIf="manageMappings">
                      <a class="nav-link pointer"
                         [routerLink]="['hub/value-mappings']"
                         [ngClass]="{'active': router.url.includes('value-mappings')}">
                        <fa-icon class="link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Value mappings
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li class="nav-item" *ngIf="schemaManager || schemaMappings">
              <div class="sub-menu">
                <div class="row">
                  <div class="col-12">
                    <a class="nav-link pointer"
                       (click)="schemaSubMenu.hidden = !schemaSubMenu.hidden"
                       [ngClass]="{'active': router.url.includes('schema-manager') || router.url.includes('schema-mappings')}">
                      <fa-icon [rotate]="schemaSubMenu.hidden == false ? 90 : undefined" class="link-icon me-2" [icon]="faExpandable" size="1x"></fa-icon> Schemas
                    </a>
                  </div>
                </div>
                <div #schemaSubMenu
                     [hidden]="true"
                     class="sub-menu-list">
                  <ul class="nav flex-column mb-0">
                    <li class="nav-item sub-nav-item pointer"
                        *ngIf="schemaManager">
                      <a class="nav-link pointer"
                         [routerLink]="['hub/schema-manager']"
                         [ngClass]="{'active': router.url.includes('schema-manager')}">
                        <fa-icon class="link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Manage schemas
                      </a>
                    </li>
                    <li class="nav-item sub-nav-item pointer"
                        *ngIf="schemaMappings">
                      <a class="nav-link pointer"
                         [routerLink]="['hub/schema-mappings']"
                         [ngClass]="{'active': router.url.includes('schema-mappings')}">
                        <fa-icon class="link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Schema mappings
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li class="nav-item" *ngIf="hubAdmin">
              <div class="sub-menu">
                <div class="row">
                  <div class="col-12">
                    <a class="nav-link pointer"
                       (click)="hubAdminSubMenu.hidden = !hubAdminSubMenu.hidden"
                       [ngClass]="{'active': router.url.includes('hub/admin/')}">
                      <fa-icon [rotate]="hubAdminSubMenu.hidden == false ? 90 : undefined" class="link-icon me-2" [icon]="faExpandable" size="1x"></fa-icon> Admin
                    </a>
                  </div>
                </div>
                <div #hubAdminSubMenu
                     [hidden]="true"
                     class="sub-menu-list">
                  <ul class="nav flex-column mb-0">
                    <li class="nav-item sub-nav-item pointer">
                      <a class="nav-link pointer"
                         [routerLink]="['hub/admin/clients']"
                         [ngClass]="{'active': router.url.endsWith('hub/admin/clients')}">
                        <fa-icon class="link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Clients
                      </a>
                    </li>
                    <li class="nav-item sub-nav-item pointer">
                      <a class="nav-link pointer"
                         [routerLink]="['hub/admin/systems']"
                         [ngClass]="{'active': router.url.endsWith('hub/admin/systems')}">
                        <fa-icon class="link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Systems
                      </a>
                    </li>
                    <li class="nav-item sub-nav-item pointer">
                      <a class="nav-link pointer"
                         [routerLink]="['hub/admin/log']"
                         [ngClass]="{'active': router.url.endsWith('hub/admin/log')}">
                        <fa-icon class="link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Hub log
                      </a>
                    </li>
                    <li class="nav-item sub-nav-item pointer">
                      <a class="nav-link pointer"
                         [routerLink]="['hub/admin/lookup-data']"
                         [ngClass]="{'active': router.url.endsWith('hub/admin/lookup-data')}">
                        <fa-icon class="link-icon me-2" [icon]="faMenuItem" size="2xs"></fa-icon> Lookup data
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</div>
