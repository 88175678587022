import { Component, OnInit } from '@angular/core';
import { CompanyService } from '../../../services/company.service';
import { IPage, IPaginationData } from '../../../shared/models/pagination-data.model';
import { faEdit, faFileText, faTrash } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AdAuthService } from '../../../core/ad-auth-service/ad-auth.service';
import { HseService } from 'src/app/services/hse.service';
import { IBranchDto } from '../../../services/models/hse/branch.model';
import {
  IRiskAssessmentDto,
  IRiskAssessmentAnswer,
  IRiskAssessmentGroup,
  IRiskAssessmentItem,
  IRiskAssessmentList,
  IRiskAssessmentListParam
} from '../../../services/models/hse/hse.model';
import { RiskAssessmentAnswerModalComponent } from './risk-assessment-answer-modal/risk-assessment-answer-modal.component';
import { RiskAssessmentCreateModalComponent } from './risk-assessment-create-modal/risk-assessment-create-modal.component';
import { RiskAssessmentReportModalComponent } from './risk-assessment-report-modal/risk-assessment-report-modal.component';
import { RiskAssessmentInformationModalComponent } from './risk-assessment-information-modal/risk-assessment-information-modal.component';
import { OpenConfirmationModal } from '../../../shared/components/confirmation-modal/confirmation-modal-functions';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PermissionCodes } from '../../../core/constants/permission-codes';
import { AlertService } from '../../../services/alert.service';
import { environment } from '../../../../environments/environment';
import {ActivatedRoute, Router} from '@angular/router';
import { ICompanyImagesDto } from '../../../services/models/member.model';
import {IApiResponse} from '../../../shared/models/api-reponse.model';

@Component({
  selector: 'app-risk-assessment-overview',
  templateUrl: './risk-assessments-overview.component.html',
  styleUrls: ['./risk-assessments-overview.component.scss'],
})
export class RiskAssessmentsOverviewComponent implements OnInit {
  // Icons
  faView = faFileText;
  faDelete = faTrash;
  faEdit = faEdit;

  // Component Variables
  paginationData: IPaginationData<IRiskAssessmentList> = null;
  riskAssessment: IRiskAssessmentDto;
  riskAssessmentGroups: IRiskAssessmentGroup[];
  riskAssessmentItems: IRiskAssessmentItem[];
  riskAnswer: IRiskAssessmentAnswer;
  companyImages: ICompanyImagesDto;
  page: IPage;
  riskAssessmentYears: number[];
  showOpenIRL: boolean = false;
  showOpenRRL: boolean = false;
  showNeedAttention: boolean = false;
  filteredIRL: number = 0;
  filteredRRL: number = 0;
  branch: IBranchDto;
  showWatermark: boolean = false;
  public riskAssessmentSearchParam: IRiskAssessmentListParam = {
    CompanyId: null,
    BranchId: null,
    Year: null,
    AssessmentStatusId: null,
    CurrentPage: 1,
    RowCount: 15
  };
  isExpired: boolean = false;
  companies$ = this.companyService.ActiveMembers$.asObservable();
  riskAssessmentStatus$ = this.hseService.RiskAssessmentStatus$.asObservable();

  // Permissions
  public readonly PermissionCodes = PermissionCodes;
  canCreateNew: boolean = false;
  canViewCompanies: boolean = false;
  canEditInformation: boolean = false;
  canEditName: boolean = false;

  // Shared variables
  loading: boolean = false;
  loadingRiskAssessment: boolean = false;
  noResult: boolean = false;
  private unsubscribe: Subject<any> = new Subject<any>();
  protected readonly environment = environment;

  constructor(private companyService: CompanyService,
              private modalService: NgbModal,
              public authService: AdAuthService,
              private route: ActivatedRoute,
              public router: Router,
              private alertService: AlertService,
              private hseService: HseService) {
  }

  ngOnInit() {
    this.paginationData = {
      DataSet: [],
      CurrentPage: 1,
      PageSize: 15,
      TotalPages: 1
    } as IPaginationData<IRiskAssessmentList>;

    this.page = {
      pageNumber: this.paginationData.CurrentPage,
      pageSize: this.paginationData.PageSize
    } as IPage;

    if (this.authService.CurrentUser) {
      this.setPermissions();
      this.getRiskAssessments();
    } else {
      this.authService.CurrentUser$
        .subscribe(() => {
          this.setPermissions();
          this.getRiskAssessments();
        });
    }

    this.route.paramMap.subscribe(paramMap => {
      const hsAssessmentId = paramMap.get('hsAssessmentId');

      if (hsAssessmentId) {
        return this.openRiskAssessment(Number(hsAssessmentId));
      }
    });

  }

  setPermissions() {
    this.canCreateNew = this.authService.CheckPermissionByCode(PermissionCodes.HSE_HS_RiskAssessment_CreateNew);
    this.canViewCompanies = this.authService.CheckPermissionByCode(PermissionCodes.HSE_HS_RiskAssessment_CompanyFilter);
    if (!this.canViewCompanies) {
      this.riskAssessmentSearchParam.CompanyId = this.authService.CurrentUser.User.CompanyId;
    }
    this.canEditInformation = this.authService.CheckPermissionByCode(PermissionCodes.HSE_HS_RiskAssessment_EditInformation);
    this.canEditName = this.authService.CheckPermissionByCode(PermissionCodes.HSE_HS_RiskAssessment_EditName);
  }

  onPage(page: IPage) {
    this.paginationData.CurrentPage = page.pageNumber;
    this.riskAssessmentSearchParam.CurrentPage = page.pageNumber;
    this.riskAssessmentSearchParam.RowCount = page.pageSize;

    this.getRiskAssessments();
  }

  navigateToCompanyChart(hsAssessmentId: number) {
    this.router.navigate(['hse/hs-risk-assessment/' + hsAssessmentId]).catch();
  }

  searchRiskAssessments() {
    this.page.pageNumber = 1;
    this.riskAssessmentSearchParam.CurrentPage = this.page.pageNumber;
    this.getRiskAssessments();
  }

  getRiskAssessments() {
    this.loading = true;

    this.hseService.getRiskAssessments(this.riskAssessmentSearchParam)
      .subscribe({
        next: (data): void => {
          this.paginationData = Object.assign([], data);
          this.riskAssessmentYears = Object.assign([], data);
          this.noResult = this.paginationData.DataSet.length === 0;
          this.loading = false;
        },
        error: (): void => {
          this.loading = false;
        }
      });
  }

  openRiskAssessment(riskAssessmentId: number) {
    this.loadingRiskAssessment = true;

    this.hseService.getRiskAssessmentById(riskAssessmentId)
      .subscribe({
        next: (data): void => {
          this.riskAssessment = Object.assign({}, data);
          this.isExpired = this.riskAssessment.AssessmentStatusName === 'Expired';
          this.loadAssessmentGroups();
          this.loadCompanyImages();
          this.loadingRiskAssessment = false;
        },
        error: (): void => {
          this.loadingRiskAssessment = false;
        }
      });
  }

  getGroupItems(groupId: number): IRiskAssessmentItem[] {
    const filteredItems = this.riskAssessmentItems.filter(x => x.AssessmentGroupId === groupId &&
      (
        (this.showOpenIRL === true && (x.IRL === 0 || x.IRL == null) || this.showOpenIRL === false) &&
        (this.showOpenRRL === true && (x.RRL === 0 || x.AnswerModifiedDate == null || x.RRL == null) || this.showOpenRRL === false) &&
        (this.showNeedAttention === true && x.IRL > 70 || this.showNeedAttention === true && x.RRL > 70 || this.showNeedAttention === false))
      );

    this.filteredIRL = filteredItems.filter(x => (x.IRL === 0 || x.IRL == null)).length;
    this.filteredRRL = filteredItems.filter(x => (x.RRL === 0 || x.RRL == null || x.AnswerModifiedDate == null)).length;

    return filteredItems;
  }

  loadAssessmentGroups() {
    this.loadingRiskAssessment = true;

    this.hseService.getRiskAssessmentGroups()
      .subscribe({
        next: (data): void => {
          this.riskAssessmentGroups = Object.assign([], data);
          this.hseService.getListRiskAssessmentItems(this.riskAssessment.Id)
            .subscribe({
              next: (data1): void => {
                this.riskAssessmentItems = Object.assign([], data1);
                this.loadingRiskAssessment = false;
              },
              error: (): void => {
                this.loadingRiskAssessment = false;
              }
            });
        },
        error: (): void => {
          this.loadingRiskAssessment = false;
        }
      });
  }

  loadCompanyImages() {
    this.companyService.getCompanyImages(this.riskAssessment.CompanyId)
      .subscribe({
        next: (data): void => {
          this.companyImages = Object.assign({}, data);
        },
        error: (): void => {
          this.alertService.warn('Could not load company image data.');
        }
      });
  }

  updateName() {
    const param = {...this.riskAssessment};
    param.RiskAssessmentAnswers = null;

    this.hseService.updateRiskAssessment(param)
      .subscribe({
        next: () => {
          this.alertService.success('Assessment name successfully updated.');
        },
        error: (err: IApiResponse) => {
          this.alertService.error(err?.Meta?.Message);
        }
      });
  }

  back(shouldRefresh: boolean) {
    this.riskAssessment = null;
    this.showWatermark = false;
    if (shouldRefresh) {
      this.getRiskAssessments();
    }
  }

  openInfoModal() {
    const modalRef = this.modalService.open(RiskAssessmentInformationModalComponent, {size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.companyId = this.riskAssessment.CompanyId;
    modalRef.componentInstance.hasAccess = this.canEditInformation;
  }

  openRiskAssessmentAnswerModal(assessmentItem: IRiskAssessmentItem, assessmentGroup: IRiskAssessmentGroup) {
    const modalRef = this.modalService.open(RiskAssessmentAnswerModalComponent, {size: 'lg', backdrop: 'static'});

    this.riskAnswer = this.riskAssessment.RiskAssessmentAnswers.find(i => i.AssessmentItemId === assessmentItem.Id);
    if (this.riskAnswer == null) {
      modalRef.componentInstance.riskAssessmentAnswer = {
        RiskAssessmentId: this.riskAssessment.Id,
        AssessmentItemId: assessmentItem.Id,
        ExposureValue: null,
        Likeliness: null,
        SeverityValue: null,
        InfluenceValue: null,
        MeasureValue: null,
        LevelOfMeasuresId: null,
        StatusId: null,
        RemainingExposure: null,
        RemainingLikeliness: null,
        RemainingSeverity: null,
        CreatedByUserId: this.authService.CurrentUser.UserId,
        Active: true
      } as IRiskAssessmentAnswer;
    } else {
      modalRef.componentInstance.riskAssessmentAnswer = this.riskAnswer;
    }
    modalRef.componentInstance.riskAssessmentItem = assessmentItem;
    modalRef.componentInstance.riskAssessmentGroup = assessmentGroup;

    // On modal close, read the result and apply logic
    modalRef.result.then((riskAssessmentAnswer: IRiskAssessmentAnswer) => {
      if (riskAssessmentAnswer != null) {
        this.riskAnswer = riskAssessmentAnswer;
        assessmentItem.AnswerModifiedDate = this.riskAnswer.DateModified;
        assessmentItem.IRL = riskAssessmentAnswer.RiskLevel1;
        assessmentItem.RRL = riskAssessmentAnswer.RemainingTotal;
        this.riskAssessment.RiskAssessmentAnswers.push(this.riskAnswer);
      }
    });
  }

  openRiskAssessmentReport(riskAssessment: IRiskAssessmentList) {
    const modalRef = this.modalService.open(RiskAssessmentReportModalComponent, {size: 'xl', backdrop: 'static'});
    modalRef.componentInstance.riskAssessment = riskAssessment;
    modalRef.result.then(() => { });
  }

  openRiskAssessmentCreate() {
    const modalRef = this.modalService.open(RiskAssessmentCreateModalComponent, {size: 'xl', backdrop: 'static'});

    modalRef.result.then((shouldReload: boolean) => {
      if (shouldReload) {
        this.getRiskAssessments();
      }
    }, () => { });
  }

  deleteRiskAssessment(riskAssessmentId: number) {
    const message = 'Are you sure you want to delete this Risk Assessment?';
    OpenConfirmationModal(this.modalService, message)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((answer) => {
        if (answer) {
          if (riskAssessmentId != null) {
            this.loading = true;
            this.hseService.deleteRiskAssessment(riskAssessmentId)
              .subscribe({
                next: () => {
                  this.getRiskAssessments();
                  this.loading = false;
                },
                error: () => { }
              });
          }
        }
      });
  }

  setIRLRiskLevelColor(riskItem: IRiskAssessmentItem): string {
    const irlLevel = riskItem.IRL;

    if (irlLevel == null) {
      return '';
    }
    if (irlLevel >= 0 && irlLevel <= 20) {
      return 'risk-level-green';
    }
    if (irlLevel > 20 && irlLevel <= 70) {
      return 'risk-level-lightgreen';
    }
    if (irlLevel > 70 && irlLevel <= 200) {
      return 'risk-level-yellow';
    }
    if (irlLevel > 200 && irlLevel <= 400) {
      return 'risk-level-orange';
    }
    if (irlLevel > 400) {
      return 'risk-level-red';
    }
  }

  setRRLRiskLevelColor(riskItem: IRiskAssessmentItem): string {
    const rrlLevel = riskItem.RRL;

    if (rrlLevel == null || riskItem.AnswerModifiedDate == null) {
      return '';
    }
    if (rrlLevel >= 0 && rrlLevel <= 20) {
      return 'risk-level-green';
    }
    if (rrlLevel > 20 && rrlLevel <= 70) {
      return 'risk-level-lightgreen';
    }
    if (rrlLevel > 70 && rrlLevel <= 200) {
      return 'risk-level-yellow';
    }
    if (rrlLevel > 200 && rrlLevel <= 400) {
      return 'risk-level-orange';
    }
    if (rrlLevel > 400) {
      return 'risk-level-red';
    }
  }

  generateYearsBetween() {
    const startYear: number = 2014;
    let endYear: number = new Date().getFullYear() + 1;
    const years = [];

    for (let i = endYear; i >= startYear; i--) {
      years.push(endYear);
      endYear--;
    }
    return years;
  }
}
