<!-------------------------------------------------------->
<!-- Show tender quote if it has not yet been accepted -->
<!-------------------------------------------------------->
<app-rate-quote *ngIf="tenderService.TenderView.QuoteAcceptedDate === null"
                (OnAccept)="startTenderChecker()"
                (OnProcessed)="loadCompletedTenderView(tenderService.TenderView.Id)">
</app-rate-quote>

<!--------------------------------------------------------->
<!-- Show tender rate results if Quote has been accepted -->
<!--------------------------------------------------------->
<ng-container *ngIf="tenderService.TenderView.QuoteAcceptedDate !== null">
  <ng-container *ngIf="tenderService.TenderView.IsProcessed === false">
    <div class="row mt-5 mb-5 pb-5">
      <div class="col-8 offset-2 text-center">
        <app-loader [Width]="80"></app-loader>
        <h4 class="mt-4 harmony-secondary-text">
          Your rates are being calculated <br> <b>You will receive an email when they are ready to be exported</b>
        </h4>
        <p class="mt-4 harmony-grey-text">
          Once the email has been received, you can return to this screen and you will be able to configure your rate sources before exporting the final rate results to an Excel file.
        </p>
        <p class="mt-2 harmony-grey-text">
          Thank you for your patience.
        </p>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="tenderService.TenderView.IsProcessed === true">
    <div class="row mt-2">
      <div class="col-12 text-start harmony-grey-text">
        <p class="mb-0">
          <small>
            <fa-icon [icon]="faInfo"></fa-icon> We have tried to select the best rates, based on the locations you entered and your preferred port.
          </small>
        </p>
        <p>
          <small>
            <fa-icon [icon]="faInfo"></fa-icon> It is possible that there is no rate available for a location. We will send out requests to agents active in this area, so our service keeps improving.
          </small>
        </p>
      </div>
    </div>
    <div class="row mb-5 pb-5">
      <div class="col-12">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item">
            <a class="nav-link harmony-primary-text fw-bold active" id="overview-tab" data-bs-toggle="tab" href="#overview" role="tab" aria-controls="overview" aria-selected="true">
              <b>Overview</b>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link harmony-primary-text fw-bold" id="services-tab" data-bs-toggle="tab" href="#services" role="tab" aria-controls="services" aria-selected="true">
              <b>Services</b>
            </a>
          </li>
          <li class="nav-item" *ngIf="canShowModality(1)">
            <a class="nav-link harmony-tertiary-text" id="air-origin-tab" data-bs-toggle="tab" href="#airOrigin" role="tab" aria-controls="airOrigin" aria-selected="false">
              AIR Origin
            </a>
          </li>
          <li class="nav-item" *ngIf="canShowFreightModality(1)">
            <a class="nav-link harmony-tertiary-text" (click)="checkFreight(1)" id="air-freight-tab" data-bs-toggle="tab" href="#airFreight" role="tab" aria-controls="airFreight" aria-selected="false">
              AIR Freight
            </a>
          </li>
          <li class="nav-item" *ngIf="canShowModality(1)">
            <a class="nav-link harmony-tertiary-text" id="air-destination-tab" data-bs-toggle="tab" href="#airDestination" role="tab" aria-controls="airDestination" aria-selected="false">
              AIR Destination
            </a>
          </li>
          <li class="nav-item" *ngIf="canShowModality(2)">
            <a class="nav-link harmony-tertiary-text" id="fcl-origin-tab" data-bs-toggle="tab" href="#fclOrigin" role="tab" aria-controls="fclOrigin" aria-selected="false">
              FCL Origin
            </a>
          </li>
          <li class="nav-item" *ngIf="canShowFreightModality(2)">
            <a class="nav-link harmony-tertiary-text" (click)="checkFreight(2)" id="fcl-freight-tab" data-bs-toggle="tab" href="#fclFreight" role="tab" aria-controls="fclFreight" aria-selected="false">
              FCL Freight
            </a>
          </li>
          <li class="nav-item" *ngIf="canShowModality(2)">
            <a class="nav-link harmony-tertiary-text" id="fcl-destination-tab" data-bs-toggle="tab" href="#fclDestination" role="tab" aria-controls="fclDestination" aria-selected="false">
              FCL Destination
            </a>
          </li>
          <li class="nav-item" *ngIf="canShowModality(3)">
            <a class="nav-link harmony-tertiary-text" id="lcl-origin-tab" data-bs-toggle="tab" href="#lclOrigin" role="tab" aria-controls="lclOrigin" aria-selected="false">
              LCL Origin
            </a>
          </li>
          <li class="nav-item" *ngIf="canShowFreightModality(3)">
            <a class="nav-link harmony-tertiary-text" (click)="checkFreight(3)" id="lcl-freight-tab" data-bs-toggle="tab" href="#lclFreight" role="tab" aria-controls="lclFreight" aria-selected="false">
              LCL Freight
            </a>
          </li>
          <li class="nav-item" *ngIf="canShowModality(3)">
            <a class="nav-link harmony-tertiary-text" id="lcl-destination-tab" data-bs-toggle="tab" href="#lclDestination" role="tab" aria-controls="lclDestination" aria-selected="false">
              LCL Destination
            </a>
          </li>
          <li class="nav-item" *ngIf="canShowModality(4)">
            <a class="nav-link harmony-tertiary-text" id="road-origin-tab" data-bs-toggle="tab" href="#roadOrigin" role="tab" aria-controls="roadOrigin" aria-selected="false">
              ROAD Origin
            </a>
          </li>
          <li class="nav-item" *ngIf="canShowModality(4)">
            <a class="nav-link harmony-tertiary-text" id="road-destination-tab" data-bs-toggle="tab" href="#roadDestination" role="tab" aria-controls="roadDestination" aria-selected="false">
              ROAD Destination
            </a>
          </li>
        </ul>
        <div class="tab-content mt-2 p-1" id="myTabContent">
          <!----------------->
          <!--- Overview ---->
          <!----------------->
          <div class="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="overview-tab">
            <div class="row">
              <div class="col-5">
                <div class="row">
                  <div class="col-12 harmony-primary-text">
                    <h4 class="pb-1 border-bottom">Details</h4>
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-4">
                    <b>
                      Tender Name
                    </b>
                  </div>
                  <div class="col-8">
                    <p class="d-inline">
                      {{ tenderService.TenderView.Name }}
                    </p>
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-4">
                    <b>
                      Member
                    </b>
                  </div>
                  <div class="col-8">
                    <p class="d-inline">
                      {{ tenderService.SelectedCompany ? tenderService.SelectedCompany.Name : '...' }}
                    </p>
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-4">
                    <b>
                      Default currency
                    </b>
                  </div>
                  <div class="col-8">
                    <p class="d-inline">
                      {{ tenderService.SelectedCurrency ? (this.tenderService.SelectedCurrency.Currency.Name + ' (' + this.tenderService.SelectedCurrency.Currency.Code + ')') : '...' }}
                    </p>
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-4">
                    <b>
                      Rates valid until
                    </b>
                  </div>
                  <div class="col-8">
                    <p class="d-inline">
                      {{ tenderService.TenderView.ExpiryDate | date: environment.FormattingStandards.ShortDateFormat }}
                    </p>
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-4">
                    <b>
                      Locations
                    </b>
                  </div>
                  <div class="col-8">
                    <p class="d-inline">
                      {{ tenderService.TenderView.TenderLocations.length }}
                    </p>
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-4">
                    <b>
                      Freight Lanes
                    </b>
                  </div>
                  <div class="col-8">
                    <p class="d-inline">
                      {{ tenderService.TenderView.TenderFreights.length }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-3">
                <div class="row">
                  <div class="col-12 harmony-primary-text">
                    <h4 class="pb-1 border-bottom">Margins</h4>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <b>Percentage</b>
                  </div>
                  <div class="col-4">
                    {{ tenderService.TenderView.DefaultMarginPercentage }} %
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <b class="d-inline">
                      Minimum
                    </b>
                  </div>
                  <div class="col-4">
                    {{ tenderService.TenderView.DefaultMarginMinimum }} {{ this.tenderService.SelectedCurrency ? this.tenderService.SelectedCurrency.Currency.Code : '...' }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <b>Maximum</b>
                  </div>
                  <div class="col-4">
                    {{ tenderService.TenderView.DefaultMarginMaximum }} {{ this.tenderService.SelectedCurrency ? this.tenderService.SelectedCurrency.Currency.Code : '...' }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <img src="../../../../../assets/branding/logo/HRN_logo_RGB.png" class="member-logo-img" alt="Harmony Relocation Network">
              </div>
            </div>
          </div>
          <!----------------------------------->
          <!-- Services Inclusions/Exclusion -->
          <!----------------------------------->
          <div class="tab-pane fade" id="services" role="tabpanel" aria-labelledby="services-tab">
            <div class="row">
              <div class="col-6">
                <div class="row">
                  <div class="col-12 text-center">
                    <h4 class="inclusion-header border-bottom">Included Services</h4>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="accordion" id="accordionInclusions">
                      <div class="card">
                        <div class="card-header" id="originInclusion">
                          <button class="btn-services text-center w-100"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseOriginInclusion"
                                  aria-expanded="false"
                                  aria-controls="collapseOriginInclusion">
                            Origin
                          </button>
                        </div>
                        <div id="collapseOriginInclusion" class="collapse" aria-labelledby="originInclusion" data-bs-parent="#accordionInclusions">
                          <div class="card-body">
                            <div class="row service-row">
                              <div class="col-6" *ngFor="let acc of tenderService.GetInclusionsByType('Origin')">
                                <p class="mb-0">{{ acc.ServiceType.Name ? acc.ServiceType.Name : '-' }}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card mt-1">
                        <div class="card-header" id="freightInclusion">
                          <button class="btn-services text-center collapsed w-100"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseFreightInclusion"
                                  aria-expanded="false"
                                  aria-controls="collapseFreightInclusion">
                            Freight
                          </button>
                        </div>
                        <div id="collapseFreightInclusion" class="collapse" aria-labelledby="freightInclusion" data-bs-parent="#accordionInclusions">
                          <div class="card-body">
                            <div class="row service-row">
                              <div class="col-6" *ngFor="let acc of tenderService.GetInclusionsByType('Freight')">
                                <p class="mb-0">{{ acc.ServiceType.Name ? acc.ServiceType.Name : '-' }}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card mt-1">
                        <div class="card-header" id="destinationInclusion">
                          <button class="btn-services text-center collapsed w-100"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseDestinationInclusion"
                                  aria-expanded="false"
                                  aria-controls="collapseDestinationInclusion">
                            Destination
                          </button>
                        </div>
                        <div id="collapseDestinationInclusion" class="collapse" aria-labelledby="destinationInclusion" data-bs-parent="#accordionInclusions">
                          <div class="card-body">
                            <div class="row service-row">
                              <div class="col-6" *ngFor="let acc of tenderService.GetInclusionsByType('Destination')">
                                <p class="mb-0">{{ acc.ServiceType.Name ? acc.ServiceType.Name : '-' }}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-12 text-center">
                    <h4 class="inclusion-header border-bottom">Excluded Services</h4>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="accordion" id="accordionExclusions">
                      <div class="card">
                        <div class="card-header" id="originExclusion">
                          <button class="btn-services text-center w-100"
                                  type="button" data-bs-toggle="collapse"
                                  data-bs-target="#collapseOriginExclusion"
                                  aria-expanded="false"
                                  aria-controls="collapseOriginExclusion">
                            Origin
                          </button>
                        </div>
                        <div id="collapseOriginExclusion" class="collapse" aria-labelledby="originExclusion" data-bs-parent="#accordionExclusions">
                          <div class="card-body">
                            <div class="row service-row">
                              <div class="col-6" *ngFor="let acc of tenderService.GetExclusionsByType('Origin')">
                                <p class="mb-0">{{ acc.ServiceType.Name ? acc.ServiceType.Name : '-' }}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card mt-1">
                        <div class="card-header" id="freightExclusion">
                          <button class="btn-services text-center collapsed w-100"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseFreightExclusion"
                                  aria-expanded="false"
                                  aria-controls="collapseFreightExclusion">
                            Freight
                          </button>
                        </div>
                        <div id="collapseFreightExclusion" class="collapse" aria-labelledby="freightExclusion" data-bs-parent="#accordionExclusions">
                          <div class="card-body">
                            <div class="row service-row">
                              <div class="col-6" *ngFor="let acc of tenderService.GetExclusionsByType('Freight')">
                                <p class="mb-0">{{ acc.ServiceType.Name ? acc.ServiceType.Name : '-' }}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card mt-1">
                        <div class="card-header" id="destinationExclusion">
                          <button class="btn-services text-center collapsed w-100"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseDestinationExclusion"
                                  aria-expanded="false"
                                  aria-controls="collapseDestinationExclusion">
                            Destination
                          </button>
                        </div>
                        <div id="collapseDestinationExclusion" class="collapse" aria-labelledby="destinationExclusion" data-bs-parent="#accordionExclusions">
                          <div class="card-body">
                            <div class="row service-row">
                              <div class="col-6" *ngFor="let acc of tenderService.GetExclusionsByType('Destination')">
                                <p class="mb-0">{{ acc.ServiceType.Name ? acc.ServiceType.Name : '-' }}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!---------------->
          <!-- AIR Origin -->
          <!---------------->
          <div
            *ngIf="canShowModality(1)"
            class="tab-pane fade"
            id="airOrigin"
            role="tabpanel"
            aria-labelledby="air-origin-tab">
            <p class="mb-0 harmony-grey-text" *ngIf="!tenderService.IsExported()">
              <small>
                <fa-icon [icon]="faInfo"></fa-icon> If you would like to use a different rate source for a specific location, click on the <fa-icon class="harmony-tertiary-text" [icon]="faEdit"></fa-icon> : “Change Rate Source” icon.
              </small>
            </p>
            <div class="row">
              <div class="col-6 text-start">
                Total locations: <b>{{ getOriginLocations().length }}</b>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12" *ngIf="!locationsLoading">
                <div class="table-wrapper">
                  <table class="table">
                    <!-- Header -->
                    <thead class="rate-table-header">
                    <tr>
                      <th scope="col">
                        Location
                      </th>
                      <th scope="col">
                        POD
                      </th>
                      <th scope="col">
                        Rate Source
                      </th>
                      <th scope="col" *ngFor="let modality of tenderService.GetTenderModalitiesByType(1)">
                        <b>{{ modality.Quantity }}</b> {{ modality.UnitType.Abbreviation }}
                      </th>
                    </tr>
                    </thead>
                    <!-- Data -->
                    <tbody>
                      <tr *ngFor="let location of getOriginLocations()">
                      <th scope="row">
                        {{ location.PartnerLocationName }}
                      </th>
                      <td>
                        <b>{{ location.AirPort?.Description }}</b> ({{ location.AirPort?.Code }})
                      </td>
                      <ng-container *ngVar="getLocationRateGroup(location, 1, false) as locationRateGroup">
                        <td class="text-nowrap">
                          <div class="row" *ngIf="locationRateGroup.RateGroupOptions && locationRateGroup.RateGroupOptions.length > 0 && !tenderService.IsExported()">
                            <div class="col-12">
                              <select class="form-select form-select-sm" [(ngModel)]="locationRateGroup.RateGroupId" (change)="updateTenderLocationRateGroupRates(locationRateGroup)">
                                <option *ngFor="let rateGroup of locationRateGroup.RateGroupOptions" [value]="rateGroup.ID">
                                  {{ rateGroup.IsHarmonyMember ? '(H) ' : '' }}{{ rateGroup.AgentCompanyName }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="row" *ngIf="!locationRateGroup.RateGroupOptions || locationRateGroup.RateGroupOptions.length <= 0 || tenderService.IsExported()">
                            <div class="col-10 overflow-hidden">
                              {{ locationRateGroup.RateGroupName }}
                            </div>
                            <div class="col-2 text-end" *ngIf="!tenderService.IsExported()">
                              <fa-icon [icon]="faEdit"
                                       class="harmony-tertiary-text pointer"
                                       [title]="'Change Rate Source'"
                                       *ngIf="!locationRateGroup.IsLoadingOptions"
                                       (click)="getRateGroupOptions(locationRateGroup, location, location.AirportCityFunctionId, rateTypes.AIROrigin)"></fa-icon>
                              <app-loader [Width]="20" *ngIf="locationRateGroup.IsLoadingOptions"></app-loader>
                            </div>
                          </div>
                        </td>
                        <td *ngFor="let locationRate of locationRateGroup.TenderLocationRates">
                          <b class="small">{{ tenderService.SelectedCurrency ? tenderService.SelectedCurrency.Currency.Code : '' }}</b>
                          {{ locationRate.TotalRate ? (locationRate.TotalRate | number:'1.2-2') : '&nbsp;&nbsp;-' }}
                        </td>
                      </ng-container>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-12 text-center" *ngIf="locationsLoading">
                <app-loader [Width]="50"></app-loader>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-6 text-start">
                Total locations: <b>{{ getOriginLocations().length }}</b>
              </div>
            </div>
          </div>
          <!--------------------->
          <!-- AIR Freight ------>
          <!--------------------->
          <div
            *ngIf="canShowFreightModality(1)"
            class="tab-pane fade"
            id="airFreight"
            role="tabpanel"
            aria-labelledby="air-freight-tab">
            <p class="mb-0 harmony-grey-text">
              <small>
                <fa-icon [icon]="faInfo"></fa-icon> It is currently not possible to change the rate source for Freight rates.
              </small>
            </p>
            <div class="row ms-1 me-1">
              <div class="col-12">
                <app-pagination [showPageNumber]=true
                                [showRowsPerPage]=true
                                [paginationData]=airPaginationData
                                (pageEvent)="onPage(1, $event, airPaginationData)"
                                [page]="airPage">
                </app-pagination>
              </div>
            </div>
            <div class="row mt-2" *ngIf="!freightLoading">
              <div class="col-12">
                <div class="table-wrapper">
                  <table class="table">
                    <!-- Header -->
                    <thead class="rate-table-header">
                      <tr>
                        <th scope="col" style="width: 4em;" class="text-center">
                          Origin
                        </th>
                        <th style="width: 2em;">

                        </th>
                        <th scope="col" style="width: 4em;" class="text-center">
                          Destination
                        </th>
                        <th scope="col">
                          Rate Source
                        </th>
                        <th scope="col" *ngFor="let modality of tenderService.GetTenderModalitiesByType(1)">
                          <b>{{ modality.Quantity }}</b> {{ modality.UnitType.Abbreviation }}
                        </th>
                      </tr>
                    </thead>
                    <!-- Data -->
                    <tbody *ngIf="airPaginationData && airPaginationData.DataSet.length > 0">
                      <tr *ngFor="let tenderFreight of airPaginationData.DataSet">
                        <th class="text-center overflow-hidden" style="width: 4em">
                          <b>{{ tenderFreight.TenderFreightRateGroups.length > 0 ? tenderFreight.TenderFreightRateGroups[0].DeparturePortName : '-'}}</b>
                        </th>
                        <td class="text-center" style="width: 2em;">
                          <fa-icon [icon]="faArrowRight"></fa-icon>
                        </td>
                        <td class="text-center overflow-hidden" style="width: 4em">
                          <b>{{ tenderFreight.TenderFreightRateGroups.length > 0 ? tenderFreight.TenderFreightRateGroups[0].ArrivalPortName : '-' }}</b>
                        </td>
                        <td>
                          <div class="row">
                            <div class="col-12 overflow-hidden">
                              {{ tenderFreight.TenderFreightRateGroups.length > 0 ? tenderFreight.TenderFreightRateGroups[0].RateGroupName : '-' }}
                            </div>
                          </div>
                        </td>
                        <td *ngFor="let freightRate of tenderFreight.TenderFreightRateGroups">
                          <b class="small">{{ tenderService.SelectedCurrency ? tenderService.SelectedCurrency.Currency.Code : '' }}</b>
                          {{ freightRate.TotalRate ? (freightRate.TotalRate  | number:'1.2-2') : '&nbsp;&nbsp;-' }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="row mt-3" *ngIf="freightLoading">
              <div class="col-12 text-center">
                <app-loader [Width]="50"></app-loader>
              </div>
            </div>
            <div class="row ms-1 me-1">
              <div class="col-12">
                <app-pagination [showPageNumber]=true
                                [showRowsPerPage]=true
                                [paginationData]=airPaginationData
                                (pageEvent)="onPage(1, $event, airPaginationData)"
                                [page]="airPage">
                </app-pagination>
              </div>
            </div>
          </div>
          <!--------------------->
          <!-- AIR Destination -->
          <!--------------------->
          <div
            *ngIf="canShowModality(1)"
            class="tab-pane fade"
            id="airDestination"
            role="tabpanel"
            aria-labelledby="air-destination-tab">
            <p class="mb-0 harmony-grey-text" *ngIf="!tenderService.IsExported()">
              <small>
                <fa-icon [icon]="faInfo"></fa-icon> If you would like to use a different rate source for a specific location, click on the <fa-icon class="harmony-tertiary-text" [icon]="faEdit"></fa-icon> : “Change Rate Source” icon.
              </small>
            </p>
            <div class="row">
              <div class="col-6 text-start">
                Total locations: <b>{{ getDestinationLocations().length }}</b>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12" *ngIf="!locationsLoading">
                <div class="table-wrapper">
                  <table class="table">
                    <!-- Header -->
                    <thead class="rate-table-header">
                    <tr>
                      <th scope="col">
                        Location
                      </th>
                      <th scope="col">
                        POA
                      </th>
                      <th scope="col">
                        Rate Source
                      </th>
                      <th scope="col" *ngFor="let modality of tenderService.GetTenderModalitiesByType(1)">
                        <b>{{ modality.Quantity }}</b> {{ modality.UnitType.Abbreviation }}
                      </th>
                    </tr>
                    </thead>
                    <!-- Data -->
                    <tbody>
                    <tr *ngFor="let location of getDestinationLocations()">
                      <th scope="row">
                        {{ location.PartnerLocationName }}
                      </th>
                      <td>
                        <b>{{ location.AirPort?.Description }}</b> ({{ location.AirPort?.Code }})
                      </td>
                      <ng-container *ngVar="getLocationRateGroup(location, 1, true) as locationRateGroup">
                        <td>
                          <div class="row" *ngIf="locationRateGroup.RateGroupOptions && locationRateGroup.RateGroupOptions.length > 0 && !tenderService.IsExported()">
                            <div class="col-12">
                              <select class="form-select form-select-sm" [(ngModel)]="locationRateGroup.RateGroupId" (change)="updateTenderLocationRateGroupRates(locationRateGroup)">
                                <option *ngFor="let rateGroup of locationRateGroup.RateGroupOptions" [value]="rateGroup.ID">{{ rateGroup.AgentCompanyName }}</option>
                              </select>
                            </div>
                          </div>
                          <div class="row" *ngIf="!locationRateGroup.RateGroupOptions || locationRateGroup.RateGroupOptions.length < 1 || tenderService.IsExported()">
                            <div class="col-10 overflow-hidden">
                              {{ locationRateGroup.RateGroupName }}
                            </div>
                            <div class="col-2 text-end" *ngIf="!tenderService.IsExported()">
                              <fa-icon [icon]="faEdit"
                                       [title]="'Change Rate Source'"
                                       class="harmony-tertiary-text pointer"
                                       *ngIf="!locationRateGroup.IsLoadingOptions"
                                       (click)="getRateGroupOptions(locationRateGroup, location, location.AirportCityFunctionId, rateTypes.AIRDestination)"></fa-icon>
                              <app-loader [Width]="20" *ngIf="locationRateGroup.IsLoadingOptions"></app-loader>
                            </div>
                          </div>
                        </td>
                        <td *ngFor="let locationRate of locationRateGroup.TenderLocationRates">
                          <b class="small">{{ tenderService.SelectedCurrency ? tenderService.SelectedCurrency.Currency.Code : '' }}</b>
                          {{ locationRate.TotalRate ? (locationRate.TotalRate | number:'1.2-2') : '&nbsp;&nbsp;-' }}
                        </td>
                      </ng-container>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-12 text-center" *ngIf="locationsLoading">
                <app-loader [Width]="50"></app-loader>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-6 text-start">
                Total locations: <b>{{ getDestinationLocations().length }}</b>
              </div>
            </div>
          </div>

          <!---------------->
          <!-- FCL Origin -->
          <!---------------->
          <div
            *ngIf="canShowModality(2)"
            class="tab-pane fade"
            id="fclOrigin"
            role="tabpanel"
            aria-labelledby="fcl-origin-tab">
            <p class="mb-0 harmony-grey-text" *ngIf="!tenderService.IsExported()">
              <small>
                <fa-icon [icon]="faInfo"></fa-icon> If you would like to use a different rate source for a specific location, click on the <fa-icon class="harmony-tertiary-text" [icon]="faEdit"></fa-icon> : “Change Rate Source” icon.
              </small>
            </p>
            <div class="row">
              <div class="col-6 text-start">
                Total locations: <b>{{ getOriginLocations().length }}</b>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12" *ngIf="!locationsLoading">
                <div class="table-wrapper">
                  <table class="table">
                    <!-- Header -->
                    <thead class="rate-table-header">
                    <tr>
                      <th scope="col">
                        Location
                      </th>
                      <th scope="col">
                        POD
                      </th>
                      <th scope="col">
                        Rate Source
                      </th>
                      <th scope="col" *ngFor="let modality of tenderService.GetTenderModalitiesByType(2)">
                        <b>{{ modality.Quantity }}</b> {{ modality.UnitType.Abbreviation }}
                      </th>
                    </tr>
                    </thead>
                    <!-- Data -->
                    <tbody>
                      <tr *ngFor="let location of getOriginLocations()">
                        <th scope="row">
                          {{ location.PartnerLocationName }}
                        </th>
                        <td>
                          <b>{{ location.SeaPort?.Description }}</b> ({{ location.SeaPort?.Code }})
                        </td>
                        <ng-container *ngVar="getLocationRateGroup(location, 2, false) as locationRateGroup">
                          <td>
                            <div class="row" *ngIf="locationRateGroup.RateGroupOptions && locationRateGroup.RateGroupOptions.length > 0 && !tenderService.IsExported()">
                              <div class="col-12 overflow-hidden">
                                <select class="form-select form-select-sm"
                                        [(ngModel)]="locationRateGroup.RateGroupId"
                                        (change)="updateTenderLocationRateGroupRates(locationRateGroup)">
                                  <option *ngFor="let rateGroup of locationRateGroup.RateGroupOptions"
                                          [value]="rateGroup.ID">
                                    {{ rateGroup.AgentCompanyName }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="row" *ngIf="!locationRateGroup.RateGroupOptions || locationRateGroup.RateGroupOptions.length < 1 || tenderService.IsExported()">
                              <div class="col-10">
                                {{ locationRateGroup.RateGroupName }}
                              </div>
                              <div class="col-2 text-end" *ngIf="!tenderService.IsExported()">
                                <fa-icon [icon]="faEdit"
                                         class="harmony-tertiary-text pointer"
                                         *ngIf="!locationRateGroup.IsLoadingOptions"
                                         [title]="'Change Rate Source'"
                                         (click)="getRateGroupOptions(locationRateGroup, location, location.SeaPortCityFunctionId, rateTypes.FCLOrigin)"></fa-icon>
                                <app-loader [Width]="20" *ngIf="locationRateGroup.IsLoadingOptions"></app-loader>
                              </div>
                            </div>
                          </td>
                          <td *ngFor="let locationRate of locationRateGroup.TenderLocationRates">
                            <b class="small">{{ tenderService.SelectedCurrency ? tenderService.SelectedCurrency.Currency.Code : '' }}</b>
                            {{ locationRate.TotalRate ? (locationRate.TotalRate | number:'1.2-2') : '&nbsp;&nbsp;-' }}
                          </td>
                        </ng-container>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-12 text-center" *ngIf="locationsLoading">
                <app-loader [Width]="50"></app-loader>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-6 text-start">
                Total locations: <b>{{ getOriginLocations().length }}</b>
              </div>
            </div>
          </div>
          <!----------------->
          <!-- FCL Freight -->
          <!----------------->
          <div
            *ngIf="canShowFreightModality(2)"
            class="tab-pane fade"
            id="fclFreight"
            role="tabpanel"
            aria-labelledby="fcl-freight-tab">
            <p class="mb-0 harmony-grey-text">
              <small>
                <fa-icon [icon]="faInfo"></fa-icon> It is currently not possible to change the rate source for Freight rates.
              </small>
            </p>
            <div class="row ms-1 me-1">
              <div class="col-12">
                <app-pagination [showPageNumber]=true
                                [showRowsPerPage]=true
                                [paginationData]=fclPaginationData
                                (pageEvent)="onPage(2, $event, fclPaginationData)"
                                [page]="fclPage">
                </app-pagination>
              </div>
            </div>
            <div class="row mt-2" *ngIf="!freightLoading">
              <div class="col-12">
                <div class="table-wrapper">
                  <table class="table">
                    <!-- Header -->
                    <thead class="rate-table-header">
                    <tr>
                      <th scope="col" style="width: 4em;" class="text-center">
                        Origin
                      </th>
                      <th style="width: 2em;">

                      </th>
                      <th scope="col" style="width: 4em;" class="text-center">
                        Destination
                      </th>
                      <th scope="col">
                        Rate Source
                      </th>
                      <th scope="col" *ngFor="let modality of tenderService.GetTenderModalitiesByType(2)">
                        <b>{{ modality.Quantity }}</b> {{ modality.UnitType.Abbreviation }}
                      </th>
                    </tr>
                    </thead>
                    <!-- Data -->
                    <tbody *ngIf="fclPaginationData && fclPaginationData.DataSet.length > 0">
                    <tr *ngFor="let freightRate of fclPaginationData.DataSet">
                      <th class="text-center overflow-hidden" style="width: 4em;">
                        <b>{{ freightRate.TenderFreightRateGroups.length > 0 ? freightRate.TenderFreightRateGroups[0].DeparturePortName : '-' }}</b>
                      </th>
                      <td class="text-center" style="width: 2em;">
                        <fa-icon [icon]="faArrowRight"></fa-icon>
                      </td>
                      <td class="text-center overflow-hidden" style="width: 4em;">
                        <b>{{ freightRate.TenderFreightRateGroups.length > 0 ? freightRate.TenderFreightRateGroups[0].ArrivalPortName : '-' }}</b>
                      </td>
                      <td>
                        <div class="row">
                          <div class="col-12 overflow-hidden">
                            {{ freightRate.TenderFreightRateGroups.length > 0 ? freightRate.TenderFreightRateGroups[0].RateGroupName : '-' }}
                          </div>
                        </div>
                      </td>
                      <td *ngFor="let freightRate of freightRate.TenderFreightRateGroups">
                        <b class="small">{{ tenderService.SelectedCurrency ? tenderService.SelectedCurrency.Currency.Code : '' }}</b>
                        {{ freightRate.TotalRate ? (freightRate.TotalRate  | number:'1.2-2') : '&nbsp;&nbsp;-' }}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="row mt-3" *ngIf="freightLoading">
              <div class="col-12 text-center">
                <app-loader [Width]="50"></app-loader>
              </div>
            </div>
            <div class="row ms-1 me-1">
              <div class="col-12">
                <app-pagination [showPageNumber]=true
                                [showRowsPerPage]=true
                                [paginationData]=fclPaginationData
                                (pageEvent)="onPage(2, $event, fclPaginationData)"
                                [page]="fclPage">
                </app-pagination>
              </div>
            </div>
          </div>
          <!--------------------->
          <!-- FCL Destination -->
          <!--------------------->
          <div
            *ngIf="canShowModality(2)"
            class="tab-pane fade"
            id="fclDestination"
            role="tabpanel"
            aria-labelledby="fcl-destination-tab">
            <p class="mb-0 harmony-grey-text" *ngIf="!tenderService.IsExported()">
              <small>
                <fa-icon [icon]="faInfo"></fa-icon> If you would like to use a different rate source for a specific location, click on the <fa-icon class="harmony-tertiary-text" [icon]="faEdit"></fa-icon> : “Change Rate Source” icon.
              </small>
            </p>
            <div class="row">
              <div class="col-6 text-start">
                Total locations: <b>{{ getDestinationLocations().length }}</b>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12" *ngIf="!locationsLoading">
                <div class="table-wrapper">
                  <table class="table">
                    <!-- Header -->
                    <thead class="rate-table-header">
                    <tr>
                      <th scope="col">
                        Location
                      </th>
                      <th scope="col">
                        POA
                      </th>
                      <th scope="col">
                        Rate Source
                      </th>
                      <th scope="col" *ngFor="let modality of tenderService.GetTenderModalitiesByType(2)">
                        <b>{{ modality.Quantity }}</b> {{ modality.UnitType.Abbreviation }}
                      </th>
                    </tr>
                    </thead>
                    <!-- Data -->
                    <tbody>
                    <tr *ngFor="let location of getDestinationLocations()">
                      <th scope="row">
                        {{ location.PartnerLocationName }}
                      </th>
                      <td>
                        <b>{{ location.SeaPort?.Description }}</b> ({{ location.SeaPort?.Code }})
                      </td>
                      <ng-container *ngVar="getLocationRateGroup(location, 2, true) as locationRateGroup">
                        <td>
                          <div class="row" *ngIf="locationRateGroup.RateGroupOptions && locationRateGroup.RateGroupOptions.length > 0 && !tenderService.IsExported()">
                            <div class="col-12">
                              <select class="form-select form-select-sm" [(ngModel)]="locationRateGroup.RateGroupId" (change)="updateTenderLocationRateGroupRates(locationRateGroup)">
                                <option *ngFor="let rateGroup of locationRateGroup.RateGroupOptions" [value]="rateGroup.ID">{{ rateGroup.AgentCompanyName }}</option>
                              </select>
                            </div>
                          </div>
                          <div class="row" *ngIf="!locationRateGroup.RateGroupOptions || locationRateGroup.RateGroupOptions.length < 1 || tenderService.IsExported()">
                            <div class="col-10 overflow-hidden">
                              {{ locationRateGroup.RateGroupName }}
                            </div>
                            <div class="col-2 text-end" *ngIf="!tenderService.IsExported()">
                              <fa-icon [icon]="faEdit"
                                       class="harmony-tertiary-text pointer"
                                       *ngIf="!locationRateGroup.IsLoadingOptions"
                                       [title]="'Change Rate Source'"
                                       (click)="getRateGroupOptions(locationRateGroup, location, location.SeaPortCityFunctionId, rateTypes.FCLDestination)"></fa-icon>
                              <app-loader [Width]="20" *ngIf="locationRateGroup.IsLoadingOptions"></app-loader>
                            </div>
                          </div>
                        </td>
                        <td *ngFor="let locationRate of locationRateGroup.TenderLocationRates">
                          <b class="small">{{ tenderService.SelectedCurrency ? tenderService.SelectedCurrency.Currency.Code : '' }}</b>
                          {{ locationRate.TotalRate ? (locationRate.TotalRate | number:'1.2-2') : '&nbsp;&nbsp;-' }}
                        </td>
                      </ng-container>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-12 text-center" *ngIf="locationsLoading">
                <app-loader [Width]="50"></app-loader>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-6 text-start">
                Total locations: <b>{{ getDestinationLocations().length }}</b>
              </div>
            </div>
          </div>

          <!---------------->
          <!-- LCL Origin -->
          <!---------------->
          <div *ngIf="canShowModality(3)"
               class="tab-pane fade"
               id="lclOrigin"
               role="tabpanel"
               aria-labelledby="lcl-origin-tab">
            <p class="mb-0 harmony-grey-text" *ngIf="!tenderService.IsExported()">
              <small>
                <fa-icon [icon]="faInfo"></fa-icon> If you would like to use a different rate source for a specific location, click on the <fa-icon class="harmony-tertiary-text" [icon]="faEdit"></fa-icon> : “Change Rate Source” icon.
              </small>
            </p>
            <div class="row">
              <div class="col-6 text-start">
                Total locations: <b>{{ getOriginLocations().length }}</b>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12" *ngIf="!locationsLoading">
                <div class="table-wrapper">
                  <table class="table">
                    <!-- Header -->
                    <thead class="rate-table-header">
                    <tr>
                      <th scope="col">
                        Location
                      </th>
                      <th scope="col">
                        POD
                      </th>
                      <th scope="col">
                        Rate Source
                      </th>
                      <th scope="col" *ngFor="let modality of tenderService.GetTenderModalitiesByType(3)">
                        <b>{{ modality.Quantity }}</b> {{ modality.UnitType.Abbreviation }}
                      </th>
                    </tr>
                    </thead>
                    <!-- Data -->
                    <tbody>
                    <tr *ngFor="let location of getOriginLocations()">
                      <th scope="row">
                        {{ location.PartnerLocationName }}
                      </th>
                      <td>
                        <b>{{ location.SeaPort?.Description }}</b> ({{ location.SeaPort?.Code }})
                      </td>
                      <ng-container *ngVar="getLocationRateGroup(location, 3, false) as locationRateGroup">
                        <td>
                          <div class="row" *ngIf="locationRateGroup.RateGroupOptions && locationRateGroup.RateGroupOptions.length > 0 && !tenderService.IsExported()">
                            <div class="col-12">
                              <select class="form-select form-select-sm" [(ngModel)]="locationRateGroup.RateGroupId" (change)="updateTenderLocationRateGroupRates(locationRateGroup)">
                                <option *ngFor="let rateGroup of locationRateGroup.RateGroupOptions" [value]="rateGroup.ID">{{ rateGroup.AgentCompanyName }}</option>
                              </select>
                            </div>
                          </div>
                          <div class="row" *ngIf="!locationRateGroup.RateGroupOptions || locationRateGroup.RateGroupOptions.length < 1 || tenderService.IsExported()">
                            <div class="col-10 overflow-hidden">
                              {{ locationRateGroup.RateGroupName }}
                            </div>
                            <div class="col-2 text-end" *ngIf="!tenderService.IsExported()">
                              <fa-icon [icon]="faEdit"
                                       class="harmony-tertiary-text pointer"
                                       *ngIf="!locationRateGroup.IsLoadingOptions"
                                       [ngbTooltip]="'Change Rate Source'"
                                       (click)="getRateGroupOptions(locationRateGroup, location, location.SeaPortCityFunctionId, rateTypes.LCLOrigin)"></fa-icon>
                              <app-loader [Width]="20" *ngIf="locationRateGroup.IsLoadingOptions"></app-loader>
                            </div>
                          </div>
                        </td>
                        <td *ngFor="let locationRate of locationRateGroup.TenderLocationRates">
                          <b class="small">{{ this.tenderService.SelectedCurrency ? this.tenderService.SelectedCurrency.Currency.Code : '' }}</b>
                          {{ locationRate.TotalRate ? (locationRate.TotalRate | number:'1.2-2') : '&nbsp;&nbsp;-' }}
                        </td>
                      </ng-container>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-12 text-center" *ngIf="locationsLoading">
                <app-loader [Width]="50"></app-loader>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-6 text-start">
                Total locations: <b>{{ getOriginLocations().length }}</b>
              </div>
            </div>
          </div>
          <!----------------->
          <!-- LCL Freight -->
          <!----------------->
          <div *ngIf="canShowFreightModality(3)"
               class="tab-pane fade"
               id="lclFreight"
               role="tabpanel"
               aria-labelledby="lcl-freight-tab">
            <p class="mb-0 harmony-grey-text">
              <small>
                <fa-icon [icon]="faInfo"></fa-icon> It is currently not possible to change the rate source for Freight rates.
              </small>
            </p>
            <div class="row ms-1 me-1">
              <div class="col-12">
                <app-pagination [showPageNumber]=true
                                [showRowsPerPage]=true
                                [paginationData]=lclPaginationData
                                (pageEvent)="onPage(3, $event, lclPaginationData)"
                                [page]="lclPage">
                </app-pagination>
              </div>
            </div>
            <div class="row mt-2" *ngIf="!freightLoading">
              <div class="col-12">
                <div class="table-wrapper">
                  <table class="table">
                    <!-- Header -->
                    <thead class="rate-table-header">
                    <tr>
                      <th scope="col" style="width: 4em;" class="text-center">
                        Origin
                      </th>
                      <th style="width: 2em;">

                      </th>
                      <th scope="col" style="width: 4em;" class="text-center">
                        Destination
                      </th>
                      <th scope="col">
                        Rate Source
                      </th>
                      <th scope="col" *ngFor="let modality of tenderService.GetTenderModalitiesByType(3)">
                        <b>{{ modality.Quantity }}</b> {{ modality.UnitType.Abbreviation }}
                      </th>
                    </tr>
                    </thead>
                    <!-- Data -->
                    <tbody *ngIf="lclPaginationData && lclPaginationData.DataSet.length > 0">
                    <tr *ngFor="let freightRate of lclPaginationData.DataSet">
                      <th class="text-center overflow-hidden" style="width: 4em;">
                        <b>{{ freightRate.TenderFreightRateGroups.length > 0 ? freightRate.TenderFreightRateGroups[0].DeparturePortName : '-' }}</b>
                      </th>
                      <td class="text-center" style="width: 2em;">
                        <fa-icon [icon]="faArrowRight"></fa-icon>
                      </td>
                      <td class="text-center overflow-hidden" style="width: 4em;">
                        <b>{{ freightRate.TenderFreightRateGroups.length > 0 ? freightRate.TenderFreightRateGroups[0].ArrivalPortName : '-' }}</b>
                      </td>
                      <td>
                        <div class="row">
                          <div class="col-12 overflow-hidden">
                            {{ freightRate.TenderFreightRateGroups.length > 0 ? freightRate.TenderFreightRateGroups[0].RateGroupName : '-' }}
                          </div>
                        </div>
                      </td>
                      <td *ngFor="let freightRate of freightRate.TenderFreightRateGroups">
                        <b class="small">{{ this.tenderService.SelectedCurrency ? this.tenderService.SelectedCurrency.Currency.Code : '' }}</b>
                        {{ freightRate.TotalRate ? (freightRate.TotalRate  | number:'1.2-2') : '&nbsp;&nbsp;-' }}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="row mt-3" *ngIf="freightLoading">
              <div class="col-12 text-center">
                <app-loader [Width]="50"></app-loader>
              </div>
            </div>
            <div class="row ms-1 me-1">
              <div class="col-12">
                <app-pagination [showPageNumber]=true
                                [showRowsPerPage]=true
                                [paginationData]=lclPaginationData
                                (pageEvent)="onPage(3, $event, lclPaginationData)"
                                [page]="lclPage">
                </app-pagination>
              </div>
            </div>
          </div>
          <!--------------------->
          <!-- LCL Destination -->
          <!--------------------->
          <div *ngIf="canShowModality(3)"
               class="tab-pane fade"
               id="lclDestination"
               role="tabpanel"
               aria-labelledby="lcl-destination-tab">
            <p class="mb-0 harmony-grey-text" *ngIf="!tenderService.IsExported()">
              <small>
                <fa-icon [icon]="faInfo"></fa-icon> If you would like to use a different rate source for a specific location, click on the <fa-icon class="harmony-tertiary-text" [icon]="faEdit"></fa-icon> : “Change Rate Source” icon.
              </small>
            </p>
            <div class="row">
              <div class="col-6 text-start">
                Total locations: <b>{{ getDestinationLocations().length }}</b>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12" *ngIf="!locationsLoading">
                <div class="table-wrapper">
                  <table class="table">
                    <!-- Header -->
                    <thead class="rate-table-header">
                    <tr>
                      <th scope="col">
                        Location
                      </th>
                      <th scope="col">
                        POA
                      </th>
                      <th scope="col">
                        Rate Source
                      </th>
                      <th scope="col" *ngFor="let modality of tenderService.GetTenderModalitiesByType(3)">
                        <b>{{ modality.Quantity }}</b> {{ modality.UnitType.Abbreviation }}
                      </th>
                    </tr>
                    </thead>
                    <!-- Data -->
                    <tbody>
                    <tr *ngFor="let location of getDestinationLocations()">
                      <th scope="row">
                        {{ location.PartnerLocationName }}
                      </th>
                      <td>
                        <b>{{ location.SeaPort?.Description }}</b> ({{ location.SeaPort?.Code }})
                      </td>
                      <ng-container *ngVar="getLocationRateGroup(location, 3, true) as locationRateGroup">
                        <td>
                          <div class="row" *ngIf="locationRateGroup.RateGroupOptions && locationRateGroup.RateGroupOptions.length > 0 && !tenderService.IsExported()">
                            <div class="col-12">
                              <select class="form-select form-select-sm" [(ngModel)]="locationRateGroup.RateGroupId" (change)="updateTenderLocationRateGroupRates(locationRateGroup)">
                                <option *ngFor="let rateGroup of locationRateGroup.RateGroupOptions" [value]="rateGroup.ID">{{ rateGroup.AgentCompanyName }}</option>
                              </select>
                            </div>
                          </div>
                          <div class="row" *ngIf="!locationRateGroup.RateGroupOptions || locationRateGroup.RateGroupOptions.length < 1 || tenderService.IsExported()">
                            <div class="col-10 overflow-hidden">
                              {{ locationRateGroup.RateGroupName }}
                            </div>
                            <div class="col-2 text-end" *ngIf="!tenderService.IsExported()">
                              <fa-icon [icon]="faEdit"
                                       class="harmony-tertiary-text pointer"
                                       *ngIf="!locationRateGroup.IsLoadingOptions"
                                       [ngbTooltip]="'Change Rate Source'"
                                       (click)="getRateGroupOptions(locationRateGroup, location, location.SeaPortCityFunctionId, rateTypes.LCLDestination)"></fa-icon>
                              <app-loader [Width]="20" *ngIf="locationRateGroup.IsLoadingOptions"></app-loader>
                            </div>
                          </div>
                        </td>
                        <td *ngFor="let locationRate of locationRateGroup.TenderLocationRates">
                          <b class="small">{{ tenderService.SelectedCurrency ? tenderService.SelectedCurrency.Currency.Code : '' }}</b>
                          {{ locationRate.TotalRate ? (locationRate.TotalRate | number:'1.2-2') : '&nbsp;&nbsp;-' }}
                        </td>
                      </ng-container>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-12 text-center" *ngIf="locationsLoading">
                <app-loader [Width]="50"></app-loader>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-6 text-start">
                Total locations: <b>{{ getDestinationLocations().length }}</b>
              </div>
            </div>
          </div>

          <!---------------->
          <!-- ROAD Origin -->
          <!---------------->
          <div *ngIf="canShowModality(4)"
               class="tab-pane fade"
               id="roadOrigin"
               role="tabpanel"
               aria-labelledby="road-origin-tab">
            <p class="mb-0 harmony-grey-text" *ngIf="!tenderService.IsExported()">
              <small>
                <fa-icon [icon]="faInfo"></fa-icon> If you would like to use a different rate source for a specific location, click on the <fa-icon class="harmony-tertiary-text" [icon]="faEdit"></fa-icon> : “Change Rate Source” icon.
              </small>
            </p>
            <div class="row">
              <div class="col-6 text-start">
                Total locations: <b>{{ getOriginLocations().length }}</b>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12" *ngIf="!locationsLoading">
                <div class="table-wrapper">
                  <table class="table">
                    <!-- Header -->
                    <thead class="rate-table-header">
                    <tr>
                      <th scope="col">
                        Location
                      </th>
                      <th scope="col">
                        Rate Source
                      </th>
                      <th scope="col" *ngFor="let modality of tenderService.GetTenderModalitiesByType(4)">
                        <b>{{ modality.Quantity }}</b> {{ modality.UnitType.Abbreviation }}
                      </th>
                    </tr>
                    </thead>
                    <!-- Data -->
                    <tbody>
                    <tr *ngFor="let location of getOriginLocations()">
                      <th scope="row">
                        {{ location.PartnerLocationName }}
                      </th>
                      <ng-container *ngVar="getLocationRateGroup(location, 4, false) as locationRateGroup">
                        <td>
                          <div class="row" *ngIf="locationRateGroup.RateGroupOptions && locationRateGroup.RateGroupOptions.length > 0 && !tenderService.IsExported()">
                            <div class="col-12">
                              <select class="form-select form-select-sm" [(ngModel)]="locationRateGroup.RateGroupId" (change)="updateTenderLocationRateGroupRates(locationRateGroup)">
                                <option *ngFor="let rateGroup of locationRateGroup.RateGroupOptions" [value]="rateGroup.ID">{{ rateGroup.AgentCompanyName }}</option>
                              </select>
                            </div>
                          </div>
                          <div class="row" *ngIf="!locationRateGroup.RateGroupOptions || locationRateGroup.RateGroupOptions.length < 1 || tenderService.IsExported()">
                            <div class="col-10 overflow-hidden">
                              {{ locationRateGroup.RateGroupName }}
                            </div>
                            <div class="col-2 text-end" *ngIf="!tenderService.IsExported()">
                              <fa-icon [icon]="faEdit"
                                       class="harmony-tertiary-text pointer"
                                       *ngIf="!locationRateGroup.IsLoadingOptions"
                                       [ngbTooltip]="'Change Rate Source'"
                                       (click)="getRateGroupOptions(locationRateGroup, location, location.SeaPortCityFunctionId, rateTypes.ROADOrigin)"></fa-icon>
                              <app-loader [Width]="20" *ngIf="locationRateGroup.IsLoadingOptions"></app-loader>
                            </div>
                          </div>
                        </td>
                        <td *ngFor="let locationRate of locationRateGroup.TenderLocationRates">
                          <b class="small">{{ this.tenderService.SelectedCurrency ? this.tenderService.SelectedCurrency.Currency.Code : '' }}</b>
                          {{ locationRate.TotalRate ? (locationRate.TotalRate | number:'1.2-2') : '&nbsp;&nbsp;-' }}
                        </td>
                      </ng-container>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-12 text-center" *ngIf="locationsLoading">
                <app-loader [Width]="50"></app-loader>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-6 text-start">
                Total locations: <b>{{ getOriginLocations().length }}</b>
              </div>
            </div>
          </div>
          <!--------------------->
          <!-- ROAD Destination -->
          <!--------------------->
          <div *ngIf="canShowModality(4)"
               class="tab-pane fade"
               id="roadDestination"
               role="tabpanel"
               aria-labelledby="road-destination-tab">
            <p class="mb-0 harmony-grey-text" *ngIf="!tenderService.IsExported()">
              <small>
                <fa-icon [icon]="faInfo"></fa-icon> If you would like to use a different rate source for a specific location, click on the <fa-icon class="harmony-tertiary-text" [icon]="faEdit"></fa-icon> : “Change Rate Source” icon.
              </small>
            </p>
            <div class="row">
              <div class="col-6 text-start">
                Total locations: <b>{{ getDestinationLocations().length }}</b>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12" *ngIf="!locationsLoading">
                <div class="table-wrapper">
                  <table class="table">
                    <!-- Header -->
                    <thead class="rate-table-header">
                    <tr>
                      <th scope="col">
                        Location
                      </th>
                      <th scope="col">
                        Rate Source
                      </th>
                      <th scope="col" *ngFor="let modality of tenderService.GetTenderModalitiesByType(4)">
                        <b>{{ modality.Quantity }}</b> {{ modality.UnitType.Abbreviation }}
                      </th>
                    </tr>
                    </thead>
                    <!-- Data -->
                    <tbody>
                    <tr *ngFor="let location of getDestinationLocations()">
                      <th scope="row">
                        {{ location.PartnerLocationName }}
                      </th>
                      <ng-container *ngVar="getLocationRateGroup(location, 4, true) as locationRateGroup">
                        <td>
                          <div class="row" *ngIf="locationRateGroup.RateGroupOptions && locationRateGroup.RateGroupOptions.length > 0 && !tenderService.IsExported()">
                            <div class="col-12">
                              <select class="form-select form-select-sm" [(ngModel)]="locationRateGroup.RateGroupId" (change)="updateTenderLocationRateGroupRates(locationRateGroup)">
                                <option *ngFor="let rateGroup of locationRateGroup.RateGroupOptions" [value]="rateGroup.ID">{{ rateGroup.AgentCompanyName }}</option>
                              </select>
                            </div>
                          </div>
                          <div class="row" *ngIf="!locationRateGroup.RateGroupOptions || locationRateGroup.RateGroupOptions.length < 1 || tenderService.IsExported()">
                            <div class="col-10 overflow-hidden">
                              {{ locationRateGroup.RateGroupName }}
                            </div>
                            <div class="col-2 text-end" *ngIf="!tenderService.IsExported()">
                              <fa-icon [icon]="faEdit"
                                       class="harmony-tertiary-text pointer"
                                       *ngIf="!locationRateGroup.IsLoadingOptions"
                                       [ngbTooltip]="'Change Rate Source'"
                                       (click)="getRateGroupOptions(locationRateGroup, location, location.SeaPortCityFunctionId, rateTypes.ROADDestination)"></fa-icon>
                              <app-loader [Width]="20" *ngIf="locationRateGroup.IsLoadingOptions"></app-loader>
                            </div>
                          </div>
                        </td>
                        <td *ngFor="let locationRate of locationRateGroup.TenderLocationRates">
                          <b class="small">{{ tenderService.SelectedCurrency ? tenderService.SelectedCurrency.Currency.Code : '' }}</b>
                          {{ locationRate.TotalRate ? (locationRate.TotalRate | number:'1.2-2') : '&nbsp;&nbsp;-' }}
                        </td>
                      </ng-container>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-12 text-center" *ngIf="locationsLoading">
                <app-loader [Width]="50"></app-loader>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-6 text-start">
                Total locations: <b>{{ getDestinationLocations().length }}</b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
