<!---- Header ---->
<div class="modal-header" xmlns="http://www.w3.org/1999/html">
  <h4 class="modal-title">
    Environmental Risk Assessment
  </h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="close(null)"> </button>
</div>

<!-- Body -->
<div class="modal-body" *ngIf="environmentalAssessmentAnswer">
  <div class="row">
    <h5 class="col-12 fw-bold harmony-primary-text">
      {{ environmentalAssessmentGroup.Id + '. ' + environmentalAssessmentGroup.Description }}
    </h5>
  </div>
  <div class="row">
    <div class="col-12 fw-bold fs-6 my-1">
      {{ environmentalAssessmentItem.ItemNumber + ' ' + environmentalAssessmentItem.Topic }}
    </div>
  </div>
  <div class="row mt-3 mb-1">
    <div class="col-2 fw-bold">
      Subject
    </div>
    <div class="col-1 fw-bold">
      <fa-icon [icon]="faInfo" class="harmony-grey-text detail-info"
               [title]="'situation/occurrence with possible negative impact'">
      </fa-icon>
    </div>
    <div class="col-9">
      {{ environmentalAssessmentItem.Subject }}
    </div>
  </div>
  <div class="row mb-1">
    <div class="col-2 fw-bold">
      Risk
    </div>
    <div class="col-1 fw-bold">
      <fa-icon [icon]="faInfo" class="harmony-grey-text detail-info"
               [title]="'swhat is the consequence if the unwanted situation occurs'">
      </fa-icon>
    </div>
    <div class="col-9">
      {{ environmentalAssessmentItem.Risk }}
    </div>
  </div>
  <div class="row mb-2">
    <div class="col-2 fw-bold">
      Area
    </div>
    <div class="col-1 fw-bold">
      <fa-icon [icon]="faInfo" class="harmony-grey-text detail-info"
               [title]="'all'">
      </fa-icon>
    </div>
    <div class="col-9">
      {{ environmentalAssessmentItem.Area }}
    </div>
  </div>
  <div class="row header-row" style="margin-left: -5px !important; margin-right: -5px !important;">
    <div class="col-12 header-title-small border-split-left border-split-right fw-bold">
      Step 1 - Initial Environmental Risk Assessment
    </div>
  </div>
  <div class="row my-1">
    <div class="col-4 fw-bold">
      Exposure
    </div>
    <div class="col-1 fw-bold">
      <fa-icon [icon]="faInfo" class="harmony-grey-text detail-info"
               [title]="'frequency at which anyone working for the company may be exposed'">
      </fa-icon>
    </div>
    <div class="col-7">
      <select class="form-select form-select-sm pointer"
              [(ngModel)]="environmentalAssessmentAnswer.ExposureValue"
              [ngClass]="{ 'is-invalid': environmentalAssessmentAnswer.ExposureValue == null}"
              (change)="calculateIRL()">
        <option [ngValue]="null" selected>None...</option>
        <option *ngFor="let item of (exposure$ | async)"
                [ngValue]="item.Value">{{ item.Description }}</option>
      </select>
    </div>
  </div>
  <div class="row mb-1">
    <div class="col-4 fw-bold">
      Likeliness
    </div>
    <div class="col-1 fw-bold">
      <fa-icon [icon]="faInfo" class="harmony-grey-text detail-info"
               [title]="'likeliness that the described situation will manifest itself'">
      </fa-icon>
    </div>
    <div class="col-7">
      <select class="form-select form-select-sm pointer"
              [(ngModel)]="environmentalAssessmentAnswer.Likeliness"
              [ngClass]="{ 'is-invalid': environmentalAssessmentAnswer.Likeliness == null }"
              (change)="calculateIRL()" required="true">
        <option [ngValue]="null" selected>None...</option>
        <option *ngFor="let item of (likeliness$ | async)"
                [ngValue]="item.Value">{{ item.Description }}</option>
      </select>
    </div>
  </div>
  <div class="row mb-1">
    <div class="col-4 fw-bold">
      Severity
    </div>
    <div class="col-1 fw-bold">
      <fa-icon [icon]="faInfo" class="harmony-grey-text detail-info"
               [title]="'(gravest) consequence if the described incident would occur'">
      </fa-icon>
    </div>
    <div class="col-7">
      <select class="form-select form-select-sm pointer"
              [(ngModel)]="environmentalAssessmentAnswer.SeverityValue"
              [ngClass]="{ 'is-invalid': environmentalAssessmentAnswer.SeverityValue == null}"
              (change)="calculateIRL()">
        <option [ngValue]="null" selected>None...</option>
        <option *ngFor="let item of (severity$ | async)"
                [ngValue]="item.Value">{{ item.Description }}</option>
      </select>
    </div>
  </div>
  <div class="row mb-1">
    <div class="col-4 fw-bold">
      Risk level (IRL)
    </div>
    <div class="col-1 fw-bold">
      <fa-icon [icon]="faInfo" class="harmony-grey-text detail-info"
               [title]="'risk level before measures were taken'">
      </fa-icon>
    </div>
    <div class="col-7">
      <input class="form-control form-control-sm" type="number" [disabled]="true"
             [(ngModel)]="environmentalAssessmentAnswer.RiskLevel1"
             [ngClass]="setRiskLevelColor(environmentalAssessmentAnswer.RiskLevel1)">
    </div>
  </div>
  <div class="row mb-1">
    <div class="col-4 fw-bold">
      Possible measures
    </div>
    <div class="col-1 fw-bold">

    </div>
    <div class="col-7">
      <textarea class="form-control form-control-sm w-100"
                [disabled]="true"
                [rows]="4"
                [ngModel]="environmentalAssessmentItem.PossibleMeasures">
      </textarea>
    </div>
  </div>
  <div class="row header-row" style="margin-left: -5px !important; margin-right: -5px !important;">
    <div class="col-12 header-title-small border-split-left border-split-right fw-bold">
      Step 2 - Action
    </div>
  </div>
  <div class="row my-1">
    <div class="col-4 fw-bold">
      Influence
    </div>
    <div class="col-1 fw-bold">
      <fa-icon [icon]="faInfo" class="harmony-grey-text detail-info"
               [title]="'could the company influence the occurrence of the incident'">
      </fa-icon>
    </div>
    <div class="col-7">
      <select class="form-select form-select-sm pointer"
              [ngClass]="{ 'is-invalid':  environmentalAssessmentAnswer.InfluenceValue == null }"
              [(ngModel)]="environmentalAssessmentAnswer.InfluenceValue">
        <option [ngValue]="null" selected>None...</option>
        <option *ngFor="let item of (influence$ | async)"
                [ngValue]="item.Value">{{ item.Description }}</option>
      </select>
    </div>
  </div>
  <div class="row mb-1">
    <div class="col-4 fw-bold">
      Legislation
    </div>
    <div class="col-1 fw-bold">
      <fa-icon [icon]="faInfo" class="harmony-grey-text detail-info"
               [title]="'relevant legislation in your country or area of operation'">
      </fa-icon>
    </div>
    <div class="col-7">
    <textarea class="form-control form-control-sm w-100"
              [rows]="2"
              placeholder="Enter a value. This field is required"
              [ngClass]="{ 'is-invalid': environmentalAssessmentAnswer.Legislation == null || environmentalAssessmentAnswer.Legislation.length < 1}"
              [(ngModel)]="environmentalAssessmentAnswer.Legislation">
    </textarea>
    </div>
  </div>
  <div class="row mb-1">
    <div class="col-4 fw-bold">
      Measure
    </div>
    <div class="col-1 fw-bold">
      <fa-icon [icon]="faInfo" class="harmony-grey-text detail-info"
               [title]="'will measures be taken (at risk level > 70 this must be done)'">
      </fa-icon>
    </div>
    <div class="col-7">
      <select class="form-select form-select-sm pointer"
              [ngClass]="{ 'is-invalid': environmentalAssessmentAnswer.MeasureValue == null}"
              [(ngModel)]="environmentalAssessmentAnswer.MeasureValue">
        <option [ngValue]="null" selected>None...</option>
        <option *ngFor="let item of (measure$ | async)"
                [ngValue]="item.Value">{{ item.Description }}</option>
      </select>
    </div>
  </div>
  <div class="row mb-1">
    <div class="col-4 fw-bold">
      Level Of Measures
    </div>
    <div class="col-1 fw-bold">
      <fa-icon [icon]="faInfo" class="harmony-grey-text detail-info"
               [title]="'(highest) level of measures taken'">
      </fa-icon>
    </div>
    <div class="col-7">
      <select class="form-select form-select-sm pointer"
              [ngClass]="{ 'is-invalid': environmentalAssessmentAnswer.LevelOfMeasuresId == null}"
              [(ngModel)]="environmentalAssessmentAnswer.LevelOfMeasuresId">
        <option [ngValue]="null" selected>None...</option>
        <option *ngFor="let item of (levelOfMeasures$ | async)"
                [ngValue]="item.Id">{{ item.Description }}</option>
      </select>
    </div>
  </div>
  <div class="row mb-1">
    <div class="col-4 fw-bold">
      Remarks
    </div>
    <div class="col-1 fw-bold">
    </div>
    <div class="col-7">
    <textarea class="form-control form-control-sm w-100"
              [rows]="4"
              [(ngModel)]="environmentalAssessmentAnswer.RemarksMotivation">
    </textarea>
    </div>
  </div>
  <div class="row mb-1">
    <div class="col-4 fw-bold">
      Status
    </div>
    <div class="col-1 fw-bold">

    </div>
    <div class="col-7">
      <select class="form-select form-select-sm pointer"
              [ngClass]="{'is-invalid': this.environmentalAssessmentAnswer.StatusId == null}"
              [(ngModel)]="environmentalAssessmentAnswer.StatusId" required>
        <option [ngValue]="null" selected>None...</option>
        <option *ngFor="let item of (environmentalAssessmentAnswerStatus$ | async)"
                [ngValue]="item.Id">{{ item.Description }}</option>
      </select>
    </div>
  </div>
  <div class="row header-row" style="margin-left: -5px !important; margin-right: -5px !important;">
    <div class="col-12 header-title-small border-split-left border-split-right fw-bold">
      Step 3 - Residual Risks
    </div>
  </div>
  <div class="row mb-1">
    <div class="col-4 fw-bold">
      Exposure
    </div>
    <div class="col-1 fw-bold">
      <fa-icon [icon]="faInfo" class="harmony-grey-text detail-info"
               [title]="'frequency at which anyone working for the company may be exposed'">
      </fa-icon>
    </div>
    <div class="col-7">
      <select class="form-select form-select-sm pointer"
              [(ngModel)]="environmentalAssessmentAnswer.RemainingExposure"
              [ngClass]="{ 'is-invalid': (environmentalAssessmentAnswer.StatusId == 2 || environmentalAssessmentAnswer.StatusId == 3) && environmentalAssessmentAnswer.RemainingExposure == null}"
              (change)="calculateRRL()">
        <option [ngValue]="null" selected>None...</option>
        <option *ngFor="let item of (exposure$ | async)"
                [ngValue]="item.Value">{{ item.Description }}</option>
      </select>
    </div>
  </div>
  <div class="row mb-1">
    <div class="col-4 fw-bold">
      Likeliness
    </div>
    <div class="col-1 fw-bold">
      <fa-icon [icon]="faInfo" class="harmony-grey-text detail-info"
               [title]="'likeliness that the described situation will manifest itself'">
      </fa-icon>
    </div>
    <div class="col-7">
      <select class="form-select form-select-sm pointer"
              [(ngModel)]="environmentalAssessmentAnswer.RemainingLikeliness"
              [ngClass]="{ 'is-invalid': (environmentalAssessmentAnswer.StatusId == 2 || environmentalAssessmentAnswer.StatusId == 3) && environmentalAssessmentAnswer.RemainingLikeliness == null }"
              (change)="calculateRRL()">
        <option [ngValue]="null" selected>None...</option>
        <option *ngFor="let item of (likeliness$ | async)"
                [ngValue]="item.Value">{{ item.Description }}</option>
      </select>
    </div>
  </div>
  <div class="row mb-1">
    <div class="col-4 fw-bold">
      Severity
    </div>
    <div class="col-1 fw-bold">
      <fa-icon [icon]="faInfo" class="harmony-grey-text detail-info"
               [title]="'(gravest) consequence if the described incident would occur'">
      </fa-icon>
    </div>
    <div class="col-7">
      <select class="form-select form-select-sm pointer"
              [(ngModel)]="environmentalAssessmentAnswer.RemainingSeverity"
              [ngClass]="{ 'is-invalid': (environmentalAssessmentAnswer.StatusId == 2 || environmentalAssessmentAnswer.StatusId == 3) && environmentalAssessmentAnswer.RemainingSeverity == null }"
              (change)="calculateRRL()">
        <option [ngValue]="null" selected>None...</option>
        <option *ngFor="let item of (severity$ | async)"
                [ngValue]="item.Value">{{ item.Description }}</option>
      </select>
    </div>
  </div>
  <div class="row mb-1">
    <div class="col-4 fw-bold">
      Risk level (RRL)
    </div>
    <div class="col-1 fw-bold">
      <fa-icon [icon]="faInfo" class="harmony-grey-text detail-info"
               [title]="'risk level after measures were taken'">
      </fa-icon>
    </div>
    <div class="col-7">
      <input class="form-control form-control-sm" type="number" [disabled]="true"
             [ngModel]="environmentalAssessmentAnswer.RemainingTotal"
             [ngClass]="setResidualRiskLevelColor(environmentalAssessmentAnswer.RemainingTotal)">
    </div>
  </div>
  <hr>
  <!---- Legend ---->
  <div class="mt-2 mb-4">
    <div class="row mb-1 mx-2 fs-6 h-25">
      <div class="col-6 ps-4 fw-bold text-start">
        <small>Risk priorities</small>
      </div>
    </div>
    <div class="row mb-1 mx-2 small h-25">
      <div class="col-2 ps-4">
        <div class="risk-level-green ps-2 assessment-legend">
          < 21
        </div>
      </div>
      <div class="col-8 ps-4 ms-3">
        low: occupational risk is low or sufficiently managed
      </div>
    </div>
    <div class="row mb-1 mx-2 small h-25">
      <div class="col-2 ps-4">
        <div class="risk-level-lightgreen assessment-legend ps-2">
          21-70
        </div>
      </div>
      <div class="col-8 ps-4 ms-3">
        medium: occupational risks are low or managed but still require attention
      </div>
    </div>
    <div class="row mb-1 mx-2 small h-25">
      <div class="col-2 ps-4">
        <div class="risk-level-yellow assessment-legend ps-2">
          71-200
        </div>
      </div>
      <div class="col-8 ps-4 ms-3">
        high: improvement action is necessary to reduce risk level
      </div>
    </div>
    <div class="row mb-1 mx-2 small h-25">
      <div class="col-2 ps-4">
        <div class="risk-level-orange assessment-legend ps-2">
          201-400
        </div>
      </div>
      <div class="col-8 ps-4 ms-3">
        very high: take immediate action to address risk
      </div>
    </div>
    <div class="row mb-1 mx-2 small h-25">
      <div class="col-2 ps-4">
        <div class="risk-level-red assessment-legend ps-2">
          > 400
        </div>
      </div>
      <div class="col-8 ps-4 ms-3">
        unacceptable: stop work immediately (*)
      </div>
    </div>
  </div>
</div>

<!---- Footer ---->
<div class="modal-footer" >
   <button type="button" class="btn btn-primary harmony-tertiary-button" (click)="updateAnswer()">Save</button>
   <button type="button" class="btn btn-primary harmony-primary-button" (click)="close(null)">Close</button>
</div>

