<div class="modal-header">
  <h4 class="modal-title">
    Add Locations
  </h4>
  <button type="button"
          class="btn-close"
          aria-label="Close"
          [disabled]="loading"
          (click)="activeModal.dismiss('Dismiss')">

  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-5">
      <div class="row">
        <div class="col-12">
          <!-- City Search -->
          <app-city-search
            class="d-inline"
            *ngIf="selectLocationType.Name == 'City'"
            [Identifier]="'LocationCityID'"
            [SelectedID]="selectCityId"
            (OnSelectCityObject)="addLocation($event)">
          </app-city-search>
        </div>
      </div>
    </div>
    <div class="col-7">
      <div class="row">
        <div class="col-4">
          <select class="form-select form-select-sm pointer"
                  [(ngModel)]="tenderCompanyId"
                  (ngModelChange)="loadTenders()"
                  appAutocompleteOff>
            <option [ngValue]="null">Select a member...</option>
            <option *ngFor="let company of (companies$ | async)"
                    [ngValue]="company.CompanyId">{{ company.CompanyName }}</option>
          </select>
        </div>
        <div class="col-5">
          <select name="tenders"
                  id="tenders"
                  [(ngModel)]="selectedTenderId"
                  [disabled]="tenders.length <= 0"
                  class="form-select form-select-sm">
            <option [ngValue]="null">Select a tender...</option>
            <option *ngFor="let tender of tenders" [ngValue]="tender.Id">{{ tender.Name }}</option>
          </select>
        </div>
        <div class="col-3">
          <button class="btn btn-sm btn-primary harmony-primary-button"
                  [disabled]="!selectedTenderId"
                  (click)="getLocationsByTenderID()">
            Load Locations
          </button>
        </div>
      </div>
    </div>
  </div>
  <hr class="my-1">
  <!--------------------->
  <!---- Result table --->
  <!--------------------->
  <div class="row mt-2">
    <div class="col-6">
      <b>Location Count:</b> {{ requestLocations.length }}
    </div>
    <div class="col-6 text-end">
      <button class="btn btn-sm btn-outline-warning harmony-primary-outline-button"
              [title]="'Reset Locations'"
              (click)="requestLocations = []">
        Reset
      </button>
    </div>
  </div>
  <div class="row mt-2 header-row">
    <div class="col-7 col-compress header-title border-split-left">
      Location Name
    </div>
    <div class="col-3 col-compress header-title">
      LO Code
    </div>
    <div class="col-2 col-compress header-title border-split-right">

    </div>
  </div>
  <app-loader *ngIf="loading"></app-loader>
  <div class="location-results" *ngIf="!loading">
    <div class="row result-row" *ngFor="let location of requestLocations;">
      <div class="col-7 col-compress overflow-hidden">
        {{ location.LocationName }}
      </div>
      <div class="col-3 col-compress overflow-hidden">
        <small>{{ location.LocationCode }}</small>
      </div>
      <div class="col-2 text-end col-compress pe-0">
        <button class="btn btn-sm btn-outline-danger"
                (click)="removeLocation(location.LocationId)">
          <fa-icon [icon]="faRemove" size="sm"></fa-icon>
        </button>
      </div>
    </div>
    <div class="row mt-4" *ngIf="!loading && requestLocations.length === 0">
      <div class="col-12 text-center harmony-grey-text">
        <fa-icon [icon]="faEmpty" size="2x"></fa-icon>
      </div>
      <div class="col-12 text-center harmony-grey-text mt-1">
        <b>Select a location type to search, or load locations linked to a tender</b>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button"
          class="btn btn-primary harmony-tertiary-button"
          [disabled]="loading"
          (click)="apply()">
    Apply
  </button>
  <button type="button"
          class="btn btn-primary harmony-primary-button"
          [disabled]="loading"
          (click)="close()">
    Close
  </button>
</div>

