<!---- Header ---->
<div class="modal-header" xmlns="http://www.w3.org/1999/html">
  <h4 class="modal-title">
    {{ riskAssessmentGroup?.Id != null ? riskAssessmentGroup.Description : 'New Risk Assessment Group'}}
  </h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="close()"> </button>
</div>

<!-- Body -->
<div class="modal-body">
  <div class="row mb-1">
    <div class="col-2 fw-bold">
      Description
    </div>
    <div class="col-6">
      <input class="form-control form-control-sm" type="text"
             [(ngModel)]="riskAssessmentGroup.Description">
    </div>
    <div class="col-4 harmony-grey-text">
      <small></small>
    </div>
  </div>
</div>

<!---- Footer ---->
<div class="modal-footer" >
   <button type="button" class="btn btn-primary harmony-tertiary-button" (click)="saveGroup()">Save</button>
   <button type="button" class="btn btn-primary harmony-primary-button" (click)="close()">Close</button>
</div>

