import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  faAngleRight,
  faCircleDot,
  faToolbox
} from '@fortawesome/free-solid-svg-icons';
import { PermissionCodes } from '../../../core/constants/permission-codes';
import { AdAuthService } from '../../../core/ad-auth-service/ad-auth.service';

@Component({
  selector: 'app-pricing-menu',
  templateUrl: './pricing-menu.component.html',
  styleUrls: ['./pricing-menu.component.scss']
})
export class PricingMenuComponent implements OnInit, AfterViewChecked {
  // Icons
  faToolbox = faToolbox;
  faMenuItem = faCircleDot;
  faExpandable = faAngleRight;

  // Menu permissions
  pricingDashboard: boolean = false;
  tenderTool: boolean = false;
  singleRate: boolean = false;
  rateGroupManager: boolean = false;
  captureRates: boolean = false;
  requestRates: boolean = false;
  failedRates: boolean = false;
  preferredAgents: boolean = false;
  externalData: boolean = false;
  pricingAdmin: boolean = false;

  // Component variables
  public logger = (this as any).constructor.name as string;

  constructor(public router: Router,
              public authService: AdAuthService,
              private cdRef: ChangeDetectorRef) { }

  ngOnInit() {
    if (this.authService.CurrentUser) {
      this.setPermissions();
    }

    this.authService.CurrentUser$.subscribe(() => {
      this.setPermissions();
    });
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  setPermissions() {
    this.pricingDashboard = this.authService.CheckPermissionByCode(PermissionCodes.Pricing_Dashboard);
    this.tenderTool = this.authService.CheckPermissionByCode(PermissionCodes.Pricing_Tender_Tool);
    this.singleRate = this.authService.CheckPermissionByCode(PermissionCodes.Pricing_SingleRate);
    this.rateGroupManager = this.authService.CheckPermissionByCode(PermissionCodes.Pricing_RateGroupManager);
    this.captureRates = this.authService.CheckPermissionByCode(PermissionCodes.Pricing_CaptureRates);
    this.requestRates = this.authService.CheckPermissionByCode(PermissionCodes.Pricing_RequestRates);
    this.failedRates = this.authService.CheckPermissionByCode(PermissionCodes.Pricing_FailedRates);
    this.preferredAgents = this.authService.CheckPermissionByCode(PermissionCodes.Pricing_PreferredAgents);
    this.externalData = this.authService.CheckPermissionByCode(PermissionCodes.Pricing_ExternalDataManager);
    this.pricingAdmin = this.authService.CheckPermissionByCode(PermissionCodes.Pricing_Admin);
  }
}
