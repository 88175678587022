<div *ngIf="companyChart">
  <div class="row align-content-start">
    <div class="col-1 align-self-center d-flex justify-content-between">
      <b>Name</b>
      <fa-icon [icon]="faInfo" class="harmony-grey-text detail-info me-1"
               [title]="'The name used for this Company chart.'"></fa-icon>
    </div>
    <div class="col-3">
      <input class="form-control form-control-sm"
             type="text"
             [maxlength]="250"
             [(ngModel)]="companyChart.Name"
             [ngClass]="{'is-invalid': companyChart?.Name?.length < 1}">
    </div>
  </div>
  <div class="row align-content-start mt-1">
    <div class="col-1 align-self-center d-flex justify-content-between">
      <b>Contact</b>
      <fa-icon [icon]="faInfo" class="harmony-grey-text detail-info me-1"
               [title]="'The email of the contact person(s) for this Company chart. The email address will be used for sending reminders.'"></fa-icon>
    </div>
    <div class="col-3">
      <app-email-capture [EmailString]="companyChart.ContactEmail"
                         (OnValidate)="contactValid = !$event"
                         (OnResult)="companyChart.ContactEmail = $event"></app-email-capture>
    </div>
    <div class="col-1 align-content-start">
      <button class="btn btn-sm btn-primary harmony-tertiary-outline-button w-100"
              (click)="editCompanyChartName()">
        Update
      </button>
    </div>
  </div>
</div>
<hr class="mt-2 mb-2">
<div class="row my-2">
  <div class="col-3">
    <input class="form-control form-control-sm"
           type="text"
           placeholder="Search company chart items..."
           (keyup.enter)="loadCompanyChartCategories()"
           [(ngModel)]="companyChartItemsSearchParam.SearchCriteria">
  </div>
  <div class="col-6 align-self-center">
    <div class="d-flex">
      <span class="cc-status-green cc-legend d-inline-block"></span><span class="ms-1 me-2 align-self-center small">OK</span>
      <span class="cc-status-orange cc-legend d-inline-block"></span><span class="ms-1 me-2 align-self-center small">Requires attention</span>
      <span class="cc-status-red cc-legend d-inline-block"></span><span class="ms-1 me-2 align-self-center small">Overdue</span>
      <span class="cc-status-grey cc-legend d-inline-block"></span><span class="ms-1 me-2 align-self-center small">Out of use</span>
    </div>
  </div>
  <div class="col-1 col-compress">
    <button class="btn btn-sm btn-primary harmony-primary-outline-button w-100"
            [title]="'Reset Search'"
            (click)="resetSearch()">
      Reset
    </button>
  </div>
  <div class="col-2">
    <button class="btn btn-sm btn-primary harmony-tertiary-button w-100"
            (click)="loadCompanyChartCategories()">
      Search
    </button>
  </div>
</div>
<hr class="mt-2 mb-2">
<!--<hr class="mt-2 mb-1">-->
<div class="col-2 offset-10 text-end">
  <button class="btn btn-sm btn-primary harmony-tertiary-outline-button mb-1"
          [disabled]="loading"
          (click)="createCategory()">
    <fa-icon class="edit-picture" [icon]="faAdd"></fa-icon>
    New category
  </button>
</div>
<div class="accordion" id="accordion-question" *ngIf="companyChartCategories && companyChartItems && !loading">
  <ng-container *ngFor="let category of companyChartCategories; let c = index;">
    <div class="row">
      <div class="col-12">
        <div class="card my-1">
          <div class="card-header p-0" [attr.id]="'heading-' + c">
            <div class="row pointer category-edit-wrapper" (click)="chapterBtn.click()">
              <div class="col-9 text-start align-self-center">
                <h3 class="mb-0">
                  <button class="btn chapter-header mb-0 pt-2 fw-bold" #chapterBtn
                          type="button"
                          data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse-' + c"
                          [attr.aria-expanded]="c == 0" [attr.aria-controls]="'collapse-' + c">
                    {{ category.Description }} <span class="fw-normal">({{ category.TotalCount }})</span>
                  </button>
                </h3>
              </div>
              <div class="col-3 text-end d-flex justify-content-end align-self-center">
                <div class="py-2">
                  <button *ngIf="companyChart" class="btn btn-sm btn-primary harmony-primary-outline-button me-1 category-edit-button"
                          (click)="editCategory(category);$event.stopPropagation();"
                          [title]="'Edit category'">
                    <fa-icon [icon]="faEdit"></fa-icon>
                  </button>
                  <button *ngIf="canDeleteCategories" class="btn btn-sm btn-outline-danger me-1 category-edit-button"
                          (click)="deleteCategory(category.Id);$event.stopPropagation();"
                          [disabled]="getCompanyChartItems(category.Id).length > 0"
                          [title]="'Delete category'">
                    <fa-icon [icon]="faDelete"></fa-icon>
                  </button>
                  <span class="cc-status-orange cc-legend d-inline-block me-1" *ngIf="category.AttentionCount > 0">{{ category.AttentionCount }}</span>
                  <span class="cc-status-red cc-legend d-inline-block me-1" *ngIf="category.OverdueCount > 0">{{ category.OverdueCount }}</span>
                  <button *ngIf="companyChart" class="btn btn-sm btn-primary harmony-tertiary-outline-button me-1"
                          (click)="addCompanyChartItem(category.Id);$event.stopPropagation();"
                          [title]="'New company chart item'">
                    <fa-icon [icon]="faPlus"></fa-icon> Add item
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div [attr.id]="'collapse-' + c" class="collapse" [ngClass]="{ 'hide': c > 0, 'show': c == 0 && searching }" [attr.aria-labelledby]="'heading-' + c" data-bs-parent="#accordion-question">
            <div class="card-body">
              <div class="row m-0 header-row">
                <div class="col-2 header-title-small col-compress pointer border-split-left"
                     (appOrder)="orderItems($event)"
                     data-column="Subject"
                     data-order="desc">
                  <small>Subject</small>
                  <fa-icon [hidden]="itemOrderParam.OrderBy != 'Subject'" [icon]="faSortAsc" style="bottom: 3px;" class="ms-2 position-relative"></fa-icon>
                </div>
                <div class="col-1 header-title-small col-compress">
                  <small># items</small>
                </div>
                <div class="col-1 header-title-small col-compress">
                  <small>Year</small>
                </div>
                <div class="col-2 pointer header-title-small col-compress"
                     (appOrder)="orderItems($event)"
                     data-column="Action"
                     data-order="asc">
                  <small>Action</small>
                  <fa-icon [hidden]="itemOrderParam.OrderBy != 'Action'" class="ms-2 position-relative"></fa-icon>
                </div>
                <div class="col-1 header-title-small col-compress">
                  <small>Frequency</small>
                </div>
                <div class="col-1 pointer header-title-small col-compress"
                     (appOrder)="orderItems($event)"
                     data-column="ResponsibleManager"
                     data-order="asc">
                  <small>Manager</small>
                  <fa-icon [hidden]="itemOrderParam.OrderBy != 'ResponsibleManager'" class="ms-2 position-relative"></fa-icon>
                </div>
                <div class="col-1 pointer header-title-small col-compress"
                     (appOrder)="orderItems($event)"
                     data-column="CcBy"
                     data-order="asc">
                  <small>By</small>
                  <fa-icon [hidden]="itemOrderParam.OrderBy != 'CcBy'" class="ms-2 position-relative"></fa-icon>
                </div>
                <div class="col-1 pointer header-title-small col-compress"
                     (appOrder)="orderItems($event)"
                     data-column="Next"
                     data-order="asc">
                  <small>Dates</small>
                  <fa-icon [hidden]="itemOrderParam.OrderBy != 'Next'" class="ms-2 position-relative"></fa-icon>
                </div>
                <div class="col-1 text-end pointer header-title-small col-compress"
                     (appOrder)="orderItems($event)"
                     data-column="StatusDescription"
                     data-order="asc">
                  <small>Status</small>
                  <fa-icon [hidden]="itemOrderParam.OrderBy != 'StatusDescription'" class="ms-2 position-relative"></fa-icon>
                </div>
                <div class="col-1 header-title-small border-split-right">
                </div>
              </div>
              <div class="row result-row border-bottom" *ngFor="let item of getCompanyChartItems(category.Id)">
                <div class="col-2 col-compress fs-6">
                  <small class="fw-bold">{{ item.Subject ? item.Subject : '-' }}</small>
                  <br>
                  <small>{{ item.MakeType ? item.MakeType : '-' }}</small>
                </div>
                <div class="col-1 col-compress">
                  <small>{{ item.NrOfItems ? item.NrOfItems : '0' }}</small>
                </div>
                <div class="col-1 col-compress">
                  <small>{{ item.Year ? item.Year : '-' }}</small>
                </div>
                <div class="col-2  col-compress overflow-hidden" [title]=item.Action>
                  <small>{{ item.Action ? item.Action : '-' }}</small>
                </div>
                <div class="col-1 col-compress overflow-hidden" [title]=item.Frequency>
                  <small>{{ item.Frequency ? item.Frequency : '-' }}</small>
                </div>
                <div class="col-1 col-compress overflow-hidden" [title]=item.ResponsibleManager>
                  <small>{{ item.ResponsibleManager ? item.ResponsibleManager : '-' }}</small>
                </div>
                <div class="col-1 col-compress overflow-hidden" [title]=item.CcBy>
                  <small>{{ item.CcBy ? item.CcBy : '-' }}</small>
                </div>
                <div class="col-2 col-compress d-flex justify-content-between">
                  <div>
                    <small>
                      Last:
                      <span class="fw-bold">
                        {{ item.Last  ? (item.Last | date: environment.FormattingStandards.ShortDateFormat) : '-'}}
                      </span>
                    </small>
                    <br>
                    <small>
                      Next:
                      <span class="fw-bold"
                            [ngClass]="{'text-warning': getDayDifference(item.Next) < 7, 'text-danger': getDayDifference(item.Next) <= 0}">
                        {{ item.Next ? (item.Next | date: environment.FormattingStandards.ShortDateFormat) : '-' }}
                      </span>
                    </small>
                  </div>
                  <div style="padding-top: 4px">
                    <span [ngClass]="setStatusColor(item)"
                          [title]="item.StatusDescription"
                          class="cc-legend d-inline-block"></span>
                  </div>
                </div>
                <div class="col-1 col-compress text-end pe-0 mt-0 align-self-center">
                  <div class="d-flex justify-content-end">
                    <div class="me-2" *ngIf="item.NrOfAttachments > 0">
                      <span class="me-1">{{ item.NrOfAttachments ? item.NrOfAttachments : '-' }}</span>
                      <span class="harmony-secondary-text"><fa-icon [icon]="faFile"></fa-icon></span>
                    </div>
                    <button *ngIf="item" class="btn btn-sm btn-primary harmony-primary-outline-button me-1"
                            (click)="openCompanyChartItemModal(item)"
                            [title]="'Edit company chart item'"
                            [disabled]="isExpired"
                            [ngClass]="{'disabled' : isExpired }">
                      <fa-icon [icon]="faEdit"></fa-icon>
                    </button>
                    <button *ngIf="item" class="btn btn-sm btn-primary harmony-secondary-outline-button me-1"
                            (click)="copyCompanyChartItem(item)"
                            [title]="'Copy company chart item'"
                            [disabled]="isExpired"
                            [ngClass]="{'disabled' : isExpired }">
                      <fa-icon [icon]="faCopy"></fa-icon>
                    </button>
                    <button *ngIf="canDeleteItems"
                            class="btn btn-sm btn-outline-danger"
                            [title]="'Delete company chart item'"
                            (click)="deleteCompanyChartItem(item)">
                      <fa-icon [icon]="faDelete"></fa-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
