<!---- Header ---->
<div class="modal-header modal-title">
  {{ Title ?? 'Review and comment' }}
</div>
<!---- Edit ---->
<div class="modal-body">
  <div class="row">
    <div class="col-12">
      <p [innerHTML]="Message"></p>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <textarea class="form-control form-control-sm w-100"
                [rows]="4"
                [(ngModel)]="comment"
                [ngClass]="{ 'is-invalid': (comment != null && comment.length < 1) && invalid == true }">
      </textarea>
    </div>
  </div>
</div>
<!---- Footer ---->
<div class="modal-footer">
  <button type="button" class="btn btn-primary harmony-tertiary-button" (click)="save()">Continue</button>
  <button type="button" class="btn btn-primary harmony-primary-button" (click)="close()">Cancel</button>
</div>
