<div class="modal-header">
  <h4 class="modal-title">
    Data and processing logs for message ID: {{ SourceMessageId ?? '-' }}
  </h4>
</div>
<div class="modal-body" *ngIf="message">
  <div class="row">
    <div class="col-6 pe-3">
      <div class="row">
        <div class="col-5 align-self-center text-center">
          <small>{{ message.SourceSystemName }}</small>
          <br>
          <b>{{ message.SourceClientName }}</b>
          <br>
          <small>{{ message.SourceSchemaTypeName }}</small>
          <br>
          <small>{{ message.SourceReference ?? '-' }}</small>
        </div>
        <div class="col-2 align-self-center text-center">
          <small class="harmony-grey-text">
            {{ message.MessageType == 1 ? 'XML' : 'JSON' }}
          </small>
          <br>
          <fa-icon [icon]="faMessage"
                   class="harmony-accent-blue-grey-text me-2"
                   size="2x"></fa-icon>
          <br>
          <small>{{ message.CreatedDate | date:env.FormattingStandards.LongDateFormat }}</small>
        </div>
        <div class="col-5 align-self-center text-center">
          <small>{{ message.TargetSystemName }}</small>
          <br>
          <b>{{ message.TargetClientName }}</b>
          <br>
          <small>{{ message.TargetSchemaTypeName }}</small>
          <br>
          <small>{{ message.TargetReference ?? '-' }}</small>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 align-self-center d-flex justify-content-between">
          <h5 class="harmony-primary-text pt-1 mb-0 d-inline fw-bold">
            Source message
          </h5>
          <fa-icon [icon]="faCopy"
                   class="harmony-accent-blue-grey-text pointer ms-2"
                   size="lg"
                   style="margin-top: 5px;"
                   [title]="'Copy message object to clipboard'"
                   (click)="copyMessageToClipboard()"></fa-icon>
        </div>
      </div>
      <hr class="my-2">
      <div class="message-wrapper mt-2">
        <div class="json-view" *ngIf="messageObject">
          <pre *ngIf="message.MessageType == 1"><code>{{ messageObject }}</code></pre>
          <pre *ngIf="message.MessageType == 2"><code>{{ messageObject | json }}</code></pre>
        </div>
      </div>
    </div>
    <div class="col-6 border-start ps-2">
      <div class="row">
        <div class="col-12">
          <h5 class="harmony-primary-text mb-0 fw-bold">
            Processing logs
          </h5>
        </div>
      </div>
      <hr class="my-2">
      <div class="row mb-0 header-row">
        <div class="col-3 header-title-small border-split-left">
          Log Time / Type
        </div>
        <div class="col-4 header-title-small">
          Subject
        </div>
        <div class="col-4 header-title-small">
          Message
        </div>
        <!-- Actions -->
        <div class="col-1 header-title border-split-right">

        </div>
      </div>
      <div class="row mt-3" *ngIf="messageLogs && messageLogs.length === 0">
        <div class="col-12 text-center harmony-grey-text">
          No logs found. Possible that no logging is implement for this message flow.
        </div>
      </div>
      <div class="row result-row border-top" *ngFor='let log of messageLogs; let i = index'>
        <div class="col-3 overflow-hidden col-compress">
          <small class="fw-bold">{{ log.DateCreated | date: env.FormattingStandards.LongDateFormat  }}</small>
          <br>
          <small [ngClass]="{'text-danger':log.AzureLogTypeName?.toLowerCase().includes('error') || log.AzureLogTypeName?.toLowerCase().includes('failed'),
                         'harmony-accent-green-text':log.AzureLogTypeName?.toLowerCase().includes('success')}">
            {{ log.AzureLogTypeName }}
          </small>
        </div>
        <div class="col-4 overflow-hidden col-compress" [title]="log.LogSubject">
          <small>{{ log.LogSubject }}</small>
        </div>
        <div class="col-4 overflow-hidden col-compress" [title]="log.LogMessage">
          <small>{{ log.LogMessage }}</small>
        </div>
        <div class="col-1 text-end col-compress">
          <button type="button"
                  class="btn btn-sm btn-outline-secondary harmony-grey-outline-button m-1 border-0"
                  [title]="'View Azure workflow'"
                  *ngIf="canViewAll && log.RunId?.length > 0"
                  (click)="goToLogicAppRun(log)">
            <fa-icon [icon]="faExternal"></fa-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary harmony-primary-button" (click)="close()">Close</button>
</div>
