<div class="modal-header">
  <h4 class="modal-title" id="modal-title-edit" *ngIf="authUser.UserId != null">
    {{ canEdit ? 'Manage' : 'Viewing' }} user | {{ authUser.User.DisplayName }}
  </h4>
  <h4 class="modal-title" id="modal-title-create" *ngIf="authUser.UserId == null">
    Create new user
  </h4>
  <button type="button" class="btn-close" aria-label="Close" [disabled]="loading" (click)="activeModal.dismiss(false)">

  </button>
</div>
<div class="modal-body" *ngIf="authUser">
  <div class="row">
    <div class="col-9">
      <div class="row">
        <div class="col-12 harmony-teal-text">
          <h5 class="mb-0">User info</h5>
        </div>
      </div>
      <hr class="mt-1 mb-1">
      <div class="row mt-2" *ngIf="authUser.User">
        <div class="col-4 align-self-center pe-1">
          <b>Username</b>
          <app-loader *ngIf="checkingUser" class="float-end" [Width]="20"></app-loader>
          <ng-container *ngIf="authUser.User.Username && authUser.User.Username.length > 0">
            <fa-icon [icon]="faValid" size="1x" *ngIf="usernameValid && domainValid" class="float-end harmony-green-text"></fa-icon>
            <fa-icon [icon]="faInvalid" size="1x" *ngIf="!domainValid || !usernameValid" class="float-end text-danger"></fa-icon>
          </ng-container>
        </div>
        <div class="col-8 ps-1" style="line-height: 1em;">
          <ng-container *ngIf="authUser.UserId != null && authUser.UserId > 0 || !canEdit">
            <div class="row mb-1">
              <div class="col-12 ps-3 py-1">
                {{ authUser.User.Username }}
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="(authUser.UserId == null || authUser.UserId == 0) && canCreate">
            <input type="text"
                   class="form-control form-control-sm"
                   [ngModel]="authUser.User.Username"
                   [disabled]="checkingUser"
                   [email]="true"
                   [required]="true"
                   [autofocus]="true"
                   #username="ngModel"
                   (change)="onUsernameChanged(username, $event);"
                   [ngClass]="{'is-invalid': !usernameValid && showValidation}">
            <small class="small" *ngIf="username.errors?.email">
              Primary email address is not valid.
            </small>
            <small *ngIf="!domainValid">
              {{ usernameValidMessage }}
            </small>
          </ng-container>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-4 align-self-center pe-1">
          <b>First Name</b>
        </div>
        <div class="col-8 ps-1">
          <input type="text"
                 [value]="authUser.User.FirstName"
                 (change)="authUser.User.FirstName = $event.target['value']"
                 [ngClass]="{'is-invalid': (!authUser.User.FirstName || authUser.User.FirstName.length <= 0) && showValidation}"
                 [required]="true"
                 [disabled]="!canEdit"
                 class="form-control form-control-sm">
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-4 align-self-center pe-1">
          <b>Last Name</b>
        </div>
        <div class="col-8 ps-1">
          <input type="text"
                 [value]="authUser.User.LastName"
                 (change)="authUser.User.LastName = $event.target['value']"
                 [ngClass]="{'is-invalid': (!authUser.User.LastName || authUser.User.LastName.length <= 0) && showValidation}"
                 [required]="true"
                 [disabled]="!canEdit"
                 class="form-control form-control-sm">
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-4 align-self-center pe-1">
          <b>Display Name</b>
        </div>
        <div class="col-8 ps-1">
          <input type="text"
                 [value]="authUser.User.DisplayName"
                 (change)="authUser.User.DisplayName = $event.target['value']"
                 [disabled]="!canEdit"
                 [ngClass]="{'is-invalid': authUser.User.DisplayName.length < 1 && showValidation}"
                 class="form-control form-control-sm">
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-4 align-self-center pe-1">
          <b>Default Company</b>
        </div>
        <div class="col-8 ps-1">
          <select class="form-select form-select-sm pointer"
                  [disabled]="authUser?.UserCompanies.length <= 0 || !canEdit"
                  [ngClass]="{'is-invalid': authUser.User.CompanyId == null && showValidation}"
                  (change)="changeDefaultCompany()"
                  [(ngModel)]="authUser.User.CompanyId">
            <option [ngValue]="null" [disabled]="true" selected>Select a default company...</option>
            <option *ngFor="let company of authUser?.UserCompanies" [ngValue]="company.CompanyId">
              {{ company?.CompanyName }}
            </option>
          </select>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-4 align-self-center pe-1">
          <b>Active Directory</b>
        </div>
        <div class="col-8 align-self-center ps-1">
          <b *ngIf="authUser.User.AzureRegistered === true && authUser.UserId != null"
                class="harmony-green-background text-white rounded w-100 d-block text-center py-1">Active</b>
          <ng-container *ngIf="authUser.User.AzureRegistered === false && authUser.UserId != null">
            <div class="row">
              <div class="col-11">
                <button class="btn btn-sm btn-primary button-azure w-100"
                        (click)="registerUserInAD(authUser.User)"
                        [disabled]="!canCreate">
                  Register in AD
                </button>
              </div>
              <div class="col-1 text-center align-self-center">
                <div class="form-check form-switch form-switch-sm pointer">
                  <input class="form-check-input pointer" style="margin-left: -3.1em; margin-top: 0.3em;"
                         type="checkbox" role="switch"
                         [(ngModel)]="sendNotification"
                         [title]="'Send welcome email'"
                         [id]="'send-notification'">
                </div>
              </div>
            </div>
          </ng-container>
          <small *ngIf="authUser.UserId == null">The user will be registered in the AD when saved. Once registered the user will be able to reset their password and log in to the portal.</small>
        </div>
      </div>
    </div>
    <div class="col-3 text-center align-self-center">
      <img *ngIf="picture" class="img-responsive user-picture" (error)="picture = null" [src]="picture" alt="my-profile-picture">
      <img *ngIf="!picture"
           class="img-responsive user-picture"
           [src]="env.SiteUrls.AzureStorageBaseURL + env.SiteUrls.ImagePlaceholderLargeUrl"
           alt="profile-picture">
    </div>
  </div>
  <hr>
  <div class="row mt-2">
    <div class="col-12" *ngIf="authUser">
      <div class="row">
        <div class="col-6">
          <div class="row">
            <div class="col-12 harmony-teal-text">
              <h5 class="mb-0">Assigned companies</h5>
            </div>
          </div>
          <hr class="mt-1 mb-1">
          <div class="row mt-1 mb-2">
            <div class="col-12">
              <app-company-search class="d-inline"
                                  [Identifier]="'AgentID'"
                                  [ErrorColor]="'#a22b9d'"
                                  [Disabled]="!canEditCompanies"
                                  [SelectedID]="selectedCompanyId"
                                  (OnSelectObject)="addCompanyToUser($event)">
              </app-company-search>
            </div>
          </div>
          <div class="row result-row" *ngFor="let userCompany of authUser.UserCompanies; let i = index">
            <div class="col-10"
                 [ngClass]="{'strikethrough': userCompany.Active == false}">
              {{ userCompany?.CompanyName }}
            </div>
            <div class="col-2 text-end">
              <ng-container *ngIf="canEdit">
                <fa-icon [icon]="faRemove"
                         class="text-danger pointer" size="1x" title="Remove"
                         *ngIf="userCompany.Active && userCompany.CompanyId !== authUser.User.CompanyId"
                         (click)="removeUserCompany(userCompany)">
                </fa-icon>
                <fa-icon [icon]="faAdd"
                         class="harmony-green-text pointer" size="1x" title="Reactivate"
                         *ngIf="!userCompany.Active && userCompany.CompanyId !== authUser.User.CompanyId"
                         (click)="userCompany.Active = true">
                </fa-icon>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="col-6 border-start">
          <div class="row">
            <div class="col-12 harmony-teal-text overflow-hidden text-nowrap">
              <h5 class="mb-0" style="text-overflow: ellipsis; overflow: hidden;">Assigned roles for <strong>{{ rolesCompanyName }}</strong></h5>
            </div>
          </div>
          <hr class="mt-1 mb-1">
          <div class="row mt-1 mb-2">
            <div class="col-6 pe-1">
              <select class="form-select form-select-sm pointer fw-bold"
                      *ngIf="authUser.UserCompanies"
                      [(ngModel)]="rolesCompanyId"
                      [disabled]="loading || authUser.UserCompanies.length <= 0"
                      (change)="changeCompanyRoles()">
                <option [ngValue]="null" [disabled]="true">Select company to edit roles...</option>
                <option *ngFor="let userCompany of authUser.UserCompanies"
                        [ngValue]="userCompany.CompanyId">
                  {{ userCompany?.CompanyName }}
                </option>
              </select>
            </div>
            <div class="col-6 ps-1">
              <ng-container *ngIf="roleAdmin">
                <select class="form-select form-select-sm pointer"
                        [(ngModel)]="roleToAdd"
                        [disabled]="loading || (roles$ | async)?.length <= 0 || (authUser.UserCompanies?.length <= 0)"
                        [ngClass]="{'is-invalid': authUser.UserRoles?.length <= 0 && showValidation}"
                        (ngModelChange)="addRoleToUser()">
                  <option [ngValue]="null">Select role to add...</option>
                  <ng-container *ngFor="let role of (roles$ | async)">
                    <option *ngIf="!userHasRole(role)"
                            [ngValue]="role">
                      {{ role.Name }}
                    </option>
                  </ng-container>
                </select>
              </ng-container>
              <ng-container *ngIf="!roleAdmin">
                <select class="form-select form-select-sm pointer"
                        [(ngModel)]="roleToAdd"
                        [disabled]="loading || (allowedRoles$ | async)?.length <= 0 || (authUser.UserCompanies?.length <= 0)"
                        [ngClass]="{'is-invalid': authUser.UserRoles?.length <= 0 && showValidation}"
                        (ngModelChange)="addRoleToUser()">
                  <option [ngValue]="null">Select role to add...</option>
                  <ng-container *ngFor="let role of (allowedRoles$ | async)">
                    <option *ngIf="!userHasRole(role)"
                            [ngValue]="role">
                      {{ role.Name }}
                    </option>
                  </ng-container>
                </select>
              </ng-container>
            </div>
          </div>
          <div class="row result-row" *ngFor="let userRole of authUser.UserRoles; let i = index">
            <div class="col-10"
                 [ngClass]="{'strikethrough': userRole.Active == false}">
              {{ userRole?.RoleName}}
            </div>
            <div class="col-2 text-end">
              <ng-container *ngIf="canEdit || canDeleteRole(userRole)">
                <fa-icon [icon]="faRemove"
                         class="text-danger pointer"
                         size="1x"
                         *ngIf="userRole.Active == true"
                         (click)="removeUserRole(userRole)"></fa-icon>
                <fa-icon [icon]="faAdd"
                         class="harmony-green-text pointer"
                         size="1x"
                         *ngIf="userRole.Active == false"
                         (click)="userRole.Active = true"></fa-icon>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <app-loader *ngIf="loading" class="ms-2" [Width]="20"></app-loader>
  <button type="button" class="btn btn-primary harmony-purple-button" (click)="save()">{{ authUser.UserId == null ? 'Create' : (authUser.User.AzureRegistered === false ? 'Register & Save' : 'Save') }}</button>
  <button type="button" class="btn btn-primary harmony-teal-button" (click)="close()">Close</button>
</div>

