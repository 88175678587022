<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom sticky-header">
  <h1 class="portal-component-header">
    User Management
  </h1>
  <div>
    <button class="btn btn-sm btn-outline-primary harmony-tertiary-outline-button me-1"
            *ngIf="hasCreatePermission"
            (click)="openNewUserModal()">
      + New User
    </button>
  </div>
</div>
<div class="row mb-5">
  <div class="col-12">
    <!------------------->
    <!---- Filtering ---->
    <!------------------->
    <div class="row mt-1">
      <div class="col-3 text-end">
        <app-company-search class="d-inline"
                            [Identifier]="'CompanyID'"
                            [SelectedID]="searchParam.CompanyId"
                            (OnSelect)="searchParam.CompanyId = $event">
        </app-company-search>
      </div>
      <div class="col-2">
        <select class="form-select form-select-sm pointer"
                [(ngModel)]="searchParam.RoleId"
                [disabled]="!(roles$ | async)">
          <option [ngValue]="null">All roles...</option>
          <option *ngFor="let role of (roles$ | async)"
                  [ngValue]="role.Id">
            {{ role.Name }}
          </option>
        </select>
      </div>
      <div class="col-8 text-end">

      </div>
    </div>
    <hr class="my-1">
    <div class="row">
      <div class="col-3">
        <input type="text"
               class="filter-item form-control form-control-sm"
               [(ngModel)]="searchParam.SearchText"
               (keyup.enter)="search()"
               placeholder="Filter users...">
      </div>
      <div class="col-2">
        <select class="form-select form-select-sm w-100 pointer"
                [(ngModel)]="searchParam.Active">
          <option [ngValue]="null">All users</option>
          <option [ngValue]="true" selected>Active users only</option>
          <option [ngValue]="false">Disabled users only</option>
        </select>
      </div>
      <div class="col-3 offset-4 text-end">
        <button class="btn btn-sm btn-outline-primary harmony-primary-outline-button me-1"
                (click)="resetSearch()">
          Reset
        </button>
        <button class="btn btn-sm btn-primary harmony-tertiary-button w-50"
                (click)="search()">
          Search
        </button>
      </div>
    </div>
    <hr class="my-1">
    <!------------------->
    <!---- Pagination --->
    <!------------------->
    <div class="row ms-1 me-1">
      <div class="col-12">
        <app-pagination [showPageNumber]=true
                        [showRowsPerPage]=true
                        [paginationData]=paginationData
                        (pageEvent)="onPageChange($event)"
                        [name]="'UserManagement'"
                        [page]="page ">
        </app-pagination>
      </div>
    </div>
    <!--------------------->
    <!---- Result table --->
    <!--------------------->
    <div class="row mt-1 header-row">
      <div class="col-4 header-title col-compress border-split-left">
        <div class="row">
          <div class="col-10 col-compress offset-2 pointer"
               (appOrder)="orderSearch($event)"
               data-column="Username"
               data-order="asc">
            Username
            <fa-icon [hidden]="orderParam.OrderBy != 'Username'" [icon]="faSortAsc" style="bottom: 3px;" class="ms-2 position-relative"></fa-icon>
          </div>
        </div>
      </div>
      <div class="col-2 col-compress header-title pointer"
           (appOrder)="orderSearch($event)"
           data-column="DisplayName"
           data-order="asc">
        Display Name
        <fa-icon [hidden]="orderParam.OrderBy != 'DisplayName'" class="ms-2 position-relative"></fa-icon>
      </div>
      <div class="col-1 col-compress header-title pointer"
           (appOrder)="orderSearch($event)"
           data-column="Company.Name"
           data-order="asc">
        Company
        <fa-icon [hidden]="orderParam.OrderBy != 'Company.Name'" class="ms-2 position-relative"></fa-icon>
      </div>
      <div class="col-1 col-compress header-title pointer"
           (appOrder)="orderSearch($event)"
           data-column="DateCreated"
           data-order="asc">
        Created
        <fa-icon [hidden]="orderParam.OrderBy != 'DateCreated'" class="ms-2 position-relative"></fa-icon>
      </div>
      <div class="col-1 col-compress header-title pointer"
           (appOrder)="orderSearch($event)"
           data-column="LastLoggedInDate"
           data-order="asc"
           *ngIf="showLogin">
        Last Login
        <fa-icon [hidden]="orderParam.OrderBy != 'LastLoggedInDate'" class="ms-2 position-relative"></fa-icon>
      </div>
      <div class="col-1 col-compress header-title pointer"
           (appOrder)="orderSearch($event)"
           data-column="DateDeactivated"
           data-order="asc"
           *ngIf="!showLogin">
        Deactivated
        <fa-icon [hidden]="orderParam.OrderBy != 'DateDeactivated'" class="ms-2 position-relative"></fa-icon>
      </div>
      <div class="col-2 col-compress header-title pointer">
        Permissions
      </div>
      <div class="col-1 header-title col-compress text-end border-split-right">

      </div>
    </div>
    <!--------------------->
    <!------ Results ------>
    <!--------------------->
    <ng-container *ngIf="paginationData">
      <div class="row result-row p-1"
           [ngClass]="{'selected-user': selectedUser && (selectedUser.UserId === user.Id) }"
           *ngFor="let user of paginationData.DataSet; let i = index; trackBy: identify">
        <div class="col-4 col-compress">
          <div class="row">
            <div class="col-2 overflow-hidden">
              <div class="profile-icon" *ngIf="token && user.ProfilePicture"
                   [ngStyle]="{'background-image': 'url(\'' + user.ProfilePicture + token + '\')' }">
              </div>
              <div class="profile-icon" *ngIf="!user.ProfilePicture"
                   [ngStyle]="{'background-image': 'url(\'' + defaultImage + '\')' }">
              </div>
            </div>
            <div class="col-10 pt-1 ps-0">
              <span class="ms-0">{{ user.Username }}</span>
            </div>
          </div>
        </div>
        <div class="col-2 col-compress pt-1 overflow-hidden">
          <b>{{ user.DisplayName ?? '-' }} {{ user.Active ? '' : '(Inactive)' }}</b>
        </div>
        <div class="col-1 col-compress pt-1 overflow-hidden">
          {{ user.CompanyName ?? '-' }}
        </div>
        <div class="col-1 col-compress pt-1 overflow-hidden">
          <small>{{ user.DateCreated | date: env.FormattingStandards.ShortDateFormat }}</small>
        </div>
        <div class="col-1 col-compress pt-1 overflow-hidden" *ngIf="showLogin">
          {{ user.LastLoggedInDate ? (user.LastLoggedInDate | date: env.FormattingStandards.ShortDateFormat) : '-' }}
        </div>
        <div class="col-1 col-compress pt-1 overflow-hidden" *ngIf="!showLogin">
          {{ user.DateDeactivated ? (user.DateDeactivated | date: env.FormattingStandards.ShortDateFormat) : '-' }}
        </div>
        <div class="col-2 col-compress pt-1 overflow-hidden">
          <app-user-module-overview [Modules]="user.Modules"></app-user-module-overview>
        </div>
        <div class="col-1 col-compress text-end pe-0">
          <button class="btn btn-sm btn-primary harmony-primary-outline-button me-1"
                  (click)="openUserEditModal(user)" [title]="'View or edit user'">
            <fa-icon [icon]="faEdit" size="xs"></fa-icon>
          </button>
          <ng-container *ngIf="hasDeletePermission">
            <button class="btn btn-sm btn-outline-danger"
                    *ngIf="user.Active === true"
                    [title]="'Deactivate User'"
                    (click)="toggleUserActive(user, false)">
              <fa-icon [icon]="faRemove" size="xs"></fa-icon>
            </button>
            <button class="btn btn-sm btn-outline-success"
                    *ngIf="user.Active === false"
                    (click)="toggleUserActive(user, true)">
              <fa-icon [icon]="faAdd" size="xs"></fa-icon>
            </button>
          </ng-container>
        </div>
      </div>
    </ng-container>
    <!--------------------->
    <!------ Loader ------->
    <!--------------------->
    <div class="row mt-4" *ngIf="loading">
      <div class="col-12 text-center">
        <app-loader [Width]="50"></app-loader>
      </div>
    </div>
    <!--------------------->
    <!---- No Results ----->
    <!--------------------->
    <div class="row mt-4" *ngIf="!loading && (paginationData && paginationData.DataSet.length === 0)">
      <div class="col-12 text-center harmony-grey-text">
        <fa-icon [icon]="faEmpty" size="2x"></fa-icon>
      </div>
      <div class="col-12 text-center harmony-grey-text mt-1">
        <h4>No results...</h4>
      </div>
    </div>
  </div>
</div>
