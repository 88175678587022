<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-2 border-bottom sticky-header">
  <h1 class="portal-component-header">
    HUB Messages
  </h1>
</div>
<div class="row mb-5">
  <div class="col-12">
    <!------------------->
    <!---- Filtering ---->
    <!------------------->
    <div class="row mt-2">
      <div class="col-2 align-self-center">
        <b>Client Direction</b>
      </div>
      <div class="col-3">
        <select class="form-select form-select-sm pointer"
                [disabled]="!(userClients$ | async) || (userClients$ | async).length <= 0"
                [(ngModel)]="selectedClient"
                (change)="setClientData()">
          <option [ngValue]="null" *ngIf="canViewAllDirections">All clients...</option>
          <option *ngFor="let client of (userClients$ | async)"
                  [selected]="searchParam.SourceClientId == client.SourceClientId"
                  [ngValue]="client">
            {{ client.SourceClientName }} ({{client.SourceSystemName}})&nbsp; &DDotrahd; &nbsp;{{ client.TargetClientName }} ({{client.TargetSystemName}})
          </option>
        </select>
      </div>
      <div class="col-3">
        <select class="form-select form-select-sm pointer"
                [disabled]="!(processStatuses$ | async) || (processStatuses$ | async).length <= 0"
                [(ngModel)]="searchParam.ProcessStatusId"
                (change)="setClientData()">
          <option [ngValue]="null">All statuses</option>
          <option *ngFor="let status of (processStatuses$ | async)"
                  [ngValue]="status.Id">
            {{ status.Name }}
          </option>
        </select>
      </div>
    </div>
    <ng-container *ngIf="canViewAllDirections">
      <div class="row mt-1">
        <div class="col-2 align-self-center">
          <b>Schemas</b>
        </div>
        <div class="col-3">
          <select class="form-select form-select-sm pointer"
                  [disabled]="!(sourceSchemaTypes$ | async) || (sourceSchemaTypes$ | async).length <= 0"
                  [(ngModel)]="searchParam.SourceSchemaTypeId">
            <option [ngValue]="null">All source schemas</option>
            <option *ngFor="let schemaType of (sourceSchemaTypes$ | async)" [ngValue]="schemaType.Id">{{ schemaType.SchemaTypeName }}</option>
          </select>
        </div>
        <div class="col-3">
          <select class="form-select form-select-sm pointer"
                  [disabled]="!(targetSchemaTypes$ | async) || (targetSchemaTypes$ | async).length <= 0"
                  [(ngModel)]="searchParam.TargetSchemaTypeId">
            <option [ngValue]="null">All target schemas</option>
            <option *ngFor="let schemaType of (targetSchemaTypes$ | async)" [ngValue]="schemaType.Id">{{ schemaType.SchemaTypeName }}</option>
          </select>
        </div>
      </div>
    </ng-container>
    <div class="row mt-1">
      <div class="col-2 align-self-center">
        <b>Date Range</b>
      </div>
      <div class="col-3">
        <app-date-picker [identifier]="'fromDate'"
                         (onChange)="searchParam.FromDate = $event"
                         [value]="searchParam.FromDate"
                         [placeholder]="'Select a from date'"></app-date-picker>
      </div>
      <div class="col-3">
        <app-date-picker [identifier]="'toDate'"
                         (onChange)="searchParam.ToDate = $event"
                         [value]="searchParam.ToDate"
                         [placeholder]="'Select a to date'"></app-date-picker>
      </div>
    </div>
    <hr class="mt-2 mb-0">
    <div class="row mt-1">
      <div class="col-5">
        <input type="text"
               class="filter-item form-control form-control-sm"
               [(ngModel)]="searchParam.SearchText"
               (keyup.enter)="searchMessages()"
               placeholder="Search messages...">
      </div>
      <div class="col-3 offset-4 text-end">
        <button class="btn btn-sm btn-outline-primary harmony-primary-outline-button me-1"
                (click)="resetSearch()">
          Reset
        </button>
        <button type="button"
                class="btn btn-sm btn-primary harmony-tertiary-button w-50"
                (click)="searchMessages()">
          Search
        </button>
      </div>
    </div>
    <hr class="mt-1 mb-0">
    <!------------------->
    <!---- Pagination --->
    <!------------------->
    <div class="row ms-1 me-1">
      <div class="col-12">
        <app-pagination [showPageNumber]=true
                        [showRowsPerPage]=true
                        [paginationData]=paginationData
                        (pageEvent)="onPage($event)"
                        [name]="'HubMissingValues'"
                        [page]="page">
        </app-pagination>
      </div>
    </div>
    <!------------------->
    <!----- Results ----->
    <!------------------->
    <div class="row mt-1 harmony-primary-text">
      <div class="col-4 offset-1 text-center">
        <h5 class="mb-0">Source</h5>
      </div>
      <div class="col-4 text-center">
        <h5 class="mb-0">Target</h5>
      </div>
    </div>
    <div class="row mt-1 header-row">
      <div class="col-1 col-compress pointer header-title border-split-left border-split-right">
        ID
      </div>
      <div class="col-1 col-compress pointer header-title border-split-left"
           (appOrder)="orderSearch($event)"
           data-column="SourceClient.ClientName"
           data-order="asc">
        Client
        <fa-icon [hidden]="orderParam.OrderBy != 'SourceClient.ClientName'" class="ms-2 position-relative"></fa-icon>
      </div>
      <div class="col-2 col-compress pointer header-title"
           (appOrder)="orderSearch($event)"
           data-column="SourceSchemaType.Name"
           data-order="asc">
        Schema
        <fa-icon [hidden]="orderParam.OrderBy != 'SourceSchemaType.Name'" class="ms-2 position-relative"></fa-icon>
      </div>
      <div class="col-1 col-compress pointer header-title border-split-right">
        Reference
      </div>
      <div class="col-1 col-compress pointer header-title border-split-left"
           (appOrder)="orderSearch($event)"
           data-column="TargetClient.ClientName"
           data-order="asc">
        Client
        <fa-icon [hidden]="orderParam.OrderBy != 'TargetClient.ClientName'" class="ms-2 position-relative"></fa-icon>
      </div>
      <div class="col-2 col-compress pointer header-title"
           (appOrder)="orderSearch($event)"
           data-column="TargetSchemaType.Name"
           data-order="asc">
        Schema
        <fa-icon [hidden]="orderParam.OrderBy != 'TargetSchemaType.Name'" class="ms-2 position-relative"></fa-icon>
      </div>
      <div class="col-1 col-compress pointer header-title border-split-right">
        Reference
      </div>
      <div class="col-1 col-compress pointer header-title border-split-left"
           (appOrder)="orderSearch($event)"
           data-column="CreatedOn"
           data-order="asc">
        Date
        <fa-icon [hidden]="orderParam.OrderBy != 'CreatedOn'" [icon]="faSortDesc" style="bottom: -3px;"  class="ms-2 position-relative"></fa-icon>
      </div>
      <div class="col-1 col-compress pointer header-title">
        Status
      </div>
      <div class="col-1 col-compress text-end header-title border-split-right">

      </div>
    </div>
    <ng-container *ngIf="!loading && paginationData && paginationData.DataSet.length > 0">
      <div class="row result-row" *ngFor="let message of paginationData.DataSet">
        <div class="col-1 col-compress overflow-hidden">
          {{ message.Id }}
        </div>
        <div class="col-1 col-compress overflow-hidden" [title]="message.SourceClientName">
          <b>{{ message.SourceClientName ?? '-' }}</b>
        </div>
        <div class="col-2 col-compress overflow-hidden" [title]="message.SourceClientName">
          {{ message.SourceSchemaTypeName ?? '-' }}
        </div>
        <div class="col-1 col-compress overflow-hidden" [title]="message.SourceReference">
          {{ message.SourceReference ?? '-' }}
        </div>
        <div class="col-1 col-compress overflow-hidden" [title]="message.TargetClientName">
          <b>{{ message.TargetClientName ?? '-' }}</b>
        </div>
        <div class="col-2 col-compress overflow-hidden" [title]="message.TargetSchemaTypeName">
          {{ message.TargetSchemaTypeName ?? '-' }}
        </div>
        <div class="col-1 col-compress overflow-hidden" [title]="message.TargetReference">
          {{ message.TargetReference ?? '-' }}
        </div>
        <div class="col-1 col-compress overflow-hidden" [title]="(message.CreatedDate + '+00:00') | date: env.FormattingStandards.LongDateFormat:timezone">
          <small>{{ (message.CreatedDate + '+00:00') | date: env.FormattingStandards.LongDateFormat:timezone }}</small>
        </div>
        <div class="col-1 col-compress overflow-hidden">
          <small [ngClass]="{'text-danger': message.MessageStatus == 'Failed',
                             'harmony-accent-green-text': message.MessageStatus == 'Successful',
                             'text-warning': message.MessageStatus == 'Duplicate',
                             'text-info': message.MessageStatus == 'New' || message.MessageStatus == 'Processing'}">
            {{ message.MessageStatus ?? '-' }}
          </small>
        </div>
        <div class="col-1 col-compress text-end pe-0">
          <button class="btn btn-sm btn-outline-warning harmony-primary-outline-button me-1"
                  [title]="'View message data'"
                  (click)="viewMessage(message.Id)">
            <fa-icon [icon]="faView" size="xs"></fa-icon>
          </button>
          <button class="btn btn-sm btn-outline-warning harmony-accent-blue-grey-outline-button me-1"
                  [title]="'Inspect mapping'"
                  (click)="viewMapping(message)">
            <fa-icon [icon]="faData" size="xs"></fa-icon>
          </button>
        </div>
      </div>
    </ng-container>
    <!------------------->
    <!----- Loader ------>
    <!------------------->
    <ng-container *ngIf="loading">
      <div class="row mt-4">
        <div class="col-12 text-center">
          <app-loader [Width]="50"></app-loader>
        </div>
      </div>
    </ng-container>
    <!------------------->
    <!--- No Results ---->
    <!------------------->
    <ng-container *ngIf="!loading && paginationData.DataSet.length <= 0">
      <div class="row mt-4" *ngIf="!loading && (paginationData.DataSet && paginationData.DataSet.length === 0)">
        <div class="col-12 text-center harmony-grey-text">
          <fa-icon [icon]="faEmpty" size="2x"></fa-icon>
        </div>
        <div class="col-12 text-center harmony-grey-text mt-1">
          <h4>No results...</h4>
        </div>
      </div>
    </ng-container>
  </div>
</div>
