<div class="modal-header">
  <h4 class="modal-title">
    Action Log Data for rate request: <b *ngIf="RateRequest">{{RateRequest.Id}}</b>
  </h4>
</div>
<div class="modal-body" *ngIf="ActionLogs">
  <div class="row ms-1 me-1">
    <div class="col-3">
      <b>Request Type</b>
    </div>
    <div class="col-9">
      {{ RateRequest.RateRequestTypeName }}
    </div>
  </div>
  <div class="row ms-1 me-1">
    <div class="col-3">
      <b>Agent</b>
    </div>
    <div class="col-9">
      {{ RateRequest.AgentName }}
    </div>
  </div>
  <div class="row mb-0 mt-3 header-row">
    <div class="col-2 header-title border-split-left">
      Date
    </div>
    <div class="col-2 header-title">
      Actioned By
    </div>
    <div class="col-3 header-title">
      Type
    </div>
    <div class="col-2 header-title">
      Value
    </div>
    <div class="col-3 header-title border-split-right">
      Comment
    </div>
  </div>
  <div class="row" *ngIf="ActionLogs && ActionLogs.length === 0">
    <div class="col-12 text-center">
      No actions found.
    </div>
  </div>
  <div class="row result-row border-top" *ngFor='let log of ActionLogs; let i = index'>
    <div class="col-2 overflow-hidden">
      <small>{{ log.DateCreated | date: environment.FormattingStandards.LongDateFormat }}</small>
    </div>
    <div class="col-2 overflow-hidden" [title]="log.ActionByUserName">
      <small>{{ log.ActionByUserName ?? '-' }}</small>
    </div>
    <div class="col-3 overflow-hidden">
      <small>{{ log.RateRequestActionTypeName }}</small>
    </div>
    <div class="col-2 overflow-hidden">
      <small>{{ log.ActionValue ?? '-' }}</small>
    </div>
    <div class="col-3 overflow-hidden" [title]="log.ActionComment">
      <small>{{ log.ActionComment ?? '-' }}</small>
    </div>
  </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary harmony-primary-button" (click)="close()">Close</button>
</div>
