<div class="card-wrapper">
  <div class="card-content">
    <div class="card-header row">
      <div class="col-12">
        <h5 class="harmony-primary-text fw-bold mt-2">Incidents</h5>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-6">
              <select class="form-select form-select-sm form-control-xs pointer mx-1"
                      [(ngModel)]="disciplineId"
                      [disabled]="loading || !(discipline$ | async)"
                      (change)="filterItemsOfDiscipline(disciplineId)">
                <option [ngValue]="0" selected>All disciplines...</option>
                <option *ngFor="let discipline of (discipline$ | async)"
                        [ngValue]="discipline.DisciplineId">{{ discipline.Description }}</option>
              </select>
            </div>
            <div class="col-6">
              <select class="form-select form-select-sm form-control-xs pointer mx-1"
                      [(ngModel)]="registrationTypeId"
                      [disabled]="loading || !(disciplineId) || !(registrationType$ | async)"
                      (change)="getIncidentReport()">
                <option [ngValue]="0" selected>All registration types...</option>
                <option *ngFor="let registrationType of filteredRegistrationTypes"
                        [ngValue]="registrationType.RegistrationTypeId">{{ registrationType.Description }}</option>
              </select>
            </div>
          </div>
          <div *ngIf="incidentReport" class="row mt-3">
            <div class="col-2 offset-1 pointer" (click)="viewIncidents(null)">
              <div class="ii-count level-total">
                <div class="label pt-1 text-center">Total</div>
                <div class="fw-bold text-center subject">{{ incidentReport.TotalIncidents }}</div>
              </div>
            </div>
            <div class="col-2 offset-2 pointer" (click)="viewIncidents('Open')">
              <div class="ii-count pt-1 level-green">
                <div class="label text-center">Open</div>
                <div class="fw-bold text-center subject">{{ incidentReport.OpenIncidents }}</div>
              </div>
            </div>
            <div class="col-2 offset-2 pointer" (click)="viewIncidents('Overdue')">
              <div class="ii-count pt-1 level-attention">
                <div class="label text-center">Overdue</div>
                <div class="fw-bold text-center subject">{{ incidentReport.OverdueIncidents }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
