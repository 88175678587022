import { Component, Input, OnInit } from '@angular/core';
import { IContactCard } from '../../../services/models/user.model';
import { ApiService } from '../../../services/api.service';
import { environment } from '../../../../environments/environment';
import { FileService } from '../../../services/file.service';

@Component({
  selector: 'app-contact-card',
  templateUrl: './contact-card.component.html',
  styleUrls: ['./contact-card.component.scss']
})
export class ContactCardComponent implements OnInit {
  @Input() UserID: number;

  userContact: IContactCard;

  // General variables
  env = environment;
  loading: boolean = false;
  defaultImage = this.env.SiteUrls.AzureStorageBaseURL + this.env.SiteUrls.UserImagePlaceholderUrl;
  token: string;

  constructor(private api: ApiService,
              private fileService: FileService) { }

  ngOnInit(): void {
    this.loadUser();
  }

  loadUser() {
    this.loading = true;
    this.api.get(`User/ContactCard/${this.UserID}`).subscribe({
      next: (data: IContactCard) => {
        this.userContact = Object.assign({}, data);
        this.getToken();
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  getFullPictureName() {
    const pictureName = this.userContact.ProfilePicture.substring(
      this.userContact.ProfilePicture.lastIndexOf('/') + 1);
    // Includes sub-folder of the userId
    return `${this.env.SiteUrls.AzureStorageBaseURL}${this.env.ContainerNames.UserProfilePictures}/${this.userContact.Id}/${pictureName}`;
  }

  getToken() {
    this.fileService.GetSasToken(this.env.ContainerNames.UserProfilePictures)
      .subscribe({
        next: (data) => {
          this.token = `?${data.Token}`;
        }
      });
  }
}
